import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";

import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import ProductDAO from "../DAO/ProductDAO";
import { withRouter } from "react-router";
import { notify } from "../helpers/FlashMessage";

class DetailsSuggestionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            successMessage: "",
        };
    }
    componentDidMount() {
        document.title = "Produits - Sobrus Labs";

        this.get();
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ProductDAO.getSuggestionById(id);
            this.setState({ product: response.data.produit }, () => this.state.product);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    translate(param) {
        if (param === true) {
            return "Oui";
        } else if (param === false) {
            return "Non";
        } else {
            return "Aucun";
        }
    }
    render() {
        const { product, successMessage } = this.state;
        const title = product && product.nom;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={"Suggestion de produit : " + title}></TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>
                                            {/* <Field_value_green>{contact && contact.gestionnaire}</Field_value_green> */}
                                            <Field_value>{product && product.nom}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Code barre</Field_label>
                                            <Field_value>{product && product.code_barre}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Code barre 2</Field_label>
                                            <Field_value>{product && product.code_barre2}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Catégorie</Field_label>
                                            <Field_value>{product && product.categorie && product.categorie}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Classe thérapeutique</Field_label>
                                            <Field_value>{product && product.classe_therapeutique && product.classe_therapeutique}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Forme galénique</Field_label>
                                            <Field_value>{product && product.forme_galenique && product.forme_galenique}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>produit tableau</Field_label>
                                            <Field_value>{product && product.produit_tableau}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>DCI</Field_label>
                                            <Field_value>{product && product.dci && product.dci}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gamme</Field_label>
                                            <Field_value>{product && product.gamme && product.gamme}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Sous-gamme</Field_label>
                                            <Field_value>{product && product.sousgame && product.sousgame}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nécessite une prescription</Field_label>
                                            <Field_value>{product && this.translate(product.necessite_prescription)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Laboratoire</Field_label>
                                            <Field_value>{product && product.laboratoire}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Produit marché</Field_label>
                                            <Field_value>{product && this.translate(product.produit_marche)}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations du stock</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>PPH</Field_label>
                                            <Field_value>{product && product.pph !== undefined && `${parseFloat(product.pph).toFixed(2)} DHS`}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Prix Public de Vente</Field_label>
                                            <Field_value>{product && product.ppv !== undefined && `${parseFloat(product.ppv).toFixed(2)} DHS`}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Taxe sur achat</Field_label>
                                            <Field_value>{product && product.taxe_achat !== undefined && `TVA (${parseFloat(product.taxe_achat).toFixed(2)}%)`}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Taxe sur vente</Field_label>
                                            <Field_value>{product && product.taxe_vente !== undefined && `TVA (${parseFloat(product.taxe_vente).toFixed(2)}%)`}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Est remboursable</Field_label>
                                            <Field_value>{product && this.translate(product.est_remboursable)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Base de remboursement</Field_label>
                                            <Field_value>{product && product.base_remboursement}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations descriptives</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Présentation</Field_label>
                                            <Field_value>{product && product.presentation}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Excipients</Field_label>
                                            <Field_value>{product && product.excipients}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Posologie pour Adulte</Field_label>
                                            <Field_value>{product && product.posologie_adulte}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Posologie pour Enfant</Field_label>
                                            <Field_value>{product && product.posologie_enfant}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Indications</Field_label>
                                            <Field_value>{product && product.indications}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contre-indication conduite</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_conduite)}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Contre-indication allaitement</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_allaitement)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contre-indication grossesse</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_grossesse)}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Référence interne laboratoire</Field_label>
                                            <Field_value>{product && product.ref_interne_laboratoire}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Conditionnement</Field_label>
                                            <Field_value>{product && product.conditionnement}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Monograph</Field_label>
                                            <Field_value>{product && product.monograph}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Description</Field_label>
                                            <Field_value>{product && product.description}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations sur statut</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Statut</Field_label>
                                            <Field_value>{product && product.statut}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Raison du rejet</Field_label>
                                            <Field_value>{product && product.raison_rejet}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Commentaire du rejet</Field_label>
                                            <Field_value>{product && product.commentaire_rejet}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                    </Left_block_container>
                    <Right_block_container>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>
                                    <Col_1_li>{product && product.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>
                                    <Col_1_li>{product && moment(product.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>
                                    <Col_1_li>{product && product.mis_a_jour_par ? product && product.mis_a_jour_par : product && product.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>
                                    <Col_1_li>{product && product.mis_a_jour_le ? product && moment(product.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : product && moment(product.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

export default withRouter(DetailsSuggestionPage);

const Main_container = styled.div`
    height: 100%;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
