import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../../helpers/FlashMessages";
import { connect } from "react-redux";
import ResourceDAO from "../../DAO/ResourceDAO";
import { notify } from "../../helpers/FlashMessage";
import { RoutesPath } from "../../values/RoutesPath";
import { acl } from "../../services/acl";
import "./AddDemand.css";
import { BiTime } from "react-icons/bi";
import PopUpGlobal from "../../components/PopUpGlobal";
import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import SearchSelect from "../../helpers/SearchSelect";
import { NotAsyncSelect } from "../../helpers/SearchSelect";
import CustomFileInput from "../../components/CustomFileInput";
import { FullRow } from "../../helpers/formStyledComp";
import ContactTablePopup from "./ContactTablePopup";
import DemandDAO from "../../DAO/DemandDAO";
import ContentLoader from "react-content-loader";
import NoResultCas from "../../helpers/NoResultCas";
import { MessagesText } from "../../values/MessagesText";
import UserDAO from "../../DAO/UserDAO";
import FromLoading from "../../helpers/FromLoading";

const PRIORITY_COLOR = {
    Normale: "#4674b9",
    Urgente: "#ffa902",
    Critique: "#E04052",
};
const STATUS_COLOR = {
    Ouvert: "#05b8c0",
    Résolu: "#00bf9c",
    Archivé: "#E04052",
};
const FIELDS = {
    object: {
        value: "",
        error: false,
    },
    manager: {
        value: "",
        error: false,
    },
    file: {
        value: null,
        name: "",
        error: false,
    },
    channel: {
        value: "Téléphone",
        error: false,
    },
    incident: {
        value: "",
        error: false,
    },
    priority: {
        value: "Normale",
        error: false,
    },
    desc: {
        value: "",
        error: false,
    },
    account: {
        value: "",
        error: false,
    },
};
class AddDemand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            isLoaded: true,
            data: [],
            disabledData: 0,
            contact: null,
            contactError: false,
            fields: {
                ...FIELDS,
            },

            selectedManager: null,
            demandId: null,
            loadingForm: false,
            demandNumber: "",
        };
    }
    setField(field, value) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: {
                    ...prevState.fields[field],
                    value,
                },
            },
        }));
    }
    setFieldError(field, error) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: {
                    ...prevState.fields[field],
                    error,
                },
            },
        }));
    }
    setFieldName(field, name) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: {
                    ...prevState.fields[field],
                    name,
                },
            },
        }));
    }
    componentDidMount() {
        const demandId = this.props.match.params.id;
        if (demandId) {
            this.setState({ demandId }, this.get);
        } else {
            this.getConnectedUserInfos();
            // if add did from contact details
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const query = urlParams.get('q');
            if (query) {
                const contact = JSON.parse(window.atob(query))
                if (contact) {
                    this.setState({ contact, contactError: false }, this.getAll);
                }
            }
            // end
        }
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setField("manager", response.data.id);
            this.setState({
                selectedManager: { label: response.data.fullname, value: response.data.id },
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    getFileName(fakeName) {
        if (fakeName && typeof fakeName === "string") {
            const firstPart = fakeName.slice(0, fakeName.lastIndexOf("-"));
            const lastPart = fakeName.slice(fakeName.lastIndexOf("."), fakeName.length);
            return firstPart + lastPart;
        }
        return "";
    }

    get = async () => {
        try {
            const demandId = this.props.match.params.id;
            const params = {};
            this.setState({ loadingForm: true });
            const response = await DemandDAO.getDemandById(demandId, params);

            const data = response.data.response;
            if (data && data.lastStatus && data.lastStatus.status && data.lastStatus.status !== "Ouvert") {
                this.props.history.push(`${RoutesPath.demand.details}/${demandId}`);
                notify({ msg: MessagesText.demand.refuseUpdate, type: "danger" });
            } else {
                data.subject && this.setField("object", data.subject);
                data.assignedTo && this.setField("manager", data.createdBy);
                data.file && this.setField("file", data.file);
                data.channel && this.setField("channel", data.channel);
                data.incident && this.setField("incident", data.incident);
                data.description && this.setField("desc", data.description);
                data.priority && this.setField("priority", data.priority);
                data.fileName && this.setFieldName("file", this.getFileName(data.fileName));
                this.setState({ demandNumber: data.demandNumber });
                if (data.createdBy && data.createdByName) {
                    this.setState({
                        selectedManager: { label: data.createdByName, value: data.createdBy },
                    });
                }
                if (data.contactName && data.contactId) {
                    this.setState({
                        contact: { fullname: data.contactName, id: data.contactId, compte_id: data.accountId },
                    });
                }
            }
            this.setState({ loadingForm: false });
        } catch (error) {
            this.setState({ loadingForm: false });
            notify({ msg: error.message, type: "danger" });
        }
    };

    getAll = async () => {
        try {
            const { contact } = this.state;
            let data = {};
            if (contact && contact.id) {
                data["contactId"] = {
                    value: contact.id,
                    op: "is",
                };
            }
            this.setState({ isLoaded: false });
            const response = await DemandDAO.getALL(this.state.pageNumber, {}, data, 5);
            this.setState({ isLoaded: true });
            this.setState({ data: response.data.response.demands ? response.data.response.demands : [], totalItems: response.data.response.total });
        } catch (error) {
            this.setState({ isLoaded: false });
            notify({ msg: error.message, type: "danger" });
        }
    };
    verifyRequired() {
        const { fields, contact } = this.state;
        const demandId = this.props.match.params.id;
        if (
            fields.object.value &&
            fields.object.value.length !== 0 &&
            fields.manager.value &&
            fields.manager.value.length !== 0 &&
            fields.channel.value &&
            fields.channel.value.length !== 0 &&
            fields.incident.value &&
            fields.incident.value.length !== 0 &&
            fields.priority.value &&
            fields.priority.value.length !== 0 &&
            contact
        ) {
            let data = new FormData();
            if (fields.file.value) {
                data.append("file", fields.file.value);
            }
            data.append("contact", contact.id);
            data.append("channel", fields.channel.value);
            data.append("incident", fields.incident.value);
            data.append("priority", fields.priority.value);
            data.append("subject", fields.object.value);
            data.append("assignedTo", fields.manager.value);
            data.append("compte", contact.compte_id);
            data.append("description", fields.desc.value);

            return data;
        } else {
            if (!fields.object.value || fields.object.value.length === 0) {
                this.setFieldError("object", true);
            }
            if (!fields.manager.value || fields.manager.value.length === 0) {
                this.setFieldError("manager", true);
            }
            if (!fields.channel.value || fields.channel.value.length === 0) {
                this.setFieldError("channel", true);
            }
            if (!fields.incident.value || fields.incident.value.length === 0) {
                this.setFieldError("incident", true);
            }
            if (!fields.priority.value || fields.priority.value.length === 0) {
                this.setFieldError("priority", true);
            }
            if (!contact) {
                this.setState({ contactError: true });
            }
            return null;
        }
    }
    verifyUpdateRequired() {
        const { fields, contact } = this.state;
        const demandId = this.props.match.params.id;
        if (
            fields.object.value &&
            fields.object.value.length !== 0 &&
            fields.channel.value &&
            fields.channel.value.length !== 0 &&
            fields.incident.value &&
            fields.incident.value.length !== 0 &&
            fields.priority.value &&
            fields.priority.value.length !== 0 &&
            contact
        ) {
            let data = new FormData();
            if (fields.file.value) {
                data.append("file", fields.file.value);
            }
            data.append("contact", contact.id);
            data.append("channel", fields.channel.value);
            data.append("incident", fields.incident.value);
            data.append("priority", fields.priority.value);
            data.append("subject", fields.object.value);
            data.append("compte", contact.compte_id);
            data.append("description", fields.desc.value);

            return data;
        } else {
            if (!fields.object.value || fields.object.value.length === 0) {
                this.setFieldError("object", true);
            }

            if (!fields.channel.value || fields.channel.value.length === 0) {
                this.setFieldError("channel", true);
            }
            if (!fields.incident.value || fields.incident.value.length === 0) {
                this.setFieldError("incident", true);
            }
            if (!fields.priority.value || fields.priority.value.length === 0) {
                this.setFieldError("priority", true);
            }
            if (!contact) {
                this.setState({ contactError: true });
            }
            return null;
        }
    }
    saveData = async (contu) => {
        try {
            const data = this.verifyRequired();
            if (data) {
                const params = {};
                this.setState({ loadingForm: true });
                const response = await DemandDAO.addDemand(params, data);
                this.setState({ loadingForm: false });
                if (response.data.status === "ok") {
                    notify({ msg: MessagesText.demand.add, type: "success" });
                    if (contu) {
                        this.setState(
                            {
                                fields: {
                                    ...FIELDS,
                                },
                                selectedManager: null,
                            },
                            () => {
                                this.getAll();
                                this.getConnectedUserInfos();
                            }
                        );
                    } else {
                        this.props.history.push(`${RoutesPath.demand.details}/${response.data.response.id}`);
                    }
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    updateData = async () => {
        try {
            const data = this.verifyUpdateRequired();
            console.log("data", data)
            if (data) {
                const { fields } = this.state;
                if (fields.file.value) {
                    data.append("is_file_updated", 1);
                } else {
                    data.append("is_file_updated", 0);
                }
                const { demandId } = this.state;
                const params = {};
                this.setState({ loadingForm: true });
                const response = await DemandDAO.updateDemand(demandId, params, data);
                console.log(response)
                this.setState({ loadingForm: false });
                if (response.data.status === "ok") {
                    notify({ msg: MessagesText.demand.update, type: "success" });
                    this.props.history.push(`${RoutesPath.demand.details}/${response.data.response.id}`);
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };

    render() {
        const id = this.props.match.params.id;
        const { successMessage, errorMessage, fields, contact, demandId, isLoaded, data, pageNumber, totalItems, loadingForm, demandNumber } = this.state;
        const PageTitle = id ? (demandNumber && demandNumber.length !== 0 ? `Modifier demande : ${demandNumber}` : "") : "Ajouter une nouvelle demande";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                {loadingForm && <FromLoading />}
                <TopOptions title={PageTitle}>
                    {!demandId && (
                        <button className="btn_white" data-shortcut_alias="create" onClick={() => this.saveData(true)}>
                            Sauvegarder & nouveau
                        </button>
                    )}
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (demandId ? this.updateData() : this.saveData(false))}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Content className="Content">
                    <Right_block_container>
                        <Traceability_block>
                            <Traceability_block_title>
                                <Num bgColor="#2fc5df">1</Num>Choisissez le contact
                            </Traceability_block_title>
                            <ul>
                                <Li_contact>
                                    <InputContainer>
                                        <ContactTablePopup
                                            name="contact"
                                            label={"Contact*"}
                                            setValue={(contact) => {
                                                this.setState({ contact, contactError: false }, this.getAll);
                                            }}
                                            reset={() => {
                                                this.setState({ contact: null });
                                            }}
                                            value={this.state.contact ? this.state.contact.fullname : ""}
                                            propertyName={"libelle"}
                                            error={this.state.contactError}
                                            modalTitle="Choisissez un contact"
                                        />
                                    </InputContainer>
                                </Li_contact>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                    <Left_block_container>
                        {!demandId && (
                            <Block paddingBottom>
                                <div className="bloc_header">
                                    <H2>
                                        <Num bgColor="#7bc752">2</Num> Avant de créer une nouvelle demande vérifier si elle n'existe pas déjà
                                    </H2>
                                    <div className="bloc_header_btns"></div>
                                </div>

                                {contact && (
                                    <Block_content pd={0}>
                                        <table className="table processableTable" id="table">
                                            <thead>
                                                <tr className="Demands__table-tr table_th_orderable">
                                                    <Th>N° demande</Th>
                                                    <Th>Sujet</Th>
                                                    <Th>Priorité</Th>
                                                    <Th>Dernière activité</Th>
                                                    <Th>Statut</Th>
                                                </tr>
                                            </thead>
                                            {!isLoaded && <Loading />}
                                            {isLoaded && data && data.length != 0 && (
                                                <tbody>
                                                    {data.map((item, index) => {
                                                        return (
                                                            <Tr key={"key_" + index}>
                                                                <Td
                                                                    onClick={() => {
                                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                                    }}
                                                                >
                                                                    {item && item.demandNumber}
                                                                </Td>
                                                                <Td
                                                                    onClick={() => {
                                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                                    }}
                                                                >
                                                                    {item && item.subject}
                                                                </Td>
                                                                <Td
                                                                    onClick={() => {
                                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                                    }}
                                                                >
                                                                    <PriorityContainer2 bgColor={PRIORITY_COLOR[item && item.priority]}>{item && item.priority}</PriorityContainer2>
                                                                </Td>
                                                                <Td
                                                                    onClick={() => {
                                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                                    }}
                                                                >
                                                                    {item && item.lastStatus && item.lastStatus.actionDate && moment(item.lastStatus.actionDate).format("YYYY-MM-DD HH:mm")}
                                                                </Td>
                                                                <Td
                                                                    onClick={() => {
                                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                                    }}
                                                                >
                                                                    <PriorityContainer bgColor={STATUS_COLOR[item && item.lastStatus && item.lastStatus.status]}> {item && item.lastStatus && item.lastStatus.status}</PriorityContainer>
                                                                </Td>
                                                            </Tr>
                                                        );
                                                    })}

                                                    <Tr>
                                                        <Td colSpan={8} className="table_pagination">
                                                            <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                                            <div style={{ display: "inline-block" }}>
                                                                {pageNumber > 1 && (
                                                                    <a
                                                                        className="sprite-table-prev-btn"
                                                                        onClick={() => {
                                                                            this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                                                this.getAll();
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                                <span className="table_page">{pageNumber}</span>
                                                                {pageNumber < totalItems / 20 && (
                                                                    <a
                                                                        className="sprite-table-next-btn"
                                                                        onClick={() => {
                                                                            this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                                                this.getAll();
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Td>
                                                    </Tr>
                                                </tbody>
                                            )}
                                        </table>
                                        {isLoaded && data && data.length === 0 && <NoResultCas textInfos={`Vous n’avez aucune demande pour le contact "${contact.fullname}"`}></NoResultCas>}
                                    </Block_content>
                                )}
                            </Block>
                        )}
                        <Block>
                            <div className="bloc_header">
                                <H2>
                                    <Num bgColor="#ff6163">{demandId ? 2 : 3}</Num>Ajouter une nouvelle demande
                                </H2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content_second>
                                <Title_container>
                                    <Title>Informations principales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <FormContainer>
                                    <Left>
                                        <InputContainer>
                                            <CustomInput
                                                error={fields.object.error}
                                                onChange={(Value) => {
                                                    this.setFieldError("object", false);
                                                    this.setField("object", Value.target.value);
                                                }}
                                                value={fields.object.value}
                                                defaultValue={fields.object.value}
                                                id="object_id"
                                                label="Objet*"
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <CustomFileInput
                                                value={fields.file.name}
                                                onChange={(Value) => {
                                                    this.setField("file", Value.target.files[0]);
                                                    this.setFieldName("file", Value.target.files[0].name);
                                                    this.setFieldError("file", false);
                                                }}
                                                id="file"
                                                error={fields.file.error}
                                                id="file"
                                                label={"Fichier"}
                                            ></CustomFileInput>
                                        </InputContainer>
                                        <InputContainer>
                                            <CustomSelect
                                                error={fields.incident.error}
                                                onChange={(Value) => {
                                                    this.setFieldError("incident", false);
                                                    this.setField("incident", Value.target.value);
                                                }}
                                                selectedValue={fields.incident.value}
                                                label="Incident*"
                                            >
                                                <option defaultValue></option>
                                                <option value="Produit">Produit</option>
                                                <option value="Prestation">Prestation</option>
                                                <option value="Relationnel">Relationnel</option>
                                            </CustomSelect>
                                        </InputContainer>
                                    </Left>
                                    <Right>
                                        <InputContainer>
                                            <SearchSelect
                                                fetchedUrl="/api/utilisateurs"
                                                HTTPMethod="post"
                                                receivedObject="utilisateurs"
                                                optionLabelProperty="fullname"
                                                optionValueProperty="id"
                                                value={this.state.selectedManager}
                                                placeholder="Gestionnaire*"
                                                onChange={(e) => {
                                                    this.setState({ selectedManager: e });
                                                    this.setField("manager", e && e.value);
                                                    this.setFieldError("manager", false);
                                                }}
                                                error={fields.manager.error}
                                                disabled={id ? true : false}
                                            />
                                        </InputContainer>
                                        <InputContainer>
                                            <CustomSelect
                                                error={fields.channel.error}
                                                onChange={(Value) => {
                                                    this.setFieldError("channel", false);
                                                    this.setField("channel", Value.target.value);
                                                }}
                                                selectedValue={fields.channel.value}
                                                label="Canal*"
                                            >
                                                <option defaultValue value="Téléphone">Téléphone</option>
                                                <option value="E-mail">E-mail</option>
                                                <option value="Equipe terrain">Équipe terrain</option>
                                                <option value="Réseaux sociaux">Réseaux sociaux</option>
                                            </CustomSelect>
                                        </InputContainer>
                                        <InputContainer>
                                            <CustomSelect
                                                error={fields.priority.error}
                                                onChange={(Value) => {
                                                    this.setFieldError("priority", false);
                                                    this.setField("priority", Value.target.value);
                                                }}
                                                selectedValue={fields.priority.value}
                                                label="Priorité*"
                                            >
                                                <option defaultValue value="Normale">Normale</option>
                                                <option value="Urgente">Urgente</option>
                                                <option value="Critique">Critique</option>
                                            </CustomSelect>
                                        </InputContainer>
                                    </Right>
                                </FormContainer>

                                {/* desc info */}

                                <Title_containerMiddle>
                                    <Title>Description</Title>
                                    <Bar></Bar>
                                </Title_containerMiddle>
                                <FormContainer>
                                    <FullRow>
                                        <CustomInput
                                            disabled={demandId ? true : false}
                                            type="textarea"
                                            error={false}
                                            onChange={(Value) => {
                                                this.setField("desc", Value.target.value);
                                                this.setFieldError("desc", false);
                                            }}
                                            value={fields.desc.value}
                                            defaultValue={fields.desc.value}
                                            id="desc"
                                            label="Description"
                                        ></CustomInput>
                                    </FullRow>
                                </FormContainer>

                                {/* end desc infos  */}
                            </Block_content_second>
                        </Block>
                    </Left_block_container>
                </Content>
            </Main_container>
        );
    }
}

export default AddDemand;

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: ${(props) => (props.paddingBottom ? "0 px" : "10px")};
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 0;
`;
const Block_content_second = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;

const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;

const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-right: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: ${(props) => (props.bgColor ? props.bgColor : "#f0f0f0")};
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
`;
const Num = styled.span`
    height: 31px;
    width: 31px;
    background-color: ${(props) => props.bgColor};
    margin-left: ${(props) => (props.marginLeft ? "8px" : 0)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    margin-right: 8px;
    
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Li_contact = styled.li`
    padding: 10px;
    padding-top: 12px;
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
const H2 = styled.h2`
    display: flex;
    align-items: center;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
    max-width: 199px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const PriorityContainer = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width:70px;
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-around")};
`;
export const InputContainer = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "41px")};
    margin-bottom: 18px;
`;

export const Left = styled.div`
    padding-top: 38px;
    width: 48%;
`;

export const Right = styled.div`
    padding-top: 38px;
    width: 48%;
`;

const Title_containerMiddle = styled.div`
    position: relative;
    margin-top: 23px;
`;
const PriorityContainer2 = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width:78px;
`;


const Loading = () => (
    <tbody>
        {[1, 2, 3].map((index) => {
            return (
                <Tr key={"key__" + index}>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                </Tr>
            );
        })}
    </tbody>
);

class NoContactSpecified extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { type } = this.props;
        return (
            <NoResultCasContainer>
                <svg width={35} height={35} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" className="svg-inline--fa fa-exclamation-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        fill={"#ffa902"}
                        d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    ></path>
                </svg>
                <TextInfos>
                    Choisir un contact dans l'étape{" "}
                    <Num marginLeft={true} bgColor="#2fc5df">
                        1
                    </Num>
                </TextInfos>
            </NoResultCasContainer>
        );
    }
}

const NoResultCasContainer = styled.div`
    height: 235px;
    background-color: #f8f8f8;
    text-align: center;
    padding-top: 40px;
    border-top-color: #8d8d8d;
    border-top-width: 1px;
    border-top-style: solid;
`;

const TextInfos = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    text-align: center;
    color: #5c5c5c;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoResultCasBtn = styled.button`
    width: 165px;
    height: 25px;
    border-radius: 3px;
    background-color: #00beb0;
    text-align: center;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 12.5px;
    margin-top: 25px;
    border-width: 0;
    cursor: pointer;
`;

