import ApiCall from "../services/ApiCall";

class ResourceDAO {
    // get all resources (pharmacies , association ... )

    static getALLResource = (resourceType, pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/${resourceType}/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all resources (pharmacies , association ... )

    static getALLResourceSuggestion = (resourceType, pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/${resourceType}/suggestions/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all resources (pharmacies , association ... )

    static addResourceSuggestion = (resourceType, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await ApiCall.post(`/api/${resourceType}suggestion`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all resources (pharmacies , association ... )

    static getResourceSuggestionById = (resourceType, id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/${resourceType}/suggestion/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get resource by id
    static getResourceById = (resourceType, id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/${resourceType}/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    static getResourceSearchData({ name, city, sector, type, advancedSearch, search, name_operator, type_operator, city_operator, sector_operator, specialite, specialite_operator }) {
        const data = {};
        if (name && name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: name,
                    op: "smart",
                };
            } else if (advancedSearch && name_operator.length !== 0) {
                data["nom"] = {
                    value: name,
                    op: name_operator,
                };
            }
        }
        if (type && type.length !== 0) {
            if (search) {
                data["type"] = {
                    value: type,
                    op: "is",
                };
            } else if (advancedSearch && type_operator.length !== 0) {
                data["type"] = {
                    value: type,
                    op: type_operator,
                };
            }
        }
        if (city && city.length !== 0) {
            if (search) {
                data["ville"] = {
                    value: city,
                    op: "is",
                };
            } else if (advancedSearch && city_operator.length !== 0) {
                data["ville"] = {
                    value: city,
                    op: city_operator,
                };
            }
        }
        if (sector && sector.length !== 0) {
            if (search) {
                data["secteur"] = {
                    value: sector,
                    op: "is",
                };
            } else if (advancedSearch && sector_operator.length !== 0) {
                data["secteur"] = {
                    value: sector,
                    op: sector_operator,
                };
            }
        }
        if (specialite && specialite.length !== 0) {
            if (search) {
                data["specialite"] = {
                    value: specialite,
                    op: "is",
                };
            } else if (advancedSearch && specialite_operator.length !== 0) {
                data["specialite"] = {
                    value: specialite,
                    op: specialite_operator,
                };
            }
        }

        return data;
    }

    //get resource details

    static getResourceById = (resourceType, id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/${resourceType}/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
}

export default ResourceDAO;
