import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import "./HeaderItem.css";

export const HeaderItem = (props) => {
    const { to, itemTitle, className, name, style } = props;

    return (
        <LiContainer style={style} name={name} className={className && className}>
            <NavLink exact={to === "/" ? true : false} to={to}>
                <Li onClick={() => {}}>
                    <Text>{itemTitle}</Text>
                </Li>
            </NavLink>
            {props.children}
        </LiContainer>
    );
};

HeaderItem.protoTypes = {
    to: PropTypes.string,
    itemTitle: PropTypes.string,
    itemIndex: PropTypes.number,
};

const Li = styled.li`
    display: inline-block;
`;

const Text = styled.span`
    padding: 5px 8px 5px 8px;
    background-color: transparent;
    border-radius: 3px;
    color: #fff;
    transition: all ease-in-out 0.2s;
    margin: 0 2px;
    line-height: 50px;
    font-weight: 900;
    outline: 0;
    text-decoration: none;
    &:hover {
        color: #037d74;
    }
`;

const LiContainer = styled.div`
    display: inline-block;
    &:hover {
        position: relative;
        > div {
            z-index: 99;
            position: absolute;
            top: 71%;
            left: 15%;

            display: block;
        }
    }
`;

export default HeaderItem;
