import ApiCall from "../services/ApiCall";

class EventDAO {

    // for getting all events limited by page number 

   static getALLEvents = (pageNumber,params,data) => new Promise(async(resolve, reject) => {
       try{
          const response = await ApiCall.post(`/api/events/${pageNumber}`,params,data)
          resolve(response);
       }catch(error){
            reject(error)
       }
        
    });


}

export default EventDAO;