import ApiCall from "../services/ApiCall";

class UserDAO {
    // get all users

    static getALLUsers = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/utilisateurs/" + pageNumber, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all connections history

    static getALLConnectionsHistory = (pageNumber, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/user/historiqueconnexion/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get user by id

    static getUserById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/utilisateur/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all connections history

    static getConnectedUserInfos = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/user/infos`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static testSearchField(fieldObject, defaultOperator, searchType) {
        if (fieldObject.value && fieldObject.value.length !== 0) {
            if (searchType === "advanced" && fieldObject.operator && fieldObject.operator.length !== 0) {
                return {
                    value: fieldObject.value,
                    op: fieldObject.operator,
                };
            }

            return {
                value: fieldObject.value,
                op: defaultOperator,
            };
        }
        return null;
    }

    static getSearchData({ searchFields, searchType, searchToggle }) {
        let data = {};
        if (searchToggle) {
            const name = UserDAO.testSearchField(searchFields.name, "smart", searchType);
            if (name) {
                data["nom"] = name;
            }
            const firstName = UserDAO.testSearchField(searchFields.firstName, "smart", searchType);
            if (firstName) {
                data["prenom"] = firstName;
            }
            const isAdmin = UserDAO.testSearchField(searchFields.isAdmin, "is", searchType);
            if (isAdmin) {
                data["is_admin"] = isAdmin;
            }
            const isSupervisor = UserDAO.testSearchField(searchFields.isSupervisor, "is", searchType);
            if (isSupervisor) {
                data["is_superviseur"] = isSupervisor;
            }
        }
        return data;
    }
}

export default UserDAO;
