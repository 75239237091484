import React, { Component } from "react";
import SuccessMessageComp from "../components/SuccessMessageComp";
import ErrorMessageComp from "../components/ErrorMessageComp";

class FlashMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { successMessage, errorMessage } = this.props;
        return (
            <div>
                {successMessage && successMessage.length !== 0 && <SuccessMessageComp successMessage={successMessage}></SuccessMessageComp>}
                {errorMessage && errorMessage.length !== 0 && <ErrorMessageComp errorMessage={errorMessage}></ErrorMessageComp>}
            </div>
        );
    }
}

export default FlashMessages;
