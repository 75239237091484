import React, { Component } from "react";
import styled from "styled-components";
import { RoutesPath } from "../values/RoutesPath";
import { withRouter } from "react-router";
import { VscChevronUp, VscChevronDown } from "react-icons/vsc";
import { acl } from "../services/acl";
import { MANAGE_PROFILE_URL } from "../env";

class SideBarMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: 1,
            showParameter: false,
            showUsers: false,
            showParameterApp: false,
            showParameterACL: false,
            showProduct: false,
            showEmailParams: false,
        };
    }
    componentDidMount() {
        const { openedUrl } = this.props;

        switch (openedUrl) {
            case 1:
                this.setState({ showParameter: true });
                break;
            case 2:
                this.setState({ showUsers: true });
                break;
            case 3:
                this.setState({ showEmailParams: true });
                break;
            case 4:
                this.setState({ showProduct: true });
                break;
            case 5:
                this.setState({ showParameterACL: true });
                break;
        }
    }
    render() {
        const { showParameter, showUsers, showParameterACL, showProduct, showEmailParams } = this.state;

        return (
            <Side_bar_block>
                <ul>
                    <Li background={showParameter ? "#f6f6f6" : "#fff"}>
                        <Li_container onClick={() => this.setState({ showParameter: !showParameter })}>
                            <Col_1_li>Mes paramètres</Col_1_li>
                            <Angle_icon>{showParameter ? <VscChevronDown size={20} color="#494949" /> : <VscChevronUp size={20} color="#494949" />}</Angle_icon>
                        </Li_container>
                        <Under_list display={showParameter ? "block" : "none"}>
                            <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.profile.list}`)}>Profil</Under_list_li>
                            <Under_list_li>
                                <Link_to href={`${MANAGE_PROFILE_URL}/change-password`} target="_blank">
                                    Changer mon mot de passe
                                </Link_to>
                            </Under_list_li>
                        </Under_list>
                    </Li>
                    {(acl.setting.user.list() || acl.setting.user.connection_history()) && (
                        <Li background={showUsers ? "#f6f6f6" : "#fff"}>
                            <Li_container onClick={() => this.setState({ showUsers: !showUsers })}>
                                <Col_1_li>Utilisateurs</Col_1_li>
                                <Angle_icon>{showUsers ? <VscChevronDown size={20} color="#494949" /> : <VscChevronUp size={20} color="#494949" />}</Angle_icon>
                            </Li_container>
                            <Under_list display={showUsers ? "block" : "none"}>
                                {acl.setting.user.list() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.user.list}`)}>Liste des utilisateurs</Under_list_li>}
                                {acl.setting.user.connection_history() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.user.connection_history}`)}>Historiques des connexions</Under_list_li>}
                            </Under_list>
                        </Li>
                    )}
                    {/* <Li background={open == 3 ? "#f6f6f6" : "#fff"}>
                        <Li_container onClick={() => this.setState({ open: 3 })}>
                            <Col_1_li>Paramètres de l'application</Col_1_li>
                            <Angle_icon>
                                {open == 3 ? (
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" className="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="#494949" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                    </Svg>
                                ) : (
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" className="svg-inline--fa fa-angle-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="#494949" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
                                    </Svg>
                                )}
                            </Angle_icon>
                        </Li_container>
                        <Under_list display={open == 3 ? "block" : "none"}>
                            <Under_list_li>Paramètres de l'entreprise</Under_list_li>
                            <Under_list_li>Logo de l'entreprise</Under_list_li>
                            <Under_list_li>En-tête du PDF</Under_list_li>
                            <Under_list_li>Pied de page du PDF</Under_list_li>
                        </Under_list>
                    </Li> */}
                    {(acl.demand.listEmailAddress() || acl.demand.listModalEmailAddress()) && <Li background={showEmailParams ? "#f6f6f6" : "#fff"}>
                        <Li_container onClick={() => this.setState({ showEmailParams: !showEmailParams })}>
                            <Col_1_li>Paramètres d'emails</Col_1_li>
                            <Angle_icon>{showEmailParams ? <VscChevronDown size={20} color="#494949" /> : <VscChevronUp size={20} color="#494949" />}</Angle_icon>
                        </Li_container>
                        <Under_list display={showEmailParams ? "block" : "none"}>
                            {acl.demand.listEmailAddress() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.email_params.incoming_email.list}`)}>E-mails entrants</Under_list_li>}
                            {acl.demand.listModalEmailAddress() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.email_params.outgoing_email.list}`)}>E-mails sortants</Under_list_li>}
                        </Under_list>
                    </Li>}
                    {acl.setting.product.range.list() && (
                        <Li background={showProduct ? "#f6f6f6" : "#fff"}>
                            <Li_container onClick={() => this.setState({ showProduct: !showProduct })}>
                                <Col_1_li>Paramètres de produits</Col_1_li>
                                <Angle_icon>{showProduct ? <VscChevronDown size={20} color="#494949" /> : <VscChevronUp size={20} color="#494949" />}</Angle_icon>
                            </Li_container>
                            <Under_list display={showProduct ? "block" : "none"}>
                                <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.product.range.list}`)}> Gammes de produits actives</Under_list_li>
                            </Under_list>
                        </Li>
                    )}
                    {(acl.setting.acl_roles.list() || acl.setting.acl_methods.list()) && (
                        <Li background={showParameterACL ? "#f6f6f6" : "#fff"}>
                            <Li_container onClick={() => this.setState({ showParameterACL: !showParameterACL })}>
                                <Col_1_li>ACL</Col_1_li>
                                <Angle_icon>{showParameterACL ? <VscChevronDown size={20} color="#494949" /> : <VscChevronUp size={20} color="#494949" />}</Angle_icon>
                            </Li_container>
                            <Under_list display={showParameterACL ? "block" : "none"}>{acl.setting.acl_roles.list() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.acl_roles.list}`)}>Rôles</Under_list_li>}</Under_list>
                            <Under_list display={showParameterACL ? "block" : "none"}>{acl.setting.acl_methods.list() && <Under_list_li onClick={() => this.props.history.push(`${RoutesPath.setting.acl_methods.list}`)}>Méthodes</Under_list_li>}</Under_list>
                        </Li>
                    )}
                </ul>
            </Side_bar_block>
        );
    }
}

export default withRouter(SideBarMenu);

const Main_container = styled.div`
    height: 100%;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
`;
const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-right: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Side_bar_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;

const Li = styled.li`
    cursor: pointer;
    position: relative;
    background: ${(props) => props.background};
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #00beb0;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Angle_icon = styled.div`
    position: absolute;
    width: 13px;
    top: 10px;
    right: 10px;
    height: 10px;
`;
const Svg = styled.svg`
    width: 12px;
`;

const Under_list = styled.ul`
    width: 100%;
    padding-bottom: 4px;
    background: #fff;
    display: ${(props) => props.display};
`;

const Li_container = styled.div`
    width: 100%;
    border-top: 1px solid #e5e5e5;
    padding: 10px 5px;
    cursor: pointer;
    &:hover {
        background: #f6f6f6;
    }
`;
const Under_list_li = styled.li`
    display: block;
    padding: 2px 9px;
    color: #00beb0;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 3px;
    &:hover {
        color: #494949;
    }
`;
const Link_to = styled.a`
    color: #00beb0;

    &:hover {
        color: #494949;
    }
    &:visited {
        color: #494949;
    }
`;
const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;

const Title_container = styled.div`
    position: relative;
`;

const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
