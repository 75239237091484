
 
 const initialState = {settingsTopOptionsBtn:[]}

 function  SettingsTopOptionsBtn(state =  initialState,action){
    let nextState;
    switch(action.type){
         case "SETTINGS_TOP_OPTIONS_BTN":
            nextState = {
                ...state,
                settingsTopOptionsBtn:action.value 
            }
              return nextState || state;
        default:
            return state;
    }
}
export default SettingsTopOptionsBtn;