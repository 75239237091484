import React, { Component } from "react";
import Icons from "../images/sprite_5.png";
import styled from "styled-components";

class ContactBtns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPop_up: false,
        };
    }
    render() {
        const { showPop_up } = this.state;
        return (
            <FooterBtns>
                {showPop_up && (
                    <Pop_up_container>
                        <HeaderContainer>
                            <HeaderText>Comment pouvons-nous vous aider ?</HeaderText>
                        </HeaderContainer>
                        <ContentContainer>
                            <CircleIcon>
                                <Img src={require("../images/Dr_Salim_Headset.png")}></Img>
                            </CircleIcon>
                            <ContentText>Pour toute question appelez-nous:</ContentText>
                            <ContentNumber>05 30 500 500</ContentNumber>
                        </ContentContainer>
                    </Pop_up_container>
                )}
                <Ul>
                    <Li>
                        <Contact_btn backgroundColor={showPop_up ? "#04beb1" : "#fff"} onClick={() => this.setState({ showPop_up: showPop_up ? false : true })}>
                            {showPop_up ? (
                                <IconContainer>
                                    <svg width={20} height={20} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                        <path
                                            fill="#04beb1"
                                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                        ></path>
                                    </svg>
                                </IconContainer>
                            ) : (
                                <Contact_icon />
                            )}

                            <Text color={showPop_up ? "#fff" : "rgb(80, 80, 80)"}>Contacter Sobrus</Text>
                        </Contact_btn>
                    </Li>
                    {/* <Li>
                            <Help_btn>
                            <Help_icon/><Text>Aide</Text>
                            </Help_btn>
                        </Li>
                        <Li>
                            <Notification_btn>
                            0
                            </Notification_btn>
                        </Li> */}
                </Ul>
            </FooterBtns>
        );
    }
}
const IconContainer = styled.span`
    display: inline-block;
    vertical-align: top;
    width: 33px;
    height: 31px;
    background-color: #fff;
    border-radius: 330px;
    position: absolute;
    top: 13%;
    left: 7%;
    text-align: center;
    padding-top: 6px;
`;
const ContentNumber = styled.div`
    color: #04beb1;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    margin-top: 12px;
`;

const ContentText = styled.div`
    color: #585858;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin-top: 17px;
`;

const Img = styled.img`
    width: 105px;
    margin-top: 7px;
`;

const CircleIcon = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 1200px;
    background: #fcf7fe;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    text-align: center;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: calc(100% - 57px);
    padding-top: 40px;
`;

const HeaderText = styled.p`
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
`;

const HeaderContainer = styled.div`
    width: 100%;
    height: 57px;
    background-color: #c557d4;
    padding-top: 16px;
    ${"" /* padding-left: 17px; */}
`;
const Pop_up_container = styled.div`
    width: 313px;
    height: 400px;
    background-color: #fff;
    box-shadow: 0 2px 14px -2px #bcbcbc;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
`;

const FooterBtns = styled.div`
    position: fixed;
    z-index: 99;
    bottom: 2.5%;
    right: 29px;
    ${"" /* width: 336px; */}
`;
const Ul = styled.ul`
    list-style: none;
    display: inline-block;
    height: 44px;
    float: right;
`;
const Li = styled.li`
    display: inline-block;
    height: 44px;
    ${"" /* margin-right:10px; */}
`;
const Contact_btn = styled.a`
    display: inline-block;
    background-color:  ${(props) => props.backgroundColor}
    position:relative;
    width: 177px;
    height: 44px;
    border-radius: 50px 50px 50px 50px;
    cursor: pointer;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    transition: all ease-in-out .2s;
`;

const Contact_icon = styled.i`
    width: 33px;
    height: 31px;
    background-image: url(${Icons});
    background-repeat: no-repeat;
    background-position: -453px -538px;
    position: absolute;
    top: 13%;
    left: 7%;
`;

const Text = styled.span`
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;    font-size: 14px;
    margin-top: 12px;
    margin-left: 47px;
    color: ${(props) => props.color};
`;
export default ContactBtns;
