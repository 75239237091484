import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import { connect } from "react-redux";
import SideBarMenu from "../helpers/SideBarMenu";
import { withRouter } from "react-router";
import { compose } from "redux";
class SettingsLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: 1,
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SELECT_HEADER_ITEM", value: 9 });

        document.title = "Paramètres - Sobrus Labs";
    }
    render() {
        const { settingsTopOptionsBtn } = this.props;
        return (
            <Main_container className="Main_container">
                <TopOptions title="Profil">
                    {settingsTopOptionsBtn.map((btn, index) => (
                        <button key={`settingsTopOptionsBtn_${index}`} className="btn_bleu" data-shortcut_alias="create" onClick={btn.onClick}>
                            {btn.label}
                        </button>
                    ))}
                    {/* <button className="btn_bleu" data-shortcut_alias="create" onClick={()=>this.props.history.push("/contacts")}>
                               test test
                    </button> */}
                </TopOptions>
                <Content className="Content">
                    <Right_block_container>
                        <SideBarMenu openedUrl={this.props.openedUrl} />
                    </Right_block_container>
                    <Left_block_container>{this.props.children}</Left_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
// export default connect(mapStateToProps)(withRouter(SettingsLayout));
export default compose(withRouter, connect(mapStateToProps))(SettingsLayout);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-right: 27px;
`;
