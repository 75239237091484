import Store from "../store/configureStore";
class Acl {
    constructor() { }
    static can(action) {
        const state = Store.getState();
        const acl_actions = state.ACL.acl_actions;

        const connectedUserInfos = state.User.connectedUserInfos;
        if (connectedUserInfos && connectedUserInfos.is_admin && connectedUserInfos.is_admin === true) {
            return true;
        } else {
            let can = acl_actions.some((object) => object.action === action);
            if (can) {
                return true;
            }
            return false;
        }
    }
}

export const acl = {
    notification: {
        list: () => Acl.can("api_app_request_getnotifications"),
        list_history: () => Acl.can("api_app_request_getnotificationshistory"),
        put_notification_as_seen: () => Acl.can("api_app_request_putnotificationmarkasseen"),
        put_all_notifications_as_seen: () => Acl.can("api_app_request_putnotificationmarkallasseen"),
    },
    demand: {
        list: () => Acl.can("api_app_request_postdemandes"),
        add: () => Acl.can("api_app_request_postdemande"),
        update: () => Acl.can("api_app_request_postupdatedemande"),
        details: () => Acl.can("api_app_request_getdemande"),
        disable: () => Acl.can("api_app_request_putarchiveupdatedemande"),
        restore: () => false,
        resolve: () => Acl.can("api_app_request_putresolveupdatedemande"),
        assigned: () => Acl.can("api_app_request_putassignupdatedemande"),
        comment: () => Acl.can("api_app_request_postcommentdemande"),
        subscribe: () => Acl.can("api_app_request_putsubscribeupdatedemande"),
        unsubscribe: () => Acl.can("api_app_request_putunsubscribeupdatedemande"),
        sendEmail: () => Acl.can("api_app_request_postmail"),
        addModel: () => Acl.can("api_app_request_postmodelresponse"),
        updateModel: () => Acl.can("api_app_request_putmodelresponse"),
        detailsModel: () => Acl.can("api_app_request_getmodelresponse"),
        addEmailAddress: () => Acl.can("api_app_request_putupdateemailconfig"),
        listEmailAddress: () => Acl.can("api_app_request_getemailconfig"),
        listModalEmailAddress: () => Acl.can("api_app_request_getallmodelsresponses"),
    },
    report: {
        home: () => true,
        purchase_orders: () => true,
        contact_by_user: () => Acl.can("api_app_rapport_getrapportparcontactcreation"),
        visits: () => Acl.can("api_app_rapport_getrapportparstatut"),
        visitor_by_channel: () => Acl.can("api_app_rapport_getrapportparcanal"),
        visitor_by_purpose: () => Acl.can("api_app_rapport_getrapportparobjectif"),
        visitor_by_contact_category: () => Acl.can("api_app_rapport_getrapportparcontact"),
        inventory_by_account: () => Acl.can("api_app_rapport_getrapportpareventtype"),
        visitsbycompetitorandsector: () => true,
    },
    dashboard: {
        list: "/",
    },
    account: {
        list: () => Acl.can("api_app_compte_getcomptes"),
        details: () => Acl.can("api_app_compte_getcompte"),
        add: () => Acl.can("api_app_compte_postcompte"),
        update: () => Acl.can("api_app_compte_putcompte"),
        disable: () => Acl.can("api_app_compte_disablecompte"),
        restore: () => Acl.can("api_app_compte_disablecompte"),
        contacts: () => Acl.can("api_app_compte_getcomptecontact"),
        events: () => Acl.can("api_app_compte_getcompteevent"),
        photos: () => Acl.can("api_app_compte_getcomptephoto"),
        inventories: () => Acl.can("api_app_compte_getcompteinventaire"),
        demands: () => true,

    },
    contact: {
        list: () => Acl.can("api_app_contact_getcontacts"),
        details: () => Acl.can("api_app_contact_getcontact"),
        add: () => Acl.can("api_app_contact_postcontact"),
        disable: () => Acl.can("api_app_contact_disablecontact"),
        update: () => Acl.can("api_app_contact_putcontact"),
        restore: () => Acl.can("api_app_contact_putcontact"),
        demands: () => true,
    },
    inventory: {
        list: () => Acl.can("api_app_inventaire_getinventaires"),
        details: () => Acl.can("api_app_inventaire_getinventaire"),
        add: () => Acl.can("api_app_inventaire_postinventaire"),
        update: () => Acl.can("api_app_inventaire_putinventaire"),
        product_list: () => Acl.can("api_app_inventaire_getlistdetailinventaire"),
        update_inventory_product: () => Acl.can("api_app_inventaire_insertproduct"),
        update_state: () => Acl.can("api_app_inventaire_putinventairestatut"),
    },
    calender: {
        list: () => Acl.can("api_app_event_getallevents"),
        list_month: () => Acl.can("api_app_event_getallevents"),
        list_week: () => Acl.can("api_app_event_getallevents"),
        details: () => Acl.can("api_app_event_getevent"),
        add: () => Acl.can("api_app_event_postevent"),
        update: () => Acl.can("api_app_event_putevent"),
        report: () => Acl.can("api_app_event_putreporterevent"),
        disable: () => Acl.can("api_app_event_disableevent"),
        restore: () => Acl.can("api_app_event_disableevent"),
    },
    crEvent: {
        list: () => Acl.can("api_app_event_getcrevents"),
        details: () => Acl.can("api_app_event_getcrevent"),
        add: () => Acl.can("api_app_event_putcrevent"),
        update: () => Acl.can("api_app_event_putcrevent"),
    },
    gallery: {
        list: () => Acl.can("api_app_photo_getgalerie"),
        details: () => Acl.can("api_app_photo_getphoto"),
        add: () => Acl.can("api_app_photo_postuploadimagecompte"),
        disable: () => Acl.can("api_app_photo_disablephoto"),
        restore: () => Acl.can("api_app_photo_disablephoto"),
        update: () => Acl.can("api_app_photo_putuploadimagecompte"),
    },
    product: {
        my_product: () => Acl.can("api_app_ressource_postproduitsbylabo"),
        list: () => Acl.can("api_app_ressource_getproduitlist"),
        details: () => Acl.can("api_app_ressource_getproduit"),
        suggestion: {
            list: () => Acl.can("api_app_ressource_getproduitsuggestionlist"),
            add: () => Acl.can("api_app_ressource_postproduitsuggestion"),
            details: () => Acl.can("api_app_ressource_getproduitsuggestion"),
        },
    },
    resource: {
        list_pharmacies: () => Acl.can("api_app_ressource_getpharmacies"),
        list_healthcareestablishments: () => Acl.can("api_app_ressource_getetablissements"),
        list_associations: () => Acl.can("api_app_ressource_getassociations"),
        pharmacy_details: () => Acl.can("api_app_ressource_getpharmacie"),
        healthcareestablishments_details: () => Acl.can("api_app_ressource_getetablissement"),
        associations_details: () => Acl.can("api_app_ressource_getassociation"),

        suggestion: {
            pharmacy: {
                list: () => Acl.can("api_app_ressource_getpharmaciessuggestion"),
                add: () => Acl.can("api_app_ressource_postpharmaciesuggestion"),
                details: () => Acl.can("api_app_ressource_getpharmaciesuggestion"),
            },
            healthcareestablishments: {
                list: () => Acl.can("api_app_ressource_getetablissementssuggestion"),
                add: () => Acl.can("api_app_ressource_postetablissementsuggestion"),
                details: () => Acl.can("api_app_ressource_getetablissementsuggestion"),
            },
            associations: {
                list: () => Acl.can("api_app_ressource_getassociationssuggestion"),
                add: () => Acl.can("api_app_ressource_postassociationsuggestion"),
                details: () => Acl.can("api_app_ressource_getassociationsuggestion"),
            },
        },
    },
    planning: {
        list: () => Acl.can("api_app_planning_getallplannings"),
        details: () => Acl.can("api_app_planning_getplanning"),
        add: () => Acl.can("api_app_planning_postplanning"),
        update: () => Acl.can("api_app_planning_putplanning"),
        duplicate: () => Acl.can("api_app_planning_postduplicateplanning"),
        event_to_planning: () => Acl.can("api_app_planning_putplanningevents"),
        new_event_to_planning: () => Acl.can("api_app_planning_postplanningressources"),
        delete: () => Acl.can("api_app_planning_disableplanning"),
        restore: () => Acl.can("api_app_planning_disableplanning"),
        duplicate: () => Acl.can("api_app_planning_postduplicateplanning"),
        events: () => Acl.can("api_app_event_geteventsbyplanning"),
    },
    setting: {
        user: {
            list: () => Acl.can("api_app_user_getusers"),
            connection_history: () => Acl.can("api_app_user_gethistory"),
            affect_role: () => Acl.can("api_app_access_setrole"),
            update: () => Acl.can("api_app_access_setrole"),
        },
        acl_roles: {
            list: () => Acl.can("api_app_access_getroles"),
            add: () => Acl.can("api_app_access_postrole"),
            update: () => Acl.can("api_app_access_putrole"),
            affect_methods: () => Acl.can("api_app_access_putrolemethods"),
            defaultRoles: () => Acl.can("api_app_access_postrolepardefaut"),
            getDefaultRoles: () => Acl.can('api_app_access_getrolespardefaut')
        },
        acl_methods: {
            list: () => Acl.can("api_app_access_getmethods"),
            affect_to_role: () => Acl.can("api_app_access_putrolemethods"),
        },
        product: {
            range: {
                list: () => Acl.can("api_app_ressource_postgammesactives"),
                update: () => Acl.can("api_app_ressource_insertorupdategammesactives"),
            },
        },
    },
    other: {
        list: () => Acl.can("authorized"),
    },
    publication: {
        list: () => Acl.can("authorized"),
    },
    sales: {
        list: () => Acl.can("authorized"),
    },
    mobile_version: {
        list: () => Acl.can("authorized"),
    },
};
export default Acl;
