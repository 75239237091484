const initialState = { acl_actions: [] };

function accessControl(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "ADD_ALL_ACL_ACTIONS":
            nextState = {
                ...state,
                acl_actions: [...action.value],
            };
            return nextState || state;
        case "ADD_ACL_ACTION":
            nextState = {
                ...state,
                acl_actions: [...state.acl_actions, action.value],
            };
            return nextState || state;
        default:
            return state;
    }
}
export default accessControl;
