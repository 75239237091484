import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import Menu from "../helpers/Menu";
import Post from "../helpers/Post";
import DailyEvents from "../helpers/DailyEvents";
import InfiniteScroll from "react-infinite-scroll-component";
import PostsLoader from "../helpers/PostsLoader";
import staticPosts from "../values/posts.json";

const posts = staticPosts.reverse();
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            length: 10,
            items: [...posts.slice(0, 10)],
        };
    }
    componentDidMount() {
        // (posts);
        document.title = "Tableau de bord - Sobrus Labs";
    }
    fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        setTimeout(() => {
            this.setState({
                items: [...this.state.items, ...posts.slice(this.state.length, this.state.length + 10)],
                length: this.state.length + 10,
            });
        }, 1000);
    };
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Tableau de bord" />
                <Content className="Content">
                    <Menu />
                    <Post_container>
                        <InfiniteScroll dataLength={this.state.items.length} next={this.fetchMoreData} hasMore={true} loader={<PostsLoader></PostsLoader>}>
                            {this.state.items.map((object, index) => {
                                return <Post post={object} key={"key" + index} />;
                            })}
                        </InfiniteScroll>
                    </Post_container>
                    <DailyEvents {...this.props} />
                </Content>
                {/* <ContactBtns /> */}
            </Main_container>
        );
    }
}
const Post_container = styled.div`
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: 1%;
    min-height: auto;
`;
const Main_container = styled.div`
    height: 100%;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
`;

export default HomePage;
