import ApiCall from "../services/ApiCall";
import moment from "moment";

class GalleryDAO {
    // for getting all photos limited by page number

    static getALLPhotos = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/galeries/" + pageNumber, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add photo

    static addPhoto = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.upload("/api/galerie/upload/", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update photo

    static updatePhoto = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/galerie/update/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    //archive Account

    static deletePhoto = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/photo/disabled/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get photo details

    static getPhotoById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/photo/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static getSearchData({ account, name, date }) {
        const data = {};
        if (account && account.length !== 0) {
            data["compte_id"] = {
                value: account,
                op: "is",
            };
        }
        if (name && name.length !== 0) {
            data["nom"] = {
                value: name,
                op: "smart",
            };
        }
        if (date && date.length !== 0) {
            data["date"] = {
                value: moment(date).format("YYYY-MM-DD"),
                op: "is_date",
            };
        }
        return data;
    }

    static getSearchDataTable({ search, advancedSearch, account_id, product_id, user_id, name, download_at, date, download_at_operator, download_by_operator, product_id_operator, account_id_operator, date_operator, name_operator }) {
        const data = {};
        if (name && name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: name,
                    op: "smart",
                };
            } else if (advancedSearch && name_operator.length !== 0) {
                data["nom"] = {
                    value: name,
                    op: name_operator,
                };
            }
        }
        if (date && date.length !== 0) {
            if (search) {
                data["date"] = {
                    value: moment(date).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && date_operator.length !== 0) {
                data["date"] = {
                    value: moment(date).format("YYYY-MM-DD"),
                    op: date_operator,
                };
            }
        }
        if (account_id && account_id.length !== 0) {
            if (search) {
                data["compte_id"] = {
                    value: account_id,
                    op: "is",
                };
            } else if (advancedSearch && account_id_operator.length !== 0) {
                data["compte_id"] = {
                    value: account_id,
                    op: account_id_operator,
                };
            }
        }
        if (product_id && product_id.length !== 0) {
            if (search) {
                data["produit_id"] = {
                    value: product_id,
                    op: "is",
                };
            } else if (advancedSearch && product_id_operator.length !== 0) {
                data["produit_id"] = {
                    value: product_id,
                    op: product_id_operator,
                };
            }
        }
        if (user_id && user_id.length !== 0) {
            if (search) {
                data["telecharge_par"] = {
                    value: user_id,
                    op: "is",
                };
            } else if (advancedSearch && download_by_operator.length !== 0) {
                data["produit"] = {
                    value: product_id,
                    op: download_by_operator,
                };
            }
        }
        if (download_at && download_at.length !== 0) {
            if (search) {
                data["cree_le"] = {
                    value: moment(download_at).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && download_at_operator.length !== 0) {
                data["cree_le"] = {
                    value: moment(download_at).format("YYYY-MM-DD"),
                    op: download_at_operator,
                };
            }
        }
        return data;
    }
}

export default GalleryDAO;
