import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Auth from "../services/Auth";
const codes = {
    code1: "erreur de connexion  entre labs et account",
    code2: "format token invalid",
    code3: "refresh token deja revoké",
    code8: "Problème d'authentification",
    code4: "Votre compte Sobrus Labs semble être introuvable, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
    code5: "Votre compte Sobrus Labs semble être supprimé ou archivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
    code6: "Votre compte Sobrus Labs semble être désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
    code404: "Votre compte Sobrus Labs semble être introuvable, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
    code405: "Votre compte Sobrus Labs semble être supprimé ou archivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
    code406: "Votre compte Sobrus Labs semble être désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter le service client de Sobrus (support@sobrus.com)",
};
class FailedResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // render() {
    //     localStorage.clear();
    //     const params = new URL(window.location.href).searchParams;
    //     const error = params.get("error");
    //     return <ErrorPage msg={codes[error]} />;
    // }
    render() {
        localStorage.clear();
        const params = new URL(window.location.href).searchParams;
        const error = params.get("error");
        if (error === "code4" || error === "code5" || error === "code6" || error === "code404" || error === "code406" || error === "code405" || error === "code8") {
            return (
                <ErrorPage
                    onClick={
                        error === "code8"
                            ? () => {
                                  this.props.history.push("/login");
                              }
                            : undefined
                    }
                    msg={codes[error]}
                />
            );
        } else if (error === "code3") {
            this.props.history.push("/login");
        } else {
            Auth.logout();
        }
        return <div></div>;
    }
}
// render() {
//     localStorage.clear();
//     const params = new URL(window.location.href).searchParams;
//     const error = params.get("error");
//     if (error === "code4" || error === "code5" || error === "code6" || error === "code404" || error === "code406" || error === "code405") {
//         return <ErrorPage msg={codes[error]} />;
//     } else if (error === "code1" || error === "code2" || error === "code3") {
//         Auth.logout();
//     }
//     return <View></View>;
// }

export default FailedResponse;
