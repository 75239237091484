import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { withRouter } from "react-router-dom";
import ResourceSuggestionTable from "../components/ResourceSuggestionTable";
import { acl } from "../services/acl";
class AssociationSuggestionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Produits - Sobrus Labs";
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Suggestions d’associations">
                    {acl.resource.suggestion.associations.add && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.resource.suggestion.associations.add}`)}>
                            Suggérer une nouvelle association
                        </button>
                    )}
                </TopOptions>

                <ResourceSuggestionTable title="Suggestions d’associations" resourceType="associations" {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(withRouter(AssociationSuggestionPage));
