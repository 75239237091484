export const RoutesPath = {
    report: {
        home: "/reports",
        purchase_orders: "/reports/purchase_orders",
        contact_by_user: "/reports/contact_by_user",
        visits: "/reports/visits",
        visitor_by_channel: "/reports/visitor_by_channel",
        visitor_by_purpose: "/reports/visitor_by_purpose",
        visitor_by_contact_category: "/reports/visitor_by_contact_category",
        inventory_by_account: "/reports/inventory_by_account",
        visitsbycompetitorandsector: "/reports/visitsbycompetitorandsector",
    },
    notification: {
        list: "/notifications",
    },
    dashboard: {
        list: "/",
    },
    account: {
        list: "/accounts",
        details: "/accounts/details",
        add: "/accounts/add",
        update: "/accounts/update",
    },
    contact: {
        list: "/contacts",
        details: "/contacts/details",
        add: "/contacts/add",
        update: "/contacts/update",
    },
    inventory: {
        list: "/inventories",
        details: "/inventories/details",
        add: "/inventories/add",
        update: "/inventories/update",
        product_list: "/inventories/products",
        update_inventory_product: "/inventories/products/update",
    },
    calender: {
        list: "/events",
        list_month: "/events/month",
        list_week: "/events/week",
        details: "/events/details",
        add: "/events/add",
        update: "/events/update",
        report: "/events/report",
    },
    crEvent: {
        add: "/events/cr/add",
        update: "/events/cr/update",
    },
    gallery: {
        list: "/photos",
        details: "/photos/details",
        add: "/photos/add",
        update: "/photos/update",
    },
    product: {
        my_product: "/products",
        list: "/products/all",
        details: "/products/details",
        suggestion: {
            list: "/products/suggestions/list",
            add: "/products/suggestions/add",
            details: "/products/suggestions/details",
        },
    },
    resource: {
        list_pharmacies: "/resources",
        list_healthcareestablishments: "/resources/healthcareestablishments",
        list_associations: "/resources/associations",
        details: "/resources/details",
        suggestion: {
            pharmacy: {
                list: "/resources/suggestions/pharmacy/list",
                add: "/resources/suggestions/pharmacy/add",
                details: "/resources/suggestions/pharmacy/details",
            },
            healthcareestablishments: {
                list: "/resources/suggestions/healthcareestablishments/list",
                add: "/resources/suggestions/healthcareestablishments/add",
                details: "/resources/suggestions/healthcareestablishments/details",
            },
            associations: {
                list: "/resources/suggestions/associations/list",
                add: "/resources/suggestions/associations/add",
                details: "/resources/suggestions/associations/details",
            },
        },
    },
    planning: {
        list: "/plannings",
        details: "/plannings/details",
        add: "/plannings/add",
        update: "/plannings/update",
        duplicate: "/plannings/duplicate",
        event_to_planning: "/plannings/events",
        new_event_to_planning: "/plannings/new_events",
    },
    setting: {
        profile: {
            list: "/settings/profile",
        },
        user: {
            list: "/settings/users",
            connection_history: "/settings/connection_history",
            affect_role: "/settings/users/affect_role",
        },
        acl_roles: {
            list: "/settings",
            add: "/settings/acl/role/add",
            update: "/settings/acl/role/update",
        },
        email_params: {
            incoming_email: {
                list: "/settings/email_params/incoming_emails",
                add: "/settings/email_params/incoming_emails/add",
                update: "/settings/email_params/incoming_emails/update",
            },
            outgoing_email: {
                list: "/settings/email_params/outgoing_emails",
                add: "/settings/email_params/outgoing_emails/add",
                update: "/settings/email_params/outgoing_emails/update",
            }
        },
        acl_methods: {
            list: "/settings/methods",
            affect_to_role: "/settings/role/methods",
        },
        product: {
            range: {
                list: "/settings/products/range/list",
                update: "/settings/products/range/update",
            },
        },
    },
    other: {
        list: "/test",
    },
    publication: {
        list: "/publication",
    },
    sales: {
        list: "/sales",
    },
    mobile_version: {
        list: "/mobile_version",
    },
    demand: {
        add: "/demands/add",
        list: "/demands",
        details: "/demands/details",
        update: "/demands/update",
    },
};
