import React, { Component } from 'react';
import TopOptions from '../../components/TopOptions';
import { RoutesPath } from '../../values/RoutesPath';
import styled from "styled-components";
import "./Notifications.css"
import { IoIosPricetag } from "react-icons/io"
import NotificationDAO from '../../DAO/NotificationDAO';
import { notify } from '../../helpers/FlashMessage';
import moment from 'moment';
import { connect } from "react-redux";
import { acl } from '../../services/acl';
import { MessagesText } from '../../values/MessagesText';

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalItems: 0,
            showHistory: false,
            notificationHistory: [],
            disableBtn: false,
        };
    }
    componentDidMount() {
    }
    getNotificationHistory = async () => {
        try {
            const response = await NotificationDAO.getALLHistory({});
            this.setState({ notificationHistory: response.data.response.notifications })
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }
    markAllIsRead = async () => {
        try {
            this.props.dispatch({ type: "CLEAR_ALL_NOTIFICATIONS" })
            const response = await NotificationDAO.markeAllAsSeen({});
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }
    seeNotification = async (id, link) => {
        try {
            if (acl.demand.details()) {
                this.props.history.push(link);
            } else {
                notify({ msg: MessagesText.notification.unauthorized, type: "danger" })
            }
            this.setState({ disableBtn: true });
            const response = await NotificationDAO.seeNotification(id, {});
            this.setState({ disableBtn: false });
            if (response.data.status === "ok") {
                this.props.dispatch({ type: "DELETE_NOTIFICATION", value: { id } })
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }

    render() {
        const { data } = this.props;
        const { showHistory, notificationHistory, disableBtn } = this.state;
        return (
            <div>
                <TopOptions title="Liste des notifications">
                    {acl.notification.list_history() && <button className="btn_white" data-shortcut_alias="create" onClick={() => this.setState({ showHistory: !showHistory }, () => { this.state.showHistory && this.getNotificationHistory() })}>
                        {showHistory ? "Actuel" : "Historique"}
                    </button>}
                    {acl.notification.put_all_notifications_as_seen() && !showHistory && <button onClick={this.markAllIsRead} className="btn_red" data-shortcut_alias="create">
                        Marquer tout comme lu
                    </button>}
                </TopOptions>
                <Bloc className="Bloc">
                    <BlocContent className="bloc_content table_wrraper">
                        <ul className="notification_list">
                            {
                                showHistory ? notificationHistory.map((notification, index) => {
                                    return (<li>
                                        <a className="notification-item" onClick={() => {
                                            if (acl.demand.details()) {
                                                this.props.history.push(notification.link)
                                            } else {
                                                notify({ msg: MessagesText.notification.unauthorized, type: "danger" })
                                            }
                                        }}>
                                            <div className="notification_list_li_content">
                                                <em class="tag">
                                                    <IoIosPricetag size="2em" color="#fff" />
                                                </em>
                                                <div>
                                                    {notification.content}

                                                    <span>
                                                        {moment(notification.createdAt).format("DD MMM à HH:mm")}
                                                    </span>
                                                    <span className="Notification__is-seen">Vue</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>)
                                }) : data.map((notification, index) => {
                                    if (notification.isSeen) {
                                        return <div></div>
                                    } else {
                                        return (<li>
                                            <a className="notification-item" onClick={() => acl.notification.put_notification_as_seen() && !disableBtn && this.seeNotification(notification.id, notification.link)}>
                                                <div className="notification_list_li_content">
                                                    <em class="tag">
                                                        <IoIosPricetag size="2em" color="#fff" />
                                                    </em>
                                                    <div>
                                                        {notification.content}
                                                        <span>
                                                            {moment(notification.createdAt).format("DD MMM à HH:mm")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>)
                                    }
                                })
                            }
                        </ul>
                    </BlocContent>
                </Bloc>
            </div>
        );
    }
}

const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const BlocContent = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
    background-color:#fff;
    padding:10px !important;
    padding-top:22px !important;
`;
const mapStateToProps = (state) => {
    return ({
        data: state.Notification.notifications,
        totalItems: state.Notification.notificationTotalItem
    })
}
export default connect(mapStateToProps)(Notifications);