import React, { Component } from "react";
import { Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    barCodeImg: {
        height: 80,
        width: "50%",
    },
});
class BarCodeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    render() {
        return <Image style={styles.barCodeImg} source={require("../images/codeBar.png")}></Image>;
    }
}

export default BarCodeComponent;
