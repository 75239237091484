import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { RoutesPath } from "../values/RoutesPath";
import AccountDAO from "../DAO/AccountDAO";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import ContactDAO from "../DAO/ContactDAO";
import { notify } from "../helpers/FlashMessage";
import { connect } from "react-redux";
import Acl, { acl } from "../services/acl";

class ContactListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            pageNumber: 1,
            search: false,
            first_name: "",
            last_name: "",
            phone: "",
            category: "",
            totalItems: 0,
        };
    }
    componentDidMount() {
        // const contacts = this.props.data;
        // if (contacts) {
        //     this.setState({ contacts });
        // }
        const { contacts } = this.state;
        contacts.length === 0 && this.getContacts();
    }
    async getContacts() {
        try {
            const { accountId } = this.props;
            const { search, first_name, last_name, phone, category, pageNumber } = this.state;
            const data = ContactDAO.getSearchData({ search, first_name, last_name, phone, category });
            const limit = 5;
            const response = await AccountDAO.getContactsByAccountId(pageNumber, accountId, {}, data, limit);
            this.setState({ contacts: response.data.contacts, totalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1 }, () => this.getContacts());
        }
    }
    resetSearch() {
        this.setState(
            {
                first_name: "",
                last_name: "",
                phone: "",
                category: "",
            },
            () => this.getContacts()
        );
    }
    render() {
        const { contacts, pageNumber, totalItems, search } = this.state;
        const { noContactsCas } = this.props;
        if (!search && contacts.length === 0) {
            return noContactsCas;
        }

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Contacts</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}
                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Prénom
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Nom
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Portable
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Catégorie
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {contacts.length != 0 &&
                                contacts.map((contact, index) => {
                                    return (
                                        <Tr key={"key_" + index}>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.contact.details}/${contact.id}`)}>{contact.prenom}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.contact.details}/${contact.id}`)}>{contact.nom}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.contact.details}/${contact.id}`)}>{contact.portable}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.contact.details}/${contact.id}`)}>{contact.categorie}</Td>
                                            <Td className="table_action">{/* <a href="/invoices/index/pdf-info/invoice_id/49577376" className="sprite-table-pdf-btn  tooltip" title="Imprimer" data-confirm_msg data-removeable_element="parents::tr" target="_blank" /> */}</Td>
                                        </Tr>
                                    );
                                })}

                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        {acl.contact.add() && (
                                            <a className="btn_white" onClick={() => this.props.history.push(`${RoutesPath.contact.add}/${this.props.accountId}`)}>
                                                Créer
                                            </a>
                                        )}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        this.getContacts();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / (this.props.limitForPagination ? this.props.limitForPagination : 20) && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        this.getContacts();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }

    renderSearchComp() {
        const { first_name, last_name, phone, category } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={first_name}
                            onChange={(value) => {
                                this.setState({ first_name: value.target.value });
                            }}
                            label="Prénom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={last_name}
                            onChange={(value) => {
                                this.setState({ last_name: value.target.value });
                            }}
                            label="Nom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={phone}
                            onChange={(value) => {
                                this.setState({ phone: value.target.value });
                            }}
                            label="Portable"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect
                            onKeyDown={this.handleKeyDown.bind(this)}
                            error={false}
                            onChange={(value) => {
                                this.setState({ category: value.target.value });
                            }}
                            selectedValue={category}
                            label="Catégorie"
                        >
                            <option value="" defaultValue></option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                        </CustomSelect>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getContacts())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(ContactListTable);
