import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import ProductSuggestionTable from "../components/ProductSuggestionTable";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { withRouter } from "react-router-dom";
import { acl } from "../services/acl";
class ProductSuggestionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Produits - Sobrus Labs";
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Suggestions des produits">
                    {acl.product.my_product() && (
                        <a onClick={() => this.props.history.push(`${RoutesPath.product.my_product}`)} className="btn_white">
                            Afficher mes produits
                        </a>
                    )}
                    {acl.product.list() && (
                        <a onClick={() => this.props.history.push(`${RoutesPath.product.list}`)} className="btn_white">
                            Afficher tous les produits
                        </a>
                    )}
                    {acl.product.suggestion.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.product.suggestion.add}`)}>
                            Suggérer un nouveau produit
                        </button>
                    )}
                </TopOptions>

                <ProductSuggestionTable {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(withRouter(ProductSuggestionPage));
