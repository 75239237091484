import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import NoResultCas from "../helpers/NoResultCas";
import { Token } from "../tmp/Token";
import Loading from "./loading";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import ResourceDAO from "../DAO/ResourceDAO";
import CommonDAO from "../DAO/CommonDAO";
import { RoutesPath } from "../values/RoutesPath";
import DataLoadingError from "../components/DataLoadingError";
import SearchSelect from "../helpers/SearchSelect";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";

class PharmaciesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            sectors: [],
            data: null,
            pageNumber: 1,
            totalItems: 0,
            search: false,
            advancedSearch: false,

            name: "",
            city: "",
            sector: "",
            type: "",

            name_operator: "like",
            city_operator: "is",
            sector_operator: "is",
            type_operator: "is",

            is_loaded: false,
            error: {
                is: false,
                message: "",
            },
            selected_city: {},
            selected_sector: {},

            orderBy: 0,
            key: "",
        };
    }
    componentDidMount() {
        this.setState({ is_loaded: false }, () => this.getAll());
        this.getCites();
        this.getSectors();
    }
    async getAll() {
        try {
            this.setLoadingError({ is: false, message: "" });
            const { orderBy, key, pageNumber, name, city, sector, type, advancedSearch, search, name_operator, type_operator, city_operator, sector_operator } = this.state;
            let data = ResourceDAO.getResourceSearchData({ name, city, sector, type, advancedSearch, search, name_operator, type_operator, city_operator, sector_operator });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }
            const response = await ResourceDAO.getALLResource("pharmacies", pageNumber, {}, data);
            this.setState({ is_loaded: true, data: response.data, totalItems: response.data.total });
        } catch (error) {
            this.setState({ is_loaded: true });
            this.setLoadingError({ is: true, message: error.message });
        }
    }

    setLoadingError(error) {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                is: error.is,
                message: error.message,
            },
        }));
    }

    async getCites() {
        try {
            const response = await CommonDAO.getAllCities(1, {});
            this.setState({ cities: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getSectors() {
        try {
            const response = await CommonDAO.getAllSectors(1, {});
            this.setState({ sectors: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    sort(property) {
        const sortedData = CommonDAO.sortData(this.state.data.ressources, property);
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }
    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.ressources, property);
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    resetSearch() {
        this.setState(
            {
                is_loaded: false,
                name: "",
                city: "",
                sector: "",
                type: "",

                name_operator: "like",
                city_operator: "is",
                sector_operator: "is",
                type_operator: "is",
                selected_city: {},
                selected_sector: {},
            },
            () => this.getAll()
        );
    }

    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    render() {
        const { key, orderBy, error, is_loaded, data, pageNumber, totalItems, search, name, type, sectors, code, category, city, sector, advancedSearch, account_type_operator, name_operator, type_operator, city_operator, sector_operator } = this.state;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Liste des pharmacies</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                        <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                    </div>
                </div>
                <TableWrapper className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {advancedSearch && (
                                <SearchTr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ name_operator: value.target.value });
                                                }}
                                                selectedValue={name_operator}
                                                label="Nom"
                                            >
                                                <option value="like" defaultValue>
                                                    contient
                                                </option>
                                                <option value="not_like">ne contient pas</option>
                                                <option value="starts_with">commence par</option>
                                                <option value="end_by">se termine par</option>
                                                <option value="is">est</option>
                                                <option value="is_not">n'est pas</option>
                                                <option value="null">est vide</option>
                                                <option value="not_null">n'est pas vide</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ city_operator: value.target.value });
                                                }}
                                                selectedValue={city_operator}
                                                label="Ville"
                                            >
                                                <option value="is" defaultValue>
                                                    est
                                                </option>
                                                <option value="is_not">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ sector_operator: value.target.value });
                                                }}
                                                selectedValue={sector_operator}
                                                label="Secteur"
                                            >
                                                <option value="is" defaultValue>
                                                    est
                                                </option>
                                                <option value="is_not">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ type_operator: value.target.value });
                                                }}
                                                selectedValue={type_operator}
                                                label="Type"
                                            >
                                                <option value="is" defaultValue>
                                                    est
                                                </option>
                                                <option value="is_not">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                            <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    fill="#fff"
                                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                ></path>
                                            </Search_btn_icon>
                                        </Search_btn>
                                    </Th>
                                </SearchTr>
                            )}

                            {(search || advancedSearch) && (
                                <SearchTr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomInput
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                onChange={(value) => {
                                                    this.setState({ name: value.target.value });
                                                }}
                                                label="Nom"
                                                value={name}
                                                defaultValue={name}
                                            ></CustomInput>
                                            <Search_icon>
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Svg>
                                            </Search_icon>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl="/api/villes"
                                                HTTPMethod="get"
                                                receivedObject="ressources"
                                                optionLabelProperty="nom"
                                                optionValueProperty="id"
                                                value={this.state.selected_city}
                                                placeholder="Ville"
                                                onChange={(e) => {
                                                    this.setState({ selected_city: e, city: e && e.value });
                                                }}
                                            />
                                            {/* <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ city: value.target.value });
                                                }}
                                                selectedValue={city}
                                                label="Ville"
                                            >
                                                <option value="" defaultValue></option>
                                                {cities.length != 0 &&
                                                    cities.map((city) => {
                                                        return <option value={city.id}>{city.nom}</option>;
                                                    })}
                                            </CustomSelect> */}
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl="/api/secteurs"
                                                HTTPMethod="get"
                                                receivedObject="ressources"
                                                optionLabelProperty="nom"
                                                optionLabelProperty_2="ville"
                                                optionValueProperty="id"
                                                value={this.state.selected_sector}
                                                placeholder="Secteur"
                                                onChange={(e) => {
                                                    this.setState({ selected_sector: e, sector: e && e.value });
                                                }}
                                            />
                                            {/* <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ sector: value.target.value });
                                                }}
                                                selectedValue={sector}
                                                label="Secteur"
                                            >
                                                <option value="" defaultValue></option>
                                                {sectors.length != 0 &&
                                                    sectors.map((sector) => {
                                                        return <option value={sector.id}>{sector.nom}</option>;
                                                    })}
                                            </CustomSelect> */}
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ type: value.target.value });
                                                }}
                                                selectedValue={type}
                                                label="Type"
                                            >
                                                <option value="" defaultValue></option>
                                                <option value="pharmacie">Pharmacie</option>
                                                <option value="parapharmacie">Parapharmacie</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {search && (
                                            <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </SearchTr>
                            )}
                            <tr className="table_th_orderable">
                                <Th key="nom" orderBy={key === "nom" && orderBy} onClick={() => this.setState({ key: "nom", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "nom" && orderBy === -1) || orderBy === 0 || key !== "nom") && <OrderUp />}
                                    {((key === "nom" && orderBy === 1) || orderBy === 0 || key !== "nom") && <OrderDown />}
                                    Nom
                                </Th>
                                <Th key="ville" orderBy={key === "ville" && orderBy} onClick={() => this.setState({ key: "ville", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "ville" && orderBy === -1) || orderBy === 0 || key !== "ville") && <OrderUp />}
                                    {((key === "ville" && orderBy === 1) || orderBy === 0 || key !== "ville") && <OrderDown />}
                                    Ville
                                </Th>
                                <Th key="secteur" orderBy={key === "secteur" && orderBy} onClick={() => this.setState({ key: "secteur", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "secteur" && orderBy === -1) || orderBy === 0 || key !== "secteur") && <OrderUp />}
                                    {((key === "secteur" && orderBy === 1) || orderBy === 0 || key !== "secteur") && <OrderDown />}
                                    Secteur
                                </Th>
                                <Th key="type" orderBy={key === "type" && orderBy} onClick={() => this.setState({ key: "type", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "type" && orderBy === -1) || orderBy === 0 || key !== "type") && <OrderUp />}
                                    {((key === "type" && orderBy === 1) || orderBy === 0 || key !== "type") && <OrderDown />}
                                    Type
                                </Th>

                                <Th className="table_action"></Th>
                            </tr>
                        </thead>
                        {!is_loaded && (
                            <tbody>
                                {[1, 2, 3].map((index) => {
                                    return (
                                        <Tr key={"key__" + index}>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        )}
                        {is_loaded && data && data.ressources.length != 0 && (
                            <tbody>
                                {data &&
                                    data.ressources.map((resource, index) => {
                                        return (
                                            <Tr
                                                onClick={() => {
                                                    acl.resource.pharmacy_details() && this.props.history.push(`${RoutesPath.resource.details}/pharmacie/${resource.id}`, { test: "tetttt" });
                                                }}
                                                key={"key_" + index}
                                            >
                                                <Td>{resource.nom}</Td>
                                                <Td>{resource.ville}</Td>
                                                <Td>{resource.secteur}</Td>
                                                <Td>{resource.type}</Td>
                                                <Td className="table_action"></Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td colSpan={8} className="table_pagination">
                                        <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                        <div style={{ display: "inline-block" }}>
                                            {pageNumber > 1 && (
                                                <a
                                                    className="sprite-table-prev-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                            <span className="table_page">{pageNumber}</span>
                                            {pageNumber < totalItems / 20 && (
                                                <a
                                                    className="sprite-table-next-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                            </tbody>
                        )}
                    </table>
                    {is_loaded && error.is && <DataLoadingError message={error.message} />}

                    {is_loaded && data && data.ressources.length == 0 && <NoResultCas btnText="" textInfos="Vous n’avez aucun compte pour le moment"></NoResultCas>}
                </TableWrapper>
            </Bloc>
        );
    }
}
const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
export default PharmaciesTable;
