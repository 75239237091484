import ApiCall from "../services/ApiCall";

class ReportDAO {
    // for getting all products limited by page number with get method

    static getVisitsByCannel = (start_date, end_date, user_id = "", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/rapport/parcanal/${start_date}/${end_date}${user_id.length !== 0 ? "/" + user_id : ""}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // for getting all products limited by page number with get method

    static getVisitsByPurpose = (start_date, end_date, user_id = "", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/rapport/parobjectif/${start_date}/${end_date}${user_id.length !== 0 ? "/" + user_id : ""}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // for getting all products limited by page number with get method

    static getVisitsByContact = (start_date, end_date, user_id = "", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/rapport/parcontact/${start_date}/${end_date}${user_id.length !== 0 ? "/" + user_id : ""}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // for getting all products limited by page number with get method

    static getContactByUser = (start_date, end_date, user_id = "", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/rapport/parcontactcreation/${start_date}/${end_date}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // for getting all products limited by page number with get method

    static getVisitsByStatus = (start_date, end_date, user_id = "", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                console.log("user_id", user_id)
                const response = await ApiCall.get(`/api/rapport/parstatut/${start_date}/${end_date}${user_id.length !== 0 ? "/" + user_id : ""}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // for getting all contacts limited by page number with get method

    // static getVisitsByStatus = (start_date, end_date, params = {}) =>
    //     new Promise(async (resolve, reject) => {
    //         try {
    //             const response = await ApiCall.get(`/api/rapport/parstatut/${start_date}/${end_date}`, params);
    //             resolve(response);
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });

    // for getting all products limited by page number with get method

    // static getMyProducts = (keyword, operator, pageNumber, params) =>
    //     new Promise(async (resolve, reject) => {
    //         try {
    //             const response = await ApiCall.get(`/api/produitsParLabo/${keyword}/${operator}/${pageNumber}`, params);
    //             resolve(response);
    //         } catch (error) {
    //             reject(error);
    //         }
    //     });

    // get product with post Method
    static getProducts = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/produits/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get product details

    static getProductById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/produit/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add product suggestion

    static addProductSuggestion = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/produitsuggestion`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static getSearchData({ advancedSearch, name, category, pharmaceuticalForm, PPV, PPH, barcode, search, name_operator, pharmaceuticalForm_operator, PPV_operator, category_operator, PPH_operator, barcode_operator }) {
        const data = {};
        if (name && name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: name,
                    op: "smart",
                };
            } else if (advancedSearch && name_operator.length !== 0) {
                data["nom"] = {
                    value: name,
                    op: name_operator,
                };
            }
        }
        if (category && category.length !== 0) {
            if (search) {
                data["categorie"] = {
                    value: category,
                    op: "is",
                };
            } else if (advancedSearch && category_operator.length !== 0) {
                data["categorie"] = {
                    value: category,
                    op: category_operator,
                };
            }
        }
        if (pharmaceuticalForm && pharmaceuticalForm.length !== 0) {
            if (search) {
                data["forme_galenique"] = {
                    value: pharmaceuticalForm,
                    op: "is",
                };
            } else if (advancedSearch && pharmaceuticalForm_operator.length !== 0) {
                data["forme_galenique"] = {
                    value: pharmaceuticalForm,
                    op: pharmaceuticalForm_operator,
                };
            }
        }
        if (PPV && PPV.length !== 0) {
            if (search) {
                data["ppv"] = {
                    value: PPV,
                    op: "is",
                };
            } else if (advancedSearch && PPV_operator.length !== 0) {
                data["ppv"] = {
                    value: PPV,
                    op: PPV_operator,
                };
            }
        }
        if (PPH && PPH.length !== 0) {
            if (search) {
                data["pph"] = {
                    value: PPH,
                    op: "is",
                };
            } else if (advancedSearch && PPH_operator.length !== 0) {
                data["pph"] = {
                    value: PPH,
                    op: PPH_operator,
                };
            }
        }
        if (barcode && barcode.length !== 0) {
            if (search) {
                data["code_barre"] = {
                    value: barcode,
                    op: "smart",
                };
            } else if (advancedSearch && barcode_operator.length !== 0) {
                data["code_barre"] = {
                    value: barcode,
                    op: barcode_operator,
                };
            }
        }
        return data;
    }
}

export default ReportDAO;
