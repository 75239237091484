import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";
class GalleryTopOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { title, disabledData, active, onChangeDataDisabled } = this.props;
        return (
            <div id="fixed_bar" className="top_options_wrapper">
                <div className="wrapper">
                    <div className="container4">
                        <div className="grid4">
                            <h1>{title}</h1>
                            <div className="top_options_btns">
                                <GalleryOptions>
                                    <GalleryList active={active === 1 ? true : false} onClick={this.props.galleryList} style={{ margin: 0 }}>
                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="list-ul" className="svg-inline--fa fa-list-ul fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path
                                                fill="#00beb0"
                                                d="M48 48a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0 160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 16H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"
                                            ></path>
                                        </Svg>
                                    </GalleryList>
                                    <GalleryPhotos active={active === 2 ? true : false} onClick={this.props.galleryPhoto} style={{ margin: 0 }}>
                                        <Svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="image" className="svg-inline--fa fa-image fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path
                                                fill="#00beb0"
                                                d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z"
                                            ></path>
                                        </Svg>
                                    </GalleryPhotos>
                                </GalleryOptions>
                                <button
                                    className="btn_white"
                                    data-shortcut_alias="create"
                                    onClick={() => {
                                        onChangeDataDisabled();
                                    }}
                                >
                                    {disabledData === 0 ? " Archives" : "Actuel"}
                                </button>
                                {acl.gallery.add() && (
                                    <Link to={`${RoutesPath.gallery.add}`} className="btn_bleu" data-shortcut_alias="create">
                                        Ajouter une photo
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const GalleryOptions = styled.div`
    float: left;
    margin-left: 5px;
    display: inline-block;
    height: 25px;
    width: 81px;
    cursor: pointer;
    background: #fff;
    border-radius: 2px;
`;
const GalleryList = styled.a`
    background-color: ${(props) => (props.active ? "#00beb0" : "")};
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 50%;
    margin: 0;

    border: 1px solid ${(props) => (props.active ? "#00beb0" : "#d2dbe0")};
    text-align: center;
    padding-top: 3px;
    > svg path {
        fill: ${(props) => (props.active ? "#fff" : "")};
    }
    &:hover {
        background: #00beb0;
        border: 1px solid #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const GalleryPhotos = styled.a`
    background-color: ${(props) => (props.active ? "#00beb0" : "")};

    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 50%;
    margin: 0;
    text-align: center;
    padding-top: 3px;
    border: 1px solid ${(props) => (props.active ? "#00beb0" : "#d2dbe0")};
    border-bottom: 1px solid #d2dbe0;
    border-right: 1px solid #d2dbe0;
    > svg path {
        fill: ${(props) => (props.active ? "#fff" : "")};
    }
    &:hover {
        background: #00beb0;
        border: 1px solid #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Svg = styled.svg`
    width: 16px;
`;
export default GalleryTopOptions;
