import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactListTable from "../components/ContactListTable";
import GalleryListTable from "../components/GalleryListTable";
import EventListTable from "../components/EventListTable";
import moment from "moment";
import Axios from "axios";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import CalenderDAO from "../DAO/CalenderDAO";
import { RoutesPath } from "../values/RoutesPath";
import { Link } from "react-router-dom";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";
import { MessagesText } from "../values/MessagesText";

class DetailsEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            crevent: [],
            successMessage: "",
            is_loaded: false,
        };
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";

        this.get();
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            this.setState({ is_loaded: false });
            const response = await CalenderDAO.getEventById(id, {});
            this.setState({ event: response.data.event, crevent: response.data.crevent, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    translate(param) {
        if (param === 1) {
            return "Oui";
        } else if (param === 0) {
            return "Non";
        } else {
            return "";
        }
    }
    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await CalenderDAO.deleteEvent(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.event.delete, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }

                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await CalenderDAO.deleteEvent(id, {}, data);

                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.event.restore, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    getSubRanges = () => {
        let subRangeNum = 1;
        const { crevent, } = this.state;
        const rendredSubRanges = [];
        crevent && crevent.gammes && crevent.gammes.forEach((obj, index) => {
            crevent.sous_gammes && crevent.sous_gammes[obj.id] && crevent.sous_gammes[obj.id].map((item, index2) => {
                rendredSubRanges.push(<Col>
                    <Field_label>{`Sous-gamme ${subRangeNum}`}</Field_label>
                    <Field_value>{item.nom}</Field_value>
                </Col>)
                subRangeNum = subRangeNum + 1;
            })
        })
        return rendredSubRanges;
    }
    render() {
        const { is_loaded, event, crevent, successMessage } = this.state;
        const id = this.props.match.params.id;
        const title = event ? event.nom : "";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={"L'événement : " + title}>
                    {is_loaded && !event.disabled && acl.calender.disable() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.delete(id)}>
                            Archiver
                        </button>
                    )}
                    {is_loaded && event.disabled && acl.calender.restore() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.restore(id)}>
                            Restaurer
                        </button>
                    )}

                    {is_loaded && !event.disabled && acl.calender.report() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.calender.report}/${id}`)}>
                            Reporter l'événement
                        </button>
                    )}
                    {is_loaded && !event.disabled && acl.crEvent.update() && crevent && crevent.statut !== "Planifié" ? (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.crEvent.update}/${id}`)}>
                            Modifier Rapport
                        </button>
                    ) : (
                            is_loaded &&
                            !event.disabled &&
                            acl.crEvent.add() && (
                                <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.crEvent.add}/${id}`)}>
                                    Rédiger Compte-rendu
                                </button>
                            )
                        )}
                    {is_loaded && !event.disabled && acl.calender.update() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.calender.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>
                                            {/* <Field_value_green>{event && event.nom}</Field_value_green> */}
                                            {is_loaded ? (
                                                <Field_value>{event && event.nom}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Description</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && event.description}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Date début</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && moment(event.date_debut).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Date fin</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && moment(event.date_fin).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Type</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && event.type}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Lieu</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && event.lieu}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Compte</Field_label>

                                            {is_loaded ? (
                                                event.compte_id ? (
                                                    <Link to={`${RoutesPath.account.details}/${event.compte_id}`}>
                                                        <Field_value isLink={true} color="#00beb0">
                                                            {event && event.compte}
                                                        </Field_value>
                                                    </Link>
                                                ) : (
                                                        <Field_value>{event && event.compte}</Field_value>
                                                    )
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contact</Field_label>

                                            {is_loaded ? (
                                                event.contact_id ? (
                                                    <Link to={`${RoutesPath.contact.details}/${event.contact_id}`}>
                                                        <Field_value isLink={true} color="#00beb0">
                                                            {event && `${event.contact_nom ? event.contact_nom : ""} ${event.contact_prenom ? event.contact_prenom : ""}`}
                                                        </Field_value>
                                                    </Link>
                                                ) : (
                                                        <Field_value>{event && `${event.contact_nom ? event.contact_nom : ""} ${event.contact_prenom ? event.contact_prenom : ""}`}</Field_value>
                                                    )
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Statut</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && event.statut}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        {/* <Col_1>
                                            <Field_label>Gestionnaire</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{event && event.gestionnaire && event.gestionnaire}</Field_value>
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_1> */}
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>

                        {crevent && (
                            <Block>
                                <div className="bloc_header">
                                    <h2>Rapport</h2>
                                    <div className="bloc_header_btns"></div>
                                </div>
                                <Block_content>
                                    <Title_container>
                                        <Title>Informations générales</Title>
                                        <Bar></Bar>
                                    </Title_container>
                                    <DetailsFieldsContainer>
                                        <Left>
                                            <Col>
                                                <Field_label>Status</Field_label>
                                                {/* <Field_value_green>{crevent && crevent[0].statut}</Field_value_green> */}
                                                <Field_value>{crevent && crevent.statut}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Visité le</Field_label>
                                                <Field_value>{crevent && crevent.visite_le && moment(crevent.visite_le).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            </Col>

                                            <Col>
                                                <Field_label>Docteur était présent</Field_label>
                                                <Field_value>{crevent && this.translate(crevent.docteur_present)}</Field_value>
                                            </Col>

                                            <Col>
                                                <Field_label>Action de la concurrence</Field_label>
                                                <Field_value>{crevent && crevent.action_concurrence}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Canal</Field_label>
                                                <Field_value>{crevent && crevent.canal}</Field_value>
                                            </Col>
                                        </Left>
                                        <Right>
                                            <Col>
                                                <Field_label>Gestionnaire</Field_label>
                                                <Field_value>{crevent && crevent.utilisateur}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Objectif de la visite</Field_label>
                                                <Field_value>{crevent && crevent.objectif}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>A revisiter le</Field_label>
                                                <Field_value>{crevent && crevent.a_revisiter_le && moment(crevent.a_revisiter_le).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Date de report</Field_label>
                                                <Field_value>{crevent && crevent.date_report && moment(crevent.date_report).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Commentaire</Field_label>
                                                <Field_value>{crevent && crevent.commentaire}</Field_value>
                                            </Col>
                                        </Right>
                                    </DetailsFieldsContainer>

                                    <Title_container>
                                        <Title>Liste des fournisseurs</Title>
                                        <Bar></Bar>
                                    </Title_container>
                                    <DetailsFieldsContainer>
                                        <Left>
                                            <Col>
                                                <Field_label>Fournisseur 1</Field_label>
                                                <Field_value>{crevent && crevent.fournisseurs && crevent.fournisseurs[0] ? crevent.fournisseurs[0].nom : ""}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Fournisseur 2</Field_label>
                                                <Field_value>{crevent && crevent.fournisseurs && crevent.fournisseurs[1] ? crevent.fournisseurs[1].nom : ""}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Fournisseur 4</Field_label>
                                                <Field_value>{crevent && crevent.fournisseurs && crevent.fournisseurs[3] ? crevent.fournisseurs[3].nom : ""}</Field_value>
                                            </Col>
                                        </Left>
                                        <Right>
                                            <Col>
                                                <Field_label>Fournisseur 3</Field_label>
                                                <Field_value>{crevent && crevent.fournisseurs && crevent.fournisseurs[2] ? crevent.fournisseurs[2].nom : ""}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Fournisseur 5</Field_label>
                                                <Field_value>{crevent && crevent.fournisseurs && crevent.fournisseurs[4] ? crevent.fournisseurs[4].nom : ""}</Field_value>
                                            </Col>
                                        </Right>
                                    </DetailsFieldsContainer>
                                    <Title_container>
                                        <Title>Liste des concurrents</Title>
                                        <Bar></Bar>
                                    </Title_container>
                                    <DetailsFieldsContainer>
                                        <Left>
                                            <Col>
                                                <Field_label>Concurent 1</Field_label>
                                                <Field_value>{crevent && crevent.concurrents && crevent.concurrents[0] && crevent.concurrents[0].nom}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Concurent 2</Field_label>
                                                <Field_value>{crevent && crevent.concurrents && crevent.concurrents[1] && crevent.concurrents[1].nom}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Concurent 4</Field_label>
                                                <Field_value>{crevent && crevent.concurrents && crevent.concurrents[3] && crevent.concurrents[3].nom}</Field_value>
                                            </Col>

                                        </Left>
                                        <Right>
                                            <Col>
                                                <Field_label>Concurent 3</Field_label>
                                                <Field_value>{crevent && crevent.concurrents && crevent.concurrents[2] && crevent.concurrents[2].nom}</Field_value>
                                            </Col>
                                            <Col>
                                                <Field_label>Concurent 5</Field_label>
                                                <Field_value>{crevent && crevent.concurrents && crevent.concurrents[4] && crevent.concurrents[4].nom}</Field_value>
                                            </Col>

                                        </Right>
                                    </DetailsFieldsContainer>
                                    {crevent && crevent.gammes && crevent.gammes.length !== 0 && <Title_container>
                                        <Title>Liste des gammes et sous-games des produits</Title>
                                        <Bar></Bar>
                                    </Title_container>}
                                    {crevent && crevent.gammes && crevent.gammes.length !== 0 && <DetailsFieldsContainer>
                                        <Left>
                                            {
                                                crevent && crevent.gammes && crevent.gammes.map((item, index) => (
                                                    <Col>
                                                        <Field_label>{`Gamme produit ${index + 1}`}</Field_label>
                                                        <Field_value>{item.nom}</Field_value>
                                                    </Col>
                                                ))
                                            }
                                        </Left>
                                        <Right>
                                            {
                                                this.getSubRanges().map(item => (item))
                                            }
                                        </Right>
                                    </DetailsFieldsContainer>}
                                </Block_content>
                            </Block>
                        )}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={event && event.disabled ? "bloc_content devis_statut ds_brouillon" : "bloc_content devis_statut ds_complete"}>
                                <h3>{event && event.disabled ? "Archivé" : "Actif"}</h3>
                            </div>
                        </div>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{event && event.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{event && moment(event.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        event && event.mis_a_jour_par ? (
                                            <Col_1_li>{event && event.mis_a_jour_par}</Col_1_li>
                                        ) : (
                                                <Col_1_li>{event && event.cree_par}</Col_1_li>
                                            )
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        event && event.mis_a_jour_le ? (
                                            <Col_1_li>{event && moment(event.mis_a_jour_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                        ) : (
                                                <Col_1_li>{event && moment(event.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                            )
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsEventPage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    min-height: 47px;
`;
const Col = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 100%;
    min-height: 47px;
    margin-bottom: 5px;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
    min-height: 47px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;

const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;

export const DetailsFieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-around")};
    padding-top: 29px;
    margin-bottom: 23px;
`;
export const Left = styled.div`
    width: 48%;
`;
export const FullRow = styled.div`
    width: 98%;
`;
export const Right = styled.div`
    width: 48%;
`;
