import ApiCall from "../services/ApiCall";

class AclDAO {
    // get all methods

    static getAllMethods = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/methods`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all roles

    static getAllRoles = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/roles`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // affect methods to role

    static affectMethodsToRole = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/methodes/role/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get all roles

    static getMethodsByRole = (id_role, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/methodes/role/${id_role}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get set role to user

    static setRoleToUser = (id_user, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/setRole/${id_user}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get add new role

    static addRole = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/role`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // update role

    static updateRole = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/role/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get add new role

    static affectRole = (user_id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/setRole/${user_id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get update existing role

    static updateRole = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/role/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    static isThereByDefaultRole = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/rolepardefaut`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static createRoleByDefault = (data, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/rolepardefaut`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
}

export default AclDAO;
