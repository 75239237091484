import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store/configureStore";
import PdfDocument from "./pages/PdfDocument";
import { PDFViewer } from "@react-pdf/renderer";
import Hello from "./pages/DemandDetails/Hello";
const users = [
    {
        id: "walter",
        display: "Walter White"
    },
    {
        id: "jesse",
        display: "Jesse Pinkman"
    },
    {
        id: "gus",
        display: 'Gustavo "Gus" Fring'
    },
    {
        id: "saul",
        display: "Saul Goodman"
    },
    {
        id: "hank",
        display: "Hank Schrader"
    },
    {
        id: "skyler",
        display: "Skyler White"
    },
    {
        id: "mike",
        display: "Mike Ehrmantraut"
    }
];

// import * as serviceWorker from "./serviceWorker";

// var proxy = require("http-proxy-middleware");
// proxy("https://Api.labsv3.sobrus.com/api/");

// ReactDOM.render(
//     <PDFViewer width="100%" height="100%">
//         {PdfDocument}
//     </PDFViewer>,
//     document.getElementById("root")
// );

ReactDOM.render(
    <Provider store={Store}>
        <App />
        {/* <Hello data={users} /> */}
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register(npm ) below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
