import React, { Component } from "react";
import styled from "styled-components";
import GalleryPictures from "../helpers/GalleryPictures";
import GalleryTopOptions from "../components/GalleryTopOptions";
import GalleryTable from "../components/GalleryTable";
import { connect } from "react-redux";

class GalleryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
            galleryPhoto: false,
            disabledData: 0,
        };
    }
    componentDidMount() {
        document.title = "Galerie - Sobrus Labs";
    }
    render() {
        const { galleryPhoto, disabledData } = this.state;
        return (
            <Main_container className="Main_container">
                <GalleryTopOptions
                    onChangeDataDisabled={() => this.setState({ disabledData: disabledData === 1 ? 0 : 1 })}
                    disabledData={disabledData}
                    active={this.state.galleryPhoto ? 2 : 1}
                    title="Galerie"
                    galleryList={() => this.setState({ galleryPhoto: false })}
                    galleryPhoto={() => this.setState({ galleryPhoto: true })}
                />
                {galleryPhoto ? <GalleryPictures disabledData={disabledData} {...this.props}></GalleryPictures> : <GalleryTable disabledData={disabledData} {...this.props}></GalleryTable>}
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(GalleryPage);
