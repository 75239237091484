import React, { Component } from "react";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import AclDAO from "../DAO/AclDAO";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import FromLoading from "../helpers/FromLoading";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
class AddACLRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            fields: {
                name: "",
            },
            fieldsError: {
                name: false,
            },
            loading: false,
        };
    }
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Sauvegarder", onClick: () => (id ? this.update() : this.add()) }] });
    }
    async add() {
        const { fields } = this.state;
        try {
            if (fields.name.length !== 0) {
                const params = {};
                const data = {
                    nom: fields.name,
                };
                this.setState({ loading: true });
                const response = await AclDAO.addRole(params, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.roles.add, type: "success" });
                    this.props.history.push(RoutesPath.setting.acl_roles.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                if (fields.name.length === 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        fieldsError: {
                            ...prevState.fieldsError,
                            name: true,
                        },
                    }));
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }
    async update() {
        const { fields } = this.state;
        try {
            const id = this.props.match.params.id;
            if (fields.name.length !== 0) {
                const params = {};
                const data = {
                    nom: fields.name,
                };
                this.setState({ loading: true });
                const response = await AclDAO.updateRole(id, params, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.roles.update, type: "success" });
                    this.props.history.push(RoutesPath.setting.acl_roles.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                if (fields.name.length === 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        fieldsError: {
                            ...prevState.fieldsError,
                            name: true,
                        },
                    }));
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }
    setFields(field, value) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: value,
            },
            fieldsError: {
                ...prevState.fieldsError,
                [field]: false,
            },
        }));
    }
    render() {
        const { fields, fieldsError, loading } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Créer un nouveau rôle</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page vous permet de créer un nouveau rôle.</Block_content_title>
                    </Block_content>
                </First_block>
                <Block>
                    {loading && <FromLoading />}
                    <div className="bloc_header">
                        <h2>Créer un nouveau rôle</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Title_container>
                            <Title>Informations générales</Title>
                            <Bar></Bar>
                        </Title_container>
                        <Fields_container>
                            <Row>
                                <Col_1>
                                    <CustomInput error={fieldsError.name} value={fields.name} onChange={(Value) => this.setFields("name", Value.target.value)} id="name" label="Nom *"></CustomInput>
                                </Col_1>
                            </Row>
                        </Fields_container>
                    </Block_content>
                </Block>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(AddACLRoles);

const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 10px;
    position: relative;
`;

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;

const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;

const Row = styled.div`
    display: block;
    margin-bottom: 20px;
    width: 100%;
`;

const Col_1 = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;

const Title_container = styled.div`
    position: relative;
`;

const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
