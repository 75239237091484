import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./HeaderItem.css";
import NotificationDAO from "../DAO/NotificationDAO";
import { notify } from "./FlashMessage";
import { connect } from "react-redux";

class NotificationHeaderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getAll = async () => {
        try {
            const response = await NotificationDAO.getALL({})
            this.setState({ data: response.data.response.notifications })
            const action = { type: "ADD_ALL_NOTIFICATIONS", value: { notifications: response.data.response.notifications, notificationTotalItem: response.data.response.notifications.length, } };
            this.props.dispatch(action)
        } catch (error) {
            // notify({ msg: error.message, type: "danger" })
        }
    }
    componentDidMount() {
        this.getAll();
        setInterval(this.getAll, 10000);

    }
    render() {
        const { to, itemTitle, className, name, style, notificationTotalItem } = this.props;
        return (
            <LiContainer style={style} name={name} className={className && className}>
                <Link exact={to === "/" ? true : false} to={to}>
                    <Li notificationTotalItem={notificationTotalItem} onClick={() => { }}>
                        <Text>{notificationTotalItem}</Text>
                        <SubText>{itemTitle}</SubText>
                    </Li>
                </Link>
                {this.props.children}
            </LiContainer>
        );
    }
}


NotificationHeaderItem.protoTypes = {
    to: PropTypes.string,
    itemTitle: PropTypes.string,
    itemIndex: PropTypes.number,
};

const Li = styled.li`
    background-color:${props => props.notificationTotalItem > 0 ? "#ee1059" : "transparent"};
    display:flex;
    flex-direction:column;
    align-items:center;
    height:50px;
    width: 58px;
    justify-content: center;
`;

const Text = styled.span`
    background-color: transparent;
    color: #fff;
    transition: all ease-in-out 0.2s;
    font-weight: 700;
    outline: 0;
    font-size:20px;
    text-decoration: none;
`;

const SubText = styled.span`
    background-color: transparent;
    color: #fff;
    transition: all ease-in-out 0.2s;
    outline: 0;
    text-decoration: none;
    font-size: 8px;
`;



const LiContainer = styled.div`
    display: inline-block;
    margin-left: 15px;
   
`;
const mapStateToProps = (state) => {
    return {
        notificationTotalItem: state.Notification.notificationTotalItem
    };
};
// const NavigationHeader = withRouter(Header)


export default connect(mapStateToProps)(NotificationHeaderItem);
