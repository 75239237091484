import React, { Component } from "react";
import styled from "styled-components";
const detailedSearchOperator = [
    { value: "like", label: "contient" },
    { value: "not_like", label: "ne contient pas" },
    { value: "starts_with", label: "commence par" },
    { value: "end_by", label: "se termine par" },
    { value: "is", label: "est" },
    { value: "is_not", label: "n'est pas" },
    { value: "null", label: "est vide" },
    { value: "not_null", label: "n'est pas vide" },
];
const shortSearchOperator = [
    { value: "is", label: "est" },
    { value: "is_not", label: "n'est pas" },
];

const categorySearchOperator = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "F", label: "F" },

];

const defaultTypes = {
    detailed: detailedSearchOperator,
    short: shortSearchOperator,
    category: categorySearchOperator,
};
class CustomSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "",
        };
    }
    componentDidMount() { }
    openSelect(event) {
        const fieldInput = this.refs.fieldInput;
        fieldInput.handleMouseDown(event);
    }
    render() {
        const { error, operatorsType, minWidth, maxWidth, disabled } = this.props;
        return (
            <Container minWidth={minWidth ? minWidth : ""} maxWidth={maxWidth ? maxWidth : ""}>
                <InputContainer>
                    <Select disabled={disabled ? disabled : false} onKeyDown={this.props.onKeyDown} borderColor={error ? "#FF068B" : "#bbc8d3"} ref="fieldInput" onChange={this.props.onChange} value={this.props.selectedValue ? this.props.selectedValue : ""} className="updateSelect" id={`${this.props.label}_id`} required>
                        {operatorsType && <option defaultValue></option>}
                        {operatorsType &&
                            defaultTypes[operatorsType].map((object, index) => (
                                <option key={`${this.props.label}_${index}`} value={object.value}>
                                    {object.label}
                                </option>
                            ))}
                        {this.props.children}
                    </Select>
                    <Label color={error ? "#FF068B" : "#284358"} htmlFor={`${this.props.label}_id`}>
                        {this.props.label}
                    </Label>
                    <Icon htmlFor="sourceSelect">
                        <Svg xmlns="http://www.w3.org/2000/svg" width="12.035" height="6.86" viewBox="0 0 12.035 6.86">
                            <path
                                fill={error ? "#FF068B" : "#284358"}
                                id="Path_282"
                                data-name="Path 282"
                                d="M103.752,6.613l-5.175,5.175A.843.843,0,0,1,97.385,10.6l4.579-4.579L97.385,1.438A.843.843,0,0,1,98.577.247l5.175,5.175a.843.843,0,0,1,0,1.192Z"
                                transform="translate(12.035 -97.139) rotate(90)"
                            />
                        </Svg>
                    </Icon>
                </InputContainer>
                {error && (
                    <ErrorContainer>
                        <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#FF068B"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                            ></path>
                        </svg>
                        <ErrorText>Ce champ est obligatoire</ErrorText>
                    </ErrorContainer>
                )}
            </Container>
        );
    }
}

const Container = styled.div`
    min-width: ${props => props.minWidth ? props.minWidth : ""};
    max-width: ${props => props.maxWidth ? props.maxWidth : ""};

    width: 100%;
    height: 100%;
`;
const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const Label = styled.label`
    position: absolute;
    left: 15px;
    top: 34%;
    ${"" /* font-size: 14px; */}
    color:red;
    font-family: "Open Sans", sans-serif;
    color: #494949;
    font-weight: 600;
    font-size: 12px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: ${(props) => props.color};
`;
const Select = styled.select`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    ${"" /* font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;
    color: #494949;
    font-size: 12px;
    font-weight: 600;

    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: ${props => props.disabled ? "#f8f8f8" : "#ffffff"};
    border-radius: 6px;
    border: solid 1px ${(props) => props.borderColor};
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    &:focus ~ ${Label}, &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 7px;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Icon = styled.label`
    position: absolute;
    right: 15px;
    top: 29%;
`;
const Svg = styled.svg``;
const ErrorContainer = styled.div``;
const ErrorText = styled.div`
    display: inline-block;
    color: #ff068b;
    font-size: 10px;
    vertical-align: top;
`;
export default CustomSelect;
