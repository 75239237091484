import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import ContactDAO from "../DAO/ContactDAO";
import { RoutesPath } from "../values/RoutesPath";
import { Link } from "react-router-dom";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";
import { MessagesText } from "../values/MessagesText";
import DemandListTable from "../components/DemandListTable";

class DetailsContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: null,
            successMessage: "",
            is_loaded: false,
        };
    }

    componentDidMount() {
        document.title = "Contacts - Sobrus Labs";
        this.get();
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ContactDAO.getContactById(id, {});
            this.setState({ contact: response.data, is_loaded: true });
        } catch (error) {
            if (error.message === "accès à la ressource refusé") {
                notify({ msg: "Vous n'avez pas la permission d'accéder à cette page", type: "danger" });
                this.props.history.goBack();
            } else {
                notify({ msg: error.message, type: "danger" });
            }
        }
    }
    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await ContactDAO.deleteContact(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.contact.delete, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
                // this.props.history.push(`${RoutesPath.contact.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await ContactDAO.deleteContact(id, {}, data);
                // this.props.history.push(`${RoutesPath.contact.list}`);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.contact.restore, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { is_loaded, contact, successMessage } = this.state;
        const title = contact ? contact.prenom + " " + contact.nom : "";
        const id = this.props.match.params.id;
        return (
            <Main_container className="Main_container">
                <TopOptions title={"Contact : " + title}>
                    {is_loaded && !contact.disabled && acl.contact.disable() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.delete(id)}>
                            Archiver
                        </button>
                    )}
                    {is_loaded && contact.disabled && acl.contact.restore() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.restore(id)}>
                            Restaurer
                        </button>
                    )}
                    {is_loaded && !contact.disabled && acl.contact.update() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.contact.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title>Informations générales</Title>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gestionnaire</Field_label>
                                            {/* <Field_value_green>{contact && contact.gestionnaire}</Field_value_green> */}

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.gestionnaire}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Compte</Field_label>

                                            {is_loaded ? (
                                                contact.compte_id ? (
                                                    <Link to={`${RoutesPath.account.details}/${contact.compte_id}`}>
                                                        <Field_value isLink={true}>{contact && contact.compte}</Field_value>
                                                    </Link>
                                                ) : (
                                                        <Field_value>{contact && contact.compte}</Field_value>
                                                    )
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Prénom</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.prenom}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Nom</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.nom}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Titre</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.titre}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Portable</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.portable}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>E-mail</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.email}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Poste</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.poste}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Service</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.service}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Catégorie</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{contact && contact.categorie}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                        {/* demands bloc */}

                        {acl.contact.demands() && contact && contact.id && (
                            <DemandListTable
                                limitForPagination={5}
                                noInventoryCas={
                                    <Block_contact>
                                        <Title_contact>Demandes</Title_contact>
                                        <Btn_contact_container>{acl.demand.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.demand.add}?q=${window.btoa(JSON.stringify(contact))}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                contactId={contact.id}
                                {...this.props}
                            />
                        )}
                        {/* end demands bloc */}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={contact && contact.disabled ? "bloc_content devis_statut ds_brouillon" : "bloc_content devis_statut ds_complete"}>
                                <h3>{contact && contact.disabled ? "Archivé" : "Actif"}</h3>
                            </div>
                        </div>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{contact && contact.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{contact && moment(contact.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{contact && contact.mis_a_jour_par ? contact && contact.mis_a_jour_par : contact && contact.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{contact && contact.mis_a_jour_le ? contact && moment(contact.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : contact && moment(contact.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsContactPage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;

const Field_value = styled.p`
    display: block;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;
const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;