const initialState = { message: null };

function FlashMessage(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SHOW_FLASH_MESSAGE":
            nextState = {
                ...state,
                message: { type: action.value.type, text: action.value.text },
            };
            return nextState || state;
        case "HIDE_FLASH_MESSAGE":
            nextState = {
                ...state,
                message: null,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default FlashMessage;
