import React, { Component } from "react";

import { Mention, MentionsInput } from "react-mentions";

import provideExampleValue from "./higher-order";
import classNames from './example.module.css'
import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defaultMentionStyle";
import { AlphabetsColor } from "../../helpers/AlphabetsColor";
import { notify } from "../../helpers/FlashMessage";
import CommonDAO from "../../DAO/CommonDAO";

// use first/outer capture group to extract the full entered sequence to be replaced
// and second/inner capture group to extract search string from the match
const emailRegex = /(([^\s@]+@[^\s@]+\.[^\s@]+))$/;
const users = [
    {
        id: "walter",
        display: "Walter White"
    },
    {
        id: "jesse",
        display: "Jesse Pinkman"
    },
    {
        id: "gus",
        display: 'Gustavo "Gus" Fring'
    },
    {
        id: "saul",
        display: "Saul Goodman"
    },
    {
        id: "hank",
        display: "Hank Schrader"
    },
    {
        id: "skyler",
        display: "Skyler White"
    },
    {
        id: "mike",
        display: "Mike Ehrmantraut"
    },

];

class MultipleTriggers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    componentDidMount() {
        this.getAllUsers()
    }
    getAllUsers = async (pageNumber = 1) => {
        try {
            const response = await CommonDAO.getAllUsers(pageNumber, {}, {})
            console.log("all users", response)
            this.setState({ data: response.data.utilisateurs.map((user, index) => ({ id: user.id, display: user.fullname })) }, () => {
                if (response.data.total / 20 > pageNumber) {
                    this.getAllUsers(pageNumber + 1)
                }
            })
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }

    render() {
        const { value, onChange, onAdd } = this.props;
        const { data } = this.state;
        return (
            <div className="multiple-triggers">

                <MentionsInput
                    displayTransform={(id, display, type) => {
                        return `@${display}`
                    }}
                    value={value}
                    onChange={onChange}
                    style={defaultStyle}
                    markup="@[__display__](__type__:__id__)"
                    placeholder="Ajouter un commentaire"
                >
                    <Mention
                        type="user"
                        trigger="@"
                        data={data}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                            index,
                            focused
                        ) => {
                            return (
                                <div key={`tags_list_${index}`} className={`user Mention__item-container ${focused ? "focused" : ""}`}>
                                    <span style={{ backgroundColor: suggestion.display ? AlphabetsColor[suggestion.display.charAt(0).toLowerCase()] : "#ff78ff" }} className="subscriber-avatar">{suggestion.display ? suggestion.display.slice(0, 2).toLocaleUpperCase() + " " : ""}</span>
                                    {highlightedDisplay}
                                </div>
                            )
                        }}
                        onAdd={onAdd}
                        className={classNames.mentions__mention}
                    />

                    {/* <Mention
                        type="email"
                        trigger={emailRegex}
                        data={search => [{ id: search, display: search }]}
                        onAdd={onAdd}
                        className={classNames.mentions__mention}
                    /> */}
                </MentionsInput>
            </div>
        );
    }
}



// const asExample = provideExampleValue("");

// export default asExample(MultipleTriggers);
export default MultipleTriggers;
