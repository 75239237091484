import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import moment from "moment";
import CustomInput from "../components/CustomInput";
import NoResultCas from "../helpers/NoResultCas";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GalleryDAO from "../DAO/GalleryDAO";
import { RoutesPath } from "../values/RoutesPath";
import SearchSelect from "../helpers/SearchSelect";
import { DatePicker } from "../components/CustomDatePicker";
import DataLoadingError from "../components/DataLoadingError";
import { acl } from "../services/acl";
import { BASE_URL } from "../env";

class GalleryPictures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pictures: [],
            totalItems: 0,
            search: false,
            account: "",
            name: "",
            date: null,
            data: null,
            isLoaded: false,
            selectedOption: {},
            error: {
                data_loading: false,
                message: "",
            },
        };
    }
    componentDidMount() {
        this.getAll();
    }
    async getAll() {
        try {
            const { disabledData } = this.props;
            this.setState({
                error: { data_loading: false, message: "" },
            });
            const { pageNumber, account, name, date } = this.state;
            this.setState({ isLoaded: false });
            let data = GalleryDAO.getSearchData({ account, name, date });
            data["disabled"] = {
                value: disabledData,
                op: "is",
            };
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            if (data) {
                const response = await GalleryDAO.getALLPhotos(pageNumber, {}, data);
                let rows = [];
                let cells = [];
                response.data.photos.map((photo, index) => {
                    if (index % 4 !== 0) {
                        cells.push(photo);
                    } else {
                        rows.push(cells);
                        cells = [];
                        cells.push(photo);
                    }
                    if (index === response.data.photos.length - 1) {
                        // when end loop we add remain date
                        rows.push(cells);
                    }
                });

                this.setState({ isLoaded: true, pictures: rows, data: response.data, totalItems: response.data.total });
            }
        } catch (error) {
            this.setState({
                error: { data_loading: true, message: error.message },
            });
        }
    }
    showImage = () => {
        this.setState({ loaded: true });
    };
    resetSearch() {
        this.setState(
            {
                isLoaded: false,
                account: "",
                name: "",
                date: null,
                selectedOption: {},
            },
            () => this.getAll()
        );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.disabledData !== this.props.disabledData) {
            this.getAll();
        }
    }
    render() {
        const { isLoaded, pictures, pageNumber, totalItems, search, account, name, date, data, error } = this.state;

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Liste des photos</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: search ? false : true })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                        {/* <a onClick={() => this.setState({ search: search ? false : true })} href="#" className="sprite-bloc-star"></a> */}
                    </div>
                </div>
                {search && (
                    <Search_container>
                        <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getAll())}>
                            <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="#fff"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Search_btn_icon>
                        </Search_btn>
                        <Search_input_container>
                            <DatePicker
                                selected={date}
                                onChange={(date) => {
                                    this.setState({
                                        date: date,
                                    });
                                }}
                                label="Date"
                                id="date"
                                dateFormat="yyyy-MM-dd"
                                showTimeSelect={false}
                            />
                        </Search_input_container>
                        <Search_input_container>
                            <CustomInput value={name} defaultValue={name} onChange={(value) => this.setState({ name: value.target.value })} id="name" label="Nom"></CustomInput>
                            <Search_icon>
                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill="currentColor"
                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                    ></path>
                                </Svg>
                            </Search_icon>
                        </Search_input_container>
                        <Search_input_container>
                            <SearchSelect
                                fetchedUrl="/api/comptes"
                                HTTPMethod="post"
                                receivedObject="comptes"
                                optionLabelProperty="libelle"
                                optionValueProperty="id"
                                value={this.state.selectedOption}
                                placeholder="Compte"
                                onChange={(e) => {
                                    this.setState({
                                        selectedOption: e,
                                        account: e && e.value,
                                    });
                                }}
                            />
                        </Search_input_container>
                    </Search_container>
                )}
                <div className="bloc_content table_wrraper">
                    {isLoaded && pictures.length != 0 && (
                        <Gallery>
                            {pictures.length != 0 &&
                                pictures.map((cells, index) => {
                                    return cells.map((picture, i) => {
                                        return (
                                            <ImageContainer
                                                imageUrl={BASE_URL + "/uploads/" + picture.path}
                                                key={"key_td_" + i}
                                                onClick={() => {
                                                    this.props.history.push(`${RoutesPath.gallery.details}/${picture.id}`);
                                                }}
                                                cellspacing="0"
                                                cellpadding="0"
                                            >
                                                {/* <LazyLoadImage
                                                            alt={"image"}
                                                            height={"100%"}
                                                            src={"https://server3.sobrus.com/uploads/" + picture.path} // use normal <img> attributes as props
                                                            width={"100%"}
                                                             /> */}
                                                {/* <Img  src={"https://server3.sobrus.com/uploads/" + picture.path} onLoad={()=><div>loading</div>} /> */}
                                                {/* <Img  src={require("../images/banner_groups.png")} style={ false ? {display: "none"} : {}} /> */}
                                                <DefaultImg style={this.state.loaded ? { display: "none" } : {}}>
                                                    <CenterDefaultImage src={require("../images/Logo_Sobrus.png")} />
                                                </DefaultImg>
                                                <Img src={picture.path} onLoad={this.showImage} style={this.state.loaded ? {} : { display: "none" }} />
                                                {/* <Img  src={require("../images/banner_groups.png")} dataSource = {"https://server3.sobrus.com/uploads/" + picture.path}  /> */}

                                                <Infos>
                                                    <Ul>
                                                        <Li>
                                                            <Circle color="#00beb0" />
                                                            <Event_desc>Nom - {picture.nom}</Event_desc>
                                                        </Li>
                                                        <Li>
                                                            <Circle color="#f89a54" />
                                                            <Event_desc>Compte - {picture.compte}</Event_desc>
                                                        </Li>
                                                        <Li>
                                                            <Circle color="#F0396E" />
                                                            <Event_desc>Date - {moment(picture.date).format("YYYY-MM-DD HH:mm")}</Event_desc>
                                                        </Li>
                                                    </Ul>
                                                </Infos>
                                            </ImageContainer>
                                        );
                                    });
                                })}
                        </Gallery>
                    )}
                    {isLoaded && error.data_loading && <DataLoadingError message={error.message} />}

                    <table>
                        <tfoot>
                            <Tr>
                                <LastTd style={{ float: "right" }}>
                                    <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                style={{ verticalAlign: "bottom" }}
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span style={{ verticalAlign: "bottom" }} className="table_page">
                                            {pageNumber}
                                        </span>
                                        {pageNumber < totalItems / 20 && (
                                            <a
                                                style={{ verticalAlign: "bottom" }}
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </LastTd>
                            </Tr>
                        </tfoot>
                    </table>
                    {isLoaded && pictures && pictures.length == 0 && <NoResultCas btnOnClick={() => acl.gallery.add() && this.props.history.push(RoutesPath.gallery.add)} btnText={acl.gallery.add() && "Ajouter une photo"} textInfos="Vous n’avez aucune photo pour le moment"></NoResultCas>}

                    {/* {data && data.photos.length == 0 && <NoResultCas btnOnClick={() => ("photo")} btnText="Créer une photo" textInfos="Vous n’avez aucune photo pour le moment"></NoResultCas>} */}
                </div>
            </Bloc>
        );
    }
}

const Th = styled.th`
    cursor: pointer;
`;
const ImageContainer = styled.div`
    display:flex;
    flex:25%
    border:2px solid #fff;
    height: 299px;
    overflow: hidden;
    
   
    ${"" /* display: inline-block; */}
    ${"" /* white-space: nowrap; */}
    position:relative;
    cursor:pointer;
    &:hover{
        > div{
            background:rgba(0, 0, 0,0.5);
            opacity:1;
        }
        >img{
            transform: scale(1.4) rotate(15deg);
        }
    }
    @media (max-width: 960px) {
        flex: 33.33%;
    }
    @media (max-width: 768px) {
        flex: 50%;
    }
    @media (max-width: 480px) {
        flex: 100%;
    }
`;
const Gallery = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;
const LastTd = styled.td`
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Infos = styled.div`
    position: absolute;
    height: 30%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    opacity: 0;
`;
const Last_td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
    text-align: right;
`;
const Tr = styled.tr`
    cursor: pointer;
    text-align: center;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const Last_tr = styled.tr`
    cursor: pointer;
`;
const Img = styled.img`
    height:100%;
    width:100%;
    object-fit: cover;
    transition:.3s
  }
`;
const DefaultImg = styled.div`
    height: 100%;
    width: 100%;
    background: #f6f7f8;
    position: relative;
`;
const CenterDefaultImage = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 84px;
`;
const Ul = styled.ul`
    list-style: none;
    margin-top: 10px;
`;
const Li = styled.li`
    display: block;
    width: 90%;
    margin: auto;
    margin-bottom: 2.5px;
    cursor: pointer;
    text-align: left;
`;
const Circle = styled.div`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    vertical-align: top;
    margin-top: 6px;
`;
const Event_desc = styled.p`
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-left: 8px;
    white-space: nowrap;
    width: 293px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Search_container = styled.div`
    width: 100%;
    height: 62px;
    display: ${(props) => props.display};
    padding: 13px;
    background: #fff;
`;
const Search_input_container = styled.div`
    width: 16%;
    height: 38px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    float: right;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    float: right;
    margin-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
export default GalleryPictures;
