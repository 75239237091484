import React, { Component } from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";
import Auth from "../../services/Auth";

class ErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { msg, onClick } = this.props;
        return (
            <div className="ErrorPage">
                <img className="ErrorPage__img" src={require("../../images/error_salim.png")} alt="dataLoadingError" />
                <p className="ErrorPage__msg-text">{msg}</p>
                <button
                    onClick={
                        onClick
                            ? onClick
                            : () => {
                                  Auth.logout(() => {});
                              }
                    }
                    className="ErrorPage__btn"
                >
                    Login
                </button>
            </div>
        );
    }
}

export default ErrorPage;
