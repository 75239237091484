import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";

import CommonDAO from "../DAO/CommonDAO";

import { TablePagination } from "./TablePagination";
import PropTypes from "prop-types";
import ipInt from "ip-to-int";
import NoResultCas from "../helpers/NoResultCas";

const is = (item) => {
    return item && item;
};

const actionsClassName = {
    modifier: "sprite-table-edit-btn",
    archiver: "sprite-table-delete-btn",
    validate: "sprite-table-validate-btn",
    restore: "sprite-table-recover-btn",
};

class TableModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchType: "simple",
            searchToggle: false,
            dataIsLoaded: false,
        };
    }

    componentDidMount() { }

    sort(property) {
        const sortedData = CommonDAO.sortData(this.state.data.photos, property);
        const data = this.state.data;
        data.photos = [...sortedData];
        this.setState({ data });
    }

    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.photos, property);
        const data = this.state.data;
        data.photos = [...sortedData];
        this.setState({ data });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }

    searchToggle(searchTypeParam) {
        const { searchToggle, searchType } = this.state;
        this.setState({ searchType: searchTypeParam, searchToggle: searchToggle && searchType === searchTypeParam ? false : true });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
        if (prevProps.tbody.dataIsLoaded !== this.props.tbody.dataIsLoaded) {
            this.setState((prevState) => ({
                ...prevState,
                tbody: {
                    ...prevState.tbody,
                    dataIsLoaded: this.props.tbody.dataIsLoaded,
                },
            }));
        }
    }

    getFormateData = (object, item) => {
        switch (item.type) {
            case "boolean":
                return object[item.property] === true ? "Oui" : "Non";
                break;
            case "date":
                return moment(object[item.property]).format(item.format ? item.format : "YYYY-MM-DD HH:mm");
                break;
            case "ip":
                return ipInt(object[item.property]).toIP();
                break;
            case "checkBox":
                return (
                    <label htmlFor={`object_${object.id}`} className="checkbox" style={{ display: "block" }}>
                        <input onChange={(e) => e.target.checked} type="checkbox" name={`object_${object.id}`} id={`object_${object.id}`} defaultValue={1} autoComplete="off" />
                        <span className="checked" />
                    </label>
                );
                break;
            default:
                return object[item.property];
        }
    };

    render() {
        const { title, onReload, thead, tbody, tfoot, BasicSearchComponent, AdvancedSearchComponent, searchToggle, searchType, searchToggleFunc, hideTri } = this.props;

        const { } = this.state;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>{is(title)}</h2>
                    <div className="bloc_header_btns">
                        {BasicSearchComponent && <a onClick={() => searchToggleFunc("simple")} className="sprite-bloc-search" />}
                        {/* required */}
                        <a onClick={() => onReload()} className="sprite-bloc-reload"></a>

                        {AdvancedSearchComponent && <a onClick={() => searchToggleFunc("advanced")} className="sprite-bloc-star"></a>}
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {searchToggle && searchType === "advanced" && AdvancedSearchComponent}

                            {searchToggle && (searchType === "advanced" || searchType === "simple") && BasicSearchComponent}

                            <tr className="table_th_orderable">
                                {thead.map((item, index) => {
                                    return (
                                        <Th key={"th_key_" + index}>
                                            {!hideTri && <OrderUp onClick={() => this.sort(item.property)} />}
                                            {!hideTri && <OrderDown onClick={() => this.reverse(item.property)} />}
                                            {item.label}
                                        </Th>
                                    );
                                })}

                                {tbody.actions.length !== 0 && <Th className="table_actions" />}
                            </tr>
                        </thead>

                        {!tbody.dataIsLoaded && <tbody>{<Loading columns={thead} />}</tbody>}

                        {tbody.dataIsLoaded && (
                            <tbody>
                                {tbody.data.map((object, index) => {
                                    return (
                                        <Tr key={"key_" + index}>
                                            {thead.map((item, index) => (
                                                <Td key={"key_td_" + index} onClick={() => tbody.onClickRow(object.id)}>
                                                    {this.getFormateData(object, item)}
                                                </Td>
                                            ))}

                                            {tbody.actions.length !== 0 && (
                                                <Td className="table_action">
                                                    {tbody.actions.map((item, index) => (
                                                        <a key={`tbody_action_${index}`} onClick={() => item.onClick(object.id)} className={actionsClassName[item.title]} title={item.title} data-confirm_msg data-removeable_element="parents::tr" target="_blank" />
                                                    ))}
                                                </Td>
                                            )}
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        )}
                        {tfoot && tbody.data.length !== 0 && <TablePagination colSpan={tfoot.colSpan} pageNumber={tfoot.pageNumber} totalItems={tfoot.totalItems} onPrevBtnClick={() => tfoot.PrevPage()} onNextBtnClick={() => tfoot.nextPage()} />}
                    </table>
                </div>

                {tbody.dataIsLoaded && tbody.data.length === 0 && (
                    this.props.NoResultCas ? this.props.NoResultCas : <div></div>
                )}
            </Bloc>
        );
    }
}

TableModel.protoTypes = {
    title: PropTypes.string,
    onReload: PropTypes.bool,
    thead: PropTypes.arrayOf(PropTypes.object),
    tbody: PropTypes.object,
    tfoot: PropTypes.object,
    BasicSearchComponent: PropTypes.element,
    AdvancedSearchComponent: PropTypes.element,
};

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
export const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
export const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;

export default TableModel;

export const Loading = (props) => {
    const { columns } = props;
    return (
        <>
            {[1, 2, 3].map((index) => {
                return (
                    <Tr key={"key__" + index}>
                        {columns.map((object, index) => (
                            <Td key={"loading_key_" + index}>
                                <ContentLoader width="160" height="20">
                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                </ContentLoader>
                            </Td>
                        ))}
                    </Tr>
                );
            })}
        </>
    );
};
