import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { DatePicker } from "../../components/CustomDatePicker";

import { connect } from "react-redux";

const options = {
    chart: {
        type: "pie",
    },
    title: {
        text: "My chart",
    },
    series: [
        {
            data: [1, 2, 3],
        },
    ],
};

class PurchaseOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
            filter_fields: {
                started_date: new Date(),
                end_date: new Date(),
            },
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        //(this.props)
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Rapport des commandes"></TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Options des rapports</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date,
                                            startDate_error: false,
                                        });
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => {
                                        this.setState({
                                            endDate: date,
                                            endDate_error: false,
                                        });
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de fin*"
                                    id="end_date_id"
                                />
                            </Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
                <Wrapper>
                    <div className="container4" style={{ marginLeft: "-0.5%", marginRight: "-0.5%" }}>
                        <div className="grid2">
                            <div className="bloc">
                                <div className="bloc_header">
                                    <h2>Graphique du chiffre d’affaires</h2>
                                </div>
                                <div className="bloc_content tdb">
                                    <div className="bloc2" style={{ width: "100%" }}>
                                        <HighchartsReact highcharts={Highcharts} options={options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid2">
                            <div className="bloc">
                                <div className="bloc_header">
                                    <h2>Graphique du nombre de commandes</h2>
                                </div>
                                <div className="bloc_content tdb">
                                    <div className="bloc2" style={{ width: "100%" }}>
                                        <HighchartsReact highcharts={Highcharts} options={options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wrapper>
                <Bloc2 className="Bloc">
                    <div className="bloc_header">
                        <h2>Statistiques des commandes par délégué</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    <Th>Délégué</Th>
                                    <Th>Nombre de commandes</Th>
                                    <Th>Chiffre d'affaires</Th>
                                </tr>
                            </thead>

                            <tbody>
                                {[].length !== 0 &&
                                    [].map((contact, index) => {
                                        return (
                                            <Tr
                                                key={"key_" + index}
                                                onClick={() => {
                                                    this.props.history.push("/details");
                                                }}
                                            >
                                                <Td>{"contact.prenom"}</Td>
                                                <Td>{"contact.nom"}</Td>
                                                <Td>{"contact.portable"}</Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td>{"contact.prenom"}</Td>
                                    <Td>{"contact.nom"}</Td>
                                    <Td>{"contact.portable"}</Td>
                                </Tr>
                            </tbody>
                        </table>
                    </div>
                </Bloc2>
            </Main_container>
        );
    }
}

const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Bloc2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-top: 10px;
    background: #fff;
`;
const Wrapper = styled.div`
    width: 96.3%;
    position: relative;
    height: 459px;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
`;
const Th = styled.th`
    padding-right: 10px;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(PurchaseOrders);
