import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import { acl } from "../../services/acl";
import { RoutesPath } from "../../values/RoutesPath";
import styled from "styled-components";

import CustomInput from "../../components/CustomInput";
import CustomSelect from "../../components/CustomSelect";
import { DatePicker } from "../../components/CustomDatePicker";
import NoResultCas from "../../helpers/NoResultCas";
import ContentLoader from "react-content-loader";
import img from "../../images/sprite_3.png";
import AccountDAO from "../../DAO/AccountDAO";
import CommonDAO from "../../DAO/CommonDAO";
import SearchSelect from "../../helpers/SearchSelect";
import DataLoadingError from "../../components/DataLoadingError";
import { notify } from "../../helpers/FlashMessage";
import { MessagesText } from "../../values/MessagesText";
import { connect } from "react-redux";
import DemandDAO from "../../DAO/DemandDAO";
import moment from "moment";
import configuration from "../../config/configuration";

const INITIAL_SEARCH_FIELDS = {
    demand_num: {
        value: "",
        operator: "",
    },
    object: {
        value: "",
        operator: "",
    },
    assignedTo: {
        value: "",
        operator: "",
    },
    createAt: {
        value: "",
        operator: "",
    },
    channel: {
        value: "",
        operator: "",
    },
    priority: {
        value: "",
        operator: "",
    },
    lastActivity: {
        value: "",
        operator: "",
    },
    status: {
        value: "",
        operator: "",
    },
    contact: {
        value: "",
        operator: "",
    },
};
const PRIORITY_COLOR = {
    Normale: "#4674b9",
    Urgente: "#ffa902",
    Critique: "#E04052",
};
const STATUS_COLOR = {
    Ouvert: "#05b8c0",
    Résolu: "#00bf9c",
    Archivé: "#E04052",
    Nouveau: "#f6ac32",

};
class Demands extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
            advancedSearch: false,
            pageNumber: 1,
            totalItems: 0,
            isLoaded: true,
            data: [],
            error: {
                dataLoading: false,
                message: "error",
            },
            searchFiled: {
                ...INITIAL_SEARCH_FIELDS,
            },
            asyncSelect: {
                contact: null,
                assignedTo: null,
            },
            orderBy: 0,
            key: "",
        };
    }
    setSearchFieldValue = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            searchFiled: {
                ...prevState.searchFiled,
                [field]: {
                    ...prevState.searchFiled[field],
                    value: value,
                },
            },
        }));
    };
    setSearchAsyncSelect = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            asyncSelect: {
                ...prevState.asyncSelect,
                [field]: value,
            },
        }));
    };
    setSearchFieldOperator = (field, operator) => {
        this.setState((prevState) => ({
            ...prevState,
            searchFiled: {
                ...prevState.searchFiled,
                [field]: {
                    ...prevState.searchFiled[field],
                    operator: operator,
                },
            },
        }));
    };
    getSearchData = () => {
        const { searchFiled, advancedSearch, search } = this.state;

        if (advancedSearch) {
            let data = {};
            if (searchFiled.object.value && searchFiled.object.value.length != 0) {
                data["subject"] = {
                    value: searchFiled.object.value,
                    op: searchFiled.object.operator && searchFiled.object.operator.length != 0 ? searchFiled.object.operator : "smart",
                };
            }
            if (searchFiled.demand_num.value && searchFiled.demand_num.value.length != 0) {
                data["demandNumber"] = {
                    value: searchFiled.demand_num.value,
                    op: searchFiled.demand_num.operator && searchFiled.demand_num.operator.length != 0 ? searchFiled.demand_num.operator : "is",
                };
            }
            if (searchFiled.contact.value && searchFiled.contact.value.length != 0) {
                data["contactId"] = {
                    value: searchFiled.contact.value,
                    op: searchFiled.contact.operator && searchFiled.contact.operator.length != 0 ? searchFiled.contact.operator : "is",
                };
            }
            if (searchFiled.assignedTo.value && searchFiled.assignedTo.value.length != 0) {
                data["assignedTo"] = {
                    value: searchFiled.assignedTo.value,
                    op: searchFiled.assignedTo.operator && searchFiled.assignedTo.operator.length != 0 ? searchFiled.assignedTo.operator : "is",
                };
            }
            if (searchFiled.priority.value && searchFiled.priority.value.length != 0) {
                data["priority"] = {
                    value: searchFiled.priority.value,
                    op: searchFiled.priority.operator && searchFiled.priority.operator.length != 0 ? searchFiled.priority.operator : "is",
                };
            }
            if (searchFiled.channel.value && searchFiled.channel.value.length != 0) {
                data["channel"] = {
                    value: searchFiled.channel.value,
                    op: searchFiled.channel.operator && searchFiled.channel.operator.length != 0 ? searchFiled.channel.operator : "is",
                };
            }
            if (searchFiled.lastActivity.value && searchFiled.lastActivity.value.length != 0) {
                data["lastActionAt"] = {
                    value: moment(searchFiled.lastActivity.value).format("YYYY-MM-DD"),
                    op: searchFiled.lastActivity.operator && searchFiled.lastActivity.operator.length != 0 ? searchFiled.lastActivity.operator : "is",
                };
            }
            if (searchFiled.status.value && searchFiled.status.value.length != 0) {
                data["lastStatus"] = {
                    value: searchFiled.status.value,
                    op: searchFiled.status.operator && searchFiled.status.operator.length != 0 ? searchFiled.status.operator : "is",
                };
            }
            if (searchFiled.createAt.value && searchFiled.createAt.value.length != 0) {
                data["createdAt"] = {
                    value: moment(searchFiled.createAt.value).format("YYYY-MM-DD"),
                    op: searchFiled.createAt.operator && searchFiled.createAt.operator.length != 0 ? searchFiled.createAt.operator : "is",
                };
            }
            return data;
        } else if (search) {
            let data = {};
            if (searchFiled.object.value && searchFiled.object.value.length != 0) {
                data["subject"] = {
                    value: searchFiled.object.value,
                    op: "smart",
                };
            }
            if (searchFiled.demand_num.value && searchFiled.demand_num.value.length != 0) {
                data["demandNumber"] = {
                    value: searchFiled.demand_num.value,
                    op: "is",
                };
            }
            if (searchFiled.contact && searchFiled.contact.value && searchFiled.contact.value.length != 0) {
                data["contactId"] = {
                    value: searchFiled.contact.value,
                    op: "is",
                };
            }
            if (searchFiled.assignedTo.value && searchFiled.assignedTo.value.length != 0) {
                data["assignedTo"] = {
                    value: searchFiled.assignedTo.value,
                    op: "is",
                };
            }
            if (searchFiled.priority.value && searchFiled.priority.value.length != 0) {
                data["priority"] = {
                    value: searchFiled.priority.value,
                    op: "is",
                };
            }
            if (searchFiled.channel.value && searchFiled.channel.value.length != 0) {
                data["channel"] = {
                    value: searchFiled.channel.value,
                    op: "is",
                };
            }
            if (searchFiled.lastActivity.value && searchFiled.lastActivity.value.length != 0) {
                data["lastActionAt"] = {
                    value: moment(searchFiled.lastActivity.value).format("YYYY-MM-DD"),
                    op: "is",
                };
            }
            if (searchFiled.status.value && searchFiled.status.value.length != 0) {
                data["lastStatus"] = {
                    value: searchFiled.status.value,
                    op: "is",
                };
            }
            if (searchFiled.createAt.value && searchFiled.createAt.value.length != 0) {
                data["createdAt"] = {
                    value: moment(searchFiled.createAt.value).format("YYYY-MM-DD"),
                    op: "is",
                };
            }
            return data;
        }
        return {};
    };
    componentDidMount() {
        document.title = "Demandes - Sobrus Labs";
        this.preload();
    }
    componentWillUnmount() {
        this.storeSearchInCookies();
        window.removeEventListener('beforeunload', this.storeSearchInCookies);
    }

    preload = () => {
        this.getSearchFromCookies(this.getAll);
        window.addEventListener('beforeunload', this.storeSearchInCookies);
    }
    getSearchFromCookies = (callBack) => {
        const searchFromCookie = configuration.getSearchFromCookies("Demandes");
        if (searchFromCookie) {
            const searchFiledFormatted = {
                ...searchFromCookie.searchFiled,
                lastActivity: {
                    value: searchFromCookie.searchFiled.lastActivity.value && searchFromCookie.searchFiled.lastActivity.value.length !== 0 ? Date.parse(searchFromCookie.searchFiled.lastActivity.value) : "",
                    operator: searchFromCookie.searchFiled.lastActivity.operator
                },
                createAt: {
                    value: searchFromCookie.searchFiled.createAt.value && searchFromCookie.searchFiled.createAt.value.length !== 0 ? Date.parse(searchFromCookie.searchFiled.createAt.value) : "",
                    operator: searchFromCookie.searchFiled.createAt.operator
                }
            }
            this.setState((prevState) => ({
                ...prevState,
                searchFiled: {
                    ...prevState.searchFiled,
                    ...searchFiledFormatted,
                },
                asyncSelect: {
                    ...prevState.asyncSelect,
                    ...searchFromCookie.asyncSelect,
                },
                advancedSearch: searchFromCookie.advancedSearch,
                search: searchFromCookie.search,
            }), () => {
                callBack();
            })
        } else {
            callBack()
        }
    }
    storeSearchInCookies = () => {
        const { search, advancedSearch, searchFiled, asyncSelect } = this.state;
        const searchFiledTmp = {
            ...searchFiled,
            lastActivity: {
                value: String(searchFiled.lastActivity.value),
                operator: searchFiled.lastActivity.operator,
            },
        }

        configuration.storeSearchInCookies({ search, advancedSearch, searchFiled: searchFiledTmp, asyncSelect }, "Demandes");
    }

    getAll = async () => {
        try {
            const { orderBy, key } = this.state;
            let data = this.getSearchData();
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }
            this.setState({ isLoaded: false });
            const response = await DemandDAO.getALL(this.state.pageNumber, {}, data);
            this.setState({ isLoaded: true });
            console.log("all demandes", response)
            this.setState({ data: response.data.response.demands, totalItems: response.data.response.total });
        } catch (error) {
            console.log("error", error)
            this.setState({ isLoaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    };
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    render() {
        const { disabledData, advancedSearch, search, searchFiled, key, orderBy, isLoaded, data, pageNumber, totalItems, error, asyncSelect } = this.state;
        return (
            <div>
                <TopOptions title="Demandes">
                    {/* <button
                        className="btn_white"
                        data-shortcut_alias="create"
                        onClick={() => {
                            this.setState({ disabledData: disabledData === 1 ? 0 : 1 }, () => this.getAll());
                        }}
                    >
                        {disabledData === 0 ? " Archives" : "Actuel"}
                    </button> */}
                    {/* if disabledData === 0 the create btn should not mounted  */}
                    {acl.demand.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(RoutesPath.demand.add)}>
                            Créer
                        </button>
                    )}
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{disabledData === 0 ? "Liste des demandes" : "Liste des demandes"}</h2>
                        <div className="bloc_header_btns">
                            <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                            <a onClick={() => this.setState({ searchFiled: INITIAL_SEARCH_FIELDS, pageNumber: 1, asyncSelect: { contact: null, assignedTo: null, }, }, this.getAll)} className="sprite-bloc-reload"></a>
                            <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                        </div>
                    </div>
                    <TableWrapper className="bloc_content table_wrraper">
                        <table className="table processableTable" id="table">
                            <thead>
                                {advancedSearch && (
                                    <tr className="Demands__table-tr table_th_orderable">
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    maxWidth="136px"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("demand_num", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.demand_num.operator}
                                                    label="N°"
                                                >
                                                    <option defaultValue></option>
                                                    <option value="like">contient</option>
                                                    <option value="starts_with">commence par</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect

                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("object", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.object.operator}
                                                    label="Objet"
                                                >
                                                    <option defaultValue></option>
                                                    <option value="like">contient</option>
                                                    <option value="starts_with">commence par</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect

                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("contact", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.contact.operator}
                                                    label="Contact"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("assignedTo", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.assignedTo.operator}
                                                    label="Assigné à "
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("createAt", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.createAt.operator}
                                                    label="Date de création"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("lastActivity", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.lastActivity.operator}
                                                    label="Dernière activité"
                                                />
                                            </Search_input_container>
                                        </Th>

                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    maxWidth="136px"
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("priority", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.priority.operator}
                                                    label="Priorité"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    maxWidth="136px"
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("channel", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.channel.operator}
                                                    label="Canal"
                                                />
                                            </Search_input_container>
                                        </Th>

                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    maxWidth="136px"
                                                    operatorsType="short"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldOperator("status", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.status.operator}
                                                    label="Statut"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            {advancedSearch && (
                                                <Search_btn onClick={() => this.setState({ pageNumber: 1 }, this.getAll)}>
                                                    <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#fff"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Search_btn_icon>
                                                </Search_btn>
                                            )}
                                        </Th>
                                    </tr>
                                )}

                                {(search || advancedSearch) && (
                                    <SearchTr className="table_th_orderable">
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomInput
                                                    maxWidth="85px"
                                                    minWidth="70px"
                                                    searchIcon={true}
                                                    value={searchFiled.demand_num.value}
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    onChange={(value) => {
                                                        this.setSearchFieldValue("demand_num", value.target.value);
                                                    }}
                                                    label="N°"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomInput
                                                    searchIcon={true}
                                                    value={searchFiled.object.value}
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    onChange={(value) => {
                                                        this.setSearchFieldValue("object", value.target.value);
                                                    }}
                                                    label="Objet"
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <SearchSelect
                                                    fetchedUrl="/api/contacts"
                                                    HTTPMethod="post"
                                                    receivedObject="contacts"
                                                    optionLabelProperty="fullname"
                                                    optionValueProperty="id"
                                                    value={asyncSelect.contact}
                                                    placeholder="contact"
                                                    onChange={(e) => {
                                                        this.setSearchAsyncSelect("contact", e);
                                                        this.setSearchFieldValue("contact", e && e.value);
                                                    }}
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <SearchSelect
                                                    fetchedUrl="/api/utilisateurs"
                                                    HTTPMethod="post"
                                                    receivedObject="utilisateurs"
                                                    optionLabelProperty="fullname"
                                                    optionValueProperty="id"
                                                    value={asyncSelect.assignedTo}
                                                    placeholder="Assigné à"
                                                    onChange={(e) => {
                                                        this.setSearchAsyncSelect("assignedTo", e);
                                                        this.setSearchFieldValue("assignedTo", e && e.value);
                                                    }}
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <DatePicker
                                                    error={false}
                                                    selected={searchFiled.createAt.value}
                                                    onChange={(date) => {
                                                        this.setSearchFieldValue("createAt", date);
                                                    }}
                                                    label="Date de création"
                                                    id="create_date"
                                                    dateFormat="yyyy-MM-dd"
                                                    showTimeSelect={false}
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <DatePicker
                                                    error={false}
                                                    selected={searchFiled.lastActivity.value}
                                                    onChange={(date) => {
                                                        console.log("date", date)
                                                        this.setSearchFieldValue("lastActivity", date);
                                                    }}
                                                    label="Dernière activité"
                                                    id="lastActivity"
                                                    dateFormat="yyyy-MM-dd"
                                                    showTimeSelect={false}
                                                />
                                            </Search_input_container>
                                        </Th>

                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    value={searchFiled.priority.value}
                                                    onChange={(value) => {
                                                        this.setSearchFieldValue("priority", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.priority.value}
                                                    label="Priorité"
                                                >
                                                    <option defaultValue></option>

                                                    <option value="Normale">Normale</option>
                                                    <option value="Urgente">Urgente</option>
                                                    <option value="Critique">Critique</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    maxWidth="136px"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldValue("channel", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.channel.value}
                                                    label="Canal"
                                                >
                                                    <option defaultValue></option>

                                                    <option value="E-mail">E-mail</option>
                                                    <option value="Téléphone">Téléphone</option>
                                                    <option value="Equipe terrain">Équipe terrain</option>
                                                    <option value="Réseaux sociaux">Réseaux sociaux</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    minWidth="70px"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setSearchFieldValue("status", value.target.value);
                                                    }}
                                                    selectedValue={searchFiled.status.value}
                                                    label="Statut"
                                                >
                                                    <option defaultValue></option>
                                                    <option value="Ouvert">Ouvert</option>
                                                    <option value="Résolu">Résolu</option>
                                                    <option value="Archivé">Archivé</option>
                                                    <option value="Nouveau">Nouveau</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            {search && (
                                                <Search_btn
                                                    onClick={() => {
                                                        this.setState({ pageNumber: 1 }, this.getAll);
                                                    }}
                                                >
                                                    <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#fff"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Search_btn_icon>
                                                </Search_btn>
                                            )}
                                        </Th>
                                    </SearchTr>
                                )}
                                <tr className="table_th_orderable">
                                    <Th>
                                        N°
                                    </Th>
                                    <Th>
                                        Objet
                                    </Th>
                                    <Th>
                                        Contact
                                    </Th>
                                    <Th>
                                        Assigné à
                                    </Th>
                                    <Th>
                                        Date de création
                                    </Th>

                                    <Th>
                                        Dernière activité
                                    </Th>
                                    <Th>
                                        Priorité
                                    </Th>
                                    <Th>
                                        Canal
                                    </Th>
                                    <Th>
                                        Statut
                                    </Th>
                                    <Th className="table_actions" />
                                </tr>
                                {/* <tr className="table_th_orderable">
                                    <Th key="demandNumber" orderBy={key === "demandNumber" && orderBy} onClick={() => this.setState({ key: "demandNumber", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "demandNumber" && orderBy === -1) || orderBy === 0 || key !== "demandNumber") && <OrderUp />}
                                        {((key === "demandNumber" && orderBy === 1) || orderBy === 0 || key !== "demandNumber") && <OrderDown />}
                                        N°
                                    </Th>
                                    <Th key="subject" orderBy={key === "subject" && orderBy} onClick={() => this.setState({ key: "subject", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "subject" && orderBy === -1) || orderBy === 0 || key !== "subject") && <OrderUp />}
                                        {((key === "subject" && orderBy === 1) || orderBy === 0 || key !== "subject") && <OrderDown />}
                                        Objet
                                    </Th>
                                    <Th key="contactName" orderBy={key === "contactName" && orderBy} onClick={() => this.setState({ key: "contactName", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "contactName" && orderBy === -1) || orderBy === 0 || key !== "contactName") && <OrderUp />}
                                        {((key === "contactName" && orderBy === 1) || orderBy === 0 || key !== "contactName") && <OrderDown />}
                                        Contact
                                    </Th>
                                    <Th key="assignedTo" orderBy={key === "assignedTo" && orderBy} onClick={() => this.setState({ key: "assignedTo", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "assignedTo" && orderBy === -1) || orderBy === 0 || key !== "assignedTo") && <OrderUp />}
                                        {((key === "assignedTo" && orderBy === 1) || orderBy === 0 || key !== "assignedTo") && <OrderDown />}
                                        Assigné à
                                    </Th>
                                    <Th key="createdAt" orderBy={key === "createdAt" && orderBy} onClick={() => this.setState({ key: "createdAt", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "createdAt" && orderBy === -1) || orderBy === 0 || key !== "createdAt") && <OrderUp />}
                                        {((key === "createdAt" && orderBy === 1) || orderBy === 0 || key !== "createdAt") && <OrderDown />}
                                        Date de création
                                    </Th>

                                    <Th key="lastActionAt" orderBy={key === "lastActionAt" && orderBy} onClick={() => this.setState({ key: "lastActionAt", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "lastActionAt" && orderBy === -1) || orderBy === 0 || key !== "lastActionAt") && <OrderUp />}
                                        {((key === "lastActionAt" && orderBy === 1) || orderBy === 0 || key !== "lastActionAt") && <OrderDown />}
                                        Dernière activité
                                    </Th>
                                    <Th key="priority" orderBy={key === "priority" && orderBy} onClick={() => this.setState({ key: "priority", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "priority" && orderBy === -1) || orderBy === 0 || key !== "priority") && <OrderUp />}
                                        {((key === "priority" && orderBy === 1) || orderBy === 0 || key !== "priority") && <OrderDown />}
                                        Priorité
                                    </Th>
                                    <Th key="channel" orderBy={key === "channel" && orderBy} onClick={() => this.setState({ key: "channel", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "channel" && orderBy === -1) || orderBy === 0 || key !== "channel") && <OrderUp />}
                                        {((key === "channel" && orderBy === 1) || orderBy === 0 || key !== "channel") && <OrderDown />}
                                        Canal
                                    </Th>
                                    <Th key="lastStatus" orderBy={key === "lastStatus" && orderBy} onClick={() => this.setState({ key: "lastStatus", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "lastStatus" && orderBy === -1) || orderBy === 0 || key !== "lastStatus") && <OrderUp />}
                                        {((key === "lastStatus" && orderBy === 1) || orderBy === 0 || key !== "lastStatus") && <OrderDown />}
                                        Statut
                                    </Th>
                                    <Th className="table_actions" />
                                </tr> */}
                            </thead>
                            {!isLoaded && <Loading />}
                            {isLoaded && data && data.length != 0 && (
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && item.demandNumber}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && item.subject}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && item.contactName}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && item.assignedToName}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {/* {item && item.statusHistoryList && item.statusHistoryList.length !== 0 && item.statusHistoryList[item.statusHistoryList.length - 1].actionDate ? moment(item.statusHistoryList[item.statusHistoryList.length - 1].actionDate).format("YYYY-MM-DD HH:mm") : ""} */}
                                                    {/* {item && item.statusHistoryList && item.statusHistoryList.length !== 0 && item.statusHistoryList.pop().actionDate ? item.statusHistoryList.pop().id : ""} */}

                                                    {/* {item && item.lastStatus && item.lastStatus.actionDate && moment(item.lastStatus.actionDate).format("YYYY-MM-DD HH:mm")} */}
                                                    {item && item.updatedAt ? moment(item.updatedAt).format("YYYY-MM-DD HH:mm") : ""}

                                                </Td>

                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    <PriorityContainer2 bgColor={PRIORITY_COLOR[item && item.priority]}>{item && item.priority}</PriorityContainer2>
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    {item && item.channel}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                    }}
                                                >
                                                    <PriorityContainer bgColor={STATUS_COLOR[item && item.lastStatus && item.lastStatus.status]}> {item && item.lastStatus && item.lastStatus.status}</PriorityContainer>
                                                </Td>

                                                <Td className="table_action">
                                                    {acl.demand.details() && acl.demand.update() && item && item.lastStatus && item.lastStatus.status === "Ouvert" && (
                                                        <a
                                                            onClick={() => {
                                                                this.props.history.push(`${RoutesPath.demand.update}/${item.id}`);
                                                            }}
                                                            className="sprite-table-edit-btn  tooltip"
                                                            title="Modifier"
                                                            data-confirm_msg
                                                            data-removeable_element="parents::tr"
                                                            target="_blank"
                                                        />
                                                    )}
                                                    {/* { acl.demand.disable() && <a onClick={() => this.delete(item.id)} className="sprite-table-delete-btn  tooltip" title="Archiver" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />}
                                                    { acl.demand.restore() && <a onClick={() => this.restore(item.id)} className="sprite-table-recover-btn restore_delivery_note tooltip" title="Restaurer" data-confirm_msg="" data-removeable_element="parents::tr"></a>} */}
                                                </Td>
                                            </Tr>
                                        );
                                    })}

                                    <Tr>
                                        <Td colSpan={10} className="table_pagination">
                                            <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                            <div style={{ display: "inline-block" }}>
                                                {pageNumber > 1 && (
                                                    <a
                                                        className="sprite-table-prev-btn"
                                                        onClick={() => {
                                                            this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                                this.getAll();
                                                            });
                                                        }}
                                                    />
                                                )}
                                                <span className="table_page">{pageNumber}</span>
                                                {pageNumber < totalItems / 20 && (
                                                    <a
                                                        className="sprite-table-next-btn"
                                                        onClick={() => {
                                                            this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                                this.getAll();
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </Td>
                                    </Tr>
                                </tbody>
                            )}
                        </table>
                        {isLoaded && error.dataLoading && <DataLoadingError message={error.message} />}

                        {/* if disabledData === 0 the create btn should not mounted  */}
                        {isLoaded && data && data.length === 0 && (
                            <NoResultCas btnOnClick={() => acl.demand.add() && this.props.history.push(`${RoutesPath.demand.add}`)} btnText={acl.demand.add() && disabledData === 0 ? "Créer une demande" : ""} textInfos="Vous n’avez aucune demande pour le moment"></NoResultCas>
                        )}
                    </TableWrapper>
                </Bloc>
            </div>
        );
    }
}

const Loading = () => (
    <tbody>
        {[1, 2, 3].map((index) => {
            return (
                <Tr key={"key__" + index}>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="80" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td className="table_action"></Td>
                </Tr>
            );
        })}
    </tbody>
);

const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
    max-width: 199px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const PriorityContainer = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width:81px;
    text-align:center;
`;
const PriorityContainer2 = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width:78px;
`;

export default Demands;
