import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../services/Auth";
import Layout from "../helpers/Layout";
import Header from "../helpers/Header";
import { connect } from "react-redux";
import Headband from "./Headband";
import Acl from "../services/acl";
import styled from "styled-components";
import FlashMessage from "./FlashMessage";

const ProtectedRoute = ({ component: Component, actionName, ...rest }) => {
    rest.dispatch({ value: rest.location.pathname, type: "CURRENT_PATH_NAME" });
    if (Auth.isAuthenticated()) {
        if (actionName === "authorized") {
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        return (
                            <ComponentContainer className="component-container">
                                <HeaderContainer>
                                    <Headband {...props} />
                                    <Header {...props} />
                                </HeaderContainer>
                                <FlashMessage />
                                <Component {...props}></Component>
                            </ComponentContainer>
                        );
                    }}
                />
            );
        } else if (Acl.can(actionName)) {
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        return (
                            <ComponentContainer className="component-container">
                                <HeaderContainer>
                                    <Headband {...props} />
                                    <Header {...props} />
                                </HeaderContainer>
                                <FlashMessage />
                                <Component {...props}></Component>
                            </ComponentContainer>
                        );
                    }}
                />
            );
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/notAuthorized",
                        state: {
                            from: rest.location,
                        },
                    }}
                />
            );
        }
    } else {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: {
                        from: rest.location,
                    },
                }}
            />
        );
    }
};
const mapStateToProps = (state) => {
    return {
        currentPathName: state.Auth.currentPathName,
        FlashMessage: state.FlashMessage.message,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(ProtectedRoute);

const HeaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999999999;
`;
const ComponentContainer = styled.div`
    position: relative;
    padding-top: 100px;
`;
// if (acl_actions.length !== 0) {
//     const acl = new Acl(rest.acl_actions);

//     if ((actionName = "api_app_home_index")) {
//         return (
//             <Route
//                 {...rest}
//                 render={(props) => {
//                     if (Auth.isAuthenticated()) {
//                         return (
//                             <div>
//                                 <Headband {...props} />
//                                 <Header {...props} />
//                                 <Component {...props}></Component>
//                             </div>
//                         );
//                     } else {
//                         return (
//                             <Redirect
//                                 to={{
//                                     pathname: "/login",
//                                     state: {
//                                         from: props.location,
//                                     },
//                                 }}
//                             />
//                         );
//                     }
//                 }}
//             />
//         );
//     } else if (acl.can(actionName)) {
//         return <Route {...rest} render={(props) => {}} />;
//     }
//     return (
//         <Redirect
//             to={{
//                 pathname: "/",
//                 state: {
//                     from: rest.location,
//                 },
//             }}
//         />
//     );
// }
// return <div></div>;

// if (acl.can(actionName)) {
//     return (
//         <Route
//             {...rest}
//             render={(props) => {
//                 if (Auth.isAuthenticated()) {
//                     return (
//                         <div>
//                             <Headband {...rest} />
//                             <Header {...rest} />
//                             <Component {...rest}></Component>
//                         </div>
//                     );
//                 } else {
//                     return (
//                         <Redirect
//                             to={{
//                                 pathname: "/login",
//                                 state: {
//                                     from: rest.location,
//                                 },
//                             }}
//                         />
//                     );
//                 }
//             }}
//         />
//     );
// } else {
//     return (
//         <Redirect
//             to={{
//                 pathname: "/",
//                 state: {
//                     from: rest.location,
//                 },
//             }}
//         />
//     );
// }

{
    /* <Route 
{...rest}

render = {
    (props)=>{
        // if(Auth.isAuthenticated()){
                return <div><Header {...props} /><Component {...props}></Component></div>
        // }else{
               rest.dispatch({ value: rest.location.pathname, type: "CURRENT_PATH_NAME" })
        //     return <Redirect to={{
        //         pathname:"/login",
        //         state:{
        //             from : props.location
        //         }
        //     }}/>
        // }
    }
}
/> */
}

// if (acl_actions.length !== 0) {
//     const acl = new Acl(rest.acl_actions);

//     if ((actionName = "api_app_home_index")) {
//         return (
//             <Route
//                 {...rest}
//                 render={(props) => {
//                     if (Auth.isAuthenticated()) {
//                         return (
//                             <div>
//                                 <Headband {...props} />
//                                 <Header {...props} />
//                                 <Component {...props}></Component>
//                             </div>
//                         );
//                     } else {
//                         return (
//                             <Redirect
//                                 to={{
//                                     pathname: "/login",
//                                     state: {
//                                         from: props.location,
//                                     },
//                                 }}
//                             />
//                         );
//                     }
//                 }}
//             />
//         );
//     } else if (acl.can(actionName)) {
//         return (
//             <Route
//                 {...rest}
//                 render={(props) => {
//                     if (Auth.isAuthenticated()) {
//                         return (
//                             <div>
//                                 <Headband {...props} />
//                                 <Header {...props} />
//                                 <Component {...props}></Component>
//                             </div>
//                         );
//                     } else {
//                         return (
//                             <Redirect
//                                 to={{
//                                     pathname: "/login",
//                                     state: {
//                                         from: props.location,
//                                     },
//                                 }}
//                             />
//                         );
//                     }
//                 }}
//             />
//         );
//     }
//     return (
//         <Redirect
//             to={{
//                 pathname: "/",
//                 state: {
//                     from: rest.location,
//                 },
//             }}
//         />
//     );
// }
// return <div></div>;
