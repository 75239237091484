import React, { Component } from "react";
import "./loading-style.css";

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="loading-container">
                <img className="sobrus-logo" src={require("../../images/logo_login1.png")} alt="loading"></img>
                <div className="loader-container">
                    <div className="loader-item"></div>
                </div>
            </div>
        );
    }
}

export default Loading;
