import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link, Redirect } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpAccounts from "../components/PopUpAccounts";
import Axios from "axios";
import CustomDatePicker from "../components/CustomDatePicker";
// import DatePicker from "react-datepicker";
import FlashMessages from "../helpers/FlashMessages";
import moment from "moment";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import UserDAO from "../DAO/UserDAO";
import InventoryDAO from "../DAO/InventoryDAO";
import AccountDAO from "../DAO/AccountDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import { DatePicker } from "../components/CustomDatePicker";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";

class AddInventoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: "",
            accounts: [],
            id_account: "",
            account_error: false,
            accountItemsNumber: 0,

            comment: "",
            date: new Date(),
            date_error: false,
            all_products: false,
            products: [],
            ready: false,
            users: [],
            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            data: null,
            inventory_id: 0,
            loading: false,
            users: [],

            manager: "",
            id_manager: "",
            error_manager: false,
        };
    }
    componentDidMount() {
        document.title = "Inventaires - Sobrus Labs";
        const id = this.props.match.params.id;
        const id_account = this.props.match.params.id_account;
        if (id) {
            this.setState({ inventory_id: id });
            this.get();
        }
        if (id_account) {
            this.getAccount(id_account);
        }
        this.getUsers();
        !id && this.getConnectedUserInfos();
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getAccount(id) {
        try {
            const response = await AccountDAO.getAccountById(id, {});
            this.setAccount(response.data);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            this.setState({ loading: true });
            const response = await InventoryDAO.getInventoryById(id, {});

            if (response && response.data && response.data.statut && response.data.statut.toLocaleLowerCase() === "brouillon") {
                this.setState({
                    loading: false,
                    account: response.data.compte,
                    id_account: response.data.compte_id,
                    comment: response.data.commentaire,
                    date: Date.parse(response.data.date),
                    all_products: response.data.tous_produits,
                    id_manager: response.data.gestionnaire_id,
                });
            } else {
                notify({ msg: `${MessagesText.inventory.unauthorized} ${response && response.data && response.data.statut && response.data.statut.toLocaleLowerCase()}`, type: "warning" });
                this.props.history.push(`${RoutesPath.inventory.details}/${id}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequired() {
        const { id_account, comment, date, all_products, id_manager } = this.state;
        const id = this.state.inventory_id;
        if (id_account && id_account.length !== 0 && date && date.length !== 0 && id_manager && id_manager.length !== 0) {
            const data = {
                date: moment(date).format("YYYY-MM-DD"),
                commentaire: comment,
                compte: id_account,
                gestionnaire: id_manager,
            };
            if (!id) {
                data["tous_produits"] = all_products;
            }
            return data;
        } else {
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!date || (date && date.length === 0)) {
                this.setState({ date_error: true });
            }
            if (!id_manager || (id_manager && id_manager.length === 0)) {
                this.setState({ error_manager: true });
            }
            return null;
        }
    }

    async add() {
        try {
            const { comment, date, all_products } = this.state;
            const data = this.verifyRequired();
            if (data) {
                this.setState({ loading: true });
                const response = await InventoryDAO.addInventory({}, data);
                this.setState({ loading: false });
                if ((response.data.status = "ok")) {
                    notify({ msg: MessagesText.inventory.add, type: "success" });
                    this.props.history.push(`${RoutesPath.inventory.product_list}/${response.data.inventaire.id}`, { comment: comment, date: date });
                } else if ((response.data.status = "ko")) {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
            this.setState({ loading: false });
        }
    }

    async update() {
        try {
            const data = this.verifyRequired();
            if (data) {
                const id = this.state.inventory_id;
                this.setState({ loading: true });
                const response = await InventoryDAO.updateInventory(id, {}, data);

                this.setState({ loading: false });
                if ((response.data.status = "ok")) {
                    notify({ msg: MessagesText.inventory.update, type: "success" });
                    this.props.history.push(`${RoutesPath.inventory.details}/${id}`);
                } else if ((response.data.status = "ko")) {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ account: value.libelle, id_account: value.id });
        this.closePopUp();
    }

    setAccount(value) {
        this.setState({ account: `${value.libelle} (${value.ville ? value.ville : ""})`, id_account: value.id, account_error: false });
    }
    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }

    async getAccounts(data, pageNumber = 1) {
        try {
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { id_manager, error_manager, users, loading, inventory_id, date_error, account_error, accounts, accountItemsNumber, all_products, date, comment, openPopUp, account, first_name_error, errorMessage, data, products } = this.state;
        const title = inventory_id ? `Modifier Inventaire : ${moment(date).format("YYYY-MM-DD")}` : "Créer un inventaire";
        const id = this.state.inventory_id;
        // const StartDate = ({ value, onClick }) => <CustomDatePicker error={date_error} onClick={onClick} defaultValue={value} id="name1" label={"Date*"}></CustomDatePicker>;
        if (!loading)
            return (
                <Main_container className="Main_container">
                    <FlashMessages successMessage={""} errorMessage={errorMessage} />
                    <PopUp></PopUp>
                    {loading && <FromLoading />}
                    <TopOptions title={title}>
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (inventory_id != 0 ? this.update() : this.add())}>
                            Sauvegarder
                        </button>
                    </TopOptions>
                    <Bloc className="Bloc">
                        <div className="bloc_header">
                            <h2>{title}</h2>
                            <div className="bloc_header_btns"></div>
                        </div>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <PopUpGlobal
                                        name="account"
                                        label={"Compte*"}
                                        setValue={this.setAccount.bind(this)}
                                        reset={this.resetAccount.bind(this)}
                                        value={account}
                                        data={accounts}
                                        propertyName={"libelle"}
                                        searchData={this.getAccounts.bind(this)}
                                        itemsNumber={accountItemsNumber}
                                        getData={() => this.getAccounts()}
                                        error={account_error}
                                        modalTitle="Choisissez un compte"
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <DatePicker
                                        selected={this.state.date}
                                        onChange={(date) => {
                                            this.setState({
                                                date: date,
                                                date_error: false,
                                            });
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        showTimeSelect={false}
                                        label="Date*"
                                        id="end_date_id"
                                        error={date_error}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect
                                        error={error_manager}
                                        onChange={(value) => {
                                            this.setState({ id_manager: value.target.value, error_manager: false });
                                        }}
                                        selectedValue={id_manager}
                                        label="Gestionnaire *"
                                    >
                                        <option defaultValue></option>
                                        {users.map((user, index) => {
                                            return (
                                                <option key={"key1_" + index} value={user.id}>
                                                    {user.fullname}
                                                </option>
                                            );
                                        })}
                                    </CustomSelect>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput
                                        error={first_name_error}
                                        onChange={(value) => {
                                            this.setState({ comment: value.target.value });
                                        }}
                                        defaultValue={comment}
                                        value={comment}
                                        id="comment"
                                        label="Commentaire"
                                    ></CustomInput>
                                </InputContainer>
                                {!id && (
                                    <InputContainer>
                                        <CustomSelect
                                            error={false}
                                            onChange={(value) => {
                                                this.setState({ all_products: value.target.value }, () => this.state.all_products);
                                            }}
                                            selectedValue={all_products}
                                            label="Tous les produits"
                                        >
                                            <option value="false" defaultValue>
                                                Afficher uniquement nos produits
                                            </option>

                                            <option value="true">Afficher tous les produits</option>
                                        </CustomSelect>
                                    </InputContainer>
                                )}
                            </Right>
                        </FormContainer>
                    </Bloc>
                </Main_container>
            );
        else return <></>;
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddInventoryPage);
