import AsyncSelect from "react-select/async";
import React, { Component } from "react";
import ApiCall from "../services/ApiCall";
import CommonFunctions from "../helpers/CommonFunctions";
import styled from "styled-components";
import Select from "react-select";

class SearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = async (inputValue = null, callback = () => { }) => {
        try {
            const { fetchedUrl, HTTPMethod, receivedObject, optionLabelProperty, optionValueProperty, optionLabelProperty_2, name } = this.props;
            // for URLS that use POST HTTP method

            if (HTTPMethod.toLocaleLowerCase() == "post") {
                let data = {
                    pagination: {
                        value: "OFF",
                    },
                };
                if (inputValue) {
                    data[optionLabelProperty] = {
                        value: inputValue,
                        op: "smart",
                    };
                }
                if (this.props.filterData) {
                    data = {
                        ...data,
                        ...this.props.filterData,
                    };
                }
                const response = await ApiCall.post(`${fetchedUrl}/1`, {}, data);
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = CommonFunctions.convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name);

                callback(convertedArray);
                // for URLS that use GET HTTP method
            } else if (HTTPMethod.toLocaleLowerCase() == "get") {
                let searchedKeyword = " ";
                if (inputValue) {
                    searchedKeyword = inputValue;
                }
                const response = await ApiCall.get(`${fetchedUrl}/${searchedKeyword}/smart/1`, {});
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = CommonFunctions.convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name);
                callback(convertedArray);
            }
        } catch (error) {
            this.setState({ is_loaded: false });
        }
    };

    render() {
        const { value, loadOptions, placeholder, onChange, defaultOptions, optionLabelProperty, optionValueProperty, optionLabelProperty_2, error, errorMessage, name, disabled } = this.props;
        return (
            <Container>
                <AsyncSelect
                    value={value}
                    width={"100%"}
                    escapeClearsValue={true}
                    isClearable={true}
                    loadOptions={this.getData}
                    placeholder={placeholder}
                    onChange={onChange}
                    isDisabled={disabled}
                    defaultOptions={CommonFunctions.convertDataForSelectBox(this.state.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name)}
                    styles={{
                        loadingIndicator: (provided, state) => ({
                            ...provided,
                            display: "none",
                        }),
                        indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: "none",
                        }),
                        container: (provided, state) => ({
                            ...provided,
                            minWidth: "159px",
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            borderColor: error ? "#FF068B" : "#bbc8d3",
                            boxShadow: "none",
                            backgroundColor: disabled ? "#f0f0f0" : "transparent",
                            ":hover": {
                                borderColor: error ? "#FF068B" : "#00beb0",
                            },
                        }),
                        input: (provided, state) => ({
                            ...provided,
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: "600",
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected && "#00beb0",
                            color: state.isSelected && "#fff",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: "600",
                            ":hover": {
                                color: "#fff",
                                backgroundColor: "#00beb0",
                            },
                        }),
                        clearIndicator: (provided, state) => ({
                            ...provided,
                            paddingRight: 0,
                        }),
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            paddingLeft: 0,
                        }),
                    }}
                />
                {error && (
                    <ErrorContainer>
                        <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#FF068B"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                            ></path>
                        </svg>
                        <ErrorText>{errorMessage ? errorMessage : `Ce champ est obligatoire`}</ErrorText>
                    </ErrorContainer>
                )}
            </Container>
        );
    }
}

export const NotAsyncSelect = ({ error, errorMessage, options, placeholder, value, onChange }) => {
    return (
        <Container>
            <Select
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                styles={{
                    loadingIndicator: (provided, state) => ({
                        ...provided,
                        display: "none",
                    }),
                    indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: "none",
                    }),
                    container: (provided, state) => ({
                        ...provided,
                        minWidth: "159px",
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        borderColor: error ? "#FF068B" : "#bbc8d3",
                        boxShadow: "none",
                        ":hover": {
                            borderColor: error ? "#FF068B" : "#00beb0",
                        },
                    }),
                    input: (provided, state) => ({
                        ...provided,
                        fontFamily: "Open Sans, sans-serif",
                        fontWeight: "600",
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected && "#00beb0",
                        color: state.isSelected && "#fff",
                        fontFamily: "Open Sans, sans-serif",
                        fontWeight: "600",
                        ":hover": {
                            color: "#fff",
                            backgroundColor: "#00beb0",
                        },
                    }),
                    clearIndicator: (provided, state) => ({
                        ...provided,
                        paddingRight: 0,
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        paddingLeft: 0,
                    }),
                }}
            />
            {error && (
                <ErrorContainer>
                    <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            fill="#FF068B"
                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                        ></path>
                    </svg>
                    <ErrorText>{errorMessage ? errorMessage : `Ce champ est obligatoire`}</ErrorText>
                </ErrorContainer>
            )}
        </Container>
    );
};

export class CustomStyleSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }
    componentDidMount() {
        this.getData();
    }

    getData = async (inputValue = null, callback = () => { }) => {
        try {
            const { fetchedUrl, HTTPMethod, receivedObject, optionLabelProperty, optionValueProperty, optionLabelProperty_2, name } = this.props;
            // for URLS that use POST HTTP method

            if (HTTPMethod.toLocaleLowerCase() == "post") {
                let data = {
                    pagination: {
                        value: "OFF",
                    },
                };
                if (inputValue) {
                    data[optionLabelProperty] = {
                        value: inputValue,
                        op: "smart",
                    };
                }
                if (this.props.filterData) {
                    data = {
                        ...data,
                        ...this.props.filterData,
                    };
                }
                const response = await ApiCall.post(`${fetchedUrl}/1`, {}, data);
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = CommonFunctions.convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name);

                callback(convertedArray);
                // for URLS that use GET HTTP method
            } else if (HTTPMethod.toLocaleLowerCase() == "get") {
                let searchedKeyword = " ";
                if (inputValue) {
                    searchedKeyword = inputValue;
                }
                const response = await ApiCall.get(`${fetchedUrl}/${searchedKeyword}/smart/1`, {});
                this.setState({ data: receivedObject ? response.data[receivedObject] : response.data });
                const convertedArray = CommonFunctions.convertDataForSelectBox(receivedObject ? response.data[receivedObject] : response.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name);
                callback(convertedArray);
            }
        } catch (error) {
            this.setState({ is_loaded: false });
        }
    };

    render() {
        const { value, loadOptions, placeholder, onChange, defaultOptions, optionLabelProperty, optionValueProperty, optionLabelProperty_2, error, errorMessage, name } = this.props;
        return (
            <CustomContainer>
                <AsyncSelect
                    value={value}
                    width={"100%"}
                    escapeClearsValue={true}
                    loadOptions={this.getData}
                    placeholder={placeholder}
                    onChange={onChange}
                    defaultOptions={CommonFunctions.convertDataForSelectBox(this.state.data, optionValueProperty, optionLabelProperty, optionLabelProperty_2, name)}
                    styles={{
                        loadingIndicator: (provided, state) => ({
                            ...provided,
                            display: "none",
                        }),
                        indicatorSeparator: (provided, state) => ({
                            ...provided,
                            display: "none",
                        }),
                        container: (provided, state) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                            minWidth: "159px",
                            marginLeft: "0px !important",
                        }),
                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: "100%",
                            width: "100%",
                        }),
                        indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: "100%",
                        }),
                        control: (provided, state) => ({
                            ...provided,
                            borderColor: error ? "#FF068B" : "#bbc8d3",
                            boxShadow: "none",
                            width: "100%",
                            height: "100%",
                            minHeight: "0px !important",
                            marginLeft: "0px !important",
                            ":hover": {
                                borderColor: error ? "#FF068B" : "#00beb0",
                            },
                        }),
                        input: (provided, state) => ({
                            ...provided,
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: "600",
                            paddingTop: 0,
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected && "#00beb0",
                            color: state.isSelected && "#fff",
                            fontFamily: "Open Sans, sans-serif",
                            fontWeight: "600",
                            ":hover": {
                                color: "#fff",
                                backgroundColor: "#00beb0",
                            },
                        }),
                        clearIndicator: (provided, state) => ({
                            ...provided,
                            paddingRight: 0,
                        }),
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            paddingLeft: 0,
                        }),
                    }}
                />
                {error && (
                    <ErrorContainer>
                        <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#FF068B"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                            ></path>
                        </svg>
                        <ErrorText>{errorMessage ? errorMessage : `Ce champ est obligatoire`}</ErrorText>
                    </ErrorContainer>
                )}
            </CustomContainer>
        );
    }
}
const CustomContainer = styled.div`
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
`;
const Container = styled.div`
    width: 100%;
    height: 100%;
`;
const ErrorText = styled.div`
    display: inline-block;
    color: #ff068b;
    font-size: 10px;
    vertical-align: top;
`;
const ErrorContainer = styled.div``;
export default SearchSelect;
