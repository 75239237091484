import styled from "styled-components";

export const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
export const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
export const Svg = styled.svg`
    width: 15px;
`;
export const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
export const Search_btn_icon = styled.svg`
    width: 15px;
`;
