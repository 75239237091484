import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpInput from "../components/PopUpInput";
import Axios from "axios";
import CustomColorInput from "../components/CustomColorInput";
import CustomDatePicker from "../components/CustomDatePicker";
import PopUpGlobal from "../components/PopUpGlobal.js";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import FlashMessages from "../helpers/FlashMessages";
import { Token } from "../tmp/Token";
import { errorMessage } from "../tmp/Messages";
import { connect } from "react-redux";
import AccountDAO from "../DAO/AccountDAO";
import ContactDAO from "../DAO/ContactDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import CommonDAO from "../DAO/CommonDAO";
import UserDAO from "../DAO/UserDAO";
import { RoutesPath } from "../values/RoutesPath";
import moment from "moment";
import { MessagesText } from "../values/MessagesText";
import ProductDAO from "../DAO/ProductDAO";
import { notify } from "../helpers/FlashMessage";

class AddCrEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relation_type: "Pharmacie",
            relation_type_error: false,
            account_type: "",
            account_type_error: false,
            resource: "",
            id_resource: "",
            error_resource: false,
            wording: "",
            error_wording: false,
            category: null,
            code: "",
            id_manager: "",
            error_manager: false,
            ready: false,
            account: null,
            users: [],

            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            accounts: [],
            contacts: [],

            name: "",
            startDate: new Date(),
            endDate: new Date(),
            type: "",
            desc: "",
            color: "",
            place: "",
            account: "",
            contact: "",
            addRapport: false,

            event_id: "",
            visitors: [],
            visitor: "",
            visitor_id: "",
            visited_at: new Date(),
            doctor_present: "Aucun",
            channel: "Terrain",
            concurrent_actions: [],
            concurrent_action: "",
            concurrent_action_id: "",
            revisited_at: new Date(),
            comment: "",
            event_goals: [],
            event_goal: "",
            event_goal_id: "",
            concurrents: [],
            concurrent: "",
            concurrent_id: "",
            provider: "",
            provider_id: "",
            range_products: [],
            range_product: "",
            range_product_id: "",
            sub_range_products: [],
            sub_range_product: [],
            sub_range_product_id: "",
            event_id: 0,

            visitor_loading: false,
        };
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        this.getUsers();
        this.getAccounts();
        this.getContacts();
        this.getVisitors({});
        this.getConcurrent_actions({});
        this.getEvent_goals({});
        this.getConcurrents({});
        this.getRange_products({});
        this.getSub_range_products({});
        const id = this.props.match.params.id;
        if (id) {
            this.setState({ event_id: id });
            this.get();
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await CalenderDAO.getEventById(id);
            const crevent = response.data.crevent[0];
            this.setState({
                crevent_id: crevent.id,
                event_id: id,
                visitor: crevent.utilisteur,
                visitor_id: crevent.utilisateur_id,
                visited_at: new Date(),
                doctor_present: crevent.docteur_present,
                channel: crevent.canal,
                concurrent_action: crevent.action_concurrence,
                concurrent_action_id: crevent.action_id,
                revisited_at: new Date(),
                comment: crevent.commentaire,
                event_goal: crevent.objectif,
                event_goal_id: crevent.objectif_id,
                concurrent: crevent.fournisseur1,
                concurrent_id: crevent.fournisseur_id_1,
                provider: crevent.fournisseur1,
                provider_id: crevent.fournisseur_id_1,
                range_product: crevent.gamme,
                range_product_id: crevent.gamme_id,
                sub_range_product: crevent.sous_gamme,
                sub_range_product_id: crevent.sous_gamme_id,
            });
        } catch (error) { }
    }

    async getUsers(data = {}, pageNumber = 1) {
        try {
            const data1 = {
                ...data,
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(pageNumber, {}, data1);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) { }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    showError(message) {
        this.setState({ errorMessage: message });
        setTimeout(() => {
            this.setState({ errorMessage: "" });
        }, 3000);
    }

    async getAccounts(data = {}, pageNumber = 1) {
        try {
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ accounts: response.data.comptes });
        } catch (error) {
            this.setState({ is_loaded: false });
        }
    }

    async getContacts(data, pageNumber = 1) {
        try {
            const response = await ContactDAO.getALLContacts(pageNumber, {}, data);
            this.setState({ contacts: response.data.contacts });
        } catch (error) { }
    }

    setVisitor(value) {
        this.setState({ visitor: value.fullname, visitor_id: value.id });
    }
    resetVisitor() {
        this.setState({ visitor: "", visitor_id: "" });
    }

    async getVisitors(data, pageNumber = 1) {
        try {
            this.setState({ visitor_loading: true });
            const response = await CommonDAO.getAllVisitors(pageNumber, {}, data);
            this.setState({ visitor_loading: false });

            this.setState({ visitors: response.data.utilisateurs });
        } catch (error) {
            this.setState({ visitor_loading: false });
        }
    }

    setConcurrent_action(value) {
        this.setState({ concurrent_action: value.nom, concurrent_action_id: value.id });
    }
    resetConcurrent_action() {
        this.setState({ concurrent_action: "", concurrent_action_id: "" });
    }

    async getConcurrent_actions(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            const response = await CommonDAO.getAllConcurrentActions(pageNumber, keyWord, {});
            this.setState({ concurrent_actions: response.data.ressources });
        } catch (error) { }
    }

    setEvent_goal(value) {
        this.setState({ event_goal: value.nom, event_goal_id: value.id });
    }
    resetEvent_goal() {
        this.setState({ event_goal: "", event_goal_id: "" });
    }

    async getEvent_goals(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            const response = await CommonDAO.getAllEventGoals(pageNumber, keyWord, {});
            this.setState({ event_goals: response.data.ressources });
        } catch (error) { }
    }

    setConcurrent(value) {
        this.setState({ concurrent: value.nom, concurrent_id: value.id });
    }
    resetConcurrent() {
        this.setState({ concurrent: "", concurrent_id: "" });
    }

    async getConcurrents(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            const response = await CommonDAO.getAllConcurrent(pageNumber, keyWord, {});
            this.setState({ concurrents: response.data.ressources });
        } catch (error) { }
    }

    setProvider(value) {
        this.setState({ provider: value.nom, provider_id: value.id });
    }

    resetProvider() {
        this.setState({ provider: "", provider_id: "" });
    }

    setRange_product(value) {
        this.setState({ range_product: value.nom, range_product_id: value.id });
    }

    resetRange_product() {
        this.setState({ range_product: "", range_product_id: "" });
    }

    async getRange_products(data, pageNumber = 1) {
        try {
            let formatData = {
                orderby: {
                    col: "id",
                    dir: "DESC",
                },
            };
            if (data.nom && data.nom.value) {
                formatData["nom"] = {
                    value: data.nom.value,
                    op: "smart",
                };
            }
            let response = await ProductDAO.getActiveRanges(pageNumber, {}, formatData);
            if (response.data.ressources.length === 0) {
                const keyWord = data.nom && data.nom.value != undefined ? data.nom.value : " ";
                response = await CommonDAO.getAllProductRanges(pageNumber, keyWord, {});
            }
            this.setState({ range_products: response.data.ressources });
        } catch (error) { }
    }

    setSub_range_product(array) {
        this.setState({ sub_range_product: array, });
    }
    resetSub_range_product() {
        this.setState({ sub_range_product: [], });
    }

    async getSub_range_products(data, pageNumber = 1) {

        try {

            const { range_product } = this.state;
            // const keyWord = data.value != undefined ? data.value : " ";
            let formatedData = { ...data, gammes: [...range_product.map(obj => obj.id)] }
            const response = await CommonDAO.getSubRangesByRanges(pageNumber, {}, formatedData);
            range_product.forEach((item, index) => {
                this.setState({ sub_range_products: [...this.state.sub_range_products, ...response.data[item.id]] });
            })
            // this.setState({ sub_range_products: response.data });

        } catch (error) {
            console.log(error)
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequireEventRapport(event_id) {
        const {
            visitor_id,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action_id,
            revisited_at,
            comment,
            event_goal_id,
            event_goals,
            concurrent_id,
            concurrents,
            provider_id,
            provider,
            range_product_id,
            range_products,
            range_product,
            sub_range_product_id,
            sub_range_products,
        } = this.state;

        const data = {
            event: event_id,
            gestionnaire: visitor_id,
            visite_le: visited_at,
            docteur_present: doctor_present,
            canal: channel,
            action_concurrence: concurrent_action_id,
            a_revisiter_le: revisited_at,
            commentaire: comment,
            objectif: event_goal_id,
            concurent_p: concurrent_id,
            fournisseurs: [{ id: provider_id, name: provider }],
            gamme: range_product_id,
            sous_gamme: sub_range_product_id,
        };
        return data;
    }

    async updateEventRapport(event_id) {
        try {
            const { crevent_id } = this.state;
            const data = this.verifyRequireEventRapport(event_id);
            const response = await CalenderDAO.updateCrEvent(crevent_id, {}, data);
            if (response.data.status == "ok") {
                this.props.history.push(`${RoutesPath.calender.details}/${event_id}`, { successMessage: MessagesText.crEvent.update });
            }
        } catch (error) { }
    }

    async addEventRapport(event_id) {
        try {
            const data = this.verifyRequireEventRapport(event_id);
            const response = await CalenderDAO.addCrEvent({}, data);
            if (response.data.status == "ok") {
                this.props.history.push(`${RoutesPath.calender.details}/${event_id}`);
            }
        } catch (error) { }
    }

    render() {
        const id = this.props.match.params.id;
        const {
            event_id,
            errorMessage,
            code,
            category,
            accounts,
            contacts,
            addRapport,
            visitor,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action,
            revisited_at,
            comment,
            event_goal,
            event_goals,
            concurrent,
            concurrents,
            provider,
            range_product,
            range_products,
            sub_range_product,
            sub_range_products,
            visitor_loading,
            range_product_id,
        } = this.state;
        const title = event_id ? `Modifier rapport d'événement : ${moment(visited_at).format("YYYY-MM-DD")}` : "Créer un nouveau rapport d'événement";
        const subTitle = event_id ? `Modifier rapport d'événement` : "Créer un rapport d'événement";

        const Visited_at = ({ value, onClick }) => <CustomDatePicker error={false} onClick={onClick} defaultValue={value} id="name2" label={"Visiter le*"}></CustomDatePicker>;
        const Revisited_at = ({ value, onClick }) => <CustomDatePicker error={false} onClick={onClick} defaultValue={value} id="name2" label={"A revisiter le"}></CustomDatePicker>;
        const renderDayContents = (day, date) => {
            const tooltipText = `Tooltip for date: ${date}`;
            return (
                <span style={{ color: "red" }} title={tooltipText}>
                    {day}
                </span>
            );
        };
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (event_id != 0 ? this.addEventRapport(id) : this.updateEventRapport(id))}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{subTitle}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal label="Visiteur" setValue={this.setVisitor.bind(this)} reset={this.resetVisitor.bind(this)} defaultValue={visitor} value={visitor} data={visitors} propertyName={"fullname"} searchData={this.getVisitors.bind(this)} loading={visitor_loading} />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={visited_at}
                                    onChange={(date) => {
                                        this.setState({
                                            visited_at: date,
                                        });
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Heure"
                                    dateFormat="yyyy-MM-d HH:mm"
                                    todayButton="Aujourd'hui"
                                    popperPlacement="bottom-center"
                                    customInput={<Visited_at />}
                                    locale="fr"
                                    useWeekdaysShort={true}
                                />
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ doctor_present: value.target.value });
                                    }}
                                    selectedValue={doctor_present}
                                    label="Docteur était présent"
                                >
                                    <option defaultValue></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </CustomSelect>
                            </Left_Input_container>
                            <Right_input_container>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ channel: value.target.value });
                                    }}
                                    selectedValue={channel}
                                    label="Canal"
                                >
                                    <option defaultValue></option>
                                    <option value="Terrain">Terrain</option>
                                    <option value="Téléphone">Téléphone</option>
                                    <option value="E-mail">E-mail</option>
                                </CustomSelect>
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal
                                    label="Action de la concurrence"
                                    setValue={this.setConcurrent_action.bind(this)}
                                    reset={this.resetConcurrent_action.bind(this)}
                                    defaultValue={concurrent_action}
                                    value={concurrent_action}
                                    data={concurrent_actions}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrent_actions.bind(this)}
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={revisited_at}
                                    onChange={(date) => {
                                        this.setState({
                                            revisited_at: date,
                                        });
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Heure"
                                    dateFormat="yyyy-MM-d HH:mm"
                                    todayButton="Aujourd'hui"
                                    popperPlacement="bottom-center"
                                    customInput={<Revisited_at />}
                                    renderDayContents={renderDayContents}
                                    locale="fr"
                                    useWeekdaysShort={true}
                                />
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ comment: value.target.value });
                                    }}
                                    defaultValue={comment}
                                    id="comment"
                                    label="Commentaire"
                                ></CustomInput>
                            </Left_Input_container>
                            <Right_input_container>
                                <PopUpGlobal modalTitle="Liste des objectifs d’événement" label="Objectif d'événement*" setValue={this.setEvent_goal.bind(this)} reset={this.resetEvent_goal.bind(this)} defaultValue={event_goal} value={event_goal} data={event_goals} propertyName={"nom"} searchData={this.getEvent_goals.bind(this)} />
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal label="Concurrent" setValue={this.setConcurrent.bind(this)} reset={this.resetConcurrent.bind(this)} defaultValue={concurrent} value={concurrent} data={concurrents} propertyName={"nom"} searchData={this.getConcurrents.bind(this)} />
                            </Left_Input_container>
                            <Right_input_container>
                                <PopUpGlobal label="Fournisseurs" setValue={this.setProvider.bind(this)} reset={this.resetProvider.bind(this)} defaultValue={provider} value={provider} data={concurrents} propertyName={"nom"} searchData={this.getConcurrents.bind(this)} />
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal label="Gamme produit" setValue={this.setRange_product.bind(this)} defaultValue={range_product} value={range_product} data={range_products} propertyName={"nom"} searchData={this.getRange_products.bind(this)} />
                            </Left_Input_container>
                            {range_product_id && range_product_id.length != 0 && (
                                <Right_input_container>
                                    <PopUpGlobal label="Sous-gamme produit" setValue={this.setSub_range_product.bind(this)} defaultValue={sub_range_product} value={sub_range_product} data={sub_range_products} propertyName={"nom"} searchData={this.getSub_range_products.bind(this)} />
                                </Right_input_container>
                            )}
                        </Row>
                    </Form_container>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 30%;
    font-family: Roboto;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: #284358;
`;
const Reset_btn = styled.div`
    position: absolute;
    top: 11px;
    right: 9px;
`;
const Svg = styled.svg`
    cursor: pointer;
`;
const Input = styled.input`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    font-size: 14px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px #bbc8d3;
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    ${"" /* &:focus ~ ${PopUp}{
        display:block;
    }; */}
    &:focus ~ ${Label} , &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const Button = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: transparent;
`;
const ErrorMessage = styled.div`
    height: 50px;
    width: 100%;
    background: #ff4a4a;
    text-align: center;
    padding-top: 14px;
`;
const ErrorMessageText = styled.div`
    color: #fff;
    display: inline-block;
    vertical-align: top;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddCrEventPage);
