export const MessagesText = {
    account: {
        add: "La compte a été créé avec succès",
        update: "Le compte a été modifié avec succès",
        delete: "le compte a été archivé avec succès",
        restore: "le compte a été restauré avec succès",
    },
    contact: {
        add: "Le contact a été créé avec succès",
        update: "Le contact a été modifié avec succès",
        delete: "le contact a été archivé avec succès",
        restore: "le contact a été restauré avec succès",
    },
    inventory: {
        add: "l'inventaire a été créé avec succès",
        update: "l'inventaire a été modifié avec succès",
        add_products: "les produits ont été affectés avec succès",
        update_product: "la quantité de produit a été modifiée avec succès",
        panel: {
            delete: "le produit a été supprimé depuis le panier",
        },
        unauthorized: "Vous ne pouvez pas modifier cet inventaire qui est",

    },
    event: {
        add: "L'événement a été créé avec succès",
        update: "L'événement a été modifié avec succès",
        report: "L'événement a été reporté avec succès",
        delete: "L'événement a été archivé avec succès",
        restore: "L'événement a été restauré avec succès",
    },
    crEvent: {
        update: "Le rapport d'événement a été modifié avec succès",
        add: "Le rapport d'événement a été crée avec succès",
    },
    planning: {
        add: "Le planning a été créé avec succès",
        update: "Le planning a été modifié avec succès",
        duplicate: "Le planning a été dupliqué avec succès",
        affectEvents: "les événements ont été affectés avec succès",
        affectEventAlert: "cet événement existe déjà",
        delete: "le planning a été archivé avec succès",
        restore: "le planning a été restauré avec succès",
    },
    roles: {
        add: "Le rôle a été créé avec succès",
        update: "Le rôle a été modifié avec succès",

        affectToUser: "le rôle a été affecté avec succès",
        affectMethods: "les méthodes ont été affectées avec succès",
    },
    gallery: {
        add: "La photo a été créée avec succès",
        update: "La photo a été modifiée avec succès",
        delete: "La photo a été archivée avec succès",
        restore: "La photo a été restaurée avec succès",
    },
    product: {
        suggestion: {
            add: "La suggestion a été créée avec succès",
        },
        range: {
            add: "La gamme a été ajoutée avec succès",
        },
    },
    demand: {
        add: "La demande a été créée avec succès",
        update: "La demande a été modifiée avec succès",
        disable: "La demande a été  archivée avec succès",
        resolve: "La demande a été résolue avec succès",
        restore: "La demande a été restaurée avec succès",
        assigned: "La demande a été assignée avec succès",
        refuseUpdate: "Vous ne pouvez pas modifier cette demande",
        addSubscription: "vous ête abonné a cette demande",
        removeSubscription: "vous ête désabonné a cette demande"
    },
    email: {
        add: "L'email a été créé avec succès",
        addModel: "Le modèle a été créée avec succès",
        updateModel: "Le modèle a été modifié avec succès",
    },
    notification: {
        unauthorized: "Vous n'avez pas la permission d'accéder à cette page."
    }
};
