import ApiCall from "../services/ApiCall";

// for function can shared between all components

class CommonDAO {
    // for getting all cities
    static getAllCities = (pageNumber = 1, params = {}, keyword = " ") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/villes/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all sectors
    static getAllSectors = (pageNumber, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get("/api/secteurs/ /smart/" + pageNumber, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all Concurrent Actions
    static getAllConcurrentActions = (pageNumber, keyword, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/actions/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all Concurrent
    static getAllConcurrent = (pageNumber, keyword, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/fournisseurs/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all event goals
    static getAllEventGoals = (pageNumber, keyword, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/objectifs/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all products ranges
    static getAllProductRanges = (pageNumber, keyword = " ", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/gammes/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for getting all products sub ranges
    static getAllProductSubRanges = (pageNumber, keyword = " ", params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/sousgammes/${keyword}/smart/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    //for getting all products sub ranges
    static getProductSubRangesById = (id, params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/sousgammesbygamme/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all visitors
    //for getting all products sub ranges
    static getSubRangesByRanges = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/sousgammesbygammes`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all visitors

    static getAllVisitors = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/utilisateurs/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all visitors

    static getAllUsers = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/utilisateurs/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all taxes

    static getAllTaxes = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/taxes/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get all visitors

    static getAllPharmaceuticalForms = (pageNumber, params, keyword = " ", op = "smart") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/formegalenique/${keyword}/${op}/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get all product dci

    static getAllProductDci = (pageNumber, params, keyword = " ", op = "smart") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/dcis/${keyword}/${op}/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get all therapeutic classes

    static getTherapeuticClasses = (pageNumber, params, keyword = " ", op = "smart") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/classetherapeutique/${keyword}/${op}/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get all product categories

    static getProductCategories = (pageNumber, keyword = " ", op = "smart") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/produitcategorie/${keyword}/${op}/${pageNumber}`);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get all providers

    static getAllProviders = (pageNumber, params, keyword = " ", op = "smart") =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/fournisseurs/${keyword}/${op}/${pageNumber}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //for sorting data
    static sortData(data, property) {
        let sortedData = data.sort((a, b) => {
            return (String(a[property]) || "").toLocaleLowerCase() > (String(b[property]) || "").toLocaleLowerCase() ? 1 : -1;
        });
        return sortedData;
    }

    //for sorting data in reverse
    static reverseData(data, property) {
        let sortedData = data.sort((a, b) => {
            return (String(a[property]) || "").toLocaleLowerCase() > (String(b[property]) || "").toLocaleLowerCase() ? -1 : 1;
        });
        return sortedData;
    }
}

export default CommonDAO;
