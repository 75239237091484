import React, { Component } from 'react';
import { PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "./PdfDocument";

class PdfViewerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <iframe width="100%" height="100%">{PdfDocument}</iframe>
        );
    }
}

export default PdfViewerPage;