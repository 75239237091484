export default {
    control: {
        backgroundColor: "#fff",

        fontSize: 12,
        fontWeight: "normal",
        borderRadius: "20px",
        minHeight: 100,

    },

    highlighter: {
        overflow: "hidden",
    },

    input: {
        margin: 0,
    },

    "&singleLine": {
        // control: {
        //     display: "inline-block",
        //     backgroundColor: "red",
        //     width: 130
        // },

        // highlighter: {
        //     padding: 1,
        //     border: "2px inset transparent"
        // },

        // input: {
        //     padding: 1,

        //     border: "2px inset"
        // }
    },

    "&multiLine": {
        // control: {
        //     fontFamily: "monospace",

        //     border: "1px solid silver"
        // },

        highlighter: {
            padding: 9
        },

        input: {
            padding: 9,
            minHeight: 20,
            outline: 0,
            border: 0
        }
    },

    suggestions: {
        list: {
            backgroundColor: "white",
            border: "1px solid #E3E4E5",
            fontSize: 10,
            minWidth: 450,
            maxHeight: 193,
            overflowY: "scroll",
            borderRadius: 4
        },

        item: {
            padding: "10px 20px",
            // borderBottom: "1px solid rgba(0,0,0,0.15)",
            transition: ".3s",
            "&focused": {
                backgroundColor: "#E8ECEE"
            }
        }
    }
};
