import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactsTable from "../components/ContactsTable";
import CustomInput from "../components/CustomInput";
import moment from "moment";
import ProductDAO from "../DAO/ProductDAO";
import { TablePagination } from "../components/TablePagination";
import InventoryDAO from "../DAO/InventoryDAO";
import { RoutesPath } from "../values/RoutesPath";
import { MessagesText } from "../values/MessagesText";
import { notify } from "../helpers/FlashMessage";
import { DatePicker } from "../components/CustomDatePicker";

class InventoryListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            products: [],
            selectedProducts: [],
            inputsValue: [],
            comment: "",
            pageNumber: 1,
            totalItems: 0,
            date: null,
            date_error: false,
            is_loaded: false,
            productsPanel: [],
            all_products: false,
        };
        this.myRef = React.createRef();
    }
    componentDidMount() {
        this.get();
    }
    async getAllProducts() {
        try {
            const { pageNumber, selectedProducts } = this.state;
            const params = {};
            const data = {
                orderby: {
                    col: "id",
                    dir: "DESC",
                },
            };

            const response = await ProductDAO.getALLProducts(pageNumber, params, data);
            this.setState({ data: response.data, products: response.data.ressources, selectedProducts: [], is_loaded: true, totalItems: response.data.total }, () => {
                this.setState({ productsPanel: [] });

                this.getProductsPanel();
            });
        } catch (error) {}
    }
    async getMyProducts() {
        try {
            const { pageNumber, selectedProducts } = this.state;

            const data = {
                orderby: {
                    col: "id",
                    dir: "DESC",
                },
            };

            const params = {};
            const response = await ProductDAO.getAllMyProducts(pageNumber, params, data);
            this.setState({ data: response.data, products: response.data.ressources, selectedProducts: [], is_loaded: true, totalItems: response.data.total }, () => {
                this.setState({ productsPanel: [] });

                this.getProductsPanel();
            });
        } catch (error) {}
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await InventoryDAO.getInventoryById(id, {});

            this.setState({ comment: response.data.commentaire, date: Date.parse(response.data.date), all_products: response && response.data && response.data.tous_produits });

            if (response && response.data && response.data.tous_produits) {
                this.getAllProducts();
            } else if (response && response.data && !response.data.tous_produits) {
                this.getMyProducts();
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getProductsPanel(pageNumber = 1) {
        try {
            let varPageNumber = pageNumber;
            const id = this.props.match.params.id;
            const data = {};
            const response = await InventoryDAO.getInventoryPanel(id, varPageNumber, {}, data);
            this.setState({ productsPanel: [...this.state.productsPanel, ...response.data.produits] }, () => {
                if (response.data.total / 20 > varPageNumber) {
                    varPageNumber = varPageNumber + 1;
                    this.getProductsPanel(varPageNumber);
                } else {
                    this.setValuesToInputs();
                }
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async add(save_continue) {
        try {
            const { data, all_products } = this.state;

            var inps = document.getElementsByClassName("pname[]");
            let selectedProducts = [];
            for (var i = 0; i < inps.length; i++) {
                var inp = inps[i];
                if (parseInt(inp.value) !== 0 && data && data.ressources[i]) {
                    selectedProducts.push({ id: data.ressources[i].id, qte: parseInt(inp.value) });
                }
            }
            const id = this.props.match.params.id;
            // const { selectedProducts } = this.state;
            if (selectedProducts.length != 0) {
                const params = {};
                const data = {
                    produits: selectedProducts,
                };
                const response = await InventoryDAO.addProductToInventory(id, params, data);
            }
            if (save_continue) {
                this.setState(
                    (prevState) => ({
                        is_loaded: false,
                        pageNumber: prevState.pageNumber + 1,
                    }),
                    () => (all_products ? this.getAllProducts() : this.getMyProducts())
                );
            } else {
                notify({ msg: MessagesText.inventory.add_products, type: "success" });
                this.props.history.push(`${RoutesPath.inventory.details}/${id}`);
            }
        } catch (error) {}
    }
    // updatePrev() {
    //     this.setState((prevState) => ({
    //         is_loaded:false,
    //         pageNumber : prevState.pageNumber -1
    //     }), () => this.getProducts())
    // }
    quite = () => {
        const id = this.props.match.params.id;
        this.props.history.push(`${RoutesPath.inventory.details}/${id}`);
    };
    setValuesToInputs() {
        const { productsPanel, data } = this.state;
        data &&
            data.ressources &&
            data.ressources.map(function (obj, index) {
                productsPanel &&
                    productsPanel.some(function (obj2) {
                        if (obj.id === obj2.produitId) {
                            var inps = document.getElementsByClassName("pname[]");
                            if (inps[index]) {
                                inps[index].value = obj2.qte;
                            }
                        }
                    });
            });
    }
    render() {
        const { pageNumber, data, products, date, comment, selectedProducts, is_loaded, date_error, totalItems } = this.state;
        return (
            <Main_container className="Main_container">
                <TopOptions title="Modifier inventaire">
                    {/* {parseInt(pageNumber) > 1 && <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.updatePrev()}>
                        Modifier la précédente
                    </button>} */}
                    {totalItems / 20 > pageNumber && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.add(true)}>
                            Sauvegarder et continuer
                        </button>
                    )}
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.add(false)}>
                        Sauvegarder et quitter
                    </button>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={this.quite}>
                        Quitter
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Modifier inventaire</h2>
                    </div>
                    <Container>
                        <StyledInfosContainer>
                            <StyledInfosTitle>Inventaire</StyledInfosTitle>
                            <StyledInfosForm>
                                <StyledFormCol>
                                    <StyledSubTitle>Informations générales</StyledSubTitle>
                                    <StyledInputContainer>
                                        <DatePicker
                                            selected={this.state.date}
                                            onChange={(date) => {
                                                this.setState({
                                                    date: date,
                                                    date_error: false,
                                                });
                                            }}
                                            dateFormat="yyyy-MM-dd"
                                            showTimeSelect={false}
                                            label="Date*"
                                            id="end_date_id"
                                            error={date_error}
                                        />
                                    </StyledInputContainer>
                                </StyledFormCol>
                                <StyledFormCol>
                                    <StyledSubTitle>Informations descriptives</StyledSubTitle>
                                    <StyledInputContainer>
                                        <CustomInput onChange={(e) => this.setState({ comment: e.target.value })} defaultValue={comment} value={comment} id="comment" label="Commentaire"></CustomInput>
                                    </StyledInputContainer>
                                </StyledFormCol>
                            </StyledInfosForm>
                        </StyledInfosContainer>
                        <StyledTable>
                            <StyledThead>
                                <tr>
                                    <StyledTh_1>Nom commercial</StyledTh_1>
                                    <StyledTh>PPV</StyledTh>
                                    <StyledTh>PPH</StyledTh>
                                    <StyledTh>Quantité *</StyledTh>
                                </tr>
                            </StyledThead>
                            <StyledTbody>
                                <StyledSeparator>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </StyledSeparator>
                                {data &&
                                    data.ressources.length != 0 &&
                                    data.ressources.map((product, index) => {
                                        return (
                                            <StyledTr>
                                                <StyledTd>
                                                    <StyledTdText>{product.nom.toLocaleUpperCase()}</StyledTdText>
                                                </StyledTd>
                                                <StyledTd>
                                                    <StyledTdText>{product.pph}</StyledTdText>
                                                </StyledTd>
                                                <StyledTd>
                                                    <StyledTdText>{product.ppv}</StyledTdText>
                                                </StyledTd>
                                                {is_loaded && (
                                                    <StyledTd>
                                                        <StyledTdInput
                                                            ref={this.myRef}
                                                            // onChange={(Value) => {
                                                            //     // const tmpArray = [...this.state.selectedProducts];
                                                            //     // tmpArray[index].qte = Value.target.value;
                                                            //     // this.setState({ selectedProducts:[...tmpArray]})
                                                            //     const tmpArray = this.state.selectedProducts;
                                                            //     const found = tmpArray.some((tmp) => tmp.id == product.id);
                                                            //     if (!found) {
                                                            //         this.setState({ selectedProducts: [...this.state.selectedProducts, { id: product.id, qte: Value.target.value }] });
                                                            //     } else {
                                                            //         var foundIndex = tmpArray.findIndex((tmp) => tmp.id == product.id);
                                                            //         if (Value.target.value.length == 0 || parseInt(Value.target.value) == 0) {
                                                            //             tmpArray.splice(foundIndex, 1);
                                                            //         } else {
                                                            //             tmpArray[foundIndex] = { id: product.id, qte: Value.target.value };
                                                            //         }
                                                            //         this.setState({ selectedProducts: [...tmpArray] });
                                                            //     }
                                                            // }}
                                                            className={"pname[]"}
                                                            type="number"
                                                            defaultValue={0}
                                                        />
                                                    </StyledTd>
                                                )}
                                            </StyledTr>
                                        );
                                    })}

                                <StyledSeparator>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </StyledSeparator>
                                {/* <TablePagination
                                    colSpan={3}
                                    pageNumber={pageNumber}
                                    totalItems={166}
                                    onPrevBtnClick={this.prevPage}
                                    onNextBtnClick={this.nextPage}
                                /> */}
                            </StyledTbody>
                        </StyledTable>
                    </Container>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Container = styled.div`
    background-color: #fff;
    padding-bottom: 60px;
`;

const StyledInfosContainer = styled.div`
    width: 100%;
    background-color: #fff;
`;
const StyledInfosTitle = styled.h1`
    font-size: 50px;
    font-weight: 300;
    color: #00beb0;
    line-height: 48px;
    padding: 10px;
`;
const StyledInfosForm = styled.div`
    width: 100%;
`;
const StyledFormCol = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding: 10px;
`;
const StyledSubTitle = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: #9b9b9b;
    margin-bottom: 10px;
`;
const StyledInputContainer = styled.div`
    width: 100%;
    height: 41px;
`;
const StyledThead = styled.thead``;
const StyledTable = styled.table`
    background-color: #fff;
    width: 100%;
    border-bottom: 2px solid #5a5a5a;
    border-collapse: collapse;
`;
const StyledTh = styled.th`
    text-align: left;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #5a5a5a;
`;
const StyledTh_1 = styled.th`
    text-align: left;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 2px solid #5a5a5a;
    width: 50%;
`;
const StyledTr = styled.tr`
    &:hover {
        background-color: #00beb0;
    }
`;
const StyledTd = styled.td`
    padding: 5px 10px;
`;
const StyledTdText = styled.p`
    background-color: #d9d9d9;
    color: #5a5a5a;
    padding: 5px;
    border-radius: 4px;
`;
const StyledTbody = styled.tbody``;
const StyledTdInput = styled.input`
    height: 25px;
    width: 100%;
    color: #5a5a5a;
    font-family: "Open Sans", sans-serif;
    border: 1px solid #bbb;
    padding: 1px 5px;
    border-radius: 3px;
    &:hover {
        border: 1px solid #00beb0;
    }
`;
const StyledSeparator = styled.tr`
    height: 20px;
`;
export default InventoryListPage;
