import React, { Component } from "react";
import { Link } from "react-router-dom";
class SaveInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div id="main_container" style={{ height: "100%" }}>
                    <div id="fixed_bar" className="top_options_wrapper">
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid4">
                                    <h1>Vente(s) créée(s) avec succès.</h1>
                                    <div className="top_options_btns"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="content">
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc-content">
                                            <div className="pay-ok">
                                                <i className="valide-ico" />
                                                <br />
                                                Vente(s) créée(s) avec succès.{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid2">
                                    <div className="plan-options">
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="facture-ico" />
                                            </div>
                                            <div className="po-link">
                                                <a href="/invoice/49678109">Afficher la facture </a>
                                            </div>
                                        </div>
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="plus-ico" />
                                            </div>
                                            <div className="po-link">
                                                <Link to={"/create"} data-shortcut_alias="create">
                                                    Créer une nouvelle vente
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="print-ico" />
                                            </div>
                                            <div className="po-link">
                                                <a href="/invoices/index/generate-receipt/invoice_id/49678109" id="print_receipt" target="_blank" data-shortcut_alias="print_receipt">
                                                    Imprimer ticket{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="print-ico" />
                                            </div>
                                            <div className="po-link">
                                                <a href="/invoices/index/pdf-info/invoice_id/49678109" target="_blank" data-shortcut_alias="print_doc">
                                                    Imprimer facture{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="print-ico" />
                                            </div>
                                            <div className="po-link">
                                                <a href="/invoices/index/pdf-info/invoice_id/49678109/print_as/delivery_note" target="_blank" data-shortcut_alias="print_doc">
                                                    Imprimer bon de livraison{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="plan-option">
                                            <div className="po-ico">
                                                <i className="list-ico" />
                                            </div>
                                            <div className="po-link">
                                                <a href="/invoices">Consulter toutes les ventes</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SaveInvoices;
