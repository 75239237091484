import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import PlanningDAO from "../DAO/PlanningDAO";
import EventDAO from "../DAO/EventDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";

import { Link } from "react-router-dom";
import AccountDAO from "../DAO/AccountDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import { DatePicker } from "../components/CustomDatePicker";
import SearchSelect from "../helpers/SearchSelect";
import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "../components/seachStyledComponents";
import FormLoading from "../helpers/FromLoading";
import { notify } from "../helpers/FlashMessage";

class EventsToPlanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
            events: [],
            eventsStates: {
                pageNumber: 1,
                totalItems: 0,
            },
            successMessage: "",
            is_loaded: false,
            planningEvents: [],
            loading: false,
        };
    }
    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";

        this.get();
        this.getPlanningEvents();
    }

    async affectEvents() {
        try {
            const { planningEvents } = this.state;
            const id = this.props.match.params.id;
            let sendedEvents = [];
            planningEvents.map((object) => sendedEvents.push(object.id));

            const data = {
                events: sendedEvents,
            };
            this.setState({ loading: true });
            const response = await PlanningDAO.affectEventsToPlanning(id, {}, data);
            this.setState({ loading: false });

            if (!response.data.msg && response.data.status === "ok") {
                notify({ msg: MessagesText.planning.affectEvents, type: "success" });
                this.props.history.push(`${RoutesPath.planning.details}/${id}`);
            } else {
                notify({ msg: response.data.msg, type: "danger" });
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await PlanningDAO.getPlanningById(id, {});
            this.setState({ planning: response.data.planning, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getPlanningEvents() {
        try {
            const planning_id = this.props.match.params.id;

            const response = await PlanningDAO.getPlanningEvents(planning_id, {});
            this.setState({ planningEvents: response.data, planningEventsLoaded: true }, () => {
                this.getEvents();
            });
        } catch (error) {
            this.setState({ planningEventsLoaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getEvents(data = {}, pageNumber = 1) {
        try {
            const { planningEvents } = this.state;
            let searchData = data;
            searchData["disabled"] = {
                value: 0,
                op: "is",
            };

            const response = await EventDAO.getALLEvents(pageNumber, {}, searchData);

            //Find values that are in result1 but not in result2
            let uniqueResultOne = planningEvents.filter(function (obj) {
                return !response.data.events.some(function (obj2) {
                    return obj.id == obj2.id;
                });
            });

            //Find values that are in result2 but not in result1
            let uniqueResultTwo = response.data.events.filter(function (obj) {
                return !planningEvents.some(function (obj2) {
                    return obj.id == obj2.id;
                });
            });

            //Combine the two arrays of unique entries
            let result = uniqueResultOne.concat(uniqueResultTwo);

            this.setState((prevState) => ({
                ...prevState,
                events: uniqueResultTwo,
                eventsLoaded: true,
                eventsStates: {
                    ...prevState.eventsStates,
                    totalItems: response.data.total,
                },
            }));
        } catch (error) {
            this.setState({ eventsLoaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }

    formateDate(date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
    }

    addToArray(array, object) {
        return [object, ...array];
    }

    removeFromArray(array = [], object) {
        return array.filter((obj) => obj.id !== object.id);
    }

    isExistInArray(array, object) {
        return array.some((obj) => obj.id === object.id);
    }

    addEventsPlanningArray(event) {
        const { planningEvents, events } = this.state;
        if (!this.isExistInArray(planningEvents, event)) {
            this.setState({
                planningEvents: this.addToArray(planningEvents, event),
                events: this.removeFromArray(events, event),
            });
        } else {
            this.setState({
                events: this.removeFromArray(events, event),
            });
            alert(MessagesText.planning.affectEventAlert);
        }
    }

    RemoveEventsFromPlanningArray(event) {
        const { planningEvents, events } = this.state;
        this.setState({
            planningEvents: this.removeFromArray(planningEvents, event),
            events: this.addToArray(events, event),
        });
    }

    render() {
        const { planningEvents, planning, events, successMessage, eventsLoaded, planningEventsLoaded, eventsStates, loading } = this.state;
        const title = planning ? planning.nom : "";

        return (
            <Main_container className="Main_container">
                {loading && <FormLoading />}
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={"Planning : " + title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.affectEvents()}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block_contact>
                            <Title_contact>Événements</Title_contact>
                        </Block_contact>
                        {/* events bloc */}
                        <EventListTable getData={(data, pageNumber) => this.getEvents(data, pageNumber)} events={events} totalItems={eventsStates.totalItems} onClickRow={(event, index) => this.addEventsPlanningArray(event, index)} />
                        {/* end events bloc */}
                        {/* {eventsLoaded && <EventListTable tfoot={tfoot} onClickClose={() => {}} onClickRow={(event, index) => this.addEventsPlanningArray(event, index)} noActions={true} data={events} {...this.props} />} */}
                    </Left_block_container>
                    <Right_block_container>
                        <Block_contact>
                            <Title_contact>{`Planning : ${planning && planning.nom} `}</Title_contact>
                        </Block_contact>
                        <div className="bloc_content table_wrraper">
                            <div className="bloc_header">
                                <h2>Nombre des événements</h2>
                                <div className="bloc_header_btns">
                                    <Bloc_header_text>{planningEvents ? planningEvents.length : 0}</Bloc_header_text>
                                </div>
                            </div>
                            <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                                <thead>
                                    <tr className="table_th_orderable">
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Nom
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" /> Date de début
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Date de fin
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Lieu
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Type
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Compte
                                        </Th>

                                        <Th className="table_actions" />
                                    </tr>
                                </thead>

                                <tbody>
                                    {planningEvents.length !== 0 &&
                                        planningEvents.map((event, index) => {
                                            return (
                                                <Tr key={"key2_" + index} onClick={() => { }}>
                                                    <Td>{event.nom}</Td>
                                                    <Td>{event.date_debut ? moment(event.date_debut).format("YYYY-MM-DD HH:mm") : ""}</Td>
                                                    <Td>{event.date_fin ? moment(event.date_fin).format("YYYY-MM-DD HH:mm") : ""}</Td>
                                                    <Td>{event.lieu}</Td>
                                                    <Td>{event.type}</Td>
                                                    <Td>{event.compte}</Td>
                                                    <Td className="table_action">
                                                        <a onClick={() => this.RemoveEventsFromPlanningArray(event)} className="sprite-table-delete-btn delete_line" title="Imprimer" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />
                                                    </Td>
                                                </Tr>
                                            );
                                        })}

                                    <Tr>
                                        <Td colSpan={8} className="table_pagination">
                                            <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                            <div style={{ display: "inline-block" }}>{/* <span className="table_page">1</span>
                                                <a href="#" className="sprite-table-next-btn" onClick={() => {}} /> */}</div>
                                        </Td>
                                    </Tr>
                                </tbody>
                            </table>
                        </div>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(EventsToPlanning);

class EventListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            actions: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            name: "",
            date_start: null,
            date_end: null,
            place: "",
            type: "",
            contact: "",
            selectedOption: {},
        };
    }
    componentDidMount() { }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            const { getData } = this.props;
            const { search, name, date_start, date_end, place, type, contact } = this.state;
            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });
            if (data) {
                this.setState({ pageNumber: 1 }, () => {
                    getData(data, this.state.pageNumber);
                });
            }
        }
    }

    resetSearch() {
        const { getData } = this.props;
        this.setState(
            {
                name: "",
                date_start: null,
                date_end: null,
                place: "",
                type: "",
                contact: "",
                selectedOption: {},
            },
            () => getData({}, this.state.pageNumber)
        );
    }
    onClickRow(event, index) {
        const { onClickRow } = this.props;
        onClickRow ? onClickRow(event, index) : this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
    }
    render() {
        const { search, pageNumber } = this.state;
        const { events, totalItems, getData } = this.props;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Liste des événements</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}

                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Nom
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Date de début
                                </Th>

                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Statut
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Type
                                </Th>

                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Contact
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {events &&
                                events.length != 0 &&
                                events.map((event, index) => {
                                    return (
                                        <Tr key={"key2_" + index}>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.nom}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.date_debut ? moment(event.date_debut).format("YYYY-MM-DD HH:mm") : ""}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.statut}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.type}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.contact_prenom && event.contact_prenom + " " + event.contact_nom && event.contact_nom}</Td>
                                            <Td className="table_action"></Td>

                                            {/* <Td className="table_action">{planningId && <Link to={`${RoutesPath.calender.update}/${event && event.id}`} className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" />}</Td> */}
                                        </Tr>
                                    );
                                })}
                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        <Link to={`${RoutesPath.calender.add}`} className="btn_white">
                                            Créer
                                        </Link>
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        getData({}, this.state.pageNumber);
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / 20 && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        getData({}, this.state.pageNumber);
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { getData } = this.props;
        const { search, name, date_start, date_end, place, type, contact } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={name}
                            onChange={(value) => {
                                this.setState({ name: value.target.value });
                            }}
                            label="Nom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    {/* <Search_input_container>
                        <DatePicker
                            selected={date_start}
                            onChange={(date) => {
                                this.setState({
                                    date_start: date,
                                });
                            }}
                            label="Date de début"
                            id="start_date"
                        />
                    </Search_input_container> */}
                </Th>

                <Th backgroundColor="#fff">
                    {/* <Search_input_container>
                        <DatePicker
                            selected={date_end}
                            onChange={(date) => {
                                this.setState({
                                    date_end: date,
                                });
                            }}
                            label="Date de fin"
                            id="end_date"
                        />
                    </Search_input_container> */}
                </Th>
                <Th backgroundColor="#fff">
                    {/* <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={place}
                            onChange={(value) => {
                                this.setState({ place: value.target.value });
                            }}
                            label="Lieu"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container> */}
                </Th>
                <Th backgroundColor="#fff">
                    {/* <Search_input_container>
                        <CustomSelect
                            onChange={(value) => {
                                this.setState({ type: value.target.value });
                            }}
                            selectedValue={type}
                            label="Type"
                        >
                            <option defaultValue></option>
                            <option value="Visite">Visite</option>
                            <option value="Formation interne ou Réunion">Formation interne ou Réunion</option>
                            <option value="Absence">Absence</option>
                            <option value="Congés">Congés</option>
                            <option value="Évènement et congrès">Évènement et congrès</option>
                        </CustomSelect>
                    </Search_input_container> */}
                </Th>

                <Th backgroundColor="#fff">
                    <Search_btn
                        onClick={() => {
                            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });
                            if (data) {
                                this.setState({ pageNumber: 1 }, () => {
                                    getData(data, this.state.pageNumber);
                                });
                            }
                        }}
                    >
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}

const Bloc_header_text = styled.span`
    color: #00beb0;
    font: 18px "Open Sans", sans-serif;
    font-weight: 800;
`;
const Main_container = styled.div`
    height: 100%;
`;
const Left_block_container = styled.div`
    width: 49%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    display: "flex";
    flex-direction: "row";
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    ${"" /* margin-top:10px; */}
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #5867c3;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 10px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
