import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Auth from "../services/Auth";
import { connect } from "react-redux";
import UserDAO from "../DAO/UserDAO";
import Loading from "../components/loading";

class SuccessResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingError: false,
        };
    }
    componentWillMount() {
        Auth.login(() => this.props.dispatch(this.getConnectedUserInfos()));
    }

    getConnectedUserInfos() {
        return async (dispatch) => {
            try {
                this.setState({ loadingError: false });
                const params = {};
                this.setState({ loading: true });
                const response = await UserDAO.getConnectedUserInfos(params);

                dispatch({ type: "ADD_USER_INFOS", value: response.data });
                dispatch({ type: "ADD_ALL_ACL_ACTIONS", value: [...response.data.methods] });
                this.setState({ loading: false });
            } catch (error) {
                this.setState({ loading: false, loadingError: true });
            }
        };
    }

    render() {
        const { loadingError, loading } = this.state;
        //  <Redirect to={redirect_uri} />
        // <Redirect to={this.props.currentPathName} />
        if (!loading && !loadingError) {
            return <Redirect to={this.props.currentPathName} />;
        } else if (!loading && loadingError) {
            // return <Redirect to={"/error"} />;
        }
        return (
            <div style={{ height: "100%", backgroundColor: "#fff" }}>
                <Loading />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        currentPathName: state.Auth.currentPathName,
    };
};
export default connect(mapStateToProps)(SuccessResponse);
