import React, { Component } from "react";
import styled from "styled-components";
import UserDAO from "../DAO/UserDAO";
import TableModel from "../components/TableModel";
import { RoutesPath } from "../values/RoutesPath";
import { withRouter } from "react-router";
import { acl } from "../services/acl";
import { connect } from "react-redux";
import { MANAGE_PROFILE_URL } from "../env";
// const thead = [
//     { property: "nom", label: "Nom", type: "string" },
//     { property: "prenom", label: "Prénom", type: "string" },
//     { property: "is_admin", label: "Est Admin", type: "boolean" },
//     { property: "is_superviseur", label: "Est Superviseur", type: "boolean" },
// ];

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Gérer mon compte Sobrus", onClick: () => window.open(`${MANAGE_PROFILE_URL}/account`, "_blank") }] });
        // this.getAll();
    }

    render() {
        const connected_user_infos = this.props.connectedUserInfos;
        const { is_loaded, data, pageNumber, totalItems } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Profil</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient vos informations et paramètres.</Block_content_title>
                    </Block_content>
                </First_block>
                <div className="bloc">
                    <div className="bloc_header">
                        <h2>Informations générales</h2>
                    </div>
                    <div className="bloc_content">
                        <div className="general_infos_wrapper">
                            <em className="sprite-default-customer general_infos_thumb">{/* <img src="images/Costumers.jpg" alt=""> */}</em>
                            <div className="general_infos_cnt">
                                <div className="general_infos_left">
                                    <div className="general_infos_row">
                                        <span>Nom et Prénom</span>
                                        <p>{connected_user_infos && connected_user_infos.fullname}</p>
                                    </div>
                                </div>
                                <div className="general_infos_right">
                                    <div className="general_infos_row">
                                        <span>Est admin</span>
                                        <p>{connected_user_infos && connected_user_infos.is_admin === false ? "Non" : connected_user_infos && connected_user_infos.is_admin === true ? "Oui" : ""}</p>
                                    </div>
                                </div>
                                <div className="general_infos_left">
                                    <div className="general_infos_row">
                                        <span>Rôle</span>
                                        <p>{connected_user_infos && connected_user_infos.role}</p>
                                    </div>
                                </div>
                                <div className="general_infos_right">
                                    <div className="general_infos_row">
                                        <span>Est superviseur</span>
                                        <p>{connected_user_infos && connected_user_infos.is_superviseur === false ? "Non" : connected_user_infos.is_superviseur === true ? "Oui" : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        acl_actions: state.ACL.acl_actions,
        connectedUserInfos: state.User.connectedUserInfos,
    };
};
export default connect(mapStateToProps)(withRouter(ProfilePage));

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
