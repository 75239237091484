import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import CustomSelect from "../../components/CustomSelect";
import { DatePicker } from "../../components/CustomDatePicker";

import { connect } from "react-redux";

const options = {
    title: {
        text: "My chart",
    },
    series: [
        {
            data: [1, 2, 3],
        },
    ],
};

class InventoryByAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        //(this.props)
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Inventaires"></TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Options des rapports</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <FullRow>
                                <CustomSelect error={false} onChange={(Value) => this.setField("contre_indication_conduite", Value.target.value)} selectedValue={""} label="Compte*">
                                    <option defaultValue value="Aucun">
                                        Aucun
                                    </option>
                                    <option value="Niveau 1">Niveau 1</option>
                                    <option value="Niveau 2">Niveau 2</option>
                                    <option value="Niveau 3">Niveau 3</option>
                                </CustomSelect>
                            </FullRow>
                        </Row>
                        <Row>
                            <FullRow>
                                <CustomSelect error={false} onChange={(Value) => this.setField("contre_indication_conduite", Value.target.value)} selectedValue={""} label="Produi*">
                                    <option defaultValue value="Aucun">
                                        Aucun
                                    </option>
                                    <option value="Niveau 1">Niveau 1</option>
                                    <option value="Niveau 2">Niveau 2</option>
                                    <option value="Niveau 3">Niveau 3</option>
                                </CustomSelect>
                            </FullRow>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date,
                                            startDate_error: false,
                                        });
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => {
                                        this.setState({
                                            endDate: date,
                                            endDate_error: false,
                                        });
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de fin*"
                                    id="end_date_id"
                                />
                            </Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
                <Bloc2 className="Bloc">
                    <div className="bloc_header">
                        <h2>Mouvements de stock</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                </Bloc2>
                <Bloc2 className="Bloc">
                    <div className="bloc_header">
                        <h2>Historique de stock</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    <Th>Date</Th>
                                    <Th>Quantité</Th>
                                </tr>
                            </thead>

                            <tbody>
                                {[].length !== 0 &&
                                    [].map((contact, index) => {
                                        return (
                                            <Tr
                                                key={"key_" + index}
                                                onClick={() => {
                                                    this.props.history.push("/details");
                                                }}
                                            >
                                                <Td>{"contact.prenom"}</Td>
                                                <Td>{"contact.nom"}</Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td>{"contact.prenom"}</Td>
                                    <Td>{"contact.nom"}</Td>
                                </Tr>
                            </tbody>
                        </table>
                    </div>
                </Bloc2>
            </Main_container>
        );
    }
}

const FullRow = styled.div`
    width: 99%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Bloc2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-top: 10px;
    background: #fff;
`;
const Th = styled.th`
    padding-right: 10px;
`;

const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(InventoryByAccount);
