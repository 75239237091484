import styled from "styled-components";
export const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    background: #fff;
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-around")};
`;
export const Left = styled.div`
    padding-top: 38px;
    width: 48%;
`;
export const FullRow = styled.div`
    padding-top: 38px;
    width: 98%;
`;
export const Right = styled.div`
    padding-top: 38px;
    width: 48%;
`;
export const InputContainer = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "41px")};
    margin-bottom: 18px;
`;
