class CommonFunctions {
    constructor() {}
    convertDataForSelectBox(data, valueProperty, labelProperty, optionLabelProperty_2, name = null) {
        let convertedData = [];
        name && name === "report" ? convertedData.push({ value: 0, label: "Tout" }) : convertedData.push({ value: "", label: "__Aucun__" });
        data.map((object) => {
            convertedData.push({ value: valueProperty ? object[valueProperty] : "", label: labelProperty ? (optionLabelProperty_2 ? `${object[labelProperty]} (${object[optionLabelProperty_2]})` : object[labelProperty]) : "" });
        });
        return convertedData;
    }
}

export default new CommonFunctions();
