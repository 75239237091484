import React, { Component } from "react";
import { BrowserRouter, Route, Link, Redirect, Switch } from "react-router-dom";

import ListInvoices from "./pages/ListInvoices";
import CreateInvoices from "./pages/CreateInvoices";
import DetailsInvoices from "./pages/DetailsInvoices";
import ApproveInvoices from "./pages/ApproveInvoices";
import SaveInvoices from "./pages/SaveInvoices";
import "./css/all_38.css";
import HomePage from "./pages/HomePage";
import AccountsPage from "./pages/AccountsPage";
import ContactsPage from "./pages/ContactsPage";
import CalenderPage from "./pages/CalenderPage";
import WeekCalenderPage from "./pages/WeekCalenderPage";
import MonthCalenderPage from "./pages/MonthCalenderPage";
import GalleryPage from "./pages/GalleryPage";
import AddAccountsPage from "./pages/AddAccountPage";
import DetailsAccountPage from "./pages/DetailsAccountPage";
import SettingsLayout from "./pages/SettingsLayout";
import SettingsAddPage from "./pages/SettingsAddPage";
import SettingsListPage from "./pages/SettingsListPage";
import "./pages/PdfViewerPage";
import PdfViewerPage from "./pages/PdfViewerPage";
import PdfDocument from "./pages/PdfDocument";
import InventoryListPage from "./pages/InventoryListPage";
import AddContactsPage from "./pages/AddContactsPage";
import DetailsContactPage from "./pages/DetailsContactPage";
import DetailsPicturePage from "./pages/DetailsPicturePage";
import AddEventPage from "./pages/AddEventPage";
import DetailsEventPage from "./pages/DetailsEventPage";
import AddCrEventPage from "./pages/AddCrEventPage";
import UpdateEventPage from "./pages/UpdateEventPage";
import UpdateCrEventPage from "./pages/UpdateCrEventPage";
import InventoryPage from "./pages/InventoryPage";
import DetailsInventoryPage from "./pages/DetailsInventoryPage";
import AddInventoryPage from "./pages/AddInventoryPage";
import AddPhotoPage from "./pages/AddPhotoPage";
import styled from "styled-components";
import Loading from "./components/loading";
import ProductsPage from "./pages/ProductsPage";
import PharmaciesPage from "./pages/PharmaciesPage";
import HealthFacilitiesPage from "./pages/HealthFacilitiesPage";
import AssociationsPage from "./pages/AssociationsPage";
import ContactBtns from "./helpers/ContactBtns";
import DetailsProductPage from "./pages/DetailsProductPage";
import DetailsResourcePage from "./pages/DetailsResourcePage";
import ACLRoles from "./pages/ACLRoles";
import AddACLRoles from "./pages/AddACLRoles";

import ACLMethods from "./pages/ACLMethodes";
import AffectACLMethods from "./pages/AffectACLMethods";

import SuccessResponse from "./pages/SuccessResponse";
import FailedResponse from "./pages/FailedResponse";

import Login from "./pages/Login";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Header from "../src/helpers/Header";
import PlanningPage from "./pages/PlanningPage";
import AddPlanningPage from "./pages/AddPlanningPage";
import DetailsPlanningPage from "./pages/DetailsPlanningPage";
import DuplicatePlanningPage from "./pages/DuplicatePlanningPage";
import EventsToPlanning from "./pages/EventsToPlanning";
import { RoutesPath } from "./values/RoutesPath";
import { ProtectedLogin } from "./helpers/ProtectedLogin";
import UsersPage from "./pages/UsersPage";
import ConnectionHistoryPage from "./pages/ConnectionHistoryPage";
import AddProductSuggestion from "./pages/AddProductSuggestion";
import MyProductsPage from "./pages/MyProductsPage";
import ReportEventPage from "./pages/ReportEventPage";
import NotFoundPage from "./pages/NotFoundPage";
import FlashMessage from "./helpers/FlashMessage";
import Report from "./pages/Report";
import PurchaseOrders from "./pages/PurchaseOrders";
import ContactByUser from "./pages/ContactByUser";
import Visits from "./pages/Visits";
import VisitorByChannel from "./pages/VisitorByChannel";
import VisitorByPurpose from "./pages/VisitorByPurpose";
import VisitorByContactCategory from "./pages/VisitorByContactCategory";
import InventoryByAccount from "./pages/InventoryByAccount";
import ProductSuggestionPage from "./pages/ProductSuggestionPage";
import RoleToUserPage from "./pages/RoleToUserPage";
import DetailsSuggestionPage from "./pages/DetailsSuggestionPage";
import ErrorPage from "./pages/ErrorPage";
import BeingProcessed from "./pages/BeingProcessed";
import Publication from "./pages/publication";
import NewEventsToPlanning from "./pages/NewEventsToPlanning";
import MobileVersion from "./pages/MobileVersion";
import UpdateInventoryProductPage from "./pages/UpdateInventoryProductPage";
import PharmacySuggestionPage from "./pages/PharmacySuggestionPage";
import AddPharmacySuggestion from "./pages/AddPharmacySuggestion";
import DetailsPharmacySuggPage from "./pages/DetailsPharmacySuggPage";
import AssociationSuggestionPage from "./pages/AssociationSuggestionPage";
import AddAssociationSuggestion from "./pages/AddAssociationSuggestion";
import DetailsAssociationSuggPage from "./pages/DetailsAssociationSuggPage";
import HealthFacilitiesSuggestionPage from "./pages/HealthFacilitiesSuggestionPage";
import AddHealthFacilitiesSuggestion from "./pages/AddHealthFacilitiesSuggestion";
import DetailsHealthFacilitiesSuggPage from "./pages/DetailsHealthFacilitiesSuggPage";
import Acl from "./services/acl";
import UserDAO from "./DAO/UserDAO";
import { connect } from "react-redux";
import Auth from "./services/Auth";
import UpdateProductsRanges from "./pages/UpdateProductsRanges";
import ProductsRanges from "./pages/ProductsRanges";
import ProfilePage from "./pages/ProfilePage";
import axios from "axios";
import { BASE_URL } from "./env";
import Demands from "./pages/demands";
import DemandDetails from "./pages/DemandDetails";
import AddDemand from "./pages/AddDemand";
import Notifications from "./pages/notifications";
import IncomingEmailPage from "./pages/IncomingEmailPage";
import OutgoingEmailPage from "./pages/OutgoingEmailPage";
import AddOutgoingEmailModelPage from "./pages/AddOutgoingEmailModelPage";
import AddIncomingEmailConfig from "./pages/AddIncomingEmailConfig";

const API = axios.create({
    baseURL: `${BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});

class DebugRouter extends BrowserRouter {
    constructor(props) {
        super(props);
    }
}
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingError: false,
        };
    }
    getConnectedUserInfos() {
        return async (dispatch) => {
            try {
                this.setState({ loadingError: false });
                const params = {};
                this.setState({ loading: true });
                const response = await API.get(`/api/user/infos`, params);
                dispatch({ type: "ADD_USER_INFOS", value: response.data });
                dispatch({ type: "ADD_ALL_ACL_ACTIONS", value: [...response.data.methods] });

                this.setState({ loading: false });
            } catch (error) {
                this.setState({ loading: false, loadingError: true });
            }
        };
    }
    componentDidMount() { }
    componentWillMount() {
        if (Auth.isAuthenticated()) {
            this.props.dispatch(this.getConnectedUserInfos());
        }
    }

    itIsMobile() {
        const { loading, loadingError } = this.state;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route path="*" component={MobileVersion} actionName="authorized" />
                    </Switch>
                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter>
                    {!loadingError ? (
                        <Switch>
                            <Route path="/login" exact component={Login} />
                            <ProtectedRoute path="/" exact component={HomePage} actionName="authorized" />
                            <ProtectedRoute path={`${RoutesPath.notification.list}`} exact component={Notifications} actionName="api_app_request_getnotifications" />
                            <ProtectedRoute path={`${RoutesPath.account.list}`} exact component={AccountsPage} actionName="api_app_compte_getcomptes" />
                            <ProtectedRoute path={`${RoutesPath.account.details}/:id`} exact component={DetailsAccountPage} actionName="api_app_compte_getcompte" />
                            <ProtectedRoute path={`${RoutesPath.account.add}`} exact component={AddAccountsPage} actionName="api_app_compte_postcompte" />
                            <ProtectedRoute path={`${RoutesPath.account.update}/:id`} exact component={AddAccountsPage} actionName="api_app_compte_putcompte" />
                            <ProtectedRoute path={`${RoutesPath.contact.list}`} exact component={ContactsPage} actionName="api_app_contact_getcontacts" />
                            <ProtectedRoute path={`${RoutesPath.contact.add}`} exact component={AddContactsPage} actionName="api_app_contact_postcontact" />
                            <ProtectedRoute path={`${RoutesPath.contact.add}/:id_account`} exact component={AddContactsPage} actionName="api_app_contact_postcontact" />
                            <ProtectedRoute path={`${RoutesPath.contact.details}/:id`} exact component={DetailsContactPage} actionName="api_app_contact_getcontact" />
                            <ProtectedRoute path={`${RoutesPath.contact.update}/:id`} exact component={AddContactsPage} actionName="api_app_contact_putcontact" />
                            <ProtectedRoute path={`${RoutesPath.gallery.list}`} exact component={GalleryPage} actionName="api_app_photo_getgalerie" />
                            <ProtectedRoute path={`${RoutesPath.gallery.details}/:id`} exact component={DetailsPicturePage} actionName="api_app_photo_getphoto" />
                            <ProtectedRoute path={`${RoutesPath.gallery.add}`} exact component={AddPhotoPage} actionName="api_app_photo_postuploadimagecompte" />
                            <ProtectedRoute path={`${RoutesPath.gallery.add}/:id_account`} exact component={AddPhotoPage} actionName="api_app_photo_postuploadimagecompte" />
                            <ProtectedRoute path={`${RoutesPath.gallery.update}/:id`} exact component={AddPhotoPage} actionName="api_app_photo_putuploadimagecompte" />
                            <ProtectedRoute path={`${RoutesPath.calender.list}`} exact component={CalenderPage} actionName="api_app_event_getallevents" />
                            <ProtectedRoute path={`${RoutesPath.calender.list_month}`} exact component={MonthCalenderPage} actionName="api_app_event_getallevents" />
                            <ProtectedRoute path={`${RoutesPath.calender.list_week}`} exact component={WeekCalenderPage} actionName="api_app_event_getallevents" />
                            <ProtectedRoute path={`${RoutesPath.calender.add}`} exact component={AddEventPage} actionName="api_app_event_postevent" />
                            <ProtectedRoute path={`${RoutesPath.calender.add}/:id_account`} exact component={AddEventPage} actionName="api_app_event_postevent" />
                            <ProtectedRoute path={`${RoutesPath.calender.details}/:id`} exact component={DetailsEventPage} actionName="api_app_event_getevent" />
                            <ProtectedRoute path={`${RoutesPath.calender.update}/:id`} exact component={AddEventPage} actionName="api_app_event_putevent" />
                            <ProtectedRoute path={`${RoutesPath.calender.report}/:id`} exact component={ReportEventPage} actionName="api_app_event_putreporterevent" />
                            <ProtectedRoute path={`${RoutesPath.crEvent.add}/:id`} exact component={UpdateCrEventPage} actionName="api_app_event_putcrevent" />
                            <ProtectedRoute path={`${RoutesPath.crEvent.update}/:id`} exact component={UpdateCrEventPage} actionName="api_app_event_putcrevent" />
                            <ProtectedRoute path={`${RoutesPath.planning.list}`} exact component={PlanningPage} actionName="api_app_planning_getallplannings" />
                            <ProtectedRoute path={`${RoutesPath.planning.add}`} exact component={AddPlanningPage} actionName="api_app_planning_postplanning" />
                            <ProtectedRoute path={`${RoutesPath.planning.update}/:id`} exact component={AddPlanningPage} actionName="api_app_planning_putplanning" />
                            <ProtectedRoute path={`${RoutesPath.planning.details}/:id`} exact component={DetailsPlanningPage} actionName="api_app_planning_getplanning" />
                            <ProtectedRoute path={`${RoutesPath.planning.duplicate}/:id`} exact component={DuplicatePlanningPage} actionName="api_app_planning_postduplicateplanning" />
                            <ProtectedRoute path={`${RoutesPath.planning.event_to_planning}/:id`} exact component={EventsToPlanning} actionName="api_app_planning_putplanningevents" />
                            <ProtectedRoute path={`${RoutesPath.planning.new_event_to_planning}/:id`} exact component={NewEventsToPlanning} actionName="api_app_planning_postplanningressources" />
                            <ProtectedRoute path={`${RoutesPath.inventory.list}`} exact component={InventoryPage} actionName="api_app_inventaire_getinventaires" />
                            <ProtectedRoute path={`${RoutesPath.inventory.details}/:id`} exact component={DetailsInventoryPage} actionName="api_app_inventaire_getinventaire" />
                            <ProtectedRoute path={`${RoutesPath.inventory.add}`} exact component={AddInventoryPage} actionName="api_app_inventaire_postinventaire" />
                            <ProtectedRoute path={`${RoutesPath.inventory.add}/:id_account`} exact component={AddInventoryPage} actionName="api_app_inventaire_postinventaire" />
                            <ProtectedRoute path={`${RoutesPath.inventory.update}/:id`} exact component={AddInventoryPage} actionName="api_app_inventaire_putinventaire" />
                            <ProtectedRoute path={`${RoutesPath.inventory.product_list}/:id`} exact component={InventoryListPage} actionName="api_app_inventaire_getlistdetailinventaire" />
                            <ProtectedRoute path={`${RoutesPath.inventory.update_inventory_product}/:id/:id_product`} exact component={UpdateInventoryProductPage} actionName="api_app_inventaire_insertproduct" />
                            {/* <ProtectedRoute path={`${RoutesPath.inventory.list}`} exact component={BeingProcessed} />
                                <ProtectedRoute path={`${RoutesPath.inventory.details}/:id`} exact component={BeingProcessed} />
                                <ProtectedRoute path={`${RoutesPath.inventory.add}`} exact component={BeingProcessed} />
                                <ProtectedRoute path={`${RoutesPath.inventory.add}/:id_account`} exact component={BeingProcessed} />
                                <ProtectedRoute path={`${RoutesPath.inventory.update}/:id`} exact component={BeingProcessed} />
                                <ProtectedRoute path={`${RoutesPath.inventory.product_list}/:id`} exact component={BeingProcessed} /> */}
                            <ProtectedRoute path={`${RoutesPath.product.list}`} exact component={ProductsPage} actionName="api_app_ressource_getproduitlist" />
                            <ProtectedRoute path={`${RoutesPath.product.my_product}`} exact component={MyProductsPage} actionName="api_app_ressource_postproduitsbylabo" />
                            <ProtectedRoute path={`${RoutesPath.product.details}/:id`} exact component={DetailsProductPage} actionName="api_app_ressource_getproduit" />
                            <ProtectedRoute path={`${RoutesPath.product.suggestion.add}`} exact component={() => <AddProductSuggestion />} actionName="api_app_ressource_postproduitsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.product.suggestion.add}/:product_id`} exact component={() => <AddProductSuggestion />} actionName="api_app_ressource_postproduitsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.product.suggestion.list}`} exact component={() => <ProductSuggestionPage />} actionName="api_app_ressource_getproduitsuggestionlist" />
                            <ProtectedRoute path={`${RoutesPath.product.suggestion.details}/:id`} exact component={() => <DetailsSuggestionPage />} actionName="api_app_ressource_getproduitsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.list_pharmacies}`} exact component={PharmaciesPage} actionName="api_app_ressource_getpharmacies" />
                            <ProtectedRoute path={`${RoutesPath.resource.list_healthcareestablishments}`} exact component={HealthFacilitiesPage} actionName="api_app_ressource_getetablissements" />
                            <ProtectedRoute path={`${RoutesPath.resource.list_associations}`} exact component={AssociationsPage} actionName="api_app_ressource_getassociations" />
                            <ProtectedRoute path={`${RoutesPath.resource.details}/:resourceName/:id`} exact component={DetailsResourcePage} actionName="api_app_ressource_getpharmacie" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.pharmacy.list}`} exact component={PharmacySuggestionPage} actionName="api_app_ressource_getpharmaciessuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.associations.list}`} exact component={AssociationSuggestionPage} actionName="api_app_ressource_getassociationssuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.healthcareestablishments.list}`} exact component={HealthFacilitiesSuggestionPage} actionName="api_app_ressource_getetablissementssuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.healthcareestablishments.add}`} exact component={AddHealthFacilitiesSuggestion} actionName="api_app_ressource_postetablissementsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.healthcareestablishments.add}/:id`} exact component={AddHealthFacilitiesSuggestion} actionName="api_app_ressource_postetablissementsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.healthcareestablishments.details}/:id`} exact component={DetailsHealthFacilitiesSuggPage} actionName="api_app_ressource_postetablissementsuggestion" />

                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.associations.add}`} exact component={AddAssociationSuggestion} actionName="api_app_ressource_postassociationsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.associations.add}/:id`} exact component={AddAssociationSuggestion} actionName="api_app_ressource_postassociationsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.associations.details}/:id`} exact component={DetailsAssociationSuggPage} actionName="api_app_ressource_getassociationsuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.pharmacy.add}`} exact component={AddPharmacySuggestion} actionName="api_app_ressource_postpharmaciesuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.pharmacy.add}/:id`} exact component={AddPharmacySuggestion} actionName="api_app_ressource_postpharmaciesuggestion" />
                            <ProtectedRoute path={`${RoutesPath.resource.suggestion.pharmacy.details}/:id`} exact component={DetailsPharmacySuggPage} actionName="api_app_ressource_getpharmaciesuggestion" />
                            {/* <Route path="/test" exact component={Test} /> */}
                            {/* <Route path="/pdf_document" exact component={PdfDocument} /> */}
                            <ProtectedRoute path="/pdf_viewer" exact component={PdfViewerPage} actionName="" />
                            <ProtectedRoute path="/list_settings" exact component={SettingsListPage} actionName="" />
                            <ProtectedRoute path="/add_settings" exact component={SettingsAddPage} actionName="" />
                            <ProtectedRoute path="/ListInvoices" exact component={ListInvoices} actionName="" />
                            <ProtectedRoute path="/create" exact component={CreateInvoices} actionName="" />
                            <ProtectedRoute path="/details" exact component={DetailsInvoices} actionName="" />
                            <ProtectedRoute path="/approve" exact component={ApproveInvoices} actionName="" />
                            <ProtectedRoute path="/save" exact component={SaveInvoices} actionName="" />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.acl_methods.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={5}>
                                        <ACLMethods />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_getmethods"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.acl_methods.affect_to_role}/:id_role`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={5}>
                                        <AffectACLMethods />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_putrolemethods"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.acl_roles.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={5}>
                                        <ACLRoles />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_getroles"
                            />

                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.incoming_email.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <IncomingEmailPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_getemailconfig"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.incoming_email.add}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <AddIncomingEmailConfig />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_putupdateemailconfig"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.incoming_email.update}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <AddIncomingEmailConfig />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_putupdateemailconfig"
                            />

                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.outgoing_email.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <OutgoingEmailPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_getallmodelsresponses"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.outgoing_email.add}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <AddOutgoingEmailModelPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_postmodelresponse"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.email_params.outgoing_email.update}/:id`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <AddOutgoingEmailModelPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_request_putmodelresponse"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.acl_roles.add}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={5}>
                                        <AddACLRoles />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_postrole"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.acl_roles.update}/:id`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={5}>
                                        <AddACLRoles />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_putrole"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.user.affect_role}/:user_id`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={3}>
                                        <RoleToUserPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_access_setrole"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.user.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={2}>
                                        <UsersPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_user_getusers"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.user.connection_history}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={2}>
                                        <ConnectionHistoryPage />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_user_gethistory"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.product.range.update}/:id`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={4}>
                                        <UpdateProductsRanges />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_ressource_insertorupdategammesactives"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.product.range.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={4}>
                                        <ProductsRanges />
                                    </SettingsLayout>
                                )}
                                actionName="api_app_ressource_postgammesactives"
                            />
                            <ProtectedRoute
                                path={`${RoutesPath.setting.profile.list}`}
                                exact
                                component={() => (
                                    <SettingsLayout openedUrl={1}>
                                        <ProfilePage />
                                    </SettingsLayout>
                                )}
                                actionName="authorized"
                            />
                            <ProtectedRoute exact path={`${RoutesPath.report.home}`} exact component={Report} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.purchase_orders}`} exact component={BeingProcessed} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.contact_by_user}`} component={ContactByUser} actionName="api_app_rapport_getrapportparcontactcreation" />
                            <ProtectedRoute exact path={`${RoutesPath.report.visits}`} exact component={Visits} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_channel}`} exact component={VisitorByChannel} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_purpose}`} exact component={VisitorByPurpose} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_contact_category}`} exact component={VisitorByContactCategory} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.inventory_by_account}`} component={BeingProcessed} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.report.visitsbycompetitorandsector}`} component={BeingProcessed} actionName="authorized" />

                            <ProtectedRoute exact path={`${RoutesPath.demand.list}`} component={Demands} actionName="api_app_request_postdemandes" />
                            <ProtectedRoute exact path={`${RoutesPath.demand.details}/:id`} component={DemandDetails} actionName="api_app_request_getdemande" />
                            <ProtectedRoute exact path={`${RoutesPath.demand.add}`} component={AddDemand} actionName="api_app_request_postdemande" />
                            <ProtectedRoute exact path={`${RoutesPath.demand.update}/:id`} component={AddDemand} actionName="api_app_request_postupdatedemande" />

                            {/* <ProtectedRoute exact path={`${RoutesPath.report.home}`} exact component={Report} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.purchase_orders}`} exact component={PurchaseOrders} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.contact_by_user}`} exact component={ContactByUser} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.visits}`} exact component={Visits} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_channel}`} exact component={VisitorByChannel} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_purpose}`} exact component={VisitorByPurpose} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.visitor_by_contact_category}`} exact component={VisitorByContactCategory} actionName="authorized" />
                        <ProtectedRoute exact path={`${RoutesPath.report.inventory_by_account}`} exact component={InventoryByAccount} actionName="authorized" /> */}
                            <ProtectedRoute exact path={`${RoutesPath.other.list}`} exact component={BeingProcessed} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.sales.list}`} exact component={BeingProcessed} actionName="authorized" />
                            <ProtectedRoute exact path={`${RoutesPath.publication.list}`} exact component={Publication} actionName="authorized" />
                            <Route exact path="/success" exact component={SuccessResponse} actionName="authorized" />
                            <Route exact path="/failed" exact component={FailedResponse} actionName="authorized" />
                            {/* <Route exact path="/error" exact component={ErrorPage} /> */}
                            {/* <Route path="/error" render={() => <ErrorPage msg={"Erreur interne du serveur"} />} exact actionName="authorized" /> */}
                            <Route path="/notAuthorized" render={() => <ErrorPage msg={"Ressource non autorisée"} />} actionName="authorized" />
                            <Route path="*" component={NotFoundPage} actionName="authorized" />
                        </Switch>
                    ) : (
                            <Switch>
                                <Route path="/login" exact component={Login} />
                                <Route path="*" render={() => <ErrorPage msg={"Erreur interne du serveur"} />} actionName="authorized" />
                            </Switch>
                        )}
                </BrowserRouter>
            );
        }
    }
    render() {
        const { loading, loadingError } = this.state;
        if (loading) {
            return <Loading />;
        }
        return (
            <DebugRouter>
                <div style={{ height: "100%" }}>
                    <GlobalContainer>{this.itIsMobile()}</GlobalContainer>
                </div>
            </DebugRouter>
        );
    }
}

const Container = styled.div`
    position: relative;
    height: 100%;
    background: #fff;
`;
const FlashMessageContainer = styled.div`
    position: relative;
    height: 100%;
    background: #fff;
`;
const GlobalContainer = styled.div`
    height: 100%;
`;

const mapStateToProps = (state) => {
    return {
        connectedUserInfos: state.User.connectedUserInfos,
    };
};
export default connect(mapStateToProps)(App);
