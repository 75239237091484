import React, { Component } from "react";
import styled from "styled-components";
import AclDAO from "../DAO/AclDAO";
import { Loading, OrderUp, OrderDown } from "../components/TableModel";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";

const thead = [
    { property: "action", label: "Action", type: "string" },
    { property: "ressource", label: "Est accessible", type: "checkBox", onCheck: () => { } },
];
class AffectACLMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            selectedMethods: [],
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Sauvegarder", onClick: () => this.add() }] });
        this.preload();
    }

    render() {
        const { is_loaded, data, selectedMethods } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>ACL > Méthodes</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient la liste de toutes les ressources.</Block_content_title>
                    </Block_content>
                </First_block>

                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Méthodes</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    {thead.map((item) => {
                                        return (
                                            <Th>
                                                <OrderUp onClick={() => this.sort(item.property)} />
                                                <OrderDown onClick={() => this.reverse(item.property)} />
                                                {item.label}
                                            </Th>
                                        );
                                    })}
                                </tr>
                            </thead>

                            {!is_loaded && <tbody>{<Loading columns={thead} />}</tbody>}

                            {is_loaded && (
                                <tbody>
                                    {data.map((object, index) => {
                                        const checked = selectedMethods.some((element) => element === object.id);
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td>{object.libelle ? object.libelle.replace(/\\/g) : object.action}</Td>
                                                <Td>
                                                    <label htmlFor={`object_${object.id}`} className="checkbox" style={{ display: "block" }}>
                                                        <input
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    this.setState((prevState) => ({
                                                                        ...prevState,
                                                                        selectedMethods: [...prevState.selectedMethods, object.id],
                                                                    }));
                                                                } else {
                                                                    const tmpArray = [...selectedMethods];
                                                                    const index = tmpArray.findIndex((tmp) => tmp === object.id);
                                                                    tmpArray.splice(index, 1);
                                                                    this.setState({ selectedMethods: [...tmpArray] });
                                                                }
                                                            }}
                                                            type="checkbox"
                                                            name={`object_${object.id}`}
                                                            id={`object_${object.id}`}
                                                            defaultValue={1}
                                                            autoComplete="off"
                                                            checked={checked}
                                                        />
                                                        <span className="checked" />
                                                    </label>
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                    </div>
                </Bloc>
            </>
        );
    }

    async add() {
        try {
            const { selectedMethods } = this.state;
            const id_role = this.props.match.params.id_role;
            if (selectedMethods.length !== 0) {
                const params = {};
                const data = {
                    methods: selectedMethods,
                };
                const response = await AclDAO.affectMethodsToRole(id_role, params, data);
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.roles.affectMethods, type: "success" });
                    this.props.history.push(RoutesPath.setting.acl_roles.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                this.props.history.goBack();
            }
        } catch (error) {
            this.setState({ s_loaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }

    preload = () => {
        this.getAll();
        this.getRoleMethods();
    };

    getRoleMethods = async () => {
        try {
            const id_role = this.props.match.params.id_role;
            const params = {};
            const response = await AclDAO.getMethodsByRole(id_role, params);
            response.data.map((item) => {
                this.setState((prevState) => ({
                    ...prevState,
                    selectedMethods: [...prevState.selectedMethods, item.id],
                }));
            });
        } catch (error) {
            this.setState({ s_loaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    };
    async getAll() {
        try {
            const params = {};
            const response = await AclDAO.getAllMethods(params);
            this.setState({ is_loaded: true, data: response.data });
        } catch (error) {
            this.setState({ s_loaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(AffectACLMethods);

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
