let BASE_URL = "https://api.labsv3.sobrus.ovh";
let LOGIN_URL = "https://api.account.sobrus.ovh";
let MANAGE_PROFILE_URL = "https://account.sobrus.ovh";
if (process.env.REACT_APP_ENV === "development") {
    BASE_URL = "https://api.labsv3.sobrus.ovh";
    LOGIN_URL = "https://api.account.sobrus.ovh";
    MANAGE_PROFILE_URL = "https://account.sobrus.ovh";
}

if (process.env.REACT_APP_ENV === "production") {
    BASE_URL = "https://api.labsv3.sobrus.com";
    LOGIN_URL = "https://api.account.sobrus.com";
    MANAGE_PROFILE_URL = "https://account.sobrus.com";
}
export { BASE_URL, LOGIN_URL, MANAGE_PROFILE_URL };
