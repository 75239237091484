const initialState = {
    notifications: [],
    notificationTotalItem: 0,
};

function notification(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "ADD_ALL_NOTIFICATIONS":
            nextState = {
                ...state,
                notifications: [...action.value.notifications],
                notificationTotalItem: action.value.notificationTotalItem
            };
            return nextState || state;
        case "CLEAR_ALL_NOTIFICATIONS":
            nextState = {
                ...state,
                notifications: [],
                notificationTotalItem: 0
            };
            return nextState || state;
        case "DELETE_NOTIFICATION":
            nextState = {
                ...state,
                notifications: state.notifications.filter((item) => item.id !== action.value.id),
                notificationTotalItem: state.notificationTotalItem - 1
            };
            return nextState || state;
        default:
            return state;
    }
}
export default notification;
