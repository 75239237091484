const initialState = { connectedUserInfos: {} };

function connectedUserInfos(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "ADD_USER_INFOS":
            nextState = {
                ...state,
                connectedUserInfos: action.value,
            };
            return nextState || state;

        default:
            return state;
    }
}
export default connectedUserInfos;
