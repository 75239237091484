import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpAccounts from "../components/PopUpAccounts";
import Axios from "axios";
import CustomDatePicker from "../components/CustomDatePicker";
// import DatePicker from "react-datepicker";
import FlashMessages from "../helpers/FlashMessages";
import moment from "moment";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import UserDAO from "../DAO/UserDAO";
import InventoryDAO from "../DAO/InventoryDAO";
import AccountDAO from "../DAO/AccountDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import { DatePicker } from "../components/CustomDatePicker";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";
import ProductDAO from "../DAO/ProductDAO";

class UpdateInventoryProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            qte: "",
            qte_error: false,
            product: {},
        };
    }
    componentDidMount() {
        document.title = "Inventaires - Sobrus Labs";
        this.get();
        // const id = this.props.match.params.id;
        // const id_account = this.props.match.params.id_account;
        // if (id) {
        //     this.setState({ inventory_id: id });
        //     this.get();
        // }
        // if (id_account) {
        //     this.getAccount(id_account);
        // }
        // this.getUsers();
    }

    async get() {
        try {
            const id_inventory = this.props.match.params.id;
            const response_inventory = await InventoryDAO.getInventoryById(id_inventory, {});
            if (response_inventory && response_inventory.data && response_inventory.data.statut && response_inventory.data.statut.toLocaleLowerCase() === "brouillon") {
                const id_product = this.props.match.params.id_product;
                const response = await ProductDAO.getProductById(id_product);

                this.setState({ product: response.data.produit }, () => this.state.product);
            } else {
                notify({ msg: `${MessagesText.inventory.unauthorized} ${response_inventory && response_inventory.data && response_inventory.data.statut && response_inventory.data.statut.toLocaleLowerCase()}`, type: "warning" });
                this.props.history.push(`${RoutesPath.inventory.details}/${id_inventory}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequired() {
        const { qte } = this.state;
        const id_product = this.props.match.params.id_product;
        if (qte && qte.length != 0) {
            const data = {
                produits: [
                    {
                        qte: qte,
                        id: id_product,
                    },
                ],
            };
            return data;
        } else {
            if (!qte || (qte && qte.length === 0)) {
                this.setState({ qte_error: true });
            }
            return null;
        }
    }

    async update() {
        try {
            const data = this.verifyRequired();

            if (data) {
                const id_inventory = this.props.match.params.id;
                this.setState({ loading: true });
                const response = await InventoryDAO.addProductToInventory(id_inventory, {}, data);
                this.setState({ loading: false });
                if ((response.data.status = "ok")) {
                    notify({ msg: MessagesText.inventory.update_product, type: "success" });
                    this.props.history.push(`${RoutesPath.inventory.details}/${id_inventory}`);
                } else if ((response.data.status = "ko")) {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { loading, inventory_id, product, qte, qte_error, accountItemsNumber, all_products, date, comment, openPopUp, account, first_name_error, errorMessage, data, products } = this.state;
        const title = inventory_id ? `Modifier Inventaire : ${moment(date).format("YYYY-MM-DD")}` : "Créer un inventaire";

        // const StartDate = ({ value, onClick }) => <CustomDatePicker error={date_error} onClick={onClick} defaultValue={value} id="name1" label={"Date*"}></CustomDatePicker>;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.update()}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{title}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <CustomInput editable={false} error={false} onChange={(value) => {}} defaultValue={product && product.nom ? product.nom : product.id} value={product && product.nom ? product.nom : product.id} id="product_name" label="Nom de produit"></CustomInput>
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <CustomInput
                                    inputType="number"
                                    error={qte_error}
                                    onChange={(value) => {
                                        this.setState({ qte: value.target.value });
                                    }}
                                    defaultValue={qte}
                                    value={qte}
                                    id="qte"
                                    label="Quantité *"
                                ></CustomInput>
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(UpdateInventoryProductPage);
