class configuration {
    storeSearchInCookies = (searchObject, pageName) => {
        this.eraseCookie(pageName + "__" + "filter");
        if (searchObject.search || searchObject.advancedSearch) {
            this.setCookie(pageName + "__" + "filter", JSON.stringify(searchObject), 1)
        }
    }
    getSearchFromCookies = (pageName) => {
        const searchObject = this.getCookie(pageName + "__" + "filter") ? JSON.parse(this.getCookie(pageName + "__" + "filter")) : null;
        if (searchObject && (searchObject.advancedSearch || searchObject.search)) {
            return searchObject;
        }
        return null;
    }
    cleanSearchCookies = (pageName) => {
        this.eraseCookie(pageName + "__" + "filter");
    }
    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=0'
    }
    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + window.btoa(cvalue) + ";" + expires + `;path=/`;
    }
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return window.atob(c.substring(name.length, c.length));
            }
        }
        return "";
    }

}

export default new configuration();