import React, { Component } from "react";
import styled from "styled-components";

import AclDAO from "../DAO/AclDAO";
import TableModel from "../components/TableModel";
import { RoutesPath } from "../values/RoutesPath";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { acl } from "../services/acl";
import { notify } from "../helpers/FlashMessage";
import EmailDAO from "../DAO/EmailDAO";

const thead = [
    { property: "name", label: "Nom", type: "string" },
    { property: "status", label: "Statut", type: "string" }
];
const IncomingEmailPage__STATUS_TEXT = {
    "true": "Actif",
    "false": "Inactif",
}
class IncomingEmailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            isThereByDefaultRole: false,
        };
    }
    componentDidMount() {
        let topOptionsBtn = [];
        if (acl.demand.addModel()) {
            topOptionsBtn.push({ label: "Nouveau modèle", onClick: () => this.props.history.push(`${RoutesPath.setting.email_params.outgoing_email.add}`) })
        }
        this.props.dispatch({
            type: "SETTINGS_TOP_OPTIONS_BTN",
            value: topOptionsBtn,
        });
        this.getAll();
    }
    async getAll() {
        try {
            const params = {};
            const response = await EmailDAO.getALLEmailModels(params);
            console.log("response", response)
            this.setState({ is_loaded: true, data: response.data.response["responses-models"].map((item) => ({ name: item.name, status: IncomingEmailPage__STATUS_TEXT[String(item.status)], id: item.id })) }, () => this.state.data);
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }

    render() {
        const { is_loaded, data } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Paramètres d'emails > Emails sortants</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient la liste de tous les modèles d'emails sortants.</Block_content_title>
                    </Block_content>
                </First_block>

                <TableModel
                    hideTri={true}
                    title="E-mails sortants"
                    onReload={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => { },
                        actions: [
                            acl.demand.detailsModel() && acl.demand.updateModel() && { title: "modifier", onClick: (id) => this.props.history.push(`${RoutesPath.setting.email_params.outgoing_email.update}/${id}`) },
                        ],
                    }}
                    {...this.props}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(IncomingEmailPage);
// export default connect(mapStateToProps)(IncomingEmailPage);

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
