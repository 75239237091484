import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import InventoryDAO from "../DAO/InventoryDAO";
import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "../components/seachStyledComponents";
import CustomSelect from "../components/CustomSelect";
import SearchSelect from "../helpers/SearchSelect";
import { DatePicker } from "../components/CustomDatePicker";
import { notify } from "../helpers/FlashMessage";
import { connect } from "react-redux";
import Acl, { acl } from "../services/acl";
class InventoryListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventories: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            date: null,
            created_by: "",
            selected_user: {},
            created_at: null,
            status: "",
        };
    }
    componentDidMount() {
        // const data = this.props.data;
        // if (data) {
        //     this.setState({ data });
        // }
        this.getInventories();
    }

    async getInventories() {
        try {
            const { pageNumber, search, date, created_by, created_at, status } = this.state;

            const { accountId } = this.props;
            const data = InventoryDAO.getSearchData({ search, date, created_by, created_at, status });
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await InventoryDAO.getAccountInventory(accountId, pageNumber, {}, data, 5);
            this.setState({ inventories: response.data.inventaires, totalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    showStatus(status) {
        if (status == "Brouillon") {
            return (
                <p className="tag orange" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else if (status == "Annulé") {
            return (
                <p className="tag red" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else if (status == "Complété") {
            return (
                <p className="tag green" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else {
            return (
                <p className="tag green" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        }
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1 }, () => this.getInventories());
        }
    }
    resetSearch() {
        this.setState(
            {
                date: null,
                created_by: "",
                selected_user: {},
                created_at: null,
                status: "",
            },
            () => this.getInventories()
        );
    }
    render() {
        const { inventories, pageNumber, totalItems, search } = this.state;
        const { noInventoryCas } = this.props;
        if (!search && inventories.length === 0) {
            return noInventoryCas;
        }

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Inventaires</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}
                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Date
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Créé par
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Créé le
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    statut
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {inventories.length != 0 &&
                                inventories.map((object, index) => {
                                    return (
                                        <Tr key={"key3_" + index}>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.inventory.details}/${object.id}`)}>{moment(object.date).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.inventory.details}/${object.id}`)}>{object.cree_par}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.inventory.details}/${object.id}`)}>{moment(object.cree_le).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.inventory.details}/${object.id}`)}>{this.showStatus(object.statut)}</Td>
                                            <Td className="table_action" />
                                        </Tr>
                                    );
                                })}

                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        {acl.inventory.add() && (
                                            <a className="btn_white" onClick={() => this.props.history.push(`${RoutesPath.inventory.add}/${this.props.accountId}`)}>
                                                Créer
                                            </a>
                                        )}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        this.getInventories();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / (this.props.limitForPagination ? this.props.limitForPagination : 20) && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        this.getInventories();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { date, selected_user, created_at, status } = this.state;

        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={date}
                            onChange={(date) => {
                                this.setState({
                                    date: date,
                                });
                            }}
                            label="Date"
                            id="date"
                            showTimeSelect={false}
                            dateFormat={"yyyy-MM-dd"}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/utilisateurs"
                            HTTPMethod="post"
                            receivedObject="utilisateurs"
                            optionLabelProperty="fullname"
                            optionValueProperty="id"
                            value={selected_user}
                            placeholder="Téléchargé par"
                            onChange={(e) => {
                                this.setState({ selected_user: e, created_by: e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={created_at}
                            onChange={(date) => {
                                this.setState({
                                    created_at: date,
                                });
                            }}
                            dateFormat="yyyy-MM-dd"
                            showTimeSelect={false}
                            label="Créé le"
                            id="Créé le"
                        />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect
                            onKeyDown={this.handleKeyDown.bind(this)}
                            error={false}
                            onChange={(value) => {
                                this.setState({ status: value.target.value });
                            }}
                            selectedValue={status}
                            label="Statut"
                        >
                            <option value="" defaultValue></option>
                            <option value="Brouillon">Brouillon</option>
                            <option value="Complété">Complété</option>
                            <option value="Annulé">Annulé</option>
                        </CustomSelect>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getInventories())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(InventoryListTable);
