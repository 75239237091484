import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import AccountsTable from "../components/AccountsTable";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";

class AccountsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        //(this.props)

    }
    componentWillUnmount() { }

    render() {
        const { acl_actions } = this.props;

        return (
            <Main_container className="Main_container">
                {/* <TopOptions title="Comptes">
                    {acl.account.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(RoutesPath.account.add)}>
                            Créer
                        </button>
                    )}
                </TopOptions> */}

                <AccountsTable {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(AccountsPage);
