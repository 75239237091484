import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../../values/RoutesPath";
import { acl } from "../../services/acl";

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
            ui: {
                open_bloc_1: "none",
                open_bloc_2: "none",
                open_bloc_3: "none",
                open_bloc_4: "none",
                open_bloc_5: "none",
            },
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";

        //(this.props)
    }
    openMenu(itemNum) {
        const item = `open_bloc_${itemNum}`;
        this.setState(
            (prevState) => ({
                ...prevState,
                ui: {
                    ...prevState.ui,
                    [item]: this.state.ui[item] === "none" ? "bloc" : "none",
                },
            }),
            () => this.state.ui[item]
        );
    }
    render() {
        const { ui } = this.state;

        return (
            <Main_container className="Main_container">
                <TopOptions title="Rapports"></TopOptions>
                <Bloc className="Bloc">
                    <div className="container4">
                        <div className="grid2">
                            <div className="rapport_accordion">
                                <ul className="accordion">
                                    <li className="accord r_principaux" style={{ height: "auto" }}>
                                        <div onClick={() => this.openMenu(1)} className={`accord_title ${ui.open_bloc_1 === "bloc" && "in"}`} style={{ height: "50px" }}>
                                            <div className="accord_left ">
                                                <span>1</span>
                                            </div>
                                            <h3>Rapport des commandes</h3>
                                        </div>
                                        <MenuContainer className="accord_content" display={ui.open_bloc_1} height="35px">
                                            <ul>
                                                <div className="accord_content_left">
                                                    <Link to={`${RoutesPath.report.purchase_orders}`}>
                                                        <li>
                                                            <span>Rapport général des commandes</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                                <div className="accord_content_right"></div>
                                            </ul>
                                        </MenuContainer>
                                    </li>
                                    <li className="accord r_principaux" style={{ height: "auto" }}>
                                        <div onClick={() => this.openMenu(2)} className={`accord_title ${ui.open_bloc_2 === "bloc" && "in"}`} style={{ height: "50px" }}>
                                            <div className="accord_left ">
                                                <span>3</span>
                                            </div>
                                            <h3>Rapports sur la concurrence</h3>
                                        </div>
                                        <MenuContainer className="accord_content" display={ui.open_bloc_2} height="35px">
                                            <ul>
                                                <div className="accord_content_left">
                                                    <Link to={`${RoutesPath.report.visitsbycompetitorandsector}`}>
                                                        <li>
                                                            <span>Classement de la concurrence par secteur</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                                <div className="accord_content_right"></div>
                                            </ul>
                                        </MenuContainer>
                                    </li>
                                    <li className="accord r_principaux" style={{ height: "auto" }}>
                                        <div onClick={() => this.openMenu(3)} className={`accord_title ${ui.open_bloc_3 === "bloc" && "in"}`} style={{ height: "50px" }}>
                                            <div className="accord_left ">
                                                <span>5</span>
                                            </div>
                                            <h3>Rapport des contacts</h3>
                                        </div>
                                        <MenuContainer className="accord_content" display={ui.open_bloc_3} height="35px">
                                            <ul>
                                                <div className="accord_content_left">
                                                    {acl.report.contact_by_user() && (
                                                        <Link to={`${RoutesPath.report.contact_by_user}`}>
                                                            <li>
                                                                <span>Contacts par utilisateur</span>
                                                            </li>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div className="accord_content_right"></div>
                                            </ul>
                                        </MenuContainer>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="grid2">
                            <div className="rapport_accordion">
                                <ul className="accordion">
                                    <li className="accord r_comptables" style={{ height: "auto" }}>
                                        <div onClick={() => this.openMenu(4)} className={`accord_title ${ui.open_bloc_4 === "bloc" && "in"}`} style={{ height: "50px" }}>
                                            <div className="accord_left ">
                                                <span>2</span>
                                            </div>
                                            <h3>Rapport des événements</h3>
                                        </div>
                                        <MenuContainer className="accord_content" display={ui.open_bloc_4} height="60px">
                                            <ul>
                                                <div className="accord_content_left">
                                                    {acl.report.visits() && (
                                                        <Link to={`${RoutesPath.report.visits}`}>
                                                            <li>
                                                                <span>Rapport général des événements</span>
                                                            </li>
                                                        </Link>
                                                    )}
                                                    {acl.report.visitor_by_channel() && (
                                                        <Link to={`${RoutesPath.report.visitor_by_channel}`}>
                                                            <li>
                                                                <span>Événements par visiteur/canal</span>
                                                            </li>
                                                        </Link>
                                                    )}
                                                </div>
                                                <div className="accord_content_right">
                                                    {acl.report.visitor_by_purpose() && (
                                                        <Link to={`${RoutesPath.report.visitor_by_purpose}`}>
                                                            <li>
                                                                <span>Événements par visiteur/objectif de événement</span>
                                                            </li>
                                                        </Link>
                                                    )}
                                                    {acl.report.visitor_by_contact_category() && (
                                                        <Link to={`${RoutesPath.report.visitor_by_contact_category}`}>
                                                            <li>
                                                                <span>Événements par visiteur/catégorie de contact</span>
                                                            </li>
                                                        </Link>
                                                    )}
                                                </div>
                                            </ul>
                                        </MenuContainer>
                                    </li>
                                    <li className="accord r_principaux" style={{ height: "auto" }}>
                                        <div onClick={() => this.openMenu(5)} className={`accord_title ${ui.open_bloc_5 === "bloc" && "in"}`} style={{ height: "50px" }}>
                                            <div className="accord_left ">
                                                <span>4</span>
                                            </div>
                                            <h3>Rapports des inventaires</h3>
                                        </div>
                                        <MenuContainer className="accord_content" display={ui.open_bloc_5} height="35px">
                                            <ul>
                                                <div className="accord_content_left">
                                                    <Link to={`${RoutesPath.report.inventory_by_account}`}>
                                                        <li>
                                                            <span>Inventaires par Compte</span>
                                                        </li>
                                                    </Link>
                                                </div>
                                                <div className="accord_content_right"></div>
                                            </ul>
                                        </MenuContainer>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    ${"" /* box-shadow: 0 1px 3px 0 #bcbcbc; */}
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const MenuContainer = styled.div`
    overflow: hidden !important;
    height: ${(props) => props.height} !important;
    margin-bottom: 0 !important;
    display: ${(props) => props.display} !important;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(Report);
