import React, { Component } from "react";
import BeingProcessed from "../BeingProcessed";

class Publication extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return <BeingProcessed />;
    }
}

export default Publication;
