import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import InventoryDAO from "../DAO/InventoryDAO";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { MessagesText } from "../values/MessagesText";
import { Link } from "react-router-dom";
import { acl } from "../services/acl";

class DetailsInventoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inventory: null,
            products: [],
            is_loaded: false,
            productState: {
                pageNumber: 1,
                totalItems: 0,
            },
        };
    }

    componentDidMount() {
        document.title = "Inventaires - Sobrus Labs";
        this.get();
        this.getProducts();
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await InventoryDAO.getInventoryById(id, {});

            this.setState({ inventory: response.data, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getProducts() {
        try {
            const id = this.props.match.params.id;
            const pageNumber = this.state.productState.pageNumber;
            const data = {};
            const response = await InventoryDAO.getInventoryPanel(id, pageNumber, {}, data);
            this.setState((prevState) => ({
                ...prevState,
                productState: {
                    ...prevState.productState,
                    totalItems: response.data.total,
                },
                products: response.data.produits,
            }));
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    showAllProduct(all_product) {
        if (all_product) {
            return <Field_value className="tag_yes">{"Oui"}</Field_value>;
        } else {
            return <Field_value className="tag_no">{"Non"}</Field_value>;
        }
    }
    nextPage = () => {
        this.setState(
            (prevState) => ({
                ...prevState,
                productState: {
                    ...prevState.productState,
                    pageNumber: prevState.productState.pageNumber + 1,
                },
            }),
            () => this.getProducts()
        );
    };

    prevPage = () => {
        this.setState(
            (prevState) => ({
                ...prevState,
                productState: {
                    ...prevState.productState,
                    pageNumber: prevState.productState.pageNumber - 1,
                },
            }),
            () => this.getProducts()
        );
    };

    async changeInventoryStatus(status) {
        try {
            const id = this.props.match.params.id;
            const params = {};
            const data = {
                statut: status,
            };
            const response = await InventoryDAO.updateInventoryStatus(id, params, data);
            this.setState({ inventory: null, is_loaded: false }, () => this.get());
        } catch (error) { }
    }

    render() {
        const { is_loaded, inventory, successMessage, products, productState } = this.state;
        const title = inventory ? moment(inventory.date).format("YYYY-MM-DD") : "";
        const id = this.props.match.params.id;
        let statusClassName = "bloc_content devis_statut ds_brouillon";

        if (inventory && inventory.statut.toLocaleLowerCase() === "complété") {
            statusClassName = "bloc_content devis_statut ds_complete";
        } else if (inventory && inventory.statut.toLocaleLowerCase() === "annulé") {
            statusClassName = "bloc_content devis_statut ds_annule";
        } else {
            statusClassName = "bloc_content devis_statut ds_brouillon";
        }
        return (
            <Main_container className="Main_container">
                <TopOptions title={"L'inventaire : " + title}>
                    {acl.inventory.update_state() && inventory && inventory.statut !== "Annulé" && (
                        <button className="btn_red protected_action" data-shortcut_alias="create" onClick={() => this.changeInventoryStatus("Annulé")}>
                            Annuler
                        </button>
                    )}
                    {acl.inventory.update_state() && inventory && inventory.statut !== "Complété" && inventory.statut !== "Annulé" && (
                        <button className="btn_white" data-shortcut_alias="create" onClick={() => this.changeInventoryStatus("Complété")}>
                            Compléter
                        </button>
                    )}
                    {acl.inventory.update() && inventory && inventory.statut !== "Complété" && inventory.statut !== "Annulé" && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.inventory.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title>Informations générales</Title>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Commentaire</Field_label>
                                            {/* <Field_value_green>{inventory && inventory.commentaire}</Field_value_green> */}
                                            {is_loaded ? (
                                                <Field_value>{inventory && inventory.commentaire}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Tous les produits</Field_label>
                                            {is_loaded ? (
                                                inventory && this.showAllProduct(inventory.tous_produits)
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Date</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{inventory && moment(inventory.date).format("YYYY-MM-DD")}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Compte</Field_label>
                                            {is_loaded ? (
                                                inventory.compte_id ? (
                                                    <Link to={`${RoutesPath.account.details}/${inventory.compte_id}`}>
                                                        <Field_value isLink={true}>{inventory && inventory.compte}</Field_value>
                                                    </Link>
                                                ) : (
                                                        <Field_value>{inventory && inventory.compte}</Field_value>
                                                    )
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gestionnaire</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{inventory && inventory.gestionnaire}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                        {products.length === 0 && (
                            <Block_contact>
                                <Title_contact>Produits de l'inventaire</Title_contact>
                                <Btn_contact_container>
                                    {acl.inventory.update_inventory_product() && inventory && inventory.statut !== "Complété" && inventory.statut !== "Annulé" && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.inventory.product_list}/${id}`)}>Gérer</Create_contact_btn>}
                                </Btn_contact_container>
                            </Block_contact>
                        )}
                        {acl.inventory.product_list() && products.length !== 0 && (
                            <Bloc className="Bloc">
                                <div className="bloc_header">
                                    <h2>Produits</h2>
                                    <div className="bloc_header_btns">{/* <a href="#" className="sprite-bloc-search" /> */}</div>
                                </div>
                                {inventory && (
                                    <ProductListTable
                                        deleteActions={inventory && (inventory.statut == "Complété" || inventory.statut == "Annulé")}
                                        pageNumber={productState.pageNumber}
                                        totalItems={productState.totalItems}
                                        onPrevPageClick={this.prevPage}
                                        onNextPageClick={this.nextPage}
                                        data={products}
                                        {...this.props}
                                        inventoryId={id}
                                    />
                                )}
                            </Bloc>
                        )}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={statusClassName}>
                                <h3>{inventory && inventory.statut}</h3>
                            </div>
                        </div>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{inventory && inventory.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{inventory && moment(inventory.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{inventory && inventory.mis_a_jour_par ? inventory && inventory.mis_a_jour_par : inventory && inventory.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{inventory && inventory.mis_a_jour_le ? inventory && moment(inventory.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : inventory && moment(inventory.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsInventoryPage);

class ProductListTable extends Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            inventories: [],
            deleteActions: this.props.deleteActions || false,
            print: false,
        };
    }
    componentDidMount() {
        const inventories = this.props.data;
        if (inventories) {
            this.setState({ inventories });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                data: this.props.data,
            });
        }
    }

    async deleteItem(id) {
        try {
            const { inventories } = this.state;
            var delete_item = window.confirm("voulez-vous vraiment supprimer ce produit");
            if (delete_item) {
                const response = await InventoryDAO.deleteProductFromPanel(id);
                if (response && response.data.status == "ok") {
                    var filteredData = inventories.filter(function (el) {
                        return el.detailId != id;
                    });
                    this.setState({ inventories: [...filteredData] }, () => {
                        notify({ msg: MessagesText.inventory.panel.delete, type: "success" });
                    });
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { inventories, deleteActions, print } = this.state;
        const { pageNumber, totalItems, onPrevPageClick, onNextPageClick, inventoryId } = this.props;
        return (
            <div className="bloc_content table_wrraper">
                <table ref={(el) => (this.componentRef = el)} className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                    <thead>
                        <tr className="table_th_orderable">
                            <Th>
                                <OrderUp href="#" />
                                <OrderDown href="#" />
                                Nom
                            </Th>
                            <Th>
                                <OrderUp href="#" />
                                <OrderDown href="#" /> PPH
                            </Th>
                            <Th>
                                <OrderUp href="#" />
                                <OrderDown href="#" />
                                Valeur en PPV
                            </Th>
                            <Th>
                                <OrderUp href="#" />
                                <OrderDown href="#" />
                                Valeur en PPH
                            </Th>
                            <Th>
                                <OrderUp href="#" />
                                <OrderDown href="#" /> Quantité
                            </Th>
                            {!deleteActions && !print && <Th className="table_actions" />}
                        </tr>
                    </thead>

                    <tbody>
                        {inventories.length != 0 &&
                            inventories.map((inventory, index) => {
                                return (
                                    <Tr key={"product_list_key_" + index}>
                                        <Td onClick={() => acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${inventory.produitId}`)}>{inventory.nom}</Td>
                                        <Td onClick={() => acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${inventory.produitId}`)}>{`${parseFloat(inventory.pph).toFixed(2)}`}</Td>
                                        <Td onClick={() => acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${inventory.produitId}`)}>{`${parseFloat(inventory.valeur_ppv).toFixed(2)}`}</Td>
                                        <Td onClick={() => acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${inventory.produitId}`)}>{`${parseFloat(inventory.valeur_pph).toFixed(2)}`}</Td>
                                        <Td onClick={() => acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${inventory.produitId}`)}>{inventory.qte}</Td>
                                        {!deleteActions && !print && (
                                            <Td className="table_action">
                                                <a onClick={() => this.deleteItem(inventory.detailId)} className="sprite-table-delete-btn  tooltip" data-confirm_msg="" data-removeable_element="parents::tr"></a>
                                                <a onClick={() => this.props.history.push(`${RoutesPath.inventory.update_inventory_product}/${inventoryId}/${inventory.produitId}`)} className="sprite-table-edit-btn  tooltip" data-confirm_msg="" data-removeable_element="parents::tr"></a>
                                            </Td>
                                        )}
                                    </Tr>
                                );
                            })}

                        {/* <Tr>
                            <ReactToPrint
                                content={() => this.componentRef}
                                onAfterPrint={()=>this.setState({deleteActions:false})}
                                >
                            <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                     <button onClick={()=>this.setState({deleteActions:true},handlePrint)}>Print this out!</button>
                                )}
                            </PrintContextConsumer>
                            </ReactToPrint>
                        </Tr> */}
                        {/* <TablePagination
                        colSpan={8}
                        pageNumber={pageNumber}
                        totalItems={totalItems}
                        onPrevBtnClick={onPrevPageClick}
                        onNextBtnClick={onNextPageClick}
                        /> */}
                        {!print && (
                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        {acl.inventory.update_inventory_product() && !deleteActions && !print && (
                                            <a className="btn_white" onClick={() => this.props.history.push(`${RoutesPath.inventory.product_list}/${inventoryId}`)}>
                                                Créer
                                            </a>
                                        )}
                                    </div>
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        <ReactToPrint content={() => this.componentRef} onAfterPrint={() => this.setState({ print: false })}>
                                            <PrintContextConsumer>
                                                {({ handlePrint }) => (
                                                    <a href="#" onClick={() => this.setState({ print: true }, handlePrint)} className="btn_white">
                                                        Imprimer
                                                    </a>
                                                )}
                                            </PrintContextConsumer>
                                        </ReactToPrint>
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && <a className="sprite-table-prev-btn" onClick={onPrevPageClick} />}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / 20 && <a className="sprite-table-next-btn" onClick={onNextPageClick} />}
                                    </div>
                                </Td>
                            </Tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value = styled.p`
    display: block;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
    min-width: 1014px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    min-width: 1014px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
