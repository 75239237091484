import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpAccounts from "../components/PopUpAccounts";
import FlashMessages from "../helpers/FlashMessages";
import CustomDatePicker from "../components/CustomDatePicker";
import CustomFileInput from "../components/CustomFileInput";
import { errorMessage } from "../tmp/Messages";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import AccountDAO from "../DAO/AccountDAO";
import GalleryDAO from "../DAO/GalleryDAO";
import UserDAO from "../DAO/UserDAO";
import ProductDAO from "../DAO/ProductDAO";
import EventDAO from "../DAO/EventDAO";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import { DatePicker } from "../components/CustomDatePicker";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";

class AddPhotoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            name: "",
            name_error: false,

            file: "",
            file_error: false,
            file_error_message: "",

            ready: false,
            users: [],
            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",

            account: "",
            accounts: [],
            id_account: "",
            account_error: false,
            accountItemsNumber: 0,

            product: "",
            Products: [],
            id_product: "",
            product_error: false,
            productItemsNumber: 0,

            event: "",
            events: [],
            id_event: "",
            event_error: false,
            eventItemsNumber: 0,

            openPopUp: false,
            photo_id: 0,

            loading: false,
            product_loading: false,
            event_loading: false,
            account_loading: false,
            manager: "",
            id_manager: "",
            error_manager: false,
        };
    }
    componentDidMount() {
        document.title = "Galerie - Sobrus Labs";
        const id = this.props.match.params.id;
        const id_account = this.props.match.params.id_account;
        if (id) {
            this.setState({ photo_id: id });
            this.get();
        }
        if (id_account) {
            this.getAccount(id_account);
        }
        this.getUsers();
        !id && this.getConnectedUserInfos();
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await GalleryDAO.getPhotoById(id, {});
            const tmpDate = moment(response.data.date);
            this.setState({
                account: response.data.compte,
                id_account: response.data.compte_id,
                id_event: response.data.event_id,
                id_product: response.data.produit_id,
                product: response.data.produit,
                event: response.data.event,
                name: response.data.nom,
                id_manager: response.data.gestionnaire_id,
                date: new Date(tmpDate.format("YYYY"), tmpDate.format("MM"), tmpDate.format("DD"), tmpDate.format("HH"), tmpDate.format("mm"), tmpDate.format("ss")),
                // file: {
                //     name: response.data.path,
                // },
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getAccount(id) {
        try {
            const response = await AccountDAO.getAccountById(id, {});
            this.setState({ account: response.data.libelle, id_account: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    testFileType(file) {
        const imageTypes = ["image/png", "image/jpg", "image/jpeg", "image/tif"];
        let typeTest = false;
        imageTypes.map((image_type) => {
            if (file.type == image_type) {
                typeTest = true;
                return typeTest;
            }
        });
        return typeTest;
    }

    verifyRequired() {
        const { date, file, name, id_event, id_product, id_account, id_manager } = this.state;
        if (file && file.length !== 0 && id_account && id_account.length !== 0 && name && name.length !== 0 && id_manager && id_manager.length !== 0 && this.testFileType(file)) {
            const data = new FormData();
            data.append("photo", file);
            if (id_product && id_product.length != 0) {
                data.append("produit", id_product);
            }
            if (id_event && id_event.length != 0) {
                data.append("event", id_event);
            }
            data.append("compte", id_account);
            data.append("nom", name);
            data.append("gestionnaire", id_manager);

            data.append("date", moment(date).format("YYYY-MM-DD HH:mm"));
            return data;
        } else {
            if (!file || (file && file.length === 0)) {
                this.setState({ file_error: true, file_error_message: "Ce champ est obligatoir" });
            }
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!name || (name && name.length === 0)) {
                this.setState({ name_error: true });
            }
            if (!this.testFileType(file)) {
                this.setState({ file_error: true, file_error_message: "incompatible type" });
            }
            if (!id_manager || (id_manager && id_manager.length === 0)) {
                this.setState({ error_manager: true });
            }
            return null;
        }
    }

    async add() {
        try {
            const data = this.verifyRequired();
            if (data) {
                this.setState({ loading: true });
                const response = await GalleryDAO.addPhoto({}, data);
                this.setState({ loading: false });
                if ((response.data.status = "ok")) {
                    notify({ msg: MessagesText.gallery.add, type: "success" });
                    this.props.history.push(`${RoutesPath.gallery.details}/${response.data.photo.id}`);
                } else if ((response.data.status = "ko")) {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequiredForUpdate() {
        const { date, file, name, id_event, id_product, id_account, id_manager } = this.state;
        if (id_account && id_account.length !== 0 && name && name.length !== 0 && id_manager && id_manager.length !== 0) {
            let data = new FormData();
            if (file.length != 0) {
                data.append("is_updated", 1);
                data.append("photo", file);
            } else {
                data.append("is_updated", 0);
            }
            if (id_product && id_product.length != 0) {
                data.append("produit", id_product);
            }
            if (id_event && id_event.length != 0) {
                data.append("event", id_event);
            }
            data.append("compte", id_account);
            data.append("nom", name);
            data.append("gestionnaire", id_manager);
            data.append("date", moment(date).format("YYYY-MM-DD"));
            return data;
        } else {
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!name || (name && name.length === 0)) {
                this.setState({ name_error: true });
            }
            if (!id_manager || (id_manager && id_manager.length === 0)) {
                this.setState({ error_manager: true });
            }
            return null;
        }
    }
    async update() {
        try {
            const data = this.verifyRequiredForUpdate();
            if (data) {
                const id = this.props.match.params.id;
                this.setState({ loading: true });
                const response = await GalleryDAO.updatePhoto(id, {}, data);
                this.setState({ loading: false });
                if ((response.data.status = "ok")) {
                    notify({ msg: MessagesText.gallery.update, type: "success" });
                    this.props.history.push(`${RoutesPath.gallery.details}/${response.data.photo.id}`);
                } else if ((response.data.status = "ko")) {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };

            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ account: value.libelle, id_account: value.id });
        this.closePopUp();
    }

    setAccount(value) {
        this.setState({ account: `${value.libelle} (${value.ville ? value.ville : ""})`, id_account: value.id, account_error: false });
    }

    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }

    async getAccounts(data, pageNumber = 1) {
        try {
            this.setState({ account_loading: true });
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ account_loading: false });

            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ account_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setProduct(value) {
        this.setState({ product: value.nom, id_product: value.id });
    }

    resetProduct() {
        this.setState({ product: "", id_product: "" });
    }

    async getProducts(data, pageNumber = 1) {
        try {
            this.setState({ product_loading: true });
            const response = await ProductDAO.getALLProductsWithPostMeth(pageNumber, {}, data);
            this.setState({ product_loading: false });

            this.setState({ products: response.data.ressources, productItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ product_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setEvent(value) {
        this.setState({ event: value.nom, id_event: value.id });
    }

    resetEvent() {
        this.setState({ event: "", id_event: "" });
    }

    async getEvents(data, pageNumber = 1) {
        try {
            this.setState({ event_loading: true });
            const filterData = {
                ...data,
                disabled: {
                    value: 0,
                    op: "is",
                },
            };
            const response = await EventDAO.getALLEvents(pageNumber, {}, filterData);

            this.setState({ event_loading: false });

            this.setState({ events: response.data.events, eventItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ event_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const {
            id_manager,
            error_manager,
            account_loading,
            event_loading,
            product_loading,
            loading,
            photo_id,
            account_error,
            file_error,
            name_error,
            event,
            events,
            eventItemsNumber,
            product,
            products,
            productItemsNumber,
            accounts,
            accountItemsNumber,
            openPopUp,
            date,
            account,
            file,
            name,
            errorMessage,
            users,
        } = this.state;
        const title = photo_id ? `Modifier photo : ${name}` : "Ajouter une photo";
        const subTitle = photo_id ? `Modifier photo` : "Ajouter une photo";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (photo_id != 0 ? this.update() : this.add())}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{subTitle}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <CustomSelect
                                    error={error_manager}
                                    onChange={(value) => {
                                        this.setState({ id_manager: value.target.value, error_manager: false });
                                    }}
                                    selectedValue={id_manager}
                                    label="Gestionnaire *"
                                >
                                    <option defaultValue></option>
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key1_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    name="account"
                                    label={"Compte*"}
                                    setValue={this.setAccount.bind(this)}
                                    reset={this.resetAccount.bind(this)}
                                    value={account}
                                    data={accounts}
                                    propertyName={"libelle"}
                                    searchData={this.getAccounts.bind(this)}
                                    itemsNumber={accountItemsNumber}
                                    getData={() => this.getAccounts()}
                                    error={account_error}
                                    modalTitle="Choisissez un compte"
                                    loading={account_loading}
                                />
                            </InputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    label={"Événement"}
                                    setValue={this.setEvent.bind(this)}
                                    reset={this.resetEvent.bind(this)}
                                    value={event}
                                    data={events ? events : []}
                                    propertyName={"nom"}
                                    searchData={this.getEvents.bind(this)}
                                    itemsNumber={eventItemsNumber}
                                    getData={() => this.getEvents()}
                                    loading={event_loading}
                                    modalTitle="Choisissez un événement"
                                />
                            </InputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    label={"Produit"}
                                    setValue={this.setProduct.bind(this)}
                                    reset={this.resetProduct.bind(this)}
                                    value={product}
                                    data={products ? products : []}
                                    propertyName={"nom"}
                                    searchData={this.getProducts.bind(this)}
                                    itemsNumber={productItemsNumber}
                                    getData={() => this.getProducts()}
                                    loading={product_loading}
                                    modalTitle="Choisissez un produit"
                                />
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => {
                                        this.setState({
                                            date: date,
                                        });
                                    }}
                                    label="Date"
                                    id="date"
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomFileInput
                                    value={file && file.name}
                                    onChange={(Value) => {
                                        let text = Value.target.files[0];
                                        this.setState({ file: text, file_error: false });
                                        name.length === 0 && this.setState({ name: text.name, name_error: false });
                                    }}
                                    id="file"
                                    error={file_error}
                                    id="file"
                                    label={photo_id ? "Fichier" : "Fichier *"}
                                ></CustomFileInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={name_error}
                                    onChange={(value) => {
                                        this.setState({ name: value.target.value, name_error: false });
                                    }}
                                    defaultValue={name}
                                    value={name}
                                    id="name"
                                    label="Nom de la photo *"
                                ></CustomInput>
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 30%;
    font-family: Roboto;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: #284358;
`;
const Reset_btn = styled.div`
    position: absolute;
    top: 11px;
    right: 9px;
`;
const Svg = styled.svg`
    cursor: pointer;
`;
const Input = styled.input`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    font-size: 14px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px #bbc8d3;
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    ${"" /* &:focus ~ ${PopUp}{
        display:block;
    }; */}
    &:focus ~ ${Label} , &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const Button = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: transparent;
`;
const ErrorMessage = styled.div`
    height: 50px;
    width: 100%;
    background: #ff4a4a;
    text-align: center;
    padding-top: 14px;
`;
const ErrorMessageText = styled.div`
    color: #fff;
    display: inline-block;
    vertical-align: top;
`;
const Search_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 7px;
    height: 20px;
    width: 20px;
`;
const Reset1_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 27px;
    height: 20px;
    width: 20px;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddPhotoPage);
