import React, { Component } from "react";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        document.title = this.props.title;
    }
    render() {
        return (
            <div>
                {/* <Header {...this.props} /> */}
                {this.props.children}
            </div>
        );
    }
}

export default Layout;
