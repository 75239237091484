import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import PlanningDAO from "../DAO/PlanningDAO";
import EventDAO from "../DAO/EventDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import UserDAO from "../DAO/UserDAO";

import { Link } from "react-router-dom";
import AccountDAO from "../DAO/AccountDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import ResourceDAO from "../DAO/ResourceDAO";
import SearchSelect from "../helpers/SearchSelect";
import { DatePicker } from "../components/CustomDatePicker";

import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "../components/seachStyledComponents";
import FromLoading from "../helpers/FromLoading";
import { notify } from "../helpers/FlashMessage";
class NewEventsToPlanning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
            resources: [],
            resourceStates: {
                pageNumber: 1,
                totalItems: 0,
            },
            successMessage: "",
            is_loaded: false,
            planningEvents: [],
            users: [],
            id_manager: "",
            resource_type: "Pharmacie",

            base_date: new Date(),
            variant_date: new Date(),
            interval: 30,
            intervalError: false,
            maxEvents: 10,

            loading: false,
            resourcesCreatedEvents: [],
        };
    }
    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";

        this.get();
        // this.getPlanningEvents();
        this.getUsers();
        this.getConnectedUserInfos();
        this.getResources();
    }
    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async affectEvents() {
        try {
            const { planningEvents } = this.state;
            const id = this.props.match.params.id;
            if (planningEvents && planningEvents.length !== 0) {
                const data = {
                    ressources: planningEvents,
                };

                this.setState({ loading: true });
                const response = await PlanningDAO.affectNewEventsToPlanning(id, {}, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.affectEvents, type: "success" });
                    this.props.history.push(`${RoutesPath.planning.details}/${id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await PlanningDAO.getPlanningById(id, {});

            this.setState({ planning: response.data.planning, is_loaded: true });
            // const base_date = Date.parse(response.data.planning.date_debut);
            let date = Date.parse(response.data.planning.date_debut);
            const base_date = new Date(date);
            const current_date = new Date();
            if (current_date.getMinutes() <= 30) {
                base_date.setHours(current_date.getHours(), 0);
                base_date.setMinutes(30);
            } else {
                base_date.setMinutes(0);
                base_date.setHours(current_date.getHours() + 1, 0);
            }
            this.setState({ variant_date: base_date, base_date: base_date });
            // response.data.planning.date_debut && this.setState({ base_date: base_date, variant_date: base_date });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    // async getPlanningEvents() {
    //     try {
    //         const planning_id = this.props.match.params.id;
    //         const response = await PlanningDAO.getPlanningEvents(planning_id, {});
    //         this.setState({ planningEvents: response.data, planningEventsLoaded: true });
    //     } catch (error) {
    //         (error);
    //         this.setState({ planningEventsLoaded: true });
    //     }
    // }

    async getResources(data = {}, pageNumber = 1) {
        try {
            const { resource_type } = this.state;
            let searchData = data;

            searchData["disabled"] = {
                value: 0,
                op: "is",
            };
            searchData["orderby"] = {
                col: "id",
                dir: "DESC",
            };

            let resourceSearch = "";
            if (resource_type === "Pharmacie") {
                resourceSearch = "pharmacies";
            } else if (resource_type === "Etablissement de santé") {
                resourceSearch = "etablissements";
            } else if (resource_type === "Association") {
                resourceSearch = "associations";
            }

            const response = await ResourceDAO.getALLResource(resourceSearch, pageNumber, {}, searchData);
            this.setState({ resources: response.data.ressources, resourceStates: { pageNumber, totalItems: response.data.total } }, () => this.state.resourceStates);
        } catch (error) {
            // this.setState({ eventsLoaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }

    formateDate(date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
    }

    addToArray(array, object) {
        const { variant_date, interval, resource_type, maxEvents, base_date } = this.state;
        if (array && array.length !== 0 && (array.length + 1) % parseInt(maxEvents) === 0) {
            if (!isNaN(parseInt(interval))) {
                const event = {
                    id: object.id,
                    nom: object.nom,
                    date_debut: moment(variant_date).format("YYYY-MM-DD HH:mm"),
                    date_fin: moment(new Date(new Date(variant_date).getTime() + parseInt(interval) * 60000)).format("YYYY-MM-DD HH:mm"),
                    type: resource_type,
                };

                let variantDate = new Date(variant_date);
                variantDate.setDate(new Date(variant_date).getDate() + 1);
                variantDate.setHours(new Date(base_date).getHours());
                variantDate.setMinutes(new Date(base_date).getMinutes());
                variantDate.setSeconds(new Date(base_date).getSeconds());

                this.setState({ variant_date: variantDate });
                return [event, ...array];
            } else {
                this.setState({ interval: 30 });
            }
        } else {
            if (!isNaN(parseInt(interval))) {
                const event = {
                    id: object.id,
                    nom: object.nom,
                    date_debut: moment(variant_date).format("YYYY-MM-DD HH:mm"),
                    date_fin: moment(new Date(new Date(variant_date).getTime() + parseInt(interval) * 60000)).format("YYYY-MM-DD HH:mm"),
                    type: resource_type,
                };
                this.setState({ variant_date: new Date(new Date(variant_date).getTime() + interval * 60000) });
                return [event, ...array];
            } else {
                this.setState({ interval: 30 });
            }
        }

        return array;
    }

    removeFromArray(array = [], object) {
        return array.filter((obj) => obj.id !== object.id);
    }

    isExistInArray(array, object) {
        return array.some((obj) => obj.id === object.id);
    }

    addEventsPlanningArray(resource) {
        const { planningEvents, resources, resourcesCreatedEvents } = this.state;
        this.setState({
            planningEvents: this.addToArray(planningEvents, resource),
            resourcesCreatedEvents: [...resourcesCreatedEvents, resource],
            resources: this.removeFromArray(resources, resource),
        });
        // } else {
        //     this.setState({
        //         events: this.removeFromArray(events, event),
        //     });
        //     alert(MessagesText.planning.affectEventAlert);
        // }
    }

    RemoveEventsFromPlanningArray(event) {
        const { planningEvents, resources, resourcesCreatedEvents } = this.state;
        const resource = resourcesCreatedEvents.find((object) => object.id === event.id);
        this.setState({
            planningEvents: this.removeFromArray(planningEvents, event),
            resources: [resource, ...resources],
            resourcesCreatedEvents: this.removeFromArray(resourcesCreatedEvents, resource),
        });
    }

    render() {
        const { planningEvents, planning, resources, successMessage, users, id_manager, resource_type, resourceStates, loading, intervalError } = this.state;
        const title = planning ? planning.nom : "";
        return (
            <Main_container className="Main_container">
                {loading && <FromLoading />}
                <TopOptions title={"Planning : " + title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.affectEvents()}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block_contact>
                            <InputContainer>
                                <CustomSelect
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ resource_type: value.target.value }, () => {
                                            this.getResources({}, 1);
                                        });
                                    }}
                                    selectedValue={resource_type}
                                    label="Type de Ressource *"
                                >
                                    <option key="Pharmacie" value="Pharmacie" defaultValue>
                                        Pharmacies
                                    </option>
                                    <option key="Etablissement" value="Etablissement de santé">
                                        Etablissements de santé
                                    </option>
                                    <option key="Association" value="Association">
                                        Associations
                                    </option>
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ id_manager: value.target.value, error_manager: false });
                                    }}
                                    selectedValue={id_manager}
                                    label="Gestionnaire *"
                                >
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect>
                            </InputContainer>
                        </Block_contact>
                        {/* events bloc */}
                        <ResourceListTable
                            resourceName={resource_type}
                            getData={(data, pageNumber) => this.getResources(data, pageNumber)}
                            pageNumber={resourceStates.pageNumber}
                            resources={resources}
                            totalItems={resourceStates.totalItems}
                            onClickRow={(resource, index) => this.addEventsPlanningArray(resource, index)}
                        />
                        {/* end events bloc */}
                        {/* {eventsLoaded && <EventListTable tfoot={tfoot} onClickClose={() => {}} onClickRow={(event, index) => this.addEventsPlanningArray(event, index)} noActions={true} data={events} {...this.props} />} */}
                    </Left_block_container>
                    <Right_block_container>
                        <Block_contact>
                            <InputContainer>
                                <DatePicker
                                    selected={this.state.base_date}
                                    onChange={(date) => {
                                        this.setState({
                                            base_date: date,
                                            variant_date: date,
                                        });
                                    }}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ maxEvents: value.target.value });
                                    }}
                                    defaultValue={this.state.maxEvents}
                                    value={this.state.maxEvents}
                                    id="maxEvents"
                                    inputType={"number"}
                                    label="Nbre Event/J Max"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={intervalError}
                                    onChange={(value) => {
                                        this.setState({ interval: value.target.value, intervalError: false });
                                    }}
                                    defaultValue={this.state.interval}
                                    value={this.state.interval}
                                    id="interval"
                                    inputType={"number"}
                                    label="Interval (min)"
                                ></CustomInput>
                            </InputContainer>
                        </Block_contact>
                        <div className="bloc_content table_wrraper">
                            <div className="bloc_header">
                                <h2>Nombre des événements</h2>
                                <div className="bloc_header_btns">
                                    <Bloc_header_text>{planningEvents ? planningEvents.length : 0}</Bloc_header_text>
                                </div>
                            </div>
                            <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                                <thead>
                                    <tr className="table_th_orderable">
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Nom
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" /> Date de début
                                        </Th>
                                        <Th>
                                            <OrderUp href="#" />
                                            <OrderDown href="#" />
                                            Date de fin
                                        </Th>

                                        <Th className="table_actions" />
                                    </tr>
                                </thead>

                                <tbody>
                                    {planningEvents &&
                                        planningEvents.length !== 0 &&
                                        planningEvents.map((event, index) => {
                                            return (
                                                <Tr key={"key2_" + index} onClick={() => { }}>
                                                    <Td>{event.nom}</Td>
                                                    <Td>{event.date_debut && moment(event.date_debut).format("YYYY-MM-DD HH:mm")}</Td>
                                                    <Td>{event.date_fin && moment(event.date_fin).format("YYYY-MM-DD HH:mm")}</Td>

                                                    <Td className="table_action">
                                                        <a onClick={() => this.RemoveEventsFromPlanningArray(event)} className="sprite-table-delete-btn delete_line" title="Imprimer" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />
                                                    </Td>
                                                </Tr>
                                            );
                                        })}

                                    <Tr>
                                        <Td colSpan={8} className="table_pagination">
                                            <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                            <div style={{ display: "inline-block" }}>{/* <span className="table_page">1</span>
                                                <a href="#" className="sprite-table-next-btn" onClick={() => {}} /> */}</div>
                                        </Td>
                                    </Tr>
                                </tbody>
                            </table>
                        </div>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(NewEventsToPlanning);

class ResourceListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            actions: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            name: "",
            date_start: null,
            date_end: null,
            place: "",
            type: "",
            contact: "",
            selectedOption: {},
            city: "",
            sector: "",
            specialite: "",
            selected_city: null,
            selected_sector: null,
            specialite_sector: null,
        };
    }
    componentDidMount() { }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            const { getData } = this.props;
            const { search, name, date_start, date_end, place, type, contact } = this.state;
            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });
            if (data) {
                this.setState({ pageNumber: 1 }, () => {
                    getData(data, this.props.pageNumber);
                });
            }
        }
    }

    resetSearch() {
        const { getData } = this.props;
        this.setState(
            {
                name: "",
                date_start: null,
                date_end: null,
                place: "",
                type: "",
                contact: "",
                selectedOption: {},
                city: "",
                sector: "",
                selected_city: {},
                selected_sector: {},
            },
            () => getData({}, this.props.pageNumber)
        );
    }
    onClickRow(event, index) {
        const { onClickRow } = this.props;
        onClickRow ? onClickRow(event, index) : this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
    }
    render() {
        const { search } = this.state;
        const { resources, totalItems, getData, pageNumber, resourceName } = this.props;
        let title = "";
        switch (resourceName && resourceName.toLocaleLowerCase()) {
            case "pharmacie":
                title = "pharmacies";
                break;
            case "etablissement de santé":
                title = "établissements de santé";
                break;
            case "association":
                title = "associations";
                break;
        }
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>{`Liste des ${title}`}</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}

                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Nom
                                </Th>

                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Ville
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Secteur
                                </Th>
                                {resourceName === "Etablissement de santé" && (
                                    <Th>
                                        <OrderUp href="#" />
                                        <OrderDown href="#" />
                                        Spécialité
                                    </Th>
                                )}

                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {resources &&
                                resources.length != 0 &&
                                resources.map((resource, index) => {
                                    return (
                                        <Tr key={"key2_" + index}>
                                            <Td onClick={() => this.onClickRow(resource, index)}>{resource.nom}</Td>
                                            <Td onClick={() => this.onClickRow(resource, index)}>{resource.ville}</Td>
                                            <Td onClick={() => this.onClickRow(resource, index)}>{resource.secteur}</Td>
                                            {resourceName === "Etablissement de santé" && <Td onClick={() => this.onClickRow(resource, index)}>{resource.specialite}</Td>}
                                            <Td className="table_action"></Td>

                                            {/* <Td className="table_action">{planningId && <Link to={`${RoutesPath.calender.update}/${event && event.id}`} className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" />}</Td> */}
                                        </Tr>
                                    );
                                })}
                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <Link to={`${RoutesPath.calender.add}`} className="btn_white">
                                            Créer
                                        </Link> */}</div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        getData({}, this.state.pageNumber);
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / 20 && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        getData({}, this.state.pageNumber);
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { getData, resourceName } = this.props;
        const { search, name, city, sector } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={name}
                            onChange={(value) => {
                                this.setState({ name: value.target.value });
                            }}
                            label="Nom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/villes"
                            HTTPMethod="get"
                            receivedObject="ressources"
                            optionLabelProperty="nom"
                            optionValueProperty="id"
                            value={this.state.selected_city}
                            placeholder="Ville"
                            onChange={(e) => {
                                this.setState({ selected_city: e, city: e && e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/secteurs"
                            HTTPMethod="get"
                            receivedObject="ressources"
                            optionLabelProperty="nom"
                            optionLabelProperty_2="ville"
                            optionValueProperty="id"
                            value={this.state.selected_sector}
                            placeholder="Secteur"
                            onChange={(e) => {
                                this.setState({ selected_sector: e, sector: e && e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                {resourceName === "Etablissement de santé" && (
                    <Th backgroundColor="#fff">
                        <Search_input_container>
                            <SearchSelect
                                fetchedUrl="/api/specialites"
                                HTTPMethod="post"
                                receivedObject="ressources"
                                optionLabelProperty="nom"
                                optionValueProperty="id"
                                value={this.state.selected_specialite}
                                placeholder="Spécialité"
                                onChange={(e) => {
                                    this.setState({ selected_specialite: e, specialite: e && e.value });
                                }}
                            />
                        </Search_input_container>
                    </Th>
                )}

                <Th backgroundColor="#fff">
                    <Search_btn
                        onClick={() => {
                            const { name, city, sector, specialite, search } = this.state;
                            let data = ResourceDAO.getResourceSearchData({ name, city, sector, specialite, search });

                            if (data) {
                                this.setState({ pageNumber: 1 }, () => {
                                    getData(data, this.props.pageNumber);
                                });
                            }
                        }}
                    >
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 49%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const InputContainer = styled.div`
    width: 244px;
    height: ${(props) => (props.height ? props.height : "41px")};
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    display: "flex";
    flex-direction: "row";
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Bloc_header_text = styled.span`
    color: #00beb0;
    font: 18px "Open Sans", sans-serif;
    font-weight: 800;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    ${"" /* margin-top:10px; */}
    display:flex;
    justify-content: space-between;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    background: #5867c3;
    color: #860b39 !important;
    height: 50px;
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 10px;
    padding-top: 5px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
