import React, { Component } from 'react';
import { Link } from "react-router-dom";

class TopOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const {title} = this.props;
        return (
            <div id="fixed_bar" className="top_options_wrapper">
                <div className="wrapper">
                    <div className="container4">
                        <div className="grid4">
                            <h1>{title}</h1>
                            <div className="top_options_btns">
                               {
                                   this.props.children
                               }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopOptions;