import ApiCall from "../services/ApiCall";

class NotificationDAO {
    // for getting all notifications

    static getALL = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/notifications`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static getALLHistory = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/notifications/history/1/40`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static markeAllAsSeen = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/notification/mark-all-as-seen`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static seeNotification = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/notification/mark-as-seen/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });


}

export default NotificationDAO;
