
import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { RoutesPath } from "../../values/RoutesPath";
import { acl } from "../../services/acl";
import CustomInput from "../../components/CustomInput";
import { DatePicker } from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect";
import SearchSelect from "../../helpers/SearchSelect";
import img from "../../images/sprite_3.png";
import ContentLoader from "react-content-loader";
import { notify } from "../../helpers/FlashMessage";
import CalenderDAO from "../../DAO/CalenderDAO";
import { withRouter } from "react-router";

const DOCTOR_WAS_PRESENT_COLOR = {
    "1": "#00bf9c",
    "0": "#E04052",
};
const DOCTOR_WAS_PRESENT_TEXT = {
    "1": "Oui",
    "0": "Non",
};
class EventsList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            tableEvents: [],
            tableEventsPageNumber: 1,
            tableEventsTotalItems: 0,

            search: false,
            advancedSearch: false,

            id_manager_table: 0,
            date_start_table: null,
            date_end_table: null,

            searchFields: {
                name: "",
                date_start: null,
                date_end: null,
                revisited_at: null,
                doctor_present: "",
                account: "",
                comment: "",
            },

            searchFieldsOperators: {
                name: "",
                date_start: null,
                revisited_at: null,
                doctor_present: "",
                account: "",
                status: "",
            },

            selectedAccount: null,

            orderBy: 0,
            key: "",
        };
    }

    componentDidMount() {
        this.getProps();
    }
    preload = () => {
        this.getAll();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterFields !== this.props.filterFields) {
            this.getProps()
        }
    }
    render() {
        const { orderBy, key, tableEvents, advancedSearch, search, pageNumber, totalItems, tableEventsPageNumber, tableEventsTotalItems } = this.state;

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>{"Liste des événements"}</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                        <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                    </div>
                </div>
                <TableWrapper className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {/* advanced search */}
                            {advancedSearch && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("account", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.account}
                                                label="Compte"
                                                operatorsType="short"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th >
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("date_start", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.date_start}
                                                label="Date de début"
                                                disabled={true}
                                            >
                                                <option defaultValue></option>
                                                <option value="is_date">est</option>
                                                <option value="is_not_date">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("revisited_at", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.revisited_at}
                                                label="À revisiter le"
                                            >
                                                <option defaultValue></option>
                                                <option value="is_date">est</option>
                                                <option value="is_not_date">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("doctor_present", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.doctor_present}
                                                label="Docteur était présent"
                                                operatorsType="short"
                                            />
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("comment", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.comment}
                                                label="Commentaire"
                                                operatorsType="detailed"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {advancedSearch && (
                                            <Search_btn onClick={() => this.setState({ tableEventsPageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </tr>
                            )}
                            {/* end advanced search */}
                            {/* simple search */}
                            {(search || advancedSearch) && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl={`/api/comptes`}
                                                HTTPMethod="post"
                                                receivedObject="comptes"
                                                optionLabelProperty="libelle"
                                                optionValueProperty="id"
                                                value={this.state.selectedAccount}
                                                placeholder="Compte"
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectedAccount: e,
                                                    });
                                                    this.setSearchField("account", e && e.value);
                                                }}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th >
                                        <Search_input_container>
                                            <DatePicker
                                                showTimeSelect={false}
                                                selected={this.state.searchFields.date_start}
                                                onChange={(date) => {
                                                    this.setSearchField("date_start", date);
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                label="Date de début"
                                                id="start_date"
                                                disabled={true}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <DatePicker
                                                showTimeSelect={false}
                                                selected={this.state.searchFields.revisited_at}
                                                onChange={(date) => {
                                                    this.setSearchField("revisited_at", date);
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                label="À revisiter le"
                                                id="revisited_at"
                                            />
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                onChange={(value) => {
                                                    this.setSearchField("doctor_present", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFields.doctor_present}
                                                label="Docteur était présent"
                                            >
                                                <option defaultValue></option>
                                                <option value="oui">Oui</option>
                                                <option value="non">Non</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomInput
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                value={this.state.searchFields.comment}
                                                onChange={(value) => {
                                                    this.setSearchField("comment", value.target.value);
                                                }}
                                                label="Commentaire"
                                                searchIcon={true}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {search && (
                                            <Search_btn onClick={() => this.setState({ tableEventsPageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </tr>
                            )}
                            {/* end simple search */}
                            {/* table head names  */}
                            <tr className="table_th_orderable">
                                <Th key="compte" orderBy={key === "compte" && orderBy} onClick={() => this.setState({ key: "compte", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "compte" && orderBy === -1) || orderBy === 0 || key !== "compte") && <OrderUp />}
                                    {((key === "compte" && orderBy === 1) || orderBy === 0 || key !== "compte") && <OrderDown />}
                                    Compte
                                </Th>
                                <Th key="date_debut" orderBy={key === "date_debut" && orderBy} onClick={() => this.setState({ key: "date_debut", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "date_debut" && orderBy === -1) || orderBy === 0 || key !== "date_debut") && <OrderUp />}
                                    {((key === "date_debut" && orderBy === 1) || orderBy === 0 || key !== "date_debut") && <OrderDown />}
                                    Date de début
                                </Th>
                                {/* <Th key="date_fin" orderBy={key === "date_fin" && orderBy} onClick={() => this.setState({ key: "date_fin", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "date_fin" && orderBy === -1) || orderBy === 0 || key !== "date_fin") && <OrderUp />}
                                    {((key === "date_fin" && orderBy === 1) || orderBy === 0 || key !== "date_fin") && <OrderDown />}
                                    Date de fin
                                </Th> */}

                                <Th key="revisited_at" orderBy={key === "revisited_at" && orderBy} onClick={() => this.setState({ key: "revisited_at", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "revisited_at" && orderBy === -1) || orderBy === 0 || key !== "revisited_at") && <OrderUp />}
                                    {((key === "revisited_at" && orderBy === 1) || orderBy === 0 || key !== "revisited_at") && <OrderDown />}
                                    À revisiter le
                                </Th>

                                <Th key="doctor_present" orderBy={key === "doctor_present" && orderBy} onClick={() => this.setState({ key: "doctor_present", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "doctor_present" && orderBy === -1) || orderBy === 0 || key !== "doctor_present") && <OrderUp />}
                                    {((key === "doctor_present" && orderBy === 1) || orderBy === 0 || key !== "doctor_present") && <OrderDown />}
                                    Docteur était présent
                                </Th>
                                <Th key="comment" orderBy={key === "comment" && orderBy} onClick={() => this.setState({ key: "comment", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "comment" && orderBy === -1) || orderBy === 0 || key !== "comment") && <OrderUp />}
                                    {((key === "comment" && orderBy === 1) || orderBy === 0 || key !== "comment") && <OrderDown />}
                                    Commentaire
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                            {/* end table head name */}
                        </thead>
                        {/* loading */}
                        {false && (
                            <tbody>
                                {[1, 2, 3].map((index) => {
                                    return (
                                        <Tr key={"key__" + index}>

                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            {/* <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td> */}
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td className="table_action"></Td>
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        )}
                        {/* end loading */}
                        {/* fetching data */}
                        <tbody>
                            {tableEvents.map((event, index) => {
                                return (
                                    <Tr key={"key_" + index}>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {event.compte}
                                        </Td>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {(event && event.date_debut) ? moment(event.date_debut).format("YYYY-MM-DD HH:mm") : ""}
                                        </Td>
                                        {/* <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {moment(event.date_fin).format("YYYY-MM-DD HH:mm")}
                                        </Td> */}

                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {(event && event.a_revisiter_le) ? moment(event.a_revisiter_le).format("YYYY-MM-DD HH:mm") : ""}
                                        </Td>

                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            <PriorityContainer2 bgColor={DOCTOR_WAS_PRESENT_COLOR[event && event.docteur_present]}>{DOCTOR_WAS_PRESENT_TEXT[event && event.docteur_present]}</PriorityContainer2>
                                        </Td>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}

                                        >
                                            <Comment>{event.commentaire}</Comment>
                                        </Td>
                                        <Td className="table_action">
                                            {/* {disabledData === 0 && acl.account.update() && (
                                                <a
                                                    onClick={() => {
                                                        this.props.history.push(`${RoutesPath.account.update}/${account.id}`);
                                                    }}
                                                    className="sprite-table-edit-btn  tooltip"
                                                    title="Modifier"
                                                    data-confirm_msg
                                                    data-removeable_element="parents::tr"
                                                    target="_blank"
                                                />
                                            )}
                                            {disabledData === 0 && acl.account.disable() && <a onClick={() => this.delete(account.id)} className="sprite-table-delete-btn  tooltip" title="Archiver" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />}
                                            {disabledData === 1 && acl.account.restore() && <a onClick={() => this.restore(account.id)} className="sprite-table-recover-btn restore_delivery_note tooltip" title="Restaurer" data-confirm_msg="" data-removeable_element="parents::tr"></a>} */}
                                        </Td>
                                    </Tr>
                                );
                            })}
                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                    <div style={{ display: "inline-block" }}>
                                        {tableEventsPageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ tableEventsPageNumber: tableEventsPageNumber - 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{tableEventsPageNumber}</span>
                                        {tableEventsPageNumber < tableEventsTotalItems / 20 && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ tableEventsPageNumber: tableEventsPageNumber + 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                        {/* end  fetching data */}
                    </table>
                </TableWrapper>
            </Bloc>
        );
    }
    getProps = () => {
        const { filterFields } = this.props;
        this.setState((prevState) => ({
            ...prevState,
            id_manager_table: filterFields.visitor.value || 0,
            date_start_table: filterFields.started_date,
            date_end_table: filterFields.end_date,
        }), this.preload)
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    setSearchField(fieldName, value) {
        console.log("date", value)
        this.setState((prevState) => ({
            ...prevState,
            searchFields: {
                ...prevState.searchFields,
                [fieldName]: value,
            },
        }));
    }
    setSearchFieldOperators(fieldName, value) {
        this.setState((prevState) => ({
            ...prevState,
            searchFieldsOperators: {
                ...prevState.searchFieldsOperators,
                [fieldName]: value,
            },
        }));
    }
    resetSearch() {
        this.setState(
            (prevState) => ({
                ...prevState,
                selectedAccount: null,

                searchFields: {
                    name: "",
                    date_start: null,
                    revisited_at: null,
                    doctor_present: "",
                    account: "",
                    comment: "",
                },
                searchFieldsOperators: {
                    name: "",
                    date_start: null,
                    revisited_at: null,
                    doctor_present: "",
                    account: "",
                    comment: "",
                },
            }),
            () => this.getAll()
        );
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    async getAll() {
        try {
            const { orderBy, key, id_manager_table, searchFields, searchFieldsOperators, search, advancedSearch, tableEventsPageNumber } = this.state;
            const { date_start_table, date_end_table } = this.state;

            let data = {};
            if (date_start_table && date_start_table.length !== 0 && date_end_table && date_end_table.length !== 0) {
                // because it's not doing in the back end 
                if (moment(moment(date_start_table).format("YYYY-MM-DD")).isSame(moment(date_end_table).format("YYYY-MM-DD"))) {
                    data["date_debut"] = {
                        value: moment(date_start_table).format("YYYY-MM-DD"),
                        op: "is_date",
                    };
                } else {
                    data["interval"] = {
                        col: "date_debut",
                        start: moment(date_start_table).format("YYYY-MM-DD"),
                        end: moment(date_end_table).format("YYYY-MM-DD"),
                    };
                }

            }
            data = {
                ...data,
                ...CalenderDAO.getSearchDataForCalender({ advancedSearch, search, ...searchFields, operators: searchFieldsOperators }),
            }
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            if (parseInt(id_manager_table) !== 0) {
                data["gestionnaire"] = {
                    value: String(id_manager_table),
                    op: "is",
                };
            }
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }
            const response = await CalenderDAO.getALLEvents(tableEventsPageNumber, {}, data);
            this.setState({ tableEvents: response.data.events, tableEventsTotalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
}

const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
    max-width:500px;
    
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;

const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc_2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    ${"" /* margin-bottom: 60px; */}
    margin-top: 10px;
`;
const PriorityContainer2 = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width: 50px;
    text-align:center;
`;
const Comment = styled.p`
    height: 17px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export default withRouter(EventsList);