import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import { errorMessage } from "../tmp/Messages";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import AccountDAO from "../DAO/AccountDAO";
import UserDAO from "../DAO/UserDAO";
import ResourceDAO from "../DAO/ResourceDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";
/**
 *
 * this component can used for persist and update  account
 * if id_account not 0 means update
 * else mean persist
 *
 */

class AddAccountsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relation_type: "Pharmacie",
            relation_type_error: false,
            account_type: "",
            account_type_error: false,
            error_resource: false,
            wording: "",
            error_wording: false,
            category: null,
            code: "",
            id_manager: "",
            error_manager: false,
            ready: false,
            account: null,
            users: [],

            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",

            resources: [],
            itemsNumber: 0,
            error_resource: false,
            id_resource: "",

            id_account: 0,
            selected_manager: {},

            loading: false,
            relation_type_loading: false,
        };
    }

    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        this.getUsers();
        const id = this.props.match.params.id;
        !id && this.getConnectedUserInfos();
        if (id) {
            this.setState({ id_account: id });
            this.get();
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await AccountDAO.getAccountById(id, {});
            const object = response.data;
            this.setState({
                account: object ? object : null,
                relation_type: object.type_relation ? object.type_relation : "",
                resource: object.nom_ressource ? object.nom_ressource : "",
                id_resource: object.id_ressource ? object.id_ressource : "",
                account_type: object.type_compte ? object.type_compte : "",
                manager: object.gestionnaire ? object.gestionnaire : "",
                id_manager: object.gestionnaire_id ? object.gestionnaire_id : "",
                code: object.code ? object.code : "",
                wording: object.libelle ? object.libelle : "",
                category: object.categorie ? object.categorie : "",
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequired() {
        const { relation_type, account_type, id_manager, code, category, wording, resource, id_resource } = this.state;
        if (relation_type && relation_type.length !== 0 && account_type && account_type.length !== 0 && wording && wording.length !== 0 && id_manager && id_manager.length !== 0 && id_resource && id_resource.length !== 0) {
            const data = {
                type_relation: relation_type,
                type_compte: account_type,
                id_ressource: String(id_resource),
                libelle: wording,
                utilisateur: id_manager,
                code: code,
                categorie: category,
            };
            return data;
        } else {
            if (!id_resource || (id_resource && id_resource.length === 0)) {
                this.setState({ error_resource: true });
            }
            if (!relation_type || (relation_type && relation_type.length === 0)) {
                this.setState({ relation_type_error: true });
            }
            if (!account_type || (account_type && account_type.length === 0)) {
                this.setState({ account_type_error: true });
            }
            if (!wording || (wording && wording.length === 0)) {
                this.setState({ error_wording: true });
            }
            if (!id_manager || (id_manager && id_manager.length === 0)) {
                this.setState({ error_manager: true });
            }
            return null;
        }
    }

    async add() {
        try {
            const data = this.verifyRequired();
            if (data) {
                this.setState({ loading: true });
                const response = await AccountDAO.addAccount({}, data);
                this.setState({ loading: false });

                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.add, type: "success" });
                    this.props.history.push(`${RoutesPath.account.details}/${response.data.compte.id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async update() {
        try {
            const data = this.verifyRequired();
            if (data) {
                const id = this.state.id_account;
                this.setState({ loading: true });
                const response = await AccountDAO.updateAccount(id, {}, data);
                this.setState({ loading: false });

                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.update, type: "success" });
                    this.props.history.push(`${RoutesPath.account.details}/${response.data.compte.id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ resource: value.nom, id_resource: value.id });
        this.closePopUp();
    }

    componentWillUnmount() {
        this.setState({ errorMessage: "errorMessage error message" }, () => "component will unmount");
    }

    setResource(value) {
        const { wording } = this.state;
        this.setState({ resource: `${value.nom} (${value.ville})`, id_resource: value.id, error_resource: false });
        const id = this.state.id_account;
        this.setState({ wording: `${value.nom} (${value.ville})`, error_wording: false });
    }

    resetResource() {
        this.setState({ resource: "", id_resource: "" });
    }

    async getResource(data, pageNumber = 1) {
        try {
            const { relation_type } = this.state;
            let resourceSearch = "";
            if (relation_type === "Pharmacie") {
                resourceSearch = "pharmacies";
            } else if (relation_type === "Etablissement de santé") {
                resourceSearch = "etablissements";
            } else if (relation_type === "Association") {
                resourceSearch = "associations";
            }
            this.setState({ relation_type_loading: true });
            const response = await ResourceDAO.getALLResource(resourceSearch, pageNumber, {}, data);
            this.setState({ relation_type_loading: false });

            this.setState({ resources: response.data.ressources, itemsNumber: response.data.total });
        } catch (error) {
            this.setState({ relation_type_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { relation_type_loading, loading, id_account, error_resource, itemsNumber, resources, errorMessage, relation_type, relation_type_error, account_type, account_type_error, id_manager, code, category, wording, error_wording, users, resource, error_manager } = this.state;
        const title = id_account ? `Modifier compte : ${wording}` : "Créer un nouveau compte";
        const subTitle = id_account ? `Modifier compte` : "Créer un compte";
        return (
            <Main_container className="Main_container">
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (id_account !== 0 ? this.update() : this.add())}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{subTitle}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <CustomSelect
                                    error={error_manager}
                                    onChange={(value) => {
                                        this.setState({ id_manager: value.target.value, error_manager: false });
                                    }}
                                    selectedValue={id_manager}
                                    label="Gestionnaire *"
                                >
                                    <option defaultValue></option>
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    name="account"
                                    label={`${relation_type}*`}
                                    setValue={this.setResource.bind(this)}
                                    reset={this.resetResource.bind(this)}
                                    value={resource}
                                    data={resources}
                                    propertyName={"nom"}
                                    searchData={this.getResource.bind(this)}
                                    itemsNumber={itemsNumber}
                                    getData={() => this.getResource()}
                                    error={error_resource}
                                    loading={relation_type_loading}
                                    modalTitle={`Choisissez une ressource`}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    error={account_type_error}
                                    onChange={(value) => {
                                        this.setState({ account_type: value.target.value, account_type_error: false });
                                    }}
                                    selectedValue={account_type}
                                    label="Type du compte *"
                                >
                                    <option defaultValue></option>
                                    <option value="Client">Client</option>
                                    <option value="Prospect">Prospect</option>
                                </CustomSelect>
                            </InputContainer>
                            {account_type === "Client" && (
                                <InputContainer>
                                    <CustomInput
                                        error={false}
                                        onChange={(value) => {
                                            this.setState({ code: value.target.value });
                                        }}
                                        defaultValue={code}
                                        id="code"
                                        label="Code client"
                                    ></CustomInput>
                                </InputContainer>
                            )}
                        </Left>
                        <Right>
                            <InputContainer>
                                <CustomSelect
                                    error={relation_type_error}
                                    onChange={(value) => {
                                        this.setState({ relation_type: value.target.value, relation_type_error: false });
                                        this.resetResource();
                                    }}
                                    selectedValue={relation_type}
                                    label="Type de relation *"
                                >
                                    <option key="defaultValue" defaultValue></option>
                                    <option key="Pharmacie" value="Pharmacie">
                                        Pharmacie
                                    </option>
                                    <option key="Etablissement" value="Etablissement de santé">
                                        Etablissement de santé
                                    </option>
                                    <option key="Association" value="Association">
                                        Association
                                    </option>
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={error_wording}
                                    onChange={(value) => {
                                        this.setState({ wording: value.target.value, error_wording: false });
                                    }}
                                    defaultValue={wording}
                                    value={wording}
                                    id="name"
                                    label="Nom du compte *"
                                ></CustomInput>
                            </InputContainer>

                            <InputContainer>
                                <CustomSelect
                                    operatorsType="category"
                                    onChange={(value) => {
                                        this.setState({ category: value.target.value });
                                    }}
                                    selectedValue={category}
                                    label="Catégorie du compte"
                                />
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;

const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddAccountsPage);
