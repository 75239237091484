import React from "react";
import styled from "styled-components";

export const TablePagination = (props) => {
    const { colSpan, pageNumber, totalItems, onNextBtnClick, onPrevBtnClick } = props;
    return (
        <tbody>
            <Tr>
                <Td colSpan={colSpan} className="table_pagination">
                    <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                    <div style={{ display: "inline-block" }}>
                        {pageNumber > 1 && <a className="sprite-table-prev-btn" onClick={onPrevBtnClick} />}
                        <span className="table_page">{pageNumber}</span>
                        {pageNumber < totalItems / 20 && <a className="sprite-table-next-btn" onClick={onNextBtnClick} />}
                    </div>
                </Td>
            </Tr>
        </tbody>
    );
};

const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
