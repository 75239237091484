import React, { Component } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { RoutesPath } from "../../values/RoutesPath";
import SearchSelect from "../../helpers/SearchSelect";

import { Link } from "react-router-dom";
import ContactDAO from "../../DAO/ContactDAO";
import { notify } from "../../helpers/FlashMessage";

class ContactTablePopup extends Component {
    constructor(props) {
        super(props);
        this.inputTimer = undefined;
        this.state = {
            openPopUp: false,
            accounts: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            resourceSearch: "",
            selected_city: {},
            createContact: false,
            data: [],
            isLoaded: false,
        };
    }
    componentDidMount() {
        this.getAll();
    }
    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.props.setValue(value);
        this.closePopUp();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading !== this.props.loading) {
        }
        if (this.props.name && this.props.name.toLowerCase() === "contact") {
            if (prevProps.createContact !== this.props.createContact) {
                this.setState({ createContact: this.props.createContact });
            }
        }
    }
    getAll = async () => {
        try {
            const { searchedText } = this.state;
            let data = {};
            if (searchedText && searchedText.length != 0) {
                data = {
                    multicols: {
                        cols: "nom,prenom,compte,ville,portable",
                        value: searchedText,
                        op: "smart",
                    },
                };
            }
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            this.setState({ isLoaded: false });
            const response = await ContactDAO.getALLContacts(this.state.pageNumber, {}, data);
            this.setState({ isLoaded: true });
            this.setState({ data: response.data.contacts, totalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };

    render() {
        const { pageNumber, searchedText, openPopUp, data, totalItems, isLoaded } = this.state;
        const { propertyName, reset, error, modalTitle, name, loading } = this.props;

        return (
            <Container>
                <InputContainer>
                    <Input borderColor={error ? "#FF068B" : "#bbc8d3"} type={this.props.type} name="input" defaultValue={this.props.defaultValue} value={this.props.value} autoFocus={this.props.autoFocus} className={"input-login"} onChange={(text) => { }} id={this.props.id} required />
                    <Label color={error ? "#FF068B" : "#284358"} id="input-label" htmlFor={this.props.id} className="label-login">
                        {this.props.label}
                    </Label>
                    <Button onClick={() => this.setState({ openPopUp: true }, this.props.getData)}></Button>
                    <Reset_btn onClick={reset}>
                        <svg width={15} height={15} aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill={error ? "#FF068B" : "#284358"}
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                            ></path>
                        </svg>
                    </Reset_btn>
                    <Search_btn>
                        <svg width={15} height={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill={error ? "#FF068B" : "#284358"}
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </svg>
                    </Search_btn>

                    {openPopUp && (
                        <PopUp>
                            <PopUp_body>
                                <PopUp_header>
                                    <PopUp_title>{modalTitle ? modalTitle : "Fenêtre Pop-up"}</PopUp_title>
                                    <Div onClick={() => this.closePopUp()}>
                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                            <path
                                                fill="currentColor"
                                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                            ></path>
                                        </Svg>
                                    </Div>
                                </PopUp_header>
                                <PopUp_top_options>
                                    <SearchContainer>
                                        <Container>
                                            <Input
                                                type="text"
                                                name="input"
                                                autoFocus={this.props.autoFocus}
                                                className={"input-login"}
                                                value={searchedText}
                                                onChange={(text) => {
                                                    this.setState({ searchedText: text.target.value }, () => {
                                                        if (this.inputTimer !== undefined) {
                                                            this.inputTimer = clearTimeout(this.inputTimer);
                                                        }
                                                        this.inputTimer = setTimeout(() => this.setState({ pageNumber: 1 }, this.getAll), 1000);
                                                    });
                                                }}
                                                id="search"
                                                required
                                            />
                                            <Label id="input-label" htmlFor="search" className="label-login">
                                                Rechercher
                                            </Label>
                                            <Search_icon id="input-label" htmlFor="search" className="label-login">
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Svg>
                                            </Search_icon>
                                        </Container>
                                    </SearchContainer>
                                </PopUp_top_options>
                                <List>
                                    <table className="table processableTable">
                                        <thead>
                                            <Th></Th>
                                            <Th>Nom</Th>
                                            <Th>Prénom</Th>
                                            <Th>Compte</Th>
                                            <Th>Ville</Th>
                                            <Th>Téléphone</Th>
                                        </thead>
                                        {!isLoaded && <Loading />}
                                        {isLoaded && data && data.length !== 0 && (
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <Tr
                                                        onClick={() => {
                                                            this.setValue(item);
                                                        }}
                                                        key={index}
                                                    >
                                                        <Td>
                                                            <RadioBtn className="checked"></RadioBtn>
                                                        </Td>
                                                        <Td>{item.nom}</Td>
                                                        <Td>{item.prenom}</Td>
                                                        <Td>{item.compte}</Td>
                                                        <Td>{item.ville}</Td>
                                                        <Td>{item.portable}</Td>
                                                    </Tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </table>
                                </List>
                                <Last_li>
                                    <Cancel onClick={() => this.closePopUp()}>Annuler</Cancel>
                                    <Pages>
                                        {pageNumber > 1 && (
                                            <Prev_page
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, this.getAll);
                                                }}
                                            >
                                                <Next_page_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path
                                                        fill="#f0f0f0"
                                                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                                    ></path>
                                                </Next_page_icon>
                                            </Prev_page>
                                        )}
                                        <Page_number>{pageNumber}</Page_number>
                                        {totalItems / 20 > pageNumber && (
                                            <Next_page
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, this.getAll);
                                                }}
                                            >
                                                <Next_page_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path
                                                        fill="#f0f0f0"
                                                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                                    ></path>
                                                </Next_page_icon>
                                            </Next_page>
                                        )}
                                    </Pages>
                                </Last_li>
                            </PopUp_body>
                        </PopUp>
                    )}

                    {error && (
                        <ErrorContainer>
                            <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="#FF068B"
                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                ></path>
                            </svg>
                            <ErrorText>Ce champ est obligatoire</ErrorText>
                        </ErrorContainer>
                    )}
                </InputContainer>
            </Container>
        );
    }

    getModal() {
        const { createContact } = this.state;
        return (
            <PopUp_body>
                <PopUp_header>
                    <PopUp_title>{"Fenêtre Pop-up"}</PopUp_title>
                    <Div onClick={() => this.closePopUp()}>
                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                            <path
                                fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                            ></path>
                        </Svg>
                    </Div>
                </PopUp_header>
            </PopUp_body>
        );
    }
}

const Loading = () => (
    <tbody>
        {[1, 2, 3].map((index) => {
            return (
                <Tr key={"key__" + index}>
                    <Td></Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                </Tr>
            );
        })}
    </tbody>
);

const ErrorText = styled.div`
    display: inline-block;
    color: #ff068b;
    font-size: 10px;
    vertical-align: top;
`;
const ErrorContainer = styled.div``;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const Reset_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 27px;
    height: 20px;
    width: 20px;
`;

const Search_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 7px;
    height: 20px;
    width: 20px;
`;

const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.4);
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    ${"" /* display:none; */}
    padding-top: 10px;
`;
const PopUp_body = styled.div`
    background: #fff;
    width: 46%;
    height: 525px;
    margin: auto;
    border-radius: 2px;
    position: relative;
`;
const PopUp_header = styled.div`
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    padding-top: 6px;
`;
const PopUp_title = styled.div`
    display: inline-block;
    vertical-align: top;
    float: left;
    font-size: 13px;
    font-weight: bold;
`;
const Svg = styled.svg`
    cursor: pointer;
`;
const Div = styled.div`
    display: inline-block;
    vertical-align: top;
    float: right;
    width: 12px;
    cursor: pointer;
`;
const PopUp_top_options = styled.div`
    height: 69px;
    width: 100%;
    padding-top: 13px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    & > :first-child {
        margin-right: 12px;
    }
`;

const SearchContainer = styled.div`
    height: 36px;
    width: 43%;
`;
const Search_icon = styled.label`
    position: absolute;
    right: 3%;
    top: 30%;
    font-family: Roboto;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: #284358;
`;
const List = styled.div`
    width: 98%;
    height: 424px;
    margin: auto;
    overflow-y: scroll;
    padding-bottom: 48px;
`;
const List_title = styled.div`
    width: 100%;
    height: 42px;
    font-weight: 700;
    background: #f8f8f8;
    font-size: 14px;
    padding-top: 12px;
    padding-left: 71px;
`;
const UlContainer = styled.div`
    height: 340px;
    overflow-x: hidden;
    overflow-x: auto;
    background-color: "red";
`;
const Ul = styled.ul``;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    height: 42px;
    ${"" /* font-size:13px; */}
    font-family: 'Open Sans', sans-serif;
    color: #494949;
    font-size: 12px;
    font-weight: 700;
    padding-top: 8px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        color: #fff;
        > span {
            background-color: #00beb0;
            border: 2px solid #fff;
        }
    }
`;

const RadioBtn = styled.span`
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #fff;
    border: 2px solid #00beb0;
    vertical-align: middle;
    -moz-border-radius: 13px;
    -webkit-border-radius: 13px;
    border-radius: 13px;
    margin-right: 42px;
    margin-left: 5px;
`;
const Last_li = styled.li`
    border-top: 1px solid #8d8d8d;
    height: 42px;
    width: 100%;
    padding-top: 8px;
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding-left: 7px;
    padding-right: 7px;
`;
const Cancel = styled.div`
    display: inline-block;
    vertical-align: top;
    float: left;
    padding: 0 22px;
    height: 25px;
    color: #fff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #ffa902;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #ffa902;
`;
const Pages = styled.div`
    display: inline-block;
    vertical-align: top;
    float: right;
    height: 25px;
    color: #fff;
`;
const Page_number = styled.span`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    line-height: 24px;
    border: 1px solid #f0f0f0;
    display: inline-block;
    padding: 0 10px;
    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
`;
const Next_page = styled.span`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    margin-left: 5px;
    padding-top: 4px;
    padding-left: 8px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Prev_page = styled.span`
    display: inline-block;
    vertical-align: top;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #f0f0f0;
    margin-right: 5px;
    padding-top: 4px;
    padding-left: 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;

const Next_page_icon = styled.svg`
    width: 9px;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 30%;
    ${"" /* font-family: Roboto;
    font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;
    color: #494949;
    font-size: 12px;
    font-weight: 600;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: ${(props) => props.color};
`;
const Input = styled.input`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    ${"" /* font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;
    color: #494949;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px ${(props) => props.borderColor};
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    ${"" /* &:focus ~ ${PopUp}{
        display:block;
    }; */}
    &:focus ~ ${Label} , &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Button = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: transparent;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
export default ContactTablePopup;
