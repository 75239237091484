import React, { Component } from "react";
import styled from "styled-components";

import AclDAO from "../DAO/AclDAO";
import TableModel from "../components/TableModel";
import { RoutesPath } from "../values/RoutesPath";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { acl } from "../services/acl";
import ProductDAO from "../DAO/ProductDAO";
import { notify } from "../helpers/FlashMessage";

const thead = [{ property: "nom", label: "Gamme produit", type: "string" }];

class ACLRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
        };
    }
    componentDidMount() {
        ("acl.setting.acl_roles.add");
        acl.setting.acl_roles.update();
        const id = this.props.match.params.id;
        if (acl.setting.product.range.update()) {
            this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Modifier gammes de produits actives", onClick: () => this.props.history.push(`${RoutesPath.setting.product.range.update}/${id}`) }] });
        } else {
            this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [] });
        }

        this.getAll();
    }
    async getAll() {
        try {
            const { pageNumber } = this.state;
            const params = {};
            const data = {};
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await ProductDAO.getActiveRanges(pageNumber, params, data);
            this.setState({ is_loaded: true, data: [...this.state.data, ...response.data.ressources] }, () => {
                if (response.data.total / 20 > pageNumber) {
                    this.setState({ pageNumber: pageNumber + 1 }, () => this.getAll());
                }
            });
        } catch (error) {
            this.setState({ s_loaded: true });
            // notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { is_loaded, data } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Paramètres de produits > Gammes de produits actives</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page vous affiche la liste de toutes les gammes de produits.</Block_content_title>
                    </Block_content>
                </First_block>

                <TableModel
                    title="Gammes de produits actives"
                    onReload={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => {},
                        actions: [],
                    }}
                    {...this.props}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(ACLRoles);
// export default connect(mapStateToProps)(ACLRoles);

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
