import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import ReportDAO from "../../DAO/ReportDAO";
import { connect } from "react-redux";
import moment from "moment";
import CommonFunctions from "../../helpers/CommonFunctions";
import UserDAO from "../../DAO/UserDAO";
import { DatePicker } from "../../components/CustomDatePicker";
import { notify } from "../../helpers/FlashMessage";
import EventsList from "./EventsList";
import SearchSelect from "../../helpers/SearchSelect";
import { FormContainer, Left, Right, InputContainer, FullRow } from "../../helpers/formStyledComp";

class Visits extends Component {
    constructor(props) {
        super(props);
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.state = {
            filter_fields: {
                started_date: lastMonth,
                end_date: new Date(),
                visitor: { value: 0, label: "Tout" },
            },
            statistical_fields: {
                show_statistical: false,
                planned: 0,
                reported: 0,
                visited: 0,
            },
            data: {
                visitors: [],
            },
            preload: {
                users: [],
            },
            accounts: [],
        };
    }
    async componentDidMount() {
        document.title = "Rapports - Sobrus Labs";
        this.getVisitsByCannel();
    }

    render() {
        const { filter_fields, statistical_fields, data } = this.state;
        return (
            <Main_container className="Main_container">
                <TopOptions title="Rapport des événements"></TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Options des rapports</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <SearchSelect
                                name="report"
                                fetchedUrl="/api/utilisateurs"
                                HTTPMethod="post"
                                receivedObject="utilisateurs"
                                optionLabelProperty="fullname"
                                optionValueProperty="id"
                                value={filter_fields.visitor}
                                placeholder="Sélectionner un visiteur"
                                onChange={(e) => {
                                    if (e && e.value.length !== 0) {
                                        this.onSearchChange(e);
                                    }
                                }}
                            />
                        </Row>

                        <FormContainer>

                            <Left>
                                <InputContainer>
                                    <DatePicker
                                        selected={filter_fields.started_date}
                                        onChange={(date) => {
                                            this.setFieldValue("started_date", date, this.getVisitsByCannel.bind(this));
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        showTimeSelect={false}
                                        label="Date de début*"
                                        id="start_date_id"
                                    />
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <DatePicker
                                        selected={filter_fields.end_date}
                                        onChange={(date) => {
                                            this.setFieldValue("end_date", date, this.getVisitsByCannel.bind(this));
                                        }}
                                        dateFormat="yyyy-MM-dd"
                                        showTimeSelect={false}
                                        label="Date de fin*"
                                        id="end_date_id"
                                    />
                                </InputContainer>
                            </Right>
                        </FormContainer>
                    </Form_container>
                </Bloc>

                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Événements par visiteur</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    <Th>Visiteur</Th>
                                    <Th>Planifié</Th>
                                    <Th>Réalisé</Th>
                                    <Th>Reporté</Th>
                                    <Th>Total</Th>
                                    <Th>Total des comptes visités</Th>
                                    <Th>Moyenne des événements par compte</Th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.visitors &&
                                    data.visitors.length !== 0 &&
                                    data.visitors.map((object, index) => {
                                        const row_total = this.getRowTotal(object["Planifié"], object["Visité"], object["Reporté"]);
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td>{`${object["fullname"]}`}</Td>
                                                <Td>{`${object["Planifié"] ? object["Planifié"] : 0} (${object["Planifié"] ? this.percentage(parseInt(object["Planifié"]), parseInt(row_total)) : 0}%)`}</Td>
                                                <Td>{`${object["Visité"] ? object["Visité"] : 0} (${object["Visité"] ? this.percentage(parseInt(object["Visité"]), parseInt(row_total)) : 0}%)`}</Td>
                                                <Td>{`${object["Reporté"] ? object["Reporté"] : 0} (${object["Reporté"] ? this.percentage(parseInt(object["Reporté"]), parseInt(row_total)) : 0}%)`}</Td>
                                                <Td>{`${row_total} (${row_total ? this.percentage(parseInt(row_total), parseInt(row_total)) : 0}%)`}</Td>
                                                <Td>{object["total_compte_visite"]}</Td>
                                                <Td>{parseFloat(object["total_compte_visite"]) > 0 ? parseFloat(row_total / object["total_compte_visite"]).toFixed(2) : 0}</Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td>{"Total"}</Td>
                                    <Td>{statistical_fields.planned}</Td>
                                    <Td>{statistical_fields.visited}</Td>
                                    <Td>{statistical_fields.reported}</Td>
                                    <Td>{statistical_fields.planned + statistical_fields.visited + statistical_fields.reported}</Td>
                                    <Td>{data.visitors && data.visitors.length !== 0 && data.visitors.reduce((total, item) => total + parseInt(item["total_compte_visite"]), 0)}</Td>
                                    <Td>{this.AverageEventsPerAccount(statistical_fields, data)}</Td>
                                </Tr>
                            </tbody>
                        </table>
                    </div>
                </Bloc>
                <EventsList filterFields={filter_fields} />
            </Main_container>
        );
    }
    AverageEventsPerAccount = (statistical_fields, data) => {
        let totalAccountVisit = 0;
        let totalStatisticalFields = statistical_fields.planned + statistical_fields.visited + statistical_fields.reported;
        if (data.visitors && data.visitors.length !== 0) {
            totalAccountVisit = data.visitors.reduce((total, item) => total + parseInt(item["total_compte_visite"]), 0)
        }
        if (totalAccountVisit > 0 && !isNaN(totalStatisticalFields)) {
            return parseFloat(totalStatisticalFields / totalAccountVisit).toFixed(2)
        } else {
            return 0;
        }
    }
    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    filter_fields: {
                        ...prevState.filter_fields,
                        visitor: selectedOption,
                    },
                }),
                this.getVisitsByCannel.bind(this)
            );
        }
    };
    percentage = (partialValue, totalValue) => {
        return parseFloat((100 * partialValue) / totalValue).toFixed(2);
    }
    getRowTotal = (num_1, num_2, num_3) => {
        let tmp_num_1 = 0;
        let tmp_num_2 = 0;
        let tmp_num_3 = 0;

        if (num_1) {
            tmp_num_1 = parseInt(num_1);
        }
        if (num_2) {
            tmp_num_2 = parseInt(num_2);
        }
        if (num_3) {
            tmp_num_3 = parseInt(num_3);
        }
        return tmp_num_1 + tmp_num_2 + tmp_num_3;
    };

    getRowTotalPercent = (value, base) => {
        return base ? Number((value / base) * 100).toFixed(3) : 0;
    };

    formattedDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    setFieldValue = (fieldName, fieldValue, callBack = () => { }) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                filter_fields: {
                    ...prevState.filter_fields,
                    [fieldName]: fieldValue,
                },
            }),
            () => {
                if (this.state.filter_fields[fieldName] !== null) callBack();
            }
        );
    };

    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    filter_fields: {
                        ...prevState.filter_fields,
                        visitor: selectedOption,
                    },
                }),
                this.getVisitsByCannel.bind(this)
            );
        }
    };

    getVisitsByCannel = async () => {

        try {
            const { filter_fields } = this.state;
            if (filter_fields.started_date && filter_fields.end_date) {
                const response = await ReportDAO.getVisitsByStatus(this.formattedDate(filter_fields.started_date), this.formattedDate(filter_fields.end_date), filter_fields.visitor.value === 0 ? "" : filter_fields.visitor.value);
                console.log("getVisitsByCannel ", response)
                // const response = await ReportDAO.getVisitsByCannel("2019-01-01", "2020-01-01");
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            visitors: response.data,
                        },
                    }),
                    () => {
                        let planned = 0;
                        let reported = 0;
                        let visited = 0;
                        this.state.data.visitors.map((object) => {
                            if (object["Planifié"]) planned = planned + parseInt(object["Planifié"]);
                            if (object["Reporté"]) reported = reported + parseInt(object["Reporté"]);
                            if (object["Visité"]) visited = visited + parseInt(object["Visité"]);
                        });
                        if (planned !== 0 || reported !== 0 || visited) {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        planned,
                                        reported,
                                        visited,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        } else {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        planned,
                                        reported,
                                        visited,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        }
                    }
                );
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
}

const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 18px;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    background: #fff;
`;
const Th = styled.th`
    padding-right: 10px;
`;

const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
// const FullRow = styled.div`
//     width: 99%;
//     height: 41px;
//     display: inline-block;
//     vertical-align: top;
// `;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(Visits);
