import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../../helpers/FlashMessages";
import { connect } from "react-redux";
import ResourceDAO from "../../DAO/ResourceDAO";
import { notify } from "../../helpers/FlashMessage";
import { RoutesPath } from "../../values/RoutesPath";
import { acl } from "../../services/acl";
import "./DemandDetails.css";
import { BiTime } from "react-icons/bi";
import DemandDAO from "../../DAO/DemandDAO";
import ContentLoader from "react-content-loader";
import { MessagesText } from "../../values/MessagesText";
import { CustomStyleSearchSelect } from "../../helpers/SearchSelect";
import UserDAO from "../../DAO/UserDAO";
import { Link } from "react-router-dom";
import { MentionsInput, Mention } from 'react-mentions'
import { BiUser } from 'react-icons/bi';
import { HiOutlinePlus } from "react-icons/hi"
import { FaBell } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import Hello from "./Hello";
import { AlphabetsColor } from "../../helpers/AlphabetsColor";
import CustomSelectSmall from "../../components/CustomSelectSmall";
import EmailDAO from "../../DAO/EmailDAO";

const STATUS_COLOR = {
    Ouvert: "#05b8c0",
    Résolu: "#00bf9c",
    Archivé: "#E04052",
    Nouveau: "#f6ac32",
};

class DemandDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            isLoaded: false,
            assignedTo: false,
            selectedManager: null,
            commentContent: "",
            currentUser: null,
            commentDisabled: false,
            subscriptions: [],
            mentions: [],
            emailModels: [],
            selectedEmailModel: undefined,
            emailContent: "",
        };
    }
    componentDidMount() {
        document.title = "Demandes - Sobrus Labs";
        this.get();
        this.getConnectedUserInfos();
        this.getEmailModels();
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({
                currentUser: response.data,
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    get = async (loading = true) => {
        try {
            const id = this.props.match.params.id;
            const params = {};
            const { connectedUserInfos } = this.props;

            //not loading when we write comments
            loading && this.setState({ isLoaded: false });
            const response = await DemandDAO.getDemandById(id, params);
            console.log("response demande details", response)
            loading && this.setState({ isLoaded: true });
            const data = response.data && response.data.response && response.data.response
            if (data) {
                if (data.lastStatus && data.lastStatus.status && data.lastStatus.status.toLowerCase() === "Nouveau".toLocaleLowerCase()) {
                    // this.assignedTo(connectedUserInfos.id)
                    this.get()
                }
                this.setState({
                    data: response.data.response,
                    subscriptions: response.data.response.subscriptions ? response.data.response.subscriptions.filter((sub, index) => sub.status === true) : []
                });
            }

        } catch (error) {
            notify({ msg: error.message, type: "danger" });
            if (error.message === "Vous n'avez pas la permission d'accéder à cette page") {
                this.props.history.goBack();
            }
        }
    };
    // for formatted tags
    formatComment = (comment) => {
        const parser = (comment) => {
            return [...comment.matchAll(/@\[(.*?)]\((user):(\d+)\)/g)]
        }
        let formatedString = comment
        parser(comment).forEach((element, index) => {
            formatedString = formatedString.replace(element[0], `<span class="tag-in-comment">${"@" + element[1]}</span>`)
        })
        return formatedString
    }
    disable = async () => {
        try {
            var answer = window.confirm("Êtes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (answer) {
                const id = this.props.match.params.id;
                const params = {};
                const data = {};
                const response = await DemandDAO.deleteDemand(id, params, data);
                if (response.data.status === "ok") {
                    notify({ msg: MessagesText.demand.disable, type: "success" });
                    this.get();
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    resolve = async () => {
        try {
            const id = this.props.match.params.id;
            const params = {};
            const data = {};
            const response = await DemandDAO.resolveDemand(id, params, data);
            if (response.data.status === "ok") {
                notify({ msg: MessagesText.demand.resolve, type: "success" });
                this.get();
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    assignedTo = async (assignedTo, refresh) => {
        try {
            const { selectedManager } = this.state;
            const id = this.props.match.params.id;
            const params = {};
            const data = {
                assignedTo: assignedTo,
            };
            const response = await DemandDAO.assignedDemand(id, params, data);
            if (response.data.status === "ok") {
                notify({ msg: MessagesText.demand.assigned, type: "success" });
                this.get();
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    comment = async () => {
        try {
            const { commentContent, subscriptions } = this.state;
            if (commentContent && commentContent.length !== 0) {

                const id = this.props.match.params.id;
                const params = {};
                const data = {
                    content: commentContent,
                };
                this.setState({ commentDisabled: true })
                const response = await DemandDAO.addComment(id, params, data);
                this.setState({ commentDisabled: false })


                if (response.data.status === "ok") {
                    // add tagged user un subscription
                    const notifiedArray = this.getNotified();
                    const newSubscriptions = notifiedArray.slice(subscriptions.length, notifiedArray.length)
                    console.log("new subscriptions", newSubscriptions);
                    if (newSubscriptions.length !== 0 && acl.demand.subscribe()) {
                        this.subscribeInCommand(newSubscriptions.map((element) => element.userId))
                    }
                    this.setState({ commentContent: "" }, () => this.get(false));
                }
            }
        } catch (error) {
            this.setState({ commentDisabled: false })
            notify({ msg: error.message, type: "danger" });
        }
    };
    getFileName(fakeName) {
        if (fakeName && typeof fakeName === "string") {
            const firstPart = fakeName.slice(0, fakeName.lastIndexOf("-"));
            const lastPart = fakeName.slice(fakeName.lastIndexOf("."), fakeName.length);
            return firstPart + lastPart;
        }
        return "";
    }

    subscribeInCommand = async (array) => {
        try {
            const id = this.props.match.params.id;
            const params = {};
            const data = {
                userIds: array
            };
            const response = await DemandDAO.addSubscriber(id, params, data)
            console.log("new sub", response)
            console.log(response)
            if (response && response.data && response.data.status && response.data.status === "ok") {
                // notify({ msg: MessagesText.demand.addSubscription, type: "success" })
                this.get(false);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }
    unsubscribeInCommand = async () => {
        try {

            const id = this.props.match.params.id;
            const { connectedUserInfos } = this.props;
            const params = {};
            const data = {
                userId: connectedUserInfos.id
            };
            const response = await DemandDAO.removeSubscriber(id, params, data)
            console.log("response", response)
            if (response && response.data && response.data.status && response.data.status === "ok") {
                console.log("response", response)
                // notify({ msg: MessagesText.demand.removeSubscription, type: "success" })
                this.get(false);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" })
        }
    }
    isSubscribed = () => {
        const { subscriptions } = this.state;
        const { connectedUserInfos } = this.props;
        return subscriptions.some((subscriber, index) => subscriber.userId === connectedUserInfos.id)
    }

    getNotified() {
        const { subscriptions, mentions } = this.state;
        const allItemsArray = [...subscriptions, ...mentions.map((element) => ({ userId: parseInt(element.id), userName: element.display }))],
            keys = ['userId'],
            filtered = allItemsArray.filter(
                (s => o =>
                    (k => !s.has(k) && s.add(k))
                        (keys.map(k => o[k]).join('|'))
                )
                    (new Set)
            );

        return filtered;
    }
    getEmailModels = async () => {
        try {
            const params = {};
            const response = await EmailDAO.getALLEmailModels(params);
            console.log("response", response)
            this.setState({ emailModels: response.data.response["responses-models"] });
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }
    sendedData = () => {
        const id = this.props.match.params.id;
        const { emailContent, selectedEmailModel, emailModels } = this.state;
        let data = {
            "demande": id,
        }
        if (emailContent.length !== 0) {
            data["content"] = emailContent
            return data;
        }
        if (selectedEmailModel && selectedEmailModel.length !== 0) {
            console.log("selectedEmailModel", selectedEmailModel)

            data["content"] = emailModels.filter((item) => parseInt(item.id) === parseInt(selectedEmailModel))[0].content.replaceAll("<br>", "\n")
            return data;
        }
        return null;

    }
    sendEmail = async () => {
        try {
            const { emailContent, selectedEmailModel, emailModels } = this.state;
            const data = this.sendedData();
            console.log("sended data", data)
            const params = {};
            this.setState({ emailDisabled: true })
            const response = await EmailDAO.sendEmail(params, data);
            this.setState({ emailDisabled: false })
            if (response.data.status === "ok") {
                this.setState({ emailContent: "", selectedEmailModel: undefined }, () => this.get(false));
            }
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }
    render() {
        this.getFileName("background-test-5fd9dade9b567497508687.jpeg");
        const id = this.props.match.params.id;
        const { data, isLoaded, assignedTo, successMessage, currentUser, commentDisabled, subscriptions, emailModels, emailDisabled } = this.state;
        const title = data ? `Demande : ${data.demandNumber}` : "";
        const { connectedUserInfos } = this.props;
        return (
            <Main_container className="Main_container">

                <ReactTooltip html={true} id='path' delayShow={500} />
                <FlashMessages successMessage={successMessage} errorMessage="" />
                {currentUser && (currentUser.is_admin || (data && parseInt(currentUser.id) === parseInt(data.assignedTo))) ? (
                    <TopOptions title={title}>
                        {assignedTo ? (
                            <InputContainer>
                                <CustomStyleSearchSelect
                                    fetchedUrl="/api/utilisateurs"
                                    HTTPMethod="post"
                                    receivedObject="utilisateurs"
                                    optionLabelProperty="fullname"
                                    optionValueProperty="id"
                                    value={this.state.selectedManager}
                                    placeholder="Assigné à"
                                    onChange={(e) => {
                                        this.setState({ assignedTo: false, selectedManager: e }, () => this.assignedTo(this.state.selectedManager.value));
                                    }}
                                />
                            </InputContainer>
                        ) : (
                                data &&
                                data.lastStatus &&
                                data.lastStatus.status &&
                                data.lastStatus.status === "Ouvert" &&
                                acl.demand.assigned() && (
                                    <button className="btn_white" data-shortcut_alias="create" onClick={() => this.setState({ assignedTo: true })}>
                                        Assigner
                                    </button>
                                )
                            )}
                        {data && data.lastStatus && data.lastStatus.status && data.lastStatus.status === "Ouvert" && acl.demand.disable() && (
                            <button className="btn_red" data-shortcut_alias="create" onClick={this.disable}>
                                Archiver
                            </button>
                        )}

                        {data && data.lastStatus && data.lastStatus.status && data.lastStatus.status === "Ouvert" && acl.demand.resolve() && (
                            <button className="btn_bleu" data-shortcut_alias="create" onClick={this.resolve}>
                                Résolu
                            </button>
                        )}
                        {data && data.lastStatus && data.lastStatus.status && data.lastStatus.status === "Ouvert" && acl.demand.update() && (
                            <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.demand.update}/${id}`)}>
                                Modifier
                            </button>
                        )}
                    </TopOptions>
                ) : (
                        <TopOptions title={title}></TopOptions>
                    )}
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gestionnaire</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.createdByName}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Assigné à</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.assignedToName}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Date</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.createdAt && moment(data.createdAt).format("YYYY-MM-DD")}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Compte</Field_label>
                                            {isLoaded ? acl.account.details() ? (
                                                <Link to={`${RoutesPath.account.details}/${data && data.accountId}`}>
                                                    <Field_value isLink={true}>{data && data.accountName}</Field_value>
                                                </Link>
                                            ) : <Field_value>{data && data.accountName}</Field_value> : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Incident</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.incident}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contact</Field_label>
                                            {isLoaded ? acl.contact.details() ? (
                                                <Link to={`${RoutesPath.contact.details}/${data && data.contactId}`}>
                                                    <Field_value isLink={true}>{data && data.contactName}</Field_value>
                                                </Link>
                                            ) : <Field_value>{data && data.contactName}</Field_value> : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Canal</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.channel}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Objet</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.subject}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Priorité</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.priority}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        {data && data.senderEmail ? <Col_2>

                                            <Field_label>E-mail</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.senderEmail || ""}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2> : <Col_2><Field_label>Fichier</Field_label>
                                                {isLoaded ? (
                                                    <a target="_blank" href={data && `https://api.requests.labsv3.sobrus.ovh/uploaded_files/demands/${data.fileName}`}>
                                                        <Field_value isLink={true}>{data && this.getFileName(data.fileName)}</Field_value>
                                                    </a>
                                                ) : (
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    )}</Col_2>}
                                    </Row>
                                    {data && data.senderEmail && <Row>
                                        <Col_1>
                                            <Field_label>Fichier</Field_label>
                                            {isLoaded ? (
                                                <a target="_blank" href={data && `https://api.requests.labsv3.sobrus.ovh/uploaded_files/demands/${data.fileName}`}>
                                                    <Field_value isLink={true}>{data && this.getFileName(data.fileName)}</Field_value>
                                                </a>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2 bg={"#fff"}>


                                        </Col_2>
                                    </Row>}
                                </Fields_container>
                                <Title_container>
                                    <Title>Détails</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <FullRow>
                                            <Field_label>Description</Field_label>
                                            {isLoaded ? (
                                                <Field_value>{data && data.description}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </FullRow>
                                    </Row>
                                    {/* <Row>
                                        <FullRow_2>

                                        </FullRow_2>
                                    </Row> */}
                                </Fields_container>
                            </Block_content>
                        </Block>
                        {acl.demand.sendEmail() && <Block_2>
                            <Block_content_2>
                                <table>
                                    <tbody>
                                        {data &&
                                            data.responses &&
                                            data.responses.map((item) => (
                                                <tr style={{ backgroundColor: "#f6f6f6" }} className="DemandDetails__comment-container">
                                                    <td style={{ backgroundColor: "#f6f6f6" }} className="DemandDetails__comment-col-1">
                                                        <div>
                                                            <div style={{ backgroundColor: "rgba(0,0,0,0.2)" }} className="comment-avatar-container">
                                                                {/* <img src={require("../../images/commentAvatar.png")} /> */}
                                                                <span className="comment-avatar-text">{item.createdByName.slice(0, 2).toLocaleUpperCase()}</span>
                                                            </div>
                                                            <strong style={{ color: "#494949" }}>{item.createdByName}</strong>
                                                        </div>
                                                        <div>
                                                            <BiTime color="#494949" size={16} />
                                                            <span style={{ fontSize: 11, fontWeight: "600", color: "#494949" }}>{item.createdAt && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                                                        </div>
                                                    </td>
                                                    <td className="DemandDetails__comment-col-2">
                                                        <p dangerouslySetInnerHTML={{ __html: item.content }} />
                                                    </td>
                                                </tr>
                                            ))}
                                        {data && data.lastStatus && data.lastStatus.status && data.lastStatus.status === "Ouvert" && <tr style={{ backgroundColor: "#f6f6f6" }} id="DemandDetails__comment-container" className="DemandDetails__comment-container last-comment-container">
                                            <td style={{ backgroundColor: "#f6f6f6" }} className="DemandDetails__comment-col-1"></td>
                                            <td className="DemandDetails__comment-col-2 comment-text-area-container">
                                                <div style={{ paddingBottom: 0 }} className="comment-text-area-content-container">
                                                    <textarea
                                                        onChange={(Value) => {
                                                            this.setState({ emailContent: Value.target.value });
                                                        }}
                                                        value={this.state.emailContent}
                                                        className="comment-text-area"
                                                        placeholder="Envoyer un email"
                                                        rows="3"
                                                        cols="50"
                                                    ></textarea>
                                                </div>
                                                <div className="comment-input-options-container">
                                                    <div className="comment-input-options-first-part">

                                                    </div>
                                                    <div className="comment-input-options-second-part">
                                                        <InputContainer_2>
                                                            <CustomSelectSmall
                                                                error={false}
                                                                onChange={(value) => {
                                                                    this.setState({ selectedEmailModel: value.target.value });
                                                                    this.setState({ emailContent: emailModels.filter((item) => parseInt(item.id) === parseInt(value.target.value))[0].content.replaceAll("<br>", "\n") });
                                                                }}
                                                                selectedValue={this.state.selectedEmailModel}
                                                                label=""
                                                            >
                                                                <option value={""}>{"Choisissez un modèle"}</option>
                                                                {
                                                                    emailModels.filter((item) => item.status === true).map((item) => (
                                                                        <option value={`${item.id}`}>{item.name}</option>
                                                                    ))
                                                                }

                                                            </CustomSelectSmall>
                                                        </InputContainer_2>
                                                        {!emailDisabled && (<button className="comment-btn" onClick={this.sendEmail}>Envoyer</button>)}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </Block_content_2>
                        </Block_2>}
                        {acl.demand.comment() && <Block_2>
                            <div className="bloc_header">
                                <h2>Commentaires</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content_2>
                                <table>
                                    <tbody>
                                        {data &&
                                            data.comments &&
                                            data.comments.map((item) => (
                                                <tr className="DemandDetails__comment-container">
                                                    <td className="DemandDetails__comment-col-1">
                                                        <div>
                                                            <div className="comment-avatar-container">
                                                                {/* <img src={require("../../images/commentAvatar.png")} /> */}
                                                                <span className="comment-avatar-text">{item.createdByName.slice(0, 2).toLocaleUpperCase()}</span>
                                                            </div>
                                                            <strong>{item.createdByName}</strong>
                                                        </div>
                                                        <div>
                                                            <BiTime color="#fff" size={16} />
                                                            <span style={{ fontSize: 11, fontWeight: "600" }}>{item.createdAt && moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                                                        </div>
                                                    </td>
                                                    <td className="DemandDetails__comment-col-2">
                                                        <p dangerouslySetInnerHTML={{ __html: this.formatComment(item.content) }} />
                                                    </td>
                                                </tr>
                                            ))}
                                        <tr id="DemandDetails__comment-container" className="DemandDetails__comment-container last-comment-container">

                                            <td className="DemandDetails__comment-col-1"></td>
                                            <td className="DemandDetails__comment-col-2 comment-text-area-container">
                                                <div className="comment-text-area-content-container">
                                                    <Hello
                                                        // onChange={(event, newValue, newPlainTextValue, mentions) => {
                                                        //     this.setState({ commentContent: newPlainTextValue });
                                                        //     console.log("mentions", mentions)
                                                        // }}
                                                        onChange={(ev, newValue, newPlainTextValue, mentions) => {
                                                            this.setState({ commentContent: newValue, mentions });
                                                        }}
                                                        value={this.state.commentContent}
                                                    />

                                                    <span data-for='path' data-tip={subscriptions.reduce((accu, item) => accu + item.userName + "<br>", "")} className="users-receive-notifications">{this.renderNotified()}</span>
                                                </div>
                                                <div className="comment-input-options-container">
                                                    <div className="comment-input-options-first-part">
                                                        <span data-for='path' data-tip="Recever des notifications <br> lorsque des modifications <br> importantes sont apportées <br> à la demande" className="subscriber-text">S'abonner</span>
                                                        <div style={{ display: "flex", alignItem: "center" }} data-for='path' data-tip={subscriptions.reduce((accu, item) => accu + item.userName + "<br>", "")}>
                                                            {
                                                                subscriptions.slice(0, 3).map((subscription, index) => {
                                                                    return (
                                                                        <div style={{ backgroundColor: subscription.userName ? AlphabetsColor[subscription.userName.charAt(0).toLowerCase()] : "#ff78ff" }} data-for='path' data-tip={subscription.userName} className="subscriber-avatar">{subscription.userName.slice(0, 2).toLocaleUpperCase()}</div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        {acl.demand.subscribe() && <div style={{ display: !this.isSubscribed() && acl.demand.subscribe() ? "flex" : "none" }} onClick={() => this.subscribeInCommand([connectedUserInfos.id])} data-for='path' data-tip="S'abonner à <br> la demande" className="subscriber-avatar-empty">
                                                            <BiUser size={15} color="#494949" />
                                                        </div>}
                                                        {acl.demand.subscribe() && <button style={{ display: !this.isSubscribed() && acl.demand.subscribe() ? "flex" : "none" }} onClick={() => this.subscribeInCommand([connectedUserInfos.id])} data-for='path' data-tip="S'abonner à <br> la demande" className="add-subscriber-btn">
                                                            <HiOutlinePlus color="#494949" />
                                                        </button>}
                                                    </div>
                                                    <div className="comment-input-options-second-part">
                                                        {acl.demand.unsubscribe() && <button style={{ display: this.isSubscribed() && acl.demand.unsubscribe() ? "flex" : "none" }} onClick={this.unsubscribeInCommand} data-for='path' data-tip="Ne plus recevoir de <br> notifications concernant les <br> activités liées à cette demande" className="insubscriber-btn" >
                                                            <FaBell color="#494949" />
                                                            <span>Quitter la demande</span>
                                                        </button>}
                                                        {!commentDisabled && (<button className="comment-btn" onClick={this.comment}>Commenter</button>)}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Block_content_2>
                        </Block_2>}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div style={{ backgroundColor: data && data.lastStatus && data.lastStatus.status ? STATUS_COLOR[data.lastStatus.status] : "#fff" }} className={"bloc_content devis_statut ds_complete"}>
                                <h3>{data && data.lastStatus && data.lastStatus.status}</h3>
                            </div>
                        </div>

                        <div className="traceability-status-container">
                            <Traceability_block_title bgColor="#fff">Traçabilité statut/assignation</Traceability_block_title>
                            <ul>
                                {data &&
                                    data.statusHistoryList &&
                                    data.statusHistoryList.map((item, index) => (
                                        <li key={"status_history_" + index} className="traceability-status-li">
                                            <span>{`${item.actionByName ? item.actionByName : ""} / ${item.status ? item.status : ""} ${item.status === "Assigné" ? `à: ${item.assignedToName ? item.assignedToName : ""}` : ""}`}</span>
                                            <span>{item.actionDate && moment(item.actionDate).format("YYYY-MM-DD HH:mm")}</span>
                                        </li>
                                    ))}
                            </ul>
                        </div>

                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>
                                    <Col_1_li>{data && data.createdByName}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>
                                    <Col_1_li>{data && data.createdAt && moment(data.createdAt).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>
                                    <Col_1_li>{data && data.updatedByName}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>
                                    <Col_1_li>{data && data.updatedAt && moment(data.updatedAt).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
    renderNotified = () => {
        if (this.getNotified().length <= 1) {
            return (`${this.getNotified().length} utilisateur recevra une notification`)
        } else {
            return (`${this.getNotified().length} utilisateurs recevront une notification`)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        acl_actions: state.ACL.acl_actions,
        connectedUserInfos: state.User.connectedUserInfos,
    };
};
// const NavigationHeader = withRouter(Header)

export default connect(mapStateToProps)(DemandDetails);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 10px;
    margin-bottom: 10px;
    min-width: 1014px;
`;
const Block_2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    margin-bottom: 10px;
    min-width: 1014px;
`;
const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_2 = styled.div`
    width: 100%;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;

const Field_value = styled.p`
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    min-height: 47px;
`;
const FullRow = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 99%;
    min-height: 100px;
`;
const FullRow_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 0;
    width: 99%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background:${props => props.bg ? props.bg : "#f6f6f6"};
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
    min-height: 47px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: ${(props) => (props.bgColor ? props.bgColor : "#f0f0f0")};
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;

export const InputContainer = styled.div`
    width: 300px;
    height: 25px;

    margin-left: 0px !important;
`;
export const InputContainer_2 = styled.div`
    width: 300px;
    height: 29px;
    margin-right:15px;
    margin-left: 0px !important;
`;