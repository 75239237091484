import React, { Component } from "react";
import { Link } from "react-router-dom";
class CreateInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div id="main_container" style={{ height: "100%" }}>
                    <div id="fixed_bar" className="top_options_wrapper">
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid4">
                                    <h1>Créer une nouvelle vente</h1>
                                    <div className="top_options_btns">
                                        <a href="#" id="go_back_to_form" className="btn_white" style={{ display: "none" }}>
                                            Retouner
                                        </a>
                                        <a href="#" id="prescription_submit" className="btn_bleu" style={{ display: "none" }} data-shortcut_alias="submit">
                                            Valider
                                        </a>
                                        <a href="#" id="payer_submit" className="btn_bleu" style={{ display: "none" }}>
                                            Valider
                                        </a>
                                        <a href="#" data-status_action="save" className="btn_white save_form_button status_action_button" data-form_id="create_invoices_form">
                                            Brouillon
                                        </a>
                                        <Link to={"approve"} data-status_action="approve" className="btn_bleu status_action_button" data-shortcut_alias="submit" data-form_id="create_invoices_form">
                                            Approuver (F12)
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="content">
                        <div className="wrapper">
                            <div id="main_form_container" className="container4">
                                <form action="/invoice/create" method="post" id="create_invoices_form" className="validateable">
                                    <div className="grid2">
                                        {/* Customer/Contact */}
                                        <div className="bloc">
                                            {/* Customer */}
                                            <div className="bloc_content" style={{ background: "#ff5778" }}>
                                                <i className="person" />

                                                <div className="plan-search">
                                                    <label htmlFor="customer_id" className="inline_label" style={{}}>
                                                        Client
                                                    </label>
                                                    <div className="inline_input">
                                                        <div className="input_search">
                                                            <input type="text" name="customer_id" id="customer_id" defaultValue className="lookup_field" readOnly="readonly" data-lookup_table="customers" autoComplete="off" />
                                                            <input type="hidden" name="customer_id" id="hidden_customer_id" defaultValue />
                                                            <a className="sprite-search-btn lookup_field_trigger" />
                                                            <a className="sprite-close-btn lookup_field_eraser" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: "none" }}>
                                                    <div className="inline_input">
                                                        <input type="text" name="address" id="address" defaultValue className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <input type="text" name="city" id="city" defaultValue className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <input type="text" name="postal_code" id="postal_code" defaultValue className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <div className="selectbox">
                                                            <select name="country" id="country" autoComplete="off">
                                                                <option value>Aucun</option>
                                                                <option value="AF">Afghanistan</option>
                                                                <option value="ZA">Afrique du Sud</option>
                                                                <option value="AL">Albanie</option>
                                                                <option value="DZ">Algérie</option>
                                                                <option value="DE">Allemagne</option>
                                                                <option value="AD">Andorre</option>
                                                                <option value="AO">Angola</option>
                                                                <option value="AI">Anguilla</option>
                                                                <option value="AQ">Antarctique</option>
                                                                <option value="AG">Antigua-et-Barbuda</option>
                                                                <option value="AN">Antilles néerlandaises</option>
                                                                <option value="SA">Arabie saoudite</option>
                                                                <option value="AR">Argentine</option>
                                                                <option value="AM">Arménie</option>
                                                                <option value="AW">Aruba</option>
                                                                <option value="AU">Australie</option>
                                                                <option value="AT">Autriche</option>
                                                                <option value="AZ">Azerbaïdjan</option>
                                                                <option value="BS">Bahamas</option>
                                                                <option value="BH">Bahreïn</option>
                                                                <option value="BD">Bangladesh</option>
                                                                <option value="BB">Barbade</option>
                                                                <option value="BE">Belgique</option>
                                                                <option value="BZ">Belize</option>
                                                                <option value="BM">Bermudes</option>
                                                                <option value="BT">Bhoutan</option>
                                                                <option value="BY">Biélorussie</option>
                                                                <option value="BO">Bolivie</option>
                                                                <option value="BA">Bosnie-Herzégovine</option>
                                                                <option value="BW">Botswana</option>
                                                                <option value="BN">Brunéi Darussalam</option>
                                                                <option value="BR">Brésil</option>
                                                                <option value="BG">Bulgarie</option>
                                                                <option value="BF">Burkina Faso</option>
                                                                <option value="BI">Burundi</option>
                                                                <option value="BJ">Bénin</option>
                                                                <option value="KH">Cambodge</option>
                                                                <option value="CM">Cameroun</option>
                                                                <option value="CA">Canada</option>
                                                                <option value="CV">Cap-Vert</option>
                                                                <option value="EA">Ceuta et Melilla</option>
                                                                <option value="CL">Chili</option>
                                                                <option value="CN">Chine</option>
                                                                <option value="CY">Chypre</option>
                                                                <option value="CO">Colombie</option>
                                                                <option value="KM">Comores</option>
                                                                <option value="CG">Congo-Brazzaville</option>
                                                                <option value="CD">Congo-Kinshasa</option>
                                                                <option value="KP">Corée du Nord</option>
                                                                <option value="KR">Corée du Sud</option>
                                                                <option value="CR">Costa Rica</option>
                                                                <option value="HR">Croatie</option>
                                                                <option value="CU">Cuba</option>
                                                                <option value="CW">Curaçao</option>
                                                                <option value="CI">Côte d’Ivoire</option>
                                                                <option value="DK">Danemark</option>
                                                                <option value="DG">Diego Garcia</option>
                                                                <option value="DJ">Djibouti</option>
                                                                <option value="DM">Dominique</option>
                                                                <option value="SV">El Salvador</option>
                                                                <option value="ES">Espagne</option>
                                                                <option value="EE">Estonie</option>
                                                                <option value="FJ">Fidji</option>
                                                                <option value="FI">Finlande</option>
                                                                <option value="FR">France</option>
                                                                <option value="GA">Gabon</option>
                                                                <option value="GM">Gambie</option>
                                                                <option value="GH">Ghana</option>
                                                                <option value="GI">Gibraltar</option>
                                                                <option value="GD">Grenade</option>
                                                                <option value="GL">Groenland</option>
                                                                <option value="GR">Grèce</option>
                                                                <option value="GP">Guadeloupe</option>
                                                                <option value="GU">Guam</option>
                                                                <option value="GT">Guatemala</option>
                                                                <option value="GG">Guernesey</option>
                                                                <option value="GN">Guinée</option>
                                                                <option value="GQ">Guinée équatoriale</option>
                                                                <option value="GW">Guinée-Bissau</option>
                                                                <option value="GY">Guyana</option>
                                                                <option value="GF">Guyane française</option>
                                                                <option value="GE">Géorgie</option>
                                                                <option value="GS">Géorgie du Sud et les Îles Sandwich du Sud</option>
                                                                <option value="HT">Haïti</option>
                                                                <option value="HN">Honduras</option>
                                                                <option value="HU">Hongrie</option>
                                                                <option value="IN">Inde</option>
                                                                <option value="ID">Indonésie</option>
                                                                <option value="IQ">Irak</option>
                                                                <option value="IR">Iran</option>
                                                                <option value="IE">Irlande</option>
                                                                <option value="IS">Islande</option>
                                                                <option value="IL">Israël</option>
                                                                <option value="IT">Italie</option>
                                                                <option value="JM">Jamaïque</option>
                                                                <option value="JP">Japon</option>
                                                                <option value="JE">Jersey</option>
                                                                <option value="JO">Jordanie</option>
                                                                <option value="KZ">Kazakhstan</option>
                                                                <option value="KE">Kenya</option>
                                                                <option value="KG">Kirghizistan</option>
                                                                <option value="KI">Kiribati</option>
                                                                <option value="XK">Kosovo</option>
                                                                <option value="KW">Koweït</option>
                                                                <option value="RE">La Réunion</option>
                                                                <option value="LA">Laos</option>
                                                                <option value="LS">Lesotho</option>
                                                                <option value="LV">Lettonie</option>
                                                                <option value="LB">Liban</option>
                                                                <option value="LY">Libye</option>
                                                                <option value="LR">Libéria</option>
                                                                <option value="LI">Liechtenstein</option>
                                                                <option value="LT">Lituanie</option>
                                                                <option value="LU">Luxembourg</option>
                                                                <option value="MK">Macédoine</option>
                                                                <option value="MG">Madagascar</option>
                                                                <option value="MY">Malaisie</option>
                                                                <option value="MW">Malawi</option>
                                                                <option value="MV">Maldives</option>
                                                                <option value="ML">Mali</option>
                                                                <option value="MT">Malte</option>
                                                                <option value="MA">Maroc</option>
                                                                <option value="MQ">Martinique</option>
                                                                <option value="MU">Maurice</option>
                                                                <option value="MR">Mauritanie</option>
                                                                <option value="YT">Mayotte</option>
                                                                <option value="MX">Mexique</option>
                                                                <option value="MD">Moldavie</option>
                                                                <option value="MC">Monaco</option>
                                                                <option value="MN">Mongolie</option>
                                                                <option value="MS">Montserrat</option>
                                                                <option value="ME">Monténégro</option>
                                                                <option value="MZ">Mozambique</option>
                                                                <option value="MM">Myanmar</option>
                                                                <option value="NA">Namibie</option>
                                                                <option value="NR">Nauru</option>
                                                                <option value="NI">Nicaragua</option>
                                                                <option value="NE">Niger</option>
                                                                <option value="NG">Nigéria</option>
                                                                <option value="NU">Niue</option>
                                                                <option value="NO">Norvège</option>
                                                                <option value="NC">Nouvelle-Calédonie</option>
                                                                <option value="NZ">Nouvelle-Zélande</option>
                                                                <option value="NP">Népal</option>
                                                                <option value="OM">Oman</option>
                                                                <option value="UG">Ouganda</option>
                                                                <option value="UZ">Ouzbékistan</option>
                                                                <option value="PK">Pakistan</option>
                                                                <option value="PW">Palaos</option>
                                                                <option value="PA">Panama</option>
                                                                <option value="PG">Papouasie-Nouvelle-Guinée</option>
                                                                <option value="PY">Paraguay</option>
                                                                <option value="NL">Pays-Bas</option>
                                                                <option value="BQ">Pays-Bas caribéens</option>
                                                                <option value="PH">Philippines</option>
                                                                <option value="PN">Pitcairn</option>
                                                                <option value="PL">Pologne</option>
                                                                <option value="PF">Polynésie française</option>
                                                                <option value="PR">Porto Rico</option>
                                                                <option value="PT">Portugal</option>
                                                                <option value="PE">Pérou</option>
                                                                <option value="QA">Qatar</option>
                                                                <option value="HK">R.A.S. chinoise de Hong Kong</option>
                                                                <option value="MO">R.A.S. chinoise de Macao</option>
                                                                <option value="RO">Roumanie</option>
                                                                <option value="GB">Royaume-Uni</option>
                                                                <option value="RU">Russie</option>
                                                                <option value="RW">Rwanda</option>
                                                                <option value="CF">République centrafricaine</option>
                                                                <option value="DO">République dominicaine</option>
                                                                <option value="CZ">République tchèque</option>
                                                                <option value="EH">Sahara occidental</option>
                                                                <option value="BL">Saint-Barthélemy</option>
                                                                <option value="KN">Saint-Kitts-et-Nevis</option>
                                                                <option value="SM">Saint-Marin</option>
                                                                <option value="MF">Saint-Martin (partie française)</option>
                                                                <option value="SX">Saint-Martin (partie néerlandaise)</option>
                                                                <option value="PM">Saint-Pierre-et-Miquelon</option>
                                                                <option value="VC">Saint-Vincent-et-les Grenadines</option>
                                                                <option value="SH">Sainte-Hélène</option>
                                                                <option value="LC">Sainte-Lucie</option>
                                                                <option value="WS">Samoa</option>
                                                                <option value="AS">Samoa américaines</option>
                                                                <option value="ST">Sao Tomé-et-Principe</option>
                                                                <option value="RS">Serbie</option>
                                                                <option value="SC">Seychelles</option>
                                                                <option value="SL">Sierra Leone</option>
                                                                <option value="SG">Singapour</option>
                                                                <option value="SK">Slovaquie</option>
                                                                <option value="SI">Slovénie</option>
                                                                <option value="SO">Somalie</option>
                                                                <option value="SD">Soudan</option>
                                                                <option value="SS">Soudan du Sud</option>
                                                                <option value="LK">Sri Lanka</option>
                                                                <option value="CH">Suisse</option>
                                                                <option value="SR">Suriname</option>
                                                                <option value="SE">Suède</option>
                                                                <option value="SJ">Svalbard et Jan Mayen</option>
                                                                <option value="SZ">Swaziland</option>
                                                                <option value="SY">Syrie</option>
                                                                <option value="SN">Sénégal</option>
                                                                <option value="TJ">Tadjikistan</option>
                                                                <option value="TZ">Tanzanie</option>
                                                                <option value="TW">Taïwan</option>
                                                                <option value="TD">Tchad</option>
                                                                <option value="TF">Terres australes françaises</option>
                                                                <option value="IO">Territoire britannique de l’océan Indien</option>
                                                                <option value="PS">Territoires palestiniens</option>
                                                                <option value="TH">Thaïlande</option>
                                                                <option value="TL">Timor oriental</option>
                                                                <option value="TG">Togo</option>
                                                                <option value="TK">Tokelau</option>
                                                                <option value="TO">Tonga</option>
                                                                <option value="TT">Trinité-et-Tobago</option>
                                                                <option value="TA">Tristan da Cunha</option>
                                                                <option value="TN">Tunisie</option>
                                                                <option value="TM">Turkménistan</option>
                                                                <option value="TR">Turquie</option>
                                                                <option value="TV">Tuvalu</option>
                                                                <option value="UA">Ukraine</option>
                                                                <option value="UY">Uruguay</option>
                                                                <option value="VU">Vanuatu</option>
                                                                <option value="VE">Venezuela</option>
                                                                <option value="VN">Vietnam</option>
                                                                <option value="WF">Wallis-et-Futuna</option>
                                                                <option value="YE">Yémen</option>
                                                                <option value="ZM">Zambie</option>
                                                                <option value="ZW">Zimbabwe</option>
                                                                <option value="ZZ">région indéterminée</option>
                                                                <option value="EG">Égypte</option>
                                                                <option value="AE">Émirats arabes unis</option>
                                                                <option value="EC">Équateur</option>
                                                                <option value="ER">Érythrée</option>
                                                                <option value="VA">État de la Cité du Vatican</option>
                                                                <option value="FM">États fédérés de Micronésie</option>
                                                                <option value="US">États-Unis</option>
                                                                <option value="ET">Éthiopie</option>
                                                                <option value="BV">Île Bouvet</option>
                                                                <option value="CX">Île Christmas</option>
                                                                <option value="CP">Île Clipperton</option>
                                                                <option value="NF">Île Norfolk</option>
                                                                <option value="IM">Île de Man</option>
                                                                <option value="AC">Île de l’Ascension</option>
                                                                <option value="IC">Îles Canaries</option>
                                                                <option value="KY">Îles Caïmans</option>
                                                                <option value="CC">Îles Cocos (Keeling)</option>
                                                                <option value="CK">Îles Cook</option>
                                                                <option value="FO">Îles Féroé</option>
                                                                <option value="HM">Îles Heard et MacDonald</option>
                                                                <option value="FK">Îles Malouines</option>
                                                                <option value="MP">Îles Mariannes du Nord</option>
                                                                <option value="MH">Îles Marshall</option>
                                                                <option value="SB">Îles Salomon</option>
                                                                <option value="TC">Îles Turques-et-Caïques</option>
                                                                <option value="VG">Îles Vierges britanniques</option>
                                                                <option value="VI">Îles Vierges des États-Unis</option>
                                                                <option value="UM">Îles mineures éloignées des États-Unis</option>
                                                                <option value="AX">Îles Åland</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div id="contact_container" className="bloc_content" style={{ display: "none", background: "#ff5778" }}>
                                                <i className="person" />
                                                <div className="plan-search">
                                                    <label htmlFor="contact_id" className="inline_label" style={{}}>
                                                        Contact
                                                    </label>
                                                    <div className="inline_input">
                                                        <div className="input_search">
                                                            <input type="text" name="contact_id" id="contact_id" defaultValue className="lookup_field" readOnly="readonly" data-lookup_table="contacts" autoComplete="off" />
                                                            <input type="hidden" name="contact_id" id="hidden_contact_id" defaultValue />
                                                            <a className="sprite-search-btn lookup_field_trigger" />
                                                            <a className="sprite-close-btn lookup_field_eraser" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bloc">
                                                <div className="bloc_header">
                                                    <i className="vente_icon" />
                                                    <div className="bloc_header_btns vente_champs_cb">
                                                        <div className="selectbox " style={{}}>
                                                            <select name="transactions_products_dci" id="transactions_products_dci" className=" " autoComplete="off">
                                                                <option value={1114}>ZIDOVUDINE</option>
                                                                <option value={1115}>ZIPRASIDONE</option>
                                                                <option value={1116}>ZOLMITRIPTAN</option>
                                                                <option value={1117}>ZOLPIDEM</option>
                                                                <option value={1118}>ZOPICLONE</option>
                                                            </select>
                                                        </div>
                                                        <div className="input_douchette">
                                                            <input type="text" id="transactions_products_barcode" autoComplete="off" placeholder="Nom ou code barre" />
                                                            <a className="sprite-douchette-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bloc_content table_wrraper">
                                                <table id="transactions_products_table" data-ajax_url="/products/api/index" data-pricing_field="sale_price" className="table">
                                                    <thead>
                                                        <tr className="table_filter">
                                                            <td>
                                                                <div className="selectbox " style={{}}>
                                                                    <select name="transactions_products_table_search_operator" id="transactions_products_table_search_operator" className=" " autoComplete="off">
                                                                        <option value="LIKE" selected="selected">
                                                                            contient
                                                                        </option>
                                                                        <option value="STARTS">commence par</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td colSpan={1}>
                                                                <div className="input_search" style={{}}>
                                                                    <input type="text" name="transactions_products_table_search_name" id="transactions_products_table_search_name" defaultValue className=" transactions_products_table_header_search_q" placeholder="Nom" autoComplete="off" />
                                                                    <a className="sprite-search-btn" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="input_search" style={{}}>
                                                                    <input type="text" name="transactions_products_table_search_sale_price" id="transactions_products_table_search_sale_price" defaultValue className=" transactions_products_table_header_search_q" placeholder="PPV" autoComplete="off" />
                                                                    <a className="sprite-search-btn" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="selectbox " style={{}}>
                                                                    <select name="transactions_products_table_search_zone_id" id="transactions_products_table_search_zone_id" className=" transactions_products_table_header_search_q" autoComplete="off">
                                                                        <option value>Zone</option>
                                                                        <option value={96888}>AEROSOLS</option>
                                                                        <option value={97292}>amam</option>
                                                                        <option value={96883}>Antibiotique</option>
                                                                        <option value={96628}>COLLYRE </option>
                                                                        <option value={96892}>COLLYRES</option>
                                                                        <option value={96507}>COMPRIMER SIMPLE</option>
                                                                        <option value={96473}>COMPRIMES</option>
                                                                        <option value={95755}>COMPRIMES SIMPL</option>
                                                                        <option value={97318}>COMPRIMES SIMPLES </option>
                                                                        <option value={96472}>COMPRIMES SIMPLES</option>
                                                                        <option value={94379}>cps</option>
                                                                        <option value={100018}>FACULTES</option>
                                                                        <option value={94548}>JORF</option>
                                                                        <option value={96886}>kk,m,l,,l,k;,mk,m</option>
                                                                        <option value={95757}>PARA</option>
                                                                        <option value={98143}>para</option>
                                                                        <option value={101441}>PARA creme</option>
                                                                        <option value={94381}>pmd</option>
                                                                        <option value={101445}>pommade ophtalmique </option>
                                                                        <option value={94261}>Rayon bébé ABCDERM</option>
                                                                        <option value={94262}>Rayon bébé Dodie</option>
                                                                        <option value={96506}>RIDA</option>
                                                                        <option value={94346}>ROCHE</option>
                                                                        <option value={94060}>ROGER CAVAILLES</option>
                                                                        <option value={96039}>SIROP</option>
                                                                        <option value={95756}>SIROP</option>
                                                                        <option value={96474}>SIROPS</option>
                                                                        <option value={97320}>SIROPS SIMPLES </option>
                                                                        <option value={99910}>taper le nom du zone </option>
                                                                        <option value={93829}>tligui</option>
                                                                        <option value={94061}>VICHY</option>
                                                                        <option value={96038}>VITAMINE</option>
                                                                        <option value={96984}>ZONE A</option>
                                                                        <option value={96985}>ZONE B</option>
                                                                        <option value={97711}>zone comp</option>
                                                                        <option value={94852}>ZONE LEKBIRA</option>
                                                                        <option value={96600}>zone suppo</option>
                                                                        <option value={97203}>zone test 1</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td className="table_action">
                                                                <a href="#" id="search_transactions_products_table" className="search_bleu_btn">
                                                                    <i className="sprite-search-btn-white" />
                                                                </a>
                                                                <a href="#" id="refresh_data" style={{ display: "none" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ minWidth: "50px" }}>Nom</th>
                                                            <th style={{ minWidth: "50px" }}>Forme </th>
                                                            <th style={{ minWidth: "50px" }}>PPV</th>
                                                            <th style={{ minWidth: "50px" }}>Zone</th>
                                                            <th style={{ minWidth: "50px" }}>Disp.</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody></tbody>
                                                </table>
                                                <div id="transactions_products_empty_wrapper" className="plan-empty plan-empty2">
                                                    <p>
                                                        <i className="vente_fleche" />
                                                        Vous pouvez lancer une recherche ici !{" "}
                                                    </p>
                                                    <i className="empty-search-ico2" />
                                                </div>
                                                <div id="transactions_products_barcode_wrapper" className="plan-empty" style={{ display: "none" }}>
                                                    <p>Vous pouvez utiliser votre douchette maintenant</p>
                                                    <span>
                                                        <i className="empty-search-ico" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid2">
                                        {/* Fields */}
                                        <div className="bloc">
                                            {/* Fields */}
                                            <div className="bloc_content" style={{ background: "#ff5778" }}>
                                                <div className="bloc2" style={{}}>
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="invoice_date" className="inline_label" style={{}}>
                                                                Date *
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="input_date">
                                                                    <input type="text" name="invoice_date" id="invoice_date" defaultValue="2019-09-12" className="datepicker validate[required, custom[date]]" data-is_datepicker="true" data-is_required="true" autoComplete="off" />
                                                                    <a className="sprite-date-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bloc2">
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="owner_id" className="inline_label" style={{}}>
                                                                Par *
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="selectbox">
                                                                    <select name="owner_id" id="owner_id" data-is_required="true" className=" validate[required]" autoComplete="off">
                                                                        <option value={26555} selected="selected">
                                                                            compte Démonstration
                                                                        </option>
                                                                        <option value={27063}>employer europharm</option>
                                                                        <option value={27679}>houda ain</option>
                                                                        <option value={27350}>Ikbal Ikbal</option>
                                                                        <option value={27006}>l p</option>
                                                                        <option value={28084}>mer roc</option>
                                                                        <option value={27585}>SOBRUS FORMATEUR</option>
                                                                        <option value={26808}>tesst0009 sobrus</option>
                                                                        <option value={27010}>test007 sobrus</option>
                                                                        <option value={27865}>y n</option>
                                                                        <option value={27810}>Y Y</option>
                                                                        <option value={26728}>Youssef Naji</option>
                                                                        <option value={27657}>zizo ikbal</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="doctor_container" className="bloc2" style={{ marginTop: "5px", display: "none" }}>
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="doctor_id" className="inline_label" style={{}}>
                                                                Médecin traitant
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="input_search">
                                                                    <input type="text" name="doctor_id" id="doctor_id" defaultValue className="lookup_field" readOnly="readonly" data-lookup_table="doctors" autoComplete="off" />
                                                                    <input type="hidden" name="doctor_id" id="hidden_doctor_id" defaultValue />
                                                                    <a className="sprite-search-btn lookup_field_trigger" />
                                                                    <a className="sprite-close-btn lookup_field_eraser" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Selected Products */}
                                        <div className="bloc">
                                            <div id="totals_container" className="bloc_header">
                                                <div className="bloc4">
                                                    <div className="plan-total">
                                                        <div className="row">
                                                            <div className="bloc2">
                                                                <div className="plan-total">
                                                                    <strong>
                                                                        Total à payer
                                                                        <small id="global_discount_button" style={{ cursor: "pointer" }}>
                                                                            R
                                                                        </small>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="bloc2">
                                                                <div className="plan-price">
                                                                    <strong className="total_amount">0 DHS</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="bloc2">
                                                                <div className="row">
                                                                    <div className="bloc2">
                                                                        <div className="plan-total">
                                                                            <span>Quantité totale</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloc2">
                                                                        <div className="plan-price">
                                                                            <span className="total_quantity">0</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bloc_content table_wrraper">
                                                <table id="selected_products_table" className="table table-hidden-tr">
                                                    <thead>
                                                        <tr>
                                                            <th />
                                                            <th>Produit</th>
                                                            <th>P.U</th>
                                                            <th>PPV</th>
                                                            <th>Disp.</th>
                                                            <th />
                                                            <th />
                                                            <th />
                                                            <th>Total</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody className="main_tbody"></tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <table id="product_prototype_container" style={{ display: "none" }}>
                                            <tbody>
                                                <tr className="product_info">
                                                    <td>
                                                        <strong className="quantity" />
                                                    </td>
                                                    <td>
                                                        <span className="name" />
                                                        <p className="expiry_date_info_normal">
                                                            <i className="peremp_icon_black" />
                                                            <strong id="peremp_strong_black" />
                                                        </p>
                                                        <p className="expiry_date_info_alert">
                                                            <i className="peremp_icon" />
                                                            <strong id="peremp_strong" />
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="unit_price" style={{ fontWeight: "bold" }} />
                                                        <div className="table-badge discount_sticker" style={{ display: "none" }} />
                                                    </td>
                                                    <td>
                                                        <strong className="sale_price" />
                                                        <p className="multiprice_container">
                                                            <i className="multiprix_icon" />
                                                            <strong id="multiprix_strong">Multiprix</strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <strong className="available" />
                                                    </td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <strong className="line_total" />
                                                    </td>
                                                    <td className="table_action">
                                                        <a href="#" className="sprite-table-delete-btn delete_product_line" />
                                                        <a href="#" className="sprite-table-edit-btn edit_product_data_button" />
                                                    </td>
                                                </tr>
                                                <tr className="hidden-tr product_data">
                                                    <td colSpan={10} className="tht-td">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type="hidden" name="products[0][ID]" className="product_id" />
                                                                        <input type="hidden" name="products[0][name]" className="product_name" />
                                                                        <input type="hidden" name="products[0][unit_original_price]" className="unit_original_price" />
                                                                        <input type="hidden" name="products[0][purchase_price]" className="purchase_price" />
                                                                        <input type="hidden" name="products[0][sale_price]" className="sale_price" />
                                                                        <input type="hidden" name="products[0][product_schedule]" className="product_schedule" />
                                                                        <input type="hidden" name="products[0][tax_id]" className="tax_id" />
                                                                        <div className="form_row">
                                                                            <label>Quantité</label>
                                                                            <input type="text" name="products[0][quantity]" id="products-0-quantity" defaultValue className=" input quantity validate[custom[integer]] intOnly" style={{}} placeholder autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Prix unitaire</label>
                                                                            <input type="text" name="products[0][unit_price]" id="products-0-unit_price" defaultValue className=" input unit_price validate[custom[number]] decimalOnly" style={{}} placeholder autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div className="form_row">
                                                                            <label>Type de remise</label>
                                                                            <div className="selectbox " style={{}}>
                                                                                <select name="products[0][discount_type]" id="products-0-discount_type" className=" discount_type" autoComplete="off">
                                                                                    <option value="percentage" selected="selected">
                                                                                        %
                                                                                    </option>
                                                                                    <option value="amount">Montant</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{}}>
                                                                        <div className="form_row">
                                                                            <label>Remise</label>
                                                                            <input type="text" name="products[0][discount]" id="products-0-discount" defaultValue className=" input discount validate[custom[number]] decimalOnly" style={{}} placeholder autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{}}>
                                                                        <div className="form_row">
                                                                            <a href="#" className="btn_white discount_free_products" style={{ width: "70px", padding: 0, marginTop: "15px" }}>
                                                                                Rem. U.G
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Prix</label>
                                                                            <div className="selectbox " style={{}}>
                                                                                <select name="products[0][product_price_id]" id="products-0-product_price_id" className=" product_price_id" autoComplete="off">
                                                                                    <option value={0}>Par défaut</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Disponible</label>
                                                                            <input type="text" name="products[0][available]" id="products-0-available" defaultValue className=" input available validate[custom[number]] disabled" style={{}} placeholder autoComplete="off" readOnly="readonly" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Base de remboursement</label>
                                                                            <input
                                                                                type="text"
                                                                                name="products[0][unit_max_reimbursable_price]"
                                                                                id="products-0-unit_max_reimbursable_price"
                                                                                defaultValue
                                                                                className=" input unit_max_reimbursable_price decimal"
                                                                                style={{}}
                                                                                placeholder
                                                                                autoComplete="off"
                                                                            />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Taux Remb.</label>
                                                                            <input type="text" name="products[0][unit_reimbursement_rate]" id="products-0-unit_reimbursement_rate" defaultValue className=" input unit_reimbursement_rate decimal" style={{}} placeholder autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div id="product_prices_popup_container" className="popup_wrapper" style={{ display: "none", position: "fixed" }}>
                                            <div className="popup_top_produit">
                                                <a href="#" className="popup_close product_prices_popup_close">
                                                    <img src="/images/close.png" />
                                                </a>
                                                <div id="product_prices_popup_image_container" className="popup_produit_img product_img"></div>
                                                <div id="product_prices_popup_zoom_container" className="tooltip_zoom"></div>
                                                <div className="popup_title" style={{ float: "left", width: "200px", marginTop: "25px", marginLeft: "20px" }}>
                                                    <h2 id="product_prices_popup_product_name" style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "bold", color: "#fff", textTransform: "uppercase", marginBottom: "2px" }}></h2>
                                                    <span style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "normal", color: "#fff" }}>Ce produit a plusieurs prix, lequel vous souhaitez utiliser ? </span>
                                                </div>
                                            </div>
                                            <div className="popup_bottom" style={{ height: "230px" }}>
                                                <div id="product_prices_lines_container" className="popup_form" style={{ width: "95%", verticalAlign: "middle", marginRight: "auto", marginLeft: "auto", overflowY: "auto", height: "219px" }}></div>
                                                <div className="popup_footer" style={{ marginTop: 0 }}>
                                                    <a href="#" className="btn_red product_prices_popup_close">
                                                        Annuler
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="product_prices_line_container" style={{ display: "none" }}>
                                            <label htmlFor="product_prices_radio_0" className="radio radio_option product_prices_line" style={{ cursor: "pointer" }}>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="product_prices_radio_0" id="product_prices_radio_0" />
                                                        <span className="checked" />
                                                    </li>
                                                    <li>
                                                        <p className="product_prices_line_price">
                                                            Prix unitaire : <strong />
                                                        </p>
                                                    </li>
                                                    <li className="product_prices_line_new_tag">
                                                        <span className="tag_new">Nouveau</span>
                                                    </li>
                                                    <li className="radio_action">
                                                        <a href="#" className="sprite-table-delete-btn tooltip delete_product_price" style={{ display: "inline-block" }} title="Supprimer" />
                                                    </li>
                                                </ul>
                                            </label>
                                        </div>
                                        <div id="product_expiry_dates_popup_container" className="popup_wrapper" style={{ display: "none", position: "fixed" }}>
                                            <div className="popup_top_produit">
                                                <a href="#" className="popup_close product_expiry_dates_popup_close">
                                                    <img src="/images/close.png" />
                                                </a>
                                                <div id="product_expiry_dates_popup_image_container" className="popup_produit_img product_img"></div>
                                                <div id="product_expiry_dates_popup_zoom_container" className="tooltip_zoom"></div>
                                                <div className="popup_title" style={{ float: "left", width: "200px", marginTop: "25px", marginLeft: "20px" }}>
                                                    <h2 id="product_expiry_dates_popup_product_name" style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "bold", color: "#fff", textTransform: "uppercase", marginBottom: "2px" }}></h2>
                                                    <span style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "normal", color: "#fff" }}>Une des dates de péremption de ce produit est proche ! </span>
                                                </div>
                                            </div>
                                            <div className="popup_bottom" style={{ height: "230px" }}>
                                                <div id="product_expiry_dates_lines_container" className="popup_form" style={{ width: "95%", verticalAlign: "middle", marginRight: "auto", marginLeft: "auto", overflowY: "auto", height: "219px" }}></div>
                                            </div>
                                        </div>
                                        <div id="product_expiry_dates_line_container" style={{ display: "none" }}>
                                            <label htmlFor="product_expiry_dates_radio_0" className="radio radio_option product_expiry_dates_line" style={{ cursor: "pointer" }}>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="product_expiry_dates_radio_0" id="product_expiry_dates_radio_0" />
                                                        <span className="checked" />
                                                    </li>
                                                    <li>
                                                        <p className="product_expiry_dates_line_expiry_date">
                                                            Date de péremption : <strong />
                                                        </p>
                                                    </li>
                                                    <li className="product_expiry_dates_line_new_tag">
                                                        <span className="tag_new">Nouveau</span>
                                                    </li>
                                                    <li className="radio_action">
                                                        <a href="#" className="sprite-table-delete-btn tooltip delete_product_expiry_date" style={{ display: "inline-block" }} title="Supprimer" />
                                                    </li>
                                                </ul>
                                            </label>
                                        </div>
                                        <div id="global_discount_form_container" style={{ display: "none" }}>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_application_type" className="inline_label">
                                                    Appliquer à
                                                </label>
                                                <div className="inline_input">
                                                    <div className="selectbox " style={{}}>
                                                        <select name="global_discount_application_type" id="global_discount_application_type" className=" " autoComplete="off">
                                                            <option value="each_product" selected="selected">
                                                                Appliquer à chaque produit
                                                            </option>
                                                            <option value="total">Appliquer au total</option>
                                                            <option value="rectify">Rectifier le total</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_type" className="inline_label">
                                                    Type de remise
                                                </label>
                                                <div className="inline_input">
                                                    <div className="selectbox " style={{}}>
                                                        <select name="global_discount_type" id="global_discount_type" className=" " autoComplete="off">
                                                            <option value="percentage" selected="selected">
                                                                %
                                                            </option>
                                                            <option value="amount">Montant</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount" className="inline_label">
                                                    Remise
                                                </label>
                                                <div className="inline_input">
                                                    <input type="text" name="global_discount" id="global_discount" defaultValue className=" input validate[custom[number]] decimalOnly" style={{}} placeholder autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_total" className="inline_label">
                                                    Total
                                                </label>
                                                <div className="inline_input">
                                                    <input type="text" name="global_discount_total" id="global_discount_total" defaultValue className=" input validate[custom[number]] decimalOnly" style={{}} placeholder autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="popup_produit_footer" style={{ width: "calc(100% + 20px)", marginTop: "30px", marginLeft: "-10px", position: "initial", marginBottom: "-15px" }}>
                                                <a href="#" id="global_discount_form_submit" className="btn_green">
                                                    Appliquer
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateInvoices;
