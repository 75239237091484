import { createStore, combineReducers, applyMiddleware } from "redux";
import CurrentPathName from "./Reducers/CurrentPathName";
import SettingsTopOptionsBtn from "./Reducers/SettingsTopOptionsBtn";
import FlashMessage from "./Reducers/FlashMessage";
import accessControl from "./Reducers/accessControl";
import connectedUserInfos from "./Reducers/connectedUserInfos";
import thunk from "redux-thunk";
import notification from "./Reducers/notification";

const rootReducer = combineReducers({ Auth: CurrentPathName, Settings: SettingsTopOptionsBtn, FlashMessage: FlashMessage, ACL: accessControl, User: connectedUserInfos, Notification: notification });

export default createStore(rootReducer, applyMiddleware(thunk));
