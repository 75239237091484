export const AlphabetsColor = {
    "a": "#00b1d6",
    "b": "#03c48a",
    "c": "#700242",
    "d": "#eda224",
    "e": "#ea4a70",
    "f": "#1aa0ed",
    "g": "#11aba0",
    "h": "#00b1d6",
    "i": "#03c48a",
    "j": "#700242",
    "k": "#eda224",
    "l": "#ea4a70",
    "m": "#1aa0ed",
    "n": "#11aba0",
    "o": "#00b1d6",
    "p": "#03c48a",
    "q": "#700242",
    "r": "#eda224",
    "s": "#ea4a70",
    "t": "#1aa0ed",
    "u": "#11aba0",
    "v": "#00b1d6",
    "w": "#03c48a",
    "x": "#700242",
    "y": "#eda224",
    "z": "#ea4a70",
}
export const CategoryColor = {
    "A": "#15BBB0",
    "B": "#2D9BFF",
    "C": "#FF6800"
}