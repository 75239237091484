import ApiCall from "../services/ApiCall";
class EmailDAO {
    // for getting all demands limited by page number

    static getALLEmailModels = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/emailmodels`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static addEmailModel = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/emailmodel`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static updateEmailModel = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/emailmodel/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static getEmailModelById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/emailmodel/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static getIncomingEmailConfig = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/emailconfig`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static addIncomingEmailConfig = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/emailconfig`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static sendEmail = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/mail`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });


}

export default EmailDAO;
