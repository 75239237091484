import React, { Component } from "react";
import styled from "styled-components";

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    render() {
        const { post } = this.props;
        return (
            <Container>
                <Post_header>
                    <User_avatar src={require("../images/dashpostsalim.png")} />
                    <Post_infos>
                        <User_name>{post && post.created_on && post.poster_app_tenant_name}</User_name>
                        <Post_date>{post && post.created_on && post.created_on}</Post_date>
                    </Post_infos>
                    {/* <Post_type>{post && post.status && post.status}</Post_type> */}
                </Post_header>
                <Post_content>
                    {/* <Post_link target="_blank" href={post && post.link && post.link}> */}
                    <Post_img src={`https://central.sobrus.com/uploads/ma/posts/${post && post.post_data && post.post_data.slice(14, post.post_data.length - 2)}`} />
                    {/* </Post_link> */}
                </Post_content>
                <Post_text>{post && post.content && post.content}</Post_text>
            </Container>
        );
    }
}
const Container = styled.div`
    width: 100%;
    display: block;
    position: relative;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: 3%;
    background: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    min-height: auto;
    padding: 10px;
    background: #fff;
    padding-bottom: 20px;
`;
const Post_text = styled.div`
    display: block;
    font-size: 14px;
    color: #494949;
    font-family: 'Open Sans', sans-serif;    font-weight: 400;
    text-align: left;
    margin-top: 10px;
    white-space: pre-wrap;
`;
// const Post_link = styled.a`
//     display: block;
// `;
const Post_header = styled.div``;
const Post_infos = styled.div`
   display:inline-block
   vertical-align:top;
   margin-top: 5px;
`;
const User_avatar = styled.img`
    display: inline-block !important;
    width: 52px !important;
    height: 54px !important;
    margin-right: 10px !important;
`;
const User_name = styled.h4`
font-family: 'Open Sans', sans-serif;    color: #494949;
    font-size: 13px;
    font-weight: 700;
`;
const Post_date = styled.p`
font-family: 'Open Sans', sans-serif;    font-weight: 400;
    text-align: left;
    margin-top: 5px !important;
    color: #828282 !important;
    font-size: 12px !important;
`;
// const Post_type = styled.span`
//     display: inline-block;
//     vertical-align: top;
//     height: 28px;
//     position: absolute;
//     right: 0;
//     top: 12px;
//     border-radius: 4px 0 0 4px;
//     background-color: #64b8d0;
//     font-family: open Sans;
//     font-size: 13px;
//     text-align: center;
//     display: inline-block;
//     padding-left: 14px;
//     padding-right: 14px;
//     color: #fff;
//     padding-top: 4px;
// `;
const Post_content = styled.div`
    margin-top: 7px;
`;

const Post_img = styled.img`
    border-radius: 5px;
    margin-top: 10px;
    width: 100%;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    height: auto;
`;
export default Post;
