import ApiCall from "../services/ApiCall";

class ContactDAO {
    // for getting all contacts limited by page number

    static getALLContacts = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/contacts/" + pageNumber, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get contact details

    static getContactById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get("/api/contact/" + id, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add contact details

    static addContact = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/contact", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update contact details

    static updateContact = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/contact/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //archive Account

    static deleteContact = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/contact/disabled/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static getSearchData({ advancedSearch, search, first_name, last_name, phone, category, account_id, account_id_operator, first_name_operator, last_name_operator, phone_operator, category_operator }) {
        const data = {};
        if (account_id && account_id.length !== 0) {
            if (search) {
                data["compte_id"] = {
                    value: account_id,
                    op: "is",
                };
            } else if (advancedSearch && account_id_operator.length !== 0) {
                data["compte_id"] = {
                    value: account_id,
                    op: account_id_operator,
                };
            }
        }
        if (first_name && first_name.length !== 0) {
            if (search) {
                data["prenom"] = {
                    value: first_name,
                    op: "smart",
                };
            } else if (advancedSearch && first_name_operator.length !== 0) {
                data["prenom"] = {
                    value: first_name,
                    op: first_name_operator,
                };
            }
        }
        if (last_name && last_name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: last_name,
                    op: "smart",
                };
            } else if (advancedSearch && last_name_operator.length !== 0) {
                data["nom"] = {
                    value: last_name,
                    op: last_name_operator,
                };
            }
        }
        if (phone && phone.length !== 0) {
            if (search) {
                data["portable"] = {
                    value: phone,
                    op: "smart",
                };
            } else if (advancedSearch && phone_operator.length !== 0) {
                data["portable"] = {
                    value: phone,
                    op: phone_operator,
                };
            }
        }
        if (category && category.length !== 0) {
            if (search) {
                data["categorie"] = {
                    value: category,
                    op: "is",
                };
            } else if (advancedSearch && category_operator.length !== 0) {
                data["categorie"] = {
                    value: category,
                    op: category_operator,
                };
            }
        }
        return data;
    }
}

export default ContactDAO;
