import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpInput from "../components/PopUpInput";
import CustomColorInput from "../components/CustomColorInput";
import { DatePicker } from "../components/CustomDatePicker";

import { errorMessage, successMessage } from "../tmp/Messages";
import PopUpAccounts from "../components/PopUpAccounts";
import PopUpGlobal from "../components/PopUpGlobal.js";
import MultiSelectPopUp from "../components/MultiSelectPopUp";

import PopUpContact from "../components/PopUpContact.js";

import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import AccountDAO from "../DAO/AccountDAO";
import ResourceDAO from "../DAO/ResourceDAO";
import ContactDAO from "../DAO/ContactDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import CommonDAO from "../DAO/CommonDAO";
import { MessagesText } from "../values/MessagesText";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";
import ProductDAO from "../DAO/ProductDAO";
import UserDAO from "../DAO/UserDAO";
import SubRangesMultiSelectPopUp from "../components/SubRangesMultiSelectPopUp";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: 0,
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
    },
};
class AddEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relation_type: "Pharmacie",
            relation_type_error: false,
            account_type: "",
            account_type_error: false,
            resource: "",
            id_resource: "",
            error_resource: false,
            wording: "",
            error_wording: false,
            category: "",
            code: "",
            id_manager: "",
            error_manager: false,
            manager: "",
            ready: false,
            users: [],

            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            contacts: [],

            name: "",
            name_error: false,
            startDate: new Date(),
            startDate_error: false,
            endDate: new Date(),
            endDate_error: false,
            type: "Visite",
            desc: "",
            color: "",
            place: "",

            addRapport: false,

            event_id: "",
            visitors: [],
            visitor: "",
            visitor_id: "",
            visited_at: new Date(),
            doctor_present: "",
            channel: "Terrain",
            concurrent_actions: [],
            concurrent_action: "",
            concurrent_action_id: "",
            revisited_at: null,
            comment: "",
            event_goals: [],
            event_goal: "",
            event_goal_id: "",
            event_goal_error: false,
            concurrents: [],
            concurrent: [],
            concurrent_id: "",
            provider: [],
            provider_id: "",
            range_products: [],
            range_product: [],
            range_product_id: "",
            sub_range_products: [],
            sub_range_product: [],
            sub_range_product_id: "",

            account: "",
            accounts: [],
            id_account: "",
            account_error: false,
            accountItemsNumber: 0,

            contact: "",
            contacts: [],
            id_contact: "",
            contact_error: false,
            contactItemsNumber: 0,

            openPopUp: false,

            loading: false,
            account_loading: false,
            contact_loading: false,
            visitor_loading: false,
            concurrent_actions_loading: false,
            createContact: false,
            visitor_error: false,
            crevent: [],
        };
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        const id = this.props.match.params.id;
        const id_account = this.props.match.params.id_account;
        if (id) {
            this.get();
        }
        if (id_account) {
            this.getAccount(id_account);
        }
        // date adapting
        let date = new Date();
        if (date.getMinutes() <= 30) {
            date.setMinutes(30);
        } else {
            date.setHours(date.getHours() + 1, 0);
        }
        this.setState({ startDate: date }, () => {
            this.setState({ endDate: new Date(this.state.startDate.getTime() + 30 * 60000) });
        });
        // end date adapting
        this.getUsers();
        !id && this.getConnectedUserInfos();
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ manager: response.data.fullname, id_manager: response.data.id });
            this.setVisitor(response.data);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await CalenderDAO.getEventById(id, {});
            const event = response.data.event;
            new Date(event.date_debut).toString();
            this.setState({
                crevent: response.data.crevent,
                name: event.nom,
                startDate: new Date(Date.parse(event.date_debut)),
                endDate: new Date(Date.parse(event.date_fin)),
                type: event.type,
                desc: event.description,
                color: event.couleur,
                place: event.lieu,
                account: event.compte,
                contact: event.contact_nom,
                id_account: event.compte_id,
                id_contact: event.contact_id,
                visitor_id: event.gestionnaire_id,
                visitor: event.gestionnaire,
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getAccount(id) {
        try {
            const response = await AccountDAO.getAccountById(id, {});
            this.setAccount(response.data);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequire() {
        const { addRapport, startDate, endDate, name, desc, type, color, place, id_account, id_contact, visitor_id } = this.state;
        let reportIsVerified = true;
        if (addRapport) {
            reportIsVerified = this.verifyRequireEventRapport();
        }
        if (name && name.length !== 0 && startDate && startDate.length !== 0 && endDate && endDate.length !== 0 && id_account && id_account.length !== 0 && visitor_id.length !== 0 && reportIsVerified) {
            const data = {
                nom: name,
                couleur: color ? color : "#00b1d6",
                type: type && type,
                lieu: place && place,
                description: desc && desc,
                date_debut: moment(startDate).format("YYYY-MM-DD HH:mm"),
                date_fin: moment(endDate).format("YYYY-MM-DD HH:mm"),
                compte: id_account,
                contact: id_contact && id_contact,
                gestionnaire: visitor_id,
            };
            return data;
        } else {
            if (!name || (name && name.length) === 0) {
                this.setState({ name_error: true });
            }
            if (!startDate || (startDate && startDate.length === 0)) {
                this.setState({ startDate_error: true });
            }
            if (!endDate || (endDate && endDate.length === 0)) {
                this.setState({ endDate_error: true });
            }
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!visitor_id || (visitor_id && visitor_id.length === 0)) {
                this.setState({ visitor_error: true });
            }
            // if (id_contact.length == 0) {
            //     this.setState({ contact_error: true });
            // }
            return null;
        }
    }
    async add() {
        try {
            const { addRapport } = this.state;

            const data = this.verifyRequire();

            if (data) {
                this.setState({ loading: true });
                const response = await CalenderDAO.addEvent({}, data);

                this.setState({ loading: false });
                if (response.data.status == "ok") {
                    if (addRapport) {
                        this.addEventRapport(response.data.event.id);
                    } else {
                        notify({ msg: MessagesText.event.add, type: "success" });
                        this.props.history.push(`${RoutesPath.calender.details}/${response.data.event.id}`);
                    }
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async updateEvent() {
        try {
            const { addRapport } = this.state;

            const id = this.props.match.params.id;
            const data = this.verifyRequire();
            if (data) {
                this.setState({ loading: true });
                const response = await CalenderDAO.updateEvent(id, {}, data);

                this.setState({ loading: false });
                // if (response.data.status == "ok") {
                //     this.props.history.push(`${RoutesPath.calender.details}/${id}`, { successMessage: MessagesText.event.update });
                // }
                if (response.data.status == "ok") {
                    if (addRapport) {
                        this.addEventRapport(response.data.event.id);
                    } else {
                        notify({ msg: MessagesText.event.update, type: "success" });
                        this.props.history.push(`${RoutesPath.calender.details}/${id}`);
                    }
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    async getContacts(data, pageNumber = 1) {
        const { id_account } = this.state;
        try {
            this.setState({ contact_loading: true });
            const fileterData = {
                ...data,
                disabled: {
                    value: 0,
                    op: "is",
                },
            };
            const response = await AccountDAO.getContactsByAccountId(pageNumber, id_account, {}, fileterData);
            this.setState({ contact_loading: false });

            this.setState({ contacts: response.data.contacts, contactItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ contact_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setVisitor(value) {
        this.setState({ visitor: value.fullname, visitor_id: value.id });
    }
    resetVisitor() {
        this.setState({ visitor: "", visitor_id: "" });
    }

    async getVisitors(data, pageNumber = 1) {
        try {
            this.setState({ visitor_loading: true });
            const response = await CommonDAO.getAllVisitors(pageNumber, {}, data);
            this.setState({ visitor_loading: false });

            this.setState({ visitors: response.data.utilisateurs });
        } catch (error) {
            this.setState({ visitor_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setConcurrent_action(value) {
        this.setState({ concurrent_action: value.nom, concurrent_action_id: value.id });
    }

    resetConcurrent_action() {
        this.setState({ concurrent_action: "", concurrent_action_id: "" });
    }

    async getConcurrent_actions(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            this.setState({ concurrent_actions_loading: true });
            const response = await CommonDAO.getAllConcurrentActions(pageNumber, keyWord, {});
            this.setState({ concurrent_actions_loading: false });
            this.setState({ concurrent_actions: response.data.ressources });
        } catch (error) {
            this.setState({ concurrent_actions_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setEvent_goal(value) {
        this.setState({ event_goal: value.nom, event_goal_id: value.id, event_goal_error: false });
    }
    resetEvent_goal() {
        this.setState({ event_goal: "", event_goal_id: "" });
    }

    async getEvent_goals(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            const response = await CommonDAO.getAllEventGoals(pageNumber, keyWord, {});
            this.setState({ event_goals: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    setConcurrent(array) {
        this.setState({ concurrent: array });
    }
    resetConcurrent() {
        this.setState({ concurrent: [] });
    }

    async getConcurrents(data, pageNumber = 1) {
        try {
            const keyWord = data.nom && data.nom.value != undefined ? data.nom.value : " ";
            const response = await CommonDAO.getAllConcurrent(pageNumber, keyWord, {});
            this.setState({ concurrents: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    setProvider(array) {
        // this.setState({ provider: value.nom, provider_id: value.id });
        this.setState({ provider: array });
    }

    resetProvider() {
        // this.setState({ provider: "", provider_id: "" });
        this.setState({ provider: [] });
    }

    setRange_product(array) {
        this.setState({ range_product: array });
    }
    resetRange_product() {
        this.setState({ range_product: [] });
    }

    async getRange_products(data, pageNumber = 1) {
        try {
            let formatData = {
                orderby: {
                    col: "id",
                    dir: "DESC",
                },
            };
            if (data.nom && data.nom.value) {
                formatData["nom"] = {
                    value: data.nom.value,
                    op: "smart",
                };
            }
            let response = await ProductDAO.getActiveRanges(pageNumber, {}, formatData);
            if (response.data.ressources.length === 0) {
                const keyWord = data.nom && data.nom.value != undefined ? data.nom.value : " ";
                response = await CommonDAO.getAllProductRanges(pageNumber, keyWord, {});
            }
            this.setState({ range_products: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    setSub_range_product(array) {
        this.setState({ sub_range_product: array, });
    }
    resetSub_range_product() {
        this.setState({ sub_range_product: [], });
    }

    async getSub_range_products(data, pageNumber = 1) {

        try {

            const { range_product } = this.state;
            // const keyWord = data.value != undefined ? data.value : " ";
            let formatedData = { ...data, gammes: [...range_product.map(obj => obj.id)] }
            const response = await CommonDAO.getSubRangesByRanges(pageNumber, {}, formatedData);
            this.setState({ sub_range_products: response.data });

        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    verifyRequireEventRapport(event_id = 0) {
        const {
            visitor_id,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action_id,
            revisited_at,
            comment,
            event_goal_id,
            event_goals,
            concurrent_id,
            concurrent,
            provider_id,
            provider,
            range_product_id,
            range_products,
            range_product,
            sub_range_product_id,
            sub_range_product,
            sub_range_products,
        } = this.state;
        let data;
        if (event_goal_id && event_goal_id.length !== 0 && visitor_id && visitor_id.length !== 0) {

            let formatSubRanges = []
            range_product.forEach((obj, index) => {
                formatSubRanges.push([...sub_range_product.filter((item, index) => parseInt(item.rangeId) === parseInt(obj.id)).map((item) => item.id)])
            })

            data = {
                visite_le: moment(visited_at).format("YYYY-MM-DD HH:mm"),
                docteur_present: doctor_present,
                canal: channel,
                action_concurrence: concurrent_action_id,
                a_revisiter_le: revisited_at,
                commentaire: comment,
                objectif: event_goal_id,
                concurrents: concurrent && concurrent.map((item) => item.id),
                // fournisseurs: [{ id: provider_id, name: provider }],
                fournisseurs: provider.map((item) => ({ id: item.id, name: item.label })),

                gammes: range_product && range_product.map((item) => item.id),
                sous_gammes: formatSubRanges,
                gestionnaire: visitor_id,
            };

        } else {
            if (!event_goal_id || (event_goal_id && event_goal_id.length === 0)) {
                this.setState({ event_goal_error: true });
            }
            if (!visitor_id || (visitor_id && visitor_id.length === 0)) {
                this.setState({ visitor_error: true });
            }
        }

        return data;
    }
    async addEventRapport(event_id) {
        try {
            const data = this.verifyRequireEventRapport(event_id);
            const response = await CalenderDAO.updateCrEvent(event_id, {}, data);

            if (response.data.status == "ok") {
                const id = this.props.match.params.id;
                notify({ msg: id ? MessagesText.event.update : MessagesText.event.add, type: "success" });
                this.props.history.push(`${RoutesPath.calender.details}/${event_id}`);
            } else {
                notify({ msg: response.data.msg, type: "danger" });
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ account: value.libelle, id_account: value.id });
        this.closePopUp();
    }

    setAccount(value) {
        const { name } = this.state;
        this.getResource(value.id_ressource, value.type_relation);
        const oldAccount = this.state.account;
        this.setState({ account: `${value.libelle} ${value.ville ? (!value.libelle.endsWith(`(${value.ville})`) ? `(${value.ville})` : "") : ""}`, id_account: value.id, account_error: false }, () => {
            if (name.length === 0 || (name.length !== 0 && name === `RDV avec ${oldAccount}`)) {
                this.setState({ name: `RDV avec ${value.libelle} ${value.ville ? (!value.libelle.endsWith(`(${value.ville})`) ? `(${value.ville})` : "") : ""}`, name_error: false });
            }
        });
    }
    async getResource(id, relation_type) {
        try {
            let type = "";
            if (relation_type === "Pharmacie") {
                type = "pharmacie";
            } else if (relation_type === "Etablissement de santé") {
                type = "etablissement";
            } else if (relation_type === "Association") {
                type = "association";
            }
            const params = {};
            const response = await ResourceDAO.getResourceById(type, id, params);
            this.setState({ place: response.data[type].adresse });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }
    setContact(value) {
        this.setState({ contact: `${value["fullname"]} (${value["compte"] ? value["compte"] : ""})`, id_contact: value.id, contact_error: false });
    }
    resetContact() {
        this.setState({ contact: "", id_contact: "" });
    }

    async getAccounts(data, pageNumber = 1) {
        try {
            this.setState({ account_loading: true });
            const dataFilter = {
                ...data,
                disabled: {
                    value: 0,
                    op: "is",
                },
            };
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, dataFilter);
            this.setState({ account_loading: false });

            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ account_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const {
            desc,
            place,
            contact_error,
            account_error,
            name_error,
            contact,
            contactItemsNumber,
            accountItemsNumber,
            account,
            type,
            errorMessage,
            code,
            accounts,
            contacts,
            addRapport,
            visitor,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action,
            revisited_at,
            comment,
            event_goal,
            event_goals,
            concurrent,
            concurrents,
            provider,
            range_product,
            range_products,
            sub_range_product,
            sub_range_products,
            name,
            color,
            loading,
            account_loading,
            contact_loading,
            visitor_loading,
            concurrent_actions_loading,
            event_goal_error,
            crevent,
            range_product_id,
            visitor_error,
            error_manager,
            id_manager,
            users,
        } = this.state;
        const event_id = this.props.match.params.id;
        const title = event_id ? `Modifier événement : ${name}` : "Créer un événement compte";
        const subTitle = event_id ? `Modifier événement` : "Créer un événement";
        return (
            <Main_container className="Main_container">
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    {(!event_id || (event_id && crevent.length === 0)) && (
                        <button className={addRapport ? "btn_red" : "btn_white"} data-shortcut_alias="create" onClick={() => this.setState({ addRapport: addRapport ? false : true })}>
                            {addRapport ? "Sans Compte-rendu" : "Rédiger Compte-rendu"}
                        </button>
                    )}
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (event_id ? this.updateEvent() : this.add())}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{subTitle}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                {/* <CustomSelect
                                    error={error_manager}
                                    onChange={(value) => {
                                        this.setState({ id_manager: value.target.value, error_manager: false });
                                        this.setVisitor({ visitor: "", visitor_id: value.target.value });
                                    }}
                                    selectedValue={id_manager}
                                    label="Gestionnaire *"
                                >
                                    <option defaultValue></option>
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key1_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect> */}
                                <PopUpGlobal
                                    error={visitor_error}
                                    label="Gestionnaire*"
                                    setValue={this.setVisitor.bind(this)}
                                    reset={this.resetVisitor.bind(this)}
                                    defaultValue={visitor}
                                    value={visitor}
                                    data={visitors}
                                    propertyName={"fullname"}
                                    searchData={this.getVisitors.bind(this)}
                                    getData={() => this.getVisitors({})}
                                    loading={visitor_loading}
                                    modalTitle="Liste des gestionnaires"
                                />
                            </InputContainer>

                            <InputContainer>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date,
                                            startDate_error: false,
                                        });
                                    }}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </InputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    name="account"
                                    label={"Compte*"}
                                    setValue={this.setAccount.bind(this)}
                                    reset={this.resetAccount.bind(this)}
                                    value={account}
                                    data={accounts}
                                    propertyName={"libelle"}
                                    searchData={this.getAccounts.bind(this)}
                                    itemsNumber={accountItemsNumber}
                                    error={account_error}
                                    getData={() => this.getAccounts()}
                                    modalTitle="Choisissez un compte"
                                    loading={account_loading}
                                />
                            </InputContainer>

                            <InputContainer>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ place: value.target.value });
                                    }}
                                    defaultValue={place}
                                    value={place}
                                    id="place"
                                    label="Lieu d'événement"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomColorInput
                                    selected={color}
                                    onChange={(color) => {
                                        this.setState({ color: color.hex });
                                    }}
                                ></CustomColorInput>
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ type: value.target.value });
                                    }}
                                    selectedValue={type}
                                    label="Type"
                                >
                                    <option value="Visite" defaultValue>
                                        Visite
                                    </option>
                                    <option value="Formation interne ou Réunion">Formation interne ou Réunion</option>
                                    <option value="Absence">Absence</option>
                                    <option value="Congés">Congés</option>
                                    <option value="Évènement et congrès">Évènement et congrès</option>
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <DatePicker
                                    selected={this.state.endDate}
                                    onChange={(date) => {
                                        this.setState({
                                            endDate: date,
                                            endDate_error: false,
                                        });
                                    }}
                                    label="Date de fin*"
                                    id="end_date_id"
                                    minDate={this.state.startDate}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={name_error}
                                    onChange={(value) => {
                                        this.setState({ name: value.target.value, name_error: false });
                                    }}
                                    defaultValue={name}
                                    value={name}
                                    id="name"
                                    label="Nom d'événement*"
                                ></CustomInput>
                            </InputContainer>
                            {account.length != 0 && (
                                <InputContainer>
                                    <PopUpContact account={account} idAccount={this.state.id_account} setValue={this.setContact.bind(this)} reset={this.resetContact.bind(this)} value={contact} searchData={this.getContacts.bind(this)} error={contact_error} />
                                </InputContainer>
                            )}

                            <InputContainer height={"120px"}>
                                <CustomInput
                                    type="textarea"
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ desc: value.target.value });
                                    }}
                                    defaultValue={desc}
                                    value={desc}
                                    id="desc"
                                    label="Description"
                                ></CustomInput>
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>

                {addRapport && (
                    <Bloc className="Bloc">
                        <div className="bloc_header">
                            <h2>Rapport d'événement</h2>
                            <div className="bloc_header_btns"></div>
                        </div>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <PopUpGlobal
                                        error={visitor_error}
                                        label="Gestionnaire*"
                                        setValue={this.setVisitor.bind(this)}
                                        reset={this.resetVisitor.bind(this)}
                                        defaultValue={visitor}
                                        value={visitor}
                                        data={visitors}
                                        propertyName={"fullname"}
                                        searchData={this.getVisitors.bind(this)}
                                        getData={() => this.getVisitors({})}
                                        loading={visitor_loading}
                                        modalTitle="Liste des gestionnaires"
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <DatePicker
                                        selected={visited_at}
                                        onChange={(date) => {
                                            this.setState({
                                                visited_at: date,
                                            });
                                        }}
                                        label="Visiter le"
                                        id="visited_at_id"
                                    />
                                </InputContainer>
                                <MultiSelectInputContainer>
                                    {/* <PopUpGlobal
                                        label="Concurrent"
                                        setValue={this.setConcurrent.bind(this)}
                                        reset={this.resetConcurrent.bind(this)}
                                        defaultValue={concurrent}
                                        value={concurrent}
                                        data={concurrents}
                                        propertyName={"nom"}
                                        searchData={this.getConcurrents.bind(this)}
                                        getData={() => this.getConcurrents({})}
                                        modalTitle="Liste des concurrents principaux"
                                    /> */}
                                    <MultiSelectPopUp
                                        maxItems={5}
                                        maxItemsWarningMessage="Vous avez atteint le maximum des concurrent (5) pour ce compte-rendu"
                                        label="Concurrent"
                                        setValue={this.setConcurrent.bind(this)}
                                        reset={this.resetConcurrent.bind(this)}
                                        defaultValue={concurrent}
                                        value={concurrent}
                                        data={concurrents}
                                        propertyName={"nom"}
                                        searchData={this.getConcurrents.bind(this)}
                                        getData={() => this.getConcurrents({})}
                                        modalTitle="Liste des concurrents principaux"
                                    />
                                </MultiSelectInputContainer>
                                <MultiSelectInputContainer>
                                    {/* <PopUpGlobal
                                        label="Gamme produit"
                                        setValue={this.setRange_product.bind(this)}
                                        reset={this.resetRange_product.bind(this)}
                                        defaultValue={range_product}
                                        value={range_product}
                                        data={range_products}
                                        propertyName={"nom"}
                                        searchData={(data) => this.getRange_products(data)}
                                        getData={() => this.getRange_products({})}
                                        modalTitle="Liste des gammes de produit"
                                    /> */}
                                    <MultiSelectPopUp
                                        maxItems={5}
                                        maxItemsWarningMessage="Vous avez atteint le maximum des gammes (5) pour ce compte-rendu"
                                        label="Gamme produit"
                                        setValue={this.setRange_product.bind(this)}
                                        reset={this.resetRange_product.bind(this)}
                                        defaultValue={range_product}
                                        value={range_product}
                                        data={range_products}
                                        propertyName={"nom"}
                                        searchData={(data) => this.getRange_products(data)}
                                        getData={() => this.getRange_products({})}
                                        modalTitle="Liste des gammes de produit"
                                    />
                                </MultiSelectInputContainer>
                                <InputContainer>
                                    <CustomSelect
                                        onChange={(value) => {
                                            this.setState({ doctor_present: value.target.value });
                                        }}
                                        selectedValue={doctor_present}
                                        label="Docteur était présent"
                                    >
                                        <option defaultValue value=""></option>
                                        <option value="1">Oui</option>
                                        <option value="0">Non</option>
                                    </CustomSelect>
                                </InputContainer>
                                <InputContainer height={"120px"}>
                                    <CustomInput
                                        type="textarea"
                                        error={false}
                                        onChange={(value) => {
                                            this.setState({ comment: value.target.value });
                                        }}
                                        defaultValue={comment}
                                        value={comment}
                                        id="comment"
                                        label="Commentaire"
                                    />
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <PopUpGlobal
                                        label="Objectif d'événement*"
                                        setValue={this.setEvent_goal.bind(this)}
                                        reset={this.resetEvent_goal.bind(this)}
                                        defaultValue={event_goal}
                                        value={event_goal}
                                        data={event_goals}
                                        propertyName={"nom"}
                                        searchData={this.getEvent_goals.bind(this)}
                                        getData={() => this.getEvent_goals({})}
                                        error={event_goal_error}
                                        modalTitle="Liste des objectifs d’événement"
                                    />
                                </InputContainer>

                                <InputContainer>
                                    <CustomSelect
                                        onChange={(value) => {
                                            this.setState({ channel: value.target.value });
                                        }}
                                        selectedValue={channel}
                                        label="Canal"
                                    >
                                        <option defaultValue value="Terrain">Terrain</option>
                                        <option value="Téléphone">Téléphone</option>
                                        <option value="E-mail">E-mail</option>
                                    </CustomSelect>
                                </InputContainer>
                                <MultiSelectInputContainer>
                                    {/* <PopUpGlobal
                                        label="Fournisseurs"
                                        setValue={this.setProvider.bind(this)}
                                        reset={this.resetProvider.bind(this)}
                                        defaultValue={provider}
                                        value={provider}
                                        data={concurrents}
                                        propertyName={"nom"}
                                        searchData={this.getConcurrents.bind(this)}
                                        getData={() => this.getConcurrents({})}
                                        modalTitle="Liste des fournisseurs principaux"
                                    /> */}

                                    <MultiSelectPopUp
                                        maxItems={5}
                                        maxItemsWarningMessage="Vous avez atteint le maximum des fournisseurs (5) pour ce compte-rendu"
                                        label="Fournisseurs"
                                        setValue={this.setProvider.bind(this)}
                                        reset={this.resetProvider.bind(this)}
                                        defaultValue={provider}
                                        value={provider}
                                        data={concurrents}
                                        propertyName={"nom"}
                                        searchData={this.getConcurrents.bind(this)}
                                        getData={() => this.getConcurrents({})}
                                        modalTitle="Liste des fournisseurs principaux"
                                    />
                                </MultiSelectInputContainer>
                                <InputContainer>
                                    <PopUpGlobal
                                        label="Action de la concurrence"
                                        setValue={this.setConcurrent_action.bind(this)}
                                        reset={this.resetConcurrent_action.bind(this)}
                                        defaultValue={concurrent_action}
                                        value={concurrent_action}
                                        data={concurrent_actions}
                                        propertyName={"nom"}
                                        searchData={this.getConcurrent_actions.bind(this)}
                                        getData={() => this.getConcurrent_actions({})}
                                        loading={concurrent_actions_loading}
                                        modalTitle="Liste des actions de la concurrence"
                                    />
                                </InputContainer>
                                {range_product && range_product.length != 0 && (
                                    <InputContainer>
                                        {/* <PopUpGlobal
                                            label="Sous-gamme produit"
                                            setValue={this.setSub_range_product.bind(this)}
                                            reset={this.resetSub_range_product.bind(this)}
                                            defaultValue={sub_range_product}
                                            value={sub_range_product}
                                            data={sub_range_products}
                                            propertyName={"nom"}
                                            searchData={this.getSub_range_products.bind(this)}
                                            getData={() => this.getSub_range_products()}
                                            modalTitle="Liste des sous-gammes de produit"
                                        /> */}
                                        <SubRangesMultiSelectPopUp
                                            maxItems={5}
                                            maxItemsWarningMessage="Vous avez atteint le maximum des sous-gammes (5) pour ce compte-rendu"
                                            label="Sous-gamme produit"
                                            setValue={this.setSub_range_product.bind(this)}
                                            reset={this.resetSub_range_product.bind(this)}
                                            defaultValue={sub_range_product}
                                            value={sub_range_product}
                                            ranges={range_product}
                                            data={sub_range_products}
                                            propertyName={"nom"}
                                            searchData={this.getSub_range_products.bind(this)}
                                            getData={() => this.getSub_range_products()}
                                            modalTitle="Liste des sous-gammes de produit"
                                        />
                                    </InputContainer>
                                )}
                                <InputContainer>
                                    <DatePicker
                                        selected={revisited_at}
                                        onChange={(date) => {
                                            this.setState({
                                                revisited_at: date,
                                            });
                                        }}
                                        label="A revisiter le"
                                        id="revisited_at_id"
                                    />
                                </InputContainer>
                            </Right>
                        </FormContainer>
                    </Bloc>
                )}
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Right_input_container_ex = styled.div`
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const MultiSelectInputContainer = styled.div`
    width: 100%;
    min-height: 41px;
    margin-bottom: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddEventPage);
