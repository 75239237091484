import React, { Component } from "react";
import styled from "styled-components";
import AclDAO from "../DAO/AclDAO";
import { connect } from "react-redux";
import TableModel from "../components/TableModel";
const thead = [
    { property: "libelle", label: "Méthodes", type: "string" },
    { property: "ressource", label: "Ressource", type: "string" },
    { property: "action", label: "Action", type: "string" },
    { property: "route", label: "Route", type: "string" },
];

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
class ACLMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [] });
        this.getAll();
    }
    async getAll() {
        try {
            const params = {};
            const response = await AclDAO.getAllMethods(params);
            this.setState({ is_loaded: true, data: response.data });
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }
    render() {
        const { is_loaded, data } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>ACL > Méthodes</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>cette page contient la liste de tous les ressources.</Block_content_title>
                    </Block_content>
                </First_block>
                <TableModel
                    title="Méthodes"
                    onReload={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => {},
                        actions: [
                            // { title: "modifier",onClick:(id)=>{} },
                            // { title: "archiver",onClick:(id)=>{} }
                        ],
                    }}
                    {...this.props}
                />
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default connect(mapStateToProps)(ACLMethods);

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
