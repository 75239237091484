import ApiCall from "../services/ApiCall";
import moment from "moment";

class InventoryDAO {
    // for getting all accounts limited by page number

    static getALLInventories = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/inventaires/" + pageNumber, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add inventory

    static addInventory = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/inventaire", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update inventory

    static updateInventory = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/inventaire/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get inventory details

    static getInventoryById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`/api/inventaire/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //  get panel product linked to inventory

    static getInventoryPanel = (id, pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/inventaire/panierinventaire/${id}/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //  get panel product linked to inventory

    static addProductToInventory = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/inventaire/insert/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //  get panel product linked to inventory

    static updateInventoryStatus = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/inventaire/statut/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static getAccountInventory = (id_account, pageNumber = 1, params = {}, data = {}, limit = 20) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/compte/inventaires/${id_account}/${pageNumber}/${limit}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static deleteProductFromPanel = (detailPanelId, pageNumber = 1, params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.delete(`/api/inventaire/delete/${detailPanelId}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static getSearchData({ search, advancedSearch, account_type, date, created_by, created_at, status, account_id, status_operator, created_at_operator, created_by_operator, date_operator, account_id_operator }) {
        const data = {};
        if (account_id && account_id.length !== 0) {
            if (search) {
                data["compte_id"] = {
                    value: account_id,
                    op: "is",
                };
            } else if (advancedSearch && account_id_operator.length !== 0) {
                data["compte_id"] = {
                    value: account_id,
                    op: account_id_operator,
                };
            }
        }
        if (date && date.length !== 0) {
            if (search) {
                data["date"] = {
                    value: moment(date).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && date_operator.length !== 0) {
                data["date"] = {
                    value: moment(date).format("YYYY-MM-DD"),
                    op: date_operator,
                };
            }
        }
        if (created_by && created_by.length !== 0) {
            if (search) {
                data["cree_par"] = {
                    value: created_by,
                    op: "is",
                };
            } else if (advancedSearch && created_by_operator.length !== 0) {
                data["cree_par"] = {
                    value: created_by,
                    op: created_by_operator,
                };
            }
        }
        if (created_at && created_at.length !== 0) {
            if (search) {
                data["cree_le"] = {
                    value: moment(created_at).format("YYYY-MM-DD HH:mm"),
                    op: "is",
                };
            } else if (advancedSearch && created_at_operator.length !== 0) {
                data["cree_le"] = {
                    value: moment(created_at).format("YYYY-MM-DD HH:mm"),
                    op: created_at_operator,
                };
            }
        }
        if (status && status.length !== 0) {
            if (search) {
                data["statut"] = {
                    value: status,
                    op: "is",
                };
            } else if (advancedSearch && status_operator.length !== 0) {
                data["statut"] = {
                    value: status,
                    op: status_operator,
                };
            }
        }
        return data;
    }
}

export default InventoryDAO;
