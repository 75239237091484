import React, { Component } from "react";

import styled from "styled-components";
import PlanningDAO from "../../DAO/PlanningDAO";
import CommonDAO from "../../DAO/CommonDAO";
import CustomInput from "../CustomInput";
import SearchSelect from "../../helpers/SearchSelect";

import TableModel from "../TableModel";
import { RoutesPath } from "../../values/RoutesPath";
import { AdvancedSearchComp, BasicSearchComp } from "./Search.Comp";
import { DatePicker } from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect";
import { acl } from "../../services/acl";
import TopOptions from "../TopOptions";
import { notify } from "../../helpers/FlashMessage";
import { MessagesText } from "../../values/MessagesText";
import NoResultCas from "../../helpers/NoResultCas";

const thead = [
    { property: "nom", label: "Nom", type: "string" },
    { property: "date_debut", label: "Date début", type: "date", format: "YYYY-MM-DD" },
    { property: "date_fin", label: "Date fin", type: "date", format: "YYYY-MM-DD" },
    { property: "gestionnaire", label: "Gestionnaire", type: "string" },
];

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            data: [],
            searchType: "simple",
            searchToggle: false,
            is_loaded: false,
            searchFields: {
                name: { value: "", operator: "" },
                start_date: { value: null, operator: "" },
                end_date: { value: null, operator: "" },
                manager: { value: "", operator: "" },
            },
            error: {
                is: false,
                message: "",
            },
            selected_user: null,
            disabledData: 0,
        };
    }

    componentDidMount() {
        this.setState({ is_loaded: false }, () => this.getAll());
    }

    async getAll() {
        try {
            const { searchFields, searchType, searchToggle, pageNumber, disabledData } = this.state;
            let data = PlanningDAO.getSearchData({ searchFields, searchType, searchToggle });
            data["disabled"] = {
                value: disabledData,
                op: "is",
            };

            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            if (data) {
                const response = await PlanningDAO.getALLPlannings(pageNumber, {}, data);

                this.setState({ is_loaded: true, data: response.data.plannings, totalItems: response.data.total });
            }
        } catch (error) {
            this.setState((prevState) => ({
                ...prevState,
                is_loaded: true,
                error: {
                    ...prevState.error,
                    is: true,
                    message: error.message,
                },
            }));
        }
    }

    sort(property) {
        const sortedData = CommonDAO.sortData(this.state.data.photos, property);
        const data = this.state.data;
        data.photos = [...sortedData];
        this.setState({ data });
    }

    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.photos, property);
        const data = this.state.data;
        data.photos = [...sortedData];
        this.setState({ data });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }

    setSearchFields(field, property, value) {
        this.setState((prevState) => ({
            ...prevState,
            searchFields: {
                ...prevState.searchFields,
                [field]: {
                    ...prevState.searchFields[field],
                    [property]: value,
                },
            },
        }));
    }

    searchToggle(searchTypeParam) {
        const { searchToggle, searchType } = this.state;
        this.setState({ searchType: searchTypeParam, searchToggle: searchToggle && searchType === searchTypeParam ? false : true });
    }
    resetSearch() {
        this.setState(
            {
                searchFields: {
                    name: { value: "", operator: "" },
                    start_date: { value: null, operator: "" },
                    end_date: { value: null, operator: "" },
                    manager: { value: "", operator: "" },
                },
                selected_user: null,
            },
            () => this.getAll()
        );
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await PlanningDAO.deletePlanning(id, {}, data);

                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.delete, type: "success" });
                    this.getAll();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await PlanningDAO.deletePlanning(id, {}, data);

                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.restore, type: "success" });
                    this.getAll();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { is_loaded, searchType, data, pageNumber, totalItems, searchToggle, searchFields, disabledData } = this.state;

        return (
            <div>
                <TopOptions title="Planning">
                    <button
                        className="btn_white"
                        data-shortcut_alias="create"
                        onClick={() => {
                            this.setState({ disabledData: disabledData === 1 ? 0 : 1 }, () => this.getAll());
                        }}
                    >
                        {disabledData === 0 ? " Archives" : "Actuel"}
                    </button>
                    {disabledData === 0 && acl.planning.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.planning.add}`)}>
                            Créer
                        </button>
                    )}
                </TopOptions>

                <Container>
                    <TableModel
                        title="Planning"
                        onReload={() => this.resetSearch()}
                        BasicSearchComponent={this.basicSearchComp()}
                        AdvancedSearchComponent={this.advancedSearch()}
                        thead={thead}
                        tbody={{
                            dataIsLoaded: is_loaded,
                            data: data,
                            onClickRow: (id) => acl.planning.details() && this.props.history.push(`${RoutesPath.planning.details}/${id}`),
                            actions: [
                                disabledData === 0 && acl.planning.update() && { title: "modifier", onClick: (id) => this.props.history.push(`${RoutesPath.planning.update}/${id}`) },
                                disabledData === 0 && acl.planning.delete() && { title: "archiver", onClick: (id) => this.delete(id) },
                                disabledData === 1 && acl.planning.restore() && { title: "restore", onClick: (id) => this.restore(id) },

                                // { title: "archiver", onClick: (id) => {} },
                            ],
                        }}
                        searchToggle={searchToggle}
                        searchType={searchType}
                        searchToggleFunc={this.searchToggle.bind(this)}
                        tfoot={{
                            colSpan: 8,
                            pageNumber: pageNumber,
                            totalItems: totalItems,
                            nextPage: () => {
                                this.setState({ pageNumber: pageNumber + 1, is_loaded: false }, () => {
                                    this.getAll();
                                });
                            },
                            PrevPage: () => {
                                this.setState({ pageNumber: pageNumber - 1, is_loaded: false }, () => {
                                    this.getAll();
                                });
                            },
                        }}
                        NoResultCas={<NoResultCas
                            btnOnClick={() => this.props.history.push(`${RoutesPath.planning.add}`)}
                            btnText="Créer un planning"
                            textInfos="Vous n'avez aucun planning pour le moment"
                        ></NoResultCas>}
                        {...this.props}
                    />
                </Container>
            </div>
        );
    }

    basicSearchComp() {
        const { searchFields, searchType } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput onKeyDown={(e) => this.handleKeyDown(e)} onChange={(value) => this.setSearchFields("name", "value", value.target.value)} value={searchFields.name.value} defaultValue={searchFields.name.value} label="Nom"></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            error={false}
                            selected={searchFields.start_date.value}
                            onChange={(date) => {
                                this.setSearchFields("start_date", "value", date);
                            }}
                            label="Date de début*"
                            id="start_date_id"
                            dateFormat="yyyy-MM-dd"
                            showTimeSelect={false}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            error={false}
                            selected={searchFields.end_date.value}
                            onChange={(date) => {
                                this.setSearchFields("end_date", "value", date);
                            }}
                            label="Date de fin*"
                            id="end_date_id"
                            dateFormat="yyyy-MM-dd"
                            showTimeSelect={false}
                        />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/utilisateurs"
                            HTTPMethod="post"
                            receivedObject="utilisateurs"
                            optionLabelProperty="fullname"
                            optionValueProperty="id"
                            value={this.state.selected_user}
                            placeholder="Gestinnaire"
                            onChange={(e) => {
                                this.setSearchFields("manager", "value", e && e.value);
                                this.setState({ selected_user: e });
                            }}
                        />
                    </Search_input_container>
                </Th>
                {searchType == "advanced" ? (
                    <Th backgroundColor="#fff"></Th>
                ) : (
                        <Th backgroundColor="#fff">
                            <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill="#fff"
                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                    ></path>
                                </Search_btn_icon>
                            </Search_btn>
                        </Th>
                    )}
            </tr>
        );
    }

    advancedSearch() {
        const { searchFields, searchType } = this.state;

        return (
            <SearchTr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect onKeyDown={() => { }} error={false} onChange={(value) => this.setSearchFields("name", "operator", value.target.value)} selectedValue={searchFields.name.operator} label="Nom" operatorsType="detailed" />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect onKeyDown={() => { }} error={false} onChange={(value) => this.setSearchFields("start_date", "operator", value.target.value)} selectedValue={searchFields.start_date.operator} label="Date de début*" operatorsType="short" />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect onKeyDown={() => { }} error={false} onChange={(value) => this.setSearchFields("end_date", "operator", value.target.value)} selectedValue={searchFields.end_date.operator} label="Date de fin*" operatorsType="short" />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect onKeyDown={() => { }} error={false} onChange={(value) => this.setSearchFields("manager", "operator", value.target.value)} selectedValue={searchFields.manager.operator} label="Gestinnaire" operatorsType="short" />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </SearchTr>
        );
    }
}

const Container = styled.div`
    width: 96.3%;
    margin: auto;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;

export default Table;
