import React from "react";
import "./DataLoadingError.css";

const DataLoadingError = ({ message }) => {
    return (
        <div className="DataLoadingError__container">
            <img className="DataLoadingError__img" src={require("../../images/error_salim.png")} alt="dataLoadingError" />
            {message}
        </div>
    );
};

export default DataLoadingError;
