import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import InventoryDAO from "../DAO/InventoryDAO";
import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "./seachStyledComponents";
import CustomSelect from "./CustomSelect";
import SearchSelect from "../helpers/SearchSelect";
import { DatePicker } from "./CustomDatePicker";
import { notify } from "../helpers/FlashMessage";
import { connect } from "react-redux";
import Acl, { acl } from "../services/acl";
import DemandDAO from "../DAO/DemandDAO";
import ContentLoader from "react-content-loader";
import CustomInput from "./CustomInput";

const PRIORITY_COLOR = {
    Normale: "#4674b9",
    Urgente: "#ffa902",
    Critique: "#E04052",
};
const STATUS_COLOR = {
    Ouvert: "#05b8c0",
    Résolu: "#00bf9c",
    Archivé: "#E04052",
};

const INITIAL_SEARCH_FIELDS = {
    demand_num: {
        value: "",
        operator: "",
    },
    object: {
        value: "",
        operator: "",
    },
    contact: {
        value: "",
        operator: "",
    },
    assignedTo: {
        value: "",
        operator: "",
    },

    lastActivity: {
        value: "",
        operator: "",
    },
    status: {
        value: "",
        operator: "",
    },
};

class DemandListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            date: null,
            created_by: "",
            selected_user: {},
            created_at: null,
            status: "",
            searchFiled: {
                ...INITIAL_SEARCH_FIELDS,
            },
            asyncSelect: {
                contact: null,
                assignedTo: null,
            },
        };
    }
    setSearchFieldValue = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            searchFiled: {
                ...prevState.searchFiled,
                [field]: {
                    ...prevState.searchFiled[field],
                    value: value,
                },
            },
        }));
    };
    setSearchAsyncSelect = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            asyncSelect: {
                ...prevState.asyncSelect,
                [field]: value,
            },
        }));
    };
    componentDidMount() {
        // const data = this.props.data;
        // if (data) {
        //     this.setState({ data });
        // }
        this.getAll();
    }
    getSearchData = () => {
        const { searchFiled, search } = this.state;
        const { contactId } = this.props;
        if (search) {

            let data = {};
            if (searchFiled.object.value && searchFiled.object.value.length != 0) {
                data["subject"] = {
                    value: searchFiled.object.value,
                    op: "smart",
                };
            }
            if (!contactId && searchFiled.contact.value && searchFiled.contact.value.length != 0) {
                data["contactId"] = {
                    value: searchFiled.contact.value,
                    op: "is",
                };
            }
            if (searchFiled.demand_num.value && searchFiled.demand_num.value.length != 0) {
                data["demandNumber"] = {
                    value: searchFiled.demand_num.value,
                    op: "is",
                };
            }

            if (searchFiled.assignedTo.value && searchFiled.assignedTo.value.length != 0) {
                data["assignedTo"] = {
                    value: searchFiled.assignedTo.value,
                    op: "is",
                };
            }

            if (searchFiled.lastActivity.value && searchFiled.lastActivity.value.length != 0) {
                data["lastActionAt"] = {
                    value: moment(searchFiled.lastActivity.value).format("YYYY-MM-DD"),
                    op: "is",
                };
            }
            if (searchFiled.status.value && searchFiled.status.value.length != 0) {
                data["lastStatus"] = {
                    value: searchFiled.status.value,
                    op: "is",
                };
            }

            return data;
        }
        return {};
    }
    getAll = async () => {
        try {
            console.log("inside getAll")
            const { pageNumber, search, date, created_by, created_at, status } = this.state;

            const { accountId, contactId } = this.props;

            let data = this.getSearchData();

            if (accountId) {
                data["accountId"] = {
                    value: accountId,
                    op: "is",
                };
            } else if (contactId) {
                data["contactId"] = {
                    value: contactId,
                    op: "is",
                };
            }

            console.log("searched  data", data)

            this.setState({ isLoaded: false });
            const response = await DemandDAO.getALL(this.state.pageNumber, {}, data, 5);
            console.log("accountId", response)
            this.setState({ isLoaded: true });
            this.setState({ data: response.data.response.demands ? response.data.response.demands : [], totalItems: response.data.response.total }, () => console.log("totalItems", this.state.totalItems));

        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }


    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1 }, () => this.getAll());
        }
    }

    render() {
        const { inventories, pageNumber, totalItems, search, data, isLoaded, searchFiled, asyncSelect } = this.state;
        const { noInventoryCas, contactId } = this.props;
        if (!search && data.length === 0) {
            return noInventoryCas;
        }

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Demandes</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.setState({ searchFiled: INITIAL_SEARCH_FIELDS, pageNumber: 1, asyncSelect: { assignedTo: null, contact: null, }, }, this.getAll)} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="table">
                        <thead>
                            {(search) && (<SearchTr className="table_th_orderable">
                                <Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <CustomInput
                                            minWidth="70px"
                                            searchIcon={true}
                                            value={searchFiled.demand_num.value}
                                            onKeyDown={this.handleKeyDown.bind(this)}
                                            onChange={(value) => {
                                                this.setSearchFieldValue("demand_num", value.target.value);
                                            }}
                                            label="N°"
                                        />
                                    </Search_input_container>
                                </Th>
                                <Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <CustomInput
                                            searchIcon={true}
                                            value={searchFiled.object.value}
                                            onKeyDown={this.handleKeyDown.bind(this)}
                                            onChange={(value) => {
                                                this.setSearchFieldValue("object", value.target.value);
                                            }}
                                            label="Objet"
                                        />
                                    </Search_input_container>
                                </Th>
                                {!contactId && (<Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <SearchSelect
                                            fetchedUrl="/api/contacts"
                                            HTTPMethod="post"
                                            receivedObject="contacts"
                                            optionLabelProperty="fullname"
                                            optionValueProperty="id"
                                            value={asyncSelect.contact}
                                            placeholder="contact"
                                            onChange={(e) => {
                                                this.setSearchAsyncSelect("contact", e);
                                                this.setSearchFieldValue("contact", e && e.value);
                                            }}
                                        />
                                    </Search_input_container>
                                </Th>)}
                                <Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <SearchSelect
                                            fetchedUrl="/api/utilisateurs"
                                            HTTPMethod="post"
                                            receivedObject="utilisateurs"
                                            optionLabelProperty="fullname"
                                            optionValueProperty="id"
                                            value={asyncSelect.assignedTo}
                                            placeholder="Assigné à"
                                            onChange={(e) => {
                                                this.setSearchAsyncSelect("assignedTo", e);
                                                this.setSearchFieldValue("assignedTo", e && e.value);
                                            }}
                                        />
                                    </Search_input_container>
                                </Th>
                                <Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <DatePicker
                                            error={false}
                                            selected={searchFiled.lastActivity.value}
                                            onChange={(date) => {
                                                this.setSearchFieldValue("lastActivity", date);
                                            }}
                                            label="Dernière activité"
                                            id="lastActivity"
                                            dateFormat="yyyy-MM-dd"
                                            showTimeSelect={false}
                                        />
                                    </Search_input_container>
                                </Th>
                                <Th backgroundColor="#fff">
                                    <Search_input_container>
                                        <CustomSelect
                                            minWidth="70px"
                                            onKeyDown={this.handleKeyDown.bind(this)}
                                            error={false}
                                            onChange={(value) => {
                                                this.setSearchFieldValue("status", value.target.value);
                                            }}
                                            selectedValue={searchFiled.status.value}
                                            label="Statut"
                                        >
                                            <option defaultValue></option>
                                            <option value="Ouvert">Ouvert</option>
                                            <option value="Résolu">Résolu</option>
                                            <option value="Archivé">Archivé</option>
                                        </CustomSelect>
                                    </Search_input_container>
                                </Th>
                                <Th backgroundColor="#fff">
                                    {search && (
                                        <Search_btn
                                            onClick={() => {
                                                const { search, advancedSearch, account_type, code, wording, category, city, sector, account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator } = this.state;
                                                const basic = {
                                                    account_type,
                                                    code,
                                                    wording,
                                                    category,
                                                    city,
                                                    sector,
                                                };
                                                const advanced = {
                                                    account_type_operator,
                                                    code_operator,
                                                    wording_operator,
                                                    category_operator,
                                                    city_operator,
                                                    sector_operator,
                                                };

                                                this.setState({ pageNumber: 1 }, this.getAll);
                                            }}
                                        >
                                            <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    fill="#fff"
                                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                ></path>
                                            </Search_btn_icon>
                                        </Search_btn>
                                    )}
                                </Th>
                            </SearchTr>)}
                            <tr className="Demands__table-tr table_th_orderable">
                                <Th>N° demande</Th>
                                <Th>Sujet</Th>
                                {!contactId && (<Th>Contact</Th>)}
                                <Th>Assigné à</Th>
                                <Th>Dernière activité</Th>
                                <Th>Statut</Th>
                                <Th></Th>
                            </tr>
                        </thead>
                        {!isLoaded && <Loading />}
                        {isLoaded && data && data.length != 0 && (
                            <tbody>
                                {data.map((item, index) => {
                                    return (
                                        <Tr key={"key_" + index}>
                                            <Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                {item && item.demandNumber}
                                            </Td>
                                            <Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                {item && item.subject}
                                            </Td>
                                            {!contactId && (<Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                {item && item.contactName}
                                            </Td>)}
                                            <Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                {item && item.assignedToName}
                                            </Td>
                                            <Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                {item && item.lastStatus && item.lastStatus.actionDate && moment(item.lastStatus.actionDate).format("YYYY-MM-DD HH:mm")}
                                            </Td>
                                            <Td
                                                onClick={() => {
                                                    acl.demand.details() && this.props.history.push(`${RoutesPath.demand.details}/${item.id}`);
                                                }}
                                            >
                                                <PriorityContainer bgColor={STATUS_COLOR[item && item.lastStatus && item.lastStatus.status]}> {item && item.lastStatus && item.lastStatus.status}</PriorityContainer>
                                            </Td>
                                            <Td>
                                            </Td>
                                        </Tr>
                                    );
                                })}

                                <Tr>
                                    <Td colSpan={8} className="table_pagination">
                                        <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                        <div style={{ display: "inline-block" }}>
                                            {pageNumber > 1 && (
                                                <a
                                                    className="sprite-table-prev-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                            <span className="table_page">{pageNumber}</span>
                                            {pageNumber < totalItems / (this.props.limitForPagination ? this.props.limitForPagination : 20) && (
                                                <a
                                                    className="sprite-table-next-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { date, selected_user, created_at, status } = this.state;

        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={date}
                            onChange={(date) => {
                                this.setState({
                                    date: date,
                                });
                            }}
                            label="Date"
                            id="date"
                            showTimeSelect={false}
                            dateFormat={"yyyy-MM-dd"}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/utilisateurs"
                            HTTPMethod="post"
                            receivedObject="utilisateurs"
                            optionLabelProperty="fullname"
                            optionValueProperty="id"
                            value={selected_user}
                            placeholder="Téléchargé par"
                            onChange={(e) => {
                                this.setState({ selected_user: e, created_by: e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={created_at}
                            onChange={(date) => {
                                this.setState({
                                    created_at: date,
                                });
                            }}
                            dateFormat="yyyy-MM-dd"
                            showTimeSelect={false}
                            label="Créé le"
                            id="Créé le"
                        />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect
                            onKeyDown={this.handleKeyDown.bind(this)}
                            error={false}
                            onChange={(value) => {
                                this.setState({ status: value.target.value });
                            }}
                            selectedValue={status}
                            label="Statut"
                        >
                            <option value="" defaultValue></option>
                            <option value="Brouillon">Brouillon</option>
                            <option value="Complété">Complété</option>
                            <option value="Annulé">Annulé</option>
                        </CustomSelect>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getAll())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
    max-width: 199px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const PriorityContainer = styled.strong`
    border-radius: 3px;
    background-color: ${(props) => props.bgColor};
    padding: 3px 12px 3px 12px;
    color: #fff;
    display: inline-block;
    min-width:70px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(DemandListTable);


const Loading = () => (
    <tbody>
        {[1, 2, 3].map((index) => {
            return (
                <Tr key={"key__" + index}>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                    <Td>
                        <ContentLoader width="160" height="20">
                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                        </ContentLoader>
                    </Td>
                </Tr>
            );
        })}
    </tbody>
);