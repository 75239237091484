import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import ReportDAO from "../../DAO/ReportDAO";
import { connect } from "react-redux";
import moment from "moment";
import SearchSelect from "../../helpers/SearchSelect";
import CommonFunctions from "../../helpers/CommonFunctions";
import UserDAO from "../../DAO/UserDAO";
import { DatePicker } from "../../components/CustomDatePicker";
import { notify } from "../../helpers/FlashMessage";

let options = {
    chart: {
        type: "pie",
    },
    title: {
        text: "",
    },
    colors: ["#28b9ac", "#1ab2d5", "#03c48a", "#eda224", "#700242"],
};

class VisitorByContactCategory extends Component {
    constructor(props) {
        super(props);
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.state = {
            filter_fields: {
                started_date: lastMonth,
                end_date: new Date(),
                visitor: { value: 0, label: "Tout" },
            },
            statistical_fields: {
                show_statistical: false,
                other: 0,
                A: 0,
                B: 0,
                C: 0,
                D: 0,
                E: 0,
                F: 0,
            },
            data: {
                visitors: [],
            },
            preload: {
                users: [],
            },
            accounts: [],
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        this.getVisitsByContact();
        // this.getConnectedUserInfos();
    }

    render() {
        const { filter_fields, statistical_fields, data, preload } = this.state;

        // after loading data and calc statistical
        options.series = [
            {
                data: [
                    ["Autre", statistical_fields.other],
                    ["A", statistical_fields.A],
                    ["B", statistical_fields.B],
                    ["C", statistical_fields.C],
                    ["D", statistical_fields.D],
                    ["E", statistical_fields.E],
                    ["F", statistical_fields.F],
                ],
            },
        ];

        return (
            <Main_container className="Main_container">
                <TopOptions title="Événements par visiteur/catégorie de contact"></TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Options des rapports</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <FullRow>
                                <SearchSelect
                                    name="report"
                                    fetchedUrl="/api/utilisateurs"
                                    HTTPMethod="post"
                                    receivedObject="utilisateurs"
                                    optionLabelProperty="fullname"
                                    optionValueProperty="id"
                                    value={filter_fields.visitor}
                                    placeholder="Sélectionner un visiteur"
                                    onChange={(e) => {
                                        if (e && e.value.length !== 0) {
                                            this.onSearchChange(e);
                                        }
                                    }}
                                />
                            </FullRow>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    selected={filter_fields.started_date}
                                    onChange={(date) => {
                                        this.setFieldValue("started_date", date, this.getVisitsByContact.bind(this));
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={filter_fields.end_date}
                                    onChange={(date) => {
                                        this.setFieldValue("end_date", date, this.getVisitsByContact.bind(this));
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de fin*"
                                    id="end_date_id"
                                />
                            </Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
                <Bloc2 className="Bloc">
                    <div className="bloc_header">
                        <h2>Événements par catégorie de contact</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">{statistical_fields.show_statistical && <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={options} />}</div>
                </Bloc2>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Événements par visiteur/catégorie de contact</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    <Th></Th>
                                    <Th>A</Th>
                                    <Th>B</Th>
                                    <Th>C</Th>
                                    <Th>D</Th>
                                    <Th>E</Th>
                                    <Th>F</Th>
                                    <Th>Autre</Th>
                                    <Th>Total</Th>
                                    <Th>Total (%)</Th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.visitors.length !== 0 &&
                                    data.visitors.map((object, index) => {
                                        const row_total = this.getRowTotal(object[""], object["A"], object["B"], object["C"], object["D"], object["E"], object["F"]);
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td>{object["fullname"]}</Td>
                                                <Td>{object["A"]}</Td>
                                                <Td>{object["B"]}</Td>
                                                <Td>{object["C"]}</Td>
                                                <Td>{object["D"]}</Td>
                                                <Td>{object["E"]}</Td>
                                                <Td>{object["F"]}</Td>
                                                <Td>{object[""]}</Td>
                                                <Td>{row_total}</Td>
                                                <Td>{`${this.getRowTotalPercent(row_total, statistical_fields.other + statistical_fields.A + statistical_fields.B + statistical_fields.C + statistical_fields.D + statistical_fields.E + statistical_fields.F)}%`}</Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td>{"Total"}</Td>
                                    <Td>{statistical_fields.A}</Td>
                                    <Td>{statistical_fields.B}</Td>
                                    <Td>{statistical_fields.C}</Td>
                                    <Td>{statistical_fields.D}</Td>
                                    <Td>{statistical_fields.E}</Td>
                                    <Td>{statistical_fields.F}</Td>
                                    <Td>{statistical_fields.other}</Td>
                                    <Td>{statistical_fields.other + statistical_fields.A + statistical_fields.B + statistical_fields.C + statistical_fields.D + statistical_fields.E + statistical_fields.F}</Td>
                                    <Td>{statistical_fields.other + statistical_fields.A + statistical_fields.B + statistical_fields.C + statistical_fields.D + statistical_fields.E + statistical_fields.F === 0 ? "0%" : `100%`}</Td>
                                </Tr>
                            </tbody>
                        </table>
                    </div>
                </Bloc>
            </Main_container>
        );
    }

    getRowTotal = (num_1, num_2, num_3, num_4, num_5, num_6, num_7) => {
        let tmp_num_1 = 0;
        let tmp_num_2 = 0;
        let tmp_num_3 = 0;
        let tmp_num_4 = 0;
        let tmp_num_5 = 0;
        let tmp_num_6 = 0;
        let tmp_num_7 = 0;

        if (num_1) {
            tmp_num_1 = parseInt(num_1);
        }
        if (num_2) {
            tmp_num_2 = parseInt(num_2);
        }
        if (num_3) {
            tmp_num_3 = parseInt(num_3);
        }
        if (num_4) {
            tmp_num_4 = parseInt(num_4);
        }
        if (num_5) {
            tmp_num_5 = parseInt(num_5);
        }
        if (num_6) {
            tmp_num_6 = parseInt(num_6);
        }
        if (num_7) {
            tmp_num_7 = parseInt(num_7);
        }
        return tmp_num_1 + tmp_num_2 + tmp_num_3 + tmp_num_4 + tmp_num_5 + tmp_num_6 + tmp_num_7;
    };

    getRowTotalPercent = (value, base) => {
        return base ? Number((value / base) * 100).toFixed(3) : 0;
    };

    formattedDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    setFieldValue = (fieldName, fieldValue, callBack = () => { }) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                filter_fields: {
                    ...prevState.filter_fields,
                    [fieldName]: fieldValue,
                },
            }),
            () => {
                if (this.state.filter_fields[fieldName] !== null) callBack();
            }
        );
    };

    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    filter_fields: {
                        ...prevState.filter_fields,
                        visitor: selectedOption,
                    },
                }),
                this.getVisitsByContact.bind(this)
            );
        }
    };

    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.onSearchChange({ value: response.data.id, label: response.data.fullname });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    getVisitsByContact = async () => {
        try {
            const { filter_fields } = this.state;
            if (filter_fields.started_date && filter_fields.end_date) {
                const response = await ReportDAO.getVisitsByContact(this.formattedDate(filter_fields.started_date), this.formattedDate(filter_fields.end_date), filter_fields.visitor.value === 0 ? "" : filter_fields.visitor.value);
                // const response = await ReportDAO.getVisitsByCannel("2019-01-01", "2020-01-01");
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            visitors: response.data,
                        },
                    }),
                    () => {
                        let other = 0;
                        let A = 0;
                        let B = 0;
                        let C = 0;
                        let D = 0;
                        let E = 0;
                        let F = 0;

                        this.state.data.visitors.map((object) => {
                            if (object[""]) other = other + parseInt(object[""]);
                            if (object["A"]) A = A + parseInt(object["A"]);
                            if (object["B"]) B = B + parseInt(object["B"]);
                            if (object["C"]) C = C + parseInt(object["C"]);
                            if (object["D"]) D = D + parseInt(object["D"]);
                            if (object["E"]) E = E + parseInt(object["E"]);
                            if (object["F"]) F = F + parseInt(object["F"]);

                        });
                        if (other !== 0 || A !== 0 || B !== 0 || C !== 0 || D !== 0 || E !== 0 || F !== 0) {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        other: other,
                                        A: A,
                                        B: B,
                                        C: C,
                                        D: D,
                                        E: E,
                                        F: F,
                                        show_statistical: true,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        } else {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        other: other,
                                        A: A,
                                        B: B,
                                        C: C,
                                        D: D,
                                        E: E,
                                        F: F,
                                        show_statistical: false,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        }
                    }
                );
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
}

const FullRow = styled.div`
    width: 99%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Bloc2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-top: 10px;
    background: #fff;
`;
const Th = styled.th`
    padding-right: 10px;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(VisitorByContactCategory);
