import React, { Component } from "react";

class DetailsInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div id="main_container" style={{ height: "100%" }}>
                    <div id="fixed_bar" className="top_options_wrapper">
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid4">
                                    <h1>Facture : FAC-5770</h1>
                                    <div className="top_options_btns">
                                        <a href="/invoices/index/void/invoice_id/49613454" className="btn_red protected_action">
                                            Annuler
                                        </a>
                                        <a href="/invoices/index/duplicate/invoice_id/49613454" className="btn_white">
                                            Dupliquer
                                        </a>
                                        <a href="/invoices/index/pdf-info/invoice_id/49613454" className="btn_white" target="_blank" data-shortcut_alias="print_doc">
                                            Imprimer
                                        </a>
                                        <a href="/invoices/index/generate-receipt/invoice_id/49613454" className="btn_white" target="_blank" data-shortcut_alias="print_receipt">
                                            Imprimer ticket de caisse
                                        </a>
                                        <a href="/salesreturns/index/from-invoice/invoice_id/49613454" className="btn_white">
                                            Générer retour sur vente
                                        </a>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="content">
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid3">
                                    <div className="bloc">
                                        <div className="bloc_header">
                                            <h2>Facture</h2>
                                        </div>
                                        <div className="bloc_content">
                                            <div className="facture_apercu_wrraper">
                                                <div className="facture_header">
                                                    <div className="grid2">
                                                        <p className="fh_title">
                                                            Facture <span>FAC-5770</span>
                                                        </p>
                                                    </div>
                                                    <div className="clear" />
                                                </div>
                                                <div className="facture_form">
                                                    <div className="grid2">
                                                        <div className="facture_elem_wrraper left">
                                                            <h3 className="ff_title">Informations sur la facture</h3>
                                                            <p className="facture_row">Gestionnaire : compte Démonstration</p>
                                                            <p className="facture_row">Date de vente : 2019-09-12</p>
                                                            <p className="facture_row">
                                                                Avec prescription : <strong className="tag_no">Non</strong>
                                                            </p>
                                                            <p className="facture_row">Devis N° : </p>
                                                        </div>
                                                    </div>
                                                    <div className="grid2">
                                                        <div className="facture_elem_wrraper right">
                                                            <h3 className="ff_title" />
                                                            <p className="facture_row" />
                                                            <p className="facture_row" />
                                                            <p className="facture_row" />
                                                            <p className="facture_row" />
                                                            <p className="facture_row" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grid4">
                                                    <div className="facture_apercu_table">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ width: "250px" }}>Produit</th>
                                                                    <th>P.U d'origine</th>
                                                                    <th>R</th>
                                                                    <th>P.U</th>
                                                                    <th>Qté.</th>
                                                                    <th style={{ width: "150px" }}>TVA</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>H&amp;T HERBES ET TRADITIONS HUILE DE RICIN BIO 50ML</td>
                                                                    <td>188,99</td>
                                                                    <td>0%</td>
                                                                    <td>188,99</td>
                                                                    <td>1</td>
                                                                    <td>TVA (20.00%)</td>
                                                                    <td>188,99</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="clear" />
                                                <div className="facture_extra_price">
                                                    <div className="grid2"></div>
                                                    <div className="grid2">
                                                        <div className="facture_price">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Sous-total HT</td>
                                                                        <td className="total_quantity">157,49</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>TVA</td>
                                                                        <td className="total_quantity">31,50</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total remise</td>
                                                                        <td>0 DHS</td>
                                                                    </tr>
                                                                    <tr className="fp_total">
                                                                        <td>Total</td>
                                                                        <td className="total_amount">188,99 DHS</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="clear" />
                                                </div>
                                                <div className="grid4">
                                                    <div className="facture_footer">
                                                        <p />
                                                        <span>
                                                            Généré par : <strong>Sobrus</strong> Pharma
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="clear" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bloc">
                                        <div className="bloc_header">
                                            <h2>Paiements de la vente</h2>
                                            <div className="bloc_header_btns">
                                                <a href="#" className="sprite-bloc-search" onclick="toggleSearchRow('26555_278fd923f78edaecab97229c84ec298f')" />
                                                <a href="#" className="sprite-bloc-reload" onclick="resetTableData('26555_278fd923f78edaecab97229c84ec298f')" />
                                                <a href="#" className="sprite-bloc-star" onclick="toggleAdvancedSearchRow('26555_278fd923f78edaecab97229c84ec298f')" />
                                            </div>
                                        </div>
                                        <div className="bloc_content table_wrraper">
                                            <table className="table processableTable" id="26555_278fd923f78edaecab97229c84ec298f" data-ajax_url="/invoices/payments/related-list/invoice_id/49613454" data-object_table="invoice_payments">
                                                <thead>
                                                    <tr className="table_filter table_header_search_tr" style={{ display: "none" }}>
                                                        <td className="table_header_search_td" data-column="date" data-column_type="date">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_0" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="EQUAL" selected="selected">
                                                                        est
                                                                    </option>
                                                                    <option value="NOT EQUAL">n'est pas</option>
                                                                    <option value="GREATER">après</option>
                                                                    <option value="LOWER">avant</option>
                                                                    <option value="EMPTY">est vide</option>
                                                                    <option value="NOT EMPTY">n'est pas vide</option>
                                                                </select>
                                                            </div>
                                                            <div className="input_date" style={{}}>
                                                                <input type="text" name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_0" defaultValue className="datepicker table_header_search_q datepicker" readOnly autoComplete="off" />
                                                                <a className="sprite-date-icon" />
                                                            </div>
                                                        </td>
                                                        <td className="table_header_search_td" data-column="payer_id" data-column_type="select">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_1" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="EQUAL" selected="selected">
                                                                        est
                                                                    </option>
                                                                    <option value="NOT EQUAL">n'est pas</option>
                                                                </select>
                                                            </div>
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_1" className=" table_header_search_q" autoComplete="off">
                                                                    <option value>Aucun</option>
                                                                    <option value={1}>CNSS</option>
                                                                    <option value={2}>CNOPS</option>
                                                                    <option value={3}>IKS</option>
                                                                    <option value={4}>TENMAR</option>
                                                                    <option value={5}>RAMSA</option>
                                                                    <option value={6}>Association Marocaine de Planification Familiale (AMPF)</option>
                                                                    <option value={7}>RADEEC</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="table_header_search_td" data-column="amount" data-column_type="decimal">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_2" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="EQUAL" selected="selected">
                                                                        Égal
                                                                    </option>
                                                                    <option value="NOT EQUAL">Différent</option>
                                                                    <option value="GREATER">Supérieur</option>
                                                                    <option value="GREATER OR EQUAL">Supérieur ou égal</option>
                                                                    <option value="LOWER">Inférieur</option>
                                                                    <option value="LOWER OR EQUAL">Inférieur ou égal</option>
                                                                    <option value="EMPTY">est vide</option>
                                                                    <option value="NOT EMPTY">n'est pas vide</option>
                                                                </select>
                                                            </div>
                                                            <div className="input_search" style={{}}>
                                                                <input type="text" name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_2" defaultValue className=" table_header_search_q" placeholder autoComplete="off" />
                                                                <a className="sprite-search-btn" />
                                                            </div>
                                                        </td>
                                                        <td className="table_header_search_td" data-column="payment_method_id" data-column_type="select">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_3" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="EQUAL" selected="selected">
                                                                        est
                                                                    </option>
                                                                    <option value="NOT EQUAL">n'est pas</option>
                                                                </select>
                                                            </div>
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_3" className=" table_header_search_q" autoComplete="off">
                                                                    <option value>Aucun</option>
                                                                    <option value={41}>Espèce</option>
                                                                    <option value={42}>Chèque</option>
                                                                    <option value={43}>Carte bancaire</option>
                                                                    <option value={44}>Lettre de change</option>
                                                                    <option value={46}>Avoir</option>
                                                                    <option value={47}>Autre</option>
                                                                    <option value={48}>Virement bancaire</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="table_header_search_td" data-column="ref" data-column_type="text">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_4" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="LIKE" selected="selected">
                                                                        contient
                                                                    </option>
                                                                    <option value="NOT LIKE">ne contient pas</option>
                                                                    <option value="STARTS">commence par</option>
                                                                    <option value="ENDS">se termine par</option>
                                                                    <option value="EQUAL">est</option>
                                                                    <option value="NOT EQUAL">n'est pas</option>
                                                                    <option value="EMPTY">est vide</option>
                                                                    <option value="NOT EMPTY">n'est pas vide</option>
                                                                </select>
                                                            </div>
                                                            <div className="input_search" style={{}}>
                                                                <input type="text" name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_4" defaultValue className=" table_header_search_q" placeholder autoComplete="off" />
                                                                <a className="sprite-search-btn" />
                                                            </div>
                                                        </td>
                                                        <td className="table_header_search_td" data-column="note" data-column_type="textarea"></td>
                                                        <td className="table_header_search_td" data-column="status" data-column_type="select">
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_operation" id="26555_278fd923f78edaecab97229c84ec298f_search_operation_6" className=" table_header_search_operation" autoComplete="off">
                                                                    <option value="EQUAL" selected="selected">
                                                                        est
                                                                    </option>
                                                                    <option value="NOT EQUAL">n'est pas</option>
                                                                </select>
                                                            </div>
                                                            <div className="selectbox " style={{}}>
                                                                <select name="table_header_search_q" id="26555_278fd923f78edaecab97229c84ec298f_search_q_6" className=" table_header_search_q" autoComplete="off">
                                                                    <option value>Aucun</option>
                                                                    <option value="pending">En attente</option>
                                                                    <option value="certified">Certifé</option>
                                                                    <option value="canceled">Annulé</option>
                                                                    <option value="completed">Complété</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="table_action">
                                                            <a href="#" className="search_bleu_btn table_header_search_button">
                                                                <i className="sprite-search-btn-white" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_th_orderable">
                                                        <th className data-column="date" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Date <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className data-column="payer_id" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Organisme <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className data-column="amount" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Montant <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className data-column="payment_method_id" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Moyens de paiement <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className data-column="ref" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Référence <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className data-column="note" data-is_orderable="false">
                                                            Note{" "}
                                                        </th>
                                                        <th className data-column="status" data-is_orderable="true" style={{ cursor: "pointer" }}>
                                                            Statut <a href="#" className="sprite-table-up-order" style={{}} />
                                                            <a href="#" className="sprite-table-down-order" style={{}} />
                                                        </th>
                                                        <th className="table_actions" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ cursor: "pointer" }} data-tr_url="/invoices/payments/view/invoice_payment_id/49969998" data-offset={0} data-resource_id={49969998}>
                                                        <td style={{ wordWrap: "break-word" }}>2019-09-12 </td>
                                                        <td style={{ wordWrap: "break-word" }}></td>
                                                        <td style={{ wordWrap: "break-word" }}>188,99 </td>
                                                        <td style={{ wordWrap: "break-word" }}>Carte bancaire </td>
                                                        <td style={{ wordWrap: "break-word" }}></td>
                                                        <td style={{ wordWrap: "break-word" }}></td>
                                                        <td style={{ wordWrap: "break-word" }}>
                                                            <p className="tag green" style={{ whiteSpace: "nowrap" }}>
                                                                Complété{" "}
                                                            </p>
                                                        </td>
                                                        <td className="table_action">
                                                            <a href="/invoices/payments/edit/invoice_payment_id/49969998" className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={8} className="table_pagination">
                                                            <div style={{ display: "inline-block", marginRight: "20px" }}>
                                                                <a href="#" onclick="printTable('26555_278fd923f78edaecab97229c84ec298f')" className="btn_white">
                                                                    Imprimer
                                                                </a>
                                                            </div>
                                                            <div style={{ display: "inline-block" }}>
                                                                <span className="table_page">1</span>
                                                            </div>
                                                        </td>
                                                    </tr>{" "}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid1">
                                    <div className="bloc">
                                        <div className="bloc_content devis_statut ds_complete">
                                            <h3>Complété</h3>
                                        </div>
                                    </div>
                                    <div className="bloc">
                                        <div className="bloc_content colored_greenlawn">
                                            <div className="bloc_content_header">
                                                <h2 className="white">Restant à payer par Client</h2>
                                            </div>
                                            <div className="colored_content">
                                                <p className="total">0 DHS</p>
                                                <p className="total_detail">Livré</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bloc">
                                        <div className="bloc_content bt">
                                            <div className="bloc_content_header">
                                                <h2>Raccourcis</h2>
                                            </div>
                                            <div className="quick_create">
                                                <a href="/invoices/index/pdf-info/invoice_id/49613454/print_as/delivery_note" target="_blank" className="btn_white large">
                                                    Imprimer bon de livraison{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bloc">
                                        <div className="bloc_content bt">
                                            <div className="bloc_content_header">
                                                <h2>Commentaires</h2>
                                            </div>
                                            <div className="add_comment">
                                                <form action="/comments/index/create/object_table/invoices/object_id/49613454" id="comment_form">
                                                    <textarea id="comment_input" placeholder="Ajouter un commentaire" style={{ overflow: "hidden", height: "132px" }} defaultValue={""} />
                                                    <div>
                                                        <input type="submit" defaultValue="Valider" className="btn_bleu" />
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="comment_btn">
                                                <a href="/comments/index/index/object_table/invoices/object_id/49613454" className="btn_white">
                                                    Tous les commentaires{" "}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bloc audit_bloc">
                                        <div className="bloc_header">
                                            <h2>Informations de traçabilité</h2>
                                        </div>
                                        <div className="bloc_content table_wrraper">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Créée par</td>
                                                        <td>compte Démonstration</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Créée le</td>
                                                        <td>2019-09-12 15:45:24</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mis à jour par</td>
                                                        <td>compte Démonstration</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mis à jour le</td>
                                                        <td>2019-09-12 15:45:24</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DetailsInvoices;
