import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UpdateIcon from "../images/sprite_5.png";
import WaitingProdIcon from "../images/sprite_5.png";
import { RoutesPath } from "../values/RoutesPath";
import ProductDAO from "../DAO/ProductDAO";
import UserDAO from "../DAO/UserDAO";
import { connect } from "react-redux";
import { MANAGE_PROFILE_URL } from "../env";

// import ItemIcons from "../images/sprite_5.png";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalItems: 0,
            connected_user_infos: null,
        };
    }
    componentDidMount() {
        this.getAll();
        // this.getConnectedUserInfos();
    }
    async getAll() {
        try {
            const data = {
                statut: {
                    value: "En attente",
                    op: "is",
                },
            };
            const response = await ProductDAO.getProductsSuggestions(1, {}, data);
            this.setState({ totalItems: response.data.total });
        } catch (error) {}
    }
    // async getConnectedUserInfos() {
    //     try {
    //         const params = {};
    //         const response = await UserDAO.getConnectedUserInfos(params);
    //         this.setState({ connected_user_infos: response.data });
    //     } catch (error) {
    //         (error);
    //     }
    // }
    render() {
        const { totalItems } = this.state;
        const connected_user_infos = this.props.connectedUserInfos;
        return (
            <MenuContainer className="MenuContainer">
                <Compte_infos className="Compte_infos">
                    <Avatar_container className="Avatar_container">
                        <Avatar className="Avatar" src={require("../images/profil_photo.png")} />
                    </Avatar_container>
                    <User_name className="User_name">{connected_user_infos && connected_user_infos.fullname && connected_user_infos.fullname}</User_name>
                    {/* <User_email className="User_email">{connected_user_infos && connected_user_infos.email && connected_user_infos.email}</User_email> */}
                    <Update_btn href={`${MANAGE_PROFILE_URL}/account`} target="_blank">
                        <Update_icon />
                    </Update_btn>
                </Compte_infos>
                <Link to={RoutesPath.product.suggestion.list}>
                    <Waiting_prod_container>
                        <Waiting_prod_icon />
                        <WaitingProdText>Produits en attente</WaitingProdText>
                        <WaitingProdQuantity>{totalItems}</WaitingProdQuantity>
                    </Waiting_prod_container>
                </Link>

                <Links>
                    <Title>Raccourcis</Title>
                    <Ul>
                        <Li>
                            <Link to={RoutesPath.calender.add}>
                                {/* <Marketplace /> */}
                                <ListStyle bgColor="#f4ac2f" />
                                <Item_link>Créer un événement</Item_link>
                            </Link>
                        </Li>
                        <Li>
                            <Link to={RoutesPath.account.add}>
                                {/* <Panel /> */}
                                <ListStyle bgColor="#00beb0" />
                                <Item_link>Créer un compte</Item_link>
                            </Link>
                        </Li>
                        <Li>
                            <Link to={RoutesPath.contact.add}>
                                {/* <Delivery /> */}
                                <ListStyle bgColor="#18b2d5" />
                                <Item_link>Créer un contact</Item_link>
                            </Link>
                        </Li>
                        {/* <Li>
                            <Command />
                            <Item_link>Créer un bon de commande</Item_link>
                        </Li> */}
                    </Ul>
                </Links>
            </MenuContainer>
        );
    }
}

const MenuContainer = styled.div`
    width: 24%;
    display: inline-block;
    position: relative;
    margin-left: 0.5%;
    margin-right: 0.5%;
    margin-bottom: 1%;
`;
const Update_btn = styled.a`
    display: none;
    position: absolute;
    right: 5px;
    top: 6px;
    cursor: pointer;
`;
const Compte_infos = styled.div`
    background: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding-left: 10px;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 70px !important;
    &:hover ${Update_btn} {
        display: inline-block;
    }
`;
const Avatar_container = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    background-color: #fbfbfb;
    position: absolute;
`;
const Avatar = styled.img`
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-color: #fbfbfb;
`;
const User_name = styled.h4`
    font-family: "Open Sans", sans-serif;
    color: #1ebeb0;
    font-weight: 700;
    font-size: 14px;
    margin-top: 4px;
    margin-left: 19.3%;
`;
const User_email = styled.p`
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    margin-left: 19.3%;
`;
const Update_icon = styled.i`
    display: inline-block;
    width: 24px;
    height: 25px;
    background-image: url(${UpdateIcon});
    background-repeat: no-repeat;
    background-position: -229px -450px;
    margin-top: -3px;
    margin-right: -1px;
    float: right;
    margin-left: -18px;
    background-size: 334px;
`;
const ListStyle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 140px;
    margin-right: 5px;
    background-color: ${(props) => props.bgColor};
`;
const Waiting_prod_container = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const Waiting_prod_icon = styled.i`
    display: inline-block;
    vertical-align: top;
    width: 35px;
    height: 31px;
    background-image: url(${WaitingProdIcon});
    background-repeat: no-repeat;
    background-position: -186px -499px;
    margin-top: -6px;
    margin-right: 3px;
    margin-left: 0;
    background-size: 283px;
`;
const WaitingProdText = styled.p`
    display: inline-block;
    vertical-align: top;
    width: 75%;
`;
const WaitingProdQuantity = styled.span`
    display: inline-block;
    vertical-align: top;
    background-color: #d5d5d5;
    color: #858585;
    font-weight: 700;
    padding: 4px 12px 4px 12px;
    border-radius: 50px 50px 50px 50px;
    float: right;
`;

const Links = styled.div`
    margin-top: 10px;
`;

const Title = styled.div`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
`;
const Li = styled.li`
    width: 100%;
    margin-bottom: 10px;
    height: 25px;
`;
const Ul = styled.ul`
    width: 100%;
    margin-top: 20px;
    list-style: none;
    //
`;
// const Marketplace = styled.i`
//     display: inline-block;
//     width: 35px;
//     height: 31px;
//     background-image: url(${ItemIcons});
//     background-repeat: no-repeat;
//     background-position: -186px -557px;
//     margin-top: -6px;
//     margin-right: 3px;
//     float: left;
//     margin-left: 0;
//     background-size: 283px;
// `;
// const Panel = styled.i`
//     display: inline-block;
//     width: 35px;
//     height: 31px;
//     background-image: url(${ItemIcons});
//     background-repeat: no-repeat;
//     background-position: -186px -404px;
//     margin-top: -6px;
//     margin-right: 3px;
//     float: left;
//     margin-left: 0;
//     background-size: 283px;
// `;
// const Delivery = styled.i`
//     display: inline-block;
//     width: 35px;
//     height: 31px;
//     background-image: url(${ItemIcons});
//     background-repeat: no-repeat;
//     background-position: -186px -435px;
//     margin-top: -6px;
//     margin-right: 3px;
//     float: left;
//     margin-left: 0;
//     background-size: 283px;
// `;
// const Command = styled.i`
//     display: inline-block;
//     width: 35px;
//     height: 31px;
//     background-image: url(${ItemIcons});
//     background-repeat: no-repeat;
//     background-position: -186px -467px;
//     margin-top: -6px;
//     margin-right: 3px;
//     float: left;
//     margin-left: 0;
//     background-size: 283px;
// `;
const Item_link = styled.span`
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    outline: 0;
    text-decoration: none;
    cursor: pointer;
    color: #494949;
    &:hover {
        > span {
            color: #1ebeb0;
        }
        text-decoration: underline;
        color: #1ebeb0;
    }
`;
// const Italic = styled.span`
//     font-style: italic;
// `;

const mapStateToProps = (state) => {
    return {
        connectedUserInfos: state.User.connectedUserInfos,
    };
};
// const NavigationHeader = withRouter(Header)

export default connect(mapStateToProps)(Menu);
