import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import CalenderDAO from "../DAO/CalenderDAO";
import { RoutesPath } from "../values/RoutesPath";
import CircleLoading from "../components/CircleLoading";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";
import { FaCalendarAlt } from "react-icons/fa";
import { AiOutlineUnorderedList } from "react-icons/ai";
import CustomInput from "../components/CustomInput";
import { DatePicker } from "../components/CustomDatePicker";
import CustomSelect from "../components/CustomSelect";
import { CustomStyleSearchSelect } from "../helpers/SearchSelect";
import SearchSelect from "../helpers/SearchSelect";
import img from "../images/sprite_3.png";
import ContentLoader from "react-content-loader";
import UserDAO from "../DAO/UserDAO";
import configuration from "../config/configuration";

class CalenderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top: 0,
            moment: moment().format("HH:mm"),
            hour: moment().format("HH"),
            minute: moment().format("mm"),
            toDay: moment().format("DD MMMM YYYY"),
            validDate: moment().format(),
            orderedArray: [],
            pageNumber: 1,
            totalItems: 0,
            events: [],
            loading: false,
            active: 2,

            tableEvents: [],
            tableEventsPageNumber: 1,
            tableEventsTotalItems: 0,
            id_manager: 0,
            selected_manager: null,

            id_manager_table: 0,
            selected_manager_table: { value: 0, label: "Tout" },
            search: false,
            advancedSearch: false,
            searchFields: {
                name: "",
                date_start: null,
                date_end: null,
                type: "",
                account: "",
                status: "",
            },
            searchFieldsOperators: {
                name: "",
                date_start: null,
                date_end: null,
                type: "",
                account: "",
                status: "",
            },
            selectedAccount: null,
            orderBy: 0,
            key: "",
        };
    }
    current() {
        setInterval(() => this.setState({ top: this.state.top + 1, moment: moment().format("HH:mm"), hour: moment().format("HH"), minute: moment().format("mm") }), 10000);
        //this.setState({top:this.state.top+1});
    }
    setSearchField(fieldName, value) {
        this.setState((prevState) => ({
            ...prevState,
            searchFields: {
                ...prevState.searchFields,
                [fieldName]: value,
            },
        }));
    }
    setSearchFieldOperators(fieldName, value) {
        this.setState((prevState) => ({
            ...prevState,
            searchFieldsOperators: {
                ...prevState.searchFieldsOperators,
                [fieldName]: value,
            },
        }));
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        //scroll to the current hour
        const currentHourTr = document.getElementById(String(this.state.hour));
        if (currentHourTr) {
            //100 is the padding added for th header
            window.scrollTo({ top: currentHourTr.offsetTop + 100, behavior: "smooth" });
        }
        // end scroll to the current hour
        this.current();
        this.getConnectedUserInfos(this.getEvents.bind(this));
        // for the calendar we must to get all events 
        this.resetSearch() // we have getAll in resetPassword
    }
    async getAll() {
        try {
            const { orderBy, key, id_manager_table, searchFields, searchFieldsOperators, search, advancedSearch, tableEventsPageNumber } = this.state;
            console.log("i am in getAll", searchFields)
            let data = CalenderDAO.getSearchDataForCalender({ advancedSearch, search, ...searchFields, operators: searchFieldsOperators });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            if (parseInt(id_manager_table) !== 0) {
                data["gestionnaire"] = {
                    value: String(id_manager_table),
                    op: "is",
                };
            }
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }
            const response = await CalenderDAO.getALLEvents(tableEventsPageNumber, {}, data);
            this.setState({ tableEvents: response.data.events, tableEventsTotalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getEvents() {
        try {
            const { validDate, id_manager } = this.state;
            const { pageNumber, totalItems, events } = this.state;
            const data = CalenderDAO.getSearchDataForDay({ validDate });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            data["pagination"] = {
                value: "OFF",
            };
            if (parseInt(id_manager) !== 0) {
                data["gestionnaire"] = {
                    value: String(id_manager),
                    op: "is",
                };
            }
            this.setState({ loading: true });
            const response = await CalenderDAO.getALLEvents(1, {}, data);
            this.setState({ loading: false });

            this.setState({ events: [...events, ...response.data.events] }, () => {
                const orderedArray = [];
                this.state.events.map((event) => {
                    const index = parseInt(moment(event.date_debut).format("HH"));
                    if (orderedArray[parseInt(index)] === undefined) {
                        orderedArray[parseInt(index)] = [event];
                    } else {
                        orderedArray[index] = [...orderedArray[index], event];
                    }
                    //(moment(event.date_debut).format("h"))
                });
                this.setState({ orderedArray });
            });
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    getAllDayEvent() {
        for (var i = 0; i <= 23; i++) {
            return this.renderEvents(i);
        }
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    resetSearch() {
        this.setState(
            (prevState) => ({
                ...prevState,
                selectedAccount: null,

                searchFields: {
                    name: "",
                    date_start: null,
                    date_end: null,
                    type: "",
                    account: "",
                    status: "",
                },
                searchFieldsOperators: {
                    name: "",
                    date_start: null,
                    date_end: null,
                    type: "",
                    account: "",
                    status: "",
                },
            }),
            () => this.getAll()
        );
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    async getConnectedUserInfos(cb) {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id, selected_manager: { value: response.data.id, label: response.data.fullname } }, () => cb());
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    render() {
        const { loading, active, id_manager } = this.state;
        return (
            <Main_container id="calender_container_id" className="Main_container">
                {loading && <Loading />}
                <TopOptions title="Calendrier">
                    <InputContainer ntainer>
                        {active === 1 ? (
                            <CustomStyleSearchSelect
                                name={"report"}
                                fetchedUrl="/api/utilisateurs"
                                HTTPMethod="post"
                                receivedObject="utilisateurs"
                                optionLabelProperty="fullname"
                                optionValueProperty="id"
                                value={this.state.selected_manager_table}
                                placeholder="Gestionnaire"
                                onChange={(e) => {
                                    this.setState({ selected_manager_table: e, id_manager_table: e && e.value }, () => {
                                        this.getAll();
                                    });
                                    // this.setFields("id_manager", e && e.value);
                                }}
                            />
                        ) : (
                                <CustomStyleSearchSelect
                                    fetchedUrl="/api/utilisateurs"
                                    HTTPMethod="post"
                                    receivedObject="utilisateurs"
                                    optionLabelProperty="fullname"
                                    optionValueProperty="id"
                                    value={this.state.selected_manager}
                                    placeholder="Gestionnaire"
                                    onChange={(e) => {
                                        this.setState({ selected_manager: e, id_manager: e && e.value }, () => {
                                            this.setState({ orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents());
                                        });
                                        // this.setFields("id_manager", e && e.value);
                                    }}
                                />
                            )}
                    </InputContainer>
                    <EventOptions>
                        <EventList active={active === 1 ? true : false} onClick={() => active !== 1 && this.setState({ active: 1 }, () => {
                            // we have getAll function in preload function
                            this.preload();
                        })} style={{ margin: 0 }}>
                            <AiOutlineUnorderedList color="#00beb0" />
                        </EventList>
                        <EventList active={active === 2 ? true : false} onClick={() => active !== 2 && this.setState({ active: 2 }, () => { this.resetSearch(); })} style={{ margin: 0 }}>
                            <FaCalendarAlt color="#00beb0" />
                        </EventList>
                    </EventOptions>
                    {acl.calender.add() && (
                        <button
                            className="btn_bleu"
                            data-shortcut_alias="create"
                            onClick={() => {
                                this.props.history.push(`${RoutesPath.calender.add}`);
                            }}
                        >
                            Créer
                        </button>
                    )}
                </TopOptions>
                {active === 1 && this.renderEventsTable()}
                {active === 2 && this.renderCalender()}
            </Main_container>
        );
    }
    renderCalender() {
        const { loading } = this.state;
        return (
            <Bloc className="Bloc">
                <Bloc_header>
                    <Right_content>
                        <Date_container>
                            <Previous_btn
                                onClick={() => this.setState({ toDay: moment(this.state.validDate).add(-1, "day").endOf("day").format("DD MMMM YYYY"), validDate: moment(this.state.validDate).add(-1, "day").endOf("day").format(), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}
                            >
                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path
                                        fill="#9f9f9f"
                                        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                    ></path>
                                </Svg>
                            </Previous_btn>
                            <Date_text>{this.state.toDay}</Date_text>
                            <Next_btn
                                onClick={() => this.setState({ toDay: moment(this.state.validDate).add(1, "day").endOf("day").format("DD MMMM YYYY"), validDate: moment(this.state.validDate).add(1, "day").endOf("day").format(), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}
                            >
                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path
                                        fill="#9f9f9f"
                                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                    ></path>
                                </Svg>
                            </Next_btn>
                        </Date_container>
                        <Btn_container>
                            {acl.calender.list_month() && <Btn onClick={() => this.props.history.push(`${RoutesPath.calender.list_month}`)}>Mois</Btn>}
                            {/* <Btn>Semaine</Btn> */}
                            {acl.calender.list() && <Clicked_btn onClick={() => this.props.history.push(`${RoutesPath.calender.list}`)}>Jours</Clicked_btn>}
                            <Btn onClick={() => this.setState({ toDay: moment().format("DD MMMM YYYY"), validDate: moment().format(), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}>Aujourd'hui</Btn>
                        </Btn_container>
                    </Right_content>
                </Bloc_header>
                <Calender_table>
                    <Tbody>
                        <Tr>
                            <Td1>
                                <Clock aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill="#494949"
                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                                    ></path>
                                </Clock>
                            </Td1>
                            <Td2>{""}</Td2>
                        </Tr>
                        <Tr id="1">
                            <Td1>1:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 1 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(1)}
                            </Td2>
                        </Tr>
                        <Tr id="2">
                            <Td1>2:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 2 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(2)}
                            </Td2>
                        </Tr>
                        <Tr id="3">
                            <Td1>3:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 3 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(3)}
                            </Td2>
                        </Tr>
                        <Tr id="4">
                            <Td1>4:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 4 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(4)}
                            </Td2>
                        </Tr>
                        <Tr id="5">
                            <Td1>5:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 5 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(5)}
                            </Td2>
                        </Tr>
                        <Tr id="6">
                            <Td1>6:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 6 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(6)}
                            </Td2>
                        </Tr>
                        <Tr id="7">
                            <Td1>7:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 7 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(7)}
                            </Td2>
                        </Tr>
                        <Tr id="8">
                            <Td1>8:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 8 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(8)}
                            </Td2>
                        </Tr>
                        <Tr id="9">
                            <Td1>9:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 9 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(9)}
                            </Td2>
                        </Tr>
                        <Tr id="10">
                            <Td1>10:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 10 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(10)}
                            </Td2>
                        </Tr>
                        <Tr id="11">
                            <Td1>11:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 11 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(11)}
                            </Td2>
                        </Tr>
                        <Tr id="12">
                            <Td1>12:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 12 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(12)}
                            </Td2>
                        </Tr>
                        <Tr id="13">
                            <Td1>13:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 13 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(13)}
                            </Td2>
                        </Tr>
                        <Tr id="14">
                            <Td1>14:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 14 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(14)}
                            </Td2>
                        </Tr>
                        <Tr id="15">
                            <Td1>15:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 15 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(15)}
                            </Td2>
                        </Tr>
                        <Tr id="16">
                            <Td1>16:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 16 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(16)}
                            </Td2>
                        </Tr>
                        <Tr id="17">
                            <Td1>17:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 17 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(17)}
                            </Td2>
                        </Tr>
                        <Tr id="18">
                            <Td1>18:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 18 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(18)}
                            </Td2>
                        </Tr>
                        <Tr id="19">
                            <Td1>19:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 19 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(19)}
                            </Td2>
                        </Tr>
                        <Tr id="20">
                            <Td1>20:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 20 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(20)}
                            </Td2>
                        </Tr>
                        <Tr id="21">
                            <Td1>21:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 21 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(21)}
                            </Td2>
                        </Tr>
                        <Tr id="22">
                            <Td1>22:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 22 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(22, "onTop")}
                            </Td2>
                        </Tr>
                        <Tr id="23">
                            <Td1>23:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 23 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(23, "onTop")}
                            </Td2>
                        </Tr>
                        <Tr id="00">
                            <Td1>00:00</Td1>
                            <Td2>
                                {parseInt(this.state.hour) === 0 ? (
                                    <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                        <Time_text>{this.state.moment}</Time_text>
                                        <div>
                                            <Current_time_circle />
                                            <Current_time_line></Current_time_line>
                                        </div>
                                    </Current_time_line_container>
                                ) : (
                                        ""
                                    )}
                                {this.renderEvents(0, "onTop")}
                            </Td2>
                        </Tr>
                    </Tbody>
                </Calender_table>
            </Bloc>
        );
    }

    renderEvents(hour, otherEventsPosition = "onBottom") {
        const { orderedArray } = this.state;
        let renderedArray = [];
        let otherEvents = [];
        orderedArray[hour] &&
            orderedArray[hour].map((event, index) => {
                const arrayLength = orderedArray[hour].length;
                const arrayLengthApp = arrayLength <= 5 ? arrayLength : 5;
                const blockWidth = arrayLength === 1 ? 100 : arrayLength === 2 ? 66.6 : arrayLength === 4 ? 40 : 100 / (arrayLengthApp - parseInt(arrayLengthApp * 0.5));

                let color = event.couleur ? event.couleur : "#0cb3d6";
                // if (index === 1) {
                //     color = "#f89a54";
                // } else if (index === 2) {
                //     color = "#F0396E";
                // } else if (index === 3) {
                //     color = "#6f69cb";
                // } else if (index > 3) {
                //     color = "#00beb0";
                // }
                if (index < 4) {
                    renderedArray.push(
                        <Event_container key={"Event_container_key" + index} onClick={() => acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`)} index={index + 1} color={color} width={blockWidth}>
                            <P>
                                {moment(event.date_debut).format("YYYY-MM-DD HH:mm")} - {event.nom}.
                            </P>
                        </Event_container>
                    );
                } else {
                    otherEvents.push(
                        <Li onClick={() => acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`)}>
                            <Circle color="#00beb0" />
                            <Event_desc>
                                {moment(event.date_debut).format("YYYY-MM-DD HH:mm")} - {event.nom}.
                            </Event_desc>
                        </Li>
                    );
                }
            });
        if (otherEvents.length !== 0) {
            renderedArray.push(
                <Event_container index="5" color="#00beb0" width={100 / (5 - 2)}>
                    <Other_event>{otherEvents.length} autre</Other_event>
                    <Pop_up otherEventsPosition={otherEventsPosition} width={100 / (5 - 2)}>
                        <Pop_up_container>
                            <Pop_up_close aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                <path
                                    fill="#9f9f9f"
                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                ></path>
                            </Pop_up_close>
                            <H4>{moment(this.state.toDay).format("DD MMMM")}</H4>
                            {/* <Desc>Saltem non obsecrans dolor</Desc> */}
                            <Ul>
                                {otherEvents.map((renderedEvent) => {
                                    return renderedEvent;
                                })}
                            </Ul>
                        </Pop_up_container>
                    </Pop_up>
                </Event_container>
            );
        }
        return (
            <div>
                {renderedArray.map((renderedEvent) => {
                    return renderedEvent;
                })}
            </div>
        );
    }
    componentWillUnmount() {
        this.storeSearchInCookies();
        window.removeEventListener('beforeunload', this.storeSearchInCookies);
    }

    preload = () => {
        this.getSearchFromCookies();
        window.addEventListener('beforeunload', this.storeSearchInCookies);
    }
    renderEventsTable() {
        const { orderBy, key, tableEvents, advancedSearch, search, pageNumber, totalItems, tableEventsPageNumber, tableEventsTotalItems } = this.state;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>{"Liste des événements"}</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                        <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                    </div>
                </div>
                <TableWrapper className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {/* advanced search */}
                            {advancedSearch && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("name", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.name}
                                                label="Nom"
                                                operatorsType="detailed"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("date_start", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.date_start}
                                                label="Date de début"
                                            >
                                                <option defaultValue></option>
                                                <option value="is_date">est</option>
                                                <option value="is_not_date">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    {/* <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("date_end", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.date_end}
                                                label="Date de fin"
                                            >
                                                <option defaultValue></option>
                                                <option value="is_date">est</option>
                                                <option value="is_not_date">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th> */}

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("type", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.type}
                                                label="Type"
                                                operatorsType="short"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("account", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.account}
                                                label="Compte"
                                                operatorsType="short"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchFieldOperators("status", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFieldsOperators.status}
                                                label="Statut"
                                                operatorsType="short"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {advancedSearch && (
                                            <Search_btn onClick={() => this.setState({ tableEventsPageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </tr>
                            )}
                            {/* end advanced search */}
                            {/* simple search */}
                            {(search || advancedSearch) && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomInput
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                value={this.state.searchFields.name}
                                                onChange={(value) => {
                                                    this.setSearchField("name", value.target.value);
                                                }}
                                                label="Nom"
                                                searchIcon={true}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <DatePicker
                                                showTimeSelect={false}
                                                selected={this.state.searchFields.date_start}
                                                onChange={(date) => {
                                                    this.setSearchField("date_start", date);
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                label="Date de début"
                                                id="start_date"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    {/* <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <DatePicker
                                                showTimeSelect={false}
                                                selected={this.state.searchFields.date_end}
                                                onChange={(date) => {
                                                    this.setSearchField("date_end", date);
                                                }}
                                                dateFormat="yyyy-MM-dd"
                                                label="Date de fin"
                                                id="end_date"
                                            />
                                        </Search_input_container>
                                    </Th> */}

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                onChange={(value) => {
                                                    this.setSearchField("type", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFields.type}
                                                label="Type"
                                            >
                                                <option defaultValue></option>
                                                <option value="Visite">Visite</option>
                                                <option value="Formation interne ou Réunion">Formation interne ou Réunion</option>
                                                <option value="Absence">Absence</option>
                                                <option value="Congés">Congés</option>
                                                <option value="Évènement et congrès">Évènement et congrès</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl={`/api/comptes`}
                                                HTTPMethod="post"
                                                receivedObject="comptes"
                                                optionLabelProperty="libelle"
                                                optionValueProperty="id"
                                                value={this.state.selectedAccount}
                                                placeholder="Compte"
                                                onChange={(e) => {
                                                    this.setState({
                                                        selectedAccount: e,
                                                    });
                                                    this.setSearchField("account", e && e.value);
                                                }}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setSearchField("status", value.target.value);
                                                }}
                                                selectedValue={this.state.searchFields.status}
                                                label="Statut"
                                            >
                                                <option value="" defaultValue></option>
                                                <option value="Planifié">Planifié</option>
                                                <option value="Visité">Visité</option>
                                                <option value="Reporté">Reporté</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {search && (
                                            <Search_btn onClick={() => this.setState({ tableEventsPageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </tr>
                            )}
                            {/* end simple search */}
                            {/* table head names  */}
                            <tr className="table_th_orderable">
                                <Th key="nom" orderBy={key === "nom" && orderBy} onClick={() => this.setState({ key: "nom", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "nom" && orderBy === -1) || orderBy === 0 || key !== "nom") && <OrderUp />}
                                    {((key === "nom" && orderBy === 1) || orderBy === 0 || key !== "nom") && <OrderDown />}
                                    Nom
                                </Th>
                                <Th key="date_debut" orderBy={key === "date_debut" && orderBy} onClick={() => this.setState({ key: "date_debut", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "date_debut" && orderBy === -1) || orderBy === 0 || key !== "date_debut") && <OrderUp />}
                                    {((key === "date_debut" && orderBy === 1) || orderBy === 0 || key !== "date_debut") && <OrderDown />}
                                    Date de début
                                </Th>
                                {/* <Th key="date_fin" orderBy={key === "date_fin" && orderBy} onClick={() => this.setState({ key: "date_fin", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "date_fin" && orderBy === -1) || orderBy === 0 || key !== "date_fin") && <OrderUp />}
                                    {((key === "date_fin" && orderBy === 1) || orderBy === 0 || key !== "date_fin") && <OrderDown />}
                                    Date de fin
                                </Th> */}

                                <Th key="type" orderBy={key === "type" && orderBy} onClick={() => this.setState({ key: "type", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "type" && orderBy === -1) || orderBy === 0 || key !== "type") && <OrderUp />}
                                    {((key === "type" && orderBy === 1) || orderBy === 0 || key !== "type") && <OrderDown />}
                                    Type
                                </Th>
                                <Th key="compte" orderBy={key === "compte" && orderBy} onClick={() => this.setState({ key: "compte", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "compte" && orderBy === -1) || orderBy === 0 || key !== "compte") && <OrderUp />}
                                    {((key === "compte" && orderBy === 1) || orderBy === 0 || key !== "compte") && <OrderDown />}
                                    Compte
                                </Th>
                                <Th key="statut" orderBy={key === "statut" && orderBy} onClick={() => this.setState({ key: "statut", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                    {((key === "statut" && orderBy === -1) || orderBy === 0 || key !== "statut") && <OrderUp />}
                                    {((key === "statut" && orderBy === 1) || orderBy === 0 || key !== "statut") && <OrderDown />}
                                    Statut
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                            {/* end table head name */}
                        </thead>
                        {/* loading */}
                        {false && (
                            <tbody>
                                {[1, 2, 3].map((index) => {
                                    return (
                                        <TableTr key={"key__" + index}>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            {/* <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td> */}
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td className="table_action"></Td>
                                        </TableTr>
                                    );
                                })}
                            </tbody>
                        )}
                        {/* end loading */}
                        {/* fetching data */}
                        <tbody>
                            {tableEvents.map((event, index) => {
                                return (
                                    <TableTr key={"key_" + index}>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {event.nom}
                                        </Td>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {moment(event.date_debut).format("YYYY-MM-DD HH:mm")}
                                        </Td>
                                        {/* <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {moment(event.date_fin).format("YYYY-MM-DD HH:mm")}
                                        </Td> */}

                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {event.type}
                                        </Td>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {event.compte}
                                        </Td>
                                        <Td
                                            onClick={() => {
                                                acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
                                            }}
                                        >
                                            {event.statut}
                                        </Td>
                                        <Td className="table_action">
                                            {/* {disabledData === 0 && acl.account.update() && (
                                                <a
                                                    onClick={() => {
                                                        this.props.history.push(`${RoutesPath.account.update}/${account.id}`);
                                                    }}
                                                    className="sprite-table-edit-btn  tooltip"
                                                    title="Modifier"
                                                    data-confirm_msg
                                                    data-removeable_element="parents::tr"
                                                    target="_blank"
                                                />
                                            )}
                                            {disabledData === 0 && acl.account.disable() && <a onClick={() => this.delete(account.id)} className="sprite-table-delete-btn  tooltip" title="Archiver" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />}
                                            {disabledData === 1 && acl.account.restore() && <a onClick={() => this.restore(account.id)} className="sprite-table-recover-btn restore_delivery_note tooltip" title="Restaurer" data-confirm_msg="" data-removeable_element="parents::tr"></a>} */}
                                        </Td>
                                    </TableTr>
                                );
                            })}
                            <TableTr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}></div>
                                    <div style={{ display: "inline-block" }}>
                                        {tableEventsPageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ tableEventsPageNumber: tableEventsPageNumber - 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{tableEventsPageNumber}</span>
                                        {tableEventsPageNumber < tableEventsTotalItems / 20 && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ tableEventsPageNumber: tableEventsPageNumber + 1 }, () => {
                                                        this.getAll();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </TableTr>
                        </tbody>
                        {/* end  fetching data */}
                    </table>
                </TableWrapper>
            </Bloc>
        );
    }

    getSearchFromCookies = () => {
        const searchFromCookie = configuration.getSearchFromCookies("Events");
        if (searchFromCookie) {
            this.setState((prevState) => ({
                ...prevState,
                searchFieldsOperators: { ...searchFromCookie.searchOperators },
                searchFields: {
                    ...searchFromCookie.searchValues,
                    date_start: searchFromCookie.searchValues.date_start ? new Date(Date.parse(searchFromCookie.searchValues.date_start)) : null,
                    date_end: searchFromCookie.searchValues.date_end ? new Date(Date.parse(searchFromCookie.searchValues.date_end)) : null,
                },
                selectedAccount: searchFromCookie.searchValues.selectedAccount,
                advancedSearch: searchFromCookie.advancedSearch,
                search: searchFromCookie.search,
            }), () => this.getAll())
        } else {
            this.getAll()
        }
    }
    storeSearchInCookies = () => {
        const { search, advancedSearch } = this.state;

        const { searchFields, selectedAccount } = this.state;
        const { searchFieldsOperators } = this.state;
        const searchOperators = {
            ...searchFieldsOperators,
        }
        const searchValues = {
            ...searchFields,
            selectedAccount,
        }
        configuration.storeSearchInCookies({ search, advancedSearch, searchOperators, searchValues }, "Events");
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Bloc_header = styled.div`
    position: relative;
    /* padding: 10px; */
    background: #f0f0f0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 48px;
`;
const Tbody = styled.tbody`
    height: 300px;
    overflow-y: scroll;
`;
const Right_content = styled.div`
    display: block;
    height: 100%;
    width: 63%;
    text-align: center;
    float: right;
`;
const Date_container = styled.div`
    display: inline-block;
    height: 100%;
    width: 25%;
    text-align: center;
    float: left;
    padding-top: 10px;
    text-align: center;
`;
const Svg = styled.svg`
    width: 10px;
`;
const Clock = styled.svg`
    width: 25px;
`;
const Next_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: right;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 5px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Previous_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: left;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 7px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Date_text = styled.p`
    display: inline-block;
    vertical-align: top;
    width: 52%;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #00beb0;
    overflow: hidden;
    margin-top: 7px;
`;
const Btn_container = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 74%;
    float: right;
    padding-top: 10px;
`;
const Btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #494949;
    margin-right: 10px;
    cursor: pointer;
    float: right;
    &:hover {
        background-color: #00beb0;
        color: #fff;
        border: solid 1px #00beb0;
    }
`;
const Clicked_btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #00beb0;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fff;
    float: right;
    margin-right: 10px;
    cursor: pointer;
`;
const Calender_table = styled.table`
    background-color: #fff;
    border-collapse: collapse;
    position: relative;
`;
const Tr = styled.tr`
    height: 80px;
`;

const Td2 = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    position: relative;
`;
const Td1 = styled.td`
    width: 7%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;

const Event_container = styled.div`
    height: 40px;
    position: relative;

    border-radius: 3px;
    border: solid 0.6px #ffffff;
    background-color: ${(props) => props.color};
    width: ${(props) => props.width + "%"};
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: ${(props) => {
        if (props.index === 1) {
            return 0;
        } else {
            return (props.index - 1) * 9 + "px";
        }
    }};
    left: ${(props) => {
        if (props.index === 1) {
            return 0;
        } else {
            return (props.width / 2) * (props.index - 1) + "%";
        }
    }};
    &:hover {
        z-index: 999;
        top: 0;
        height: 100%;
        transition: 0.2s;
        cursor: pointer;
        > div {
            display: block;
        }
    }
`;

const P = styled.p`
    padding-top: 10px;
    padding-left: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    width: 100%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-overflow: ellipsis;
`;
const Other_event = styled.p`
    padding-top: 10px;
    padding-left: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    &:hover {
        color: #037d74;
    }
`;
const Pop_up = styled.div`
    position: absolute;
    bottom: ${(props) => (props.otherEventsPosition === "onTop" ? 0 : "none")};
    top: ${(props) => (props.otherEventsPosition === "onBottom" ? 0 : "none")};
    left: -1px;
    width: 101%;
    display: none;
    z-index: 999999;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    padding-bottom: 10px;
`;
const Pop_up_container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 16px;
`;
const H4 = styled.h4`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #9f9f9f;
`;
const Desc = styled.p`
    display: block;
    width: 90%;
    margin: auto;
    height: 18px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #00beb0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
`;
const Ul = styled.ul`
    list-style: none;
    margin-top: 10px;
`;
const Li = styled.li`
    display: block;
    width: 90%;
    margin: auto;
    margin-bottom: 7px;
    &:hover {
        background: #f0f0f0;
    }
`;
const Circle = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
`;
const Event_desc = styled.p`
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #494949;
    margin-left: 8px;
`;
const Pop_up_close = styled.svg`
    position: absolute;
    top: 4px;
    right: 7px;
    width: 12px;
    height: 16px;
    cursor: pointer;
    &:hover {
        > path {
            fill: #00beb0;
        }
    }
`;
const Current_time_line = styled.div`
    height: 3px;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
    width: 99.2%;
    margin-top: 5px;
    margin-left: -6px;
`;
const Current_time_circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
`;
const Current_time_line_container = styled.div`
    width: 100.8%;
    position: absolute;
    z-index: 999;
    top: ${(props) => props.top + "px"};
    left: -6px;
    height: 29px;
`;
const Time_text = styled.p`
    margin-left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    /* text-align: center; */
    color: #f0396e;
`;
const EventOptions = styled.div`
    float: left;
    margin-left: 5px;
    display: inline-block;
    height: 25px;
    width: 81px;
    cursor: pointer;
    background: #fff;
    border-radius: 2px;
`;
const EventList = styled.a`
    background-color: ${(props) => (props.active ? "#00beb0" : "")};
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 50%;
    margin: 0;

    border: 1px solid ${(props) => (props.active ? "#00beb0" : "#d2dbe0")};
    text-align: center;
    padding-top: 4px;
    > svg path {
        fill: ${(props) => (props.active ? "#fff" : "")};
    }
    &:hover {
        background: #00beb0;
        border: 1px solid #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(CalenderPage);

const Loading = () => {
    return (
        <LoadingContainer>
            <CircleLoading />
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
    position: absolute;
    background-color: rgba(229, 229, 229, 0.5);
    padding-top: 50vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
`;

const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;

export const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;

const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const TableTr = styled.tr`
    cursor: pointer;
`;
export const InputContainer = styled.div`
    width: 300px;
    height: 25px;

    margin-left: 0px !important;
`;

const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
