import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordWrap: 'break-word';
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
     cursor: pointer;
`;
const Tag_no = styled.strong`
     padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block; 
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

class SettingsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="bloc_content table_wrraper">
                <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                    <thead>
                        <tr className="table_th_orderable">
                            <Th>
                                Utilisateur
                            </Th>
                            <Th>
                                Heure
                            </Th>
                            <Th>
                                IP
                            </Th>
                            <Th>
                               Type d'appareil
                            </Th>
                        </tr>
                    </thead>

                    <tbody>
                        <Tr onClick={() => { this.props.history.push('/details') }}>
                            <Td>
                                Prospect
                            </Td>
                            <Td>FAC-5751</Td>
                            <Td>
                               41.248.106.134
                            </Td>
                            <Td>
                                PC
                            </Td>
                            
                        </Tr>

                        <Tr onClick={() => { this.props.history.push('/details') }}>
                            <Td>
                                Prospect
                            </Td>
                            <Td>FAC-5751</Td>
                            <Td>
                               41.248.106.134
                            </Td>
                            <Td>
                                PC
                            </Td>
                        </Tr>

                        <Tr>
                            <Td colSpan={8} className="table_pagination">
                                <div style={{ display: 'inline-block', marginRight: '20px' }}>
                                    <a href="#" onClick={() => { }} className="btn_white">Imprimer</a>
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    <span className="table_page">1</span>
                                    <a href="#" className="sprite-table-next-btn" onClick={() => { }} />
                                </div>
                            </Td>
                        </Tr>

                    </tbody>

                </table>
            </div>
        );
    }
}

export default SettingsTable;