import React, { Component } from "react";
import "./Login.css";
import { LOGIN_URL, BASE_URL } from "../../env";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="Login">
                <header>
                    <div className="header_bottom">
                        <div className="Login__container">
                            <a href="/" className="logo">
                                <img src={require("../../images/logo_login.png")} alt="login_logo" />
                            </a>
                            <ul className="header_btns">
                                <a href="http://support.sobrus.com" target="_blank" rel="noopener noreferrer" className="help_lien">
                                    Besoin d'aide ?
                                </a>
                                <li>
                                    <a href="http://support.sobrus.com" target="_blank" rel="noopener noreferrer" className="Login__btn_green">
                                        <i className="icon_support" />
                                        Support
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
                <section className="Login__wrapper">
                    <div className="Login__container">
                        <div className="login_bloc">
                            <div className="login_bloc_header">
                                <h3>Connectez-vous à votre compte Sobrus Labs</h3>
                            </div>
                            <form id="connex_form" className="form_login">
                                <img src={require("../../images/login_salim.png")} alt="salim_logo"></img>
                                <div className="row_form">
                                    <a className="Login__btn" href={LOGIN_URL + "/authorize?prompt=login&response_type=code&client_id=10003&scope=openid offline_access&redirect_uri=" + encodeURI(BASE_URL + "/auth/token")}>
                                        Se connecter
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <footer className="Login__footer">
                    <div className="container">
                        <div className="copyright">
                            <p>Réalisé avec</p>
                            <i className="heart_icon" />
                            <p>au Maroc © 2020 Sobrus.</p>
                        </div>
                        <ul className="contact_footer">
                            <li>
                                <i className="icon_tel" />
                                <p>05 30 500 500</p>
                            </li>
                            <li>
                                <i className="icon_msg" />
                                <p>support@sobrus.com</p>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/Sobrusgroup/" target="_blank" rel="noopener noreferrer">
                                    <i className="icon_facebook" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Login;
