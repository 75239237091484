import React, { Component } from "react";
import styled from "styled-components";

import AclDAO from "../DAO/AclDAO";
import TableModel from "../components/TableModel";
import { RoutesPath } from "../values/RoutesPath";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { acl } from "../services/acl";
import { notify } from "../helpers/FlashMessage";

const thead = [{ property: "nom", label: "Nom", type: "string" }];

class ACLRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            isThereByDefaultRole: false,
        };
    }
    componentDidMount() {
        if (acl.setting.acl_roles.getDefaultRoles()) {
            this.rolesByDefault();
        } else {
            let topOptionsBtn = [];
            if (acl.setting.acl_roles.add()) {
                topOptionsBtn.push({ label: "Nouveau rôle", onClick: () => this.props.history.push(`${RoutesPath.setting.acl_roles.add}`) })
            }
            this.props.dispatch({
                type: "SETTINGS_TOP_OPTIONS_BTN",
                value: topOptionsBtn,
            });
        }
        this.getAll();
    }
    async rolesByDefault() {
        try {
            const response = await AclDAO.isThereByDefaultRole();
            this.setState({ isThereByDefaultRole: response.data.length === 0 ? false : true }, () => {
                let topOptionsBtn = [];
                if (acl.setting.acl_roles.add()) {
                    topOptionsBtn.push({ label: "Nouveau rôle", onClick: () => this.props.history.push(`${RoutesPath.setting.acl_roles.add}`) })
                }
                if (acl.setting.acl_roles.defaultRoles() && !this.state.isThereByDefaultRole) {
                    topOptionsBtn.push({ label: "Ajouter les rôles par défaut", onClick: this.addDefaultRoles })
                }
                this.props.dispatch({
                    type: "SETTINGS_TOP_OPTIONS_BTN",
                    value: topOptionsBtn,
                });
            });
        } catch (error) {
            console.log(error);
        }
    }
    addDefaultRoles = async () => {
        try {
            const response = await AclDAO.createRoleByDefault();
            if (response.data.status === "ok") {
                this.getAll();
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    async getAll() {
        try {
            const params = {};
            const data = {};
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await AclDAO.getAllRoles(params);
            this.setState({ is_loaded: true, data: response.data }, () => this.state.data);
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }

    render() {
        const { is_loaded, data } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>ACL > Rôles</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient la liste des acls.</Block_content_title>
                    </Block_content>
                </First_block>

                <TableModel
                    title="Rôles"
                    onReload={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => { },
                        actions: [
                            acl.setting.acl_roles.update() && { title: "modifier", onClick: (id) => this.props.history.push(`${RoutesPath.setting.acl_roles.update}/${id}`) },
                            acl.setting.acl_roles.affect_methods() && { title: "validate", onClick: (id) => this.props.history.push(`${RoutesPath.setting.acl_methods.affect_to_role}/${id}`) },
                        ],
                    }}
                    {...this.props}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(ACLRoles);
// export default connect(mapStateToProps)(ACLRoles);

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
