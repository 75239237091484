import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomSelect from "../components/CustomSelect";
import PopUpAccounts from "../components/PopUpAccounts";
import Axios from "axios";
import FlashMessages from "../helpers/FlashMessages";
import { Token } from "../tmp/Token";
import { errorMessage, successMessage } from "../tmp/Messages";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import AccountDAO from "../DAO/AccountDAO";
import UserDAO from "../DAO/UserDAO";
import ContactDAO from "../DAO/ContactDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import FromLoading from "../helpers/FromLoading";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { notify } from "../helpers/FlashMessage";
import CustomInput from "../components/CustomInput";

class AddContactsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manager: "",
            id_manager: "",
            error_manager: false,

            account: "",
            accounts: [],
            id_account: "",
            account_error: false,
            accountItemsNumber: 0,

            first_name: "",
            first_name_error: false,

            last_name: "",
            last_name_error: false,

            title: "",

            phone: "",

            email: "",
            email_error: false,

            poste: "",

            service: "",

            category: "",
            category_error: false,

            ready: false,
            users: [],
            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            contact_id: 0,

            loading: false,
            account_loading: false,
        };
    }

    componentDidMount() {
        document.title = "Contacts - Sobrus Labs";
        const id = this.props.match.params.id;
        const id_account = this.props.match.params.id_account;
        if (id) {
            this.setState({ contact_id: id });
            this.get();
        }
        if (id_account) {
            this.getAccount();
        }
        this.getUsers();
        !id && this.getConnectedUserInfos();
    }

    getAccount = async () => {
        try {
            const id_account = this.props.match.params.id_account;
            const response = await AccountDAO.getAccountById(id_account);
            this.setAccount({ id: response.data.id, libelle: response.data.libelle });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ContactDAO.getContactById(id, {});
            const object = response.data;
            this.setState({
                id_manager: object.gestionnaire_id ? object.gestionnaire_id : "",
                manager: object.gestionnaire ? object.gestionnaire : "",
                id_account: object.compte_id ? object.compte_id : "",
                account: object.compte ? object.compte : "",
                first_name: object.prenom ? object.prenom : "",
                last_name: object.nom ? object.nom : "",
                title: object.titre ? object.titre : "",
                phone: object.portable ? object.portable : "",
                email: object.email ? object.email : "",
                poste: object.poste ? object.poste : "",
                service: object.service ? object.service : "",
                category: object.categorie ? object.categorie : "",
            });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequired() {
        const { pageNumber, manager, users, pharmacy, itemsNumber, searchedText, id_manager, error_manager, account, id_account, account_error, first_name, first_name_error, last_name, last_name_error, title, phone, email, email_error, poste, service, category, category_error } = this.state;

        if (category && category.length !== 0 && last_name && last_name.length !== 0 && first_name && first_name.length !== 0 && id_manager && id_manager.length !== 0 && id_account && id_account.length !== 0) {
            const data = {
                nom: last_name,
                prenom: first_name,
                portable: phone,
                titre: title,
                categorie: category,
                service: service,
                poste: poste,
                utilisateur: id_manager,
                compte: String(id_account),
            };
            if (email && email.length !== 0) {
                const expression = /\S+@\S+\.\S+/;
                if (expression.test(String(email).toLowerCase())) {
                    data["email"] = email;
                    return data;
                } else {
                    this.setState({ email_error: true });
                    return null;
                }
            } else {
                return data;
            }
            return data;
        } else {
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!category || (category && category.length === 0)) {
                this.setState({ category_error: true });
            }
            if (!last_name || (last_name && last_name.length === 0)) {
                this.setState({ last_name_error: true });
            }
            if (!first_name || (first_name && first_name.length === 0)) {
                this.setState({ first_name_error: true });
            }
            if (!id_account || (id_account && id_account.length === 0)) {
                this.setState({ account_error: true });
            }
            if (!id_manager || (id_manager && id_manager.length === 0)) {
                this.setState({ error_manager: true });
            }
            return null;
        }
    }

    async add() {
        try {
            const data = this.verifyRequired();
            if (data) {
                this.setState({ loading: true });
                const response = await ContactDAO.addContact({}, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status == "ok") {
                    notify({ msg: MessagesText.contact.add, type: "success" });
                    this.props.history.push(`${RoutesPath.contact.details}/${response.data.contact.id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async update() {
        try {
            const data = this.verifyRequired();
            if (data) {
                const id = this.props.match.params.id;
                this.setState({ loading: true });
                const response = await ContactDAO.updateContact(id, {}, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status == "ok") {
                    notify({ msg: MessagesText.contact.update, type: "success" });
                    this.props.history.push(`${RoutesPath.contact.details}/${response.data.contact.id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ account: value.libelle, id_account: value.id });
        this.closePopUp();
    }

    setAccount(value) {
        this.setState({ account: `${value.libelle} (${value.ville ? value.ville : ""})`, id_account: value.id, account_error: false });
    }

    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }

    async getAccounts(data, pageNumber = 1) {
        try {
            this.setState({ account_loading: true });
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ account_loading: false });
            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ account_loading: false });
            this.setState({ is_loaded: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const {
            account_loading,
            loading,
            contact_id,
            accounts,
            accountItemsNumber,
            users,
            id_manager,
            error_manager,
            account,
            account_error,
            first_name,
            first_name_error,
            last_name,
            last_name_error,
            title,
            phone,
            email,
            email_error,
            poste,
            service,
            category,
            category_error,
            errorMessage,
        } = this.state;
        const PageTitle = contact_id ? `Modifier contact : ${first_name} ${last_name}` : "Créer un nouveau contact";
        const subPageTitle = contact_id ? `Modifier contact` : "Créer un contact";

        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={PageTitle}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => (contact_id != 0 ? this.update() : this.add())}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{subPageTitle}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <CustomSelect
                                    error={error_manager}
                                    onChange={(value) => {
                                        this.setState({ id_manager: value.target.value, error_manager: false });
                                    }}
                                    selectedValue={id_manager}
                                    label="Gestionnaire *"
                                >
                                    <option defaultValue></option>
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key1_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={first_name_error}
                                    onChange={(value) => {
                                        this.setState({ first_name: value.target.value, first_name_error: false });
                                    }}
                                    defaultValue={first_name}
                                    value={first_name}
                                    id="first_name"
                                    label="Prénom *"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ title: value.target.value });
                                    }}
                                    selectedValue={title}
                                    label="Titre"
                                >
                                    <option defaultValue></option>
                                    <option value="Monsieur">Monsieur</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Docteur">Docteur</option>
                                    <option value="Professeur">Professeur</option>
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={email_error}
                                    onChange={(value) => {
                                        this.setState({ email: value.target.value, email_error: false });
                                    }}
                                    defaultValue={email}
                                    value={email}
                                    id="email"
                                    label="E-mail"
                                    errorMessage="Saisissez une adresse e-mail valide"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ poste: value.target.value });
                                    }}
                                    defaultValue={poste}
                                    value={poste}
                                    id="poste"
                                    label="Poste"
                                ></CustomInput>
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <PopUpGlobal
                                    name="account"
                                    label={"Compte*"}
                                    setValue={this.setAccount.bind(this)}
                                    reset={this.resetAccount.bind(this)}
                                    value={account}
                                    data={accounts}
                                    propertyName={"libelle"}
                                    searchData={this.getAccounts.bind(this)}
                                    itemsNumber={accountItemsNumber}
                                    getData={() => this.getAccounts()}
                                    error={account_error}
                                    modalTitle="Choisissez un compte"
                                    loading={account_loading}
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={last_name_error}
                                    onChange={(value) => {
                                        this.setState({ last_name: value.target.value, last_name_error: false });
                                    }}
                                    defaultValue={last_name}
                                    value={last_name}
                                    id="last_name"
                                    label="Nom *"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    operatorsType="category"
                                    error={category_error}
                                    onChange={(value) => {
                                        this.setState({ category: value.target.value, category_error: false });
                                    }}
                                    selectedValue={category}
                                    label="Catégorie *"
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ phone: value.target.value });
                                    }}
                                    defaultValue={phone}
                                    value={phone}
                                    id="phone"
                                    label="Portable"
                                ></CustomInput>
                            </InputContainer>
                            <InputContainer>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ service: value.target.value });
                                    }}
                                    defaultValue={service}
                                    value={service}
                                    id="service"
                                    label="Service"
                                ></CustomInput>
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 30%;
    font-family: Roboto;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: #284358;
`;
const Reset_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 27px;
    height: 20px;
    width: 20px;
`;

const Search_btn = styled.div`
    position: absolute;
    top: 14px;
    right: 7px;
    height: 20px;
    width: 20px;
`;
const Svg = styled.svg`
    cursor: pointer;
`;
const Input = styled.input`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    font-size: 14px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px #bbc8d3;
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    ${"" /* &:focus ~ ${PopUp}{
        display:block;
    }; */}
    &:focus ~ ${Label} , &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const Button = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: transparent;
`;
const ErrorMessage = styled.div`
    height: 50px;
    width: 100%;
    background: #ff4a4a;
    text-align: center;
    padding-top: 14px;
`;
const ErrorMessageText = styled.div`
    color: #fff;
    display: inline-block;
    vertical-align: top;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(AddContactsPage);
