import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpInput from "../components/PopUpInput";
import Axios from "axios";
import CustomColorInput from "../components/CustomColorInput";
import CustomDatePicker from "../components/CustomDatePicker";
import PopUpGlobal from "../components/PopUpGlobal.js";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { Token } from "../tmp/Token";
import { errorMessage } from "../tmp/Messages";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from "../env";

class UpdateEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relation_type: "Pharmacie",
            relation_type_error: false,
            account_type: "",
            account_type_error: false,
            resource: "",
            id_resource: "",
            error_resource: false,
            wording: "",
            error_wording: false,
            category: null,
            code: "",
            id_manager: "",
            error_manager: false,
            ready: false,
            users: [],

            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            contacts: [],

            name: "",
            name_error: false,
            startDate: new Date(),
            startDate_error: false,
            endDate: new Date(),
            endDate_error: false,
            type: "",
            desc: "",
            color: "",
            place: "",
            contact: "",
            contact_id: "",
            contact_error: false,
            addRapport: false,

            event_id: "",
            visitors: [],
            visitor: "",
            visitor_id: "",
            visited_at: new Date(),
            doctor_present: "Aucun",
            channel: "Terrain",
            concurrent_actions: [],
            concurrent_action: "",
            concurrent_action_id: "",
            revisited_at: new Date(),
            comment: "",
            event_goals: [],
            event_goal: "",
            event_goal_id: "",
            concurrents: [],
            concurrent: "",
            concurrent_id: "",
            provider: "",
            provider_id: "",
            range_products: [],
            range_product: "",
            range_product_id: "",
            sub_range_products: [],
            sub_range_product: "",
            sub_range_product_id: "",

            account: "",
            accounts: [],
            id_account: "",
            account_error: false,
            accountItemsNumber: 0,
        };
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        this.getEvent();
    }
    async getEvent() {
        const id = this.props.match.params.id;

        const headers = {
            "Content-type": "application/json",
            Authorization: "BEARER " + Token,
        };

        const response = await Axios.get(BASE_URL + "/api/event/" + id, { headers: headers });
        if (response.status == 200) {
            const event = response.data.event;
            const startDate = moment(event.date_debut);
            const endDate = moment(event.date_fin);
            this.setState({
                name: event.nom,
                startDate: new Date(startDate.format("YYYY"), startDate.format("MM"), startDate.format("DD"), startDate.format("HH"), startDate.format("mm"), startDate.format("ss")),
                endDate: new Date(endDate.format("YYYY"), endDate.format("MM"), endDate.format("DD"), endDate.format("HH"), endDate.format("mm"), endDate.format("ss")),
                type: event.type,
                desc: event.description,
                color: event.couleur,
                place: event.lieu,
                account: event.compte,
                contact: event.contact_nom,
                id_account: event.compte_id,
                id_contact: event.contact_id,
            });
        } else {
        }
    }
    async saveData() {
        try {
            const id = this.props.match.params.id;
            const { addRapport, startDate, endDate, name, desc, type, color, place, account, contact, id_contact, id_account } = this.state;
            if (name.length != 0 && startDate.length != 0 && endDate.length != 0 && id_account.length != 0 && id_contact.length != 0) {
                //({startDate,endDate,name,desc,type,color})
                const headers = {
                    "Content-type": "application/json",
                    Authorization: "BEARER " + Token,
                };
                const data = {
                    nom: name,
                    couleur: color,
                    type: type,
                    lieu: place,
                    description: desc,
                    date_debut: startDate,
                    date_fin: endDate,
                    compte: id_account,
                    contact: id_contact,
                };
                const response = await Axios.put(BASE_URL + "/api/event/" + id, data, { headers: headers });
                if (response.status == 200) {
                    if (response.data.status == "ok") {
                        this.props.history.push(`${RoutesPath.calender.details}/${id}`, { successMessage: "L'événement a été modifié avec succès" });
                    }
                } else {
                    this.showError(response.data.msg);
                }
            } else {
                if (name.length == 0) {
                    this.setState({ name_error: true });
                }
                if (startDate.length == 0) {
                    this.setState({ startDate_error: true });
                }
                if (endDate.length == 0) {
                    this.setState({ endDate_error: true });
                }
                if (id_account.length == 0) {
                    this.setState({ account_error: true });
                }
                if (id_contact.length == 0) {
                    this.setState({ contact_error: true });
                }
            }
        } catch (error) {
            this.showError(error.message);
        }
    }

    showError(message) {
        this.setState({ errorMessage: message });
        setTimeout(() => {
            this.setState({ errorMessage: "" });
        }, 3000);
    }

    setAccount(value) {
        this.setState({ account: `${value.libelle} (${value.ville ? value.ville : ""})`, id_account: value.id, account_error: false });
    }
    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }

    async getAccount(data, pageNumber = 1) {
        const headers = {
            "Content-type": "application/json",
            Authorization: "BEARER " + Token,
        };
        const response = await Axios.post(BASE_URL + "/api/comptes/" + pageNumber, data, { headers: headers });
        if (response.status == 200) {
            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } else {
        }
    }

    setContact(value) {
        this.setState({ contact: `${value["fullname"]} (${value["compte"] ? value["compte"] : ""})`, id_contact: value.id, contact_error: false });
    }
    resetContact() {
        this.setState({ contact: "", id_contact: "" });
    }

    async getContacts(data, pageNumber = 1) {
        const headers = {
            "Content-type": "application/json",
            Authorization: "BEARER " + Token,
        };
        const response = await Axios.post(BASE_URL + "/api/contacts/" + pageNumber, data, { headers: headers });
        if (response.status == 200) {
            this.setState({ contacts: response.data.contacts, contactItemsNumber: response.data.total });
        } else {
        }
    }

    render() {
        const {
            contact_error,
            account_error,
            endDate_error,
            startDate_error,
            name_error,
            contactItemsNumber,
            accountItemsNumber,
            contact_id,
            account_id,
            contact,
            account,
            place,
            color,
            desc,
            type,
            name,
            errorMessage,
            code,
            category,
            accounts,
            contacts,
            addRapport,
            visitor,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action,
            revisited_at,
            comment,
            event_goal,
            event_goals,
            concurrent,
            concurrents,
            provider,
            range_product,
            range_products,
            sub_range_product,
            sub_range_products,
        } = this.state;

        const StartDate = ({ value, onClick }) => <CustomDatePicker error={startDate_error} onClick={onClick} defaultValue={value} id="name1" label={"Date de début*"}></CustomDatePicker>;
        const EndDate = ({ value, onClick }) => <CustomDatePicker error={endDate_error} onClick={onClick} defaultValue={value} id="name2" label={"Date de fin*"}></CustomDatePicker>;
        const renderDayContents = (day, date) => {
            const tooltipText = `Tooltip for date: ${date}`;
            return (
                <span style={{ color: "red" }} title={tooltipText}>
                    {day}
                </span>
            );
        };
        const title = name ? name : "";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                <TopOptions title={"Modifier l'événement : " + title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.saveData()}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Modifier l'événement</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <Left_Input_container>
                                <CustomInput
                                    error={name_error}
                                    onChange={(value) => {
                                        this.setState({ name: value.target.value, name_error: false });
                                    }}
                                    defaultValue={name}
                                    id="name"
                                    label="Nom d'événement*"
                                ></CustomInput>
                            </Left_Input_container>
                            <Right_input_container>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ desc: value.target.value });
                                    }}
                                    defaultValue={desc}
                                    id="desc"
                                    label="Description"
                                ></CustomInput>
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    selected={this.state.startDate}
                                    onChange={(date) => {
                                        this.setState({
                                            startDate: date,
                                            startDate_error: false,
                                        });
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="yyyy-MM-d HH:mm"
                                    todayButton="Aujourd'hui"
                                    popperPlacement="bottom-center"
                                    showWeekNumbers
                                    customInput={<StartDate />}
                                    renderDayContents={renderDayContents}
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    selected={this.state.endDate}
                                    onChange={(date) => {
                                        this.setState({
                                            endDate: date,
                                            endDate_error: false,
                                        });
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="yyyy-MM-d HH:mm"
                                    todayButton="Aujourd'hui"
                                    popperPlacement="bottom-center"
                                    showWeekNumbers
                                    customInput={<EndDate />}
                                    renderDayContents={renderDayContents}
                                />
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ type: value.target.value });
                                    }}
                                    selectedValue={type}
                                    label="Type"
                                >
                                    <option defaultValue></option>
                                    <option value="Aucun">Aucun</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                </CustomSelect>
                            </Left_Input_container>
                            <Right_input_container>
                                <CustomColorInput
                                    onChange={(color) => {
                                        this.setState({ color: color.hex });
                                    }}
                                ></CustomColorInput>
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal
                                    name="account"
                                    label={"Compte*"}
                                    setValue={this.setAccount.bind(this)}
                                    reset={this.resetAccount.bind(this)}
                                    value={account}
                                    data={accounts}
                                    propertyName={"libelle"}
                                    searchData={this.getAccount.bind(this)}
                                    itemsNumber={accountItemsNumber}
                                    getData={() => this.getAccount()}
                                    error={account_error}
                                    modalTitle="Choisissez un compte"
                                />
                                {/* <CustomSelect onChange={(value) => { this.setState({ account: value.target.value }) }} selectedValue={account} label="Compte">
                                    <option defaultValue></option>
                                    {
                                        accounts.length != 0 && (
                                            accounts.map((account,index) => {
                                                return (
                                                    <option key={"key2_"+index} value={account.id} >{account.libelle}</option>
                                                );
                                            })
                                        )
                                    }
                                </CustomSelect> */}
                            </Left_Input_container>
                            <Right_input_container>
                                <CustomInput
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ place: value.target.value });
                                    }}
                                    defaultValue={place}
                                    id="place"
                                    label="Lieu d'événement"
                                ></CustomInput>
                            </Right_input_container>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <PopUpGlobal
                                    name="contact"
                                    label={"Contact"}
                                    setValue={this.setContact.bind(this)}
                                    reset={this.resetContact.bind(this)}
                                    value={contact}
                                    data={contacts}
                                    propertyName={"nom"}
                                    searchData={this.getContacts.bind(this)}
                                    itemsNumber={contactItemsNumber}
                                    getData={() => this.getContacts()}
                                    error={contact_error}
                                    modalTitle="Choisissez un contact"
                                />
                            </Left_Input_container>
                            <Right_input_container></Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 30%;
    font-family: Roboto;
    font-size: 14px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: #284358;
`;
const Reset_btn = styled.div`
    position: absolute;
    top: 11px;
    right: 9px;
`;
const Svg = styled.svg`
    cursor: pointer;
`;
const Input = styled.input`
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    font-size: 14px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px #bbc8d3;
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    ${"" /* &:focus ~ ${PopUp}{
        display:block;
    }; */}
    &:focus ~ ${Label} , &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: #00beb0;
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const Button = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: transparent;
`;
const ErrorMessage = styled.div`
    height: 50px;
    width: 100%;
    background: #ff4a4a;
    text-align: center;
    padding-top: 14px;
`;
const ErrorMessageText = styled.div`
    color: #fff;
    display: inline-block;
    vertical-align: top;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(UpdateEventPage);
