import React, { Component } from "react";
import styled from "styled-components";
import UserDAO from "../DAO/UserDAO";
import TableModel from "../components/TableModel";
import { connect } from "react-redux";

const thead = [
    { property: "nom", label: "Nom", type: "string" },
    { property: "prenom", label: "Prénom", type: "string" },
    { property: "device_type", label: "L’appareil", type: "string" },
    { property: "ip", label: "Adresse IP", type: "ip" },
    { property: "heure", label: "Heure", type: "date" },
];

class ConnectionHistoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [] });

        this.getAll();
    }
    async getAll() {
        try {
            const { pageNumber } = this.state;
            const params = {};
            const response = await UserDAO.getALLConnectionsHistory(pageNumber, params);
            this.setState({ is_loaded: true, data: response.data.ressources, totalItems: response.data.total }, () => this.state.data);
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }

    render() {
        const { is_loaded, data, pageNumber, totalItems } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Historiques des connexions</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient la liste des connexions d'utilisateurs.</Block_content_title>
                    </Block_content>
                </First_block>

                <TableModel
                    title="Historiques des connexions"
                    onReload={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => {},
                        actions: [
                            // { title: "modifier",onClick:(id)=>{} },
                            // { title: "archiver",onClick:(id)=>{} }
                        ],
                    }}
                    tfoot={{
                        colSpan: 8,
                        pageNumber: pageNumber,
                        totalItems: totalItems,
                        nextPage: () => {
                            this.setState({ pageNumber: pageNumber + 1, is_loaded: false }, () => {
                                this.getAll();
                            });
                        },
                        PrevPage: () => {
                            this.setState({ pageNumber: pageNumber - 1, is_loaded: false }, () => {
                                this.getAll();
                            });
                        },
                    }}
                    {...this.props}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state,
    };
};

export default connect(mapStateToProps)(ConnectionHistoryPage);

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
