import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import CustomSelect from "./CustomSelect";
import moment from "moment";
import NoResultCas from "../helpers/NoResultCas";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import InventoryDAO from "../DAO/InventoryDAO";
import AccountDAO from "../DAO/AccountDAO";
import CommonDAO from "../DAO/CommonDAO";
import { RoutesPath } from "../values/RoutesPath";
import DataLoadingError from "../components/DataLoadingError";
import SearchSelect from "../helpers/SearchSelect";
import { DatePicker } from "../components/CustomDatePicker";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";

class InventoryTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            data: null,
            totalItems: 0,
            search: false,
            account_type: "",

            account_id: "",
            date: "",
            created_by: "",
            created_at: "",
            status: "",

            account_id_operator: "is",
            date_operator: "like",
            created_by_operator: "like",
            created_at_operator: "like",
            status_operator: "is",

            search: false,
            advancedSearch: false,

            is_loaded: false,
            error: {
                is: false,
                message: "",
            },
            selected_account: {},
            selected_user: {},
        };
    }

    componentDidMount() {
        this.setState({ is_loaded: false }, () => this.getAll());
        this.getAccounts();
    }

    async getAll() {
        try {
            this.setLoadingError({ is: false, message: "" });

            const { search, advancedSearch, pageNumber, account_type, date, created_by, created_at, status, account_id, status_operator, created_at_operator, created_by_operator, date_operator, account_id_operator } = this.state;
            let data = InventoryDAO.getSearchData({ search, advancedSearch, account_type, date, created_by, created_at, status, account_id, status_operator, created_at_operator, created_by_operator, date_operator, account_id_operator });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await InventoryDAO.getALLInventories(pageNumber, {}, data);
            this.setState({ is_loaded: true, data: response.data, totalItems: response.data.total });
        } catch (error) {
            this.setState({ is_loaded: true });
            this.setLoadingError({ is: true, message: error.message });
        }
    }

    setLoadingError(error) {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                is: error.is,
                message: error.message,
            },
        }));
    }
    async getAccounts() {
        try {
            const data = {};
            const response = await AccountDAO.getALLAccounts(1, {}, data);
            this.setState({ accounts: response.data.comptes });
        } catch (error) {
            this.setState({ is_loaded: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    showStatus(status) {
        if (status == "Brouillon") {
            return (
                <p className="tag orange" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else if (status == "Annulé") {
            return (
                <p className="tag red" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else if (status == "Complété") {
            return (
                <p className="tag green" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        } else {
            return (
                <p className="tag green" style={{ whiteSpace: "nowrap" }}>
                    {status}
                </p>
            );
        }
    }
    sort(property) {
        const sortedData = CommonDAO.sortData(this.state.data.inventaires, property);
        const data = this.state.data;
        data.inventaires = [...sortedData];
        this.setState({ data });
    }
    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.inventaires, property);
        const data = this.state.data;
        data.inventaires = [...sortedData];
        this.setState({ data });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    resetSearch() {
        this.setState(
            {
                is_loaded: false,
                account_id: "",
                date: "",
                created_by: "",
                created_at: "",
                status: "",

                account_id_operator: "is",
                date_operator: "like",
                created_by_operator: "like",
                created_at_operator: "like",
                status_operator: "is",

                selected_account: {},
                selected_user: {},
            },
            () => this.getAll()
        );
    }

    render() {
        const { error, is_loaded, advancedSearch, status_operator, created_at_operator, created_by_operator, date_operator, account_id_operator, data, pageNumber, totalItems, search, account_type, date, created_by, created_at, account_id, status, accounts } = this.state;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Liste des inventaires</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                        <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {advancedSearch && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff"></Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ account_id_operator: value.target.value });
                                                }}
                                                selectedValue={account_id_operator}
                                                label="Compte"
                                            >
                                                <option value="is" defaultValue>
                                                    est
                                                </option>
                                                <option value="is_not">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ date_operator: value.target.value });
                                                }}
                                                selectedValue={date_operator}
                                                label="Date"
                                            >
                                                <option value="like" defaultValue>
                                                    contient
                                                </option>
                                                <option value="not_like">ne contient pas</option>
                                                <option value="starts_with">commence par</option>
                                                <option value="end_by">se termine par</option>
                                                <option value="is">est</option>
                                                <option value="is_not">n'est pas</option>
                                                <option value="null">est vide</option>
                                                <option value="not_null">n'est pas vide</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ created_by_operator: value.target.value });
                                                }}
                                                selectedValue={created_by_operator}
                                                label="Créé par"
                                            >
                                                <option value="like" defaultValue>
                                                    contient
                                                </option>
                                                <option value="not_like">ne contient pas</option>
                                                <option value="starts_with">commence par</option>
                                                <option value="end_by">se termine par</option>
                                                <option value="is">est</option>
                                                <option value="is_not">n'est pas</option>
                                                <option value="null">est vide</option>
                                                <option value="not_null">n'est pas vide</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ created_at_operator: value.target.value });
                                                }}
                                                selectedValue={created_at_operator}
                                                label="Créé le"
                                            >
                                                <option value="like" defaultValue>
                                                    contient
                                                </option>
                                                <option value="not_like">ne contient pas</option>
                                                <option value="starts_with">commence par</option>
                                                <option value="end_by">se termine par</option>
                                                <option value="is">est</option>
                                                <option value="is_not">n'est pas</option>
                                                <option value="null">est vide</option>
                                                <option value="not_null">n'est pas vide</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ status_operator: value.target.value });
                                                }}
                                                selectedValue={status_operator}
                                                label="Statut"
                                            >
                                                <option value="is" defaultValue>
                                                    est
                                                </option>
                                                <option value="is_not">n'est pas</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                            <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    fill="#fff"
                                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                ></path>
                                            </Search_btn_icon>
                                        </Search_btn>
                                    </Th>
                                </tr>
                            )}

                            {(search || advancedSearch) && (
                                <tr className="table_th_orderable">
                                    <Th backgroundColor="#fff"></Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl="/api/comptes"
                                                HTTPMethod="post"
                                                receivedObject="comptes"
                                                optionLabelProperty="libelle"
                                                optionValueProperty="id"
                                                value={this.state.selected_account}
                                                placeholder="Compte"
                                                onChange={(e) => {
                                                    this.setState({ selected_account: e, account_id: e && e.value });
                                                }}
                                            />
                                            {/* <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ account_id: value.target.value });
                                                }}
                                                selectedValue={account_id}
                                                label="Compte"
                                            >
                                                <option value="" defaultValue></option>
                                                {accounts.map((account) => {
                                                    return <option value={account.id}>{account.libelle}</option>;
                                                })}
                                            </CustomSelect> */}
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <DatePicker
                                                selected={date}
                                                onChange={(date) => {
                                                    this.setState({
                                                        date: date,
                                                    });
                                                }}
                                                label="Date"
                                                id="date"
                                                dateFormat="yyyy-MM-dd"
                                                showTimeSelect={false}
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <SearchSelect
                                                fetchedUrl="/api/utilisateurs"
                                                HTTPMethod="post"
                                                receivedObject="utilisateurs"
                                                optionLabelProperty="fullname"
                                                optionValueProperty="id"
                                                value={this.state.selected_user}
                                                placeholder="Téléchargé par"
                                                onChange={(e) => {
                                                    this.setState({ selected_user: e, created_by: e && e.value });
                                                }}
                                            />
                                            {/* <CustomInput
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                onChange={(value) => {
                                                    this.setState({ created_by: value.target.value });
                                                }}
                                                label="Créé par"
                                            ></CustomInput>
                                            <Search_icon>
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="currentColor"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Svg>
                                            </Search_icon> */}
                                        </Search_input_container>
                                    </Th>

                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <DatePicker
                                                selected={created_at}
                                                onChange={(date) => {
                                                    this.setState({
                                                        created_at: date,
                                                    });
                                                }}
                                                label="Créé le"
                                                id="created_at"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                            />
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        <Search_input_container>
                                            <CustomSelect
                                                onKeyDown={this.handleKeyDown.bind(this)}
                                                error={false}
                                                onChange={(value) => {
                                                    this.setState({ status: value.target.value });
                                                }}
                                                selectedValue={status}
                                                label="Statut"
                                            >
                                                <option value="" defaultValue></option>
                                                <option value="Brouillon">Brouillon</option>
                                                <option value="Complété">Complété</option>
                                                <option value="Annulé">Annulé</option>
                                            </CustomSelect>
                                        </Search_input_container>
                                    </Th>
                                    <Th backgroundColor="#fff">
                                        {search && (
                                            <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path
                                                        fill="#fff"
                                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                    ></path>
                                                </Search_btn_icon>
                                            </Search_btn>
                                        )}
                                    </Th>
                                </tr>
                            )}
                            <tr className="table_th_orderable">
                                <Th>
                                    ID
                                </Th>
                                <Th>
                                    <OrderUp onClick={() => this.sort("compte")} />
                                    <OrderDown onClick={() => this.reverse("compte")} />
                                    Compte
                                </Th>
                                <Th>
                                    <OrderUp onClick={() => this.sort("date")} />
                                    <OrderDown onClick={() => this.reverse("date")} />
                                    Date
                                </Th>
                                <Th>
                                    <OrderUp onClick={() => this.sort("cree_par")} />
                                    <OrderDown onClick={() => this.reverse("cree_par")} />
                                    Créé par
                                </Th>
                                <Th>
                                    <OrderUp onClick={() => this.sort("cree_le")} />
                                    <OrderDown onClick={() => this.reverse("cree_le")} />
                                    Créé le
                                </Th>
                                <Th>
                                    <OrderUp onClick={() => this.sort("statut")} />
                                    <OrderDown onClick={() => this.reverse("statut")} />
                                    Statut
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>
                        {!is_loaded && (
                            <tbody>
                                {[1, 2, 3].map((index) => {
                                    return (
                                        <Tr key={"key__" + index}>
                                            <Td>
                                                <ContentLoader width="40" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="40" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td>
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            </Td>
                                            <Td className="table_action">
                                                <a className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </tbody>
                        )}
                        {is_loaded && data && data.inventaires.length != 0 && (
                            <tbody>
                                {data &&
                                    data.inventaires.map((inventory, index) => {
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {inventory.id}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {inventory.compte}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {moment(inventory.date).format("YYYY-MM-DD")}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {inventory.cree_par}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {moment(inventory.cree_le).format("YYYY-MM-DD HH:mm")}
                                                </Td>
                                                <Td
                                                    onClick={() => {
                                                        acl.inventory.details() && this.props.history.push(`${RoutesPath.inventory.details}/${inventory.id}`);
                                                    }}
                                                >
                                                    {this.showStatus(inventory.statut)}
                                                </Td>
                                                <Td className="table_action">
                                                    {acl.inventory.update() && (
                                                        <a
                                                            onClick={() => {
                                                                this.props.history.push(`${RoutesPath.inventory.update}/${inventory.id}`);
                                                            }}
                                                            className="sprite-table-edit-btn  tooltip"
                                                            title="Modifier"
                                                            data-confirm_msg
                                                            data-removeable_element="parents::tr"
                                                            target="_blank"
                                                        />
                                                    )}
                                                </Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td colSpan={8} className="table_pagination">
                                        <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                        <div style={{ display: "inline-block" }}>
                                            {pageNumber > 1 && (
                                                <a
                                                    className="sprite-table-prev-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                            <span className="table_page">{pageNumber}</span>
                                            {pageNumber < totalItems / 20 && (
                                                <a
                                                    className="sprite-table-next-btn"
                                                    onClick={() => {
                                                        this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                            this.getAll();
                                                        });
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                            </tbody>
                        )}
                    </table>
                    {is_loaded && error.is && <DataLoadingError message={error.message} />}

                    {is_loaded && data && data.inventaires.length == 0 && (
                        <NoResultCas btnOnClick={() => acl.inventory.add() && this.props.history.push(`${RoutesPath.inventory.add}`)} btnText={acl.inventory.add() && "Créer un inventaire"} textInfos="Vous n’avez aucun inventaire pour le moment"></NoResultCas>
                    )}
                </div>
            </Bloc>
        );
    }
}

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
export default InventoryTable;
