import React, { Component } from "react";
import Barcode from "react-barcode";
import styled from "@react-pdf/styled-components";
import ReactDOMServer from "react-dom/server";
import BarCodeComponent from "../components/BarCodeComponent";

import { Document, Page, Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    body: {
        padding: 20,
        paddingBottom: 80,
    },
    title: {
        fontSize: 10,
        textAlign: "left",
        fontFamily: "OpenSans-Bold",
        color: "#3b3b3b",
        marginBottom: 3,
    },
    author: {
        fontSize: 12,
        textAlign: "center",
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: "Oswald",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    header: {
        flexDirection: "row",
        marginBottom: 24,
    },
    item_container: {
        flexDirection: "row",
        marginBottom: 2,
        alignItems: "center",
    },
    addressItem: {
        flexDirection: "row",
        marginBottom: 2,
        alignItems: "center",
    },
    item_icon: {
        height: 20,
        width: 20,
    },
    item_text: {
        color: "#3b3b3b",
        fontSize: 9,
        marginLeft: 4,
    },
    AddressText: {
        color: "#3b3b3b",
        fontSize: 9,
        marginLeft: 4,
    },
    leftSide: {
        width: "50%",
    },
    rightSide: {
        width: "50%",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    barCodeImg: {
        height: 80,
        width: "50%",
    },
    topInfos: {
        borderStyle: "solid",
        borderWidth: 0.5,
        borderColor: "#7189a8",
        flexDirection: "row",
        paddingBottom: 1,
    },
    col_1: {
        backgroundColor: "#12bbae",
        height: "100%",
        width: "30%",
        justifyContent: "center",
        alignItems: "center",
    },
    col_2: {
        height: "100%",
        width: "35%",
        paddingLeft: 5,
        justifyContent: "flex-start",
        paddingTop: 13,
        paddingBottom: 12,
    },
    col_3: {
        height: "100%",
        width: "35%",
        paddingLeft: 5,
        justifyContent: "flex-start",
        paddingTop: 13,
    },
    col_1_text: {
        fontSize: 20,
        color: "#fff",
        width: 150,
    },
    col_text: {
        color: "#3b3b3b",
        fontSize: 9,
        marginLeft: 3,
        marginTop: 2.5,
        flex: 1,
    },
    col_title: {
        color: "#3b3b3b",
        fontSize: 9,
        fontFamily: "OpenSans-Bold",
    },
    text_container: {
        flexDirection: "row",
        marginBottom: 5,
    },

    tableContainer: {
        marginTop: 20,
    },

    table: {
        display: "table",
        width: "auto",
    },
    headerTableRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#deeaf9",
    },

    tableRow: {
        margin: "auto",
        flexDirection: "row",
    },

    headerTableCol_1: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 0.3,
        borderLeftWidth: 0.5,
        borderColor: "#95a5b8",
        justifyContent: "center",
        alignItems: "center",
        height: 30,
        backgroundColor: "#deeaf9",
    },
    headerTableCol_2: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0.3,
        borderRightWidth: 0.5,
        borderColor: "#95a5b8",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#deeaf9",
    },
    tableCol_1: {
        width: "60%",
        borderStyle: "solid",
        borderWidth: 0.3,
        borderLeftWidth: 0.5,
        borderColor: "#95a5b8",
        paddingTop: 5,
        paddingLeft: 5,
        paddingBottom: 5,
    },
    tableCol_2: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0.3,
        borderRightWidth: 0.5,
        borderColor: "#95a5b8",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 5,
        paddingLeft: 5,
        paddingBottom: 5,
    },

    tableCell: {
        fontSize: 10,
        color: "#3b3b3b",
    },
    tableCell_container: {
        flexDirection: "row",
        marginBottom: 4,
    },
    tableCell_title: {
        color: "#3b3b3b",
        fontSize: 9,
        fontFamily: "OpenSans-Bold",
    },
    tableCell_value: {
        color: "#3b3b3b",
        fontSize: 9,
        marginTop: 2.5,
        marginLeft: 3,
        flex: 1,
    },

    footerContainer: {
        flex: 1,
        justifyContent: "flex-end",
    },
    footer: {
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
        position: "absolute",
        bottom: 30,
        left: 0,
        right: 0,
    },

    footerTitle: {
        fontSize: 10,
        fontFamily: "OpenSans-Bold",
        color: "#3b3b3b",
        marginBottom: 4,
    },
    footerText: {
        fontSize: 9,
        color: "#3b3b3b",
        marginBottom: 4,
    },
});
Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});
Font.register({
    family: "OpenSans-Bold",
    src: require("../fonts/OpenSans-Bold.ttf"),
});

const PdfDocument = (
    <Document>
        <Page style={styles.body} wrap={true}>
            {/*header*/}
            <View style={styles.header} fixed>
                {/*left side*/}
                <View style={styles.leftSide}>
                    <Text style={styles.title}>LABORATOIRE SOBRUS DEMO</Text>
                    {/*phone number*/}
                    <View style={styles.item_container}>
                        <Image style={styles.item_icon} source={require("../images/sprite_facture.png")}></Image>
                        <Text style={styles.item_text}>05 00 00 00 00</Text>
                    </View>
                    {/*email*/}
                    <View style={styles.item_container}>
                        <Image style={styles.item_icon} source={require("../images/sprite_facture2.png")}></Image>
                        <Text style={styles.item_text}>labs.demo@sobrus.com</Text>
                    </View>
                    {/*address*/}
                    <View style={styles.item_container}>
                        <Image style={styles.item_icon} source={require("../images/sprite_facture3.png")}></Image>
                        <Text style={styles.item_text}>TEMARA REU TETOUAN HARHOURA TEMARA</Text>
                    </View>
                </View>
                {/*right side*/}
                <View style={styles.rightSide}>
                    <BarCodeComponent></BarCodeComponent>
                </View>
            </View>

            {/*top infos*/}
            <View style={styles.topInfos}>
                {/*the first column*/}
                <View style={styles.col_1}>
                    <Text style={styles.col_1_text}>RAPPORT D’ACTIVITÉ</Text>
                </View>
                {/*the second column*/}
                <View style={styles.col_2}>
                    {/* company name */}
                    <View style={styles.text_container}>
                        <Text style={styles.col_title}>Nom campagne :</Text>
                        <Text style={styles.col_text}> comp test 1</Text>
                    </View>
                    {/* visitor name */}
                    <View style={styles.text_container}>
                        <Text style={styles.col_title}>Nom du visiteur :</Text>
                        <Text style={styles.col_text}> Z Admin. Sobrus Demo </Text>
                    </View>
                    {/* printed at */}
                    <View style={styles.text_container}>
                        <Text style={styles.col_title}>Imprimé le :</Text>
                        <Text style={styles.col_text}> 2019-10-17 14:52</Text>
                    </View>
                </View>
                {/* the col 3 */}
                <View style={styles.col_3}>
                    <View style={styles.text_container}>
                        <Text style={styles.col_title}>Période :</Text>
                        <Text style={styles.col_text}> De 2019-05-22 à 2019-05-23</Text>
                    </View>
                    <View style={styles.text_container}>
                        <Text style={styles.col_title}>Imprimé par :</Text>
                        <Text style={styles.col_text}> Z Admin. Sobrus Demo</Text>
                    </View>
                </View>
            </View>

            <View style={styles.tableContainer}>
                <View style={styles.table}>
                    {/* TableHeader */}
                    <View style={styles.headerTableRow}>
                        <View style={styles.headerTableCol_1}>
                            <Text style={styles.tableCell}>Clients visités</Text>
                        </View>
                        <View style={styles.headerTableCol_2}>
                            <Text style={styles.tableCell}>Cachet et signature</Text>
                        </View>
                    </View>
                    {/* table content */}

                    {/* Row 1 */}
                    <View style={styles.tableRow} wrap={false}>
                        <View style={styles.tableCol_1}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 2 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 3 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 4 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 5 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 7 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 8 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 9 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 10 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 11 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>

                    {/* Row 12 */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol_1} wrap={false}>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Compte :</Text>
                                <Text style={styles.tableCell_value}>Grande Pharmacie</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Ville :</Text>
                                <Text style={styles.tableCell_value}>KENITRA</Text>
                            </View>
                            <View style={styles.tableCell_container}>
                                <Text style={styles.tableCell_title}>Secteur :</Text>
                                <Text style={styles.tableCell_value}>AIN SEBAA</Text>
                            </View>
                        </View>
                        <View style={styles.tableCol_2} wrap={false}></View>
                    </View>
                </View>
            </View>

            <View style={styles.footer} wrap={false} fixed>
                <Text style={styles.footerTitle}>LABORATOIRE SOBRUS DEMO</Text>
                <Text style={styles.footerText}>Téléphone : 05 00 00 00 00 E-mail : labs.demo@sobrus.com Adresse : REU TETOUAN HARHOURA , TEMARA</Text>
                <Text style={styles.footerText}>CNSS : 0000000000 RC : 00000000000 IF : 0000000000</Text>
            </View>
        </Page>
    </Document>
);

export default PdfDocument;
