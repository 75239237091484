import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import ProductDAO from "../DAO/ProductDAO";
import CommonDAO from "../DAO/CommonDAO";
import { withRouter } from "react-router-dom";
import FromLoading from "../helpers/FromLoading";
import { FormContainer, Left, Right, FullRow, InputContainer } from "../helpers/formStyledComp";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";

/**
 *
 * this component can used for persist and update  account
 * if id_account not 0 means update
 * else mean persist
 *
 */

class AddProductSuggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            fields: {
                nom: "",
                code_barre: "",
                code_barre2: "",
                pph: "",
                ppv: "",
                taxe_achat: "", //tva 20
                taxe_vente: "", //tva 20
                fournisseur: "",
                categorie: "",
                gamme: "",
                sousgamme: "",
                forme_galenique: "",
                dci: "",
                produit_tableau: "",
                classe_therapeutique: "",
                necessite_prescription: false,
                produit_marche: false,
                est_remboursable: "",
                base_remboursement: "",
                presentation: "",
                excipients: "",
                posologie_adulte: "",
                posologie_enfant: "",
                contre_indication_conduite: "",
                contre_indication_allaitement: "",
                ref_interne_laboratoire: "",
                conditionnement: "",
                monograph: "",
                description: "",
                indications: "",
            },
            error: {
                nom: false,
                pph: false,
                ppv: false,
                taxe_achat: false,
                taxe_vente: false,
                fournisseur: false,
                forme_galenique: false,
                product_dci: false,
                product_ranges: false,
                product_sub_ranges: false,
                therapeutic_classes: false,
            },
            preload: {
                pharmaceutical_forms: [],
                product_ranges: [],
                product_sub_ranges: [],
                product_dci: [],
                therapeutic_classes: [],
                product_categories: [],
                providers: [],
                taxes: [],
                providers_total_items: 0,
                pharmaceutical_forms_total_items: 0,
                product_dci_total_items: 0,
                product_ranges_total_items: 0,
                product_sub_ranges_total_items: 0,
                therapeutic_classes_total_items: 0,
            },
            loading: false,
            fournisseur_name: "",
            forme_galenique_name: "",
            product_dci_name: "",
            product_range_name: "",
            product_sub_ranges_name: "",
            therapeutic_classes_name: "",
        };
    }

    componentDidMount() {
        document.title = "Produit - Sobrus Labs";
        this.preload();
    }

    render() {
        const { loading, errorMessage, fields, error, preload, fournisseur_name, forme_galenique_name, product_dci_name, product_range_name, product_sub_ranges_name, therapeutic_classes_name } = this.state;
        const id = this.props.match.params.product_id;

        const title = id ? `Suggérer le produit : ${fields.nom}` : "Suggérer un nouveau produit";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={this.add}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{"Suggérer un produit"}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Title_container>
                            <Title>Informations générales</Title>
                            <Bar></Bar>
                        </Title_container>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <CustomInput
                                        error={error.nom}
                                        onChange={(Value) => {
                                            this.setField("nom", Value.target.value);
                                            this.setFieldError("nom", false);
                                        }}
                                        value={fields.nom}
                                        defaultValue={fields.nom}
                                        id="nom"
                                        label="Nom*"
                                    ></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("code_barre2", Value.target.value)} value={fields.code_barre2} defaultValue={fields.code_barre2} id="code_barre2" label="Code barre 2"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <PopUpGlobal
                                        label={"Classe thérapeutique"}
                                        setValue={this.setTherapeuticClasse.bind(this)}
                                        reset={this.resetTherapeuticClasse.bind(this)}
                                        value={therapeutic_classes_name}
                                        data={preload.therapeutic_classes}
                                        propertyName={"nom"}
                                        searchData={this.getTherapeuticClasses.bind(this)}
                                        itemsNumber={preload.therapeutic_classes_total_items}
                                        getData={this.getTherapeuticClasses.bind(this)}
                                        error={error.therapeutic_classes}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("produit_tableau", Value.target.value)} selectedValue={fields.produit_tableau} label="Produit tableau">
                                        <option defaultValue></option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                    </CustomSelect>
                                </InputContainer>
                                <InputContainer>
                                    <PopUpGlobal
                                        label={"Gamme"}
                                        setValue={this.setProductRange.bind(this)}
                                        reset={this.resetProductRange.bind(this)}
                                        value={product_range_name}
                                        data={preload.product_ranges}
                                        propertyName={"nom"}
                                        searchData={this.getProductRanges.bind(this)}
                                        itemsNumber={preload.product_ranges_total_items}
                                        getData={this.getProductRanges.bind(this)}
                                        error={error.product_range}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <PopUpGlobal
                                        label={"Laboratoire"}
                                        setValue={this.setResource.bind(this)}
                                        reset={this.resetResource.bind(this)}
                                        value={fournisseur_name}
                                        data={preload.providers}
                                        propertyName={"nom"}
                                        searchData={this.getLaboratories.bind(this)}
                                        itemsNumber={preload.providers_total_items}
                                        getData={this.getLaboratories.bind(this)}
                                        error={error.fournisseur}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <div className="form_row">
                                        <CheckBoxLabel htmlFor="produit_marche" className style={{}}>
                                            Produit marché
                                        </CheckBoxLabel>
                                        <label htmlFor="produit_marche" className="checkbox" style={{ display: "block" }}>
                                            {/* <input
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.setField("produit_marche", "1");
                                                } else {
                                                    this.setField("produit_marche", "0");
                                                }
                                            }}
                                            type="hidden"
                                            name="produit_marche"
                                        /> */}
                                            <input
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setField("produit_marche", true);
                                                    } else {
                                                        this.setField("produit_marche", false);
                                                    }
                                                }}
                                                checked={fields.produit_marche}
                                                type="checkbox"
                                                name="produit_marche"
                                                id="produit_marche"
                                                defaultValue={fields.produit_marche}
                                                autoComplete="off"
                                            />
                                            <span className="checked" />
                                        </label>
                                    </div>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("code_barre", Value.target.value)} value={fields.code_barre} defaultValue={fields.code_barre} id="code_barre" label="Code barre"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("categorie", Value.target.value)} selectedValue={fields.categorie} label="Catégorie">
                                        <option defaultValue></option>
                                        {preload.product_categories.map((object) => (
                                            <option value={`${object.id}`}>{object.nom + (object.margin ? `(${parseFloat(object.margin).toFixed(3)}%)` : "")}</option>
                                        ))}
                                    </CustomSelect>
                                </InputContainer>
                                <InputContainer>
                                    {/* <CustomSelect error={false} onChange={(Value) => this.setField("forme_galenique", Value.target.value)} selectedValue={fields.forme_galenique} label="Forme galénique">
                                        <option defaultValue></option>
                                        {preload.pharmaceutical_forms.map((object) => (
                                            <option value={`${object.id}`}>{object.nom}</option>
                                        ))}
                                    </CustomSelect> */}
                                    <PopUpGlobal
                                        label={"Forme galénique"}
                                        setValue={this.setPharmaceuticalForms.bind(this)}
                                        reset={this.resetPharmaceuticalForms.bind(this)}
                                        value={forme_galenique_name}
                                        data={preload.pharmaceutical_forms}
                                        propertyName={"nom"}
                                        searchData={this.getPharmaceuticalForms.bind(this)}
                                        itemsNumber={preload.pharmaceutical_forms_total_items}
                                        getData={this.getPharmaceuticalForms.bind(this)}
                                        error={error.forme_galenique}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    {/* <CustomSelect error={false} onChange={(Value) => this.setField("dci", Value.target.value)} selectedValue={fields.dci} label="DCI">
                                        <option defaultValue></option>
                                        {preload.product_sub_ranges.map((object) => (
                                            <option value={`${object.id}`}>{object.nom}</option>
                                        ))}
                                    </CustomSelect> */}
                                    <PopUpGlobal
                                        label={"DCI"}
                                        setValue={this.setProductDci.bind(this)}
                                        reset={this.resetProductDci.bind(this)}
                                        value={product_dci_name}
                                        data={preload.product_dci}
                                        propertyName={"nom"}
                                        searchData={this.getProductDci.bind(this)}
                                        itemsNumber={preload.product_dci_total_items}
                                        getData={this.getProductDci.bind(this)}
                                        error={error.product_dci}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    {/* <CustomSelect error={false} onChange={(Value) => this.setField("sousgamme", Value.target.value)} selectedValue={fields.sousgamme} label="Sous-gamme">
                                        <option defaultValue></option>
                                        {preload.product_sub_ranges.map((object) => (
                                            <option value={`${object.id}`}>{object.nom}</option>
                                        ))}
                                    </CustomSelect> */}
                                    <PopUpGlobal
                                        label={"Sous-gamme"}
                                        setValue={this.setProductSubRange.bind(this)}
                                        reset={this.resetProductSubRange.bind(this)}
                                        value={product_sub_ranges_name}
                                        data={preload.product_sub_ranges}
                                        propertyName={"nom"}
                                        searchData={this.getProductSubRanges.bind(this)}
                                        itemsNumber={preload.product_sub_ranges_total_items}
                                        getData={this.getProductSubRanges.bind(this)}
                                        error={error.product_sub_ranges}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <div className="form_row">
                                        <CheckBoxLabel htmlFor="necessite_prescription" className style={{}}>
                                            Nécessite une prescription
                                        </CheckBoxLabel>
                                        <label htmlFor="necessite_prescription" className="checkbox" style={{ display: "block" }}>
                                            {/* <input
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.setField("necessite_prescription", true);
                                                } else {
                                                    this.setField("necessite_prescription", false);
                                                }
                                            }}
                                            type="hidden"
                                            name="necessite_prescription"
                                        /> */}
                                            <input
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        this.setField("necessite_prescription", true);
                                                    } else {
                                                        this.setField("necessite_prescription", false);
                                                    }
                                                }}
                                                checked={fields.necessite_prescription}
                                                type="checkbox"
                                                name="necessite_prescription"
                                                id="necessite_prescription"
                                                defaultValue={fields.necessite_prescription}
                                                autoComplete="off"
                                            />
                                            <span className="checked" />
                                        </label>
                                    </div>
                                </InputContainer>
                            </Right>
                        </FormContainer>

                        {/* stock info */}

                        <Title_containerMiddle inerMiddle>
                            <Title>Informations du stock</Title>
                            <Bar></Bar>
                        </Title_containerMiddle>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <CustomInput
                                        inputType={"number"}
                                        error={error.pph}
                                        onChange={(Value) => {
                                            this.setFieldError("pph", false);
                                            this.setField("pph", Value.target.value);
                                        }}
                                        value={fields.pph}
                                        defaultValue={fields.pph}
                                        id="pph"
                                        label="PPH (DHS)*"
                                    ></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect
                                        error={error.taxe_achat}
                                        onChange={(Value) => {
                                            this.setFieldError("taxe_achat", false);
                                            this.setField("taxe_achat", Value.target.value);
                                        }}
                                        selectedValue={fields.taxe_achat}
                                        label="Taxe sur achat*"
                                    >
                                        <option defaultValue></option>
                                        {preload.taxes &&
                                            preload.taxes.map((object, index) => {
                                                return <option value={object.id}>{`${object.nom}(${parseFloat(object.taxe).toFixed(2)}%)`}</option>;
                                            })}
                                    </CustomSelect>
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("est_remboursable", Value.target.value)} selectedValue={fields.est_remboursable} label="Est remboursable">
                                        <option defaultValue></option>
                                        <option value="Non">Non</option>
                                        <option value="Oui">Oui</option>
                                    </CustomSelect>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput
                                        inputType={"number"}
                                        error={error.ppv}
                                        onChange={(Value) => {
                                            this.setFieldError("ppv", false);
                                            this.setField("ppv", Value.target.value);
                                        }}
                                        value={fields.ppv}
                                        defaultValue={fields.ppv}
                                        id="ppv"
                                        label="Prix Public de Vente (DHS)*"
                                    ></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomSelect
                                        error={error.taxe_vente}
                                        onChange={(Value) => {
                                            this.setFieldError("taxe_vente", false);
                                            this.setField("taxe_vente", Value.target.value);
                                        }}
                                        selectedValue={fields.taxe_vente}
                                        label="Taxe sur vente*"
                                    >
                                        <option defaultValue></option>
                                        {preload.taxes &&
                                            preload.taxes.map((object, index) => {
                                                return <option value={object.id}>{`${object.nom}(${parseFloat(object.taxe).toFixed(2)}%)`}</option>;
                                            })}
                                    </CustomSelect>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput
                                        error={error.base_remboursement}
                                        onChange={(Value) => this.setField("base_remboursement", Value.target.value)}
                                        value={fields.base_remboursement}
                                        defaultValue={fields.base_remboursement}
                                        id="base_remboursement"
                                        label="Base de remboursement"
                                        inputType={"number"}
                                    ></CustomInput>
                                </InputContainer>
                            </Right>
                        </FormContainer>

                        {/* end stock infos  */}

                        {/* desc info */}

                        <Title_containerMiddle>
                            <Title>Informations descriptives</Title>
                            <Bar></Bar>
                        </Title_containerMiddle>
                        <FormContainer justifyContent="center">
                            <FullRow>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("presentation", Value.target.value)} value={fields.presentation} defaultValue={fields.presentation} id="presentation" label="Présentation"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("excipients", Value.target.value)} value={fields.excipients} defaultValue={fields.excipients} id="excipients" label="Excipients"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("posologie_adulte", Value.target.value)} value={fields.posologie_adulte} defaultValue={fields.posologie_adulte} id="posologie_adulte" label="Posologie pour Adulte"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("posologie_enfant", Value.target.value)} value={fields.posologie_enfant} defaultValue={fields.posologie_enfant} id="posologie_enfant" label="Posologie pour Enfant"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("indications", Value.target.value)} value={fields.indications} defaultValue={fields.indications} id="indications" label="Indications"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("contre_indication_conduite", Value.target.value)} selectedValue={fields.contre_indication_conduite} label="Contre-indication conduite">
                                        <option defaultValue value="Aucun">
                                            Aucun
                                        </option>
                                        <option value="Niveau 1">Niveau 1</option>
                                        <option value="Niveau 2">Niveau 2</option>
                                        <option value="Niveau 3">Niveau 3</option>
                                    </CustomSelect>
                                </InputContainer>

                                <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("contre_indication_allaitement", Value.target.value)} selectedValue={fields.contre_indication_allaitement} label="Contre-indication allaitement">
                                        <option defaultValue value="Aucun">
                                            Aucun
                                        </option>
                                        <option value="Niveau 1">Niveau 1</option>
                                        <option value="Niveau 2">Niveau 2</option>
                                        <option value="Niveau 3">Niveau 3</option>
                                    </CustomSelect>
                                </InputContainer>

                                {/* <InputContainer>
                                    <CustomSelect error={false} onChange={(Value) => this.setField("fournisseur", Value.target.value)} selectedValue={fields.fournisseur} label="Contre-indication grossesse">
                                        <option defaultValue value="Aucun">
                                            Aucun
                                        </option>
                                        <option value="1">Niveau 1</option>
                                        <option value="1">Niveau 2</option>
                                        <option value="1">Niveau 3</option>
                                    </CustomSelect>
                                </InputContainer> */}

                                <InputContainer>
                                    <CustomInput
                                        error={false}
                                        onChange={(Value) => this.setField("ref_interne_laboratoire", Value.target.value)}
                                        value={fields.ref_interne_laboratoire}
                                        defaultValue={fields.ref_interne_laboratoire}
                                        id="ref_interne_laboratoire"
                                        label="Référence interne laboratoire"
                                    ></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    {" "}
                                    <CustomInput error={false} onChange={(Value) => this.setField("conditionnement", Value.target.value)} value={fields.conditionnement} defaultValue={fields.conditionnement} id="conditionnement" label="Conditionnement"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    {" "}
                                    <CustomInput error={false} onChange={(Value) => this.setField("monograph", Value.target.value)} value={fields.monograph} defaultValue={fields.monograph} id="monograph" label="Monograph"></CustomInput>
                                </InputContainer>

                                <InputContainer>
                                    {" "}
                                    <CustomInput error={false} onChange={(Value) => this.setField("description", Value.target.value)} value={fields.description} defaultValue={fields.description} id="description" label="Description"></CustomInput>
                                </InputContainer>
                            </FullRow>
                        </FormContainer>

                        {/* end desc infos  */}
                    </Block_content>
                </Bloc>
            </Main_container>
        );
    }

    setResource(value) {
        this.setField("fournisseur", value.id);
        this.setState({ fournisseur_name: value.nom });
    }
    resetResource() {
        this.setField("fournisseur", "");
        this.setState({ fournisseur_name: "" });
    }
    setPharmaceuticalForms(value) {
        this.setField("forme_galenique", value.id);
        this.setState({ forme_galenique_name: value.nom });
    }
    resetPharmaceuticalForms() {
        this.setField("forme_galenique", "");
        this.setState({ forme_galenique_name: "" });
    }
    setProductDci(value) {
        this.setField("dci", value.id);
        this.setState({ product_dci_name: value.nom });
    }
    resetProductDci() {
        this.setField("dci", "");
        this.setState({ product_dci_name: "" });
    }
    setProductRange(value) {
        this.setField("gamme", value.id);
        this.setState({ product_range_name: value.nom });
    }
    resetProductRange() {
        this.setField("gamme", "");
        this.setState({ product_range_name: "" });
    }
    setProductSubRange(value) {
        this.setField("sousgamme", value.id);
        this.setState({ product_sub_ranges_name: value.nom });
    }
    resetProductSubRange() {
        this.setField("sousgamme", "");
        this.setState({ product_sub_ranges_name: "" });
    }
    setTherapeuticClasse(value) {
        this.setField("classe_therapeutique", value.id);
        this.setState({ therapeutic_classes_name: value.nom });
    }
    resetTherapeuticClasse() {
        this.setField("classe_therapeutique", "");
        this.setState({ therapeutic_classes_name: "" });
    }
    setField = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: value,
            },
        }));
    };

    setFieldError = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [field]: value,
            },
        }));
    };

    setPrelaod = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            preload: {
                ...prevState.preload,
                [field]: value,
            },
        }));
    };

    add = async () => {
        try {
            const { fields } = this.state;
            if (fields.nom.length !== 0 && fields.pph.length !== 0 && fields.ppv.length !== 0 && fields.taxe_achat.length !== 0 && fields.taxe_vente.length !== 0) {
                const data = fields;
                const params = {};
                this.setState({ loading: true });
                const response = await ProductDAO.addProductSuggestion(params, data);
                this.setState({ loading: false });

                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.product.suggestion.add, type: "success" });
                    this.props.history.push(RoutesPath.product.suggestion.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                if (fields.nom.length === 0) {
                    this.setFieldError("nom", true);
                }
                if (fields.pph.length === 0) {
                    this.setFieldError("pph", true);
                }
                if (fields.ppv.length === 0) {
                    this.setFieldError("ppv", true);
                }
                if (fields.taxe_achat.length === 0) {
                    this.setFieldError("taxe_achat", true);
                }
                if (fields.taxe_vente.length === 0) {
                    this.setFieldError("taxe_vente", true);
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    };

    // add = async () => {
    //     try {
    //         const { fields } = this.state;
    //         if (
    //             fields.nom.length != 0 ||
    //             fields.code_barre.length != 0 ||
    //             fields.code_barre2.length != 0 ||
    //             fields.pph.length != 0 ||
    //             fields.ppv.length != 0 ||
    //             fields.taxe_achat.length != 0 ||
    //             fields.taxe_vente.length != 0 ||
    //             fields.fournisseur.length != 0 ||
    //             fields.categorie.length != 0 ||
    //             fields.gamme.length != 0 ||
    //             fields.sousgamme.length != 0 ||
    //             fields.forme_galenique.length != 0 ||
    //             fields.dci.length != 0 ||
    //             fields.produit_tableau.length != 0 ||
    //             fields.classe_therapeutique.length != 0 ||
    //             fields.est_remboursable.length != 0 ||
    //             fields.base_remboursement.length != 0 ||
    //             fields.presentation.length != 0 ||
    //             fields.excipients.length != 0 ||
    //             fields.posologie_adulte.length != 0 ||
    //             fields.posologie_enfant.length != 0 ||
    //             fields.contre_indication_conduite.length != 0 ||
    //             fields.contre_indication_allaitement.length != 0 ||
    //             fields.ref_interne_laboratoire.length != 0 ||
    //             fields.conditionnement.length != 0 ||
    //             fields.monograph.length != 0 ||
    //             fields.description.length != 0 ||
    //             fields.indications.length != 0
    //         ) {
    //             const data = fields;
    //             const params = {};
    //             (data);
    //             this.setState({ loading: true });
    //             const response = await ProductDAO.addProductSuggestion(params, data);
    //             (response);
    //             this.setState({ loading: false });
    //             response.data.status === "ok" && this.props.history.push(RoutesPath.product.suggestion.list);
    //         }
    //     } catch (error) {
    //         this.setState({ loading: false });
    //         (error);
    //     }
    // };
    preload = () => {
        const id = this.props.match.params.product_id;
        if (id) {
            this.getProductDetails(id);
        }
        this.getPharmaceuticalForms();
        this.getProductRanges();
        this.getProductSubRanges();
        this.getProductDci();
        this.getTherapeuticClasses();
        this.getProductCategories();
        this.getLaboratories();
        this.getTaxes();
    };
    getProductDetails = async (id) => {
        try {
            const response = await ProductDAO.getProductById(id);
            const product = response.data.produit;
            this.setState(
                (prevState) => ({
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        nom: product && product.nom,
                        code_barre: product && product.code_barre,
                        code_barre2: product && product.code_barre2,
                        pph: product && product.pph,
                        ppv: product && product.ppv,
                        taxe_achat: product && product.taxe_achat_id,
                        taxe_vente: product && product.taxe_vente_id,
                        fournisseur: product && product.laboratoire_id,
                        categorie: product && product.categorie_id,
                        gamme: product && product.gamme_id,
                        sousgamme: product && product.sousgamme_id,
                        forme_galenique: product && product.forme_galenique_id,
                        dci: product && product.dci_id,
                        produit_tableau: product && product.produit_tableau,
                        classe_therapeutique: product && product.classe_therapeutique_id,
                        necessite_prescription: product && product.necessite_prescription,
                        produit_marche: product && product.produit_marche,
                        est_remboursable: product && product.est_remboursable,
                        base_remboursement: product && product.base_remboursement,
                        presentation: product && product.presentation,
                        excipients: product && product.excipients,
                        posologie_adulte: product && product.posologie_adulte,
                        posologie_enfant: product && product.posologie_enfant,
                        contre_indication_conduite: product && product.contre_indication_conduite,
                        contre_indication_allaitement: product && product.contre_indication_allaitement,
                        ref_interne_laboratoire: product && product.ref_interne_laboratoire,
                        conditionnement: product && product.conditionnement,
                        monograph: product && product.monograph,
                        description: product && product.description,
                        indications: product && product.indications,
                    },
                }),
                () => this.state.fields
            );
            this.setState({
                forme_galenique_name: product && product.forme_galenique,
                product_range_name: product && product.gamme,
                product_dci_name: product && product.dci,
                therapeutic_classes_name: product && product.classe_therapeutique,
                product_sub_ranges_name: product && product.sousgamme,
                fournisseur_name: product && product.laboratoire,
                therapeutic_classes_name: product && product.classe_therapeutique,
            });
        } catch (error) {}
    };
    getTherapeuticClasses = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getTherapeuticClasses(pageNumber, params, data.nom.value, data.nom.op);
            } else {
                response = await CommonDAO.getTherapeuticClasses(pageNumber, params);
            }

            this.setPrelaod("therapeutic_classes", response.data.ressources);
            this.setPrelaod("therapeutic_classes_total_items", response.data.total);
        } catch (error) {}
    };
    getProductCategories = async () => {
        try {
            const pageNumber = 1;
            const response = await CommonDAO.getProductCategories(pageNumber);
            this.setPrelaod("product_categories", response.data.ressources);
        } catch (error) {}
    };
    getTaxes = async () => {
        try {
            const pageNumber = 1;
            const response = await CommonDAO.getAllTaxes(pageNumber);
            this.setPrelaod("taxes", response.data.ressources);
        } catch (error) {}
    };
    getPharmaceuticalForms = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getAllPharmaceuticalForms(pageNumber, params, data.nom.value, data.nom.op);
            } else {
                response = await CommonDAO.getAllPharmaceuticalForms(pageNumber, params);
            }
            this.setPrelaod("pharmaceutical_forms", response.data.ressources);
            this.setPrelaod("pharmaceutical_forms_total_items", response.data.total);
        } catch (error) {}
    };
    getProductDci = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getAllProductDci(pageNumber, params, data.nom.value, data.nom.op);
            } else {
                response = await CommonDAO.getAllProductDci(pageNumber, params);
            }

            this.setPrelaod("product_dci", response.data.ressources);
            this.setPrelaod("product_dci_total_items", response.data.total);
        } catch (error) {}
    };
    getProductRanges = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getAllProductRanges(pageNumber, data.nom.value, params);
            } else {
                response = await CommonDAO.getAllProductRanges(pageNumber, " ", params);
            }

            this.setPrelaod("product_ranges", response.data.ressources);
            this.setPrelaod("product_ranges_total_items", response.data.total);
        } catch (error) {}
    };
    getProductSubRanges = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getAllProductSubRanges(pageNumber, data.nom.value, params);
            } else {
                response = await CommonDAO.getAllProductSubRanges(pageNumber, " ", params);
            }

            this.setPrelaod("product_sub_ranges", response.data.ressources);
            this.setPrelaod("product_sub_ranges_total_items", response.data.total);
        } catch (error) {}
    };
    getLaboratories = async (data = {}, pageNumber = 1) => {
        try {
            const params = {};
            let response;
            if (data.nom) {
                response = await CommonDAO.getAllProviders(pageNumber, params, data.nom.value, data.nom.op);
            } else {
                response = await CommonDAO.getAllProviders(pageNumber, params);
            }

            this.setPrelaod("providers", response.data.ressources);
            this.setPrelaod("providers_total_items", response.data.total);
        } catch (error) {}
    };
}

const Bar = styled.div`
    width: 99%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title_container = styled.div`
    position: relative;
`;
const Title_containerMiddle = styled.div`
    position: relative;
    margin-top: 23px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    background: #fff;
    ${"" /* height: 430px; */}
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;

// const FullRow = styled.div`
//     width: 99%;
//     height: 41px;
//     display: inline-block;
//     vertical-align: top;
// `;

const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const CheckBoxLabel = styled.label`
    display: inline-block;
    margin-bottom: 3px;
`;

const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(withRouter(AddProductSuggestion));
