import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import AccountDAO from "../DAO/AccountDAO";
import CustomInput from "../components/CustomInput";
import { DatePicker } from "../components/CustomDatePicker";
import SearchSelect from "../helpers/SearchSelect";
import GalleryDAO from "../DAO/GalleryDAO";
import { connect } from "react-redux";

import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "../components/seachStyledComponents";
import { notify } from "../helpers/FlashMessage";
import Acl, { acl } from "../services/acl";

class GalleryListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            date: null,
            selected_product: {},
            product_id: "",
            selected_user: {},
            user_id: "",
            download_at: null,
            name: "",
        };
    }
    componentDidMount() {
        // const gallery = this.props.data;
        // if (gallery) {
        //     this.setState({ gallery });
        // }
        this.getGallery();
    }
    async getGallery() {
        try {
            const { accountId } = this.props;
            const { search, pageNumber, product_id, user_id, name, download_at, date } = this.state;
            const data = GalleryDAO.getSearchDataTable({ search, product_id, user_id, name, download_at, date });
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await AccountDAO.getPhotosByAccountId(pageNumber, accountId, {}, data, 5);
            this.setState({ gallery: response.data.photos, totalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1 }, () => this.getGallery());
        }
    }
    resetSearch() {
        this.setState(
            {
                date: null,
                name: "",
                selected_product: {},
                product_id: "",
                selected_user: {},
                user_id: "",
                download_at: null,
            },
            () => this.getGallery()
        );
    }
    render() {
        const { gallery, pageNumber, totalItems, search } = this.state;
        const { noGalleryCas } = this.props;
        if (!search && gallery.length === 0) {
            return noGalleryCas;
        }

        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Galerie</h2>
                    <div className="bloc_header_btns">
                        <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}
                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Nom
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Date
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Produit
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Téléchargé par
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Téléchargée le
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {gallery.length != 0 &&
                                gallery.map((photo, index) => {
                                    return (
                                        <Tr key={"key3_" + index}>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.gallery.details}/${photo.id}`)}>{photo.nom}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.gallery.details}/${photo.id}`)}>{moment(photo.date).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.gallery.details}/${photo.id}`)}>{photo.produit}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.gallery.details}/${photo.id}`)}>{photo.cree_par}</Td>
                                            <Td onClick={() => this.props.history.push(`${RoutesPath.gallery.details}/${photo.id}`)}>{moment(photo.cree_le).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td className="table_action"></Td>
                                        </Tr>
                                    );
                                })}

                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        {acl.gallery.add() && (
                                            <a className="btn_white" onClick={() => this.props.history.push(`${RoutesPath.gallery.add}/${this.props.accountId}`)}>
                                                Créer
                                            </a>
                                        )}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        this.getGallery();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / (this.props.limitForPagination ? this.props.limitForPagination : 20) && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        this.getGallery();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { search, name, date, selected_product, selected_user, download_at } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={name}
                            onChange={(value) => {
                                this.setState({ name: value.target.value });
                            }}
                            label="Nom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={date}
                            onChange={(date) => {
                                this.setState({
                                    date: date,
                                });
                            }}
                            label="Date"
                            id="date"
                        />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/produits"
                            HTTPMethod="get"
                            receivedObject="ressources"
                            optionLabelProperty="nom"
                            optionValueProperty="id"
                            value={selected_product}
                            placeholder="Produit"
                            onChange={(e) => {
                                this.setState({ selected_product: e, product_id: e && e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <SearchSelect
                            fetchedUrl="/api/utilisateurs"
                            HTTPMethod="post"
                            receivedObject="utilisateurs"
                            optionLabelProperty="fullname"
                            optionValueProperty="id"
                            value={selected_user}
                            placeholder="Téléchargé par"
                            onChange={(e) => {
                                this.setState({ selected_user: e, user_id: e && e.value });
                            }}
                        />
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <DatePicker
                            selected={download_at}
                            onChange={(date) => {
                                this.setState({
                                    download_at: date,
                                });
                            }}
                            dateFormat="yyyy-MM-dd"
                            showTimeSelect={false}
                            label="Téléchargée le"
                            id="download_at"
                        />
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getGallery())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(GalleryListTable);
