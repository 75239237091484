import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import FlashMessages from "../helpers/FlashMessages";
import PopUpGlobal from "../components/PopUpGlobal.js";
import { connect } from "react-redux";
import ProductDAO from "../DAO/ProductDAO";
import CommonDAO from "../DAO/CommonDAO";
import { withRouter } from "react-router-dom";
import FromLoading from "../helpers/FromLoading";
import { FormContainer, Left, Right, FullRow, InputContainer } from "../helpers/formStyledComp";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import SearchSelect from "../helpers/SearchSelect";
import ResourceDAO from "../DAO/ResourceDAO";
import countries from "../values/countries.json";
import { NotAsyncSelect } from "../helpers/SearchSelect";

/**
 *
 * this component can used for persist and update  account
 * if id_account not 0 means update
 * else mean persist
 *
 */

const options = [];
countries.map((object) => {
    options.push({ value: object.alpha2, label: object.name });
});
class AddAssociationSuggestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            fields: {
                nom: "",
                ville: "",
                secteur: "",
                telephone: "",
                fax: "", //tva 20
                site_internet: "", //tva 20
                rc: "",
                inpe: "",
                adresse: "",
                region: "",
                pays: "ma",
                code_postal: "",
                portable: "",
                email: "",
            },
            error: {
                nom: false,
                ville: false,
                email: false,
                site_internet: false,
                pays: false,
            },
            preload: {},
            loading: false,
            selected_country: { value: "ma", label: "Maroc" },
            selected_city: null,
            selected_sector: null,
        };
    }

    componentDidMount() {
        document.title = "Produit - Sobrus Labs";
        this.preload();
    }

    render() {
        const { loading, errorMessage, fields, error } = this.state;
        const id = this.props.match.params.product_id;

        const title = "Suggérer association";
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={this.add}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{"Suggérer association"}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Title_container>
                            <Title>Informations générales</Title>
                            <Bar></Bar>
                        </Title_container>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <CustomInput
                                        error={error.nom}
                                        onChange={(Value) => {
                                            this.setField("nom", Value.target.value);
                                            this.setFieldError("nom", false);
                                        }}
                                        value={fields.nom}
                                        defaultValue={fields.nom}
                                        id="nom"
                                        label="Nom*"
                                    ></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("telephone", Value.target.value)} value={fields.telephone} defaultValue={fields.telephone} id="telephone" label="Téléphone"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("fax", Value.target.value)} value={fields.fax} defaultValue={fields.fax} id="fax" label="Fax"></CustomInput>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput
                                        error={error.email}
                                        errorMessage="Saisissez une adresse e-mail valide"
                                        onChange={(Value) => {
                                            this.setFieldError("email", false);
                                            this.setField("email", Value.target.value);
                                        }}
                                        value={fields.email}
                                        defaultValue={fields.email}
                                        id="email_id"
                                        label="E-mail"
                                    ></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("portable", Value.target.value)} value={fields.portable} defaultValue={fields.portable} id="portable" label="Portable"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput
                                        error={error.site_internet}
                                        errorMessage="Saisissez un site internet valide"
                                        onChange={(Value) => {
                                            this.setFieldError("site_internet", false);
                                            this.setField("site_internet", Value.target.value);
                                        }}
                                        value={fields.site_internet}
                                        defaultValue={fields.site_internet}
                                        id="site_internet"
                                        label="Site internet"
                                    ></CustomInput>
                                </InputContainer>
                            </Right>
                        </FormContainer>

                        {/* stock info */}

                        <Title_containerMiddle inerMiddle>
                            <Title>Informations légales</Title>
                            <Bar></Bar>
                        </Title_containerMiddle>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("rc", Value.target.value)} value={fields.rc} defaultValue={fields.rc} id="rc" label="RC"></CustomInput>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("inpe", Value.target.value)} value={fields.inpe} defaultValue={fields.inpe} id="inpe" label="INPE"></CustomInput>
                                </InputContainer>
                            </Right>
                        </FormContainer>

                        {/* end stock infos  */}

                        {/* desc info */}

                        <Title_containerMiddle>
                            <Title>Adresse</Title>
                            <Bar></Bar>
                        </Title_containerMiddle>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <SearchSelect
                                        error={error.ville}
                                        fetchedUrl="/api/villes"
                                        HTTPMethod="get"
                                        receivedObject="ressources"
                                        optionLabelProperty="nom"
                                        optionValueProperty="id"
                                        value={this.state.selected_city}
                                        placeholder="Ville*"
                                        onChange={(e) => {
                                            this.setState({ selected_city: e });
                                            this.setField("ville", e && e.value);
                                            this.setFieldError("ville", false);
                                        }}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("region", Value.target.value)} value={fields.region} defaultValue={fields.region} id="region" label="Région"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <NotAsyncSelect
                                        error={error.pays}
                                        options={options}
                                        placeholder={"Pays*"}
                                        value={this.state.selected_country}
                                        onChange={(e) => {
                                            this.setField("pays", e && e.value);
                                            this.setState({ selected_country: e });
                                            this.setFieldError("pays", false);
                                        }}
                                    />
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("adresse", Value.target.value)} value={fields.adresse} defaultValue={fields.adresse} id="adresse" label="Adresse"></CustomInput>
                                </InputContainer>
                                <InputContainer>
                                    <SearchSelect
                                        name="sector"
                                        fetchedUrl="/api/secteurs"
                                        HTTPMethod="get"
                                        receivedObject="ressources"
                                        optionLabelProperty="nom"
                                        optionLabelProperty_2="ville"
                                        optionValueProperty="id"
                                        value={this.state.selected_sector}
                                        placeholder="Secteur"
                                        onChange={(e) => {
                                            this.setField("secteur", e && e.value);
                                            this.setState({ selected_sector: e });
                                        }}
                                    />
                                </InputContainer>
                                <InputContainer>
                                    <CustomInput error={false} onChange={(Value) => this.setField("code_postal", Value.target.value)} value={fields.code_postal} defaultValue={fields.code_postal} id="code_postal" label="Code postal"></CustomInput>
                                </InputContainer>
                            </Right>
                        </FormContainer>

                        {/* end desc infos  */}
                    </Block_content>
                </Bloc>
            </Main_container>
        );
    }

    setField = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: value,
            },
        }));
    };

    setFieldError = (field, value) => {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                [field]: value,
            },
        }));
    };
    verifyData() {
        const { fields } = this.state;

        if (fields.nom && fields.nom.length !== 0 && fields.ville && fields.ville.length !== 0 && fields.pays && fields.pays.length !== 0) {
            const data = fields;
            if (fields.email && fields.email.length !== 0) {
                const expression = /\S+@\S+\.\S+/;
                if (expression.test(String(fields.email).toLowerCase())) {
                    if (fields.site_internet && fields.site_internet.length !== 0) {
                        const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;
                        if (expression.test(String(fields.site_internet).toLowerCase())) {
                            return data;
                        } else {
                            this.setFieldError("site_internet", true);
                            return null;
                        }
                    } else {
                        return data;
                    }
                } else {
                    this.setFieldError("email", true);
                    return null;
                }
            }

            return data;
        } else {
            if (!fields.nom || (fields.nom && fields.nom.length === 0)) {
                this.setFieldError("nom", true);
            }
            if (!fields.ville || (fields.ville && fields.ville.length === 0)) {
                this.setFieldError("ville", true);
            }
            if (!fields.pays || (fields.pays && fields.pays.length === 0)) {
                this.setFieldError("pays", true);
            }
            return null;
        }
    }
    add = async () => {
        try {
            const id = this.props.match.params.id;
            const { fields } = this.state;
            let data = this.verifyData();
            if (data) {
                const params = {};
                // if add did from contact details
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const query = urlParams.get('q');
                if (query) {
                    if (window.atob(query) === "suggest a modification") {
                        data = {
                            ...data,
                            id,
                        }
                    }
                }
                // end

                this.setState({ loading: true });
                const response = await ResourceDAO.addResourceSuggestion("association", params, data);
                this.setState({ loading: false });

                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.product.suggestion.add, type: "success" });
                    this.props.history.push(`${RoutesPath.resource.suggestion.associations.list}`);
                } else {
                    // notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            console.log("error", error)
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    };

    preload = () => {
        const id = this.props.match.params.id;

        id && this.get();
    };
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ResourceDAO.getResourceById("association", id, {});
            const resource = response.data.association;
            this.setState({
                fields: {
                    nom: resource.nom ? resource.nom : "",
                    secteur: resource.secteur ? resource.secteur_id : "",
                    telephone: resource.telephone ? resource.telephone : "",
                    fax: resource.fax ? resource.fax : "", //tva 20
                    site_internet: resource.site_internet ? resource.site_internet : "", //tva 20
                    rc: resource.rc ? resource.rc : "",
                    inpe: resource.inpe ? resource.inpe : "",
                    adresse: resource.adresse ? resource.adresse : "",
                    region: resource.region ? resource.region : "",
                    pays: resource.pays ? resource.pays : "",
                    code_postal: resource.code_postal ? resource.code_postal : "",
                    portable: resource.portable ? resource.portable : "",
                    email: resource.email ? resource.email : "",
                    ville: resource.ville ? parseInt(resource.ville_id) : "",
                },
            });
            response.data.association && response.data.association.ville && response.data.association.ville_id && this.setState({ selected_city: { label: response.data.association.ville, value: response.data.association.ville_id } });
            response.data.association && response.data.association.secteur && response.data.association.secteur_id && this.setState({ selected_sector: { label: response.data.association.secteur, value: response.data.association.secteur_id } });
            response.data.association && response.data.association.pays && this.setState({ selected_country: { value: response.data.association.pays, label: response.data.association.pays } });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
}

const Bar = styled.div`
    width: 99%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title_container = styled.div`
    position: relative;
`;
const Title_containerMiddle = styled.div`
    position: relative;
    margin-top: 23px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 20px;
    margin-top: 10px;
    background: #fff;
    ${"" /* height: 430px; */}
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;

// const FullRow = styled.div`
//     width: 99%;
//     height: 41px;
//     display: inline-block;
//     vertical-align: top;
// `;

const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const CheckBoxLabel = styled.label`
    display: inline-block;
    margin-bottom: 3px;
`;

const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(withRouter(AddAssociationSuggestion));
