import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactsTable from "../components/ContactsTable";
import moment from "moment";
import "moment/locale/fr";
import Axios from "axios";
import { Token } from "../tmp/Token";
import { connect } from "react-redux";
import CalenderDAO from "../DAO/CalenderDAO";
import { RoutesPath } from "../values/RoutesPath";
import FromLoading from "../helpers/FromLoading";

class MonthCalenderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top: 0,
            moment: moment().format("HH:mm"),
            hour: moment().format("HH"),
            minute: moment().format("mm"),
            day: moment().format("dddd"),
            day_nb: moment().format("DD"),
            daysInMonth: moment().daysInMonth(),
            toDay: moment().format("MMMM YYYY"),
            daysTable: [],
            dateObject: moment(),
            orderedArray: [],
            pageNumber: 1,
            totalItems: 0,
            events: [],
            loading: true,
        };
        moment.locale("fr");
        let tmp = [];
        for (let i = 0; i < this.state.daysInMonth; i++) {
            tmp = [...tmp, i + 1];
        }
        this.state.daysTable = tmp;
    }
    current() {
        setInterval(() => this.setState({ top: this.state.top + 1, moment: moment().format("HH:mm"), hour: moment().format("HH"), minute: moment().format("mm"), day_nb: moment().format("DD") }), 10000);
        //this.setState({top:this.state.top+1});
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        moment.locale("fr");
        this.current();
        this.setState({ daysInMonth: moment().daysInMonth() });
        this.getEvents();
    }

    async getEvents() {
        try {
            const { dateObject, pageNumber, events } = this.state;
            let data = CalenderDAO.getSearchData({ validDate: dateObject.format("YYYY-MM") });
            data["pagination"] = {
                value: "OFF",
            };
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            this.setState({ loading: true });
            const response = await CalenderDAO.getALLEvents(pageNumber, {}, data);

            this.setState({ loading: false });

            this.setState({ events: [...response.data.events] }, () => {
                // if (response.data.total / 20 > pageNumber) {
                //     this.setState({ pageNumber: pageNumber + 1 }, () => this.getEvents());
                // }
                const orderedArray = [];
                this.state.events.map((event) => {
                    const index = parseInt(moment(event.date_debut).format("DD"));

                    if (orderedArray[index] == undefined) {
                        orderedArray[index] = [event];
                    } else {
                        orderedArray[index] = [...orderedArray[index], event];
                    }
                });
                this.setState({ orderedArray: [...orderedArray], totalItems: response.data.total });
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    tds() {
        let tmp = [];
        for (let i = 0; i < this.state.daysInMonth; i++) {
            tmp = [...tmp, i + 1];
        }
        this.state.daysTable = tmp;
    }
    firstDayOfMonth = () => {
        let dateObject = this.state.dateObject;
        let firstDay = moment(dateObject).startOf("month").format("d");

        return firstDay;
    };
    daysInMonth = () => {
        let dateObject = this.state.dateObject;
        let daysInMonth = moment(dateObject).daysInMonth();
        return daysInMonth;
    };
    currentDay = () => {
        return this.state.dateObject.format("D");
    };
    currentMonth = () => {
        return moment().format("MMMM");
    };
    renderEvents(day) {
        const { orderedArray, showPopUp } = this.state;
        // ("orderedArray");
        // (orderedArray[day]);
        let renderedArray = [];
        let otherEvents = [];
        orderedArray[day] &&
            orderedArray[day].length != 0 &&
            orderedArray[day].map((event, index) => {
                let color = "#00beb0";
                if (index == 1) {
                    color = "#f89a54";
                } else if (index == 2) {
                    color = "#F0396E";
                }
                if (index < 3) {
                    renderedArray.push(
                        <Li>
                            <Circle color={color} />
                            <Link to={`${RoutesPath.calender.details}/${event.id}`}>
                                <Event_desc>
                                    {moment(event.date_debut).format("HH:mm")} - {event.nom}.
                                </Event_desc>
                            </Link>
                        </Li>
                    );
                } else {
                    otherEvents.push(
                        <Li>
                            <Circle color="#00beb0" />
                            <Link to={`${RoutesPath.calender.details}/${event.id}`}>
                                <Event_desc>
                                    {moment(event.date_debut).format("HH:mm")} - {event.nom}.
                                </Event_desc>
                            </Link>
                        </Li>
                    );
                }
            });
        let openPop_up = true;
        return orderedArray[day] && orderedArray[day].length != 0 && <TdEventContent renderedArray={renderedArray} otherEvents={otherEvents} orderedArray={orderedArray} day={day} />;
    }

    render() {
        const { orderedArray, loading } = this.state;
        let weekdayshort = moment.weekdays();
        let weekdayshortname = weekdayshort.map((day) => {
            return (
                <Th key={day} className="week-day">
                    {day}
                </Th>
            );
        });
        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<Disabled_td>{""}</Disabled_td>);
        }
        let daysInMonth = [];
        let currentDay = this.currentDay();
        let currentMonth = this.currentMonth();
        for (let d = 1; d <= this.daysInMonth(); d++) {
            if (d == currentDay && this.state.dateObject.format("MMMM") == currentMonth) {
                daysInMonth.push(
                    <Td2 key={d}>
                        <Current_day_in_month>{d}</Current_day_in_month>
                        {this.renderEvents(d)}
                    </Td2>
                );
            } else {
                daysInMonth.push(
                    <Td2 key={d}>
                        <Day_in_month>{d}</Day_in_month>
                        {this.renderEvents(d)}
                    </Td2>
                );
            }
        }
        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];
        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) {
                // when end loop we add remain date
                rows.push(cells);
            }
        });
        let daysinmonth = rows.map((d, i) => {
            if (i != 0) {
                return <Tr2>{d}</Tr2>;
            }
        });
        return (
            <Main_container className="Main_container">
                {loading && <FromLoading />}
                <TopOptions title="Calendrier">
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.calender.add}`)}>
                        Créer
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <Bloc_header>
                        <Right_content>
                            <Date_container>
                                <Previous_btn onClick={() => this.setState({ toDay: moment(this.state.dateObject).add(-1, "months").format("MMMM YYYY"), dateObject: moment(this.state.dateObject).add(-1, "months"), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}>
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path
                                            fill="#9f9f9f"
                                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                        ></path>
                                    </Svg>
                                </Previous_btn>
                                <Date_text>{this.state.toDay}</Date_text>
                                <Next_btn onClick={() => this.setState({ toDay: moment(this.state.dateObject).add(1, "months").format("MMMM YYYY"), dateObject: moment(this.state.dateObject).add(1, "months"), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}>
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path
                                            fill="#9f9f9f"
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                        ></path>
                                    </Svg>
                                </Next_btn>
                            </Date_container>
                            <Btn_container>
                                <Clicked_btn onClick={() => this.props.history.push(`${RoutesPath.calender.list_month}`)}>Mois</Clicked_btn>
                                {/* <Clicked_btn> Semaine</Clicked_btn> */}
                                <Btn onClick={() => this.props.history.push(`${RoutesPath.calender.list}`)}>Jours</Btn>
                                <Btn onClick={() => this.setState({ toDay: moment().format("MMMM YYYY"), dateObject: moment(), orderedArray: [], events: [], pageNumber: 1 }, () => this.getEvents())}>Le mois courant</Btn>
                            </Btn_container>
                        </Right_content>
                    </Bloc_header>
                    <Calender_table>
                        <thead>
                            <Tr>{weekdayshortname}</Tr>
                        </thead>
                        <Tbody>{daysinmonth}</Tbody>
                    </Calender_table>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
    position: relative;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Bloc_header = styled.div`
    position: relative;
    /* padding: 10px; */
    background: #f0f0f0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 48px;
`;
const Tbody = styled.tbody`
    height: 300px;
    overflow-y: scroll;
`;
const Right_content = styled.div`
    display: block;
    height: 100%;
    width: 63%;
    text-align: center;
    float: right;
`;
const Date_container = styled.div`
    display: inline-block;
    height: 100%;
    width: 25%;
    text-align: center;
    float: left;
    padding-top: 10px;
    text-align: center;
`;
const Svg = styled.svg`
    width: 10px;
`;
const Clock = styled.svg`
    width: 25px;
`;
const Next_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: right;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 5px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Previous_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: left;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 7px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Date_text = styled.p`
    display: inline-block;
    vertical-align: top;
    width: 52%;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #00beb0;
    overflow: hidden;
    margin-top: 7px;
`;
const Btn_container = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 74%;
    float: right;
    padding-top: 10px; ;
`;
const Btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #494949;
    margin-right: 10px;
    cursor: pointer;
    float: right;
    &:hover {
        background-color: #00beb0;
        color: #fff;
        border: solid 1px #00beb0;
    }
`;
const Clicked_btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #00beb0;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fff;
    float: right;
    margin-right: 10px;
    cursor: pointer;
`;
const Calender_table = styled.table`
    background-color: #fff;
    border-collapse: collapse;
    position: relative;
`;
const Tr = styled.tr`
    height: 80px;
`;
const Tr2 = styled.tr`
    height: 151px;
`;
const Sous_tr = styled.tr`
    height: 40px;
    background: red;
`;
const Td2 = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    position: relative;
    width: 13%;
    position: relative;
    vertical-align: top;
`;
const Disabled_td = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    position: relative;
    width: 13%;
    background: #fafafa;
`;
const Td_last = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    position: relative;
    width: 13%;
`;
const Td1 = styled.td`
    width: 7%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Th1 = styled.th`
    width: 7%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Th = styled.th`
    width: 13%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Under_table = styled.table`
    border-collapse: collapse;
`;
const Under_tr = styled.tr`
    height: 50%;
`;
const Under_tr_2 = styled.tr`
    height: 50%;
    border-top: dotted 0.1px #9f9f9f;
`;
const Event_container = styled.div`
    height: 40px;
    position: relative;

    border-radius: 3px;
    border: solid 0.6px #ffffff;
    background-color: ${(props) => props.color};
    width: ${(props) => props.width + "%"};
    display: inline-block;
    position: absolute;
    vertical-align: top;
    cursor: pointer;
    top: ${(props) => {
        if (props.index == 1) {
            return 0;
        } else {
            return (props.index - 1) * 9 + "px";
        }
    }};
    left: ${(props) => {
        if (props.index == 1) {
            return 0;
        } else {
            return (props.width / 2) * (props.index - 1) + "%";
        }
    }};
    &:hover {
        > div {
            display: block;
        }
    }
`;
const P = styled.p`
    padding-top: 10px;
    padding-left: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    overflow: hidden;
    width: 99%;
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Other_event = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #494949;
    padding-left: 11px;
    cursor: pointer;
    margin-top: 5px;
`;
const Pop_up = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    overflow: hidden;
    padding-bottom: 16px;
`;
const Pop_up_container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 16px;
`;
const H4 = styled.h4`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #9f9f9f;
`;
const Desc = styled.p`
    display: block;
    width: 97%;
    margin: auto;
    height: 18px;
    margin-top: 24px;
    border-radius: 4px;
    background-color: #00beb0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #ffffff;
`;
const Ul = styled.ul`
    list-style: none;
    margin-top: 10px;
`;
const Li = styled.li`
    display: block;
    width: 90%;
    margin: auto;
    margin-bottom: 1px;
    cursor: pointer;
    &:hover {
        background: #f0f0f0;
    }
`;
const Circle = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    vertical-align: top;
    margin-top: 6px;
`;
const Event_desc = styled.p`
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #494949;
    margin-left: 8px;
    white-space: nowrap;
    width: 159px;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Pop_up_close = styled.svg`
    position: absolute;
    top: 4px;
    right: 7px;
    width: 12px;
    height: 16px;
    cursor: pointer;
    &:hover {
        > path {
            fill: #00beb0;
        }
    }
`;
const Current_time_line = styled.div`
    height: 3px;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
    width: 95.8%;
    margin-top: 5px;
    margin-left: -6px;
`;
const Current_time_circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
`;
const Current_time_line_container = styled.div`
    width: 104.8%;
    position: absolute;
    z-index: 999;
    top: ${(props) => props.top + "px"};
    left: -6px;
    height: 29px;
`;
const Time_text = styled.p`
    margin-left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #f0396e;
`;
const Current_day = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #00beb0;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
`;
const Current_day_nb = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
    margin-left: 10px;
`;
const Day_in_month = styled.p`
    position: absolute;
    top: 6px;
    right: 8px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #9f9f9f;
`;

const Current_day_in_month = styled.p`
    position: absolute;
    top: 6px;
    right: 8px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding-top: 3px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(MonthCalenderPage);

class TdEventContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopUp: false,
        };
    }
    render() {
        const { renderedArray, otherEvents, orderedArray, day } = this.props;
        const { showPopUp } = this.state;
        return (
            <div style={{ position: "relative", marginTop: 30 }}>
                {/* <Desc>Saltem non obsecrans dolor</Desc> */}
                <Ul>
                    {renderedArray.map((renderedItem) => {
                        return renderedItem;
                    })}
                </Ul>
                {otherEvents.length != 0 && (
                    <Other_event>
                        <Desc onClick={() => this.setState({ showPopUp: !showPopUp })}>{otherEvents.length} autres</Desc>
                        {showPopUp && (
                            <Pop_up width={100 / (5 - 2)}>
                                <Pop_up_container>
                                    <Pop_up_close onClick={() => this.setState({ showPopUp: false })} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                        <path
                                            fill="#9f9f9f"
                                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                        ></path>
                                    </Pop_up_close>
                                    <H4>{orderedArray[day] && orderedArray[day].length !== 0 && moment(orderedArray[day][0].date_debut).format("MMMM DD")}</H4>
                                    {/* <Desc>Saltem non obsecrans dolor</Desc> */}
                                    <Ul>
                                        {otherEvents.map((renderedItem) => {
                                            return renderedItem;
                                        })}
                                    </Ul>
                                </Pop_up_container>
                            </Pop_up>
                        )}
                    </Other_event>
                )}
            </div>
        );
    }
}
