import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import PlanningTable from "../components/planning.table/Table";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";

class PlanningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
            galleryPhoto: false,
        };
    }
    componentDidMount() {
        document.title = "Planning - Sobrus Labs";
    }
    render() {
        return (
            <Main_container className="Main_container">
                <PlanningTable {...this.props}></PlanningTable>
                {/* <TableModel
                    title="Planning"
                    onReload={() => ("onReload")}
                    BasicSearchComponent={<div>testttttt</div>}
                    AdvancedSearchComponent={<div>testttttt</div>}
                    thead={[
                        { property: "nom", label: "Nom" },
                        { property: "date_debut", label: "Date début" },
                        { property: "date_fin", label: "Date fin" },
                        { property: "cree_par", label: "Superviseur" },
                    ]}
                    tbody={{
                            dataIsLoaded: true,
                            data: [{cree_le: "2020-07-16T11:14:11+00:00",cree_par: "red moon",date_debut: "2020-07-16T11:14:01+00:00",date_fin: "2020-07-27T11:14:01+00:00",id: 3,nom: "sszsz"}],
                            onClickRow:()=>{("row is clicked")},
                    }}
                    tfoot={{
                        colSpan: 8,
                        pageNumber: 1,
                        totalItems:30,
                        nextPage: () => { ("nextPage") },
                        PrevPage:()=>{("PrevPage")}
                    }}
                    {...this.props} 
                    /> */}
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(PlanningPage);
