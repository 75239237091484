import React, { Component } from "react";
import Header from "../helpers/Header";
import { Link } from "react-router-dom";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactsTable from "../components/ContactsTable";
import moment from "moment";
import Axios from "axios";
import { Token } from "../tmp/Token";
import { BASE_URL } from "../env";

class WeekCalenderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top: 0,
            moment: moment().format("HH:mm"),
            hour: moment().format("HH"),
            minute: moment().format("mm"),
            day: moment().format("dddd"),
            day_nb: moment().format("DD"),
            toDay: moment().format("DD MMMM YYYY"),
            validDate: moment().format(),
            currentDay: moment().format("dddd"),
            daysInWeek: [],
        };
    }
    current() {
        setInterval(() => this.setState({ top: this.state.top + 1, moment: moment().format("HH:mm"), hour: moment().format("HH"), minute: moment().format("mm"), day_nb: moment().format("DD") }), 10000);
        //this.setState({top:this.state.top+1});
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";
        this.setState({ currentDay: moment().format("dddd") });
        this.current();
        this.weekDays();
        this.getEvents();
    }
    async getEvents() {
        const { validDate } = this.state;
        const headers = {
            "Content-type": "application/json",
            Authorization: "BEARER " + Token,
        };
        const data = {
            interval: {
                col: "date_debut",
                start: "2020-01-20",
                end: "2020-01-26",
            },
        };
        const response = await Axios.post(BASE_URL + "/api/events/1", data, { headers: headers });
        if (response.status == 200) {
            const orderedMatrix = [];
            response.data.events.map((event) => {
                const hour = moment(event.date_debut).format("HH");
                const date = moment(event.date_debut).format("YYYY-MM-DD");
                orderedMatrix[hour] = event;
                // const index = moment(event.date_debut).format("HH") - 1;
                // if (orderedMatrix[index] == undefined) {
                //     orderedMatrix[index] = [event]
                // } else {
                //     orderedArray[index] = [...orderedArray[index], event]
                // }
                //(moment(event.date_debut).format("h"))
            });
            // this.setState({ orderedArray }, () => (this.state.orderedArray))
        } else {
        }
    }
    firstDay() {
        const { validDate } = this.state;
    }
    weekDays() {
        const { validDate } = this.state;
        const daysInWeek = [];
        const firstDateInWeek = moment(validDate).startOf("week").format();
        // const firstDayInWeek = moment(validDate).startOf('week').format("DD");
        // const lastDayInWeek = moment(validDate).endOf('week').format("DD");
        let tmp = firstDateInWeek;
        daysInWeek.push(moment(tmp).format("DD"));
        for (var i = 1; i <= 6; i++) {
            tmp = moment(tmp).add(1, "day").endOf("day").format();
            daysInWeek.push(moment(tmp).format("DD"));
        }
        this.setState({ daysInWeek });
    }
    render() {
        const { daysInWeek } = this.state;
        return (
            <Main_container className="Main_container">
                <TopOptions title="Calendrier"></TopOptions>
                <Bloc className="Bloc">
                    <Bloc_header>
                        <Right_content>
                            <Date_container>
                                <Previous_btn onClick={() => this.setState({ toDay: moment(this.state.validDate).add(-1, "weeks").startOf("isoWeek").format("DD MMMM YYYY"), validDate: moment(this.state.validDate).add(-1, "weeks").startOf("isoWeek").format() }, () => this.weekDays())}>
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path
                                            fill="#9f9f9f"
                                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                        ></path>
                                    </Svg>
                                </Previous_btn>
                                <Date_text>{this.state.toDay}</Date_text>
                                <Next_btn onClick={() => this.setState({ toDay: moment(this.state.validDate).add(1, "weeks").startOf("isoWeek").format("DD MMMM YYYY"), validDate: moment(this.state.validDate).add(1, "weeks").startOf("isoWeek").format() }, () => this.weekDays())}>
                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path
                                            fill="#9f9f9f"
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                        ></path>
                                    </Svg>
                                </Next_btn>
                            </Date_container>
                            <Btn_container>
                                <Btn>Mois</Btn>
                                <Clicked_btn> Semaine</Clicked_btn>
                                <Btn>Jours</Btn>
                                <Btn>Aujourd'hui</Btn>
                            </Btn_container>
                        </Right_content>
                    </Bloc_header>
                    <Calender_table>
                        <thead>
                            <Tr>
                                <Th1>
                                    <Clock aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path
                                            fill="#494949"
                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                                        ></path>
                                    </Clock>
                                </Th1>
                                <Th>
                                    {this.state.currentDay == "lundi" ? (
                                        <div>
                                            <Current_day>Lundi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[0]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Lundi {this.state.daysInWeek[0]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "mardi" ? (
                                        <div>
                                            <Current_day>Mardi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[1]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Mardi {this.state.daysInWeek[1]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "mercredi" ? (
                                        <div>
                                            <Current_day>Mercredi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[2]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Mercredi {this.state.daysInWeek[2]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "jeudi" ? (
                                        <div>
                                            <Current_day>Jeudi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[3]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Jeudi {this.state.daysInWeek[3]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "vendredi" ? (
                                        <div>
                                            <Current_day>Vendredi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[4]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Vendredi {this.state.daysInWeek[4]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "samedi" ? (
                                        <div>
                                            <Current_day>Samedi</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[5]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Samedi {this.state.daysInWeek[5]}</div>
                                    )}
                                </Th>
                                <Th>
                                    {this.state.currentDay == "dimanche" ? (
                                        <div>
                                            <Current_day>Dimanche</Current_day>
                                            <Current_day_nb>{this.state.daysInWeek[6]}</Current_day_nb>
                                        </div>
                                    ) : (
                                        <div>Dimanche {this.state.daysInWeek[6]}</div>
                                    )}
                                </Th>
                            </Tr>
                        </thead>
                        <Tbody>
                            <Tr>
                                <Td1>1:00</Td1>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td_last>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td_last>
                            </Tr>
                            <Tr>
                                <Td1>2:00</Td1>
                                <Td2>
                                    {this.state.hour == 2 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td_last>
                                    {this.state.hour == 1 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (2 - 0.5)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td_last>
                            </Tr>
                            <Tr>
                                <Td1>3:00</Td1>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 5 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="3" color="#F0396E" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="4" color="#6f69cb" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="5" color="#00beb0" width={100 / (5 - 2)}>
                                        <Other_event>54 autre</Other_event>
                                        <Pop_up width={100 / (5 - 2)}>
                                            <H4>7 octobre</H4>
                                            <Desc>Saltem non obsecrans dolor</Desc>
                                            <Ul>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#f89a54" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#F0396E" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#6f69cb" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#f89a54" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                            </Ul>
                                        </Pop_up>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                                <Td2>
                                    {this.state.hour == 3 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / 1}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                </Td2>
                            </Tr>
                            <Tr>
                                <Td1>4:00</Td1>
                                <Td2>
                                    {this.state.hour == 4 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>5:00</Td1>
                                <Td2>
                                    {this.state.hour == 5 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                    <Event_container index="1" color="#0cb3d6" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="2" color="#f89a54" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="3" color="#F0396E" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="4" color="#6f69cb" width={100 / (5 - 2)}>
                                        <P>15:00 - Textrinaccaum iungitur oriundi velut velut vim.</P>
                                    </Event_container>
                                    <Event_container index="5" color="#00beb0" width={100 / (5 - 2)}>
                                        <Other_event>54 autre</Other_event>
                                        <Pop_up width={100 / (5 - 2)}>
                                            <H4>7 octobre</H4>
                                            <Desc>Saltem non obsecrans dolor</Desc>
                                            <Ul>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#f89a54" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#F0396E" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#6f69cb" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#00beb0" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                                <Li>
                                                    <Circle color="#f89a54" />
                                                    <Event_desc>14:00 - Sed mattis nibh non enim malesu ante.</Event_desc>
                                                </Li>
                                            </Ul>
                                        </Pop_up>
                                    </Event_container>
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>6:00</Td1>
                                <Td2>
                                    {this.state.hour == 6 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>7:00</Td1>
                                <Td2>
                                    {this.state.hour == 7 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>8:00</Td1>
                                <Td2>
                                    {this.state.hour == 8 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>9:00</Td1>
                                <Td2>
                                    {this.state.hour == 9 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>10:00</Td1>
                                <Td2>
                                    {this.state.hour == 10 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>11:00</Td1>
                                <Td2>
                                    {this.state.hour == 11 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>12:00</Td1>
                                <Td2>
                                    {this.state.hour == 12 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>13:00</Td1>
                                <Td2>
                                    {this.state.hour == 13 ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>14:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>15:00</Td1>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Monday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Tuesday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Wednesday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Thursday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Friday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Saturday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                                <Td2>
                                    {this.state.hour == 15 && this.state.day == "Sunday" ? (
                                        <Current_time_line_container top={(80 / 60) * this.state.minute - 23}>
                                            <Time_text>{this.state.moment}</Time_text>
                                            <div>
                                                <Current_time_circle />
                                                <Current_time_line></Current_time_line>
                                            </div>
                                        </Current_time_line_container>
                                    ) : (
                                        ""
                                    )}
                                </Td2>
                            </Tr>
                            <Tr>
                                <Td1>16:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>17:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>18:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>19:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>20:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>21:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>22:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>23:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                            <Tr>
                                <Td1>00:00</Td1>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                                <Td2></Td2>
                            </Tr>
                        </Tbody>
                    </Calender_table>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Bloc_header = styled.div`
    position: relative;
    /* padding: 10px; */
    background: #f0f0f0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 48px;
`;
const Tbody = styled.tbody`
    height: 300px;
    overflow-y: scroll;
`;
const Right_content = styled.div`
    display: block;
    height: 100%;
    width: 63%;
    text-align: center;
    float: right;
`;
const Date_container = styled.div`
    display: inline-block;
    height: 100%;
    width: 25%;
    text-align: center;
    float: left;
    padding-top: 10px;
    text-align: center;
`;
const Svg = styled.svg`
    width: 10px;
`;
const Clock = styled.svg`
    width: 25px;
`;
const Next_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: right;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 5px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Previous_btn = styled.button`
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    outline: none;
    float: left;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 7px;
    &:hover {
        background-color: #00beb0;
        border: solid 1px #00beb0;
        > svg path {
            fill: #fff;
        }
    }
`;
const Date_text = styled.p`
    display: inline-block;
    vertical-align: top;
    width: 52%;
    height: 17px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #00beb0;
    overflow: hidden;
    margin-top: 7px;
`;
const Btn_container = styled.div`
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 74%;
    float: right;
    padding-top: 10px;
`;
const Btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #494949;
    margin-right: 10px;
    cursor: pointer;
    float: right;
    &:hover {
        background-color: #00beb0;
        color: #fff;
        border: solid 1px #00beb0;
    }
`;
const Clicked_btn = styled.button`
    display: inline-block;
    vertical-align: top;
    padding: 5px 22px;
    border-radius: 3px;
    border: solid 1px #00beb0;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fff;
    float: right;
    margin-right: 10px;
    cursor: pointer;
`;
const Calender_table = styled.table`
    background-color: #fff;
    border-collapse: collapse;
    position: relative;
`;
const Tr = styled.tr`
    height: 80px;
`;
const Sous_tr = styled.tr`
    height: 40px;
    background: red;
`;
const Td2 = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    position: relative;
    width: 13%;
`;
const Td_last = styled.td`
    border-bottom: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;
    position: relative;
    width: 13%;
`;
const Td1 = styled.td`
    width: 7%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Th1 = styled.th`
    width: 7%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Th = styled.th`
    width: 13%;
    border-bottom: solid 0.1px #9f9f9f;
    border-right: solid 0.1px #9f9f9f;
    border-top: solid 0.1px #9f9f9f;

    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
`;
const Under_table = styled.table`
    border-collapse: collapse;
`;
const Under_tr = styled.tr`
    height: 50%;
`;
const Under_tr_2 = styled.tr`
    height: 50%;
    border-top: dotted 0.1px #9f9f9f;
`;
const Event_container = styled.div`
    height: 40px;
    position: relative;

    border-radius: 3px;
    border: solid 0.6px #ffffff;
    background-color: ${(props) => props.color};
    width: ${(props) => props.width + "%"};
    display: inline-block;
    position: absolute;
    vertical-align: top;
    cursor: pointer;
    top: ${(props) => {
        if (props.index == 1) {
            return 0;
        } else {
            return (props.index - 1) * 9 + "px";
        }
    }};
    left: ${(props) => {
        if (props.index == 1) {
            return 0;
        } else {
            return (props.width / 2) * (props.index - 1) + "%";
        }
    }};
    &:hover {
        > div {
            display: block;
        }
    }
`;
const P = styled.p`
    padding-top: 10px;
    padding-left: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    overflow: hidden;
    width: 99%;
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Other_event = styled.p`
    padding-top: 10px;
    padding-left: 5px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    &:hover {
        color: #037d74;
    }
`;
const Pop_up = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 322px;
    height: 300px;
    display: none;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #9f9f9f;
    background-color: #ffffff;
`;
const Pop_up_container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 16px;
`;
const H4 = styled.h4`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #9f9f9f;
`;
const Desc = styled.p`
    display: block;
    width: 90%;
    margin: auto;
    height: 18px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #00beb0;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
`;
const Ul = styled.ul`
    list-style: none;
    margin-top: 10px;
`;
const Li = styled.li`
    display: block;
    width: 90%;
    margin: auto;
    margin-bottom: 7px;
    &:hover {
        background: #f0f0f0;
    }
`;
const Circle = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
`;
const Event_desc = styled.p`
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #494949;
    margin-left: 8px;
`;
const Pop_up_close = styled.svg`
    position: absolute;
    top: 4px;
    right: 7px;
    width: 12px;
    height: 16px;
    cursor: pointer;
    &:hover {
        > path {
            fill: #00beb0;
        }
    }
`;
const Current_time_line = styled.div`
    height: 3px;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
    width: 95.8%;
    margin-top: 5px;
    margin-left: -6px;
`;
const Current_time_circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f0396e;
    display: inline-block;
    vertical-align: top;
`;
const Current_time_line_container = styled.div`
    width: 104.8%;
    position: absolute;
    z-index: 999;
    top: ${(props) => props.top + "px"};
    left: -6px;
    height: 29px;
`;
const Time_text = styled.p`
    margin-left: 16px;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #f0396e;
`;
const Current_day = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #00beb0;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
`;
const Current_day_nb = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #00beb0;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    padding-top: 5px;
    margin-left: 10px;
`;

export default WeekCalenderPage;
