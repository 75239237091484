import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import AccountDAO from "../DAO/AccountDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import PlanningDAO from "../DAO/PlanningDAO";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import { DatePicker } from "../components/CustomDatePicker";
import SearchSelect from "../helpers/SearchSelect";
import { Search_input_container, Search_icon, Svg, Search_btn, Search_btn_icon } from "../components/seachStyledComponents";
import { notify } from "../helpers/FlashMessage";
import { connect } from "react-redux";
import Acl, { acl } from "../services/acl";
class EventListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            actions: [],
            search: false,
            pageNumber: 1,
            totalItems: 0,
            name: "",
            date_start: null,
            date_end: null,
            place: "",
            type: "",
            contact: "",
            selectedOption: {},
        };
    }
    componentDidMount() {
        this.getTargetEvents();
    }
    getTargetEvents() {
        const { accountId, planningId } = this.props;
        if (accountId) {
            this.getEventsByAccountId();
        } else if (planningId) {
            this.getEventsByPlanningId();
        } else {
            this.getAllEvents();
        }
    }
    async getEventsByAccountId() {
        try {
            const { accountId } = this.props;
            const { search, pageNumber, name, date_start, date_end, place, type, contact } = this.state;
            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });

            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await AccountDAO.getEventsByAccountId(pageNumber, accountId, {}, data, 5);
            this.setState({ events: response.data.events, totalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getEventsByPlanningId() {
        try {
            const { planningId } = this.props;
            const { search, pageNumber, name, date_start, date_end, place, type, contact } = this.state;
            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await PlanningDAO.getPlanningEvents(planningId, {}, data);
            this.setState({ events: response && response.data ? response.data : [], totalItems: response && response.data ? response.data.length : 0 });
        } catch (error) { }
    }
    async getAllEvents() {
        try {
            const { search, pageNumber, name, date_start, date_end, place, type, contact } = this.state;
            const data = CalenderDAO.getSearchDataForTable({ search, name, date_start, date_end, place, type, contact });
            const response = await CalenderDAO.getALLEvents(pageNumber, {}, data);
            this.setState({ events: response.data.events, totalItems: response.data.total });
        } catch (error) { }
    }

    handleKeyDown(e) {
        const { accountId } = this.props;

        if (e.key === "Enter") {
            this.setState({ pageNumber: 1 }, () => (accountId ? this.getEventsByAccountId() : this.getAllEvents()));
        }
    }
    resetSearch() {
        const { accountId } = this.props;
        this.setState(
            {
                name: "",
                date_start: null,
                date_end: null,
                place: "",
                type: "",
                contact: "",
                selectedOption: {},
            },
            () => this.getTargetEvents()
        );
    }
    onClickRow(event, index) {
        const { accountId, onClickRow } = this.props;

        onClickRow ? onClickRow(event, index) : acl.calender.details() && this.props.history.push(`${RoutesPath.calender.details}/${event.id}`);
    }
    render() {
        const { accountId, onClickRow, onClickCreate } = this.props;
        const { noEventsCas } = this.props;
        const { events, search, pageNumber, totalItems } = this.state;
        console.log("events events events", events)

        if (!search && events && events.length === 0) {
            return noEventsCas;
        }
        const { planningId } = this.props;
        return (
            <Bloc className="Bloc">
                <div className="bloc_header">
                    <h2>Liste des événements</h2>
                    <div className="bloc_header_btns">
                        {!planningId && <a onClick={() => this.setState({ search: !search })} className="sprite-bloc-search" />}
                        <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                    </div>
                </div>
                <div className="bloc_content table_wrraper">
                    <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                        <thead>
                            {search && this.renderSearchComp()}

                            <tr className="table_th_orderable">
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Nom
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" /> Date de début
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Date de fin
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Lieu
                                </Th>
                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Type
                                </Th>

                                <Th>
                                    <OrderUp href="#" />
                                    <OrderDown href="#" />
                                    Contact
                                </Th>
                                <Th className="table_actions" />
                            </tr>
                        </thead>

                        <tbody>
                            {events &&
                                events.length != 0 &&
                                events.map((event, index) => {
                                    return (
                                        <Tr key={"key2_" + index}>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.nom}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.date_debut && moment(event.date_debut).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.date_fin && moment(event.date_fin).format("YYYY-MM-DD HH:mm")}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.lieu}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.type}</Td>
                                            <Td onClick={() => this.onClickRow(event, index)}>{event.contact_prenom && event.contact_prenom + " " + event.contact_nom && event.contact_nom}</Td>
                                            <Td className="table_action"></Td>

                                            {/* <Td className="table_action">{planningId && <Link to={`${RoutesPath.calender.update}/${event && event.id}`} className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" />}</Td> */}
                                        </Tr>
                                    );
                                })}
                            <Tr>
                                <Td colSpan={8} className="table_pagination">
                                    <div style={{ display: "inline-block", marginRight: "20px" }}>
                                        {acl.calender.add() && (
                                            <a className="btn_white" onClick={onClickCreate ? onClickCreate : () => this.props.history.push(`${RoutesPath.calender.add}/${this.props.accountId}`)}>
                                                Créer
                                            </a>
                                        )}
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        {pageNumber > 1 && (
                                            <a
                                                className="sprite-table-prev-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                        accountId ? this.getEventsByAccountId() : this.getAllEvents();
                                                    });
                                                }}
                                            />
                                        )}
                                        <span className="table_page">{pageNumber}</span>
                                        {pageNumber < totalItems / (this.props.limitForPagination ? this.props.limitForPagination : 20) && (
                                            <a
                                                className="sprite-table-next-btn"
                                                onClick={() => {
                                                    this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                        accountId ? this.getEventsByAccountId() : this.getAllEvents();
                                                    });
                                                }}
                                            />
                                        )}
                                    </div>
                                </Td>
                            </Tr>
                        </tbody>
                    </table>
                </div>
            </Bloc>
        );
    }
    renderSearchComp() {
        const { accountId, planningId } = this.props;
        const { search, name, date_start, date_end, place, type, contact } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput
                            onKeyDown={this.handleKeyDown.bind(this)}
                            value={name}
                            onChange={(value) => {
                                this.setState({ name: value.target.value });
                            }}
                            label="Nom"
                        ></CustomInput>
                        <Search_icon>
                            <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="currentColor"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Svg>
                        </Search_icon>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    {(accountId || planningId) && (
                        <Search_input_container>
                            <DatePicker
                                selected={date_start}
                                onChange={(date) => {
                                    this.setState({
                                        date_start: date,
                                    });
                                }}
                                label="Date de début"
                                id="start_date"
                            />
                        </Search_input_container>
                    )}
                </Th>

                <Th backgroundColor="#fff">
                    {(accountId || planningId) && (
                        <Search_input_container>
                            <DatePicker
                                selected={date_end}
                                onChange={(date) => {
                                    this.setState({
                                        date_end: date,
                                    });
                                }}
                                label="Date de fin"
                                id="end_date"
                            />
                        </Search_input_container>
                    )}
                </Th>
                <Th backgroundColor="#fff">
                    {(accountId || planningId) && (
                        <Search_input_container>
                            <CustomInput
                                onKeyDown={this.handleKeyDown.bind(this)}
                                value={place}
                                onChange={(value) => {
                                    this.setState({ place: value.target.value });
                                }}
                                label="Lieu"
                            ></CustomInput>
                            <Search_icon>
                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill="currentColor"
                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                    ></path>
                                </Svg>
                            </Search_icon>
                        </Search_input_container>
                    )}
                </Th>
                <Th backgroundColor="#fff">
                    {(accountId || planningId) && (
                        <Search_input_container>
                            <CustomSelect
                                onChange={(value) => {
                                    this.setState({ type: value.target.value });
                                }}
                                selectedValue={type}
                                label="Type"
                            >
                                <option defaultValue></option>
                                <option value="Visite">Visite</option>
                                <option value="Formation interne ou Réunion">Formation interne ou Réunion</option>
                                <option value="Absence">Absence</option>
                                <option value="Congés">Congés</option>
                                <option value="Évènement et congrès">Évènement et congrès</option>
                            </CustomSelect>
                        </Search_input_container>
                    )}
                </Th>
                <Th backgroundColor="#fff">
                    {(accountId || planningId) && (
                        <Search_input_container>
                            <SearchSelect
                                fetchedUrl={`/api/compte/contacts/${this.props.accountId}`}
                                HTTPMethod="post"
                                receivedObject="contacts"
                                optionLabelProperty="nom"
                                optionValueProperty="id"
                                value={this.state.selectedOption}
                                placeholder="Contact"
                                onChange={(e) => {
                                    this.setState({
                                        selectedOption: e,
                                        contact: e && e.value,
                                    });
                                }}
                            />
                        </Search_input_container>
                    )}
                </Th>
                <Th backgroundColor="#fff">
                    <Search_btn onClick={() => this.setState({ pageNumber: 1 }, () => this.getTargetEvents())}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            </tr>
        );
    }
}

const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    margin: auto;
    margin-bottom: 5px;
    margin-top: 10px;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(/static/media/sprite_5.35a5a6fc.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(EventListTable);
