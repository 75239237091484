import ApiCall from "../services/ApiCall";
import moment from "moment";

class PlanningDAO {
    // for getting all planning limited by page number

    static getALLPlannings = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/plannings/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add Planning

    static addPlanning = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/planning`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //duplicate Planning

    static duplicatePlanning = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/duplicateplanning/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //delete Planning

    static deletePlanning = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/planning/disabled/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update planning

    static updatePlanning = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`api/planning/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get planning details

    static getPlanningById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get(`api/planning/${id}`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get all events linked to planning id details

    static getPlanningEvents = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {

                const response = await ApiCall.get(`/api/events/planning/${id}`, params, data);
                // let updatedData = {
                //     ...data,
                //     planning: id,
                // }
                // const response = await ApiCall.post(`/api/events/${pageNumber}`, params, updatedData);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //affect events to planning

    static affectEventsToPlanning = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/planning/events/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //affect new events to planning

    static affectNewEventsToPlanning = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/planning/ressources/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static testSearchField(fieldObject, defaultOperator, searchType) {
        if (fieldObject.value && fieldObject.value.length !== 0) {
            if (searchType === "advanced" && fieldObject.operator && fieldObject.operator.length !== 0) {
                return {
                    value: fieldObject.value,
                    op: fieldObject.operator,
                };
            }

            return {
                value: fieldObject.value,
                op: defaultOperator,
            };
        }
        return null;
    }

    static getSearchData({ searchFields, searchType, searchToggle }) {
        let data = {};
        if (searchToggle) {
            const name = PlanningDAO.testSearchField(searchFields.name, "smart", searchType);
            if (name) {
                data["nom"] = name;
            }
            const start_date = PlanningDAO.testSearchField(searchFields.start_date, "is_date", searchType);
            if (start_date) {
                data["date_debut"] = {
                    value: moment(start_date.value).format("YYYY-MM-DD"),
                    op: start_date.op,
                };
            }
            const end_date = PlanningDAO.testSearchField(searchFields.end_date, "is", searchType);
            if (end_date) {
                data["date_fin"] = {
                    value: moment(end_date.value).format("YYYY-MM-DD"),
                    op: end_date.op,
                };
            }
            const manager = PlanningDAO.testSearchField(searchFields.manager, "is", searchType);
            if (manager) {
                data["gestionnaire"] = manager;
            }
        }
        return data;
    }
}

export default PlanningDAO;
