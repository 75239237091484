import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import CustomInput from "./CustomInput";
import CustomSelect from "./CustomSelect";
import NoResultCas from "../helpers/NoResultCas";
import { Token } from "../tmp/Token";
import Loading from "./loading";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import ApiCall from "../services/ApiCall";
import ProductDAO from "../DAO/ProductDAO";
import CommonDAO from "../DAO/CommonDAO";
import { RoutesPath } from "../values/RoutesPath";
import DataLoadingError from "../components/DataLoadingError";
import SearchSelect from "../helpers/SearchSelect";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";
import configuration from "../config/configuration";
class MyProductsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            sectors: [],
            data: [],
            pageNumber: 1,
            totalItems: 0,
            search: false,
            advancedSearch: false,

            name: "",
            category: "",
            pharmaceuticalForm: "",
            PPV: "",
            PPH: "",
            barcode: "",

            pharmaceuticalForms: [],

            name_operator: "like",
            category_operator: "is",
            pharmaceuticalForm_operator: "is",
            PPV_operator: "like",
            PPH_operator: "like",
            barcode_operator: "like",

            is_loaded: false,
            error: {
                is: false,
                message: "",
            },
            orderBy: 0,
            key: "",
            product_categories: [],
            selected_pharmaceuticalForm: {},
        };
    }
    componentDidMount() {
        this.setState({ is_loaded: false }, () => this.getAll());
        this.getCites();
        this.getSectors();
        this.getProductCategories();
        this.preload();
    }
    async getAll() {
        try {
            this.setLoadingError({ is: false, message: "" });

            const { orderBy, key, advancedSearch, pageNumber, name, category, pharmaceuticalForm, PPV, PPH, barcode, search, name_operator, pharmaceuticalForm_operator, PPV_operator, category_operator, PPH_operator, barcode_operator } = this.state;

            let data = ProductDAO.getSearchData({ advancedSearch, name, category, pharmaceuticalForm, PPV, PPH, barcode, search, name_operator, pharmaceuticalForm_operator, PPV_operator, category_operator, PPH_operator, barcode_operator });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }
            const params = {};
            const response = await ProductDAO.getAllMyProducts(pageNumber, params, data);

            this.setState({ is_loaded: true, data: response.data.ressources, totalItems: response.data.total }, () => this.state.data);
        } catch (error) {
            this.setState({ is_loaded: true });
            this.setLoadingError({ is: true, message: error.message });
        }
    }

    setLoadingError(error) {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                is: error.is,
                message: error.message,
            },
        }));
    }

    async getCites() {
        try {
            const response = await CommonDAO.getAllCities(1, {});
            this.setState({ cities: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getSectors() {
        try {
            const response = await CommonDAO.getAllSectors(1, {});
            this.setState({ sectors: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    sort(property) {
        const sortedData = CommonDAO.sortData(this.state.data.ressources, property);
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }
    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.ressources, property);
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }
    sortNumbers(property) {
        let sortedData = this.state.data.ressources.sort((a, b) => {
            return a[property] > b[property] ? 1 : -1;
        });
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }
    reverseNumbers(property) {
        let sortedData = this.state.data.ressources.sort((a, b) => {
            return a[property] > b[property] ? -1 : 1;
        });
        const data = this.state.data;
        data.ressources = [...sortedData];
        this.setState({ data });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }

    async getPharmaceuticalForm() {
        try {
            const response = await CommonDAO.getAllPharmaceuticalForms(" ", "smart", 1);
            this.setState({ pharmaceuticalForms: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    resetSearch() {
        this.setState(
            {
                is_loaded: false,
                name: "",
                category: "",
                pharmaceuticalForm: "",
                PPV: "",
                PPH: "",
                barcode: "",

                pharmaceuticalForms: [],

                name_operator: "like",
                category_operator: "is",
                pharmaceuticalForm_operator: "is",
                PPV_operator: "like",
                PPH_operator: "like",
                barcode_operator: "like",
            },
            () => this.getAll()
        );
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }

    getProductCategories = async () => {
        try {
            const pageNumber = 1;
            const response = await CommonDAO.getProductCategories(pageNumber);
            this.setState({ product_categories: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
    componentWillUnmount() {
        this.storeSearchInCookies();
        window.removeEventListener('beforeunload', this.storeSearchInCookies);
    }

    preload = () => {
        this.getSearchFromCookies();
        window.addEventListener('beforeunload', this.storeSearchInCookies);
    }

    render() {
        const {
            error,
            pharmaceuticalForms,
            pharmaceuticalForm,
            is_loaded,
            data,
            pageNumber,
            totalItems,
            search,
            name,
            PPV,
            PPH,
            barcode,
            category,
            city,
            sector,
            advancedSearch,
            account_type_operator,
            PPV_operator,
            pharmaceuticalForm_operator,
            name_operator,
            category_operator,
            city_operator,
            sector_operator,
            PPH_operator,
            barcode_operator,
            key,
            orderBy,
            product_categories,
        } = this.state;
        return (
            <>
                {is_loaded && !(search || advancedSearch) && !error.is && data.length == 0 && (
                    <Bloc_2>
                        <Block_contact>
                            <Title_contact>Produits</Title_contact>
                            <Btn_contact_container>{acl.product.list() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.product.list}`)}>Afficher tous les produits</Create_contact_btn>}</Btn_contact_container>
                        </Block_contact>
                    </Bloc_2>
                )}
                {is_loaded && (error.is || data.length != 0 || search || advancedSearch) && (
                    <Bloc className="Bloc">
                        <div className="bloc_header">
                            <h2>Liste des produits</h2>
                            <div className="bloc_header_btns">
                                <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                                <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                                <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                            </div>
                        </div>
                        <TableWrapper className="bloc_content table_wrraper">
                            <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                                <thead>
                                    {advancedSearch && (
                                        <SearchTr className="table_th_orderable">
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ name_operator: value.target.value });
                                                        }}
                                                        selectedValue={name_operator}
                                                        label="Nom"
                                                    >
                                                        <option value="like" defaultValue>
                                                            contient
                                                        </option>
                                                        <option value="not_like">ne contient pas</option>
                                                        <option value="starts_with">commence par</option>
                                                        <option value="end_by">se termine par</option>
                                                        <option value="is">est</option>
                                                        <option value="is_not">n'est pas</option>
                                                        <option value="null">est vide</option>
                                                        <option value="not_null">n'est pas vide</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ category_operator: value.target.value });
                                                        }}
                                                        selectedValue={category_operator}
                                                        label="Catégorie"
                                                    >
                                                        <option value="is" defaultValue>
                                                            est
                                                        </option>
                                                        <option value="is_not">n'est pas</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ pharmaceuticalForm_operator: value.target.value });
                                                        }}
                                                        selectedValue={pharmaceuticalForm_operator}
                                                        label="Forme galénique"
                                                    >
                                                        <option value="is" defaultValue>
                                                            est
                                                        </option>
                                                        <option value="is_not">n'est pas</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ PPV_operator: value.target.value });
                                                        }}
                                                        selectedValue={PPV_operator}
                                                        label="PPV"
                                                    >
                                                        <option value="is" defaultValue>
                                                            est
                                                        </option>
                                                        <option value="is_not">n'est pas</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ PPH_operator: value.target.value });
                                                        }}
                                                        selectedValue={PPH_operator}
                                                        label="PPH"
                                                    >
                                                        <option value="is" defaultValue>
                                                            est
                                                        </option>
                                                        <option value="is_not">n'est pas</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ barcode_operator: value.target.value });
                                                        }}
                                                        selectedValue={barcode_operator}
                                                        label="Code barre"
                                                    >
                                                        <option value="like" defaultValue>
                                                            contient
                                                        </option>
                                                        <option value="not_like">ne contient pas</option>
                                                        <option value="starts_with">commence par</option>
                                                        <option value="end_by">se termine par</option>
                                                        <option value="is">est</option>
                                                        <option value="is_not">n'est pas</option>
                                                        <option value="null">est vide</option>
                                                        <option value="not_null">n'est pas vide</option>
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                    <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#fff"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Search_btn_icon>
                                                </Search_btn>
                                            </Th>
                                        </SearchTr>
                                    )}

                                    {(search || advancedSearch) && (
                                        <SearchTr className="table_th_orderable">
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomInput
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                        onChange={(value) => {
                                                            this.setState({ name: value.target.value });
                                                        }}
                                                        label="Nom"
                                                        value={name}
                                                        defaultValue={name}
                                                    ></CustomInput>
                                                    <Search_icon>
                                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                            ></path>
                                                        </Svg>
                                                    </Search_icon>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomSelect
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                        error={false}
                                                        onChange={(value) => {
                                                            this.setState({ category: value.target.value });
                                                        }}
                                                        selectedValue={category}
                                                        label="Catégorie"
                                                    >
                                                        <option defaultValue></option>
                                                        {product_categories.map((object) => (
                                                            <option value={`${object.id}`}>{object.nom + (object.margin ? `(${parseFloat(object.margin).toFixed(3)}%)` : object.fixed_margin ? `(${object.fixed_margin})` : "")}</option>
                                                        ))}
                                                    </CustomSelect>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <SearchSelect
                                                        fetchedUrl="/api/formegalenique"
                                                        HTTPMethod="get"
                                                        receivedObject="ressources"
                                                        optionLabelProperty="nom"
                                                        optionValueProperty="id"
                                                        value={this.state.selected_pharmaceuticalForm}
                                                        placeholder="Forme galénique"
                                                        onChange={(e) => {
                                                            this.setState({ selected_pharmaceuticalForm: e, pharmaceuticalForm: e && e.value });
                                                        }}
                                                    />
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomInput
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                        onChange={(value) => {
                                                            this.setState({ PPV: value.target.value });
                                                        }}
                                                        label="PPV"
                                                        value={PPV}
                                                        defaultValue={PPV}
                                                        inputType={"number"}
                                                    ></CustomInput>
                                                    <Search_icon>
                                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                            ></path>
                                                        </Svg>
                                                    </Search_icon>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomInput
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                        onChange={(value) => {
                                                            this.setState({ PPH: value.target.value });
                                                        }}
                                                        label="PPH"
                                                        value={PPH}
                                                        defaultValue={PPH}
                                                        inputType={"number"}
                                                    ></CustomInput>
                                                    <Search_icon>
                                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                            ></path>
                                                        </Svg>
                                                    </Search_icon>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                <Search_input_container>
                                                    <CustomInput
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                        onChange={(value) => {
                                                            this.setState({ barcode: value.target.value });
                                                        }}
                                                        label="Code barre"
                                                        value={barcode}
                                                        defaultValue={barcode}
                                                    ></CustomInput>
                                                    <Search_icon>
                                                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                fill="currentColor"
                                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                            ></path>
                                                        </Svg>
                                                    </Search_icon>
                                                </Search_input_container>
                                            </Th>
                                            <Th backgroundColor="#fff">
                                                {search && (
                                                    <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path
                                                                fill="#fff"
                                                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                            ></path>
                                                        </Search_btn_icon>
                                                    </Search_btn>
                                                )}
                                            </Th>
                                        </SearchTr>
                                    )}
                                    <tr className="table_th_orderable">
                                        <Th key="nom" orderBy={key === "nom" && orderBy} onClick={() => this.setState({ key: "nom", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "nom" && orderBy === -1) || orderBy === 0 || key !== "nom") && <OrderUp />}
                                            {((key === "nom" && orderBy === 1) || orderBy === 0 || key !== "nom") && <OrderDown />}
                                            Nom
                                        </Th>
                                        <Th key="categorie" orderBy={key === "categorie" && orderBy} onClick={() => this.setState({ key: "categorie", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "categorie" && orderBy === -1) || orderBy === 0 || key !== "categorie") && <OrderUp />}
                                            {((key === "categorie" && orderBy === 1) || orderBy === 0 || key !== "categorie") && <OrderDown />}
                                            Catégorie
                                        </Th>
                                        <Th key="forme_galenique" orderBy={key === "forme_galenique" && orderBy} onClick={() => this.setState({ key: "forme_galenique", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "forme_galenique" && orderBy === -1) || orderBy === 0 || key !== "forme_galenique") && <OrderUp />}
                                            {((key === "forme_galenique" && orderBy === 1) || orderBy === 0 || key !== "forme_galenique") && <OrderDown />}
                                            Forme galénique
                                        </Th>
                                        <Th key="ppv" orderBy={key === "ppv" && orderBy} onClick={() => this.setState({ key: "ppv", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "ppv" && orderBy === -1) || orderBy === 0 || key !== "ppv") && <OrderUp />}
                                            {((key === "ppv" && orderBy === 1) || orderBy === 0 || key !== "ppv") && <OrderDown />}
                                            PPV
                                        </Th>
                                        <Th key="pph" orderBy={key === "pph" && orderBy} onClick={() => this.setState({ key: "pph", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "pph" && orderBy === -1) || orderBy === 0 || key !== "pph") && <OrderUp />}
                                            {((key === "pph" && orderBy === 1) || orderBy === 0 || key !== "pph") && <OrderDown />}
                                            PPH
                                        </Th>
                                        <Th key="code_barre" orderBy={key === "code_barre" && orderBy} onClick={() => this.setState({ key: "code_barre", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                            {((key === "code_barre" && orderBy === -1) || orderBy === 0 || key !== "code_barre") && <OrderUp />}
                                            {((key === "code_barre" && orderBy === 1) || orderBy === 0 || key !== "code_barre") && <OrderDown />}
                                            Code barre
                                        </Th>

                                        <Th className="table_actions" />
                                    </tr>
                                </thead>
                                {!is_loaded && (
                                    <tbody>
                                        {[1, 2, 3].map((index) => {
                                            return (
                                                <Tr key={"key__" + index}>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                    <Td>
                                                        <ContentLoader width="160" height="20">
                                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                        </ContentLoader>
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                    </tbody>
                                )}
                                {is_loaded && data && data.length != 0 && (
                                    <tbody>
                                        {data.map((product, index) => {
                                            return (
                                                <Tr
                                                    onClick={() => {
                                                        acl.product.details() && this.props.history.push(`${RoutesPath.product.details}/${product.id}`, { test: "tetttt" });
                                                    }}
                                                    key={"key_" + index}
                                                >
                                                    <Td>{product.nom}</Td>
                                                    <Td>{product.categorie + (product.categorie_margin ? ` (${parseFloat(product.categorie_margin).toFixed(3)}%)` : product.categorie_fixed_margin ? ` (${product.categorie_fixed_margin})` : "")}</Td>
                                                    <Td>{product.forme_galenique}</Td>
                                                    <Td>{product.ppv}</Td>
                                                    <Td>{product.pph}</Td>
                                                    <Td>{product.code_barre}</Td>
                                                    <Td className="table_action"> </Td>
                                                </Tr>
                                            );
                                        })}

                                        <Tr>
                                            <Td colSpan={8} className="table_pagination">
                                                <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                                <div style={{ display: "inline-block" }}>
                                                    {pageNumber > 1 && (
                                                        <a
                                                            className="sprite-table-prev-btn"
                                                            onClick={() => {
                                                                this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                                    this.getAll();
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                    <span className="table_page">{pageNumber}</span>
                                                    {pageNumber < totalItems / 20 && (
                                                        <a
                                                            className="sprite-table-next-btn"
                                                            onClick={() => {
                                                                this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                                    this.getAll();
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </Td>
                                        </Tr>
                                    </tbody>
                                )}
                            </table>
                            {is_loaded && error.is && <DataLoadingError message={error.message} />}
                        </TableWrapper>
                    </Bloc>
                )}
            </>
        );
    }

    getSearchFromCookies = () => {
        const searchFromCookie = configuration.getSearchFromCookies("MyProducts");
        if (searchFromCookie) {
            this.setState((prevState) => ({
                ...prevState,
                ...searchFromCookie.searchOperators,
                ...searchFromCookie.searchValues,
                advancedSearch: searchFromCookie.advancedSearch,
                search: searchFromCookie.search,
            }))
        }
    }
    storeSearchInCookies = () => {
        const { search, advancedSearch } = this.state;
        const { name_operator, category_operator, pharmaceuticalForm_operator, PPV_operator, PPH_operator, barcode_operator } = this.state;
        const { name, category, pharmaceuticalForm, PPV, PPH, barcode, selected_pharmaceuticalForm } = this.state;
        const searchOperators = {
            name_operator,
            category_operator,
            pharmaceuticalForm_operator,
            PPV_operator,
            PPH_operator,
            barcode_operator,
        }
        const searchValues = {
            name,
            category,
            pharmaceuticalForm,
            PPV,
            PPH,
            barcode,
            selected_pharmaceuticalForm
        }
        configuration.storeSearchInCookies({ search, advancedSearch, searchOperators, searchValues }, "MyProducts");
    }
}
const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;

const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc_2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    ${"" /* margin-bottom: 60px; */}
    margin-top: 10px;
`;
export default MyProductsTable;
