import React, { Component } from "react";
import { Link } from "react-router-dom";

class ApproveInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div id="main_container" style={{ height: "100%" }}>
                    <div id="fixed_bar" className="top_options_wrapper" style={{ position: "static" }}>
                        <div className="wrapper">
                            <div className="container4">
                                <div className="grid4">
                                    <h1>Créer une nouvelle vente</h1>
                                    <div className="top_options_btns">
                                        <Link to={"create"} id="go_back_to_form" className="btn_white" style={{}}>
                                            Retouner
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="content">
                        <div className="wrapper">
                            <div id="main_form_container" className="container4" style={{ display: "none" }}>
                                <form action="/invoice/create" method="post" id="create_invoices_form" className="validateable">
                                    <div className="grid2">
                                        {/* Customer/Contact */}
                                        <div className="bloc">
                                            {/* Customer */}
                                            <div className="bloc_content" style={{ background: "#ff5778" }}>
                                                <i className="person" />
                                                <div className="plan-search">
                                                    <label htmlFor="customer_id" className="inline_label" style={{}}>
                                                        Client
                                                    </label>
                                                    <div className="inline_input">
                                                        <div className="input_search">
                                                            <input type="text" name="customer_id" id="customer_id" className="lookup_field" readOnly="readonly" data-lookup_table="customers" autoComplete="off" />
                                                            <input type="hidden" name="customer_id" id="hidden_customer_id" />
                                                            <a className="sprite-search-btn lookup_field_trigger" />
                                                            <a className="sprite-close-btn lookup_field_eraser" />
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                                <div style={{ display: "none" }}>
                                                    <div className="inline_input">
                                                        <input type="text" name="address" id="address" className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <input type="text" name="city" id="city" defaultValue className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <input type="text" name="postal_code" id="postal_code" defaultValue className=" input" autoComplete="off" />
                                                    </div>
                                                    <div className="inline_input">
                                                        <div className="selectbox">
                                                            <select name="country" id="country" autoComplete="off">
                                                                <option value>Aucun</option>
                                                                <option value="AF">Afghanistan</option>
                                                                <option value="ZA">Afrique du Sud</option>
                                                                <option value="AL">Albanie</option>
                                                                <option value="DZ">Algérie</option>
                                                                <option value="DE">Allemagne</option>
                                                                <option value="AD">Andorre</option>
                                                                <option value="AO">Angola</option>
                                                                <option value="AI">Anguilla</option>
                                                                <option value="AQ">Antarctique</option>
                                                                <option value="AG">Antigua-et-Barbuda</option>
                                                                <option value="AN">Antilles néerlandaises</option>
                                                                <option value="SA">Arabie saoudite</option>
                                                                <option value="AR">Argentine</option>
                                                                <option value="AM">Arménie</option>
                                                                <option value="AW">Aruba</option>
                                                                <option value="AU">Australie</option>
                                                                <option value="AT">Autriche</option>
                                                                <option value="AZ">Azerbaïdjan</option>
                                                                <option value="BS">Bahamas</option>
                                                                <option value="BH">Bahreïn</option>
                                                                <option value="BD">Bangladesh</option>
                                                                <option value="BB">Barbade</option>
                                                                <option value="BE">Belgique</option>
                                                                <option value="BZ">Belize</option>
                                                                <option value="BM">Bermudes</option>
                                                                <option value="BT">Bhoutan</option>
                                                                <option value="BY">Biélorussie</option>
                                                                <option value="BO">Bolivie</option>
                                                                <option value="BA">Bosnie-Herzégovine</option>
                                                                <option value="BW">Botswana</option>
                                                                <option value="BN">Brunéi Darussalam</option>
                                                                <option value="BR">Brésil</option>
                                                                <option value="BG">Bulgarie</option>
                                                                <option value="BF">Burkina Faso</option>
                                                                <option value="BI">Burundi</option>
                                                                <option value="BJ">Bénin</option>
                                                                <option value="KH">Cambodge</option>
                                                                <option value="CM">Cameroun</option>
                                                                <option value="CA">Canada</option>
                                                                <option value="CV">Cap-Vert</option>
                                                                <option value="EA">Ceuta et Melilla</option>
                                                                <option value="CL">Chili</option>
                                                                <option value="CN">Chine</option>
                                                                <option value="CY">Chypre</option>
                                                                <option value="CO">Colombie</option>
                                                                <option value="KM">Comores</option>
                                                                <option value="CG">Congo-Brazzaville</option>
                                                                <option value="CD">Congo-Kinshasa</option>
                                                                <option value="KP">Corée du Nord</option>
                                                                <option value="KR">Corée du Sud</option>
                                                                <option value="CR">Costa Rica</option>
                                                                <option value="HR">Croatie</option>
                                                                <option value="CU">Cuba</option>
                                                                <option value="CW">Curaçao</option>
                                                                <option value="CI">Côte d’Ivoire</option>
                                                                <option value="DK">Danemark</option>
                                                                <option value="DG">Diego Garcia</option>
                                                                <option value="DJ">Djibouti</option>
                                                                <option value="DM">Dominique</option>
                                                                <option value="SV">El Salvador</option>
                                                                <option value="ES">Espagne</option>
                                                                <option value="EE">Estonie</option>
                                                                <option value="FJ">Fidji</option>
                                                                <option value="FI">Finlande</option>
                                                                <option value="FR">France</option>
                                                                <option value="GA">Gabon</option>
                                                                <option value="GM">Gambie</option>
                                                                <option value="GH">Ghana</option>
                                                                <option value="GI">Gibraltar</option>
                                                                <option value="GD">Grenade</option>
                                                                <option value="GL">Groenland</option>
                                                                <option value="GR">Grèce</option>
                                                                <option value="GP">Guadeloupe</option>
                                                                <option value="GU">Guam</option>
                                                                <option value="GT">Guatemala</option>
                                                                <option value="GG">Guernesey</option>
                                                                <option value="GN">Guinée</option>
                                                                <option value="GQ">Guinée équatoriale</option>
                                                                <option value="GW">Guinée-Bissau</option>
                                                                <option value="GY">Guyana</option>
                                                                <option value="GF">Guyane française</option>
                                                                <option value="GE">Géorgie</option>
                                                                <option value="GS">Géorgie du Sud et les Îles Sandwich du Sud</option>
                                                                <option value="HT">Haïti</option>
                                                                <option value="HN">Honduras</option>
                                                                <option value="HU">Hongrie</option>
                                                                <option value="IN">Inde</option>
                                                                <option value="ID">Indonésie</option>
                                                                <option value="IQ">Irak</option>
                                                                <option value="IR">Iran</option>
                                                                <option value="IE">Irlande</option>
                                                                <option value="IS">Islande</option>
                                                                <option value="IL">Israël</option>
                                                                <option value="IT">Italie</option>
                                                                <option value="JM">Jamaïque</option>
                                                                <option value="JP">Japon</option>
                                                                <option value="JE">Jersey</option>
                                                                <option value="JO">Jordanie</option>
                                                                <option value="KZ">Kazakhstan</option>
                                                                <option value="KE">Kenya</option>
                                                                <option value="KG">Kirghizistan</option>
                                                                <option value="KI">Kiribati</option>
                                                                <option value="XK">Kosovo</option>
                                                                <option value="KW">Koweït</option>
                                                                <option value="RE">La Réunion</option>
                                                                <option value="LA">Laos</option>
                                                                <option value="LS">Lesotho</option>
                                                                <option value="LV">Lettonie</option>
                                                                <option value="LB">Liban</option>
                                                                <option value="LY">Libye</option>
                                                                <option value="LR">Libéria</option>
                                                                <option value="LI">Liechtenstein</option>
                                                                <option value="LT">Lituanie</option>
                                                                <option value="LU">Luxembourg</option>
                                                                <option value="MK">Macédoine</option>
                                                                <option value="MG">Madagascar</option>
                                                                <option value="MY">Malaisie</option>
                                                                <option value="MW">Malawi</option>
                                                                <option value="MV">Maldives</option>
                                                                <option value="ML">Mali</option>
                                                                <option value="MT">Malte</option>
                                                                <option value="MA">Maroc</option>
                                                                <option value="MQ">Martinique</option>
                                                                <option value="MU">Maurice</option>
                                                                <option value="MR">Mauritanie</option>
                                                                <option value="YT">Mayotte</option>
                                                                <option value="MX">Mexique</option>
                                                                <option value="MD">Moldavie</option>
                                                                <option value="MC">Monaco</option>
                                                                <option value="MN">Mongolie</option>
                                                                <option value="MS">Montserrat</option>
                                                                <option value="ME">Monténégro</option>
                                                                <option value="MZ">Mozambique</option>
                                                                <option value="MM">Myanmar</option>
                                                                <option value="NA">Namibie</option>
                                                                <option value="NR">Nauru</option>
                                                                <option value="NI">Nicaragua</option>
                                                                <option value="NE">Niger</option>
                                                                <option value="NG">Nigéria</option>
                                                                <option value="NU">Niue</option>
                                                                <option value="NO">Norvège</option>
                                                                <option value="NC">Nouvelle-Calédonie</option>
                                                                <option value="NZ">Nouvelle-Zélande</option>
                                                                <option value="NP">Népal</option>
                                                                <option value="OM">Oman</option>
                                                                <option value="UG">Ouganda</option>
                                                                <option value="UZ">Ouzbékistan</option>
                                                                <option value="PK">Pakistan</option>
                                                                <option value="PW">Palaos</option>
                                                                <option value="PA">Panama</option>
                                                                <option value="PG">Papouasie-Nouvelle-Guinée</option>
                                                                <option value="PY">Paraguay</option>
                                                                <option value="NL">Pays-Bas</option>
                                                                <option value="BQ">Pays-Bas caribéens</option>
                                                                <option value="PH">Philippines</option>
                                                                <option value="PN">Pitcairn</option>
                                                                <option value="PL">Pologne</option>
                                                                <option value="PF">Polynésie française</option>
                                                                <option value="PR">Porto Rico</option>
                                                                <option value="PT">Portugal</option>
                                                                <option value="PE">Pérou</option>
                                                                <option value="QA">Qatar</option>
                                                                <option value="HK">R.A.S. chinoise de Hong Kong</option>
                                                                <option value="MO">R.A.S. chinoise de Macao</option>
                                                                <option value="RO">Roumanie</option>
                                                                <option value="GB">Royaume-Uni</option>
                                                                <option value="RU">Russie</option>
                                                                <option value="RW">Rwanda</option>
                                                                <option value="CF">République centrafricaine</option>
                                                                <option value="DO">République dominicaine</option>
                                                                <option value="CZ">République tchèque</option>
                                                                <option value="EH">Sahara occidental</option>
                                                                <option value="BL">Saint-Barthélemy</option>
                                                                <option value="KN">Saint-Kitts-et-Nevis</option>
                                                                <option value="SM">Saint-Marin</option>
                                                                <option value="MF">Saint-Martin (partie française)</option>
                                                                <option value="SX">Saint-Martin (partie néerlandaise)</option>
                                                                <option value="PM">Saint-Pierre-et-Miquelon</option>
                                                                <option value="VC">Saint-Vincent-et-les Grenadines</option>
                                                                <option value="SH">Sainte-Hélène</option>
                                                                <option value="LC">Sainte-Lucie</option>
                                                                <option value="WS">Samoa</option>
                                                                <option value="AS">Samoa américaines</option>
                                                                <option value="ST">Sao Tomé-et-Principe</option>
                                                                <option value="RS">Serbie</option>
                                                                <option value="SC">Seychelles</option>
                                                                <option value="SL">Sierra Leone</option>
                                                                <option value="SG">Singapour</option>
                                                                <option value="SK">Slovaquie</option>
                                                                <option value="SI">Slovénie</option>
                                                                <option value="SO">Somalie</option>
                                                                <option value="SD">Soudan</option>
                                                                <option value="SS">Soudan du Sud</option>
                                                                <option value="LK">Sri Lanka</option>
                                                                <option value="CH">Suisse</option>
                                                                <option value="SR">Suriname</option>
                                                                <option value="SE">Suède</option>
                                                                <option value="SJ">Svalbard et Jan Mayen</option>
                                                                <option value="SZ">Swaziland</option>
                                                                <option value="SY">Syrie</option>
                                                                <option value="SN">Sénégal</option>
                                                                <option value="TJ">Tadjikistan</option>
                                                                <option value="TZ">Tanzanie</option>
                                                                <option value="TW">Taïwan</option>
                                                                <option value="TD">Tchad</option>
                                                                <option value="TF">Terres australes françaises</option>
                                                                <option value="IO">Territoire britannique de l’océan Indien</option>
                                                                <option value="PS">Territoires palestiniens</option>
                                                                <option value="TH">Thaïlande</option>
                                                                <option value="TL">Timor oriental</option>
                                                                <option value="TG">Togo</option>
                                                                <option value="TK">Tokelau</option>
                                                                <option value="TO">Tonga</option>
                                                                <option value="TT">Trinité-et-Tobago</option>
                                                                <option value="TA">Tristan da Cunha</option>
                                                                <option value="TN">Tunisie</option>
                                                                <option value="TM">Turkménistan</option>
                                                                <option value="TR">Turquie</option>
                                                                <option value="TV">Tuvalu</option>
                                                                <option value="UA">Ukraine</option>
                                                                <option value="UY">Uruguay</option>
                                                                <option value="VU">Vanuatu</option>
                                                                <option value="VE">Venezuela</option>
                                                                <option value="VN">Vietnam</option>
                                                                <option value="WF">Wallis-et-Futuna</option>
                                                                <option value="YE">Yémen</option>
                                                                <option value="ZM">Zambie</option>
                                                                <option value="ZW">Zimbabwe</option>
                                                                <option value="ZZ">région indéterminée</option>
                                                                <option value="EG">Égypte</option>
                                                                <option value="AE">Émirats arabes unis</option>
                                                                <option value="EC">Équateur</option>
                                                                <option value="ER">Érythrée</option>
                                                                <option value="VA">État de la Cité du Vatican</option>
                                                                <option value="FM">États fédérés de Micronésie</option>
                                                                <option value="US">États-Unis</option>
                                                                <option value="ET">Éthiopie</option>
                                                                <option value="BV">Île Bouvet</option>
                                                                <option value="CX">Île Christmas</option>
                                                                <option value="CP">Île Clipperton</option>
                                                                <option value="NF">Île Norfolk</option>
                                                                <option value="IM">Île de Man</option>
                                                                <option value="AC">Île de l’Ascension</option>
                                                                <option value="IC">Îles Canaries</option>
                                                                <option value="KY">Îles Caïmans</option>
                                                                <option value="CC">Îles Cocos (Keeling)</option>
                                                                <option value="CK">Îles Cook</option>
                                                                <option value="FO">Îles Féroé</option>
                                                                <option value="HM">Îles Heard et MacDonald</option>
                                                                <option value="FK">Îles Malouines</option>
                                                                <option value="MP">Îles Mariannes du Nord</option>
                                                                <option value="MH">Îles Marshall</option>
                                                                <option value="SB">Îles Salomon</option>
                                                                <option value="TC">Îles Turques-et-Caïques</option>
                                                                <option value="VG">Îles Vierges britanniques</option>
                                                                <option value="VI">Îles Vierges des États-Unis</option>
                                                                <option value="UM">Îles mineures éloignées des États-Unis</option>
                                                                <option value="AX">Îles Åland</option>
                                                            </select>
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                            {/* Contact */}
                                            <div id="contact_container" className="bloc_content" style={{ display: "none", background: "#ff5778" }}>
                                                <i className="person" />
                                                <div className="plan-search">
                                                    <label htmlFor="contact_id" className="inline_label" style={{}}>
                                                        Contact
                                                    </label>
                                                    <div className="inline_input">
                                                        <div className="input_search">
                                                            <input type="text" name="contact_id" id="contact_id" defaultValue className="lookup_field" readOnly="readonly" data-lookup_table="contacts" autoComplete="off" />
                                                            <input type="hidden" name="contact_id" id="hidden_contact_id" defaultValue />
                                                            <a className="sprite-search-btn lookup_field_trigger" />
                                                            <a className="sprite-close-btn lookup_field_eraser" />
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Products */}
                                        <div className="bloc">
                                            <div className="bloc_header">
                                                <i className="vente_icon" />
                                                <div className="bloc_header_btns vente_champs_cb">
                                                    <div className="selectbox " style={{}}>
                                                        <select name="transactions_products_dci" id="transactions_products_dci" className=" " autoComplete="off">
                                                            <option value>DCI</option>
                                                            <option value={1}>ACARBOSE</option>
                                                            <option value={2}>ACEBUTOLOL</option>
                                                            <option value={3}>ACECLOFENAC</option>
                                                            <option value={4}>ACEGLUMATE DE DEANOL</option>
                                                            <option value={5}>ACEMETACINE</option>
                                                            <option value={6}>ACENOCOUMAROL</option>
                                                            <option value={7}>ACETATE DE CALCIUM</option>
                                                            <option value={8}>ACETAZOLAMIDE</option>
                                                            <option value={9}>ACETYLCYSTEINE</option>
                                                            <option value={10}>ACETYLCYSTEINE / SULFATE DE TUAMINOHEPTANE</option>
                                                            <option value={11}>ACETYLLEUCINE</option>
                                                            <option value={12}>ACICLOVIR</option>
                                                            <option value={13}>ACIDE ACETYLSALICYLIQUE</option>
                                                            <option value={14}>ACIDE ACETYLSALICYLIQUE / ACIDE ASCORBIQUE (VITAMINE C)</option>
                                                            <option value={15}>ACIDE ACETYLSALICYLIQUE / ACIDE ASCORBIQUE (VITAMINE C) / CAFEINE</option>
                                                            <option value={16}>ACIDE ACETYLSALICYLIQUE / CYANOCOBALAMINE</option>
                                                            <option value={17}>ACIDE ACETYLSALICYLIQUE / THIAMINE (VITAMINE B1)</option>
                                                            <option value={18}>ACIDE ACEXAMIQUE / NEOMYCINE</option>
                                                            <option value={1119}>ACIDE ALENDRONIQUE</option>
                                                            <option value={19}>ACIDE ALGINIQUE / ALUMINIUM HYDROXYDE</option>
                                                            <option value={20}>ACIDE ASCORBIQUE (VITAMINE C)</option>
                                                            <option value={21}>ACIDE ASCORBIQUE (VITAMINE C) / ALPHATOCOFEROL</option>
                                                            <option value={22}>ACIDE ASCORBIQUE (VITAMINE C) / ASCORBATE DE SODIUM</option>
                                                            <option value={23}>ACIDE ASCORBIQUE (VITAMINE C) / HEMATOPORPHYRINE</option>
                                                            <option value={24}>ACIDE ASCORBIQUE (VITAMINE C) / PYRIDOXINE (VITAMINE B6) / NICOTINAMIDE (VIT PP)</option>
                                                            <option value={25}>ACIDE ASCORBIQUE / ZINC</option>
                                                            <option value={1120}>ACIDE ASCORBIQUE PYRIDOXINE NICOTINAMIDE ASPARTATE DE MAGNESIUM</option>
                                                            <option value={26}>ACIDE BORIQUE / BORATE DE SODIUM</option>
                                                            <option value={27}>ACIDE DIMECROTIQUE</option>
                                                            <option value={28}>ACIDE FOLIQUE / FER</option>
                                                            <option value={29}>ACIDE FUSIDIQUE</option>
                                                            <option value={30}>ACIDE GADOTERIQUE</option>
                                                            <option value={31}>ACIDE IBANDRONIQUE</option>
                                                            <option value={1121}>ACIDE IOXITALAMIQUE SEL DE MEGLUMINE</option>
                                                            <option value={1122}>ACIDE IOXITALAMIQUE SEL DE MEGLUMINE ACIDE IOXITALAMIQUE SEL DE SODIUM</option>
                                                            <option value={32}>ACIDE MEFENAMIQUE</option>
                                                            <option value={33}>ACIDE MYCOPHENOLIQUE</option>
                                                            <option value={1123}>ACIDE N-ACETYL ASPARTYL GLUTAMIQUE SODIQUE</option>
                                                            <option value={34}>ACIDE N-ACETYL ASPARTYLGLUTAMIQUE</option>
                                                            <option value={35}>ACIDE NIFLUMIQUE</option>
                                                            <option value={36}>ACIDE RISEDRONIQUE / CALCIUM / COLECALCIFEROL (VITAMINE D3)</option>
                                                            <option value={37}>ACIDE SALICYLIQUE</option>
                                                            <option value={38}>ACIDE SALICYLIQUE / ACIDE LACTIQUE</option>
                                                            <option value={39}>ACIDE SALICYLIQUE / GLUCONATE DE CHLORHEXIDINE</option>
                                                            <option value={40}>ACIDE TIAPROFENIQUE</option>
                                                            <option value={41}>ACIDE TRANEXAMIQUE</option>
                                                            <option value={42}>ACIDE URIDIQUE / 5-TRIPHOSPHORIQUE</option>
                                                            <option value={43}>ACIDE ZOLEDRONIQUE</option>
                                                            <option value={44}>ACIDES AMINES / HUILES DOLIVE ET SOJA RAFFINES</option>
                                                            <option value={1124}>ACIDES AMINES ELECTROLYTES GLUCOSE EMULSION LIPIDIQUE</option>
                                                            <option value={45}>ACITRETINE</option>
                                                            <option value={46}>ADALIMUMAB</option>
                                                            <option value={47}>ADAPALENE</option>
                                                            <option value={48}>ADAPALENE / PEROXYDE DE BENZOYLE</option>
                                                            <option value={49}>ADEFOVIR</option>
                                                            <option value={50}>ADENOSINE TRIPHOSPHATE</option>
                                                            <option value={51}>ADRENALINE / LIDOCAINE</option>
                                                            <option value={1125}>AFLUZOSINE</option>
                                                            <option value={52}>AGOMELATINE</option>
                                                            <option value={53}>ALBENDAZOLE</option>
                                                            <option value={54}>ALENDRONATE</option>
                                                            <option value={55}>ALENDRONATE / COLECALCIFEROL (VITAMINE D3)</option>
                                                            <option value={56}>ALFA-AMYLASE</option>
                                                            <option value={57}>ALFACALCIDOL</option>
                                                            <option value={58}>ALFENTANIL</option>
                                                            <option value={59}>ALFUZOSINE</option>
                                                            <option value={60}>ALGINATE DE MAGNESIUM / HYDROXYDE D ALUMINIUM</option>
                                                            <option value={61}>ALGINATE DE SODIUM / BICARBONATE DE SODIUM ADULTE</option>
                                                            <option value={62}>ALGINATE DE SODIUM / BICARBONATE DE SODIUM NOURRISSON</option>
                                                            <option value={63}>ALIMEMAZINE</option>
                                                            <option value={64}>ALKYLDIAMINOETHYLGLYCINE</option>
                                                            <option value={65}>ALLOPURINOL</option>
                                                            <option value={66}>ALMINOPROFENE</option>
                                                            <option value={67}>ALMITRINE / RAUBASINE</option>
                                                            <option value={68}>ALOE VERA</option>
                                                            <option value={1126}>ALPHA DU AMYLASE</option>
                                                            <option value={69}>ALPRAZOLAM</option>
                                                            <option value={70}>ALPROSTADIL</option>
                                                            <option value={71}>ALUMINIUM / MAGNESIUM</option>
                                                            <option value={72}>ALUMINIUM HYDROXYDE / DIMETICONE</option>
                                                            <option value={73}>ALVERINE / SIMETHICONE</option>
                                                            <option value={74}>AMBENONIUM</option>
                                                            <option value={75}>AMBROXOL</option>
                                                            <option value={1127}>AMBROXOL CHLORHYDRATE</option>
                                                            <option value={76}>AMIDOTRIZOATE DE MEGLUMINE ET DE SODIUM</option>
                                                            <option value={77}>AMIDOTRIZOATE DE SODIUM / AMIDOTRIZOATE DE MEGLUMINE</option>
                                                            <option value={78}>AMIKACINE</option>
                                                            <option value={79}>AMILORIDE / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={80}>AMIODARONE</option>
                                                            <option value={81}>AMISULPRIDE</option>
                                                            <option value={82}>AMITRIPTYLINE</option>
                                                            <option value={83}>AMLODIPINE</option>
                                                            <option value={84}>AMLODIPINE / ATORVASTATINE</option>
                                                            <option value={85}>AMLODIPINE / VALSARTAN</option>
                                                            <option value={86}>AMLODIPINE / VALSARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={87}>AMOROLFINE</option>
                                                            <option value={88}>AMOXICILLINE</option>
                                                            <option value={89}>AMOXICILLINE / ACIDE CLAVULANIQUE</option>
                                                            <option value={90}>AMOXICILLINE / BROMHEXINE</option>
                                                            <option value={91}>AMOXICILLINE / CLARITHROMYCINE / OMEPRAZOLE</option>
                                                            <option value={92}>AMOXICILLINE / METRONIDAZOLE</option>
                                                            <option value={93}>AMPHOLYTE G</option>
                                                            <option value={94}>AMPHOTERICINE B</option>
                                                            <option value={95}>AMPICILLINE</option>
                                                            <option value={96}>AMPICILLINE / SULBACTAM</option>
                                                            <option value={97}>ANASTROZOLE</option>
                                                            <option value={98}>ANATOXINE TETANIQUE</option>
                                                            <option value={99}>ANATOXINE TETANIQUE / ANATOXINE DIPHTERIQUE</option>
                                                            <option value={1128}>ANTAGONISTES DE L'ANGIOTENSINE II ET DIURETIQUES</option>
                                                            <option value={1129}>ANTIHISTAMINIQUES ( ANTIALLERGIQUE )</option>
                                                            <option value={100}>APRACLONIDINE</option>
                                                            <option value={101}>ARGENT COLLOIDAL</option>
                                                            <option value={102}>ARGININE</option>
                                                            <option value={103}>ARGININE / ACIDE MALIQUE</option>
                                                            <option value={104}>ARGININE / BETAINE</option>
                                                            <option value={1130}>ARGININEL(+) ACIDE MALIQUE</option>
                                                            <option value={105}>ARIPIPRAZOLE</option>
                                                            <option value={106}>ARTICAINE</option>
                                                            <option value={107}>ASCORBATE DE CALCIUM / ASCORBATE DE LYSINE</option>
                                                            <option value={108}>ASPARAGINASE</option>
                                                            <option value={109}>ASPARTATE D ARGININE</option>
                                                            <option value={110}>ASPARTATE DE MAGNESIUM</option>
                                                            <option value={111}>ATENOLOL</option>
                                                            <option value={112}>ATENOLOL / CHLORTALIDONE</option>
                                                            <option value={113}>ATOMOXETINE</option>
                                                            <option value={114}>ATORVASTATINE</option>
                                                            <option value={115}>ATOVAQUONE / CHLORHYDRATE DE PROGUANIL</option>
                                                            <option value={116}>ATRACURIUM</option>
                                                            <option value={117}>ATROPINE</option>
                                                            <option value={118}>ATTAPULGITE DE MORMOIRON ACTIVEE</option>
                                                            <option value={119}>ATTAPULGITE DE MORMOIRON ACTIVEE / HYDROXYDE D ALUMINIUM ET CARBONATE DE MAGNESIUM</option>
                                                            <option value={120}>AZACITIDINE</option>
                                                            <option value={121}>AZATHIOPRINE</option>
                                                            <option value={122}>AZELASTINE</option>
                                                            <option value={123}>AZITHROMYCINE</option>
                                                            <option value={124}>B-ESCINE PURE ANHDYRE</option>
                                                            <option value={125}>BACITRACINE / COLISTIMETHATE</option>
                                                            <option value={126}>BACLOFENE</option>
                                                            <option value={127}>BAICALLINE / SITOSTEROL</option>
                                                            <option value={128}>BAUME DE PEROU / OXYDE DE ZINC</option>
                                                            <option value={129}>BECLOMETASONE</option>
                                                            <option value={130}>BEMIPARINE</option>
                                                            <option value={131}>BENAZEPRIL</option>
                                                            <option value={132}>BENPHOTHIAMINE</option>
                                                            <option value={133}>BENZALKONIUM</option>
                                                            <option value={134}>BENZATHINE BENZYLPENICILLINE</option>
                                                            <option value={135}>BENZOATE DE BENZYLE / SULFIRAME</option>
                                                            <option value={136}>BENZOATE DE SODIUM / GRINDELIA</option>
                                                            <option value={137}>BENZOATE DE SODIUM / TERPINE</option>
                                                            <option value={138}>BENZOCAINE / ESCULOSIDE</option>
                                                            <option value={139}>BENZYLPENICILLINE</option>
                                                            <option value={140}>BETAHISTINE</option>
                                                            <option value={141}>BETAMETHASONE</option>
                                                            <option value={142}>BETAMETHASONE / ACIDE SALICYLIQUE</option>
                                                            <option value={143}>BETAMETHASONE / NEOMYCINE</option>
                                                            <option value={1131}>BETASITOSTEROL</option>
                                                            <option value={144}>BETAXOLOL</option>
                                                            <option value={1132}>BETHAMETHAZONE DIPROPIONATE</option>
                                                            <option value={145}>BEVACIZUMAB</option>
                                                            <option value={146}>BICALUTAMIDE</option>
                                                            <option value={147}>BICARBONATE DE POTASSIUM</option>
                                                            <option value={148}>BICARBONATE DE SODIUM</option>
                                                            <option value={149}>BICARBONATE DE SODIUM / SULFATE DE SODIUM</option>
                                                            <option value={1133}>BICLOTHYMOL EUCALYPTOL PARACETAMOL PHOLCODINE</option>
                                                            <option value={150}>BICLOTYMOL</option>
                                                            <option value={151}>BIFONAZOLE</option>
                                                            <option value={152}>BIFONAZOLE / UREE</option>
                                                            <option value={153}>BIMATOPROST</option>
                                                            <option value={154}>BISACODYL</option>
                                                            <option value={155}>BISACODYL / PHOSPHATE DISODIQUE / PHOSPHATE MONOSODIQUE</option>
                                                            <option value={156}>BISOPROLOL</option>
                                                            <option value={157}>BISOPROLOL / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={158}>BLEOMYCINE</option>
                                                            <option value={159}>BLEU PATENTE</option>
                                                            <option value={160}>BOCEPREVIR</option>
                                                            <option value={161}>BOEHMITE</option>
                                                            <option value={162}>BRIMONIDINE</option>
                                                            <option value={163}>BRINZOLAMIDE</option>
                                                            <option value={164}>BRINZOLAMIDE / TIMOLOL</option>
                                                            <option value={165}>BROMAZEPAM</option>
                                                            <option value={166}>BROMHEXINE</option>
                                                            <option value={167}>BROMHEXINE / SULFOGAICOLE</option>
                                                            <option value={168}>BROMOCRIPTINE</option>
                                                            <option value={169}>BROMOGALACTOGLUCONATE DE CALCIUM</option>
                                                            <option value={1134}>BROMOPRIDE</option>
                                                            <option value={170}>BROMPHENIRAMINE / PHENYLPROPANOLAMINE</option>
                                                            <option value={171}>BROMURE DE BENZODODECINIUM / BUTOXYDE DE PIPERONYLE</option>
                                                            <option value={172}>BROMURE DE CALCIUM / LACTATE DE CALCIUM</option>
                                                            <option value={173}>BUCLIZINE</option>
                                                            <option value={174}>BUDESONIDE</option>
                                                            <option value={175}>BUDESONIDE / FORMOTEROL</option>
                                                            <option value={176}>BUFEXAMAC</option>
                                                            <option value={177}>BUFLOMEDIL</option>
                                                            <option value={178}>BUPIVACAINE</option>
                                                            <option value={179}>BUPRENORPHINE</option>
                                                            <option value={180}>BUPROPION</option>
                                                            <option value={181}>BUTOCONAZOLE</option>
                                                            <option value={182}>BUZEPIDE / CLOCINIZINE</option>
                                                            <option value={183}>CABAZITAXEL</option>
                                                            <option value={184}>CABERGOLINE</option>
                                                            <option value={185}>CALCIPOTRIOL</option>
                                                            <option value={186}>CALCIPOTRIOL / BETAMETHASONE</option>
                                                            <option value={188}>CALCITONINE HUMAINE</option>
                                                            <option value={187}>CALCITONINE SYNTHETIQUE DE SAUMON</option>
                                                            <option value={189}>CALCIUM</option>
                                                            <option value={190}>CALCIUM / COLECALCIFEROL (VITAMINE D3)</option>
                                                            <option value={191}>CALCIUM CARBONATE / COLECALCIFEROL (VITAMINE D3)</option>
                                                            <option value={192}>CALCIUM POLYSTYRENE SULFONATE</option>
                                                            <option value={1135}>CALCIUM, VITAMINE C , MAGNESIUM</option>
                                                            <option value={193}>CAMPHRE OFFICINAL</option>
                                                            <option value={194}>CANDESARTAN</option>
                                                            <option value={195}>CANDESARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={196}>CAPECITABINE</option>
                                                            <option value={197}>CAPSICINE / HISTAMINE / MEPHENESINE</option>
                                                            <option value={198}>CAPTOPRIL</option>
                                                            <option value={199}>CAPTOPRIL / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={200}>CARBAMAZEPINE</option>
                                                            <option value={201}>CARBIMAZOLE</option>
                                                            <option value={202}>CARBOCYSTEINE</option>
                                                            <option value={203}>CARBOCYSTEINE / PROMETHAZINE</option>
                                                            <option value={1136}>CARBOMERE</option>
                                                            <option value={204}>CARBOMERE 474 P</option>
                                                            <option value={205}>CARBOMERE 974 P</option>
                                                            <option value={206}>CARBOMERE 980</option>
                                                            <option value={207}>CARBONATE DE CALCIUM / CARBONATE DE MAGNESIUM</option>
                                                            <option value={208}>CARBONATE DE MAGNESIUM</option>
                                                            <option value={209}>CARBOPLATINE</option>
                                                            <option value={210}>CARMELLOSE</option>
                                                            <option value={211}>CARMUSTINE</option>
                                                            <option value={212}>CARRAGHENATES / DIOXYDE DE TITANE</option>
                                                            <option value={213}>CARTEOLOL</option>
                                                            <option value={214}>CARVEDILOL</option>
                                                            <option value={215}>CATALASE / HEXAMIDINE</option>
                                                            <option value={216}>CATALASE / NEOMYCINE</option>
                                                            <option value={217}>CEFACLOR</option>
                                                            <option value={218}>CEFADROXIL</option>
                                                            <option value={219}>CEFALEXINE</option>
                                                            <option value={220}>CEFALOTINE</option>
                                                            <option value={221}>CEFAZOLINE</option>
                                                            <option value={222}>CEFDITOREN PIVOXIL</option>
                                                            <option value={1211}>CEFEPIME</option>
                                                            <option value={223}>CEFIXIME</option>
                                                            <option value={224}>CEFOTAXIME</option>
                                                            <option value={225}>CEFPODOXIME</option>
                                                            <option value={226}>CEFTAZIDIME</option>
                                                            <option value={227}>CEFTRIAXONE</option>
                                                            <option value={1137}>CEFTRIAXONE SODIQUE</option>
                                                            <option value={228}>CEFUROXIME</option>
                                                            <option value={229}>CELECOXIB</option>
                                                            <option value={230}>CELLULOSE OXYDEE</option>
                                                            <option value={231}>CENTELLA ASIATICA</option>
                                                            <option value={232}>CETIRIZINE</option>
                                                            <option value={233}>CETRIMIDE</option>
                                                            <option value={234}>CETUXIMAB</option>
                                                            <option value={235}>CETYLLPYRIDINIUM / CHLOROBUTANOL HEMYDRATE</option>
                                                            <option value={236}>CHARBON ACTIVE</option>
                                                            <option value={237}>CHARBON ACTIVE / DIMETICONE</option>
                                                            <option value={238}>CHARBON ACTIVE / PAPAVERINE</option>
                                                            <option value={239}>CHARBON VEGETAL</option>
                                                            <option value={1138}>CHARBON VEGETAL EXTRAIT DE RHUBARBE FEUILLES DE SENE SOUFRE</option>
                                                            <option value={240}>CHLORAMPHENICOL</option>
                                                            <option value={241}>CHLORAMPHENICOL / BISMUTH SUCCINATE</option>
                                                            <option value={242}>CHLORHEXIDINE</option>
                                                            <option value={243}>CHLORHEXIDINE / BENZALKONIUM</option>
                                                            <option value={244}>CHLORHEXIDINE / CHLORBUTANOL</option>
                                                            <option value={245}>CHLORHEXIDINE / HEXAMIDINE / CHLOROCRESOL</option>
                                                            <option value={246}>CHLORHEXIDINE / TETRACAINE</option>
                                                            <option value={247}>CHLORHEXIDINE DIGLUCONATE</option>
                                                            <option value={248}>CHLORHYDRATE DE LIDOCAINE / CHLORURE DE BENZALKONIUM</option>
                                                            <option value={249}>CHLORHYDRATE DE NALOXONE ANHYDRE</option>
                                                            <option value={250}>CHLORHYDRATE DE PIVMECILLINAM</option>
                                                            <option value={251}>CHLOROQUINE</option>
                                                            <option value={252}>CHLOROXYDANT / ELECTROLYTIQUE</option>
                                                            <option value={253}>CHLORPHENOXAMINE</option>
                                                            <option value={254}>CHLORPHENOXAMINE / CAFEINE</option>
                                                            <option value={255}>CHLORPHENOXAMINE / SULFOGAICOLE</option>
                                                            <option value={256}>CHLORPROMAZINE</option>
                                                            <option value={257}>CHLORQUINALDOL / PROMESTRIENE</option>
                                                            <option value={258}>CHLORTETRACYCLINE</option>
                                                            <option value={259}>CHLORURE DE BENZALKONIUM</option>
                                                            <option value={260}>CHLORURE DE BENZALKONIUM / DIGLUCONATE DE CHLORHEXIDINE</option>
                                                            <option value={261}>CHLORURE DE POTASSIUM</option>
                                                            <option value={262}>CHLORURE DE SODIUM</option>
                                                            <option value={263}>CHLORURE DE SODIUM / CHLORURE DE POTASSIUM</option>
                                                            <option value={264}>CHLORURE DE SODIUM / CITRATE TRISODIQUE</option>
                                                            <option value={1204}>CHOLECALCIFEROL (vitamine D3)</option>
                                                            <option value={265}>CHONDROITINE</option>
                                                            <option value={266}>CHORIOGONADOTROPINE ALPHA</option>
                                                            <option value={267}>CHRONDROITINE</option>
                                                            <option value={268}>CICLOPIROXOLAMINE</option>
                                                            <option value={269}>CICLOSPORINE</option>
                                                            <option value={270}>CILAZAPRIL</option>
                                                            <option value={271}>CIMETIDINE</option>
                                                            <option value={272}>CINEOLE / CODEINE</option>
                                                            <option value={273}>CIPROFIBRATE</option>
                                                            <option value={274}>CIPROFLOXACINE</option>
                                                            <option value={275}>CISATRACURIUM</option>
                                                            <option value={276}>CISPLATINE</option>
                                                            <option value={277}>CITRULINE MALEATE</option>
                                                            <option value={278}>CLADRIBINE</option>
                                                            <option value={279}>CLARITHROMYCINE</option>
                                                            <option value={280}>CLIDINIUM / CHLORDIAZEPOXIDE</option>
                                                            <option value={281}>CLINDAMYCINE</option>
                                                            <option value={282}>CLOBAZAM</option>
                                                            <option value={283}>CLOBETASOL</option>
                                                            <option value={284}>CLOBETASONE BUTYRATE</option>
                                                            <option value={285}>CLODRONATE DISODIQUE</option>
                                                            <option value={286}>CLOMIFENE</option>
                                                            <option value={287}>CLOMIPRAMINE</option>
                                                            <option value={288}>CLONAZEPAM</option>
                                                            <option value={289}>CLONIDINE</option>
                                                            <option value={290}>CLOPIDOGREL</option>
                                                            <option value={291}>CLORAZEPATE</option>
                                                            <option value={292}>CLOTRIMAZOLE</option>
                                                            <option value={293}>CLOTRIMAZOLE / DEXAMETHASONE</option>
                                                            <option value={294}>CLOZAPINE</option>
                                                            <option value={295}>COAL TAR</option>
                                                            <option value={296}>COAL TAR / ACIDE SALICYLIQUE</option>
                                                            <option value={297}>CODEINE</option>
                                                            <option value={298}>CODEINE / ERYSIMUM</option>
                                                            <option value={1139}>CODEINE CAMPEHOSULFONATE ACIDE ASCOÇRBIQUE EAU DE LAURIER CERISE SIROP D'IPECACUHANA COMPOSE</option>
                                                            <option value={1140}>CODEINE MALTODEXTRINE ERYSIUM</option>
                                                            <option value={299}>COLCHICINE</option>
                                                            <option value={300}>COLISTINE</option>
                                                            <option value={301}>COMPLEXE CHYMOTRYPSINE / PHENYLBUTAZONE</option>
                                                            <option value={302}>COMPLEXE OSSEINE HYDROXYAPATITE</option>
                                                            <option value={303}>CONCENTRE POUR DIALYSE PERITONEALE</option>
                                                            <option value={304}>CONCENTRE POUR HEMODIALYSE</option>
                                                            <option value={305}>CORBONATE MONOSODIQUE / SULFATE DE SODIUM</option>
                                                            <option value={306}>CORTIVAZOL</option>
                                                            <option value={307}>CROMOGLYCATE DE SODIUM</option>
                                                            <option value={308}>CROTAMITON</option>
                                                            <option value={309}>CYCLOPENTOLATE</option>
                                                            <option value={310}>CYCLOPHOSPHAMIDE</option>
                                                            <option value={311}>CYPROHEPTADINE</option>
                                                            <option value={312}>CYPROHEPTADINE / THIAMINE (VITAMINE B1)</option>
                                                            <option value={313}>CYPROHEPTADINE / VITAMINES</option>
                                                            <option value={1141}>CYPROHEPTADINE CHLORHYDRATE</option>
                                                            <option value={314}>CYPROTERONE</option>
                                                            <option value={315}>CYPROTERONE / ETHINYLESTRADIOL</option>
                                                            <option value={316}>CYTARABINE</option>
                                                            <option value={317}>DALTEPARINE</option>
                                                            <option value={318}>DANAZOL</option>
                                                            <option value={319}>DARBEPOETIN ALFA</option>
                                                            <option value={320}>DAUNORUBICINE</option>
                                                            <option value={321}>DEANOL / MAGNESIUM</option>
                                                            <option value={322}>DEFERASIROX</option>
                                                            <option value={323}>DEFERIPRONE</option>
                                                            <option value={324}>DEFEROXAMINE</option>
                                                            <option value={325}>DEFLAZACORT</option>
                                                            <option value={326}>DELAPRIL</option>
                                                            <option value={327}>DELAPRIL / INDAPAMIDE</option>
                                                            <option value={328}>DEPALLETHRINE / BUTOXYDE DE PIPERONYLE</option>
                                                            <option value={329}>DESLORATADINE</option>
                                                            <option value={330}>DESMOPRESSINE</option>
                                                            <option value={331}>DESOGESTREL</option>
                                                            <option value={332}>DESOGESTREL / ETHINYLESTRADIOL</option>
                                                            <option value={333}>DESONIDE</option>
                                                            <option value={334}>DESOXIMETASONE</option>
                                                            <option value={335}>DEXAMETHASONE</option>
                                                            <option value={336}>DEXAMETHASONE / FRAMYCETINE</option>
                                                            <option value={337}>DEXAMETHASONE / GENTAMICINE</option>
                                                            <option value={338}>DEXAMETHASONE / NANDROLONE</option>
                                                            <option value={339}>DEXAMETHASONE / NEOMYCINE</option>
                                                            <option value={340}>DEXAMETHASONE / OXYTETRACYCLINE</option>
                                                            <option value={341}>DEXAMETHASONE / SALICYLAMIDE</option>
                                                            <option value={1142}>DEXAMETHASONE POTASSIUM GENTAMICINE SODIUM SULFATE</option>
                                                            <option value={342}>DEXCHLORPHENIRAMINE</option>
                                                            <option value={343}>DEXPANTHENOL / ALCOOL POLYVINYLIQUE</option>
                                                            <option value={344}>DEXTRAN / CHLORHEXIDINE</option>
                                                            <option value={345}>DEXTROMETHORPHANE</option>
                                                            <option value={346}>DIACERRHEINE</option>
                                                            <option value={347}>DIAZEPAM</option>
                                                            <option value={348}>DICLOFENAC</option>
                                                            <option value={349}>DICLOFENAC / MISOPROSTOL</option>
                                                            <option value={1143}>DICLOFENAC SODIQUE</option>
                                                            <option value={350}>DIDANOSINE</option>
                                                            <option value={351}>DIENOGEST</option>
                                                            <option value={352}>DIGOXINE</option>
                                                            <option value={353}>DIHYDROERGOCRYPTINE</option>
                                                            <option value={1144}>DIHYDROERGOCRYPTINE A DE MESILATE</option>
                                                            <option value={354}>DIHYDROERGOTAMINE</option>
                                                            <option value={355}>DIHYDROERGOTAMINE / RAUBASINE</option>
                                                            <option value={356}>DIHYDROERGOTOXINE</option>
                                                            <option value={357}>DILTIAZEM</option>
                                                            <option value={1145}>DIMEGLUMINE GADOPENTETATE</option>
                                                            <option value={358}>DIMENHYDRINATE</option>
                                                            <option value={359}>DIMETICONE</option>
                                                            <option value={360}>DIOSMECTITE</option>
                                                            <option value={361}>DIOSMINE</option>
                                                            <option value={362}>DIOXYDE DE TITANE / OXYDE DE ZINC</option>
                                                            <option value={363}>DIPHENHYDRAMINE</option>
                                                            <option value={364}>DIPYRIDAMOL</option>
                                                            <option value={365}>DISOPYRAMIDE</option>
                                                            <option value={366}>DOBUTAMINE</option>
                                                            <option value={367}>DOCETAXEL</option>
                                                            <option value={368}>DOMPERIDONE</option>
                                                            <option value={369}>DONEPEZIL</option>
                                                            <option value={370}>DOPAMINE</option>
                                                            <option value={371}>DORZOLAMIDE</option>
                                                            <option value={372}>DORZOLAMIDE / TIMOLOL</option>
                                                            <option value={373}>DOXAZOSINE</option>
                                                            <option value={374}>DOXORUBICINE</option>
                                                            <option value={375}>DOXYCYCLINE</option>
                                                            <option value={376}>DROSPIRENONE / ETHINYLESTRADIOL</option>
                                                            <option value={377}>DROTAVERINE</option>
                                                            <option value={378}>DULOXETINE</option>
                                                            <option value={379}>DUTASTERIDE</option>
                                                            <option value={380}>DYDROGESTERONE</option>
                                                            <option value={381}>EAU POUR PREPARATION INJECTABLE</option>
                                                            <option value={382}>ECONAZOLE</option>
                                                            <option value={383}>EFAVIRENZ</option>
                                                            <option value={384}>ELETRIPTAN</option>
                                                            <option value={385}>ELTROMBOPAG</option>
                                                            <option value={386}>EMEDASTINE</option>
                                                            <option value={387}>ENALAPRIL</option>
                                                            <option value={388}>ENALAPRIL / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={389}>ENOXACINE</option>
                                                            <option value={390}>ENOXAPARINE</option>
                                                            <option value={391}>ENOXOLONE</option>
                                                            <option value={392}>ENTACAPONE / LEVODOPA / CARBIDOPA</option>
                                                            <option value={393}>ENTECAVIR</option>
                                                            <option value={394}>EOSINE</option>
                                                            <option value={395}>EPHEDRINE</option>
                                                            <option value={396}>EPINEPHRINE</option>
                                                            <option value={397}>EPIRUBICINE</option>
                                                            <option value={398}>EPLERENONE</option>
                                                            <option value={399}>EPOETINE ALFA</option>
                                                            <option value={400}>EPOETINE BETA</option>
                                                            <option value={401}>EPTACOG ALPHA ACTIVE ( R FVII)</option>
                                                            <option value={1146}>ERGOCALCIFEROL</option>
                                                            <option value={402}>ERGOCALCIFEROL (VITAMINE D2)</option>
                                                            <option value={403}>ERGOTAMINE / CAFEINE</option>
                                                            <option value={404}>ERLOTINIB</option>
                                                            <option value={405}>ERTAPENEME</option>
                                                            <option value={406}>ERYSIMUM / PHOLCODINE</option>
                                                            <option value={407}>ERYTHROMYCINE</option>
                                                            <option value={408}>ERYTHROMYCINE / SULFAFURAZOLE</option>
                                                            <option value={409}>ERYTHROPOIETINE HUMAINE</option>
                                                            <option value={410}>ESCITALOPRAM</option>
                                                            <option value={411}>ESDEPALLETHRINE / BUTOXYDE DE PIPERONYLE</option>
                                                            <option value={412}>ESOMEPRAZOLE</option>
                                                            <option value={413}>ESTER BENZYLSALICYLIQUE / BIFLUORURE D AMMONIUM</option>
                                                            <option value={414}>ESTERS ETHYLIQUES D ACIDES GRAS IODES DE L HUILE D OEILLETTE</option>
                                                            <option value={415}>ESTERS ETHYLIQUES D ACIDES OMEGA-3 / ALPHA TOCOPHEROL</option>
                                                            <option value={416}>ESTRADIOL</option>
                                                            <option value={417}>ESTRADIOL / CYPROTERONE</option>
                                                            <option value={418}>ESTRADIOL / DYDROGESTERONE</option>
                                                            <option value={419}>ESTRADIOL / MEDROXYPROGESTERONE</option>
                                                            <option value={420}>ESTRADIOL / NORETHISTERONE</option>
                                                            <option value={421}>ESTRADIOL / PROGESTERONE</option>
                                                            <option value={422}>ESTRAMUSTINE</option>
                                                            <option value={1147}>ESTRAMUSTINE PHOSPHATE</option>
                                                            <option value={423}>ESTRIOL</option>
                                                            <option value={424}>ETAMSYLATE</option>
                                                            <option value={425}>ETANERCEPT</option>
                                                            <option value={426}>ETHAMBUTOL</option>
                                                            <option value={427}>ETHINYLESTRADIOL / DROSPIRENONE</option>
                                                            <option value={428}>ETIDRONATE</option>
                                                            <option value={429}>ETIFOXINE</option>
                                                            <option value={430}>ETILEFRINE</option>
                                                            <option value={431}>ETOFENAMATE</option>
                                                            <option value={432}>ETOMIDATE</option>
                                                            <option value={1148}>ETONOGESTREL ETHINYLESTRADIOL</option>
                                                            <option value={433}>ETOPOSIDE</option>
                                                            <option value={434}>ETORICOXIB</option>
                                                            <option value={435}>EVEROLIMUS</option>
                                                            <option value={436}>EXEMESTANE</option>
                                                            <option value={1149}>EXTRAIT ALLERGENIQUE LYOPHILISE</option>
                                                            <option value={437}>EXTRAIT ANTHOCYANOSIDIQUE DE MYRTILLE / ACETATE D ALPHA-TOCOPHEROL</option>
                                                            <option value={438}>EXTRAIT ANTHOCYANOSIDIQUE DE MYRTILLE / CAROTENE</option>
                                                            <option value={439}>EXTRAIT DE GINKGO BILOBA</option>
                                                            <option value={440}>EXTRAIT DE GINKGO BILOBA / HEPTAMINOL</option>
                                                            <option value={441}>EXTRAIT DE GINKGO BILOBA / HEPTAMINOL / TROXERUTINE</option>
                                                            <option value={1150}>EXTRAIT DE LIERRE GRIMPANT</option>
                                                            <option value={442}>EXTRAIT DE RUSCUS ACULEATUS / HESPERIDINE METHYL CHALCONE</option>
                                                            <option value={443}>EXTRAIT FLUIDE DE FUMETTERE</option>
                                                            <option value={444}>EXTRAIT LIPIDOSTEROLIQUE DE SERENOA REPENS</option>
                                                            <option value={445}>EXTRAIT SEC DE RUSCUS ACULEATUS / EXTRAIT FLUIDE DE MELILOT</option>
                                                            <option value={1151}>EXTRAITS D'AUBEPINE PASSIFLORE VALERIANE BALLOTE</option>
                                                            <option value={446}>EZETIMIBE</option>
                                                            <option value={447}>FACTEUR IX</option>
                                                            <option value={448}>FACTEUR VIII</option>
                                                            <option value={449}>FAMOTIDINE</option>
                                                            <option value={450}>FELODIPINE</option>
                                                            <option value={451}>FENOFIBRATE</option>
                                                            <option value={452}>FENOPROFENE</option>
                                                            <option value={453}>FENSPIRIDE</option>
                                                            <option value={454}>FENTANYL</option>
                                                            <option value={455}>FENTICONAZOLE</option>
                                                            <option value={456}>FER</option>
                                                            <option value={457}>FER / MANGANESE</option>
                                                            <option value={458}>FILGRASTIM</option>
                                                            <option value={459}>FINASTERIDE</option>
                                                            <option value={460}>FLAVONOIDES MICRONISES</option>
                                                            <option value={461}>FLAVOXATE</option>
                                                            <option value={462}>FLECAINIDE</option>
                                                            <option value={463}>FLOCTAFENINE</option>
                                                            <option value={464}>FLUCLOXACILLINE</option>
                                                            <option value={465}>FLUCONAZOLE</option>
                                                            <option value={466}>FLUCYTOSINE</option>
                                                            <option value={467}>FLUDARABINE</option>
                                                            <option value={468}>FLUMAZENIL</option>
                                                            <option value={469}>FLUMEQUINE</option>
                                                            <option value={470}>FLUNITRAZEPAM</option>
                                                            <option value={471}>FLUOCINONIDE</option>
                                                            <option value={472}>FLUOCINONIDE / NEOMYCINE</option>
                                                            <option value={473}>FLUOCINONIDE / POLYMYXINE B</option>
                                                            <option value={474}>FLUOCORTOLONE</option>
                                                            <option value={475}>FLUORESCEINE SODIQUE</option>
                                                            <option value={476}>FLUOROMETHOLONE</option>
                                                            <option value={477}>FLUOROURACIL</option>
                                                            <option value={478}>FLUORURE DE SODIUM</option>
                                                            <option value={1152}>FLUOXACILLINE</option>
                                                            <option value={479}>FLUOXETINE</option>
                                                            <option value={480}>FLUPHENAZINE</option>
                                                            <option value={481}>FLUTAMIDE</option>
                                                            <option value={482}>FLUTICASONE</option>
                                                            <option value={483}>FLUTICASONE / SALMETEROL</option>
                                                            <option value={484}>FLUVASTATINE</option>
                                                            <option value={485}>FLUVOXAMINE</option>
                                                            <option value={486}>FOIE DE POISSON / ZINC</option>
                                                            <option value={487}>FOLINATE DE CALCIUM</option>
                                                            <option value={488}>FOLLITROPINE ALFA</option>
                                                            <option value={489}>FOLLITROPINE BETA</option>
                                                            <option value={490}>FONDAPARINUX SODIQUE</option>
                                                            <option value={491}>FORMALDEHYDE / CHLORURE DE SODIUM</option>
                                                            <option value={492}>FORMOCORTAL</option>
                                                            <option value={493}>FORMOCORTAL / GENTAMICINE</option>
                                                            <option value={494}>FORMOTEROL</option>
                                                            <option value={495}>FOSFOMYCINE</option>
                                                            <option value={1153}>FRACTION FLAVONOÏQUE PURIFIEE MICRONISEE</option>
                                                            <option value={496}>FRACTION PHOSPHOLIPIDIQUE</option>
                                                            <option value={497}>FRAMYCETINE</option>
                                                            <option value={1154}>FRAMYCETINE SULFATE DEXAMETHASONE PHOSPHATE SODIQUE</option>
                                                            <option value={498}>FUROATE DE FLUTICASONE</option>
                                                            <option value={499}>FUROSEMIDE</option>
                                                            <option value={500}>FUSAFUNGINE</option>
                                                            <option value={501}>GABAPENTINE</option>
                                                            <option value={1203}>Gadobutrol</option>
                                                            <option value={1155}>GADODIAMIDE</option>
                                                            <option value={502}>GADOPENTETATE DE DIMEGLUMINE / GADOPENTETATE DE MEGLUMINE</option>
                                                            <option value={503}>GANCICLOVIR</option>
                                                            <option value={504}>GANIRELIX</option>
                                                            <option value={505}>GEFITINIB</option>
                                                            <option value={506}>GELATINE FLUIDE MODIFIEE</option>
                                                            <option value={507}>GELSEMIUM / GRINDELIA / PARACETAMOL</option>
                                                            <option value={508}>GEMCITABINE</option>
                                                            <option value={1156}>GEMIFLOXACINE</option>
                                                            <option value={509}>GENTAMICINE</option>
                                                            <option value={510}>GENTAMICINE / DEXAMETHASONE</option>
                                                            <option value={511}>GESTODENE / ETHINYLESTRADIOL</option>
                                                            <option value={1157}>GINKGO BILOBA</option>
                                                            <option value={512}>GLIBENCLAMIDE</option>
                                                            <option value={513}>GLICLAZIDE</option>
                                                            <option value={514}>GLIMEPIRIDE</option>
                                                            <option value={515}>GLIMEPIRIDE / METFORMINE</option>
                                                            <option value={516}>GLIPIZIDE</option>
                                                            <option value={517}>GLIQUIDONE</option>
                                                            <option value={518}>GLUCAGON</option>
                                                            <option value={519}>GLUCONATE DE POTASSIUM</option>
                                                            <option value={1158}>GLUCOSAMINE</option>
                                                            <option value={520}>GLUCOSE</option>
                                                            <option value={521}>GLUCOSE ANHYDRE / CHLORURE DE SODIUM</option>
                                                            <option value={522}>GLYCERINE</option>
                                                            <option value={523}>GLYCOCOLLE</option>
                                                            <option value={1205}>GLYCOPYRRONIUM</option>
                                                            <option value={524}>GOMME DE STERCULIA</option>
                                                            <option value={1159}>GONADOSTIMULINE CHORIONIQUE HUMAINE</option>
                                                            <option value={525}>GONADOTROPHINE CHORIONIQUE HUMAINE (HCG)</option>
                                                            <option value={526}>GOSERELINE</option>
                                                            <option value={527}>GOUDRON / ZINC PYRITHIONE</option>
                                                            <option value={528}>GRISEOFULVINE</option>
                                                            <option value={529}>GRISEOFULVINE / ACIDE SALICYLIQUE</option>
                                                            <option value={530}>GUAIFENESINE / PHOLCODINE</option>
                                                            <option value={531}>GUANFACINE</option>
                                                            <option value={532}>HALOPERIDOL</option>
                                                            <option value={533}>HALOTHANE</option>
                                                            <option value={534}>HEMATOPORPHYRINE / ACIDE ASCORBIQUE (VITAMINE C)</option>
                                                            <option value={535}>HEMICELLULOSE DE PSYLLIUM</option>
                                                            <option value={536}>HEPARINE CALCIQUE</option>
                                                            <option value={537}>HEPARINE SODIQUE</option>
                                                            <option value={538}>HEPTAMINOL</option>
                                                            <option value={539}>HEXAMIDINE</option>
                                                            <option value={1160}>HEXAMIDINE DI-ISETHIONATE</option>
                                                            <option value={540}>HEXETIDINE</option>
                                                            <option value={541}>HEXETIDINE / ACIDE PROPIONIQUE</option>
                                                            <option value={542}>HEXETIDINE / CHOLINE SALICYLATE</option>
                                                            <option value={1161}>HOMEO</option>
                                                            <option value={1162}>HUILE DE FOIE DE POISSON OXYDE DE ZINC</option>
                                                            <option value={1163}>HUILE ESSENTIELLE D'EUCALYPTUS TENOATE DE SODIUM</option>
                                                            <option value={543}>HUILE ESSENTIELLE DEUCALYPTUS / PARACETAMOL</option>
                                                            <option value={544}>HUILE ESSENTIELLE DEUCALYPTUS / TENOATE DE SODIUM</option>
                                                            <option value={545}>HUILE NATURELLE DE CHAIR DE POISSON</option>
                                                            <option value={1164}>HYALURONATE DE SODIUM</option>
                                                            <option value={546}>HYDROCHLOROTHIAZIDE</option>
                                                            <option value={547}>HYDROCORTISONE</option>
                                                            <option value={548}>HYDROCORTISONE / CINCHOCAINE CHLORHYDRATE</option>
                                                            <option value={549}>HYDROGENOPHOSPHATE DE SODIUM DODECAHYDRATE / DIHYDROGENOPHOSPHATE DE SODIUM HYDRATE</option>
                                                            <option value={550}>HYDROSMINE</option>
                                                            <option value={551}>HYDROXOCOBALAMINE</option>
                                                            <option value={552}>HYDROXYCARBAMIDE</option>
                                                            <option value={553}>HYDROXYDE D ALUMINIUM / HYDROXYDE DE MAGNESIUM</option>
                                                            <option value={554}>HYDROXYDE DE MAGNESIUM</option>
                                                            <option value={555}>HYDROXYETHYLCELLULOSE</option>
                                                            <option value={556}>HYDROXYLETHYLAMIDON / CHLORURE DE SODIUM</option>
                                                            <option value={557}>HYDROXYPROGESTERONE</option>
                                                            <option value={558}>HYDROXYZINE</option>
                                                            <option value={559}>HYMECROMONE</option>
                                                            <option value={560}>HYPROMELLOSE</option>
                                                            <option value={561}>IBUPROFENE</option>
                                                            <option value={562}>IBUPROFENE / PSEUDOEPHEDRINE</option>
                                                            <option value={563}>IDARUBICINE</option>
                                                            <option value={564}>IDROCILAMIDE</option>
                                                            <option value={565}>IFOSFAMIDE</option>
                                                            <option value={566}>IMATINIB</option>
                                                            <option value={567}>IMIPENEME / CILASTATINE</option>
                                                            <option value={568}>IMIPRAMINE</option>
                                                            <option value={1165}>IMMUNISATION CONTRE LE VIRUS HERPES ZOSTER</option>
                                                            <option value={569}>IMMUNOGLOBULINE HUMAINE NORMALE</option>
                                                            <option value={570}>IMMUNOGLOBULINE TETANIQUE EQUINE</option>
                                                            <option value={571}>IMMUNOGLOBULINES ANTITETANIQUES</option>
                                                            <option value={572}>IMMUNOGLOBULINES DE LAPIN ANTITHYMOCYTES HUMAINS</option>
                                                            <option value={573}>IMMUNOGLOBULINES HUMAINES ANTI-D</option>
                                                            <option value={574}>INDAPAMIDE</option>
                                                            <option value={575}>INDAPAMIDE / PERINDOPRIL</option>
                                                            <option value={576}>INDINAVIR</option>
                                                            <option value={577}>INDOMETACINE</option>
                                                            <option value={578}>INDOMETACINE / GENTAMICINE</option>
                                                            <option value={579}>INFLIXIMAB</option>
                                                            <option value={580}>INOSINE ACEDOBENE DIMEPRANOL</option>
                                                            <option value={581}>INOSINE MONOPHOSPHATE</option>
                                                            <option value={582}>INOSINE PHOSPHATE DISODIQUE DIHYDRATE</option>
                                                            <option value={583}>INSAPONIFICABLE D AVOCAT ET DE SOJA</option>
                                                            <option value={584}>INSULINE DETEMIR</option>
                                                            <option value={1166}>INSULINE GLARGINE</option>
                                                            <option value={1167}>INSULINE GLULISINE</option>
                                                            <option value={585}>INSULINE HUMAINE</option>
                                                            <option value={586}>INTERFERON ALFA-2A</option>
                                                            <option value={587}>INTERFERON ALFA-2A PEGYLE</option>
                                                            <option value={588}>INTERFERON ALFA-2B</option>
                                                            <option value={589}>INTERFERON ALFA-2B PEGYLE</option>
                                                            <option value={590}>INTERFERON BETA-1A</option>
                                                            <option value={591}>INTERFERON BETA-1B RECOMBINANT</option>
                                                            <option value={592}>IOBITRIDOL</option>
                                                            <option value={593}>IODOFORME</option>
                                                            <option value={1168}>IOHEXOL</option>
                                                            <option value={594}>IOPROMIDE</option>
                                                            <option value={595}>IOXAGLATE DE SODIUM ET DE MEGLUMINE</option>
                                                            <option value={596}>IOXITALAMATE DE MEGLUMINE</option>
                                                            <option value={597}>IOXITALAMATE DE MEGLUMINE / IOXITALAMATE DE SODIUM</option>
                                                            <option value={598}>IOXITALAMATE DE METHYLGLUCAMINE</option>
                                                            <option value={599}>IOXITALAMATE DE SODIUM</option>
                                                            <option value={600}>IPRATROPIUM</option>
                                                            <option value={601}>IRBESARTAN</option>
                                                            <option value={1206}>IRBESARTAN / AMLODIPINE</option>
                                                            <option value={602}>IRBESARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={603}>IRINOTECAN</option>
                                                            <option value={604}>ISOCONAZOLE</option>
                                                            <option value={605}>ISOFLURANE</option>
                                                            <option value={606}>ISOPRENALINE</option>
                                                            <option value={607}>ISOSORBIDE DINITRATE</option>
                                                            <option value={608}>ISOSORBIDE MONONITRATE</option>
                                                            <option value={609}>ISOTHIPENDYL</option>
                                                            <option value={610}>ISOTRETINOINE</option>
                                                            <option value={611}>ISRADIPINE</option>
                                                            <option value={612}>IVABRADINE</option>
                                                            <option value={613}>JOSAMYCINE</option>
                                                            <option value={614}>KANAMYCINE</option>
                                                            <option value={615}>KETOCONAZOLE</option>
                                                            <option value={616}>KETOPROFENE</option>
                                                            <option value={617}>KETOROLAC</option>
                                                            <option value={618}>KETOTIFENE</option>
                                                            <option value={619}>L-CYSTINE / SOUFFRE PRECIPITE</option>
                                                            <option value={620}>LA TUBERCULOSE [BCG]</option>
                                                            <option value={621}>LACIDIPINE</option>
                                                            <option value={622}>LACTATE DE MAGNESIUM / PIDOLATE DE MAGNESIUM</option>
                                                            <option value={623}>LACTOBACILLUS ACIDOPHILLUS</option>
                                                            <option value={624}>LACTULOSE</option>
                                                            <option value={625}>LAMIVUDINE</option>
                                                            <option value={626}>LAMIVUDINE / ZIDOVUDINE</option>
                                                            <option value={627}>LAMOTRIGINE</option>
                                                            <option value={628}>LANREOTIDE</option>
                                                            <option value={629}>LANSOPRAZOLE</option>
                                                            <option value={630}>LAPATINIB</option>
                                                            <option value={631}>LATANOPROST</option>
                                                            <option value={632}>LATANOPROST / TIMOLOL</option>
                                                            <option value={633}>LEFLUNOMIDE</option>
                                                            <option value={634}>LENALIDOMIDE</option>
                                                            <option value={635}>LENOGRASTIM</option>
                                                            <option value={636}>LERCANIDIPINE</option>
                                                            <option value={637}>LETROZOLE</option>
                                                            <option value={638}>LEVETIRACETAM</option>
                                                            <option value={639}>LEVOCABASTINE</option>
                                                            <option value={640}>LEVOCETIRIZINE</option>
                                                            <option value={641}>LEVODOPA / BENSERAZIDE</option>
                                                            <option value={642}>LEVOFLOXACINE</option>
                                                            <option value={1169}>LEVOFOLINATE CALCIUM</option>
                                                            <option value={643}>LEVOMENTHOL / CAMPHRE RACEMIQUE</option>
                                                            <option value={644}>LEVOMEPROMAZINE</option>
                                                            <option value={645}>LEVONORGESTREL</option>
                                                            <option value={646}>LEVONORGESTREL / ETHINYLESTRADIOL</option>
                                                            <option value={647}>LEVOTHYROXINE SODIQUE</option>
                                                            <option value={648}>LIDOCAINE</option>
                                                            <option value={649}>LIDOCAINE / ACIDE SALICYLIQUE</option>
                                                            <option value={650}>LIDOCAINE / CETRIMIDE</option>
                                                            <option value={651}>LIDOCAINE / NAPHAZOLINE</option>
                                                            <option value={652}>LIDOCAINE / PHENYLEPHRINE</option>
                                                            <option value={653}>LIDOCAINE / PRILOCAINE</option>
                                                            <option value={654}>LIDOCAINE PARAFORMALDEHYDE</option>
                                                            <option value={655}>LINCOMYCINE</option>
                                                            <option value={1170}>LIRAGLUTIDE</option>
                                                            <option value={656}>LITHIUM</option>
                                                            <option value={657}>LODOXAMIDE</option>
                                                            <option value={658}>LOPERAMIDE</option>
                                                            <option value={659}>LORATADINE</option>
                                                            <option value={660}>LORATADINE / PSEUDOEPHEDRINE</option>
                                                            <option value={661}>LORAZEPAM</option>
                                                            <option value={662}>LOSARTAN</option>
                                                            <option value={663}>LOSARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={664}>LUTROPINE ALFA</option>
                                                            <option value={665}>LYMECYCLINE</option>
                                                            <option value={666}>LYSOZYME / PAPAINE</option>
                                                            <option value={667}>LYSOZYME / PYRIDOXINE (VITAMINE B6)</option>
                                                            <option value={668}>MACROGOL 3350</option>
                                                            <option value={669}>MACROGOL 4000</option>
                                                            <option value={670}>MAGNESIUM / PYRIDOXINE (VITAMINE B6)</option>
                                                            <option value={1171}>MAGNESIUM ALUMINIUM</option>
                                                            <option value={671}>MAGNESIUM PIDOLATE</option>
                                                            <option value={1172}>MAGNESIUM PYRIDOXINE</option>
                                                            <option value={672}>MAGNESIUM SULFATE / LEVURE CEREVISIAE</option>
                                                            <option value={673}>MANIDIPINE</option>
                                                            <option value={674}>MANNITOL</option>
                                                            <option value={675}>MAPROTILINE</option>
                                                            <option value={676}>MEBENDAZOLE</option>
                                                            <option value={677}>MEBEVERINE</option>
                                                            <option value={678}>MECLOZINE</option>
                                                            <option value={679}>MEDROGESTONE</option>
                                                            <option value={680}>MEDROXYPROGESTERONE</option>
                                                            <option value={681}>MELANGE DE GOUDRON</option>
                                                            <option value={682}>MELOXICAM</option>
                                                            <option value={683}>MELPHALAN</option>
                                                            <option value={684}>MENOTROPINE</option>
                                                            <option value={685}>MEPIVACAINE</option>
                                                            <option value={686}>MEPROBAMATE</option>
                                                            <option value={687}>MEQUINOL</option>
                                                            <option value={688}>MEQUITAZINE</option>
                                                            <option value={689}>MERCAPTOPURINE</option>
                                                            <option value={690}>MESALAZINE</option>
                                                            <option value={691}>MESNA</option>
                                                            <option value={1173}>MESO INOSITOL BICARBONATE DE SODIUM PHOSPHATE DISODIQUE SULFATE DE SODIUM</option>
                                                            <option value={692}>MESTEROLONE</option>
                                                            <option value={693}>MESYLATE DE GEMIFLOXACINE</option>
                                                            <option value={694}>METACYCLINE</option>
                                                            <option value={695}>METAMIZOLE</option>
                                                            <option value={696}>METFORMINE</option>
                                                            <option value={697}>METFORMINE / GLIBENCLAMIDE</option>
                                                            <option value={698}>METHOTREXATE</option>
                                                            <option value={699}>METHOXSALENE</option>
                                                            <option value={700}>METHYLDOPA</option>
                                                            <option value={701}>METHYLERGOMETRINE</option>
                                                            <option value={702}>METHYLPOLYSILOXANE</option>
                                                            <option value={703}>METHYLPREDNISOLONE</option>
                                                            <option value={704}>METHYLTHIONINIUM HYDROXYDE / NAPHAZOLINE</option>
                                                            <option value={705}>METOCLOPRAMIDE</option>
                                                            <option value={706}>METOPIMAZINE</option>
                                                            <option value={707}>METOPROLOL</option>
                                                            <option value={708}>METRONIDAZOLE</option>
                                                            <option value={709}>MIANSERINE</option>
                                                            <option value={710}>MICONAZOLE</option>
                                                            <option value={711}>MIDAZOLAM</option>
                                                            <option value={712}>MILNACIPRAN</option>
                                                            <option value={713}>MINOCYCLINE</option>
                                                            <option value={714}>MINOXIDIL</option>
                                                            <option value={715}>MIOCAMYCINE</option>
                                                            <option value={716}>MIRTAZAPINE</option>
                                                            <option value={717}>MITOXANTRONE</option>
                                                            <option value={718}>MIVACURIUM</option>
                                                            <option value={719}>MOLSIDOMINE</option>
                                                            <option value={720}>MOMETASONE</option>
                                                            <option value={721}>MONOFLUOROPHOSPHATE DE SODIUM</option>
                                                            <option value={722}>MONTELUKAST</option>
                                                            <option value={723}>MONTMORILLONITE BEIDELLITIQUE</option>
                                                            <option value={724}>MORPHINE</option>
                                                            <option value={725}>MOXIFLOXACINE</option>
                                                            <option value={726}>MULTIPLE</option>
                                                            <option value={727}>MULTIVITAMINES ET MINERAUX</option>
                                                            <option value={728}>MUPIROCINE</option>
                                                            <option value={729}>MYCOPHENOLATE MOFETIL</option>
                                                            <option value={730}>N-ACETYLCYSTEINE</option>
                                                            <option value={731}>N-BUTYL HYOSCINE</option>
                                                            <option value={732}>NADROPARINE</option>
                                                            <option value={733}>NAFTIDROFURYL</option>
                                                            <option value={734}>NAFTIFINE</option>
                                                            <option value={735}>NANDROLONE</option>
                                                            <option value={736}>NAPROXENE</option>
                                                            <option value={737}>NATALIZUMAB</option>
                                                            <option value={738}>NATAMYCINE / NEOMYCINE</option>
                                                            <option value={739}>NEBIVOLOL</option>
                                                            <option value={740}>NEFOPAM</option>
                                                            <option value={741}>NELFINAVIR</option>
                                                            <option value={742}>NEOMYCINE / DEXAMETHASONE</option>
                                                            <option value={743}>NEOMYCINE / HYDROCORTISONE</option>
                                                            <option value={744}>NEOMYCINE / NYSTATINE</option>
                                                            <option value={745}>NEOMYCINE / POLYMYXINE B</option>
                                                            <option value={746}>NEOSTIGMINE</option>
                                                            <option value={747}>NETILMICINE</option>
                                                            <option value={748}>NEVIRAPINE</option>
                                                            <option value={749}>NIAOULI / GRINDELIA / GELSEMIUM</option>
                                                            <option value={750}>NIAOULI / THYM</option>
                                                            <option value={751}>NICARDIPINE</option>
                                                            <option value={752}>NICLOSAMIDE</option>
                                                            <option value={753}>NICOTINE</option>
                                                            <option value={754}>NIFEDIPINE</option>
                                                            <option value={755}>NIFEDIPINE / ATENOLOL</option>
                                                            <option value={756}>NIFUROXAZIDE</option>
                                                            <option value={757}>NILOTINIB</option>
                                                            <option value={758}>NIMESULIDE</option>
                                                            <option value={759}>NIMODIPINE</option>
                                                            <option value={760}>NITRENDIPINE</option>
                                                            <option value={761}>NITROFURANTOINE</option>
                                                            <option value={762}>NITROXOLINE</option>
                                                            <option value={763}>NOMEGESTROL</option>
                                                            <option value={764}>NORADRENALINE</option>
                                                            <option value={765}>NORDAZEPAM</option>
                                                            <option value={766}>NORETHISTERONE</option>
                                                            <option value={767}>NORETHISTERONE / ETHINYLESTRADIOL</option>
                                                            <option value={768}>NORFLOXACINE</option>
                                                            <option value={769}>NORGESTREL / ETHINYLESTRADIOL</option>
                                                            <option value={770}>NYSTATINE</option>
                                                            <option value={771}>OCTOCOG ALPHA (FVIII RECOMBINANT)</option>
                                                            <option value={772}>OCTREOTIDE</option>
                                                            <option value={773}>OESTRADIOL</option>
                                                            <option value={774}>OFLOXACINE</option>
                                                            <option value={775}>OLANZAPINE</option>
                                                            <option value={776}>OLAPATADINE</option>
                                                            <option value={777}>OLMESARTAN</option>
                                                            <option value={778}>OLMESARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={1174}>OLOPATADINE</option>
                                                            <option value={779}>OMEPRAZOLE</option>
                                                            <option value={780}>OMOCONAZOLE</option>
                                                            <option value={781}>ONDANSETRON</option>
                                                            <option value={782}>ORLISTAT</option>
                                                            <option value={783}>ORNIDAZOLE</option>
                                                            <option value={784}>OSELTAMIVIR</option>
                                                            <option value={785}>OXACILLINE</option>
                                                            <option value={786}>OXALIPLATINE</option>
                                                            <option value={787}>OXCARBAZEPINE</option>
                                                            <option value={788}>OXELADINE</option>
                                                            <option value={789}>OXETACAINE / OXYDE DE MAGNESIUM / OXYDE D ALUMINIUM</option>
                                                            <option value={790}>OXOMEMAZINE / GUAIFENESINE</option>
                                                            <option value={791}>OXYBUPROCAINE</option>
                                                            <option value={792}>OXYBUTININE</option>
                                                            <option value={793}>OXYDE DE ZINC / BENZOATE DE CHOLESTEROL</option>
                                                            <option value={794}>OXYTETRACYCLINE</option>
                                                            <option value={795}>OXYTETRACYCLINE / POLYMYXINE B / NYSTATINE / DEXAMETHASONE</option>
                                                            <option value={796}>OXYTOCINE</option>
                                                            <option value={797}>PACLITAXEL</option>
                                                            <option value={798}>PANCURONIUM</option>
                                                            <option value={799}>PANTOPRAZOLE</option>
                                                            <option value={800}>PARACETAMOL</option>
                                                            <option value={801}>PARACETAMOL / ACIDE ACETYLSALICYLIQUE</option>
                                                            <option value={802}>PARACETAMOL / ACIDE ASCORBIQUE (VITAMINE C)</option>
                                                            <option value={803}>PARACETAMOL / CAFEINE</option>
                                                            <option value={804}>PARACETAMOL / CHLORPHENAMINE</option>
                                                            <option value={805}>PARACETAMOL / CODEINE</option>
                                                            <option value={806}>PARACETAMOL / NIAOULI / GRINDELIA / GELSEMIUM</option>
                                                            <option value={807}>PARACETAMOL / PHENIRAMINE MALEATE / PHENYLEPHRINE CHLORHYDRATE / ACIDE ASCORBIQUE</option>
                                                            <option value={808}>PARACETAMOL / PROMETHAZINE</option>
                                                            <option value={809}>PARACETAMOL / PROPYPHENAZONE</option>
                                                            <option value={810}>PARACETAMOL / PSEUDOEPHEDRINE</option>
                                                            <option value={811}>PARACETAMOL / THIOCOLCHICOSIDE</option>
                                                            <option value={812}>PARACETAMOL / TRIPROLIDINE</option>
                                                            <option value={1175}>PARACETAMOL CODEINE CAFEINE</option>
                                                            <option value={1176}>PARACETAMOL TENOATE DE SODIUM HUILE ESSENTIEL</option>
                                                            <option value={1177}>PARACETAMOL, ACIDE ASCORBIQUE, PHENIRAMINE ET PHENYLEPHRINE</option>
                                                            <option value={1178}>PARACETAMOL, PHENIRAMINE ET ACIDE ASCORBIQUE</option>
                                                            <option value={813}>PARACHLOROMETACRESOL / GAIAZULENE</option>
                                                            <option value={814}>PARAFFINE LIQUIDE</option>
                                                            <option value={815}>PARECOXIB</option>
                                                            <option value={816}>PAROXETINE</option>
                                                            <option value={817}>PAZOPANIB</option>
                                                            <option value={818}>PEMETREXED</option>
                                                            <option value={1179}>PEMETREXED DISODIQUE HEPTAHYDRATE</option>
                                                            <option value={819}>PENTOXIFYLLINE</option>
                                                            <option value={820}>PERBORATE DE SODIUM / BORAX</option>
                                                            <option value={821}>PERINDOPRIL</option>
                                                            <option value={822}>PERINDOPRIL / AMLODIPINE</option>
                                                            <option value={823}>PERINDOPRIL / INDAPAMIDE</option>
                                                            <option value={824}>PEROXYDE DE BENZOYLE</option>
                                                            <option value={825}>PHENAZONE / LIDOCAINE</option>
                                                            <option value={826}>PHENIRAMINE MALEATE / PARACETAMOL / ACIDE ASCORBIQUE</option>
                                                            <option value={827}>PHENOBARBITAL</option>
                                                            <option value={828}>PHENOXYMETHYLPENICILLINE</option>
                                                            <option value={829}>PHENOXYMETHYLPENICILLINE BENZATHINE</option>
                                                            <option value={830}>PHENYLBUTAZONE</option>
                                                            <option value={831}>PHLOROGLUCINOL</option>
                                                            <option value={832}>PHLOROGLUCINOL / TRIMETHYLPHLOROGLUCINOL</option>
                                                            <option value={833}>PHOLCODINE / TENOATE DE SODIUM</option>
                                                            <option value={834}>PHOSPHATE D ALUMINIUM</option>
                                                            <option value={835}>PHOSPHATE DISODIQUE / PHOSPHATE MONOSODIQUE</option>
                                                            <option value={836}>PHOSPHATE MONOSODIQUE / PHOSPHATE DISODIQUE</option>
                                                            <option value={837}>PHYTOMENADIONE</option>
                                                            <option value={838}>PIDOLATE DE MAGNESIUM</option>
                                                            <option value={839}>PILOCARPINE</option>
                                                            <option value={840}>PIMETIXENE</option>
                                                            <option value={841}>PINAVERIUM</option>
                                                            <option value={842}>PINDOLOL</option>
                                                            <option value={843}>PIPERACILLINE</option>
                                                            <option value={844}>PIPERACILLINE / TAZOBACTAM</option>
                                                            <option value={845}>PIPOTIAZINE</option>
                                                            <option value={846}>PIRACETAM</option>
                                                            <option value={847}>PIRBUTEROL</option>
                                                            <option value={848}>PIRIBEDIL</option>
                                                            <option value={849}>PIROXICAM</option>
                                                            <option value={850}>PIROXICAM BETA CYCLODEXTRINE</option>
                                                            <option value={851}>PIZOTIFENE</option>
                                                            <option value={852}>PODOPHYLLOTOXINE</option>
                                                            <option value={853}>POLYMYXINE B / NEOMYCINE</option>
                                                            <option value={854}>POLYMYXINE B / TRIMETHOPRIME</option>
                                                            <option value={855}>POLYOSIDES PNEUMOCOCCIQUES</option>
                                                            <option value={856}>POLYSTYRENE SULFONATE DE SODIUM</option>
                                                            <option value={857}>POLYVIDONE IODEE</option>
                                                            <option value={1180}>POTASSIUM</option>
                                                            <option value={858}>POVIDONE</option>
                                                            <option value={859}>POVIDONE IODEE</option>
                                                            <option value={860}>PRAMIPEXOLE</option>
                                                            <option value={861}>PRANOPROFENE</option>
                                                            <option value={862}>PRAVASTATINE</option>
                                                            <option value={863}>PRAZEPAM</option>
                                                            <option value={864}>PREDNISOLONE</option>
                                                            <option value={865}>PREDNISONE</option>
                                                            <option value={866}>PREDNISONE / ACIDE ASCORBIQUE (VITAMINE C) / CHLORURE DE POTASSIUM</option>
                                                            <option value={867}>PREGABALIN</option>
                                                            <option value={868}>PRIFINIUM</option>
                                                            <option value={869}>PRISTINAMYCINE</option>
                                                            <option value={870}>PROCAINE</option>
                                                            <option value={871}>PROGESTERONE</option>
                                                            <option value={872}>PROGUANIL / CHLOROQUINE</option>
                                                            <option value={873}>PROMEGESTONE</option>
                                                            <option value={874}>PROMESTRIENE</option>
                                                            <option value={875}>PROMETHAZINE</option>
                                                            <option value={876}>PROPERICIAZINE</option>
                                                            <option value={877}>PROPIONATE DE CLOBETASOL</option>
                                                            <option value={878}>PROPOFOL</option>
                                                            <option value={879}>PROPRANOLOL</option>
                                                            <option value={880}>PROPYPHENAZONE / CODEINE</option>
                                                            <option value={881}>PROTAMINE</option>
                                                            <option value={882}>PROTEINE L1 DE PAPILLOMAVIRUS HUMAIN DE TYPE 16 ET 18</option>
                                                            <option value={883}>PROTEINES DE LACTOSERUM</option>
                                                            <option value={884}>PROZAPINE</option>
                                                            <option value={885}>PYGEUM AFRICANUM</option>
                                                            <option value={886}>PYRANTEL</option>
                                                            <option value={887}>PYRAZINAMIDE</option>
                                                            <option value={888}>PYRIDOSTIGMINE</option>
                                                            <option value={889}>PYRIDOXINE (VITAMINE B6) / ACIDE L GLUTAMIQUE</option>
                                                            <option value={890}>PYRIDOXINE (VITAMINE B6) / THIAMINE (VITAMINE B1)</option>
                                                            <option value={1181}>PYRIDOXINE CHLORHYDRATE</option>
                                                            <option value={1182}>PYRITHIONE</option>
                                                            <option value={1183}>QUETIAPINE</option>
                                                            <option value={891}>QUINAPRIL</option>
                                                            <option value={892}>QUINAPRIL / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={893}>RALTEGRAVIR</option>
                                                            <option value={894}>RALTITREXED</option>
                                                            <option value={895}>RAMIPRIL</option>
                                                            <option value={896}>RAMIPRIL / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={897}>RANELATE DE STRONTIUM</option>
                                                            <option value={898}>RANIBIZUMAB</option>
                                                            <option value={899}>RANITIDINE</option>
                                                            <option value={900}>REMIFENTANIL</option>
                                                            <option value={901}>REPAGLINIDE</option>
                                                            <option value={1184}>RH (D)</option>
                                                            <option value={902}>RHUBARBE EXTRAIT SEC / ACIDE SALICYLIQUE</option>
                                                            <option value={903}>RIBAVIRINE</option>
                                                            <option value={904}>RIFAMPICINE</option>
                                                            <option value={905}>RIFAMPICINE / ISONIAZIDE</option>
                                                            <option value={906}>RIFAMYCINE</option>
                                                            <option value={907}>RIFAMYCINE / PREDNISOLONE</option>
                                                            <option value={908}>RILMENIDINE</option>
                                                            <option value={909}>RILUZOLE</option>
                                                            <option value={910}>RIMEXOLONE</option>
                                                            <option value={911}>RISEDRONATE</option>
                                                            <option value={1185}>RISEDRONATE MONOSODIQUE</option>
                                                            <option value={912}>RISPERIDONE</option>
                                                            <option value={913}>RITUXIMAB</option>
                                                            <option value={914}>RIVAROXABAN</option>
                                                            <option value={915}>RIVASTIGMINE</option>
                                                            <option value={916}>ROCURONIUM</option>
                                                            <option value={917}>ROPINIROLE</option>
                                                            <option value={1186}>ROSIGLITAZONE MALEATE</option>
                                                            <option value={918}>ROSUVASTATINE</option>
                                                            <option value={919}>ROTAVIRUS HUMAIN, SOUCHE RIX4414</option>
                                                            <option value={920}>ROXITHROMYCINE</option>
                                                            <option value={921}>SACCHAROMYCES BOULARDII</option>
                                                            <option value={922}>SALBUTAMOL</option>
                                                            <option value={923}>SALCATONINE</option>
                                                            <option value={924}>SALISYLATE DE CHOLINE / CHLORURE DE CETALKONIUM</option>
                                                            <option value={925}>SALISYLATE DE PHENYLPROPYL / METHOXYSALICYLATE DETHYLE</option>
                                                            <option value={926}>SALMETEROL</option>
                                                            <option value={927}>SALMETEROL / FLUTICASONE</option>
                                                            <option value={928}>SAQUINAVIR</option>
                                                            <option value={929}>SECNIDAZOLE</option>
                                                            <option value={930}>SENE / CASCARA</option>
                                                            <option value={931}>SENOSIDES CALCIQUES</option>
                                                            <option value={1187}>SERENOA REPENS (EXTRAIT LIPIDOSTEROLIQUE DE)</option>
                                                            <option value={932}>SERRAPEPTASE</option>
                                                            <option value={933}>SERTACONAZOLE</option>
                                                            <option value={934}>SERTRALINE</option>
                                                            <option value={935}>SEVOFLURANE</option>
                                                            <option value={936}>SILDENAFIL</option>
                                                            <option value={937}>SIMVASTATINE</option>
                                                            <option value={938}>SIMVASTATINE / EZETIMIBE</option>
                                                            <option value={939}>SITAGLIPTINE</option>
                                                            <option value={940}>SITAGLIPTINE / METFORMINE</option>
                                                            <option value={941}>SODIUM POLYSTYRENE SULFONATE</option>
                                                            <option value={1202}>SOFOSBUVIR</option>
                                                            <option value={942}>SOMATOSTATINE</option>
                                                            <option value={943}>SOMATROPINE</option>
                                                            <option value={944}>SORAFENIB</option>
                                                            <option value={945}>SORBITOL / CITRATE DE SODIUM</option>
                                                            <option value={946}>SORBITOL / PHOSPHATE DISODIQUE</option>
                                                            <option value={947}>SOTALOL</option>
                                                            <option value={948}>SOUCHE HOMEOPATHIQUE</option>
                                                            <option value={949}>SPECTINOMYCINE</option>
                                                            <option value={950}>SPIRAMYCINE</option>
                                                            <option value={951}>SPIRAMYCINE / METRONIDAZOLE</option>
                                                            <option value={952}>SPIRONOLACTONE</option>
                                                            <option value={953}>SPIRONOLACTONE / ALTIZIDE</option>
                                                            <option value={1188}>SPORES DE BACILLUS CLAUSII</option>
                                                            <option value={954}>STAVUDINE</option>
                                                            <option value={955}>STAVUDINE / LAMIVUDINE</option>
                                                            <option value={956}>STREPTOKINASE</option>
                                                            <option value={957}>STREPTOMYCINE</option>
                                                            <option value={958}>SUCRALFATE</option>
                                                            <option value={959}>SUFENTANIL</option>
                                                            <option value={960}>SULBUTIAMINE</option>
                                                            <option value={961}>SULCONAZOLE</option>
                                                            <option value={962}>SULFACETAMIDE / TETRAMETHYLTHIONINE</option>
                                                            <option value={963}>SULFADIAZINE</option>
                                                            <option value={964}>SULFAMETHOXAZOLE / TRIMETHOPRIME</option>
                                                            <option value={965}>SULFAMETHOXAZOLE / TRIMETHOPRIME / BROMHEXINE</option>
                                                            <option value={966}>SULFASALAZINE</option>
                                                            <option value={967}>SULFATE DE BARYUM</option>
                                                            <option value={968}>SULFATE DE CUIVRE / SULFATE DE ZINC</option>
                                                            <option value={969}>SULFATE DE NEOMYCINE / SULFATE DE POLYMYXINE B / NYSTATINE</option>
                                                            <option value={970}>SULFATE DE ZINC</option>
                                                            <option value={971}>SULFATE FERREUX / ACIDE ASCORBIQUE (VITAMINE C)</option>
                                                            <option value={972}>SULFATE FERREUX / ACIDE FOLIQUE</option>
                                                            <option value={973}>SULFURE DE SELENIUM</option>
                                                            <option value={974}>SULPIRIDE</option>
                                                            <option value={975}>SULTAMICILLINE</option>
                                                            <option value={976}>SUMATRIPTAN</option>
                                                            <option value={977}>SUNITINIB</option>
                                                            <option value={978}>SYNEPHRINE</option>
                                                            <option value={979}>SYNEPHRINE / DIGLUCONATE DE CHLORHEXIDINE</option>
                                                            <option value={980}>TACROLIMUS</option>
                                                            <option value={981}>TADALAFIL</option>
                                                            <option value={1189}>TAFLUPROST</option>
                                                            <option value={982}>TAMOXIFENE</option>
                                                            <option value={983}>TAMSULOSINE</option>
                                                            <option value={1190}>TANIN HAMAMELIS PHENAZOLE</option>
                                                            <option value={984}>TAZAROTENE</option>
                                                            <option value={985}>TEGAFUR / URACILE</option>
                                                            <option value={986}>TEGASEROD</option>
                                                            <option value={987}>TEGUMENT MUCILAGINEUX DE GRAINE DE PSYLIUM / PARAFFINE LIQUIDE</option>
                                                            <option value={988}>TEGUMENTS DISPAGHULL</option>
                                                            <option value={989}>TEICOPLANINE</option>
                                                            <option value={990}>TEINTURE DE KOLA / ACIDE PHOSPHORIQUE</option>
                                                            <option value={991}>TELBIVUDINE</option>
                                                            <option value={992}>TELMISARTAN</option>
                                                            <option value={993}>TELMISARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={994}>TEMOZOLOMIDE</option>
                                                            <option value={995}>TENECTEPLASE</option>
                                                            <option value={996}>TENONITROZOLE</option>
                                                            <option value={997}>TENOXICAM</option>
                                                            <option value={1191}>TERAZOCINE</option>
                                                            <option value={1192}>TERBIFINATE CHLORHYDRATE</option>
                                                            <option value={998}>TERBINAFINE</option>
                                                            <option value={999}>TERBUTALINE</option>
                                                            <option value={1000}>TERPINE</option>
                                                            <option value={1001}>TESTOSTERONE</option>
                                                            <option value={1002}>TESTOSTERONE / TESTOSTERONE</option>
                                                            <option value={1003}>TETRACOSACTIDE</option>
                                                            <option value={1004}>TETRACYCLINE</option>
                                                            <option value={1005}>TETRACYCLINE / BROMHEXINE</option>
                                                            <option value={1006}>TETRAFLUOROETHANE</option>
                                                            <option value={1007}>TETRAHYDROZOLINE</option>
                                                            <option value={1008}>THALIDOMIDE</option>
                                                            <option value={1009}>THEOPHYLLINE</option>
                                                            <option value={1010}>THIAMINE (VITAMINE B1) / PYRIDOXINE (VITAMINE B6)</option>
                                                            <option value={1011}>THIAMPHENICOL</option>
                                                            <option value={1012}>THIOCOLCHICOSIDE</option>
                                                            <option value={1013}>THIOPENTAL</option>
                                                            <option value={1014}>THIOPHENE CARBOXYLATE DE SODIUM</option>
                                                            <option value={1015}>THIORIDAZINE</option>
                                                            <option value={1016}>THIOTEPA</option>
                                                            <option value={1017}>TIANEPTINE</option>
                                                            <option value={1018}>TIBOLONE</option>
                                                            <option value={1212}>TICAGRELOR</option>
                                                            <option value={1019}>TICLOPIDINE</option>
                                                            <option value={1020}>TIEMONIUM</option>
                                                            <option value={1021}>TIEMONIUM IODURE / DIPROPHYLLINE</option>
                                                            <option value={1022}>TILIQUINOL / TILBROQUINOL</option>
                                                            <option value={1023}>TIMOLOL</option>
                                                            <option value={1024}>TINIDAZOLE</option>
                                                            <option value={1025}>TINZAPARINE</option>
                                                            <option value={1026}>TIOCONAZOLE</option>
                                                            <option value={1027}>TIOTROPIUM</option>
                                                            <option value={1028}>TIROFIBAN</option>
                                                            <option value={1029}>TIXOCORTOL</option>
                                                            <option value={1030}>TIZANIDINE</option>
                                                            <option value={1031}>TOBRAMYCINE</option>
                                                            <option value={1032}>TOBRAMYCINE / DEXAMETHASONE</option>
                                                            <option value={1033}>TOCILIZUMAB</option>
                                                            <option value={1213}>TOLPÉRISONE</option>
                                                            <option value={1193}>TOLTERODINE</option>
                                                            <option value={1034}>TOPIRAMATE</option>
                                                            <option value={1035}>TOXINE BOTULINIQUE TYPE A</option>
                                                            <option value={1036}>TRAMADOL</option>
                                                            <option value={1037}>TRAMADOL / PARACETAMOL</option>
                                                            <option value={1038}>TRANDOLAPRIL</option>
                                                            <option value={1039}>TRASTUZUMAB</option>
                                                            <option value={1040}>TRAVOPROST</option>
                                                            <option value={1041}>TRAVOPROST / TIMOLOL</option>
                                                            <option value={1042}>TRETINOINE</option>
                                                            <option value={1043}>TRETINOINE / ERYTHROMYCINE</option>
                                                            <option value={1044}>TRIAMCINOLONE</option>
                                                            <option value={1045}>TRIAMCINOLONE / CHLORURE DE BENZALKONIUM</option>
                                                            <option value={1046}>TRIAMCINOLONE / NEOMYCINE</option>
                                                            <option value={1047}>TRIAMTERENE / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={1048}>TRIBENOSIDE / LIDOCAINE</option>
                                                            <option value={1049}>TRICLOCARBAN</option>
                                                            <option value={1050}>TRIFLUSAL</option>
                                                            <option value={1051}>TRIHEXYPHENIDYLE</option>
                                                            <option value={1052}>TRIMEBUTINE</option>
                                                            <option value={1053}>TRIMEBUTINE / RUSCOGENINE</option>
                                                            <option value={1054}>TRIMETAZIDINE</option>
                                                            <option value={1055}>TRIMETHYLPHLOROGLUCINOL / PHLOROGLUCINOL</option>
                                                            <option value={1056}>TRIMIPRAMINE</option>
                                                            <option value={1057}>TRINITRINE</option>
                                                            <option value={1058}>TRIPROLIDINE / DEXTROMETHORPHANE</option>
                                                            <option value={1059}>TRIPROLIDINE / PSEUDOEPHEDRINE</option>
                                                            <option value={1060}>TRIPROLIDINE / PSEUDOEPHEDRINE / PARACETAMOL</option>
                                                            <option value={1061}>TRIPTORELINE</option>
                                                            <option value={1062}>TROLAMINE</option>
                                                            <option value={1063}>TROPICAMIDE</option>
                                                            <option value={1064}>TROXERUTINE</option>
                                                            <option value={1065}>TUBERCULINE</option>
                                                            <option value={1194}>ULIPRISTAL</option>
                                                            <option value={1066}>UREE</option>
                                                            <option value={1067}>UROFOLLITROPINE</option>
                                                            <option value={1068}>VACCIN ANTI-HAEMOPHILUS INFLUENZAE TYPE B</option>
                                                            <option value={1069}>VACCIN ANTI-HEPATITE A</option>
                                                            <option value={1070}>VACCIN ANTI-HEPATITE A ET B</option>
                                                            <option value={1071}>VACCIN ANTI-HEPATITE B</option>
                                                            <option value={1072}>VACCIN ANTI-RUBEOLE</option>
                                                            <option value={1073}>VACCIN ANTI-STREPTOCOCCUS PNEUMONIAE</option>
                                                            <option value={1074}>VACCIN ANTI-TYPHOIDIQUE</option>
                                                            <option value={1075}>VACCIN ANTI-VARICELLE</option>
                                                            <option value={1076}>VACCIN ANTIGRIPPAL</option>
                                                            <option value={1077}>VACCIN ANTIMENINGOCOCCIQUE A ET C</option>
                                                            <option value={1078}>VACCIN ANTIMENINGOCOCCIQUE A, C,W135 ET Y</option>
                                                            <option value={1079}>VACCIN ANTIRABIQUE</option>
                                                            <option value={1080}>VACCIN ANTIROUGEOLEUX</option>
                                                            <option value={1195}>VACCIN CONTRE D, T, COQUELUCHE (PERTUSSIS), HEPATITE B, POLIOMYELITE ET HI B</option>
                                                            <option value={1196}>VACCIN CONTRE LES IGE A ROTAVIRUS</option>
                                                            <option value={1081}>VACCIN D.T</option>
                                                            <option value={1082}>VACCIN D.T.COQ.HEPATITE B</option>
                                                            <option value={1083}>VACCIN D.T.COQ.P</option>
                                                            <option value={1084}>VACCIN D.T.COQ.P.HIB</option>
                                                            <option value={1085}>VACCIN D.T.COQ.P.HIB.HEPATITE B</option>
                                                            <option value={1086}>VACCIN D.T.P</option>
                                                            <option value={1197}>VACCIN INACTIVE DE L'HEPATHITE A ADSORBE (VACCIN PEDIATRIQUE)</option>
                                                            <option value={1198}>VACCIN PAPILLOMAVIRUS HUMAIN [TYPES 16, 18]</option>
                                                            <option value={1199}>VACCIN PAPILLOMAVIRUS HUMAIN [TYPES 16, 18] (RECOMBINANT, AVEC ADJUVANT, ADSORBE)</option>
                                                            <option value={1087}>VACCIN PENTAVALENT CONTRE 5 ROTAVIRUS REASSORTANTS (G1, G3, G4 et P1)</option>
                                                            <option value={1200}>VACCIN PNEUMOCOCCIQUE POLYOSIDIQUE CONJUQUE ADSORBE</option>
                                                            <option value={1088}>VACCIN QUADRIVALENT CONTRE LE VIRUS HPV SEROTYPES 6,11,16 et 18</option>
                                                            <option value={1089}>VACCIN R.O.R</option>
                                                            <option value={1201}>VACCIN TYPHOÏDIQUE POLYOSIDIQUE</option>
                                                            <option value={1090}>VALACICLOVIR</option>
                                                            <option value={1091}>VALERATE D ESTRADIOL / DIENOGEST</option>
                                                            <option value={1092}>VALPROATE DE SODIUM</option>
                                                            <option value={1093}>VALSARTAN</option>
                                                            <option value={1094}>VALSARTAN / HYDROCHLOROTHIAZIDE</option>
                                                            <option value={1210}>VALSARTAN / SACUBITRIL</option>
                                                            <option value={1095}>VANCOMYCINE</option>
                                                            <option value={1096}>VARDENAFIL</option>
                                                            <option value={1097}>VARENICLINE</option>
                                                            <option value={1098}>VECURONIUM</option>
                                                            <option value={1099}>VENLAFAXINE</option>
                                                            <option value={1100}>VERAPAMIL</option>
                                                            <option value={1207}>VERAPAMIL / TRANDOLAPRIL</option>
                                                            <option value={1101}>VERTEPORFINE</option>
                                                            <option value={1102}>VILDAGLIPTINE</option>
                                                            <option value={1103}>VILDAGLIPTINE / METFORMINE</option>
                                                            <option value={1104}>VINBLASTINE</option>
                                                            <option value={1105}>VINCRISTINE</option>
                                                            <option value={1106}>VINFLUNINE</option>
                                                            <option value={1107}>VINORELBINE</option>
                                                            <option value={1108}>VITAMINES</option>
                                                            <option value={1109}>VITAMINES / MINERAUX</option>
                                                            <option value={1110}>VORICONAZOLE</option>
                                                            <option value={1111}>XIPAMIDE</option>
                                                            <option value={1112}>XYLOMETAZOLINE</option>
                                                            <option value={1113}>ZANAMIVIR</option>
                                                            <option value={1114}>ZIDOVUDINE</option>
                                                            <option value={1115}>ZIPRASIDONE</option>
                                                            <option value={1116}>ZOLMITRIPTAN</option>
                                                            <option value={1117}>ZOLPIDEM</option>
                                                            <option value={1118}>ZOPICLONE</option>
                                                        </select>
                                                    </div>{" "}
                                                    <div className="input_douchette">
                                                        <input type="text" id="transactions_products_barcode" autoComplete="off" placeholder="Nom ou code barre" />
                                                        <a className="sprite-douchette-icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bloc_content table_wrraper">
                                                <table id="transactions_products_table" data-ajax_url="/products/api/index" data-pricing_field="sale_price" className="table">
                                                    <thead>
                                                        <tr className="table_filter">
                                                            <td>
                                                                <div className="selectbox " style={{}}>
                                                                    <select name="transactions_products_table_search_operator" id="transactions_products_table_search_operator" className=" " autoComplete="off">
                                                                        <option value="LIKE">contient</option>
                                                                        <option value="STARTS">commence par</option>
                                                                    </select>
                                                                </div>{" "}
                                                            </td>
                                                            <td colSpan={1}>
                                                                <div className="input_search" style={{}}>
                                                                    <input type="text" name="transactions_products_table_search_name" id="transactions_products_table_search_name" defaultValue className=" transactions_products_table_header_search_q" placeholder="Nom" autoComplete="off" />
                                                                    <a className="sprite-search-btn" />
                                                                </div>{" "}
                                                            </td>
                                                            <td>
                                                                <div className="input_search" style={{}}>
                                                                    <input type="text" name="transactions_products_table_search_sale_price" id="transactions_products_table_search_sale_price" defaultValue className=" transactions_products_table_header_search_q" placeholder="PPV" autoComplete="off" />
                                                                    <a className="sprite-search-btn" />
                                                                </div>{" "}
                                                            </td>
                                                            <td>
                                                                <div className="selectbox " style={{}}>
                                                                    <select name="transactions_products_table_search_zone_id" id="transactions_products_table_search_zone_id" className=" transactions_products_table_header_search_q" autoComplete="off">
                                                                        <option value>Zone</option>
                                                                        <option value={96888}>AEROSOLS</option>
                                                                        <option value={97292}>amam</option>
                                                                        <option value={96883}>Antibiotique</option>
                                                                        <option value={96628}>COLLYRE </option>
                                                                        <option value={96892}>COLLYRES</option>
                                                                        <option value={96507}>COMPRIMER SIMPLE</option>
                                                                        <option value={96473}>COMPRIMES</option>
                                                                        <option value={95755}>COMPRIMES SIMPL</option>
                                                                        <option value={97318}>COMPRIMES SIMPLES </option>
                                                                        <option value={96472}>COMPRIMES SIMPLES</option>
                                                                        <option value={94379}>cps</option>
                                                                        <option value={100018}>FACULTES</option>
                                                                        <option value={94548}>JORF</option>
                                                                        <option value={96886}>kk,m,l,,l,k;,mk,m</option>
                                                                        <option value={95757}>PARA</option>
                                                                        <option value={98143}>para</option>
                                                                        <option value={101441}>PARA creme</option>
                                                                        <option value={94381}>pmd</option>
                                                                        <option value={101445}>pommade ophtalmique </option>
                                                                        <option value={94261}>Rayon bébé ABCDERM</option>
                                                                        <option value={94262}>Rayon bébé Dodie</option>
                                                                        <option value={96506}>RIDA</option>
                                                                        <option value={94346}>ROCHE</option>
                                                                        <option value={94060}>ROGER CAVAILLES</option>
                                                                        <option value={96039}>SIROP</option>
                                                                        <option value={95756}>SIROP</option>
                                                                        <option value={96474}>SIROPS</option>
                                                                        <option value={97320}>SIROPS SIMPLES </option>
                                                                        <option value={99910}>taper le nom du zone </option>
                                                                        <option value={93829}>tligui</option>
                                                                        <option value={94061}>VICHY</option>
                                                                        <option value={96038}>VITAMINE</option>
                                                                        <option value={96984}>ZONE A</option>
                                                                        <option value={96985}>ZONE B</option>
                                                                        <option value={97711}>zone comp</option>
                                                                        <option value={94852}>ZONE LEKBIRA</option>
                                                                        <option value={96600}>zone suppo</option>
                                                                        <option value={97203}>zone test 1</option>
                                                                    </select>
                                                                </div>{" "}
                                                            </td>
                                                            <td className="table_action">
                                                                <a href="#" id="search_transactions_products_table" className="search_bleu_btn">
                                                                    <i className="sprite-search-btn-white" />
                                                                </a>
                                                                <a href="#" id="refresh_data" style={{ display: "none" }} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ minWidth: "50px" }}>Nom</th>
                                                            <th style={{ minWidth: "50px" }}>Forme </th>
                                                            <th style={{ minWidth: "50px" }}>PPV</th>
                                                            <th style={{ minWidth: "50px" }}>Zone</th>
                                                            <th style={{ minWidth: "50px" }}>Disp.</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody></tbody>
                                                </table>
                                                <div id="transactions_products_empty_wrapper" className="plan-empty plan-empty2">
                                                    <p>
                                                        <i className="vente_fleche" />
                                                        Vous pouvez lancer une recherche ici !{" "}
                                                    </p>
                                                    <i className="empty-search-ico2" />
                                                </div>
                                                <div id="transactions_products_barcode_wrapper" className="plan-empty" style={{ display: "none" }}>
                                                    <p>Vous pouvez utiliser votre douchette maintenant</p>
                                                    <span>
                                                        <i className="empty-search-ico" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid2">
                                        {/* Fields */}
                                        <div className="bloc">
                                            {/* Fields */}
                                            <div className="bloc_content" style={{ background: "#ff5778" }}>
                                                <div className="bloc2" style={{}}>
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="invoice_date" className="inline_label" style={{}}>
                                                                Date *
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="input_date">
                                                                    <input type="text" name="invoice_date" id="invoice_date" defaultValue="2019-09-12" className="datepicker validate[required, custom[date]] hasDatepicker" data-is_datepicker="true" data-is_required="true" autoComplete="off" />
                                                                    <a className="sprite-date-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bloc2">
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="owner_id" className="inline_label" style={{}}>
                                                                Par *
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="selectbox">
                                                                    <select name="owner_id" id="owner_id" data-is_required="true" className=" validate[required]" autoComplete="off">
                                                                        <option value={26555}>compte Démonstration</option>
                                                                        <option value={27063}>employer europharm</option>
                                                                        <option value={27679}>houda ain</option>
                                                                        <option value={27350}>Ikbal Ikbal</option>
                                                                        <option value={27006}>l p</option>
                                                                        <option value={28084}>mer roc</option>
                                                                        <option value={27585}>SOBRUS FORMATEUR</option>
                                                                        <option value={26808}>tesst0009 sobrus</option>
                                                                        <option value={27010}>test007 sobrus</option>
                                                                        <option value={27865}>y n</option>
                                                                        <option value={27810}>Y Y</option>
                                                                        <option value={26728}>Youssef Naji</option>
                                                                        <option value={27657}>zizo ikbal</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="doctor_container" className="bloc2" style={{ marginTop: "5px", display: "none" }}>
                                                    <div className="plan-filter">
                                                        <div className="bloc2">
                                                            <label htmlFor="doctor_id" className="inline_label" style={{}}>
                                                                Médecin traitant
                                                            </label>
                                                        </div>
                                                        <div className="bloc2">
                                                            <div className="inline_input">
                                                                <div className="input_search">
                                                                    <input type="text" name="doctor_id" id="doctor_id" defaultValue className="lookup_field" readOnly="readonly" data-lookup_table="doctors" autoComplete="off" />
                                                                    <input type="hidden" name="doctor_id" id="hidden_doctor_id" defaultValue />
                                                                    <a className="sprite-search-btn lookup_field_trigger" />
                                                                    <a className="sprite-close-btn lookup_field_eraser" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Selected Products */}
                                        <div className="bloc">
                                            <div id="totals_container" className="bloc_header">
                                                <div className="bloc4">
                                                    <div className="plan-total">
                                                        <div className="row">
                                                            <div className="bloc2">
                                                                <div className="plan-total">
                                                                    <strong>
                                                                        Total à payer
                                                                        <small id="global_discount_button" style={{ cursor: "pointer" }}>
                                                                            R
                                                                        </small>
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                            <div className="bloc2">
                                                                <div className="plan-price">
                                                                    <strong className="total_amount">0.00 DHS</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="bloc2">
                                                                <div className="row">
                                                                    <div className="bloc2">
                                                                        <div className="plan-total">
                                                                            <span>Quantité totale</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloc2">
                                                                        <div className="plan-price">
                                                                            <span className="total_quantity">0</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bloc_content table_wrraper">
                                                <table id="selected_products_table" className="table table-hidden-tr">
                                                    <thead>
                                                        <tr>
                                                            <th />
                                                            <th>Produit</th>
                                                            <th>P.U</th>
                                                            <th>PPV</th>
                                                            <th>Disp.</th>
                                                            <th />
                                                            <th />
                                                            <th />
                                                            <th>Total</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody className="main_tbody"></tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <table id="product_prototype_container" style={{ display: "none" }}>
                                            <tbody>
                                                <tr className="product_info">
                                                    <td>
                                                        <strong className="quantity" />
                                                    </td>
                                                    <td>
                                                        <span className="name" />
                                                        <p className="expiry_date_info_normal">
                                                            <i className="peremp_icon_black" />
                                                            <strong id="peremp_strong_black" />
                                                        </p>
                                                        <p className="expiry_date_info_alert">
                                                            <i className="peremp_icon" />
                                                            <strong id="peremp_strong" />
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <div className="unit_price" style={{ fontWeight: "bold" }} />
                                                        <div className="table-badge discount_sticker" style={{ display: "none" }} />
                                                    </td>
                                                    <td>
                                                        <strong className="sale_price" />
                                                        <p className="multiprice_container">
                                                            <i className="multiprix_icon" />
                                                            <strong id="multiprix_strong">Multiprix</strong>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <strong className="available" />
                                                    </td>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <strong className="line_total" />
                                                    </td>
                                                    <td className="table_action">
                                                        <a href="#" className="sprite-table-delete-btn delete_product_line" />
                                                        <a href="#" className="sprite-table-edit-btn edit_product_data_button" />
                                                    </td>
                                                </tr>
                                                <tr className="hidden-tr product_data">
                                                    <td colSpan={10} className="tht-td">
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type="hidden" name="products[0][ID]" className="product_id" />
                                                                        <input type="hidden" name="products[0][name]" className="product_name" />
                                                                        <input type="hidden" name="products[0][unit_original_price]" className="unit_original_price" />
                                                                        <input type="hidden" name="products[0][purchase_price]" className="purchase_price" />
                                                                        <input type="hidden" name="products[0][sale_price]" className="sale_price" />
                                                                        <input type="hidden" name="products[0][product_schedule]" className="product_schedule" />
                                                                        <input type="hidden" name="products[0][tax_id]" className="tax_id" />
                                                                        <div className="form_row">
                                                                            <label>Quantité</label>
                                                                            <input type="text" name="products[0][quantity]" id="products-0-quantity" defaultValue className=" input quantity validate[custom[integer]] intOnly" style={{}} autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Prix unitaire</label>
                                                                            <input type="text" name="products[0][unit_price]" id="products-0-unit_price" defaultValue className=" input unit_price validate[custom[number]] decimalOnly" style={{}} autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ width: "100px" }}>
                                                                        <div className="form_row">
                                                                            <label>Type de remise</label>
                                                                            <div className="selectbox " style={{}}>
                                                                                <select name="products[0][discount_type]" id="products-0-discount_type" className=" discount_type" autoComplete="off">
                                                                                    <option value="percentage">%</option>
                                                                                    <option value="amount">Montant</option>
                                                                                </select>
                                                                            </div>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{}}>
                                                                        <div className="form_row">
                                                                            <label>Remise</label>
                                                                            <input type="text" name="products[0][discount]" id="products-0-discount" defaultValue className=" input discount validate[custom[number]] decimalOnly" style={{}} autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td style={{}}>
                                                                        <div className="form_row">
                                                                            <a href="#" className="btn_white discount_free_products" style={{ width: "70px", padding: 0, marginTop: "15px" }}>
                                                                                Rem. U.G
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Prix</label>
                                                                            <div className="selectbox " style={{}}>
                                                                                <select name="products[0][product_price_id]" id="products-0-product_price_id" className=" product_price_id" autoComplete="off">
                                                                                    <option value={0}>Par défaut</option>
                                                                                </select>
                                                                            </div>{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Disponible</label>
                                                                            <input type="text" name="products[0][available]" id="products-0-available" defaultValue className=" input available validate[custom[number]] disabled" style={{}} autoComplete="off" readOnly="readonly" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Base de remboursement</label>
                                                                            <input type="text" name="products[0][unit_max_reimbursable_price]" id="products-0-unit_max_reimbursable_price" defaultValue className=" input unit_max_reimbursable_price decimal" style={{}} autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form_row">
                                                                            <label>Taux Remb.</label>
                                                                            <input type="text" name="products[0][unit_reimbursement_rate]" id="products-0-unit_reimbursement_rate" defaultValue className=" input unit_reimbursement_rate decimal" style={{}} autoComplete="off" />{" "}
                                                                        </div>
                                                                    </td>
                                                                    <td />
                                                                    <td />
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div id="product_prices_popup_container" className="popup_wrapper" style={{ display: "none", position: "fixed" }}>
                                            <div className="popup_top_produit">
                                                <a href="#" className="popup_close product_prices_popup_close">
                                                    <img src="/images/close.png" />
                                                </a>
                                                <div id="product_prices_popup_image_container" className="popup_produit_img product_img"></div>
                                                <div id="product_prices_popup_zoom_container" className="tooltip_zoom"></div>
                                                <div className="popup_title" style={{ float: "left", width: "200px", marginTop: "25px", marginLeft: "20px" }}>
                                                    <h2 id="product_prices_popup_product_name" style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "bold", color: "#fff", textTransform: "uppercase", marginBottom: "2px" }}></h2>
                                                    <span style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "normal", color: "#fff" }}>Ce produit a plusieurs prix, lequel vous souhaitez utiliser ? </span>
                                                </div>
                                            </div>
                                            <div className="popup_bottom" style={{ height: "230px" }}>
                                                <div id="product_prices_lines_container" className="popup_form" style={{ width: "95%", verticalAlign: "middle", marginRight: "auto", marginLeft: "auto", overflowY: "auto", height: "219px" }}></div>
                                                <div className="popup_footer" style={{ marginTop: 0 }}>
                                                    <a href="#" className="btn_red product_prices_popup_close">
                                                        Annuler
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="product_prices_line_container" style={{ display: "none" }}>
                                            <label htmlFor="product_prices_radio_0" className="radio radio_option product_prices_line" style={{ cursor: "pointer" }}>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="product_prices_radio_0" id="product_prices_radio_0" />
                                                        <span className="checked" />
                                                    </li>
                                                    <li>
                                                        <p className="product_prices_line_price">
                                                            Prix unitaire : <strong />
                                                        </p>
                                                    </li>
                                                    <li className="product_prices_line_new_tag">
                                                        <span className="tag_new">Nouveau</span>
                                                    </li>
                                                    <li className="radio_action">
                                                        <a href="#" className="sprite-table-delete-btn tooltip delete_product_price" style={{ display: "inline-block" }} />
                                                    </li>
                                                </ul>
                                            </label>
                                        </div>
                                        <div id="product_expiry_dates_popup_container" className="popup_wrapper" style={{ display: "none", position: "fixed" }}>
                                            <div className="popup_top_produit">
                                                <a href="#" className="popup_close product_expiry_dates_popup_close">
                                                    <img src="/images/close.png" />
                                                </a>
                                                <div id="product_expiry_dates_popup_image_container" className="popup_produit_img product_img"></div>
                                                <div id="product_expiry_dates_popup_zoom_container" className="tooltip_zoom"></div>
                                                <div className="popup_title" style={{ float: "left", width: "200px", marginTop: "25px", marginLeft: "20px" }}>
                                                    <h2 id="product_expiry_dates_popup_product_name" style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "bold", color: "#fff", textTransform: "uppercase", marginBottom: "2px" }}></h2>
                                                    <span style={{ fontFamily: "open Sans", fontSize: "15px", fontWeight: "normal", color: "#fff" }}>Une des dates de péremption de ce produit est proche ! </span>
                                                </div>
                                            </div>
                                            <div className="popup_bottom" style={{ height: "230px" }}>
                                                <div id="product_expiry_dates_lines_container" className="popup_form" style={{ width: "95%", verticalAlign: "middle", marginRight: "auto", marginLeft: "auto", overflowY: "auto", height: "219px" }}></div>
                                            </div>
                                        </div>
                                        <div id="product_expiry_dates_line_container" style={{ display: "none" }}>
                                            <label htmlFor="product_expiry_dates_radio_0" className="radio radio_option product_expiry_dates_line" style={{ cursor: "pointer" }}>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="product_expiry_dates_radio_0" id="product_expiry_dates_radio_0" />
                                                        <span className="checked" />
                                                    </li>
                                                    <li>
                                                        <p className="product_expiry_dates_line_expiry_date">
                                                            Date de péremption : <strong />
                                                        </p>
                                                    </li>
                                                    <li className="product_expiry_dates_line_new_tag">
                                                        <span className="tag_new">Nouveau</span>
                                                    </li>
                                                    <li className="radio_action">
                                                        <a href="#" className="sprite-table-delete-btn tooltip delete_product_expiry_date" style={{ display: "inline-block" }} />
                                                    </li>
                                                </ul>
                                            </label>
                                        </div>
                                        <div id="global_discount_form_container" style={{ display: "none" }}>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_application_type" className="inline_label">
                                                    Appliquer à
                                                </label>
                                                <div className="inline_input">
                                                    <div className="selectbox " style={{}}>
                                                        <select name="global_discount_application_type" id="global_discount_application_type" className=" " autoComplete="off">
                                                            <option value="each_product">Appliquer à chaque produit</option>
                                                            <option value="total">Appliquer au total</option>
                                                            <option value="rectify">Rectifier le total</option>
                                                        </select>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_type" className="inline_label">
                                                    Type de remise
                                                </label>
                                                <div className="inline_input">
                                                    <div className="selectbox " style={{}}>
                                                        <select name="global_discount_type" id="global_discount_type" className=" " autoComplete="off">
                                                            <option value="percentage">%</option>
                                                            <option value="amount">Montant</option>
                                                        </select>
                                                    </div>
                                                </div>{" "}
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount" className="inline_label">
                                                    Remise
                                                </label>
                                                <div className="inline_input">
                                                    <input type="text" name="global_discount" id="global_discount" defaultValue className=" input validate[custom[number]] decimalOnly" style={{}} autoComplete="off" />
                                                </div>{" "}
                                            </div>
                                            <div className="form_row">
                                                <label htmlFor="global_discount_total" className="inline_label">
                                                    Total
                                                </label>
                                                <div className="inline_input">
                                                    <input type="text" name="global_discount_total" id="global_discount_total" defaultValue className=" input validate[custom[number]] decimalOnly" style={{}} autoComplete="off" />
                                                </div>{" "}
                                            </div>
                                            <div className="popup_produit_footer" style={{ width: "calc(100% + 20px)", marginTop: "30px", marginLeft: "-10px", position: "initial", marginBottom: "-15px" }}>
                                                <a href="#" id="global_discount_form_submit" className="btn_green">
                                                    Appliquer
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="prescription_container" className="container4" style={{ display: "none" }}>
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc_content Products_tab_content">
                                            <div className="container4_in">
                                                <div className="gri4">
                                                    <div className="bloc_content_top">
                                                        <img src="/images/products_tab.png" alt="produits tableaux sobrus" />
                                                        <h3>Produits tableaux</h3>
                                                        <p>Certains produits dans cette vente font partie des produits tableaux. Veuillez compléter le formulaire ci-après pour respecter la réglementation en vigueur.</p>
                                                    </div>
                                                    <div className="bloc_content table_wrraper Table_padding">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Nom</th>
                                                                    <th>Tableau</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="schedule_products"></tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc_header">
                                            <h2>Informations de prescription </h2>
                                        </div>
                                        <div className="bloc_content">
                                            <form method="post" id="prescription_form" className="validateable">
                                                <input type="hidden" name="prescription_customer_id_name" defaultValue id="prescription_customer_id_name" />
                                                <input type="hidden" name="prescription_doctor_id_name" defaultValue id="prescription_doctor_id_name" />
                                                <input type="hidden" name="hash" defaultValue="4092193cd2bc7d9bd2caea73b9d18fa8" id="hash" />
                                                <input type="hidden" name="redirect_url" defaultValue id="redirect_url" />{" "}
                                                <div className="container4_in">
                                                    <div className="gri4">
                                                        <div className="bloc_h3">
                                                            <h3>Information</h3>
                                                            <span />
                                                        </div>
                                                        <div className="grid4">
                                                            <div className="form_elem_wrraper">
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_customer_id_name" style={{}} />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_customer_id" style={{}}>
                                                                        Client *
                                                                    </label>
                                                                    <div className="input_search">
                                                                        <input
                                                                            type="text"
                                                                            name="prescription_customer_id"
                                                                            id="prescription_customer_id"
                                                                            defaultValue
                                                                            className="lookup_field validate[required]"
                                                                            readOnly="readonly"
                                                                            data-lookup_table="customers"
                                                                            data-is_required="true"
                                                                            autoComplete="off"
                                                                        />
                                                                        <input type="hidden" name="prescription_customer_id" id="hidden_prescription_customer_id" defaultValue />
                                                                        <a className="sprite-search-btn lookup_field_trigger" />
                                                                    </div>{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_address" style={{}}>
                                                                        Adresse *
                                                                    </label>
                                                                    <input type="text" name="prescription_address" id="prescription_address" defaultValue data-is_required="true" className=" input validate[required]" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_city" style={{}}>
                                                                        Ville *
                                                                    </label>
                                                                    <input type="text" name="prescription_city" id="prescription_city" defaultValue data-is_required="true" className=" input validate[required]" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_postal_code" style={{}}>
                                                                        Code postal
                                                                    </label>
                                                                    <input type="text" name="prescription_postal_code" id="prescription_postal_code" defaultValue className=" input" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_country" style={{}}>
                                                                        Pays *
                                                                    </label>
                                                                    <div className="selectbox">
                                                                        <select name="prescription_country" id="prescription_country" data-is_required="true" className=" validate[required]" autoComplete="off">
                                                                            <option value>Aucun</option>
                                                                            <option value="AF">Afghanistan</option>
                                                                            <option value="ZA">Afrique du Sud</option>
                                                                            <option value="AL">Albanie</option>
                                                                            <option value="DZ">Algérie</option>
                                                                            <option value="DE">Allemagne</option>
                                                                            <option value="AD">Andorre</option>
                                                                            <option value="AO">Angola</option>
                                                                            <option value="AI">Anguilla</option>
                                                                            <option value="AQ">Antarctique</option>
                                                                            <option value="AG">Antigua-et-Barbuda</option>
                                                                            <option value="AN">Antilles néerlandaises</option>
                                                                            <option value="SA">Arabie saoudite</option>
                                                                            <option value="AR">Argentine</option>
                                                                            <option value="AM">Arménie</option>
                                                                            <option value="AW">Aruba</option>
                                                                            <option value="AU">Australie</option>
                                                                            <option value="AT">Autriche</option>
                                                                            <option value="AZ">Azerbaïdjan</option>
                                                                            <option value="BS">Bahamas</option>
                                                                            <option value="BH">Bahreïn</option>
                                                                            <option value="BD">Bangladesh</option>
                                                                            <option value="BB">Barbade</option>
                                                                            <option value="BE">Belgique</option>
                                                                            <option value="BZ">Belize</option>
                                                                            <option value="BM">Bermudes</option>
                                                                            <option value="BT">Bhoutan</option>
                                                                            <option value="BY">Biélorussie</option>
                                                                            <option value="BO">Bolivie</option>
                                                                            <option value="BA">Bosnie-Herzégovine</option>
                                                                            <option value="BW">Botswana</option>
                                                                            <option value="BN">Brunéi Darussalam</option>
                                                                            <option value="BR">Brésil</option>
                                                                            <option value="BG">Bulgarie</option>
                                                                            <option value="BF">Burkina Faso</option>
                                                                            <option value="BI">Burundi</option>
                                                                            <option value="BJ">Bénin</option>
                                                                            <option value="KH">Cambodge</option>
                                                                            <option value="CM">Cameroun</option>
                                                                            <option value="CA">Canada</option>
                                                                            <option value="CV">Cap-Vert</option>
                                                                            <option value="EA">Ceuta et Melilla</option>
                                                                            <option value="CL">Chili</option>
                                                                            <option value="CN">Chine</option>
                                                                            <option value="CY">Chypre</option>
                                                                            <option value="CO">Colombie</option>
                                                                            <option value="KM">Comores</option>
                                                                            <option value="CG">Congo-Brazzaville</option>
                                                                            <option value="CD">Congo-Kinshasa</option>
                                                                            <option value="KP">Corée du Nord</option>
                                                                            <option value="KR">Corée du Sud</option>
                                                                            <option value="CR">Costa Rica</option>
                                                                            <option value="HR">Croatie</option>
                                                                            <option value="CU">Cuba</option>
                                                                            <option value="CW">Curaçao</option>
                                                                            <option value="CI">Côte d’Ivoire</option>
                                                                            <option value="DK">Danemark</option>
                                                                            <option value="DG">Diego Garcia</option>
                                                                            <option value="DJ">Djibouti</option>
                                                                            <option value="DM">Dominique</option>
                                                                            <option value="SV">El Salvador</option>
                                                                            <option value="ES">Espagne</option>
                                                                            <option value="EE">Estonie</option>
                                                                            <option value="FJ">Fidji</option>
                                                                            <option value="FI">Finlande</option>
                                                                            <option value="FR">France</option>
                                                                            <option value="GA">Gabon</option>
                                                                            <option value="GM">Gambie</option>
                                                                            <option value="GH">Ghana</option>
                                                                            <option value="GI">Gibraltar</option>
                                                                            <option value="GD">Grenade</option>
                                                                            <option value="GL">Groenland</option>
                                                                            <option value="GR">Grèce</option>
                                                                            <option value="GP">Guadeloupe</option>
                                                                            <option value="GU">Guam</option>
                                                                            <option value="GT">Guatemala</option>
                                                                            <option value="GG">Guernesey</option>
                                                                            <option value="GN">Guinée</option>
                                                                            <option value="GQ">Guinée équatoriale</option>
                                                                            <option value="GW">Guinée-Bissau</option>
                                                                            <option value="GY">Guyana</option>
                                                                            <option value="GF">Guyane française</option>
                                                                            <option value="GE">Géorgie</option>
                                                                            <option value="GS">Géorgie du Sud et les Îles Sandwich du Sud</option>
                                                                            <option value="HT">Haïti</option>
                                                                            <option value="HN">Honduras</option>
                                                                            <option value="HU">Hongrie</option>
                                                                            <option value="IN">Inde</option>
                                                                            <option value="ID">Indonésie</option>
                                                                            <option value="IQ">Irak</option>
                                                                            <option value="IR">Iran</option>
                                                                            <option value="IE">Irlande</option>
                                                                            <option value="IS">Islande</option>
                                                                            <option value="IL">Israël</option>
                                                                            <option value="IT">Italie</option>
                                                                            <option value="JM">Jamaïque</option>
                                                                            <option value="JP">Japon</option>
                                                                            <option value="JE">Jersey</option>
                                                                            <option value="JO">Jordanie</option>
                                                                            <option value="KZ">Kazakhstan</option>
                                                                            <option value="KE">Kenya</option>
                                                                            <option value="KG">Kirghizistan</option>
                                                                            <option value="KI">Kiribati</option>
                                                                            <option value="XK">Kosovo</option>
                                                                            <option value="KW">Koweït</option>
                                                                            <option value="RE">La Réunion</option>
                                                                            <option value="LA">Laos</option>
                                                                            <option value="LS">Lesotho</option>
                                                                            <option value="LV">Lettonie</option>
                                                                            <option value="LB">Liban</option>
                                                                            <option value="LY">Libye</option>
                                                                            <option value="LR">Libéria</option>
                                                                            <option value="LI">Liechtenstein</option>
                                                                            <option value="LT">Lituanie</option>
                                                                            <option value="LU">Luxembourg</option>
                                                                            <option value="MK">Macédoine</option>
                                                                            <option value="MG">Madagascar</option>
                                                                            <option value="MY">Malaisie</option>
                                                                            <option value="MW">Malawi</option>
                                                                            <option value="MV">Maldives</option>
                                                                            <option value="ML">Mali</option>
                                                                            <option value="MT">Malte</option>
                                                                            <option value="MA">Maroc</option>
                                                                            <option value="MQ">Martinique</option>
                                                                            <option value="MU">Maurice</option>
                                                                            <option value="MR">Mauritanie</option>
                                                                            <option value="YT">Mayotte</option>
                                                                            <option value="MX">Mexique</option>
                                                                            <option value="MD">Moldavie</option>
                                                                            <option value="MC">Monaco</option>
                                                                            <option value="MN">Mongolie</option>
                                                                            <option value="MS">Montserrat</option>
                                                                            <option value="ME">Monténégro</option>
                                                                            <option value="MZ">Mozambique</option>
                                                                            <option value="MM">Myanmar</option>
                                                                            <option value="NA">Namibie</option>
                                                                            <option value="NR">Nauru</option>
                                                                            <option value="NI">Nicaragua</option>
                                                                            <option value="NE">Niger</option>
                                                                            <option value="NG">Nigéria</option>
                                                                            <option value="NU">Niue</option>
                                                                            <option value="NO">Norvège</option>
                                                                            <option value="NC">Nouvelle-Calédonie</option>
                                                                            <option value="NZ">Nouvelle-Zélande</option>
                                                                            <option value="NP">Népal</option>
                                                                            <option value="OM">Oman</option>
                                                                            <option value="UG">Ouganda</option>
                                                                            <option value="UZ">Ouzbékistan</option>
                                                                            <option value="PK">Pakistan</option>
                                                                            <option value="PW">Palaos</option>
                                                                            <option value="PA">Panama</option>
                                                                            <option value="PG">Papouasie-Nouvelle-Guinée</option>
                                                                            <option value="PY">Paraguay</option>
                                                                            <option value="NL">Pays-Bas</option>
                                                                            <option value="BQ">Pays-Bas caribéens</option>
                                                                            <option value="PH">Philippines</option>
                                                                            <option value="PN">Pitcairn</option>
                                                                            <option value="PL">Pologne</option>
                                                                            <option value="PF">Polynésie française</option>
                                                                            <option value="PR">Porto Rico</option>
                                                                            <option value="PT">Portugal</option>
                                                                            <option value="PE">Pérou</option>
                                                                            <option value="QA">Qatar</option>
                                                                            <option value="HK">R.A.S. chinoise de Hong Kong</option>
                                                                            <option value="MO">R.A.S. chinoise de Macao</option>
                                                                            <option value="RO">Roumanie</option>
                                                                            <option value="GB">Royaume-Uni</option>
                                                                            <option value="RU">Russie</option>
                                                                            <option value="RW">Rwanda</option>
                                                                            <option value="CF">République centrafricaine</option>
                                                                            <option value="DO">République dominicaine</option>
                                                                            <option value="CZ">République tchèque</option>
                                                                            <option value="EH">Sahara occidental</option>
                                                                            <option value="BL">Saint-Barthélemy</option>
                                                                            <option value="KN">Saint-Kitts-et-Nevis</option>
                                                                            <option value="SM">Saint-Marin</option>
                                                                            <option value="MF">Saint-Martin (partie française)</option>
                                                                            <option value="SX">Saint-Martin (partie néerlandaise)</option>
                                                                            <option value="PM">Saint-Pierre-et-Miquelon</option>
                                                                            <option value="VC">Saint-Vincent-et-les Grenadines</option>
                                                                            <option value="SH">Sainte-Hélène</option>
                                                                            <option value="LC">Sainte-Lucie</option>
                                                                            <option value="WS">Samoa</option>
                                                                            <option value="AS">Samoa américaines</option>
                                                                            <option value="ST">Sao Tomé-et-Principe</option>
                                                                            <option value="RS">Serbie</option>
                                                                            <option value="SC">Seychelles</option>
                                                                            <option value="SL">Sierra Leone</option>
                                                                            <option value="SG">Singapour</option>
                                                                            <option value="SK">Slovaquie</option>
                                                                            <option value="SI">Slovénie</option>
                                                                            <option value="SO">Somalie</option>
                                                                            <option value="SD">Soudan</option>
                                                                            <option value="SS">Soudan du Sud</option>
                                                                            <option value="LK">Sri Lanka</option>
                                                                            <option value="CH">Suisse</option>
                                                                            <option value="SR">Suriname</option>
                                                                            <option value="SE">Suède</option>
                                                                            <option value="SJ">Svalbard et Jan Mayen</option>
                                                                            <option value="SZ">Swaziland</option>
                                                                            <option value="SY">Syrie</option>
                                                                            <option value="SN">Sénégal</option>
                                                                            <option value="TJ">Tadjikistan</option>
                                                                            <option value="TZ">Tanzanie</option>
                                                                            <option value="TW">Taïwan</option>
                                                                            <option value="TD">Tchad</option>
                                                                            <option value="TF">Terres australes françaises</option>
                                                                            <option value="IO">Territoire britannique de l’océan Indien</option>
                                                                            <option value="PS">Territoires palestiniens</option>
                                                                            <option value="TH">Thaïlande</option>
                                                                            <option value="TL">Timor oriental</option>
                                                                            <option value="TG">Togo</option>
                                                                            <option value="TK">Tokelau</option>
                                                                            <option value="TO">Tonga</option>
                                                                            <option value="TT">Trinité-et-Tobago</option>
                                                                            <option value="TA">Tristan da Cunha</option>
                                                                            <option value="TN">Tunisie</option>
                                                                            <option value="TM">Turkménistan</option>
                                                                            <option value="TR">Turquie</option>
                                                                            <option value="TV">Tuvalu</option>
                                                                            <option value="UA">Ukraine</option>
                                                                            <option value="UY">Uruguay</option>
                                                                            <option value="VU">Vanuatu</option>
                                                                            <option value="VE">Venezuela</option>
                                                                            <option value="VN">Vietnam</option>
                                                                            <option value="WF">Wallis-et-Futuna</option>
                                                                            <option value="YE">Yémen</option>
                                                                            <option value="ZM">Zambie</option>
                                                                            <option value="ZW">Zimbabwe</option>
                                                                            <option value="ZZ">région indéterminée</option>
                                                                            <option value="EG">Égypte</option>
                                                                            <option value="AE">Émirats arabes unis</option>
                                                                            <option value="EC">Équateur</option>
                                                                            <option value="ER">Érythrée</option>
                                                                            <option value="VA">État de la Cité du Vatican</option>
                                                                            <option value="FM">États fédérés de Micronésie</option>
                                                                            <option value="US">États-Unis</option>
                                                                            <option value="ET">Éthiopie</option>
                                                                            <option value="BV">Île Bouvet</option>
                                                                            <option value="CX">Île Christmas</option>
                                                                            <option value="CP">Île Clipperton</option>
                                                                            <option value="NF">Île Norfolk</option>
                                                                            <option value="IM">Île de Man</option>
                                                                            <option value="AC">Île de l’Ascension</option>
                                                                            <option value="IC">Îles Canaries</option>
                                                                            <option value="KY">Îles Caïmans</option>
                                                                            <option value="CC">Îles Cocos (Keeling)</option>
                                                                            <option value="CK">Îles Cook</option>
                                                                            <option value="FO">Îles Féroé</option>
                                                                            <option value="HM">Îles Heard et MacDonald</option>
                                                                            <option value="FK">Îles Malouines</option>
                                                                            <option value="MP">Îles Mariannes du Nord</option>
                                                                            <option value="MH">Îles Marshall</option>
                                                                            <option value="SB">Îles Salomon</option>
                                                                            <option value="TC">Îles Turques-et-Caïques</option>
                                                                            <option value="VG">Îles Vierges britanniques</option>
                                                                            <option value="VI">Îles Vierges des États-Unis</option>
                                                                            <option value="UM">Îles mineures éloignées des États-Unis</option>
                                                                            <option value="AX">Îles Åland</option>
                                                                        </select>
                                                                    </div>{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_doctor_id_name" style={{}} />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="prescription_doctor_id" style={{}}>
                                                                        Médecin traitant *
                                                                    </label>
                                                                    <div className="input_search">
                                                                        <input type="text" name="prescription_doctor_id" id="prescription_doctor_id" defaultValue className="lookup_field validate[required]" readOnly="readonly" data-lookup_table="doctors" data-is_required="true" autoComplete="off" />
                                                                        <input type="hidden" name="prescription_doctor_id" id="hidden_prescription_doctor_id" defaultValue />
                                                                        <a className="sprite-search-btn lookup_field_trigger" />
                                                                    </div>{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clear" />
                                                    </div>
                                                </div>
                                            </form>{" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="payer_container" className="container4" style={{ display: "none" }}>
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc_header">
                                            <h2>Informations de l’organisme</h2>
                                        </div>
                                        <div className="bloc_content">
                                            <form method="post" id="customer_payer_info_form" className="validateable">
                                                <input type="hidden" name="payer_doctor_id_name" defaultValue id="payer_doctor_id_name" />
                                                <input type="hidden" name="hash" defaultValue="28656c3c6b7de6e102817fe2e1c68c4c" id="hash" />
                                                <input type="hidden" name="redirect_url" defaultValue id="redirect_url" />{" "}
                                                <div className="container4_in">
                                                    <div className="gri4">
                                                        <div className="bloc_h3">
                                                            <h3>Informations de l’organisme</h3>
                                                            <span />
                                                        </div>
                                                        <div className="grid4">
                                                            <div className="form_elem_wrraper">
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_contact_id" style={{}}>
                                                                        Bénéficiaire
                                                                    </label>
                                                                    <div className="selectbox">
                                                                        <select name="payer_contact_id" id="payer_contact_id" autoComplete="off">
                                                                            <option value>Aucun</option>
                                                                        </select>
                                                                    </div>{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_doctor_id_name" style={{}} />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_doctor_id" style={{}}>
                                                                        Médecin traitant *
                                                                    </label>
                                                                    <div className="input_search">
                                                                        <input type="text" name="payer_doctor_id" id="payer_doctor_id" defaultValue className="lookup_field validate[required]" readOnly="readonly" data-lookup_table="doctors" data-is_required="true" autoComplete="off" />
                                                                        <input type="hidden" name="payer_doctor_id" id="hidden_payer_doctor_id" defaultValue />
                                                                        <a className="sprite-search-btn lookup_field_trigger" />
                                                                    </div>{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_registration_number" style={{}}>
                                                                        Numéro d'immatriculation *
                                                                    </label>
                                                                    <input type="text" name="payer_registration_number" id="payer_registration_number" defaultValue data-is_required="true" className=" input validate[required]" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_affiliation_number" style={{}}>
                                                                        Numéro d'affiliation
                                                                    </label>
                                                                    <input type="text" name="payer_affiliation_number" id="payer_affiliation_number" defaultValue className=" input" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_file_number" style={{}}>
                                                                        Dossier N° *
                                                                    </label>
                                                                    <input type="text" name="payer_file_number" id="payer_file_number" defaultValue data-is_required="true" className=" input validate[required]" autoComplete="off" />{" "}
                                                                </div>
                                                                <div className="form_row">
                                                                    <label htmlFor="payer_coverage_number" style={{}}>
                                                                        N° Prise en charge *
                                                                    </label>
                                                                    <input type="text" name="payer_coverage_number" id="payer_coverage_number" defaultValue data-is_required="true" className=" input validate[required]" autoComplete="off" />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="clear" />
                                                    </div>
                                                </div>
                                            </form>{" "}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid2">
                                    <div className="bloc ">
                                        <a href="#" id="skip_payer_info" className="btn_next">
                                            <h4>Ignorer cette étape</h4>
                                            <p>Cliquez ici si ce n’est pas une vente tiers payant</p>
                                            <i className="next_icon" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div id="payment_container" className="container4" style={{}}>
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc_header">
                                            <div className="plan-user-detail">
                                                <div id="payment_invoice_date" className="bloc2">
                                                    Date : 2019-09-12
                                                </div>
                                                <div id="payment_invoice_owner_id" className="bloc2 text-right">
                                                    Par : compte Démonstration
                                                </div>
                                            </div>
                                            <div className="bloc2">
                                                <div className="plan-total">
                                                    <strong>Total à payer par le client</strong>
                                                    <span>Total à payer par l’organisme </span>
                                                    <br />
                                                    <span>Quantité totale </span>
                                                </div>
                                            </div>
                                            <div className="bloc2">
                                                <div className="plan-price">
                                                    <strong className="total_amount_for_customer">0.00 DHS</strong>
                                                    <span style={{ textAlign: "right" }} className="total_amount_for_payer">
                                                        0.00 DHS
                                                    </span>
                                                    <span style={{ textAlign: "right" }} className="total_quantity">
                                                        0
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="payment_customer_info" className="bloc_content" style={{ display: "none" }}>
                                            <div className="plan-row">
                                                <div className="bloc2">
                                                    <div className="user-name">
                                                        <i className="person" />
                                                        <span className="client">Client</span> <br />
                                                        <a href="#" id="payment_customer_name" target="_blank" />
                                                    </div>
                                                </div>
                                                <div className="bloc2 text-right">
                                                    <a href="#" id="credit_sale" className="btn_red2">
                                                        Vente à crédit <i className="caret" />
                                                    </a>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="user-calcul">
                                                <div className="plan-row">
                                                    <div className="bloc2">
                                                        <strong>Dernier achat</strong>
                                                    </div>
                                                    <div className="bloc2 text-right">
                                                        <span id="payment_last_invoice_date" />
                                                    </div>
                                                </div>
                                                <div className="plan-row">
                                                    <div className="bloc2">
                                                        <strong>Date dernier paiement</strong>
                                                    </div>
                                                    <div className="bloc2 text-right">
                                                        <span id="payment_last_payment_date" />
                                                    </div>
                                                </div>
                                                <div className="plan-row">
                                                    <div className="bloc2">
                                                        <strong>Crédit</strong>
                                                    </div>
                                                    <div className="bloc2 text-right">
                                                        <i className="minus" />
                                                        <span id="payment_customer_debit">0 DHS</span>
                                                    </div>
                                                </div>
                                                <div className="plan-row">
                                                    <div className="bloc2">
                                                        <strong>Avoir</strong>
                                                    </div>
                                                    <div className="bloc2 text-right">
                                                        <span id="payment_customer_credit">0 DHS</span>
                                                    </div>
                                                </div>
                                                <div className="plan-row somme">
                                                    <div className="bloc2">Solde </div>
                                                    <div className="bloc2 text-right">
                                                        <i className="equal" />
                                                        <span id="payment_customer_balance" style={{ color: "white" }}>
                                                            0 DHS
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid2">
                                    <div className="bloc">
                                        <div className="bloc_content">
                                            <div className="plan-row plan-montant">
                                                <div className="bloc2">
                                                    <strong>Montant reçu (DHS)</strong>
                                                </div>
                                                <div className="bloc2 text-right">
                                                    <input type="text" name="payment_amount_received" id="payment_amount_received" defaultValue className=" input validate[custom[number]] decimalOnly" style={{}} autoComplete="off" />{" "}
                                                </div>
                                            </div>
                                            <div className="plan-row plan-montant">
                                                <div className="bloc2">
                                                    <strong>Monnaie</strong>
                                                </div>
                                                <div className="bloc2 text-right">
                                                    <span id="payment_change" className="plan-montant-price">
                                                        0 DHS
                                                    </span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="plan-row">
                                                <div className="bloc2">Statut </div>
                                                <div className="bloc2">
                                                    <div className="selectbox">
                                                        <select name="status" id="status" data-is_required="true" className=" validate[required]" autoComplete="off">
                                                            <option value="pending">En attente</option>
                                                            <option value="certified">Certifé</option>
                                                            <option value="canceled">Annulé</option>
                                                            <option value="completed">Complété</option>
                                                        </select>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                            <div className="plan-row" style={{ display: "none" }}>
                                                <div className="bloc2">Date paiement </div>
                                                <div className="bloc2">
                                                    <div className="input_date">
                                                        <input type="text" name="payment_date" id="payment_date" defaultValue className="datepicker validate[required, custom[date]] hasDatepicker" data-is_datepicker="true" data-is_required="true" autoComplete="off" />
                                                        <a className="sprite-date-icon" />
                                                    </div>{" "}
                                                </div>
                                            </div>
                                            <div className="plan-row">
                                                <div className="bloc2">Référence </div>
                                                <div className="bloc2">
                                                    <input type="text" name="ref" id="ref" defaultValue className=" input" autoComplete="off" />{" "}
                                                </div>
                                            </div>
                                            <div className="plan-row">
                                                <div className="bloc2">Livré </div>
                                                <div className="bloc2">
                                                    <label htmlFor="payment_is_delivered" className="checkbox" style={{ display: "block" }}>
                                                        <input type="checkbox" name="payment_is_delivered" id="payment_is_delivered" defaultValue={1} defaultChecked="checked" autoComplete="off" />
                                                        <span className="checked" />
                                                    </label>{" "}
                                                </div>
                                            </div>
                                            <div id="given_back_via_credit_container" className="plan-row" style={{ display: "none" }}>
                                                <div className="bloc2">Restituer comme avoir </div>
                                                <div className="bloc2">
                                                    <label htmlFor="payment_given_back_via_credit" className="checkbox" style={{ display: "block" }}>
                                                        <input type="checkbox" name="payment_given_back_via_credit" id="payment_given_back_via_credit" defaultValue={1} autoComplete="off" />
                                                        <span className="checked" />
                                                    </label>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid_third">
                                        <div className="grid_1_3">
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_espece" data-shortcut_alias="submit" data-payment_method_id={41}>
                                                    Espèces (F12)
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="grid_1_3">
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_cheque" data-shortcut_alias="check" data-payment_method_id={42}>
                                                    Chèque
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="grid_1_3">
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_carte" data-shortcut_alias="debit_card" data-payment_method_id={43}>
                                                    Carte bancaire
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="grid_1_3">
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_lettre" data-payment_method_id={44}>
                                                    Lettre de change
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="grid_1_3" style={{ display: "none" }}>
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_avoir" data-shortcut_alias="credit" data-payment_method_id={46}>
                                                    Avoir
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="grid_1_3">
                                            <div className="bloc">
                                                <Link to={"save"} className="pay-type payment_method_button bloc_autre" data-payment_method_id={47}>
                                                    Autre
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ApproveInvoices;
