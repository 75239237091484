import React, { Component } from "react";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import AclDAO from "../DAO/AclDAO";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import FromLoading from "../helpers/FromLoading";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import CustomSelect from "../components/CustomSelect";
import EmailDAO from "../DAO/EmailDAO";
class AddOutgoingEmailModelPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            fields: {
                name: "",
                content: "",
                status: "true"
            },
            fieldsError: {
                name: false,
                content: false,
                status: false,
            },
            loading: false,
        };
    }
    componentDidMount() {
        const id = this.props.match.params.id;
        id && this.get();
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Sauvegarder", onClick: () => (id ? this.update() : this.add()) }] });
    }
    get = async () => {
        try {
            const id = this.props.match.params.id;
            const response = await EmailDAO.getEmailModelById(id, {});
            this.setFields("name", response.data.response.name);
            this.setFields("content", response.data.response.content.replaceAll("<br>", "\n"));
            this.setFields("status", String(response.data.response.status));
            console.log("response details", response)
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async add() {
        const { fields } = this.state;
        try {
            if (fields.name.length !== 0 && fields.content.length !== 0 && fields.status.length !== 0) {
                const params = {};
                const data = {
                    ...fields,
                    status: (fields.status === "true"),
                };
                this.setState({ loading: true });
                const response = await EmailDAO.addEmailModel(params, data);
                console.log("addOutgoing", response)
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.email.addModel, type: "success" });
                    this.props.history.push(RoutesPath.setting.email_params.outgoing_email.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                if (fields.name.length === 0) {
                    this.setFieldsError("name", true)
                }
                if (fields.content.length === 0) {
                    this.setFieldsError("content", true)
                }
                if (fields.status.length === 0) {
                    this.setFieldsError("status", true)
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }
    async update() {
        const { fields } = this.state;
        try {
            const id = this.props.match.params.id;
            if (fields.name.length !== 0 && fields.content.length !== 0 && fields.status.length !== 0) {
                const params = {};
                const data = {
                    ...fields,
                    status: (fields.status === "true"),
                };
                this.setState({ loading: true });
                const response = await EmailDAO.updateEmailModel(id, params, data);
                console.log("response update", response)
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.email.updateModel, type: "success" });
                    this.props.history.push(RoutesPath.setting.email_params.outgoing_email.list);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            } else {
                if (fields.name.length === 0) {
                    this.setFieldsError("name", true)
                }
                if (fields.content.length === 0) {
                    this.setFieldsError("content", true)
                }
                if (fields.status.length === 0) {
                    this.setFieldsError("status", true)
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }
    setFields(field, value) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: value,
            },
            fieldsError: {
                ...prevState.fieldsError,
                [field]: false,
            },
        }));
    }
    setFieldsError(field, value) {
        this.setState((prevState) => ({
            ...prevState,
            fieldsError: {
                ...prevState.fieldsError,
                [field]: value,
            },
        }));
    }
    render() {
        const { fields, fieldsError, loading } = this.state;
        const id = this.props.match.params.id;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>{`Paramètres d'emails > Emails sortants > ${id ? "modifier" : "Ajouter"}`}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>{`Cette page vous permet ${id ? "de modifier" : "d'ajouter"} un modèle d'email.`}</Block_content_title>
                    </Block_content>
                </First_block>
                <Block>
                    {loading && <FromLoading />}
                    <div className="bloc_header">
                        <h2>{id ? "Modifier un modèle d'email" : "Ajouter un nouveau modèle"}</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Title_container>
                            <Title>Informations générales</Title>
                            <Bar></Bar>
                        </Title_container>
                        <FormContainer>
                            <Left>
                                <InputContainer>
                                    <CustomInput error={fieldsError.name} value={fields.name} onChange={(Value) => {
                                        this.setFields("name", Value.target.value);
                                        this.setFieldsError("name", false);
                                    }} id="name" label="Nom *"></CustomInput>
                                </InputContainer>
                            </Left>
                            <Right>
                                <InputContainer>
                                    <CustomSelect
                                        error={false}
                                        onChange={(value) => {
                                            this.setFields("status", value.target.value);
                                            this.setFieldsError("status", false);
                                        }}
                                        selectedValue={fields.status}
                                        label="Statut*"
                                    >
                                        <option value="true" defaultValue>Actif</option>
                                        <option value="false">Inactif</option>
                                    </CustomSelect>
                                </InputContainer>
                            </Right>
                        </FormContainer>
                        <FormContainer_2>
                            <FullRow>
                                <CustomInput
                                    type="textarea"
                                    error={false}
                                    onChange={(Value) => {
                                        this.setFields("content", Value.target.value);
                                        this.setFieldsError("content", false);
                                    }}
                                    value={fields.content}
                                    defaultValue={fields.content}
                                    id="content"
                                    label="Modèle"
                                ></CustomInput>
                            </FullRow>
                        </FormContainer_2>
                    </Block_content>
                </Block>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(AddOutgoingEmailModelPage);

const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 10px;
    position: relative;
`;

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;

const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;

const Row = styled.div`
    display: block;
    margin-bottom: 20px;
    width: 100%;
`;

const Col_1 = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;

const Title_container = styled.div`
    position: relative;
`;

const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;

const InputContainer = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "41px")};
    margin-bottom: 18px;
`;
export const Left = styled.div`
    padding-top: 38px;
    width: 48%;
`;
export const FullRow = styled.div`
    padding-top: 10px;
    width: 98%;
`;
export const Right = styled.div`
    padding-top: 38px;
    width: 48%;
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-around")};
`;
export const FormContainer_2 = styled.div`
    display: flex;
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-around")};
`;