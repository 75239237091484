import React, { Component } from "react";
import styled from "styled-components";

class CustomFileInput extends Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.state = {
            fileName: "",
        };
    }
    render() {
        const { error, value, errorMessage } = this.props;
        return (
            <Container>
                <InputContainer>
                    <Input2 borderColor={error ? "#FF068B" : "#bbc8d3"} name="input" className={"input-login"} htmlFor={this.props.id} onClick={() => this.fileRef.current.click()} required>
                        <FileNameText>{value}</FileNameText>
                    </Input2>
                    <Input ref={this.fileRef} borderColor={error ? "#FF068B" : "#bbc8d3"} type={"file"} name="input" autoFocus={this.props.autoFocus} className={"input-login"} onChange={this.props.onChange} id={this.props.id} placeholder={this.props.placeholder} required />
                    {!value || (value && value.length === 0) ? (
                        <Label color={error ? "#FF068B" : "#284358"} id="input-label" htmlFor={this.props.id} className="label-login">
                            {this.props.label}
                        </Label>
                    ) : (
                        <ValidLabel id="input-label" htmlFor={this.props.id} className="label-login">
                            {this.props.label}
                        </ValidLabel>
                    )}
                </InputContainer>
                {error && (
                    <ErrorContainer>
                        <svg aria-hidden="true" width="16.035" height="10.86" focusable="false" data-prefix="fas" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#FF068B"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                            ></path>
                        </svg>
                        <ErrorText>{errorMessage ? errorMessage : "Ce champ est obligatoire"}</ErrorText>
                    </ErrorContainer>
                )}
            </Container>
        );
    }
}
const Container = styled.div`
    width: 100%;
    height: 100%;
`;
const FileNameText = styled.div`
    ${"" /* font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #494949;
    font-size: 12px;
`;
const InputContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;
const Label = styled.label`
    position: absolute;
    left: 2%;
    top: 34%;
    ${"" /* font-family: Roboto;
    font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;

    font-weight: 600;
    color: #494949;
    font-size: 12px;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    color: ${(props) => props.color};
`;
const ValidLabel = styled.label`
    position: absolute;
    font-family: "Open Sans", sans-serif;
    background-color: #fff;
    top: -7px;
    width: auto;
    left: 1%;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
    color: ${(props) => props.color};
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    color: #00beb0;
`;
const Input2 = styled.div`
    padding-top: 11px;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    ${"" /* font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;

    font-weight: 600;
    color: #494949;
    font-size: 12px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px ${(props) => props.borderColor};
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &::valid-within {
        border: solid 1px #00beb0;
    }
    &:focus-within ~ ${Label}, &:valid-within ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: ${(props) => props.color};
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const Input = styled.input`
    visibility: hidden;
    width: 100%;
    height: 100%;
    resize: none;
    margin: 0;
    outline: none;
    ${"" /* font-size: 14px; */}
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #494949;
    font-size: 12px;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px ${(props) => props.borderColor};
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
    &:focus ~ ${Label}, &:valid ~ ${Label} {
        background-color: #fff;
        top: -10%;
        width: auto;
        left: 1%;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 11px;
        color: ${(props) => props.color};
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
    &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
        transition: all 0.1s ease;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }
`;
const ErrorContainer = styled.div``;
const ErrorText = styled.div`
    display: inline-block;
    color: #ff068b;
    font-size: 10px;
    vertical-align: top;
`;
export default CustomFileInput;
