import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactsTable from "../components/ContactsTable";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";

class ContactsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    componentDidMount() {
        document.title = "Contacts - Sobrus Labs";
    }

    render() {
        return (
            <Main_container className="Main_container">
                <ContactsTable {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(ContactsPage);
