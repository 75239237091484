import React from "react";
import styled, { keyframes } from "styled-components";

const CircleLoading = () => {
    return (
        <div>
            <CircleContainer>
                <C1></C1>
                <C2></C2>
                <C3></C3>
            </CircleContainer>
        </div>
    );
};

const Circle = styled.div`
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-right: 6px;
    border-radius: 50%;
`;
const CircleContainer = styled.div`
    text-align: center;
`;
const animationName1 = keyframes`
   0%, 10%, 20%, 30% {
        transform: translateX(0);
      }
      40%, 50%, 60%,70% {
        transform: translateX(36px);
      }
      80%, 90%,100% {
        transform: translateX(0);

      }
`;
const C1 = styled(Circle)`
    background-color: #00b2d8;
    animation: ${animationName1} 2.5s infinite;
`;
const animationName2 = keyframes`
  0% {
        transform: translateX(0);
      }
    10%, 20%, 30% {
        transform: translateX(18px);

      }
      40%, 50%, 60%,70% {
        transform: translateX(-18px);

      }
     80%, 90%,100% {
        transform: translateX(0);

      }
`;
const C2 = styled(Circle)`
    background-color: #f4ac2f;
    animation: ${animationName2} 2.5s infinite;
`;
const animationName3 = keyframes`
  0% {
        transform: translateX(0);
      }
      10%, 20%, 30% {
        transform: translateX(-17px);

      }
      40%, 50%, 60% ,70%{
        transform: translateX(-17px);

      }
      80%, 90%,100% {
        transform: translateX(0);

      }
`;
const C3 = styled(Circle)`
    background-color: #00beb0;
    animation: ${animationName3} 2.5s infinite;
`;
export default CircleLoading;
