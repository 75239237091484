import React from "react";
import CircleLoading from "../components/CircleLoading";
import styled from "styled-components";

const FromLoading = () => {
    return (
        <LoadingContainer>
            <CircleLoading />
        </LoadingContainer>
    );
};
const LoadingContainer = styled.div`
    position: absolute;
    background-color: rgba(229, 229, 229, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default FromLoading;
