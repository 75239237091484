import React, { Component } from "react";
import "./MobileVersion.css";
import { FaGooglePlay, FaAppStore } from "react-icons/fa";
import { AiFillApple } from "react-icons/ai";
class MobileVersion extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="MobileVersion__container">
                <img className="ErrorPage__img" src={require("../../images/login_salim.png")} alt="dataLoadingError" />
                <p>Téléchargez l’application mobile pour une meilleure expérience sur Sobrus Labs</p>

                <div className="MobileVersion__btn-container">
                    <a className="MobileVersion__btn" href="https://play.google.com/store/apps/details?id=com.sobrus_labs_app_2" target="_blank">
                        <img className="MobileVersion__playStore-img" src={require("../../images/playstore.svg")} alt="play store " />
                        <div>
                            <span>Disponible sur</span> <span>Play Store</span>
                        </div>
                    </a>
                    <a className="MobileVersion__btn" href="https://apps.apple.com/us/app/sobrus-labs/id1501311531">
                        <AiFillApple size={45} color="#fff" />
                        <div>
                            <span>Disponible sur</span> <span>App Store</span>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default MobileVersion;
