import React, { Component } from "react";

import styled from "styled-components";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BsExclamationCircleFill } from "react-icons/bs";

import ee from "event-emitter";

const emitter = new ee();

export const notify = ({ msg, type }) => {
    emitter.emit("notification", { msg, type });
};
class FlashMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            message: { msg: "", type: "" },
        };
        this.timeout = null;
        emitter.on("notification", ({ msg, type }) => {
            this.onShow({ msg, type });
        });
    }
    componentDidMount() { }
    renderMessageStyle() {
        const { message } = this.state;
        if (message.type === "danger") {
            return (
                <ErrorMessage bgColor="#FF4A4A">
                    <RiCloseCircleFill size={20} color="#fff" />
                    <ErrorMessageText>{message.msg}</ErrorMessageText>
                </ErrorMessage>
            );
        } else if (message.type === "success") {
            return (
                <ErrorMessage bgColor="#52d879">
                    <IoIosCheckmarkCircle size={20} color="#fff" />
                    <ErrorMessageText>{message.msg}</ErrorMessageText>
                </ErrorMessage>
            );
        } else if (message.type === "warning") {
            return (
                <ErrorMessage bgColor="#eda224">
                    <BsExclamationCircleFill size={20} color="#fff" />
                    <ErrorMessageText>{message.msg}</ErrorMessageText>
                </ErrorMessage>
            );
        }
    }
    onShow({ msg, type }) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.setState({ height: 0 }, () => {
                this.timeout = setTimeout(() => {
                    this.showNotification({ msg, type });
                }, 300);
            });
        } else {
            this.showNotification({ msg, type });
        }
    }
    showNotification = ({ msg, type }) => {
        this.setState(
            {
                height: 50,
                message: { msg: typeof msg === "string" ? msg : "Dev error", type },
            },
            () => {
                this.timeout = setTimeout(() => {
                    this.setState({ height: 0 });
                }, 5000);
            }
        );
    };
    render() {
        const { height } = this.state;
        return <Container height={height}>{this.renderMessageStyle()}</Container>;
    }
}

const ErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: ${(props) => props.bgColor};
`;
const ErrorMessageText = styled.div`
    color: #fff;
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
`;
const Container = styled.div`
    width: 100%;
    height: ${(props) => props.height + "px"};
    transition: height 0.3s;
    overflow: hidden;
`;
export default FlashMessage;
