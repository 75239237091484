import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";

import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ProductDAO from "../DAO/ProductDAO";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";

class DetailsProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            successMessage: "",
        };
    }
    componentDidMount() {
        document.title = "Produits - Sobrus Labs";

        this.get();
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ProductDAO.getProductById(id);
            this.setState({ product: response.data.produit }, () => this.state.product);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    translate(param) {
        if (param === true) {
            return "Oui";
        } else if (param === false) {
            return "Non";
        } else {
            return "Aucun";
        }
    }

    render() {
        const { product, successMessage } = this.state;
        const title = product && product.nom;
        const id = this.props.match.params.id;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={"Produit : " + title}>
                    {acl.product.suggestion.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.product.suggestion.add}/${id}`)}>
                            Suggérer une modification
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        {/* <Block>
                            <div className="bloc_header">
                                <h2>Informations générales</h2>
                                <div className="bloc_header_btns">
                                </div>
                            </div>
                            <Block_content></Block_content>
                        </Block> */}
                        <div style={{ minWidth: "1014px" }} className="bloc">
                            <div className="bloc_header">
                                <h2>Informations générales</h2>
                            </div>
                            <div className="bloc_content">
                                <div className="general_infos_wrapper">
                                    <em className="general_infos_thumb">
                                        {/* <img src="https://ma.pharma.sobrus.com/uploads/ma/products/thumbnails/1582648612_20030_574c8d39fc816b87435e1e114836edb714.png" style={{ maxHeight: '100%!important', height: 'auto!important', position: 'relative', top: '50%', transform: 'translateY(-50%)' }} /> */}
                                        {/* <img
                                            src={product && product.photo ? `https://ma.pharma.sobrus.com/uploads/ma/products/thumbnails/${product.photo}` : require("../images/product_default_image.png")}
                                            style={{ maxHeight: "100%!important", height: "auto!important", position: "relative", top: "50%", transform: "translateY(-50%)", objectFit: "contain" }}
                                            alt={product && product.nom}
                                        /> */}
                                        <img src={require("../images/product_default_image.png")} style={{ maxHeight: "100%!important", height: "auto!important", position: "relative", top: "50%", transform: "translateY(-50%)", objectFit: "contain" }} alt={product && product.nom} />
                                    </em>
                                    <div className="general_infos_cnt">
                                        <div className="general_infos_left">
                                            <div className="general_infos_row">
                                                <span>Nom</span>
                                                <p>{product && product.nom && product.nom}</p>
                                            </div>
                                            <div className="general_infos_row">
                                                <span>Prix Public de Vente</span>
                                                <p>{product && product.ppv && `${parseFloat(product.ppv).toFixed(2)} DHS`}</p>
                                            </div>
                                            <div className="general_infos_row">
                                                <span>PPH</span>
                                                <p>{product && product.pph && `${parseFloat(product.pph).toFixed(2)} DHS`}</p>
                                            </div>
                                        </div>
                                        <div className="general_infos_right">
                                            <div className="general_infos_row">
                                                <span>DCI</span>
                                                <p>{product && product.dci && product.dci}</p>
                                            </div>
                                            <div className="general_infos_row">
                                                <span>Nécessite une prescription</span>
                                                <p>
                                                    <i className="sprite-table-nonvalid" />
                                                    {product && this.translate(product.necessite_prescription)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>
                                            {/* <Field_value_green>{contact && contact.gestionnaire}</Field_value_green> */}
                                            <Field_value>{product && product.nom && product.nom}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Code barre</Field_label>
                                            <Field_value>{product && product.code_barre && product.code_barre}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Code barre 2</Field_label>
                                            <Field_value>{product && product.code_barre2 && product.code_barre2}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Catégorie</Field_label>

                                            <Field_value>{product && product.categorie && product.categorie + (product.categorie_margin ? ` (${parseFloat(product.categorie_margin).toFixed(3)}%)` : product.categorie_fixed_margin ? ` (${product.categorie_fixed_margin})` : "")}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Classe thérapeutique</Field_label>
                                            <Field_value>{product && product.classe_therapeutique && product.classe_therapeutique}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Forme galénique</Field_label>
                                            <Field_value>{product && product.forme_galenique && product.forme_galenique}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>produit tableau</Field_label>
                                            <Field_value>{product && product.produit_tableau}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>DCI</Field_label>
                                            <Field_value>{product && product.dci && product.dci}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gamme</Field_label>
                                            <Field_value>{product && product.game && product.game}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Sous-gamme</Field_label>
                                            <Field_value>{product && product.sousgamme && product.sousgamme}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nécessite une prescription</Field_label>
                                            <Field_value>{product && this.translate(product.necessite_prescription)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Laboratoire</Field_label>
                                            <Field_value>{product && product.laboratoire && product.laboratoire}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Produit marché</Field_label>
                                            <Field_value>{product && this.translate(product.produit_marche)}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations du stock</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>PPH</Field_label>
                                            <Field_value>{product && product.pph && `${parseFloat(product.pph).toFixed(2)} DHS`}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Prix Public de Vente</Field_label>
                                            <Field_value>{product && product.ppv && `${parseFloat(product.ppv).toFixed(2)} DHS`}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Taxe sur achat</Field_label>
                                            <Field_value>{product && product.taxe_achat !== undefined && `TVA (${parseFloat(product.taxe_achat).toFixed(2)}%)`}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Taxe sur vente</Field_label>
                                            <Field_value>{product && product.taxe_vente !== undefined && `TVA (${parseFloat(product.taxe_vente).toFixed(2)}%)`}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Est remboursable</Field_label>
                                            <Field_value>{product && this.translate(product.est_remboursable)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Base de remboursement</Field_label>
                                            <Field_value>{product && product.base_remboursement && product.base_remboursement}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations descriptives</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Présentation</Field_label>
                                            <Field_value>{product && product.presentation && product.presentation}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Excipients</Field_label>
                                            <Field_value>{product && product.excipients && product.excipients}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Posologie pour Adulte</Field_label>
                                            <Field_value>{product && product.posologie_adulte && product.posologie_adulte}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Posologie pour Enfant</Field_label>
                                            <Field_value>{product && product.posologie_enfant && product.posologie_enfant}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Indications</Field_label>
                                            <Field_value>{product && product.indications && product.indications}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contre-indication conduite</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_conduite)}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Contre-indication allaitement</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_allaitement)}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Contre-indication grossesse</Field_label>
                                            <Field_value>{product && this.translate(product.contre_indication_grossesse)}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Référence interne laboratoire</Field_label>
                                            <Field_value>{product && product.ref_interne_laboratoire && product.ref_interne_laboratoire}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Conditionnement</Field_label>
                                            <Field_value>{product && product.conditionnement && product.conditionnement}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Monograph</Field_label>
                                            <Field_value>{product && product.monograph && product.monograph}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Description</Field_label>
                                            <Field_value>{product && product.description && product.description}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                        <Block_contact>
                            <Title_contact>Galerie</Title_contact>
                            <Btn_contact_container></Btn_contact_container>
                        </Block_contact>
                    </Left_block_container>
                    <Right_block_container>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>
                                    <Col_1_li>{product && product.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>
                                    <Col_1_li>{product && moment(product.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>
                                    <Col_1_li>{product && product.mis_a_jour_par ? product && product.mis_a_jour_par : product && product.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>
                                    <Col_1_li>{product && product.mis_a_jour_le ? product && moment(product.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : product && moment(product.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsProductPage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
    min-width: 1014px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
