import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import PharmaciesTable from "../components/PharmaciesTable";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";

class PharmaciesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Ressources - Sobrus Labs";
        //(this.props)
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Pharmacies">
                    {acl.resource.suggestion.pharmacy.list() && (
                        <a onClick={() => this.props.history.push(`${RoutesPath.resource.suggestion.pharmacy.list}`)} className="btn_white">
                            Historique des suggestions
                        </a>
                    )}
                    {acl.resource.suggestion.pharmacy.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.resource.suggestion.pharmacy.add}`)}>
                            Suggérer une nouvelle pharmacie
                        </button>
                    )}
                </TopOptions>

                <PharmaciesTable {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(PharmaciesPage);
