import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import EventListTable from "../components/EventListTable";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import PlanningDAO from "../DAO/PlanningDAO";
import { RoutesPath } from "../values/RoutesPath";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import "../components/PlanningModal/PlanningModal.css";
import { notify } from "../helpers/FlashMessage";
import { CgClose } from "react-icons/cg";
import { HiChevronRight } from "react-icons/hi";
import { MessagesText } from "../values/MessagesText";
import { acl } from "../services/acl";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: 0,
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
    },
};
Modal.setAppElement("#root");

class DetailsPlanningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planning: null,
            events: [],
            successMessage: "",
            is_loaded: false,
            addEventsModal: true,
        };
    }
    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        this.setState({ addEventsModal: false });
        this.get();
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await PlanningDAO.getPlanningById(id, {});
            this.setState({ planning: response.data.planning, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }



    async delete() {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const planning_id = this.props.match.params.id;
                const data = {
                    disabled: 1,
                };
                const response = await PlanningDAO.deletePlanning(planning_id, {}, data);
                // this.props.history.push(`${RoutesPath.planning.list}`);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.delete, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore() {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const planning_id = this.props.match.params.id;
                const data = {
                    disabled: 0,
                };
                const response = await PlanningDAO.deletePlanning(planning_id, {}, data);
                // this.props.history.push(`${RoutesPath.planning.list}`);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.restore, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    formateDate(date) {
        return moment(date).format("YYYY-MM-DD");
    }
    render() {
        const { planning, is_loaded, successMessage } = this.state;
        const title = planning ? planning.nom : "";
        const id = this.props.match.params.id;
        return (
            <Main_container className="Main_container">
                {this.getModal()}
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions onClick={() => this.props.history.push(`${RoutesPath.planning.duplicate}/${id}`)} title={"Planning : " + title}>
                    {is_loaded && !planning.disabled && acl.planning.delete() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.delete()}>
                            Archiver
                        </button>
                    )}
                    {is_loaded && !planning.disabled && acl.planning.duplicate() && (
                        <button className="btn_white" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.planning.duplicate}/${id}`)}>
                            Dupliquer
                        </button>
                    )}

                    {is_loaded && !planning.disabled && acl.planning.event_to_planning() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.setState({ addEventsModal: true })}>
                            Ajouter des événements dans planning
                        </button>
                    )}
                    {is_loaded && !planning.disabled && acl.planning.update() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.planning.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                    {is_loaded && planning.disabled && acl.planning.restore() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.restore(id)}>
                            Restaurer
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title>Informations générales</Title>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{planning && planning.nom}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Date début</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{planning && planning.date_debut && this.formateDate(planning.date_debut)}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Date fin</Field_label>
                                            {is_loaded ? (
                                                <Field_value>{planning && planning.date_fin && this.formateDate(planning.date_fin)}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Gestionnaire</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{planning && planning.gestionnaire}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>

                        {acl.planning.events() && planning && planning.id && (
                            <EventListTable
                                noEventsCas={
                                    <Block_contact>
                                        <Title_contact>Événements</Title_contact>
                                        <Btn_contact_container>{acl.planning.event_to_planning() && <Create_contact_btn onClick={() => this.setState({ addEventsModal: true })}>Gérer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                planningId={planning.id}
                                onClickCreate={() => this.setState({ addEventsModal: true })}
                                {...this.props}
                            />
                        )}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={planning && planning.disabled ? "bloc_content devis_statut ds_brouillon" : "bloc_content devis_statut ds_complete"}>
                                <h3>{planning && planning.disabled ? "Archivé" : "Actif"}</h3>
                            </div>
                        </div>
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{planning && planning.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{planning && moment(planning.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{planning && planning.mis_a_jour_par ? planning && planning.mis_a_jour_par : planning && planning.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{planning && planning.mis_a_jour_le ? planning && moment(planning.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : planning && moment(planning.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
    getModal() {
        const { addEventsModal } = this.state;
        const id = this.props.match.params.id;
        return (
            <Modal isOpen={addEventsModal} onAfterOpen={() => { }} onRequestClose={() => { }} style={customStyles} contentLabel="">
                <div className="PlanningModal_container">
                    <div className="PlanningModal_header">
                        <h1>Ajouter des événements à ce planning</h1>
                        <button onClick={() => this.setState({ addEventsModal: false })} className="close">
                            <CgClose color="#fff" size={20} />
                        </button>
                    </div>
                    <div className="PlanningModal_body">
                        <Link to={`${RoutesPath.planning.event_to_planning}/${id}`} className="close">
                            Depuis des événements existants
                            <HiChevronRight color="#00beb0" size={20} />
                        </Link>
                        <Link to={`${RoutesPath.planning.new_event_to_planning}/${id}`} className="close">
                            En créant de nouveaux événements
                            <HiChevronRight color="#00beb0" size={20} />
                        </Link>
                    </div>
                    <div className="PlanningModal_footer">
                        <button onClick={() => this.setState({ addEventsModal: false })} className="close">
                            Annuler
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsPlanningPage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
