import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ResourceDAO from "../DAO/ResourceDAO";
import { notify } from "../helpers/FlashMessage";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";
const TITLE = {
    "pharmacie": "Pharmacie",
    "etablissement": "Établissement",
    "association": "Association",

}
class DetailsResourcePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pharmacy: null,
            successMessage: "",
        };
    }
    componentDidMount() {
        document.title = "Ressources - Sobrus Labs";

        this.get();
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const resourceName = this.props.match.params.resourceName;
            const response = await ResourceDAO.getResourceById(resourceName, id, {});

            this.setState({ pharmacy: response.data[resourceName] });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const id = this.props.match.params.id;

        const { pharmacy, successMessage } = this.state;
        const title = pharmacy ? pharmacy.nom : "";
        const resourceName = this.props.match.params.resourceName;
        let goTo = "";
        if (resourceName === "pharmacie" && acl.resource.suggestion.pharmacy.add()) {
            goTo = RoutesPath.resource.suggestion.pharmacy.add;
        } else if (resourceName === "etablissement" && acl.resource.suggestion.healthcareestablishments.add()) {
            goTo = RoutesPath.resource.suggestion.healthcareestablishments.add;
        } else if (resourceName === "association" && acl.resource.suggestion.associations.add()) {
            goTo = RoutesPath.resource.suggestion.associations.add;
        }
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={TITLE[resourceName] + " : " + title}>
                    {goTo.length !== 0 && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${goTo}/${id}?q=${window.btoa("suggest a modification")}`)}>
                            Suggérer une modification
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        {/* <Block>
                            <div className="bloc_header">
                                <h2>Informations générales</h2>
                                <div className="bloc_header_btns">
                                </div>
                            </div>
                            <Block_content></Block_content>
                        </Block> */}
                        {resourceName === "pharmacie" && (
                            <div style={{ minWidth: "1014px" }} className="bloc">
                                <div className="bloc_header">
                                    <h2>Informations générales</h2>
                                </div>
                                <div className="bloc_content">
                                    <div className="general_infos_wrapper">
                                        <em className="sprite-default-customer general_infos_thumb">{/* <img src="images/Costumers.jpg" alt=""> */}</em>
                                        <div className="general_infos_cnt">
                                            <div className="general_infos_left">
                                                <div className="general_infos_row">
                                                    <span>Nom</span>
                                                    <p>{pharmacy && pharmacy.nom}</p>
                                                </div>
                                            </div>
                                            <div className="general_infos_right">
                                                <div className="general_infos_row">
                                                    <span>Téléphone</span>
                                                    <p>{pharmacy && pharmacy.telephone}</p>
                                                </div>
                                            </div>
                                            <div className="general_infos_right">
                                                <div className="general_infos_row">
                                                    <span>Pharmacien</span>
                                                    <p>{""}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Type</Field_label>
                                            <Field_value>{pharmacy && pharmacy.type}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Nom</Field_label>
                                            <Field_value>{pharmacy && pharmacy.nom}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Téléphone</Field_label>
                                            <Field_value>{pharmacy && pharmacy.telephone}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Fax</Field_label>
                                            <Field_value>{pharmacy && pharmacy.fax}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Site internet</Field_label>
                                            <Field_value>{pharmacy && pharmacy.site_internet}</Field_value>
                                        </Col_1>
                                        {resourceName && resourceName === "etablissement" && (
                                            <Col_2>
                                                <Field_label>Spécialité</Field_label>
                                                <Field_value>{pharmacy && pharmacy.specialite}</Field_value>
                                            </Col_2>
                                        )}
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations légales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>RC</Field_label>
                                            <Field_value>{pharmacy && pharmacy.rc}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>INPE</Field_label>
                                            <Field_value>{pharmacy && pharmacy.inpe}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Adresse</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Localisation GPS</Field_label>
                                            <Field_value>{pharmacy && pharmacy.localisation_gps}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Adresse</Field_label>
                                            <Field_value>{pharmacy && pharmacy.adresse}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Secteur</Field_label>
                                            <Field_value>{pharmacy && pharmacy.secteur}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Ville</Field_label>
                                            <Field_value>{pharmacy && pharmacy.ville}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Région</Field_label>
                                            <Field_value>{pharmacy && pharmacy.region}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Code postal</Field_label>
                                            <Field_value>{pharmacy && pharmacy.code_postal}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Pays</Field_label>
                                            <Field_value>{pharmacy && pharmacy.pays}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                    </Left_block_container>
                    <Right_block_container>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>
                                    <Col_1_li>{pharmacy && pharmacy.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>
                                    <Col_1_li>{pharmacy && pharmacy.cree_le ? moment(pharmacy.cree_le).format("YYYY-MM-DD HH:mm") : ""}</Col_1_li>

                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>
                                    <Col_1_li>{pharmacy && pharmacy.mis_a_jour_par ? pharmacy && pharmacy.mis_a_jour_par : pharmacy && pharmacy.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>
                                    <Col_1_li>{pharmacy && pharmacy.mis_a_jour_le ? pharmacy && moment(pharmacy.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : pharmacy && moment(pharmacy.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsResourcePage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;

const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
