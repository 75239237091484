import React, { Component } from "react";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import NoResultCas from "../helpers/NoResultCas";
import ContentLoader from "react-content-loader";
import img from "../images/sprite_3.png";
import AccountDAO from "../DAO/AccountDAO";
import CommonDAO from "../DAO/CommonDAO";
import SearchSelect from "../helpers/SearchSelect";
import { RoutesPath } from "../values/RoutesPath";
import DataLoadingError from "../components/DataLoadingError";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import { connect } from "react-redux";
import { acl } from "../services/acl";
import TopOptions from "./TopOptions";
import configuration from "../config/configuration";
class AccountsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            sectors: [],
            data: [],

            pageNumber: 1,
            totalItems: 0,
            search: false,
            advancedSearch: false,

            account_type: "",
            code: "",
            wording: "",
            category: "",
            city: "",
            sector: "",

            account_type_operator: "is",
            code_operator: "like",
            wording_operator: "like",
            category_operator: "is",
            city_operator: "is",
            sector_operator: "is",

            is_loaded: false,
            error: {
                data_loading: false,
                message: "",
            },
            selected_city: {},
            selected_sector: {},

            orderBy: 0,
            key: "",
            disabledData: 0,
        };
    }

    componentDidMount() {
        // const compteSearch = JSON.parse(this.getCookie("compteSearch"));
        // console.log(compteSearch);
        // if (compteSearch) {
        //     this.setState(
        //         {
        //             is_loaded: false,
        //             search: compteSearch.basic.open,
        //             advancedSearch: compteSearch.advanced.open,
        //             ...compteSearch.basic.data,
        //             ...compteSearch.advanced.data,
        //         },
        //         () => this.getAll()
        //     );
        // } else {
        this.preload();
        this.setState(
            {
                is_loaded: false,
            },
            () => this.getAll()
        );
        // }
    }


    async getAll() {
        try {
            const { disabledData } = this.state;
            this.setLoadingError({ data_loading: false, message: "" });

            const { orderBy, key, pageNumber, account_type, code, wording, category, city, sector, advancedSearch, search, account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator } = this.state;

            //get simple or advanced search  criterion

            let data = AccountDAO.getSearchData({ account_type, code, wording, category, city, sector, advancedSearch, search, account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator });

            data["disabled"] = {
                value: disabledData,
                op: "is",
            };
            if (orderBy !== 0 && key.length !== 0) {
                data["orderby"] = {
                    col: key,
                    dir: orderBy === 1 ? "ASC" : "DESC",
                };
            } else {
                data["orderby"] = {
                    col: "id",
                    dir: "DESC",
                };
            }

            //get all accounts
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ is_loaded: true, data: response.data, totalItems: response.data.total });
        } catch (error) {
            this.setState({ is_loaded: true });
            this.setLoadingError({ data_loading: true, message: error.message });
        }
    }
    setLoadingError(error) {
        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...prevState.error,
                data_loading: error.data_loading,
                message: error.message,
            },
        }));
    }
    // async getCites(inputValue = null, callback = () => {}) {
    //     try {
    //         let searchData = " ";
    //         if (inputValue) {
    //             searchData = inputValue;
    //         }
    //         const response = await CommonDAO.getAllCities(1, {}, searchData);
    //         this.setState({ cities: response.data.ressources });
    //         const convertedArray = CommonFunctions.convertDataForSelectBox(response.data.ressources, "id", "nom");
    //         callback(convertedArray);
    //     } catch (error) {
    //         (error);
    //     }
    // }

    // async getSectors() {
    //     try {
    //         const response = await CommonDAO.getAllSectors(1, {});
    //         this.setState({ sectors: response.data.ressources });
    //     } catch (error) {
    //         (error);
    //     }
    // }

    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await AccountDAO.deleteAccount(id, {}, data);

                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.delete, type: "success" });
                    this.getAll();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await AccountDAO.deleteAccount(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.restore, type: "success" });
                    this.getAll();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    reverse(property) {
        const sortedData = CommonDAO.reverseData(this.state.data.comptes, property);
        const data = this.state.data;
        data.comptes = [...sortedData];
        this.setState({ data });
    }

    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }

    resetSearch() {
        this.setState(
            {
                is_loaded: false,
                account_type: "",
                code: "",
                wording: "",
                category: "",
                city: "",
                sector: "",

                account_type_operator: "is",
                code_operator: "like",
                wording_operator: "like",
                category_operator: "is",
                city_operator: "is",
                sector_operator: "is",

                selected_city: {},
                selected_sector: {},
            },
            () => this.getAll()
        );
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    componentWillUnmount() {
        this.storeSearchInCookies();
        window.removeEventListener('beforeunload', this.storeSearchInCookies);
    }

    preload = () => {
        this.getSearchFromCookies();
        window.addEventListener('beforeunload', this.storeSearchInCookies);
    }
    render() {
        const { disabledData, key, code, wording, error, is_loaded, data, pageNumber, totalItems, search, account_type, category, advancedSearch, account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator, orderBy } = this.state;
        return (
            <div>
                <TopOptions title="Comptes">
                    <button
                        className="btn_white"
                        data-shortcut_alias="create"
                        onClick={() => {
                            this.setState({ disabledData: disabledData === 1 ? 0 : 1 }, () => this.getAll());
                        }}
                    >
                        {disabledData === 0 ? " Archives" : "Actuel"}
                    </button>
                    {/* if disabledData === 0 the create btn should not mounted  */}
                    {disabledData === 0 && acl.account.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(RoutesPath.account.add)}>
                            Créer
                        </button>
                    )}
                </TopOptions>

                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>{disabledData === 0 ? "Liste des comptes" : "Liste des comptes archivés"}</h2>
                        <div className="bloc_header_btns">
                            <a onClick={() => this.setState({ advancedSearch: false, search: search ? false : true })} className="sprite-bloc-search" />
                            <a onClick={() => this.resetSearch()} className="sprite-bloc-reload"></a>
                            <a onClick={() => this.setState({ search: false, advancedSearch: advancedSearch ? false : true })} href="#" className="sprite-bloc-star"></a>
                        </div>
                    </div>
                    <TableWrapper className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                {advancedSearch && (
                                    <SearchTr className="table_th_orderable">
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ account_type_operator: value.target.value });
                                                    }}
                                                    selectedValue={account_type_operator}
                                                    label="Type"
                                                >
                                                    <option value="is" defaultValue>
                                                        est
                                                    </option>
                                                    <option value="is_not">n'est pas</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ code_operator: value.target.value });
                                                    }}
                                                    selectedValue={code_operator}
                                                    label="Code"
                                                >
                                                    <option value="like" defaultValue>
                                                        contient
                                                    </option>
                                                    <option value="not_like">ne contient pas</option>
                                                    <option value="starts_with">commence par</option>
                                                    <option value="end_by">se termine par</option>
                                                    <option value="is">est</option>
                                                    <option value="is_not">n'est pas</option>
                                                    <option value="null">est vide</option>
                                                    <option value="not_null">n'est pas vide</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ wording_operator: value.target.value });
                                                    }}
                                                    selectedValue={wording_operator}
                                                    label="Nom"
                                                >
                                                    <option value="like" defaultValue>
                                                        contient
                                                    </option>
                                                    <option value="not_like">ne contient pas</option>
                                                    <option value="starts_with">commence par</option>
                                                    <option value="end_by">se termine par</option>
                                                    <option value="is">est</option>
                                                    <option value="is_not">n'est pas</option>
                                                    <option value="null">est vide</option>
                                                    <option value="not_null">n'est pas vide</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ category_operator: value.target.value });
                                                    }}
                                                    selectedValue={category_operator}
                                                    label="Catégorie"
                                                >
                                                    <option value="is" defaultValue>
                                                        est
                                                    </option>
                                                    <option value="is_not">n'est pas</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ city_operator: value.target.value });
                                                    }}
                                                    selectedValue={city_operator}
                                                    label="Ville"
                                                >
                                                    <option value="is" defaultValue>
                                                        est
                                                    </option>
                                                    <option value="is_not">n'est pas</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ sector_operator: value.target.value });
                                                    }}
                                                    selectedValue={sector_operator}
                                                    label="Secteur"
                                                >
                                                    <option value="is" defaultValue>
                                                        est
                                                    </option>
                                                    <option value="is_not">n'est pas</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            {advancedSearch && (
                                                <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                                                    <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#fff"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Search_btn_icon>
                                                </Search_btn>
                                            )}
                                        </Th>
                                    </SearchTr>
                                )}

                                {(search || advancedSearch) && (
                                    <SearchTr className="table_th_orderable">
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ account_type: value.target.value });
                                                    }}
                                                    selectedValue={account_type}
                                                    label="Type"
                                                >
                                                    <option value="" defaultValue></option>
                                                    <option value="Client">Client</option>
                                                    <option value="Prospect">Prospect</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomInput
                                                    value={code}
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    onChange={(value) => {
                                                        this.setState({ code: value.target.value });
                                                    }}
                                                    label="Code"
                                                ></CustomInput>
                                                <Search_icon>
                                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Svg>
                                                </Search_icon>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomInput
                                                    value={wording}
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    onChange={(value) => {
                                                        this.setState({ wording: value.target.value });
                                                    }}
                                                    label="Nom"
                                                ></CustomInput>
                                                <Search_icon>
                                                    <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="currentColor"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Svg>
                                                </Search_icon>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <CustomSelect
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                    error={false}
                                                    onChange={(value) => {
                                                        this.setState({ category: value.target.value });
                                                    }}
                                                    selectedValue={category}
                                                    label="Catégorie"
                                                >
                                                    <option value="" defaultValue></option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                    <option value="E">E</option>
                                                    <option value="F">F</option>
                                                </CustomSelect>
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <SearchSelect
                                                    fetchedUrl="/api/villes"
                                                    HTTPMethod="get"
                                                    receivedObject="ressources"
                                                    optionLabelProperty="nom"
                                                    optionValueProperty="id"
                                                    value={this.state.selected_city}
                                                    placeholder="Ville"
                                                    onChange={(e) => {
                                                        this.setState({ selected_city: e, city: e && e.value });
                                                    }}
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            <Search_input_container>
                                                <SearchSelect
                                                    name="sector"
                                                    fetchedUrl="/api/secteurs"
                                                    HTTPMethod="get"
                                                    receivedObject="ressources"
                                                    optionLabelProperty="nom"
                                                    optionLabelProperty_2="ville"
                                                    optionValueProperty="id"
                                                    value={this.state.selected_sector}
                                                    placeholder="Secteur"
                                                    onChange={(e) => {
                                                        this.setState({ selected_sector: e, sector: e && e.value });
                                                    }}
                                                />
                                            </Search_input_container>
                                        </Th>
                                        <Th backgroundColor="#fff">
                                            {search && (
                                                <Search_btn
                                                    onClick={() => {

                                                        this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
                                                    }}
                                                >
                                                    <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#fff"
                                                            d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                                        ></path>
                                                    </Search_btn_icon>
                                                </Search_btn>
                                            )}
                                        </Th>
                                    </SearchTr>
                                )}
                                <tr className="table_th_orderable">
                                    <Th key="type_compte" orderBy={key === "type_compte" && orderBy} onClick={() => this.setState({ key: "type_compte", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "type_compte" && orderBy === -1) || orderBy === 0 || key !== "type_compte") && <OrderUp />}
                                        {((key === "type_compte" && orderBy === 1) || orderBy === 0 || key !== "type_compte") && <OrderDown />}
                                        Type
                                    </Th>
                                    <Th key="code" orderBy={key === "code" && orderBy} onClick={() => this.setState({ key: "code", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "code" && orderBy === -1) || orderBy === 0 || key !== "code") && <OrderUp />}
                                        {((key === "code" && orderBy === 1) || orderBy === 0 || key !== "code") && <OrderDown />}
                                        Code
                                    </Th>
                                    <Th key="libelle" orderBy={key === "libelle" && orderBy} onClick={() => this.setState({ key: "libelle", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "libelle" && orderBy === -1) || orderBy === 0 || key !== "libelle") && <OrderUp />}
                                        {((key === "libelle" && orderBy === 1) || orderBy === 0 || key !== "libelle") && <OrderDown />}
                                        Nom
                                    </Th>
                                    <Th key="categorie" orderBy={key === "categorie" && orderBy} onClick={() => this.setState({ key: "categorie", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "categorie" && orderBy === -1) || orderBy === 0 || key !== "categorie") && <OrderUp />}
                                        {((key === "categorie" && orderBy === 1) || orderBy === 0 || key !== "categorie") && <OrderDown />}
                                        Catégorie
                                    </Th>
                                    <Th key="ville" orderBy={key === "ville" && orderBy} onClick={() => this.setState({ key: "ville", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "ville" && orderBy === -1) || orderBy === 0 || key !== "ville") && <OrderUp />}
                                        {((key === "ville" && orderBy === 1) || orderBy === 0 || key !== "ville") && <OrderDown />}
                                        Ville
                                    </Th>
                                    <Th key="secteur" orderBy={key === "secteur" && orderBy} onClick={() => this.setState({ key: "secteur", orderBy: this.getOrderBy() }, () => this.getAll())}>
                                        {((key === "secteur" && orderBy === -1) || orderBy === 0 || key !== "secteur") && <OrderUp />}
                                        {((key === "secteur" && orderBy === 1) || orderBy === 0 || key !== "secteur") && <OrderDown />}
                                        Secteur
                                    </Th>
                                    <Th className="table_actions" />
                                </tr>
                            </thead>
                            {!is_loaded && (
                                <tbody>
                                    {[1, 2, 3].map((index) => {
                                        return (
                                            <Tr key={"key__" + index}>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td>
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                </Td>
                                                <Td className="table_action">
                                                    <a className="sprite-table-edit-btn  tooltip" title="Modifier" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </tbody>
                            )}
                            {is_loaded && data && data.comptes && data.comptes.length != 0 && (
                                <tbody>
                                    {data &&
                                        data.comptes.map((account, index) => {
                                            return (
                                                <Tr key={"key_" + index}>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.type_compte}
                                                    </Td>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.code}
                                                    </Td>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.libelle}
                                                    </Td>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.categorie}
                                                    </Td>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.ville}
                                                    </Td>
                                                    <Td
                                                        onClick={() => {
                                                            acl.account.details() && this.props.history.push(`${RoutesPath.account.details}/${account.id}`, { test: "tetttt" });
                                                        }}
                                                    >
                                                        {account.secteur}
                                                    </Td>
                                                    <Td className="table_action">
                                                        {disabledData === 0 && acl.account.update() && (
                                                            <a
                                                                onClick={() => {
                                                                    this.props.history.push(`${RoutesPath.account.update}/${account.id}`);
                                                                }}
                                                                className="sprite-table-edit-btn  tooltip"
                                                                title="Modifier"
                                                                data-confirm_msg
                                                                data-removeable_element="parents::tr"
                                                                target="_blank"
                                                            />
                                                        )}
                                                        {disabledData === 0 && acl.account.disable() && <a onClick={() => this.delete(account.id)} className="sprite-table-delete-btn  tooltip" title="Archiver" data-confirm_msg data-removeable_element="parents::tr" target="_blank" />}
                                                        {disabledData === 1 && acl.account.restore() && <a onClick={() => this.restore(account.id)} className="sprite-table-recover-btn restore_delivery_note tooltip" title="Restaurer" data-confirm_msg="" data-removeable_element="parents::tr"></a>}
                                                    </Td>
                                                </Tr>
                                            );
                                        })}

                                    <Tr>
                                        <Td colSpan={8} className="table_pagination">
                                            <div style={{ display: "inline-block", marginRight: "20px" }}>{/* <a href="#" onClick={() => { }} className="btn_white">Imprimer</a> */}</div>
                                            <div style={{ display: "inline-block" }}>
                                                {pageNumber > 1 && (
                                                    <a
                                                        className="sprite-table-prev-btn"
                                                        onClick={() => {
                                                            this.setState({ pageNumber: pageNumber - 1 }, () => {
                                                                this.getAll();
                                                            });
                                                        }}
                                                    />
                                                )}
                                                <span className="table_page">{pageNumber}</span>
                                                {pageNumber < totalItems / 20 && (
                                                    <a
                                                        className="sprite-table-next-btn"
                                                        onClick={() => {
                                                            this.setState({ pageNumber: pageNumber + 1 }, () => {
                                                                this.getAll();
                                                            });
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </Td>
                                    </Tr>
                                </tbody>
                            )}
                        </table>
                        {is_loaded && error.data_loading && <DataLoadingError message={error.message} />}

                        {/* if disabledData === 0 the create btn should not mounted  */}
                        {is_loaded && data && data.comptes && data.comptes.length == 0 && (
                            <NoResultCas btnOnClick={() => acl.account.add() && this.props.history.push(`${RoutesPath.account.add}`)} btnText={acl.account.add() && disabledData === 0 ? "Créer un Compte" : ""} textInfos="Vous n’avez aucun compte pour le moment"></NoResultCas>
                        )}
                    </TableWrapper>
                </Bloc>
            </div>
        );
    }
    getSearchFromCookies = () => {
        const searchFromCookie = configuration.getSearchFromCookies("Accounts");
        if (searchFromCookie) {
            this.setState((prevState) => ({
                ...prevState,
                ...searchFromCookie.searchOperators,
                ...searchFromCookie.searchValues,
                advancedSearch: searchFromCookie.advancedSearch,
                search: searchFromCookie.search,
            }))
        }
    }
    storeSearchInCookies = () => {
        const { search, advancedSearch } = this.state;
        const { account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator, } = this.state;
        const { account_type, code, wording, category, city, sector, selected_city, selected_sector } = this.state;
        const searchOperators = {
            account_type_operator,
            code_operator,
            wording_operator,
            category_operator,
            city_operator,
            sector_operator,
        }
        const searchValues = {
            account_type,
            code,
            wording,
            category,
            city,
            sector,
            selected_city,
            selected_sector,
        }
        configuration.storeSearchInCookies({ search, advancedSearch, searchOperators, searchValues }, "Accounts");
    }

}
const ErrorSalim = styled.img`
    height: 247px;
    width: 103.14px;
    margin-bottom: 14px;
`;
const ErrorContainer = styled.div`
    padding: 150px 0;
    display: flex;
    align-items: center;
    font-weight: 800;
    color: #db544c;
    flex-direction: column;
`;

const TableWrapper = styled.div`
    height: ${(props) => props.height} !important;
    position: relative;
`;

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
    border-style: solid;
    border-color: #28b9ac;
    border-bottom-width: ${(props) => (props.orderBy === 1 ? "1px" : 0)};
    border-top-width: ${(props) => (props.orderBy === -1 ? "1px" : 0)};
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 600;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const Tag_no = styled.strong`
    padding: 3px 10px 3px 10px;
    border-radius: 3px;
    background-color: #db544c;
    color: #fff;
    display: inline-block;
`;
const Tag_yes = styled.strong`
    padding: 3px 12px 3px 12px;
    border-radius: 3px;
    background-color: #28b9ac;
    color: #fff;
    display: inline-block;
`;
const OrderUp = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 6px;
    height: 4px;
    ${"" /* width: 100px;
    height: 100px; */}
    background-position: -213px -98px;
    position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
`;
const OrderDown = styled.a`
    background-image: url(${img});
    background-repeat: no-repeat;
    display: inline-block;
    width: 8px;
    height: 5px;
    background-position: -195px -98px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: 4px;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(AccountsTable);
