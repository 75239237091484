import React, { Component } from "react";
import styled from "styled-components";
import { CirclePicker } from "react-color";

class CustomColorInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            background: "#fff",
        };
    }
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    render() {
        return (
            <Container>
                <ColorContainer borderColor="#bbc8d3">
                    <CirclePicker color={this.props.selected} onChangeComplete={this.handleChangeComplete} circleSize={20} colors={["#00b1d6", "#03c48a", "#eda224", "#ea4a70", "#11aba0", "#700242"]} onChange={this.props.onChange} />
                    <Label id="input-label" htmlFor={this.props.id} className="label-login">
                        Couleur
                    </Label>
                </ColorContainer>
            </Container>
        );
    }
}
const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Label = styled.label`
    position: absolute;
    font-family: Roboto;
    line-height: 0.94;
    letter-spacing: 0.22px;
    text-align: left;
    background-color: #fff;
    top: -10%;
    width: auto;
    left: 1%;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
    color: #00beb0;
    font-family: "Open Sans", sans-serif;

    font-weight: 600;
`;
const ColorContainer = styled.div`
    width: 100%;
    height: 100%;
    resize: none;
    padding-top: 10px;
    outline: none;
    font-size: 14px;
    position: relative;
    padding-left: 10px;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: #ffffff;
    border: solid 1px ${(props) => props.borderColor};
    border-radius: 6px;
    &:hover {
        border: solid 1px #00beb0;
    }
    &:focus-within,
    &:valid {
        border: solid 1px #00beb0;
    }
`;

export default CustomColorInput;
