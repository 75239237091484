import ApiCall from "../services/ApiCall";
class DemandDAO {
    // for getting all demands limited by page number

    static getALL = (pageNumber, params, data, limit = 20) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/demandes/${pageNumber}/${limit}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    //add demand

    static addDemand = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.upload("/api/demande", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    //get demand details

    static getDemandById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get("/api/demande/" + id, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update demand

    static updateDemand = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/update/demande/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //archive Demand

    static deleteDemand = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/demande/archive/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //resolve demand

    static resolveDemand = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/demande/resolve/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // assigned demand

    static assignedDemand = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/demande/assign/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // add comment demand

    static addComment = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/demande/comment/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    // add subscriber demand

    static addSubscriber = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/demande/subscribe/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    static removeSubscriber = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/demande/unsubscribe/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
}

export default DemandDAO;
