import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Container>
                <HeaderTitle>Page non trouvée</HeaderTitle>
                <Link to="/">
                    <Button>Accueil</Button>
                </Link>
            </Container>
        );
    }
}
const Button = styled.div`
    background: #fff;
    padding: 12px 54px;
    border-radius: 10px;
    color: #00beb0;
    font-weight: 800;
    font-size: 12px;
`;
const Container = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #00beb0;
    flex-direction: column;
`;
const HeaderTitle = styled.p`
    font-size: 24px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    float: left;
    margin-top: 7px;
    margin-bottom: 13px;
`;

export default NotFoundPage;
