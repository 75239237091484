import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import PopUpGlobal from "../components/PopUpGlobal.js";
// import "react-datepicker/dist/react-datepicker.css";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import AccountDAO from "../DAO/AccountDAO";
import ResourceDAO from "../DAO/ResourceDAO";
import ContactDAO from "../DAO/ContactDAO";
import CalenderDAO from "../DAO/CalenderDAO";
import CommonDAO from "../DAO/CommonDAO";
import moment from "moment";
import { MessagesText } from "../values/MessagesText";
import { DatePicker } from "../components/CustomDatePicker";
import FromLoading from "../helpers/FromLoading";
import { notify } from "../helpers/FlashMessage";
import UserDAO from "../DAO/UserDAO";
import ProductDAO from "../DAO/ProductDAO";
import MultiSelectPopUp from "../components/MultiSelectPopUp";
import SubRangesMultiSelectPopUp from "../components/SubRangesMultiSelectPopUp";

class UpdateCrEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relation_type: "Pharmacie",
            relation_type_error: false,
            account_type: "",
            account_type_error: false,
            resource: "",
            id_resource: "",
            error_resource: false,
            wording: "",
            error_wording: false,
            category: null,
            code: "",
            id_manager: "",
            error_manager: false,
            ready: false,
            account: null,
            users: [],

            openPopUp: false,
            pharmacy: [],
            itemsNumber: 0,
            resource: "",
            resource_id: null,
            pageNumber: 1,
            searchedText: "",
            errorMessage: "",
            accounts: [],
            contacts: [],

            name: "",
            startDate: new Date(),
            endDate: new Date(),
            type: "",
            desc: "",
            color: "",
            place: "",
            account: "",
            contact: "",
            addRapport: false,

            event_id: "",
            visitors: [],
            visitor: "",
            visitor_error: false,
            visitor_id: "",
            visited_at: new Date(),
            doctor_present: "",
            channel: "Terrain",
            concurrent_actions: [],
            concurrent_action: "",
            concurrent_action_id: "",
            revisited_at: null,
            comment: "",
            event_goals: [],
            event_goal: "",
            event_goal_id: "",
            event_goal_error: false,
            concurrents: [],
            concurrent: [],
            concurrent_id: "",
            provider: [],
            provider_id: "",
            range_products: [],
            range_product: [],
            range_product_id: "",
            sub_range_products: [],
            sub_range_product: [],
            sub_range_product_id: "",
            crevent_id: "",
            status: "",

            loading: false,
            account_loading: false,
            contact_loading: false,
            visitor_loading: false,
            concurrent_actions_loading: false,
        };
    }
    componentDidMount() {
        document.title = "Calendrier - Sobrus Labs";

        const id = this.props.match.params.id;
        if (id) {
            this.get();
        }
        const path = window.location.pathname;
        path && !path.includes("update") && this.getConnectedUserInfos();
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);

            this.setVisitor({ fullname: response.data.fullname, id: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            this.setState({ loading: true });
            const response = await CalenderDAO.getEventById(id, {});
            this.setState({ loading: false });

            const crevent = response.data.crevent;
            let formatSubRanges = [];
            if (crevent && crevent.gammes && crevent.gammes.length !== 0 && crevent.sous_gammes && crevent.sous_gammes.length !== 0) {
                crevent.gammes.forEach((item, index) => {
                    crevent.sous_gammes[item.id] && crevent.sous_gammes[item.id].forEach((obj, index) => {
                        formatSubRanges.push({ id: obj.id, label: obj.nom, rangeId: item.id })
                    })
                })
            }
            if (crevent) {
                this.setState({
                    status: crevent.statut,
                    crevent_id: crevent.id,
                    event_id: id,
                    visitor_id: response.data.event && response.data.event.gestionnaire_id ? response.data.event.gestionnaire_id : "",
                    visitor: response.data.event.gestionnaire_id ? response.data.event.gestionnaire : "",
                    visited_at: crevent.visite_le ? Date.parse(crevent.visite_le) : new Date(),
                    doctor_present: String(crevent.docteur_present),
                    channel: crevent.canal ? crevent.canal : "Terrain",
                    concurrent_action: crevent.action_concurrence,
                    concurrent_action_id: crevent.action_id,
                    revisited_at: crevent.a_revisiter_le ? Date.parse(crevent.a_revisiter_le) : null,
                    comment: crevent.commentaire,
                    event_goal: crevent.objectif,
                    event_goal_id: crevent.objectif_id,
                    concurrent: crevent && crevent.concurrents && crevent.concurrents.map((item) => ({ id: item.id, label: item.nom })),
                    concurrent_id: crevent.concurent_p_id,
                    provider: crevent && crevent.fournisseurs && crevent.fournisseurs.map((item) => ({ id: item.id, label: item.nom })),
                    provider_id: crevent.fournisseur_id_1,
                    range_product: crevent && crevent.gammes && crevent.gammes.map((item) => ({ id: item.id, label: item.nom })),
                    range_product_id: crevent.gamme_id,
                    sub_range_product: formatSubRanges,
                    sub_range_product_id: crevent.sous_gamme_id,
                });
            }
        } catch (error) {
            this.setState({ loading: false });

            notify({ msg: error.message, type: "danger" });
        }
    }

    async getAccount(id) {
        try {
            const response = await AccountDAO.getAccountById(id, {});
            this.setAccount(response.data);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    async getContacts(data, pageNumber = 1) {
        const { id_account } = this.state;
        try {
            this.setState({ contact_loading: true });
            const response = await AccountDAO.getContactsByAccountId(pageNumber, id_account, {}, data);
            this.setState({ contact_loading: false });

            this.setState({ contacts: response.data.contacts, contactItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ contact_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setVisitor(value) {
        this.setState({ visitor: value.fullname, visitor_id: value.id, visitor_error: false });
    }
    resetVisitor() {
        this.setState({ visitor: "", visitor_id: "" });
    }

    async getVisitors(data, pageNumber = 1) {
        try {
            this.setState({ visitor_loading: true });
            const response = await CommonDAO.getAllVisitors(pageNumber, {}, data);
            this.setState({ visitor_loading: false });

            this.setState({ visitors: response.data.utilisateurs });
        } catch (error) {
            this.setState({ visitor_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setConcurrent_action(value) {
        this.setState({ concurrent_action: value.nom, concurrent_action_id: value.id });
    }

    resetConcurrent_action() {
        this.setState({ concurrent_action: "", concurrent_action_id: "" });
    }

    async getConcurrent_actions(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            this.setState({ concurrent_actions_loading: true });
            const response = await CommonDAO.getAllConcurrentActions(pageNumber, keyWord, {});
            this.setState({ concurrent_actions_loading: false });
            this.setState({ concurrent_actions: response.data.ressources });
        } catch (error) {
            this.setState({ concurrent_actions_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    setEvent_goal(value) {
        this.setState({ event_goal: value.nom, event_goal_id: value.id, event_goal_error: false });
    }
    resetEvent_goal() {
        this.setState({ event_goal: "", event_goal_id: "" });
    }

    async getEvent_goals(data, pageNumber = 1) {
        try {
            const keyWord = data.value != undefined ? data.value : " ";
            const response = await CommonDAO.getAllEventGoals(pageNumber, keyWord, {});
            this.setState({ event_goals: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    setConcurrent(array) {
        this.setState({ concurrent: array });
    }
    resetConcurrent() {
        this.setState({ concurrent: [] });
    }

    async getConcurrents(data, pageNumber = 1) {
        try {
            const keyWord = data.nom && data.nom.value != undefined ? data.nom.value : " ";
            const response = await CommonDAO.getAllConcurrent(pageNumber, keyWord, {});
            this.setState({ concurrents: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    setProvider(array) {
        this.setState({ provider: array });
    }

    resetProvider() {
        this.setState({ provider: [] });
    }

    setRange_product(array) {
        this.setState({ range_product: array });
    }
    resetRange_product() {
        this.setState({ range_product: [] });
    }

    async getRange_products(data, pageNumber = 1) {
        try {
            let formatData = {
                orderby: {
                    col: "id",
                    dir: "DESC",
                },
            };
            if (data.nom && data.nom.value) {
                formatData["nom"] = {
                    value: data.nom.value,
                    op: "smart",
                };
            }

            let response = await ProductDAO.getActiveRanges(pageNumber, {}, formatData);
            if (response.data.ressources.length === 0) {
                const keyWord = data.nom && data.nom.value != undefined ? data.nom.value : " ";
                response = await CommonDAO.getAllProductRanges(pageNumber, keyWord, {});
            }
            this.setState({ range_products: response.data.ressources });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    setSub_range_product(array) {
        this.setState({ sub_range_product: array, });
    }
    resetSub_range_product() {
        this.setState({ sub_range_product: [], });
    }


    async getSub_range_products(data, pageNumber = 1) {

        try {

            const { range_product } = this.state;
            // const keyWord = data.value != undefined ? data.value : " ";
            let formatedData = { ...data, gammes: [...range_product.map(obj => obj.id)] }
            const response = await CommonDAO.getSubRangesByRanges(pageNumber, {}, formatedData);
            this.setState({ sub_range_products: response.data });

        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }

    }
    verifyRequireEventRapport(event_id = 0) {
        const {
            visitor_id,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action_id,
            revisited_at,
            comment,
            event_goal_id,
            event_goals,
            concurrent_id,
            concurrent,
            provider_id,
            provider,
            range_product_id,
            range_product,
            sub_range_product_id,
            sub_range_products,
            sub_range_product
        } = this.state;
        let data;
        if (event_goal_id && event_goal_id.length !== 0 && visitor_id && visitor_id.length !== 0) {
            let formatSubRanges = []
            range_product.forEach((obj, index) => {
                formatSubRanges.push([...sub_range_product.filter((item, index) => parseInt(item.rangeId) === parseInt(obj.id)).map((item) => item.id)])
            })

            data = {
                visite_le: visited_at && moment(visited_at).format("YYYY-MM-DD HH:mm"),
                docteur_present: parseInt(doctor_present),
                canal: channel,
                action_concurrence: concurrent_action_id,
                a_revisiter_le: revisited_at && moment(revisited_at).format("YYYY-MM-DD HH:mm"),
                commentaire: comment,
                objectif: event_goal_id,
                concurrents: concurrent && concurrent.map((item) => item.id),
                fournisseurs: provider.map((item) => ({ id: item.id, name: item.label })),
                gammes: range_product.map((item) => item.id),
                sous_gammes: formatSubRanges,
                gestionnaire: visitor_id,
            };
            // if (provider_id && provider_id.length !== 0 && provider && provider.length !== 0) {
            //     data["fournisseurs"] = [{ id: provider_id, name: provider }];
            // }a
        } else {
            if (!event_goal_id || (event_goal_id && event_goal_id.length === 0)) {
                this.setState({ event_goal_error: true });
            }
            if (!visitor_id || (visitor_id && visitor_id.length === 0)) {
                this.setState({ visitor_error: true });
            }
        }

        return data;
    }
    async addEventRapport(event_id) {
        try {
            const data = this.verifyRequireEventRapport(event_id);
            const { status } = this.state;
            const id = this.props.match.params.id;
            let response;
            if (data && id && id.length !== 0) {
                this.setState({ loading: true });
                response = await CalenderDAO.updateCrEvent(id, {}, data);
                this.setState({ loading: false });

                if (response.data.status == "ok") {
                    if (status && status !== "Planifié") {
                        notify({ msg: MessagesText.crEvent.update, type: "success" });
                    } else {
                        notify({ msg: MessagesText.crEvent.add, type: "success" });
                    }
                    this.props.history.push(`${RoutesPath.calender.details}/${event_id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            this.setState({ loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    closePopUp() {
        this.setState({ openPopUp: false });
    }

    setValue(value) {
        this.setState({ account: value.libelle, id_account: value.id });
        this.closePopUp();
    }

    setAccount(value) {
        this.getResource(value.id_ressource, value.type_relation);
        this.setState({ account: `${value.libelle} (${value.ville ? value.ville : ""})`, id_account: value.id, account_error: false });
    }
    async getResource(id, relation_type) {
        try {
            let type = "";
            if (relation_type === "Pharmacie") {
                type = "pharmacie";
            } else if (relation_type === "Etablissement de santé") {
                type = "etablissement";
            } else if (relation_type === "Association") {
                type = "association";
            }
            const params = {};
            const response = await ResourceDAO.getResourceById(type, id, params);
            this.setState({ place: response.data[type].adresse });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    resetAccount() {
        this.setState({ account: "", id_account: "" });
    }
    setContact(value) {
        const { name } = this.state;
        this.setState({ contact: `${value["fullname"]} (${value["compte"] ? value["compte"] : ""})`, id_contact: value.id, contact_error: false });
        name.length == 0 && this.setState({ name: `RDV avec ${value["fullname"]} (${value["compte"] ? value["compte"] : ""})`, name_error: false });
    }
    resetContact() {
        this.setState({ contact: "", id_contact: "" });
    }

    async getAccounts(data, pageNumber = 1) {
        try {
            this.setState({ account_loading: true });
            const response = await AccountDAO.getALLAccounts(pageNumber, {}, data);
            this.setState({ account_loading: false });

            this.setState({ accounts: response.data.comptes, accountItemsNumber: response.data.total });
        } catch (error) {
            this.setState({ account_loading: false });
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const id = this.props.match.params.id;
        const {
            errorMessage,

            visitor,
            visitors,
            visited_at,
            doctor_present,
            channel,
            concurrent_actions,
            concurrent_action,
            revisited_at,
            comment,
            event_goal,
            event_goals,
            concurrent,
            concurrents,
            provider,
            range_product,
            range_products,
            sub_range_product,
            sub_range_products,
            loading,
            visitor_loading,
            concurrent_actions_loading,
            event_goal_error,
            range_product_id,
            visitor_error,
        } = this.state;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                {loading && <FromLoading />}
                <TopOptions title={"Modifier rapport d'événement "}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.addEventRapport(id)}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Rapport d'événement</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <PopUpGlobal
                                    error={visitor_error}
                                    label="Gestionnaire*"
                                    setValue={this.setVisitor.bind(this)}
                                    reset={this.resetVisitor.bind(this)}
                                    defaultValue={visitor}
                                    value={visitor}
                                    data={visitors}
                                    propertyName={"fullname"}
                                    searchData={this.getVisitors.bind(this)}
                                    getData={() => this.getVisitors({})}
                                    loading={visitor_loading}
                                    modalTitle="Liste des gestionnaires"
                                />
                            </InputContainer>
                            <InputContainer>
                                <DatePicker
                                    selected={visited_at}
                                    onChange={(date) => {
                                        this.setState({
                                            visited_at: date,
                                        });
                                    }}
                                    label="Visiter le"
                                    id="visited_at_id"
                                />
                            </InputContainer>
                            <MultiSelectInputContainer>
                                {/* <PopUpGlobal
                                    label="Concurrent"
                                    setValue={this.setConcurrent.bind(this)}
                                    reset={this.resetConcurrent.bind(this)}
                                    defaultValue={concurrent}
                                    value={concurrent}
                                    data={concurrents}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrents.bind(this)}
                                    getData={() => this.getConcurrents({})}
                                /> */}
                                <MultiSelectPopUp
                                    maxItems={5}
                                    maxItemsWarningMessage="Vous avez atteint le maximum des concurrent (5) pour ce compte-rendu"
                                    label="Concurrent"
                                    setValue={this.setConcurrent.bind(this)}
                                    reset={this.resetConcurrent.bind(this)}
                                    defaultValue={concurrent}
                                    value={concurrent}
                                    data={concurrents}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrents.bind(this)}
                                    getData={() => this.getConcurrents({})}
                                    modalTitle="Liste des concurrents principaux"
                                />
                            </MultiSelectInputContainer>
                            <MultiSelectInputContainer>
                                {/* <PopUpGlobal
                                    label="Gamme produit"
                                    setValue={this.setRange_product.bind(this)}
                                    reset={this.resetRange_product.bind(this)}
                                    defaultValue={range_product}
                                    value={range_product}
                                    data={range_products}
                                    propertyName={"nom"}
                                    searchData={this.getRange_products.bind(this)}
                                    getData={() => this.getRange_products({})}
                                /> */}
                                <MultiSelectPopUp
                                    maxItems={5}
                                    maxItemsWarningMessage="Vous avez atteint le maximum des gammes (5) pour ce compte-rendu"
                                    label="Gamme produit"
                                    setValue={this.setRange_product.bind(this)}
                                    reset={this.resetRange_product.bind(this)}
                                    defaultValue={range_product}
                                    value={range_product}
                                    data={range_products}
                                    propertyName={"nom"}
                                    searchData={(data) => this.getRange_products(data)}
                                    getData={() => this.getRange_products({})}
                                    modalTitle="Liste des gammes de produit"
                                />
                            </MultiSelectInputContainer>
                            <InputContainer>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ doctor_present: value.target.value });
                                    }}
                                    selectedValue={doctor_present}
                                    label="Docteur était présent"
                                >
                                    <option defaultValue value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </CustomSelect>
                            </InputContainer>
                            <InputContainer height={"120px"}>
                                <CustomInput
                                    type="textarea"
                                    error={false}
                                    onChange={(value) => {
                                        this.setState({ comment: value.target.value });
                                    }}
                                    defaultValue={comment}
                                    value={comment}
                                    id="comment"
                                    label="Commentaire"
                                />
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <PopUpGlobal
                                    label="Objectif d'événement*"
                                    setValue={this.setEvent_goal.bind(this)}
                                    reset={this.resetEvent_goal.bind(this)}
                                    defaultValue={event_goal}
                                    value={event_goal}
                                    data={event_goals}
                                    propertyName={"nom"}
                                    searchData={this.getEvent_goals.bind(this)}
                                    getData={() => this.getEvent_goals({})}
                                    error={event_goal_error}
                                    modalTitle="Liste des objectifs d’événement"
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    onChange={(value) => {
                                        this.setState({ channel: value.target.value });
                                    }}
                                    selectedValue={channel}
                                    label="Canal"
                                >
                                    <option defaultValue value="Terrain">Terrain</option>
                                    <option value="Téléphone">Téléphone</option>
                                    <option value="E-mail">E-mail</option>
                                </CustomSelect>
                            </InputContainer>
                            <MultiSelectInputContainer>
                                {/* <PopUpGlobal
                                    label="Fournisseurs"
                                    setValue={this.setProvider.bind(this)}
                                    reset={this.resetProvider.bind(this)}
                                    defaultValue={provider}
                                    value={provider}
                                    data={concurrents}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrents.bind(this)}
                                    getData={() => this.getConcurrents({})}
                                    modalTitle="Liste des fournisseurs principaux"
                                /> */}
                                <MultiSelectPopUp
                                    maxItems={5}
                                    maxItemsWarningMessage="Vous avez atteint le maximum des fournisseurs (5) pour ce compte-rendu"
                                    label="Fournisseurs"
                                    setValue={this.setProvider.bind(this)}
                                    reset={this.resetProvider.bind(this)}
                                    defaultValue={this.state.provider}
                                    value={this.state.provider}
                                    data={concurrents}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrents.bind(this)}
                                    getData={() => this.getConcurrents({})}
                                    modalTitle="Liste des fournisseurs principaux"
                                />
                            </MultiSelectInputContainer>
                            <InputContainer>
                                <PopUpGlobal
                                    label="Action de la concurrence"
                                    setValue={this.setConcurrent_action.bind(this)}
                                    reset={this.resetConcurrent_action.bind(this)}
                                    defaultValue={concurrent_action}
                                    value={concurrent_action}
                                    data={concurrent_actions}
                                    propertyName={"nom"}
                                    searchData={this.getConcurrent_actions.bind(this)}
                                    getData={() => this.getConcurrent_actions({})}
                                    loading={concurrent_actions_loading}
                                />
                            </InputContainer>
                            {/* {range_product_id && range_product_id.length != 0 && (
                                <InputContainer>
                                    <PopUpGlobal
                                        label="Sous-gamme produit"
                                        setValue={this.setSub_range_product.bind(this)}
                                        reset={this.resetSub_range_product.bind(this)}
                                        defaultValue={sub_range_product}
                                        value={sub_range_product}
                                        data={sub_range_products}
                                        propertyName={"nom"}
                                        searchData={this.getSub_range_products.bind(this)}
                                        getData={() => this.getSub_range_products()}
                                        modalTitle="Liste des sous-gammes de produit"
                                    />
                                </InputContainer>
                            )} */}
                            {range_product && range_product.length != 0 && (
                                <InputContainer>
                                    {/* <PopUpGlobal
                                            label="Sous-gamme produit"
                                            setValue={this.setSub_range_product.bind(this)}
                                            reset={this.resetSub_range_product.bind(this)}
                                            defaultValue={sub_range_product}
                                            value={sub_range_product}
                                            data={sub_range_products}
                                            propertyName={"nom"}
                                            searchData={this.getSub_range_products.bind(this)}
                                            getData={() => this.getSub_range_products()}
                                            modalTitle="Liste des sous-gammes de produit"
                                        /> */}
                                    {/* <MultiSelectPopUp
                                        maxItems={5}
                                        maxItemsWarningMessage="Vous avez atteint le maximum des sous-gammes (5) pour ce compte-rendu"
                                        label="Sous-gamme produit"
                                        setValue={this.setSub_range_product.bind(this)}
                                        reset={this.resetSub_range_product.bind(this)}
                                        defaultValue={sub_range_product}
                                        value={sub_range_product}
                                        data={sub_range_products}
                                        propertyName={"nom"}
                                        searchData={this.getSub_range_products.bind(this)}
                                        getData={() => this.getSub_range_products()}
                                        modalTitle="Liste des sous-gammes de produit"
                                    /> */}
                                    <SubRangesMultiSelectPopUp
                                        maxItems={5}
                                        maxItemsWarningMessage="Vous avez atteint le maximum des sous-gammes (5) pour ce compte-rendu"
                                        label="Sous-gamme produit"
                                        setValue={this.setSub_range_product.bind(this)}
                                        reset={this.resetSub_range_product.bind(this)}
                                        defaultValue={sub_range_product}
                                        value={sub_range_product}
                                        ranges={range_product}
                                        data={sub_range_products}
                                        propertyName={"nom"}
                                        searchData={this.getSub_range_products.bind(this)}
                                        getData={() => this.getSub_range_products()}
                                        modalTitle="Liste des sous-gammes de produit"
                                    />
                                </InputContainer>
                            )}
                            <InputContainer>
                                <DatePicker
                                    selected={revisited_at}
                                    onChange={(date) => {
                                        this.setState({
                                            revisited_at: date,
                                        });
                                    }}
                                    label="A revisiter le"
                                    id="revisited_at_id"
                                />
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
// const Bloc = styled.div`
//     box-shadow: 0 1px 3px 0 #bcbcbc;
//     width: 96.3%;
//     position: relative;
//     margin: auto;
//     padding-bottom: 60px;
//     margin-top: 10px;
//     background: #fff;
// `;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const MultiSelectInputContainer = styled.div`
    width: 100%;
    min-height: 41px;
    margin-bottom: 18px;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(UpdateCrEventPage);
