import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import GalleryDAO from "../DAO/GalleryDAO";
import { RoutesPath } from "../values/RoutesPath";
import { Link } from "react-router-dom";
import { notify } from "../helpers/FlashMessage";
import { acl } from "../services/acl";
import { BASE_URL } from "../env";
import PlanningDAO from "../DAO/PlanningDAO";
import { MessagesText } from "../values/MessagesText";

const download = require("image-downloader");
const Path = require("path");

class DetailsPicturePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picture: null,
            is_loaded: false,
        };
    }

    componentDidMount() {
        document.title = "Galerie - Sobrus Labs";
        this.get();
    }

    async downloadImage() {
        try {
            const { picture } = this.state;
            const path = Path.resolve(__dirname, "files", "image.jpg");
            const options = {
                url: `${BASE_URL}/uploads/${picture.path}`,
                dest: path, // Save to /path/to/dest/image.jpg
            };
            const { filename, image } = await download.image(options);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await GalleryDAO.getPhotoById(id, {});
            this.setState({ picture: response.data, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await GalleryDAO.deletePhoto(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.gallery.delete, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }

                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await GalleryDAO.deletePhoto(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.gallery.restore, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }

                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    render() {
        const { is_loaded, picture, successMessage } = this.state;
        const id = this.props.match.params.id;
        const title = picture ? picture.nom : "";
        return (
            <Main_container className="Main_container">
                <TopOptions title={"Fichier : " + title}>
                    {/* <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.downloadImage()}>
                        Télécharger
                    </button> */}
                    {is_loaded && !picture.disabled && acl.gallery.disable() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.delete(id)}>
                            Archiver
                        </button>
                    )}
                    {is_loaded && picture.disabled && acl.gallery.restore() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.restore(id)}>
                            Restaurer
                        </button>
                    )}
                    {is_loaded && !picture.disabled && acl.gallery.update() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.gallery.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title>Informations générales</Title>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>
                                            {/* <Field_value_green>{picture && picture.nom}</Field_value_green> */}

                                            {is_loaded ? (
                                                <Field_value>{picture && picture.nom}</Field_value>
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Date</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{picture && moment(picture.date).format("YYYY-MM-DD HH:mm")}</Field_value>
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Compte</Field_label>

                                            {is_loaded ? (
                                                picture.compte_id ? (
                                                    <Link to={`${RoutesPath.account.details}/${picture.compte_id}`}>
                                                        <Field_value isLink={true} color="#00beb0">
                                                            {picture && picture.compte}
                                                        </Field_value>
                                                    </Link>
                                                ) : (
                                                    <Field_value>{picture && picture.compte}</Field_value>
                                                )
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Produit</Field_label>

                                            {is_loaded ? (
                                                picture.produit_id ? (
                                                    <Link to={`${RoutesPath.product.details}/${picture.produit_id}`}>
                                                        <Field_value isLink={true} color="#00beb0">
                                                            {picture && picture.produit}
                                                        </Field_value>
                                                    </Link>
                                                ) : (
                                                    <Field_value>{picture && picture.produit}</Field_value>
                                                )
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Événement</Field_label>

                                            {is_loaded ? (
                                                picture.event_id ? (
                                                    <Link to={`${RoutesPath.calender.details}/${picture.event_id}`}>
                                                        <Field_value isLink={true} color="#00beb0">
                                                            {picture && picture.event}
                                                        </Field_value>
                                                    </Link>
                                                ) : (
                                                    <Field_value>{picture && picture.event}</Field_value>
                                                )
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Gestionnaire</Field_label>
                                            {is_loaded ? (
                                                <Field_value>{picture && picture.gestionnaire}</Field_value>
                                            ) : (
                                                <ContentLoader width="160" height="20">
                                                    <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                </ContentLoader>
                                            )}
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                        <Block>
                            <div className="bloc_header">
                                <h2>Photo</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <FullImg src={picture && picture.path} />
                            </Block_content>
                        </Block>
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={picture && picture.disabled ? "bloc_content devis_statut ds_brouillon" : "bloc_content devis_statut ds_complete"}>
                                <h3>{picture && picture.disabled ? "Archivé" : "Actif"}</h3>
                            </div>
                        </div>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        {/* <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            <Add_btn>Créer un nouveau contact</Add_btn>
                        </Command_block> */}
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{picture && picture.cree_par}</Col_1_li>
                                    ) : (
                                        <ContentLoader width="160" height="20">
                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                        </ContentLoader>
                                    )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{picture && moment(picture.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                        <ContentLoader width="160" height="20">
                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                        </ContentLoader>
                                    )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{picture && picture.mis_a_jour_par ? picture && picture.mis_a_jour_par : picture && picture.cree_par}</Col_1_li>
                                    ) : (
                                        <ContentLoader width="160" height="20">
                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                        </ContentLoader>
                                    )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{picture && picture.mis_a_jour_le ? picture && moment(picture.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : picture && moment(picture.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                        <ContentLoader width="160" height="20">
                                            <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                        </ContentLoader>
                                    )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DetailsPicturePage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;

const Field_value = styled.p`
    display: block;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const FullImg = styled.img`
    width: 100%;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
