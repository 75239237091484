import React, { Component } from "react";
import "./Headband.css";
class Headband extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="Headband">
                <p>Nous évoluons chaque jour pour vous. Des questions ? Appelez-nous : 05 30 500 500</p>
            </div>
        );
    }
}

export default Headband;
