
import axios from "axios";
// import axiosRetry from "axios-retry";
import HttpErrors from "./HttpErrors";
import Auth from "./Auth";
import { BASE_URL } from "../env";

const API = axios.create({
    baseURL: `${BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});

// const refreshAuthLogic = failedRequest => axios.get('https://server3.sobrus.com/auth/refresh').then(tokenRefreshResponse => {
//     return Promise.resolve();
// }).catch((error) => {
//   ("refreeeeesh ");
//   (error)
// });
// createAuthRefreshInterceptor(API, refreshAuthLogic);

// Response interceptor for API calls
// axiosRetry(API, { retryDelay: axiosRetry.exponentialDelay });
// axiosRetry(API, { retries: 3 });


API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response && error.response.status && error.response.status === 401 && error.response && error.response.data && error.response.data.status && error.response.data.status === "ko" && error.response.data.code === 401 && !originalRequest._retry) {
            Auth.refresh(() => { });
            // originalRequest._retry = true;
            // return API(originalRequest);
        } else if (
            error.response &&
            error.response.status &&
            error.response.status === 401 &&
            error.response &&
            error.response.data &&
            error.response.data.status &&
            error.response.data.status === "ko" &&
            (error.response.data.code === 406 || error.response.data.code === 405 || error.response.data.code === 404)
        ) {
            localStorage.clear();
            window.location.href = `/failed?error=code${error.response.data.code}`;
        } else if (error.response && error.response.status && error.response.status === 403) {
            localStorage.clear();
            window.location.href = "https://labsv3.sobrus.com/login";
            // originalRequest._retry = true;
            // return API(originalRequest);
        }
        return Promise.reject(error);
    }
);

class ApiCall {
    static get = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "application/json",
                };
                let response = await API.get(url, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static post = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "application/json",
                };
                let response = await API.post(url, data, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static put = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "application/json",
                };
                let response = await API.put(url, data, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static upload = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "multipart/form-data",
                };
                let response = await API.post(url, data, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static delete = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "application/json",
                };
                let response = await API.delete(url, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
}

const handleError = (error, reject) => {
    if (error.response) {
        if (error.response.status === 401 && error.response.data.status === "ko" && error.response.data.code === 401) {
            // Auth.refresh(() => {});
            // originalRequest._retry = true;
            // return API(originalRequest);
        } else if (error.response.status === 401 && error.response.data.status === "ko" && (error.response.data.code === 406 || error.response.data.code === 405 || error.response.data.code === 404)) {
            // localStorage.clear();
            // window.location.href = `/failed?error=code${error.response.data.code}`;
        } else if (error.response.status === 403) {
            // localStorage.clear();
            // window.location.href = "https://labsv3.sobrus.com/login";
            // originalRequest._retry = true;
            // return API(originalRequest);
        } else {
            reject({ response: true, request: false, message: error.response.data && error.response.data.message ? error.response.data.message : HttpErrors[error.response.status] });
        }
    } else if (error.request) {
        reject({ response: false, request: true, message: "Aucune Reponse reçu, merci de vérifier que vous êtes bien connecté à l'internet" });
    } else {
        reject({ response: false, request: false, message: error.message });
    }
};

export default ApiCall;
