import React, { Component } from "react";
import "./BeingProcessed.css";

class BeingProcessed extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="BeingProcessed__container">
                <img src={require("../../images/settings.png")} alt="dataLoadingError" />
                <p>En cours de refonte</p>
            </div>
        );
    }
}

export default BeingProcessed;
