import ApiCall from "../services/ApiCall";

class AccountDAO {
    // for getting all accounts limited by page number

    static getALLAccounts = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/comptes/" + pageNumber, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //archive Account

    static deleteAccount = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/compte/disabled/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add Account

    static addAccount = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/compte", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update Account

    static updateAccount = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/compte/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get account details

    static getAccountById = (id, params = {}) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get("/api/compte/" + id, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get contacts linked to account

    static getContactsByAccountId = (pageNumber, accountId, params, data, limit = 20) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/compte/contacts/" + accountId + "/" + pageNumber + "/" + limit, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get photos linked to account

    static getPhotosByAccountId = (pageNumber, accountId, params, data, limit = 20) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/compte/photos/${accountId}/${pageNumber}/${limit}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get events linked to account

    static getEventsByAccountId = (pageNumber, accountId, params, data, limit = 20) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/compte/events/${accountId}/${pageNumber}/${limit}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage

    static getSearchData({ account_type, code, wording, category, city, sector, advancedSearch, search, account_type_operator, code_operator, wording_operator, category_operator, city_operator, sector_operator }) {
        const data = {};
        if (account_type && account_type.length !== 0) {
            if (search) {
                data["type_compte"] = {
                    value: account_type,
                    op: "is",
                };
            } else if (advancedSearch && account_type_operator.length !== 0) {
                data["type_compte"] = {
                    value: account_type,
                    op: account_type_operator,
                };
            }
        }
        if (code && code.length !== 0) {
            if (search) {
                data["code"] = {
                    value: code,
                    op: "smart",
                };
            } else if (advancedSearch && code_operator.length !== 0) {
                data["code"] = {
                    value: code,
                    op: code_operator,
                };
            }
        }
        if (wording && wording.length !== 0) {
            if (search) {
                data["libelle"] = {
                    value: wording,
                    op: "smart",
                };
            } else if (advancedSearch && wording_operator.length !== 0) {
                data["libelle"] = {
                    value: wording,
                    op: wording_operator,
                };
            }
        }
        if (category && category.length !== 0) {
            if (search) {
                data["categorie"] = {
                    value: category,
                    op: "is",
                };
            } else if (advancedSearch && category_operator.length !== 0) {
                data["categorie"] = {
                    value: category,
                    op: category_operator,
                };
            }
        }
        if (city && city.length !== 0) {
            if (search) {
                data["ville"] = {
                    value: city,
                    op: "is",
                };
            } else if (advancedSearch && city_operator.length !== 0) {
                data["ville"] = {
                    value: city,
                    op: city_operator,
                };
            }
        }
        if (sector && sector.length !== 0) {
            if (search) {
                data["secteur"] = {
                    value: sector,
                    op: "is",
                };
            } else if (advancedSearch && sector_operator.length !== 0) {
                data["secteur"] = {
                    value: sector,
                    op: sector_operator,
                };
            }
        }
        return data;
    }
}

export default AccountDAO;
