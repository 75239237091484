import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import FlashMessages from "../helpers/FlashMessages";
import { errorMessage } from "../tmp/Messages";
import { connect } from "react-redux";

import PlanningDAO from "../DAO/PlanningDAO";
import { MessagesText } from "../values/MessagesText";
import { DatePicker } from "../components/CustomDatePicker";

import moment from "moment";
import { RoutesPath } from "../values/RoutesPath";
import { Bloc, FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import CustomSelect from "../components/CustomSelect";
import UserDAO from "../DAO/UserDAO";
import { notify } from "../helpers/FlashMessage";

/**
 *
 * this component can used for persist and update  account
 * if id_account not 0 means update
 * else mean persist
 *
 */

class DuplicatePlanningPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            planning_id: 0,
            planning: {},
            users: [],
            fields: {
                name: { value: "", error: false },
                start_date: { value: null, error: false },
                manager: { value: "", error: false },
                error: false,
            },
        };
    }

    componentDidMount() {
        const { planning } = this.state;
        moment(planning.date_debut).weekday();
        document.title = "Planning - Sobrus Labs";
        const id = this.props.match.params.id;
        this.getUsers();
        this.getConnectedUserInfos();
        if (id) {
            this.get();
        }
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await PlanningDAO.getPlanningById(id, {});
            this.setState({ planning: response.data.planning });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    verifyRequired() {
        const { fields } = this.state;
        if (fields.name && fields.name.value && fields.name.value.length !== 0 && fields.start_date && fields.start_date.value !== null && fields.manager && fields.manager.value && fields.manager.value.length !== 0) {
            const data = {
                nom: fields.name.value,
                date_debut: moment(fields.start_date.value).format("YYYY-MM-DD"),
                gestionnaire: fields.manager.value,
            };
            return data;
        } else {
            if (!fields.name.value || (fields.name.value && fields.name.value.length === 0)) {
                this.setFields("name", "error", true);
            }
            if (fields.start_date.value === null) {
                this.setFields("start_date", "error", true);
            }
            if (!fields.manager.value || (fields.manager.value && fields.manager.value.length === 0)) this.setFields("manager", "error", true);
        }
    }

    async duplicate() {
        try {
            const planning_id = this.props.match.params.id;
            const data = this.verifyRequired();
            if (data) {
                const response = await PlanningDAO.duplicatePlanning(planning_id, {}, data);
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.planning.duplicate, type: "success" });
                    this.props.history.push(`${RoutesPath.planning.details}/${response.data.planning.id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    setFields(field, property, value) {
        this.setState(
            (prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [field]: {
                        ...prevState.fields[field],
                        [property]: value,
                    },
                },
            }),
            () => this.state.fields[field][property]
        );
    }

    disabledDays = (date) => {
        const { planning } = this.state;
        const day = moment(date).weekday();
        return day === moment(planning.date_debut).weekday();
    };
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setFields("manager", "value", response.data.id);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    render() {
        const { planning, fields, errorMessage, users } = this.state;
        const title = `Dupliquer ${planning.nom}`;

        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                <TopOptions title={title}>
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.duplicate()}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Dupliquer Planning</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <FormContainer>
                        <Left>
                            <InputContainer>
                                <CustomInput
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    error={fields.name.error}
                                    onChange={(value) => {
                                        this.setFields("name", "value", value.target.value);
                                        this.setFields("name", "error", false);
                                    }}
                                    defaultValue={fields.name.value}
                                    id="name"
                                    label="Nom *"
                                />
                            </InputContainer>
                            <InputContainer>
                                <CustomSelect
                                    error={fields.manager.error}
                                    onChange={(value) => {
                                        this.setFields("manager", "value", value.target.value);
                                        this.setFields("manager", "error", false);
                                    }}
                                    selectedValue={fields.manager.value}
                                    label="Gestionnaire *"
                                >
                                    <option defaultValue></option>
                                    {users.map((user, index) => {
                                        return (
                                            <option key={"key_" + index} value={user.id}>
                                                {user.fullname}
                                            </option>
                                        );
                                    })}
                                </CustomSelect>
                            </InputContainer>
                        </Left>
                        <Right>
                            <InputContainer>
                                <DatePicker
                                    filterDate={this.disabledDays}
                                    error={fields.start_date.error}
                                    selected={fields.start_date.value}
                                    onChange={(date) => {
                                        this.setFields("start_date", "value", date);
                                        this.setFields("start_date", "error", false);
                                    }}
                                    label="Date de début*"
                                    id="start_date_id"
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                />
                            </InputContainer>
                        </Right>
                    </FormContainer>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(DuplicatePlanningPage);
