import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import CalendarDAO from "../DAO/CalenderDAO";
import { RoutesPath } from "../values/RoutesPath";
import CircleLoading from "../components/CircleLoading";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
class DailyEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {}

    render() {
        const connected_user_infos = this.props.connectedUserInfos;
        let PlannedFilter = {
            orderby: {
                col: "date_debut",
                dir: "ASC",
            },
            disabled: {
                value: 0,
                op: "is",
            },
            date_debut: {
                value: moment().format("YYYY-MM-DD"),
                op: "is_date",
            },
            statut: {
                value: "Planifié",
                op: "is",
            },
            gestionnaire: {
                value: connected_user_infos.id,
                op: "is",
            },
        };
        let ReportedFilter = {
            orderby: {
                col: "date_debut",
                dir: "ASC",
            },
            disabled: {
                value: 0,
                op: "is",
            },
            date_debut: {
                value: moment().format("YYYY-MM-DD"),
                op: "is_date",
            },
            statut: {
                value: "Reporté",
                op: "is",
            },
            gestionnaire: {
                value: connected_user_infos.id,
                op: "is",
            },
        };
        const realizedFilter = {
            orderby: {
                col: "date_debut",
                dir: "ASC",
            },
            disabled: {
                value: 0,
                op: "is",
            },
            date_debut: {
                value: moment().format("YYYY-MM-DD"),
                op: "is_date",
            },
            statut: {
                value: "Visité",
                op: "is",
            },
            gestionnaire: {
                value: connected_user_infos.id,
                op: "is",
            },
        };
        return (
            <Container>
                {/* <a href="/posts/index/follow-link/post_id/88">
                <Ads_img src="https://central.sobrus.com/uploads/ma/posts/1568041080_20024_3d3838caaf43329dad960e5c1588bf03b5d76687872603.png" />
            </a> */}
                <HeaderContainer>
                    <HeaderTitle>Événements de la journée</HeaderTitle>
                    <ShowAllBtn onClick={() => this.props.history.push(`${RoutesPath.calender.list}`)}>Voir tout</ShowAllBtn>
                </HeaderContainer>

                <DailyEventsBloc
                    opened={true}
                    fetchingFilter={PlannedFilter}
                    title={"Prévu"}
                    headerIcon={<Icon1 src={require("../images/Calendar.svg")} />}
                    style={{
                        backgroundColor: "#18b2d5",
                    }}
                    getEventDetails={(id) => this.props.history.push(`${RoutesPath.calender.details}/${id}`)}
                />
                <DailyEventsBloc
                    fetchingFilter={realizedFilter}
                    title={"Réalisé"}
                    headerIcon={<Icon1 src={require("../images/calendar_2.svg")} />}
                    style={{
                        backgroundColor: "#00beb0",
                    }}
                    getEventDetails={(id) => this.props.history.push(`${RoutesPath.calender.details}/${id}`)}
                />
                <DailyEventsBloc
                    fetchingFilter={ReportedFilter}
                    title={"Reporté"}
                    headerIcon={<Icon1 src={require("../images/calendar_3.svg")} />}
                    style={{
                        backgroundColor: "#e03e4d",
                    }}
                    getEventDetails={(id) => this.props.history.push(`${RoutesPath.calender.details}/${id}`)}
                />
            </Container>
        );
    }
}

class DailyEventsBloc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openBloc: this.props.opened || false,
            events: [],
            dataIsLoaded: false,
            dataLoadingError: {
                is: false,
                message: "",
            },
            pageNumber: 1,
        };
    }
    componentDidMount() {
        const { events } = this.state;
        events.length === 0 && this.getEvents();
    }
    async getEvents() {
        try {
            const { fetchingParams, fetchingFilter } = this.props;
            const { events, pageNumber } = this.state;
            this.setState({ dataIsLoaded: false });
            let data = fetchingFilter || {};
            let params = fetchingParams || {};

            const response = await CalendarDAO.getALLEvents(pageNumber, params, data);

            this.setState({ dataIsLoaded: true, events: response.data.events.slice(0, 5), totalItems: response.data.total });
            //for getting all events in all pages
            // if (response.data.total / 20 > pageNumber) {
            //     this.setState({ pageNumber: pageNumber + 1 }, () => this.getEvents());
            // }
        } catch (error) {
            this.setState({ dataIsLoaded: true, dataLoadingError: { is: true, message: error.message } });
        }
        return [];
    }
    render() {
        const { openBloc, events, dataIsLoaded, dataLoadingError, totalItems } = this.state;
        const { style, title, headerIcon, getEventDetails } = this.props;
        return (
            <SectionContainer>
                <SectionHeaderContainer onClick={() => this.setState({ openBloc: !openBloc }, () => this.state.events.length === 0 && this.getEvents())} color={style.backgroundColor || "#18b2d5"}>
                    <IconBox>{headerIcon || <Icon1 src={require("../images/Calendar.svg")} />}</IconBox>
                    <BlockContainer>
                        <P>{`${title + "s" || ""} ${dataIsLoaded ? `(${totalItems})` : ``}`}</P>
                        <DownIcon>
                            <Chevron rotate={openBloc ? "180deg" : "0deg"} xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 12.035 6.86">
                                <path id="Path_282" data-name="Path 282" d="M103.752,6.613l-5.175,5.175A.843.843,0,0,1,97.385,10.6l4.579-4.579L97.385,1.438A.843.843,0,0,1,98.577.247l5.175,5.175a.843.843,0,0,1,0,1.192Z" transform="translate(12.035 -97.139) rotate(90)" fill="#fff" />
                            </Chevron>
                        </DownIcon>
                    </BlockContainer>
                </SectionHeaderContainer>
                <EventsContainer display={openBloc ? "block" : "none"}>
                    {/* loading case  */}
                    {openBloc && !dataIsLoaded && (
                        <NoEvent>
                            <LoadingContainer>
                                <CircleLoading />
                            </LoadingContainer>
                        </NoEvent>
                    )}
                    {/* data is loaded successfully case */}
                    {dataIsLoaded &&
                        !dataLoadingError.is &&
                        events.length !== 0 &&
                        events.map((event, index) => {
                            return (
                                <Event key={"plannedEvents_key" + index}>
                                    <Part_1>
                                        {event.compte_id ? (
                                            <Link to={`${RoutesPath.account.details}/${event.compte_id}`}>
                                                <EventText isLink={true} color="#00beb0">
                                                    {event && event.compte}
                                                </EventText>
                                            </Link>
                                        ) : (
                                            <EventText>{event.compte}</EventText>
                                        )}
                                        <EventText>{event.date_debut && moment(event.date_debut).format("YYYY-MM-DD HH:mm")}</EventText>
                                    </Part_1>
                                    <EyeContainer hoverColor={style.backgroundColor || "#18b2d5"} onClick={() => getEventDetails(event.id)}>
                                        <FaEye color="#7a7a7a"></FaEye>
                                        {/* <EyeIcon xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 931 827">
                                            <text id="_" data-name="" transform="matrix(1, 0, 0, -1, 0, 103)" fill="#7a7a7a" fontSize="827" fontFamily="FontAwesome5Free-Solid, 'Font Awesome 5 Free'">
                                                <tspan x="0" y="0">
                                                    
                                                </tspan>
                                            </text>
                                        </EyeIcon> */}
                                    </EyeContainer>
                                </Event>
                            );
                        })}
                    {/* data is loaded successfully but no element existed */}
                    {dataIsLoaded && !dataLoadingError.is && events.length === 0 && (
                        <NoEvent>
                            <Part_1>
                                <EventText>{`Aucun événement ${title && title.toLocaleLowerCase()}`}</EventText>
                            </Part_1>
                        </NoEvent>
                    )}
                    {/* data loading error case */}
                    {dataIsLoaded && dataLoadingError.is && (
                        <NoEvent>
                            <Part_1>
                                <EventTextError>{dataLoadingError.message}</EventTextError>
                            </Part_1>
                        </NoEvent>
                    )}
                </EventsContainer>
            </SectionContainer>
        );
    }
}

const BlockContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Container = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 24%;
    position: relative;
    margin-left: 0.5%;
    margin-right: 0.5%;
    min-height: auto;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    ${"" /* min-width: 370px; */}

    ${"" /* overflow: scroll; */}
`;
const HeaderContainer = styled.div`
    margin-bottom: 23px;
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-between ${"" /* min-width: 347px; */} ${"" /* overflow: scroll; */};
`;
const HeaderTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #494949;
    display: inline-block;
    vertical-align: top;
    margin-top: 7px;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;
const ShowAllBtn = styled.button`
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
    background-color: #00beb0;
    border: 0;
    border-radius: 3px;
    padding: 8px 28px;
    cursor: pointer;
`;
const SectionContainer = styled.div`
    margin-top: 14px;
    ${"" /* min-width: 347px; */}
    ${"" /* overflow-x: scroll; */}
`;
const SectionHeaderContainer = styled.div`
    height: 59px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    background-color: ${(props) => props.color};
    cursor: pointer;
`;
const IconBox = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 58px;
    height: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.2);
    float: left;
    position: relative;
`;
const P = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    float: left;
    margin-top: 17px;
    margin-left: 20px;
    overflow-x: hidden;
    text-overflow: ellipsis;
`;
const DownIcon = styled.div`
    display: inline-block;
    vertical-align: top;
    float: right;
    margin-right: 26px;
    margin-top: 22px;
`;
const Chevron = styled.svg`
    transform: rotate(${(props) => props.rotate});
`;
const Icon1 = styled.img`
    width: 20.3px;
    height: 20.3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const EventsContainer = styled.div`
    display: ${(props) => props.display};
    ${"" /* max-height: 367px;
    overflow: scroll; */}
`;

const Event = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16.5px;
    padding-left: 24px;
    padding-bottom: 16.5px;
    padding-right: 19px;
    border-bottom: solid 1px rgba(187, 187, 187, 0.4);
    border-left: solid 1px rgba(187, 187, 187, 0.4);
    border-right: solid 1px rgba(187, 187, 187, 0.4);
    min-height: 75px;
`;
const NoEvent = styled.div`
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex !important;
    justify-content: center !important;
    border-bottom: solid 1px rgba(187, 187, 187, 0.4);
    border-left: solid 1px rgba(187, 187, 187, 0.4);
    border-right: solid 1px rgba(187, 187, 187, 0.4);
`;
const Part_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    float: left;
    width: 86%;
`;
const EventText = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => (props.color ? props.color : "#494949")};
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        text-decoration: ${(props) => props.isLink && "underline"};
    }
`;
const EventTextError = styled(EventText)`
    color: #e03e4d;
`;
const EyeIcon = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const EyeContainer = styled.div`
    width: 27px;
    height: 27px;
    border: solid 1px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    float: right;
    position: relative;
    cursor: pointer;
    margin-top: 7px;
    transition: 0.3s;
    &:hover {
        background-color: ${(props) => props.hoverColor};
        border: solid 1px ${(props) => props.hoverColor};
    }
    &:hover > svg {
        fill: #fff;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    align-self: center;
`;
const mapStateToProps = (state) => {
    return {
        acl_actions: state.ACL.acl_actions,
        connectedUserInfos: state.User.connectedUserInfos,
    };
};
export default connect(mapStateToProps)(DailyEvents);
