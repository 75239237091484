import ApiCall from "../services/ApiCall";
import moment from "moment";
class CalenderDAO {
    // for getting all accounts limited by page number

    static getALLEvents = (pageNumber, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/api/events/${pageNumber}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add Event

    static addEvent = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/event", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update event

    static updateEvent = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/event/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //add event report

    static addCrEvent = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post("/api/crevent", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //update  event report

    static updateCrEvent = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/crevent/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //get event details

    static getEventById = (id, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.get("/api/event/" + id, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    //report event

    static reportEvent = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put(`/api/event/reporter/${id}`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    //archive event

    static deleteEvent = (id, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.put("/api/event/disabled/" + id, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });

    // get data for doing search is not DAO function but for have readable and clean code in AccountPage
    static getSearchDataForDay({ validDate }) {
        let data = {};
        data = {
            date_debut: {
                value: moment(validDate).format("YYYY-MM-DD"),
                op: "is_date",
            },
        };
        return data;
    }
    static getSearchData({ validDate }) {
        let data = {};
        data = {
            date_debut: {
                value: validDate,
                op: "by_month",
            },
        };
        return data;
    }
    static getSearchDataForTable({ advancedSearch, search, name, date_start, date_end, place, type, contact }) {
        const data = {};

        if (name && name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: name,
                    op: "smart",
                };
            }
        }
        if (place && place.length !== 0) {
            if (search) {
                data["lieu"] = {
                    value: place,
                    op: "smart",
                };
            }
        }
        if (type && type.length !== 0) {
            if (search) {
                data["type"] = {
                    value: type,
                    op: "smart",
                };
            }
        }
        if (contact && contact.length !== 0) {
            if (search) {
                data["contact"] = {
                    value: contact,
                    op: "is",
                };
            }
        }
        if (date_start && date_start.length !== 0) {
            if (search) {
                data["date_debut"] = {
                    value: moment(date_start).format("YYYY-MM-DD HH:mm"),
                    op: "is_date",
                };
            }
        }
        if (date_end && date_end.length !== 0) {
            if (search) {
                data["date_fin"] = {
                    value: moment(date_end).format("YYYY-MM-DD HH:mm"),
                    op: "is_date",
                };
            }
        }
        return data;
    }

    static getSearchDataForCalender({ advancedSearch, search, name, date_start, date_end, status, type, account, doctor_present, comment, revisited_at, operators }) {
        const data = {};

        if (name && name.length !== 0) {
            if (search) {
                data["nom"] = {
                    value: name,
                    op: "smart",
                };
            } else if (advancedSearch && operators.name.length !== 0) {
                data["nom"] = {
                    value: name,
                    op: operators.name,
                };
            }
        }
        if (status && status.length !== 0) {
            if (search) {
                data["statut"] = {
                    value: status,
                    op: "smart",
                };
            } else if (advancedSearch && operators.status.length !== 0) {
                data["statut"] = {
                    value: status,
                    op: operators.status,
                };
            }
        }
        if (type && type.length !== 0) {
            if (search) {
                data["type"] = {
                    value: type,
                    op: "smart",
                };
            } else if (advancedSearch && operators.type.length !== 0) {
                data["type"] = {
                    value: type,
                    op: operators.type,
                };
            }
        }
        if (account && account.length !== 0) {
            if (search) {
                data["compte"] = {
                    value: account,
                    op: "is",
                };
            } else if (advancedSearch && operators.account.length !== 0) {
                data["Compte"] = {
                    value: account,
                    op: operators.account,
                };
            }
        }
        if (date_start && date_start.length !== 0) {
            if (search) {
                data["date_debut"] = {
                    value: moment(date_start).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && operators.date_start.length !== 0) {
                data["date_debut"] = {
                    value: moment(date_start).format("YYYY-MM-DD"),
                    op: operators.date_start,
                };
            }
        }
        if (date_end && date_end.length !== 0) {
            if (search) {
                data["date_fin"] = {
                    value: moment(date_end).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && operators.date_end.length !== 0) {
                data["date_fin"] = {
                    value: moment(date_end).format("YYYY-MM-DD"),
                    op: operators.date_end,
                };
            }
        }
        if (doctor_present && doctor_present.length !== 0) {
            if (search) {
                data["docteur_present"] = {
                    value: doctor_present.toLocaleLowerCase() === "oui" ? "1" : "0",
                    op: "is",
                };
            } else if (advancedSearch && operators.doctor_present.length !== 0) {
                data["docteur_present"] = {
                    value: doctor_present.toLocaleLowerCase() === "oui" ? "1" : "0",
                    op: operators.doctor_present,
                };
            }
        }
        if (comment && comment.length !== 0) {
            if (search) {
                data["commentaire"] = {
                    value: comment,
                    op: "smart",
                };
            } else if (advancedSearch && operators.comment.length !== 0) {
                data["commentaire"] = {
                    value: comment,
                    op: operators.comment,
                };
            }
        }
        if (revisited_at && revisited_at.length !== 0) {
            if (search) {
                data["a_revisiter_le"] = {
                    value: moment(revisited_at).format("YYYY-MM-DD"),
                    op: "is_date",
                };
            } else if (advancedSearch && operators.revisited_at.length !== 0) {
                data["a_revisiter_le"] = {
                    value: moment(revisited_at).format("YYYY-MM-DD"),
                    op: operators.comment,
                };
            }
        }
        return data;
    }
}

export default CalenderDAO;
