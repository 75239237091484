import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
// import { withRouter } from "react-router";

import styled from "styled-components";
import { connect } from "react-redux";
import Auth from "../services/Auth";
import { HeaderItem } from "./HeaderItem";
import { RoutesPath } from "../values/RoutesPath";
import UserDAO from "../DAO/UserDAO";
import { acl } from "../services/acl";
import "./Header.css";
import { MANAGE_PROFILE_URL } from "../env";
import NotificationHeaderItem from "./NotificationHeaderItem";
// function myFunction(x) {
//     if (x.matches) {
//         // If media query matches
//         document.body.style.backgroundColor = "yellow";
//     } else {
//         document.body.style.backgroundColor = "pink";
//     }
// }

// var x = window.matchMedia("(max-width: 700px)");
// myFunction(x); // Call listener function at run time
// x.addListener(myFunction);

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 1,
            display: "none",
            connected_user_infos: {},
        };
        this.showProfileMenu = this.showProfileMenu.bind(this);
        this.closeProfileMenu = this.closeProfileMenu.bind(this);
    }

    componentDidMount() {
        this.configuration()
    }
    configuration = () => {
        this.constructMenu();
        window.addEventListener('scroll', this.handleScroll);
    }


    render() {
        const { display } = this.state;
        const connected_user_infos = this.props.connectedUserInfos;
        const { acl_actions, notificationTotalItem } = this.props;
        console.log("totlae notif ", notificationTotalItem)
        // const acl = new Acl(acl_actions);

        return (
            <Container className="Container">
                <Wrapper id="parentContainer" className="Wrapper">
                    <Link to={"/"}>
                        <Logo id="logo" className="Logo">
                            <Strong>sobrus </Strong>LABS
                        </Logo>
                    </Link>
                    {acl.notification.list() && <NotificationHeaderItem name="notifications" to={RoutesPath.notification.list} itemTitle={notificationTotalItem > 1 ? "Notifications" : "Notification"} {...this.props} />}
                    <Nav id="nabBar" className="nav-header">
                        <Ul>

                            <HeaderItem name="board" to={RoutesPath.dashboard.list} itemTitle="Tableau de bord" {...this.props} />
                            {acl.product.my_product() && <HeaderItem name="product" to={RoutesPath.product.my_product} itemTitle="Produits" {...this.props} />}
                            {(acl.resource.list_pharmacies() || acl.resource.list_healthcareestablishments() || acl.resource.list_associations()) && (
                                <HeaderItem name="resource" to={RoutesPath.resource.list_pharmacies} itemTitle="Ressources" {...this.props}>
                                    <Sub_menu minWidth="210px">
                                        <Bloc_2>
                                            <ul>
                                                {acl.resource.list_pharmacies() && (
                                                    <Link to={RoutesPath.resource.list_pharmacies}>
                                                        <Item>Pharmacies</Item>
                                                    </Link>
                                                )}
                                                {acl.resource.list_healthcareestablishments() && (
                                                    <Link to={RoutesPath.resource.list_healthcareestablishments}>
                                                        <Item>Établissement de santé</Item>
                                                    </Link>
                                                )}
                                                {acl.resource.list_associations() && (
                                                    <Link to={RoutesPath.resource.list_associations}>
                                                        <Item>Associations</Item>
                                                    </Link>
                                                )}
                                            </ul>
                                        </Bloc_2>

                                        {/* <Bloc_4>
                                        <Bloc_4_text>
                                            {"Activer la commande en ligne".toLocaleUpperCase()}
                                            <br />
                                            {"avec + de 10 fournisseurs".toLocaleUpperCase()}
                                        </Bloc_4_text>
                                        <Bloc_4_circle>
                                            <Bloc_4_img src={require("../images/shop_cart.png")}></Bloc_4_img>
                                        </Bloc_4_circle>
                                        <Bloc_4_btn>Commander maintenant</Bloc_4_btn>
                                    </Bloc_4> */}
                                    </Sub_menu>
                                </HeaderItem>
                            )}
                            {acl.account.list() && <HeaderItem name="account" to={RoutesPath.account.list} itemTitle="Comptes" {...this.props} />}
                            {acl.contact.list() && <HeaderItem name="contact" to={RoutesPath.contact.list} itemTitle="Contacts" {...this.props} />}
                            {acl.demand.list() && <HeaderItem name="demandes" to={RoutesPath.demand.list} itemTitle="Demandes" {...this.props} />}
                            <HeaderItem name="publication" to={RoutesPath.publication.list} itemTitle="Publications" {...this.props} />
                            <HeaderItem name="purches" to={RoutesPath.sales.list} itemTitle="Ventes" {...this.props} />
                            {acl.planning.list() && <HeaderItem name="planning" to={RoutesPath.planning.list} itemTitle="Planning" itemIndex={10} {...this.props} />}
                            {acl.calender.list() && <HeaderItem name="calender" to={RoutesPath.calender.list} itemTitle="Calendrier" {...this.props} />}
                            {acl.inventory.list() && <HeaderItem name="inventory" to={RoutesPath.inventory.list} itemTitle="Inventaires" {...this.props} />}
                            {<HeaderItem to={RoutesPath.report.home} name="rapport" itemTitle="Rapports" itemIndex={10} {...this.props} />}
                            {acl.gallery.list() && <HeaderItem name="gallery" className="gallery" to={RoutesPath.gallery.list} itemTitle="Galerie" {...this.props} />}
                            <HeaderItem name="settings" to={RoutesPath.setting.profile.list} itemTitle="Paramètres" itemIndex={9} {...this.props} />
                            <HeaderItem className="more" to={""} itemTitle="Plus" {...this.props}>
                                <Sub_menu minWidth="210px">
                                    <Bloc_2>
                                        <ul>
                                            {acl.account.list() && (
                                                <Link className="more" name="account" to={RoutesPath.account.list}>
                                                    <Item>Comptes</Item>
                                                </Link>
                                            )}
                                            {acl.contact.list() && (
                                                <Link className="more" name="contact" to={RoutesPath.contact.list}>
                                                    <Item>Contacts</Item>
                                                </Link>
                                            )}
                                            {acl.demand.list() && <Link className="more" name="demandes" to={RoutesPath.demand.list} >
                                                <Item>Demandes</Item>
                                            </Link>}

                                            {
                                                <Link className="more" name="publication" to={RoutesPath.publication.list}>
                                                    <Item>Publications</Item>
                                                </Link>
                                            }
                                            {
                                                <Link className="more" name="purches" to={RoutesPath.sales.list}>
                                                    <Item>Ventes</Item>
                                                </Link>
                                            }
                                            {acl.planning.list() && (
                                                <Link className="more" name="planning" to={RoutesPath.planning.list}>
                                                    <Item>Planning</Item>
                                                </Link>
                                            )}
                                            {acl.calender.list() && (
                                                <Link className="more" name="calender" to={RoutesPath.calender.list}>
                                                    <Item>Calendrier</Item>
                                                </Link>
                                            )}
                                            {acl.inventory.list() && (
                                                <Link className="more" name="inventory" to={RoutesPath.inventory.list}>
                                                    <Item>Inventaires</Item>
                                                </Link>
                                            )}
                                            {
                                                <Link className="more" name="rapport" to={RoutesPath.report.home}>
                                                    <Item>Rapports</Item>
                                                </Link>
                                            }

                                            {acl.gallery.list() && (
                                                <Link className="more" name="gallery" to={RoutesPath.gallery.list}>
                                                    <Item>Galerie</Item>
                                                </Link>
                                            )}

                                            {
                                                <Link className="more" name="settings" to={RoutesPath.setting.profile.list}>
                                                    <Item>Paramètres</Item>
                                                </Link>
                                            }
                                        </ul>
                                    </Bloc_2>
                                </Sub_menu>
                            </HeaderItem>
                        </Ul>
                    </Nav>
                    <User_param id="userInfo" onClick={this.showProfileMenu}>
                        <Avatar href="#" title="Changer d’utilisateur">
                            {(connected_user_infos.fullname && connected_user_infos.fullname.substring(0, 2).toLocaleUpperCase()) || "..."}
                        </Avatar>
                        <User_name>{connected_user_infos.fullname}</User_name>
                        <span className="profil_arrow">.</span>
                        <Sub_menu_user_param display={display}>
                            <Avatar_user_param>
                                <svg width={26} height={26} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" className="svg-inline--fa fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                        fill="#fff"
                                        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                                    ></path>
                                </svg>
                            </Avatar_user_param>
                            <Name_user_param>{connected_user_infos.fullname || "Chargement..."}</Name_user_param>
                            {/* <Reference_user_param>
                                Référence client : <span style={{ fontWeight: "bold", color: "#969696" }}>Ma-2345-24343</span>
                            </Reference_user_param> */}
                            <Infos_list>
                                <Item_list
                                    onClick={() => {
                                        var win = window.open(`${MANAGE_PROFILE_URL}/account`, "_blank");
                                        win.focus();
                                    }}
                                >
                                    <a>Mon profil</a>
                                </Item_list>
                                {/* <Item_list>
                                    Mon abonnements
                                </Item_list>
                                <Item_list>
                                    Mes demandes
                                </Item_list> */}
                                <LogOut_Item_list
                                    onClick={() => {
                                        Auth.logout();
                                        // this.props.history.push("/login")
                                    }}
                                >
                                    <LogOut_text>Déconnexion</LogOut_text>
                                    <LogOut_icon width={14} height={14} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="power-off" className="svg-inline--fa fa-power-off fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path
                                            fill="#F84B4B"
                                            d="M400 54.1c63 45 104 118.6 104 201.9 0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 173.1 48.9 99.3 111.8 54.2c11.7-8.3 28-4.8 35 7.7L162.6 90c5.9 10.5 3.1 23.8-6.6 31-41.5 30.8-68 79.6-68 134.9-.1 92.3 74.5 168.1 168 168.1 91.6 0 168.6-74.2 168-169.1-.3-51.8-24.7-101.8-68.1-134-9.7-7.2-12.4-20.5-6.5-30.9l15.8-28.1c7-12.4 23.2-16.1 34.8-7.8zM296 264V24c0-13.3-10.7-24-24-24h-32c-13.3 0-24 10.7-24 24v240c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24z"
                                        ></path>
                                    </LogOut_icon>
                                </LogOut_Item_list>
                            </Infos_list>
                        </Sub_menu_user_param>
                    </User_param>
                </Wrapper>
            </Container>
        );
    }
    handleScroll = (event) => {
        const header = document.querySelector("header");
        const headband = document.querySelector(".Headband");
        let height = headband ? headband.offsetHeight : 50;
        if (window.scrollY > height) {
            header && header.classList.add("fixed_nav");
        } else {
            header && header.classList.remove("fixed_nav");
        }
    }

    constructMenu = () => {
        const header = parseInt(document.getElementsByTagName("header")[0].getBoundingClientRect().width);
        const parentContainer = parseInt(document.getElementById("parentContainer").getBoundingClientRect().width);
        const navSize = parseInt(document.getElementById("nabBar").getBoundingClientRect().width);
        const logo = parseInt(document.getElementById("logo").getBoundingClientRect().width);
        const userInfosBolck = parseInt(document.getElementById("userInfo").getBoundingClientRect().width);
        let max_width = logo + navSize + userInfosBolck + 15 + (header - parentContainer);

        function myFunction(x) {
            if (x.matches) {
                // If media query matches
                const navItem = document.querySelector(".nav-header > ul > div:nth-last-child(2)");
                const navItem2 = document.querySelector(".nav-header > ul > div:nth-last-child(3)");
                const moreItem = document.querySelector(".nav-header > ul > div:nth-last-child(1)");

                navItem.style.display = "none";
                navItem2.style.display = "none";
                moreItem.style.display = "inline-block";

                moreItem.querySelector(`div div ul a[name=${navItem.getAttribute("name")}]`).style.display = "block";
                moreItem.querySelector(`div div ul a[name=${navItem2.getAttribute("name")}]`).style.display = "block";
            } else {
                const navItem = document.querySelector(".nav-header > ul > div:nth-last-child(2)");
                const navItem2 = document.querySelector(".nav-header > ul > div:nth-last-child(3)");
                const moreItem = document.querySelector(".nav-header > ul > div:nth-last-child(1)");

                navItem.style.display = "inline-block";
                navItem2.style.display = "inline-block";
                moreItem.style.display = "none";
                moreItem.querySelector(`div div ul a[name=${navItem.getAttribute("name")}]`).style.display = "none";
                moreItem.querySelector(`div div ul a[name=${navItem2.getAttribute("name")}]`).style.display = "none";
            }
        }

        var x = window.matchMedia(`(max-width: ${max_width + 20}px)`);
        myFunction(x); // Call listener function at run time
        x.addListener(myFunction);
        const navItems = document.querySelectorAll(".nav-header > ul > div");

        let max_width_2 = max_width + 45;
        const moreItem = document.querySelector(".nav-header > ul > div:nth-last-child(1)");

        for (var i = navItems.length - 3; i >= 3; i--) {
            max_width_2 = max_width_2 - parseInt(navItems[i].getBoundingClientRect().width);
            var mediaQuary = window.matchMedia(`(max-width: ${max_width_2 + 20}px)`);

            if (mediaQuary.matches) {
                // If media query matches
                navItems[i].style.display = "none";
                if (moreItem.querySelector(`div div ul a[name=${navItems[i].getAttribute("name")}]`)) {
                    moreItem.querySelector(`div div ul a[name=${navItems[i].getAttribute("name")}]`).style.display = "block";
                }
            } else {
                navItems[i].style.display = "inline-block";
                if (moreItem.querySelector(`div div ul a[name=${navItems[i].getAttribute("name")}]`)) {
                    moreItem.querySelector(`div div ul a[name=${navItems[i].getAttribute("name")}]`).style.display = "none";
                }
            }
            let itemHidden = navItems[i];
            mediaQuary.addListener((x) => {
                if (x.matches) {
                    // If media query matches
                    itemHidden.style.display = "none";
                    if (moreItem.querySelector(`div div ul a[name=${itemHidden.getAttribute("name")}]`)) {
                        moreItem.querySelector(`div div ul a[name=${itemHidden.getAttribute("name")}]`).style.display = "block";
                    }
                } else {
                    itemHidden.style.display = "inline-block";
                    if (moreItem.querySelector(`div div ul a[name=${itemHidden.getAttribute("name")}]`)) {
                        moreItem.querySelector(`div div ul a[name=${itemHidden.getAttribute("name")}]`).style.display = "none";
                    }
                }
            });
        }

        // window.addEventListener("resize", (e) => {
        // (parseInt(navSize.width));
        // if (parseInt(window.innerWidth) === parseInt(navSize.width)) {
        //     ("ok");
        // }
        // const navSize = parseInt(document.getElementById("nabBar").getBoundingClientRect().width);
        // const logo = parseInt(document.getElementById("logo").getBoundingClientRect().width);
        // const userInfosBolck = parseInt(document.getElementById("userInfo").getBoundingClientRect().width);
        // const parentContainer = parseInt(document.getElementById("parentContainer").getBoundingClientRect().width);
        // ("parentwidth = " + parentContainer);
        // ("childs = " + (logo + navSize + userInfosBolck + 15 + 15));
        // if (parentContainer - (logo + navSize + userInfosBolck + 15 + 15) < 0) {
        //     const lastChilds = document.getElementById("nabBar").querySelectorAll("ul > div");
        //     for (var i = lastChilds.length - 1; i >= 0; i--) {
        //         (lastChilds[i]);
        //         if (lastChilds[i].style.display !== "none") {
        //             (lastChilds[i]);
        //             lastChilds[i].style.display = "none";
        //             break;
        //         }
        //     }
        //     // lastChild.style.display = "none";
        // }
        // else if (parentContainer - (logo + navSize + userInfosBolck + 15 + 15) > 0) {
        //     const lastChilds = document.getElementById("nabBar").querySelectorAll("ul > div");
        //     for (var i = 0; i < lastChilds.length; i++) {
        //         (lastChilds[i]);
        //         if (lastChilds[i].style.display === "none") {
        //             (lastChilds[i]);
        //             lastChilds[i].style.display = "inline-block";
        //             break;
        //         }
        //     }
        // }
        // });
    }
    showProfileMenu(event) {
        event.preventDefault();

        this.setState({ display: "block" }, () => {
            document.addEventListener("click", this.closeProfileMenu);
        });
    }

    closeProfileMenu() {
        this.setState({ display: "none" }, () => {
            document.removeEventListener("click", this.closeProfileMenu);
        });
    }
}
const LogOut_icon = styled.svg`
    float: right;
    margin-top: 3px;
`;
const LogOut_text = styled.span`
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #f84b4b;
    float: left;
`;
const Container = styled.header`
    height: 50px;
    background: #00beb0;
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    font: 12px "Open Sans", sans-serif;
    font-weight: 400;
    color: #494949;
    box-sizing: border-box;
    position: relative;
`;
const Sub_menu = styled.div`
    ${"" /* min-width: 540px; */}
    min-width: ${(props) => props.minWidth};
    background: #fff;
    border: 1px solid #c3c3c3;
    ${"" /* height:232px; */}
    ${"" /* height: 289px; */}
    display:none;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-left-color: #00beb0;
    border-left-width: 5px;
`;

const Bloc_2 = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    padding-left: 11px;
    padding-top: 11px;
    border-right: solid 1px #f5f5f5;
`;

const Item = styled.li`
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.color ? props.color : "#494949")};
    padding-bottom: 22px;
    padding-right: 8px;
    cursor: pointer;
    &:hover {
        color: #00beb0;
    }
`;

const Bloc_3 = styled.div`
    display: inline-block;
    width: 246px;
    height: 100%;
    vertical-align: top;
    padding-left: 11px;
    padding-top: 11px;
    border-right: solid 1px #f5f5f5;
`;
const Bloc_4 = styled.div`
    display: inline-block;
    background-color: #00beb0;
    height: 100%;
    width: 48%;
    text-align: center;
    padding-top: 63px;
`;
const Bloc_4_text = styled.p`
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    color: #fff;
`;
const Bloc_4_circle = styled.div`
    height: 80px;
    width: 80px;
    border-radius: 800px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    position: relative;
`;
const Bloc_4_img = styled.img`
    width: 38px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const Bloc_4_btn = styled.button`
    width: 150px;
    padding: 6px 0;
    border-radius: 4px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: #00beb0;
    text-align: center;
    border-width: 0;
    font-weight: 600;
`;
const Wrapper = styled.div`
    width: 96.2%;
    margin: auto;
`;
const Logo = styled.span`
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    line-height: 50px;
    float: left;
    outline: 0;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
`;
const Strong = styled.strong`
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
`;
const Nav = styled.nav`
    display: inline-block;
    vertical-align: top;
    margin-left:5px;
    ${"" /* margin-right: 15px; */}
`;
const Ul = styled.ul`
    list-style: none;
`;
const Li = styled.li`
    display: inline-block;
`;
const LiContainer = styled.div`
    display: inline-block;
    &:hover {
        position: relative;
        > div {
            z-index: 99;
            position: absolute;
            top: 71%;
            left: 15%;

            display: block;
        }
    }
`;

const Text = styled.span`
    padding: 5px 8px 5px 8px;
    background-color: transparent;
    border-radius: 3px;
    color: #fff;
    transition: all ease-in-out 0.2s;
    margin: 0 2px;
    line-height: 50px;
    font-weight: 900;
    outline: 0;
    text-decoration: none;
    &:hover {
        color: #037d74;
    }
`;
const Selected_text = styled.span`
    padding: 5px 8px 5px 8px;
    background-color: #00a89b;
    border-radius: 3px;
    color: #fff;
    transition: all ease-in-out 0.2s;
    margin: 0 2px;
    line-height: 50px;
    font-weight: 900;
    outline: 0;
    text-decoration: none;
`;
// const Selected_text = styled.span`
//     padding: 5px 8px 5px 8px;
//     background-color: #00a89b;
//     border-radius: 3px;
//     color: #fff;
//     font-weight: 900;
//     outline: 0;
//     text-decoration: none;
// `;
const User_param = styled.div`
    padding: 7px 10px 0 20px;
    float: right;
    cursor: pointer;
    border-left: 1px solid #0ba89c;
    height: 50px;
    &:hover {
        ${"" /* > div {
      display: block;
    } */}
    }
`;
const Sub_menu_user_param = styled.div`
    ${"" /* min-width: 540px; */}
    display:${(props) => props.display}
  min-width: 273px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #c3c3c3;
    background-color: #ffffff;
    height: 242px;
    ${"" /* height: 330px; */}
    z-index:99;
    position: absolute;
    top: 50px;
    right: 0;
    padding-top: 17px;
    &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background: #fff;
        right: 34px;
        top: -6px;
        transform: rotate(45deg);
        ${"" /* border-top: solid 1px #c3c3c3;
    border-left: solid 1px #c3c3c3; */}
    }
`;

const Avatar_user_param = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 600px;
    border: solid 1px #969696;
    background-color: #cbcbcb;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 13px;
`;

const Name_user_param = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #494949;
    text-align: center;
    margin-top: 11px;
`;

const Reference_user_param = styled.p`
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #969696;
    text-align: center;
    margin-top: 6px;
`;
const Infos_list = styled.div`
    padding-top: 14px;
`;
const Item_list = styled.div`
    border-top: solid 1px #f5f5f5;
    height: 45px;
    width: 201px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #494949;
    padding-top: 13px;
`;
const LogOut_Item_list = styled.div`
    border-top: solid 1px #f5f5f5;
    height: 38px;
    width: 208px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
`;

const Avatar = styled.a`
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #f6ac32;
    ${"" /* background-color: #f6ac32;
    border: 1px solid #fff; */}
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    ${"" /* background-image: url(${avatar});
    background-repeat: no-repeat;
    background-position-x: 6px;
    background-position-y: 6px; */}
    transition: all ease-in-out .2s;
    outline: 0;
    text-decoration: none;
    ${"" /* &:hover{
        background-color: #03a79b;
    } */}
    font-size: 13px;
    font-weight: 900;
    color: #fff;
`;
const User_name = styled.p`
    margin: 6px 10px 0 10px;
    float: left;
    color: #fff;
`;
const mapStateToProps = (state) => {
    return {
        acl_actions: state.ACL.acl_actions,
        connectedUserInfos: state.User.connectedUserInfos,
        notificationTotalItem: state.Notification.notificationTotalItem
    };
};
// const NavigationHeader = withRouter(Header)

export default withRouter(connect(mapStateToProps)(Header));
