import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";

class SettingsAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showParameter: false,
            showUsers: false,
            showParameterApp: false,
            showParameterPro: false,
        };
    }
    render() {
        const { showParameter, showUsers, showParameterApp, showParameterPro } = this.state;
        return (
            <Main_container className="Main_container">
                <TopOptions />
                <Content className="Content">
                    <Right_block_container>
                        <Side_bar_block>
                            <ul>
                                <Li background="#f6f6f6">
                                    <Li_container onClick={() => this.setState({ showParameter: showParameter ? false : true })}>
                                        <Col_1_li>Mes paramètres</Col_1_li>
                                        <Angle_icon>
                                            {showParameter ? (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                                </Svg>
                                            ) : (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
                                                </Svg>
                                            )}
                                        </Angle_icon>
                                    </Li_container>
                                    <Under_list display={showParameter ? "block" : "none"}>
                                        <Under_list_li>Profil</Under_list_li>
                                        <Under_list_li>Changer mon mot de passe</Under_list_li>
                                    </Under_list>
                                </Li>
                                <Li background="#f6f6f6">
                                    <Li_container onClick={() => this.setState({ showUsers: showUsers ? false : true })}>
                                        <Col_1_li>Utilisateurs</Col_1_li>
                                        <Angle_icon>
                                            {showUsers ? (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                                </Svg>
                                            ) : (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
                                                </Svg>
                                            )}
                                        </Angle_icon>
                                    </Li_container>
                                    <Under_list display={showUsers ? "block" : "none"}>
                                        <Under_list_li>Liste des utilisateurs</Under_list_li>
                                        <Under_list_li>Historiques des connexions</Under_list_li>
                                    </Under_list>
                                </Li>
                                <Li background="#f6f6f6">
                                    <Li_container onClick={() => this.setState({ showParameterApp: showParameterApp ? false : true })}>
                                        <Col_1_li>Paramètres de l'application</Col_1_li>
                                        <Angle_icon>
                                            {showParameterApp ? (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                                </Svg>
                                            ) : (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
                                                </Svg>
                                            )}
                                        </Angle_icon>
                                    </Li_container>
                                    <Under_list display={showParameterApp ? "block" : "none"}>
                                        <Under_list_li>Paramètres de l'entreprise</Under_list_li>
                                        <Under_list_li>Logo de l'entreprise</Under_list_li>
                                        <Under_list_li>En-tête du PDF</Under_list_li>
                                        <Under_list_li>Pied de page du PDF</Under_list_li>
                                    </Under_list>
                                </Li>
                                <Li background="#f6f6f6">
                                    <Li_container onClick={() => this.setState({ showParameterPro: showParameterPro ? false : true })}>
                                        <Col_1_li>Paramètres de produits</Col_1_li>
                                        <Angle_icon>
                                            {showParameterPro ? (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                                </Svg>
                                            ) : (
                                                <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-up" class="svg-inline--fa fa-angle-up fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path fill="#494949" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
                                                </Svg>
                                            )}
                                        </Angle_icon>
                                    </Li_container>
                                    <Under_list display={showParameterPro ? "block" : "none"}>
                                        <Under_list_li> Gammes de produits actives</Under_list_li>
                                    </Under_list>
                                </Li>
                            </ul>
                        </Side_bar_block>
                    </Right_block_container>
                    <Left_block_container>
                        <First_block>
                            <div className="bloc_header">
                                <h2>Changer mon mot de passe</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Block_content_title>Cette page vous permet de modifier votre mot de passe.</Block_content_title>
                            </Block_content>
                        </First_block>
                        <Block>
                            <div className="bloc_header">
                                <h2>Changer mon mot de passe</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Changer mon mot de passe</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <CustomInput id="old" label="Ancien mot de passe *"></CustomInput>
                                        </Col_1>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <CustomInput id="new" label="Nouveau mot de passe *"></CustomInput>
                                        </Col_1>
                                        <Col_2>
                                            <CustomInput id="confirm" label="Confirmation du mot de passe *"></CustomInput>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                    </Left_block_container>
                </Content>
            </Main_container>
        );
    }
}

export default SettingsAddPage;

const Main_container = styled.div`
    height: 100%;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 10px;
`;
const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 20px;
    width: 100%;
`;
const Col_1 = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Col_2 = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-right: 27px;
`;
const Side_bar_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;

const Li = styled.li`
    cursor: pointer;
    position: relative;
    background: ${(props) => props.background};
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #00beb0;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Angle_icon = styled.div`
    position: absolute;
    width: 13px;
    top: 10px;
    right: 6px;
    height: 10px;
`;
const Svg = styled.svg`
    width: 12px;
`;

const Under_list = styled.ul`
    width: 100%;
    padding-bottom: 4px;
    background: #fff;
    display: ${(props) => props.display};
`;

const Li_container = styled.div`
    width: 100%;
    border-top: 1px solid #e5e5e5;
    padding: 10px 5px;
    cursor: pointer;
    &:hover {
        background: #f6f6f6;
    }
`;
const Under_list_li = styled.li`
    display: block;
    padding: 2px 9px;
    color: #494949;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 3px;
    &:hover {
        color: #00beb0;
    }
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;

const Title_container = styled.div`
    position: relative;
`;

const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
