import styled from "styled-components";
import React, { Component } from "react";
import CustomInput from "../CustomInput";
import CustomSelect from "../CustomSelect";
import moment from "moment";
import SearchSelect from "../../helpers/SearchSelect";

import DatePicker, { registerLocale } from "react-datepicker";
import CustomDatePicker from "../../components/CustomDatePicker";

export const BasicSearchComp = (props) => {
    const { columns, searchType, onSearch } = props;
    const DateComp = ({ value, onClick }) => <CustomDatePicker error={false} onClick={onClick} defaultValue={value} id="name2" label={"Date"}></CustomDatePicker>;
    return (
        <tr className="table_th_orderable">
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <CustomInput onKeyDown={() => {}} onChange={columns.name.onChange} label="Nom"></CustomInput>
                    <Search_icon>
                        <Svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="currentColor"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Svg>
                    </Search_icon>
                </Search_input_container>
            </Th>
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <DatePicker selected={columns.start_date.value} onChange={columns.start_date.onChange} showTimeSelect timeFormat="HH:mm" timeIntervals={15} timeCaption="time" dateFormat="yyyy-MM-d HH:mm" todayButton="Aujourd'hui" popperPlacement="bottom-center" customInput={<DateComp />} />
                </Search_input_container>
            </Th>
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <DatePicker selected={columns.end_date.value} onChange={columns.end_date.onChange} showTimeSelect timeFormat="HH:mm" timeIntervals={15} timeCaption="time" dateFormat="yyyy-MM-d HH:mm" todayButton="Aujourd'hui" popperPlacement="bottom-center" customInput={<DateComp />} />
                </Search_input_container>
            </Th>

            <Th backgroundColor="#fff">
                <Search_input_container>
                    <SearchSelect fetchedUrl="/api/utilisateurs" HTTPMethod="post" receivedObject="utilisateurs" optionLabelProperty="fullname" optionValueProperty="id" value={columns.manager.value} placeholder="Gestinnaire" onChange={columns.manager.onChange} />
                </Search_input_container>
            </Th>
            {searchType == "advanced" ? (
                <Th backgroundColor="#fff"></Th>
            ) : (
                <Th backgroundColor="#fff">
                    <Search_btn onClick={onSearch}>
                        <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                fill="#fff"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                        </Search_btn_icon>
                    </Search_btn>
                </Th>
            )}
        </tr>
    );
};

export const AdvancedSearchComp = (props) => {
    const { columns } = props;

    return (
        <SearchTr className="table_th_orderable">
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <CustomSelect onKeyDown={() => {}} error={false} onChange={columns.name.onChange} selectedValue={columns.name.operator} label="Nom" operatorsType="detailed" />
                </Search_input_container>
            </Th>
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <CustomSelect onKeyDown={() => {}} error={false} onChange={columns.start_date.onChange} selectedValue={columns.start_date.operator} label="Date" operatorsType="detailed" />
                </Search_input_container>
            </Th>
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <CustomSelect onKeyDown={() => {}} error={false} onChange={columns.end_date.onChange} selectedValue={columns.end_date.operator} label="Nom" operatorsType="detailed" />
                </Search_input_container>
            </Th>
            <Th backgroundColor="#fff">
                <Search_input_container>
                    <CustomSelect onKeyDown={() => {}} error={false} onChange={columns.manager.onChange} selectedValue={columns.manager.operator} label="Nom" operatorsType="detailed" />
                </Search_input_container>
            </Th>

            <Th backgroundColor="#fff"></Th>
        </SearchTr>
    );
};

const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
