import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import ContactListTable from "../components/ContactListTable";
import GalleryListTable from "../components/GalleryListTable";
import EventListTable from "../components/EventListTable";
import InventoryListTable from "../components/InventoryListTable";
import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import AccountDAO from "../DAO/AccountDAO";
import InventoryDAO from "../DAO/InventoryDAO";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import { Link } from "react-router-dom";
import { acl } from "../services/acl";
import DemandListTable from "../components/DemandListTable";

class DetailsAccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            contacts: [],
            contactsTotalItems: 0,
            gallery: [],
            galleryTotalItems: 0,
            events: [],
            eventsTotalItems: 0,
            inventories: [],
            inventoriesTotalItems: 0,
            successMessage: "",
            is_loaded: false,
        };
    }
    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        this.get();
        this.getInventories();
    }

    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await AccountDAO.getAccountById(id, {});
            this.setState({ account: response.data, is_loaded: true });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async getInventories() {
        try {
            const accountId = this.props.match.params.id;
            const response = await InventoryDAO.getAccountInventory(accountId);
            this.setState({ inventories: response.data.inventaires, inventoriesTotalItems: response.data.total });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    async delete(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 1,
                };
                const response = await AccountDAO.deleteAccount(id, {}, data);
                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.delete, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }

                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async restore(id) {
        try {
            var delete_item = window.confirm("Etes-vous sûr de vouloir faire effectuer cette action, les modifications seront définitives !");
            if (delete_item) {
                const data = {
                    disabled: 0,
                };
                const response = await AccountDAO.deleteAccount(id, {}, data);

                if (response.data && response.data.status === "ok") {
                    notify({ msg: MessagesText.account.restore, type: "success" });
                    this.get();
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
                // this.props.history.push(`${RoutesPath.account.list}`);
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    getResources(resourceSearch) {
        if (resourceSearch === "Pharmacie") {
            return "pharmacie";
        } else if (resourceSearch === "Etablissement de santé") {
            return "etablissement";
        } else if (resourceSearch === "Association") {
            return "association";
        }
    }
    render() {
        const { is_loaded, account, contacts, gallery, events, successMessage, inventories, contactsTotalItems, galleryTotalItems, eventsTotalItems, inventoriesTotalItems } = this.state;
        const title = account ? account.libelle : "";
        const id = this.props.match.params.id;

        return (
            <Main_container className="Main_container">
                <TopOptions title={"Compte : " + title}>
                    {is_loaded && !account.disabled && acl.account.disable() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.delete(id)}>
                            Archiver
                        </button>
                    )}
                    {is_loaded && account.disabled && acl.account.restore() && (
                        <button className="btn_red" data-shortcut_alias="create" onClick={() => this.restore(id)}>
                            Restaurer
                        </button>
                    )}
                    {is_loaded && !account.disabled && acl.account.update() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.account.update}/${id}`)}>
                            Modifier
                        </button>
                    )}
                </TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title>Informations générales</Title>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>{account && account.type_relation ? account.type_relation : "Pharmacie"}</Field_label>
                                            {/* <Field_value_green>{account && account.nom_ressource}</Field_value_green> */}
                                            {/* <Field_value>{account && account.nom_ressource}</Field_value> */}
                                            {is_loaded ? (
                                                account.id_ressource ? (
                                                    <Link to={`${RoutesPath.resource.details}/${this.getResources(account.type_relation)}/${account.id_ressource}`}>
                                                        <Field_value isLink={true}>{account && account.nom_ressource}</Field_value>
                                                    </Link>
                                                ) : (
                                                        <Field_value>{account && account.nom_ressource}</Field_value>
                                                    )
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Type</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{account && account.type_compte}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Gestionnaire</Field_label>
                                            {is_loaded ? (
                                                <Field_value>{account && account.gestionnaire}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Code</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{account && account.code}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{account && account.libelle}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Catégorie</Field_label>

                                            {is_loaded ? (
                                                <Field_value>{account && account.categorie}</Field_value>
                                            ) : (
                                                    <ContentLoader width="160" height="20">
                                                        <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                                    </ContentLoader>
                                                )}
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                            </Block_content>
                        </Block>
                        {/* contact Bloc */}
                        {acl.account.contacts() && account && account.id && (
                            <ContactListTable
                                limitForPagination={5}
                                noContactsCas={
                                    <Block_contact>
                                        <Title_contact>Contacts</Title_contact>
                                        <Btn_contact_container>{acl.contact.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.contact.add}/${id}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                accountId={account.id}
                                {...this.props}
                            />
                        )}
                        {/* end contact bloc */}
                        {/* events bloc */}
                        {acl.account.events() && account && account.id && (
                            <EventListTable
                                limitForPagination={5}
                                noEventsCas={
                                    <Block_contact>
                                        <Title_contact>Événements</Title_contact>
                                        <Btn_contact_container>{acl.calender.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.calender.add}/${id}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                accountId={account.id}
                                {...this.props}
                            />
                        )}
                        {/* end events bloc */}
                        {/* galley bloc */}
                        {acl.account.photos() && account && account.id && (
                            <GalleryListTable
                                limitForPagination={5}
                                noGalleryCas={
                                    <Block_contact>
                                        <Title_contact>Galerie</Title_contact>
                                        <Btn_contact_container>{acl.gallery.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.gallery.add}/${id}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                accountId={account.id}
                                {...this.props}
                            />
                        )}
                        {/* end gallery bloc */}
                        {/* inventory bloc */}

                        {acl.account.inventories() && account && account.id && (
                            <InventoryListTable
                                limitForPagination={5}
                                noInventoryCas={
                                    <Block_contact>
                                        <Title_contact>Inventaires</Title_contact>
                                        <Btn_contact_container>{acl.inventory.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.inventory.add}/${id}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                accountId={account.id}
                                {...this.props}
                            />
                        )}
                        {/* end inventory bloc */}
                        {/* demands bloc */}

                        {acl.account.demands() && account && account.id && (
                            <DemandListTable
                                limitForPagination={5}
                                noInventoryCas={
                                    <Block_contact>
                                        <Title_contact>Demandes</Title_contact>
                                        <Btn_contact_container>{acl.demand.add() && <Create_contact_btn onClick={() => this.props.history.push(`${RoutesPath.demand.add}`)}>Créer</Create_contact_btn>}</Btn_contact_container>
                                    </Block_contact>
                                }
                                accountId={account.id}
                                {...this.props}
                            />
                        )}
                        {/* end demands bloc */}
                    </Left_block_container>
                    <Right_block_container>
                        <div className="bloc">
                            <div className={account && account.disabled ? "bloc_content devis_statut ds_brouillon" : "bloc_content devis_statut ds_complete"}>
                                <h3>{account && account.disabled ? "Archivé" : "Actif"}</h3>
                            </div>
                        </div>
                        {/* <Command_block>
                            <Command_block_Title>Commandes des 12 derniers mois</Command_block_Title>
                            <Command_block_Total>
                                <Command_block_icon>
                                    <Img src={require("../images/commande_icon.png")}></Img>
                                </Command_block_icon>
                                <Command_block_value><Strong>0</Strong> Total commandes</Command_block_value>
                            </Command_block_Total>
                        </Command_block> */}
                        <Command_block>
                            <Command_block_Title>Raccourcis</Command_block_Title>
                            {acl.contact.add() && <Add_btn onClick={() => this.props.history.push(`${RoutesPath.contact.add}/${id}`)}>Créer un nouveau contact</Add_btn>}
                        </Command_block>

                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{account && account.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{account && moment(account.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{account && account.mis_a_jour_par ? account && account.mis_a_jour_par : account && account.cree_par}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>

                                    {is_loaded ? (
                                        <Col_1_li>{account && account.mis_a_jour_le ? account && moment(account.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : account && moment(account.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                    ) : (
                                            <ContentLoader width="160" height="20">
                                                <rect x="0" y="0" rx="3" ry="3" width="160" height="20" />
                                            </ContentLoader>
                                        )}
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
        acl_actions: state.ACL.acl_actions,
    };
};
export default connect(mapStateToProps)(DetailsAccountPage);

const Main_container = styled.div`
    height: 100%;
    min-width: 1390px;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    min-width: 1014px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value_green = styled.p`
    display: block;
    color: #00beb0;
    font: 12px "Open Sans", sans-serif;
`;
const Field_value = styled.p`
    display: block;
    font-size: ${(props) => (props.isLink ? "12px" : "14px")};
    font-weight: ${(props) => (props.isLink ? "400" : "700")};
    color: ${(props) => (props.isLink ? "#00beb0" : "")};
    &:hover {
        text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    }
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Block_contact = styled.div`
    margin-top: 10px;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #e7055a;
    color: #860b39 !important;
    height: 50px;
    padding-top: 14px;
    padding-left: 9px;
    padding-right: 9px;
`;
const Title_contact = styled.p`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
`;

const Btn_contact_container = styled.div`
    height: 25px;
    float: right;
    display: inline-block;
    vertical-align: top;
`;

const Create_contact_btn = styled.button`
    outline: none;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    height: 100%;
    padding: 0 22px;
    font-size: 12px;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Command_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    padding: 10px;
`;

const Command_block_Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`;

const Command_block_Total = styled.div`
    margin-top: 13px;
`;
const Command_block_icon = styled.div`
    width: 29px;
    height: 29px;
    border-radius: 3px;
    background-color: #ef396f;
    margin-right: 6px;
    display: inline-block;
    position: relative;
`;
const Img = styled.img`
    position: absolute;
    top: 5px;
    left: 7px;
`;
const Command_block_value = styled.div`
    display: inline-block;
    vertical-align: top;
    background-color: #f6f6f6;
    width: 88%;
    height: 29px;
    border-radius: 3px;
    margin-left: 2px;

    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 8px;
`;
const Strong = styled.strong`
    font-weight: 700;
    color: #4a4a4a;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
`;

const Add_btn = styled.button`
    margin-top: 13px;
    height: 25px;
    width: 100%;
    outline: none;
    background: #fff;
    border-radius: 2px;
    color: #494949;
    border: 1px solid #f0f0f0;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        border: 1px solid #b2b2b2;
    }
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;

