
 
 const initialState = {currentPathName:"/"}

 function  CurrentPathName(state =  initialState,action){
    let nextState;
    switch(action.type){
         case "CURRENT_PATH_NAME":
            nextState = {
                ...state,
                currentPathName:action.value 
            }
              return nextState || state;
        default:
            return state;
    }
}
export default CurrentPathName;