import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import ProductsTable from "../components/ProductsTable";
import styled from "styled-components";
import { connect } from "react-redux";
import { RoutesPath } from "../values/RoutesPath";
import { acl } from "../services/acl";
class ProductsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: false,
        };
    }
    async componentDidMount() {
        document.title = "Produits - Sobrus Labs";
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions title="Produits">
                    {acl.product.my_product() && (
                        <a onClick={() => this.props.history.push(`${RoutesPath.product.my_product}`)} className="btn_white">
                            Afficher mes produits
                        </a>
                    )}
                    {acl.product.suggestion.list() && (
                        <a onClick={() => this.props.history.push(`${RoutesPath.product.suggestion.list}`)} className="btn_white">
                            Historique des suggestions
                        </a>
                    )}
                    {acl.product.suggestion.add() && (
                        <button className="btn_bleu" data-shortcut_alias="create" onClick={() => this.props.history.push(`${RoutesPath.product.suggestion.add}`)}>
                            Suggérer un nouveau produit
                        </button>
                    )}
                </TopOptions>

                <ProductsTable {...this.props} />
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
const Search_container = styled.div`
    width: 100%;
    height: 62px;
    display: ${(props) => props.display};
    padding: 13px;
    background: #fff;
`;
const Search_input_container = styled.div`
    width: 100%;
    height: 38px;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    float: right;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const Th = styled.th`
    padding-right: 10px;
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(ProductsPage);
