import React, { Component } from "react";
import TopOptions from "../../components/TopOptions";
import styled from "styled-components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import ReportDAO from "../../DAO/ReportDAO";
import { connect } from "react-redux";
import moment from "moment";
import SearchSelect from "../../helpers/SearchSelect";
import UserDAO from "../../DAO/UserDAO";
import { DatePicker } from "../../components/CustomDatePicker";
import { notify } from "../../helpers/FlashMessage";

let options = {
    chart: {
        type: "pie",
    },
    title: {
        text: "",
    },
    colors: ["#28b9ac", "#1ab2d5", "#eda224", "#700242", "#03c48a"],
};

class VisitorByPurpose extends Component {
    constructor(props) {
        super(props);
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        this.state = {
            filter_fields: {
                started_date: lastMonth,
                end_date: new Date(),
                visitor: { value: 0, label: "Tout" },
            },
            statistical_fields: {
                show_statistical: false,
                merchandising: 0,
                formation: 0,
                other: 0,
                recouvrement: 0,
                after_purchase: 0,
                visits: 0,
                purchase: 0,
                accompagnement: 0,
            },
            data: {
                visits: [],
            },
            preload: {
                users: [],
            },
            accounts: [],
        };
    }
    async componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
        this.getVisitsByPurpose();
        // this.getConnectedUserInfos();
    }

    render() {
        const { filter_fields, statistical_fields, data, preload } = this.state;

        // after loading data and calc statistical
        options.series = [
            {
                data: [
                    ["Merchandising", statistical_fields.merchandising],
                    ["Formation", statistical_fields.formation],
                    ["Autre", statistical_fields.other],
                    ["Recouvrement", statistical_fields.recouvrement],
                    ["Service après-vente", statistical_fields.after_purchase],
                    ["Visite de courtoisie", statistical_fields.visits],
                    ["Vente", statistical_fields.purchase],
                    ["Accompagnement", statistical_fields.accompagnement],
                ],
            },
        ];

        return (
            <Main_container className="Main_container">
                <TopOptions title="Événements par visiteur/objectif de événement"></TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Options des rapports</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <FullRow>
                                <SearchSelect
                                    name="report"
                                    fetchedUrl="/api/utilisateurs"
                                    HTTPMethod="post"
                                    receivedObject="utilisateurs"
                                    optionLabelProperty="fullname"
                                    optionValueProperty="id"
                                    value={filter_fields.visitor}
                                    placeholder="Sélectionner un visiteur"
                                    onChange={(e) => {
                                        if (e && e.value.length !== 0) {
                                            this.onSearchChange(e);
                                        }
                                    }}
                                />
                            </FullRow>
                        </Row>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    selected={filter_fields.started_date}
                                    onChange={(date) => {
                                        this.setFieldValue("started_date", date, this.getVisitsByPurpose.bind(this));
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de début*"
                                    id="start_date_id"
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <DatePicker
                                    selected={filter_fields.end_date}
                                    onChange={(date) => {
                                        this.setFieldValue("end_date", date, this.getVisitsByPurpose.bind(this));
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    showTimeSelect={false}
                                    label="Date de fin*"
                                    id="end_date_id"
                                />
                            </Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
                <Bloc2 className="Bloc">
                    <div className="bloc_header">
                        <h2>Événements par objet</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">{statistical_fields.show_statistical && <HighchartsReact updateArgs={[true, true, true]} highcharts={Highcharts} options={options} />}</div>
                </Bloc2>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Événements par visiteur/objectif de événement</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content table_wrraper">
                        <table className="table processableTable" id="26555_e2d99ecca39dab4b0c717798144e1d8f" data-ajax_url="/invoices" data-object_table="invoices">
                            <thead>
                                <tr className="table_th_orderable">
                                    <Th></Th>
                                    <Th>Accompagnement</Th>
                                    <Th>Vente</Th>
                                    <Th>Visite de courtoisie</Th>
                                    <Th>Service après-vente</Th>
                                    <Th>Recouvrement</Th>
                                    <Th>Autre</Th>
                                    <Th>Formation</Th>
                                    <Th>Merchandising</Th>
                                    <Th>Total</Th>
                                    <Th>Total (%)</Th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.visits.length !== 0 &&
                                    data.visits.map((object, index) => {
                                        const row_total = this.getRowTotal([object["Merchandising"], object["Formation"], object[""], object["Recouvrement"], object["Service après-vente"], object["Visite de courtoisie"], object["Vente"], object["Accompagnement"]]);
                                        return (
                                            <Tr key={"key_" + index}>
                                                <Td>{object["fullname"]}</Td>
                                                <Td>{object["Accompagnement"]}</Td>
                                                <Td>{object["Vente"]}</Td>
                                                <Td>{object["Visite de courtoisie"]}</Td>
                                                <Td>{object["Service après-vente"]}</Td>
                                                <Td>{object["Recouvrement"]}</Td>
                                                <Td>{object[""]}</Td>
                                                <Td>{object["Formation"]}</Td>
                                                <Td>{object["Merchandising"]}</Td>
                                                <Td>{row_total}</Td>
                                                <Td>{`${this.getRowTotalPercent(
                                                    row_total,
                                                    statistical_fields.formation +
                                                        statistical_fields.merchandising +
                                                        statistical_fields.other +
                                                        statistical_fields.recouvrement +
                                                        statistical_fields.after_purchase +
                                                        statistical_fields.visits +
                                                        statistical_fields.purchase +
                                                        statistical_fields.accompagnement
                                                )}%`}</Td>
                                            </Tr>
                                        );
                                    })}

                                <Tr>
                                    <Td>{"Total"}</Td>
                                    <Td>{statistical_fields.accompagnement}</Td>
                                    <Td>{statistical_fields.purchase}</Td>
                                    <Td>{statistical_fields.visits}</Td>
                                    <Td>{statistical_fields.after_purchase}</Td>
                                    <Td>{statistical_fields.recouvrement}</Td>
                                    <Td>{statistical_fields.other}</Td>
                                    <Td>{statistical_fields.formation}</Td>
                                    <Td>{statistical_fields.merchandising}</Td>
                                    <Td>
                                        {statistical_fields.formation + statistical_fields.merchandising + statistical_fields.other + statistical_fields.recouvrement + statistical_fields.after_purchase + statistical_fields.visits + statistical_fields.purchase + statistical_fields.accompagnement}
                                    </Td>
                                    <Td>
                                        {statistical_fields.formation + statistical_fields.merchandising + statistical_fields.other + statistical_fields.recouvrement + statistical_fields.after_purchase + statistical_fields.visits + statistical_fields.purchase + statistical_fields.accompagnement ===
                                        0
                                            ? "0%"
                                            : `100%`}
                                    </Td>
                                </Tr>
                            </tbody>
                        </table>
                    </div>
                </Bloc>
            </Main_container>
        );
    }

    getRowTotal = (array) => {
        return array.reduce((total, item) => total + (item ? parseInt(item) : 0), 0);
    };

    getRowTotalPercent = (value, base) => {
        return base ? Number((value / base) * 100).toFixed(3) : 0;
    };

    formattedDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    setFieldValue = (fieldName, fieldValue, callBack = () => {}) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                filter_fields: {
                    ...prevState.filter_fields,
                    [fieldName]: fieldValue,
                },
            }),
            () => {
                if (this.state.filter_fields[fieldName] !== null) callBack();
            }
        );
    };

    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    filter_fields: {
                        ...prevState.filter_fields,
                        visitor: selectedOption,
                    },
                }),
                this.getVisitsByPurpose.bind(this)
            );
        }
    };

    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.onSearchChange({ value: response.data.id, label: response.data.fullname });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }

    getVisitsByPurpose = async () => {
        try {
            const { filter_fields } = this.state;
            if (filter_fields.started_date && filter_fields.end_date) {
                const response = await ReportDAO.getVisitsByPurpose(this.formattedDate(filter_fields.started_date), this.formattedDate(filter_fields.end_date), filter_fields.visitor.value === 0 ? "" : filter_fields.visitor.value);
                this.setState(
                    (prevState) => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            visits: response.data,
                        },
                    }),
                    () => {
                        let merchandising = 0;
                        let formation = 0;
                        let other = 0;
                        let recouvrement = 0;
                        let after_purchase = 0;
                        let visits = 0;
                        let purchase = 0;
                        let accompagnement = 0;

                        this.state.data.visits.map((object) => {
                            if (object["Merchandising"]) merchandising = merchandising + parseInt(object["Merchandising"]);
                            if (object["Formation"]) formation = formation + parseInt(object["Formation"]);
                            if (object[""]) other = other + parseInt(object[""]);
                            if (object["Recouvrement"]) recouvrement = recouvrement + parseInt(object["Recouvrement"]);
                            if (object["Service après-vente"]) after_purchase = after_purchase + parseInt(object["Service après-vente"]);
                            if (object["Visite de courtoisie"]) visits = visits + parseInt(object["Visite de courtoisie"]);
                            if (object["Vente"]) purchase = purchase + parseInt(object["Vente"]);
                            if (object["Accompagnement"]) accompagnement = accompagnement + parseInt(object["Accompagnement"]);
                        });
                        if (merchandising !== 0 || formation !== 0 || other !== 0 || recouvrement !== 0 || after_purchase !== 0 || visits !== 0 || purchase !== 0 || accompagnement !== 0) {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        merchandising: merchandising,
                                        formation: formation,
                                        other: other,
                                        recouvrement: recouvrement,
                                        after_purchase: after_purchase,
                                        visits: visits,
                                        purchase: purchase,
                                        accompagnement: accompagnement,
                                        show_statistical: true,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        } else {
                            this.setState(
                                (prevState) => ({
                                    ...prevState,
                                    statistical_fields: {
                                        ...prevState.statistical_fields,
                                        merchandising: merchandising,
                                        formation: formation,
                                        other: other,
                                        recouvrement: recouvrement,
                                        after_purchase: after_purchase,
                                        visits: visits,
                                        purchase: purchase,
                                        accompagnement: accompagnement,
                                        show_statistical: false,
                                    },
                                }),
                                () => this.state.statistical_fields
                            );
                        }
                    }
                );
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };
}

const FullRow = styled.div`
    width: 99%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 10px;
    background: #fff;
`;
const Bloc2 = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-top: 10px;
    background: #fff;
`;
const Th = styled.th`
    padding-right: 10px;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};

export default connect(mapStateToProps)(VisitorByPurpose);
