import React, { Component } from "react";
import styled from "styled-components";

const COLOR_ICON = {
    info: "#2fc5df",
};
class NoResultCas extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { type } = this.props;
        return (
            <NoResultCasContainer>
                <svg width={35} height={35} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" className="svg-inline--fa fa-exclamation-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        fill={type ? COLOR_ICON[type] : "#707070"}
                        d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    ></path>
                </svg>
                <TextInfos>{this.props.textInfos}</TextInfos>
                {this.props.btnText && this.props.btnText.length !== 0 && <NoResultCasBtn onClick={this.props.btnOnClick}>{this.props.btnText}</NoResultCasBtn>}
            </NoResultCasContainer>
        );
    }
}

const NoResultCasContainer = styled.div`
    height: 235px;
    background-color: #f8f8f8;
    text-align: center;
    padding-top: 40px;
    border-top-color: #8d8d8d;
    border-top-width: 1px;
    border-top-style: solid;
`;

const TextInfos = styled.div`
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    text-align: center;
    color: #5c5c5c;
    margin-top: 14px;
`;

const NoResultCasBtn = styled.button`
    width: 165px;
    height: 25px;
    border-radius: 3px;
    background-color: #00beb0;
    text-align: center;
    color: #ffffff;
    font-family: "Open Sans", sans-serif;
    font-size: 12.5px;
    margin-top: 25px;
    border-width: 0;
    cursor: pointer;
`;
export default NoResultCas;
