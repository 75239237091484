import React, { Component } from "react";
import styled from "styled-components";
import AclDAO from "../DAO/AclDAO";
import { Loading, OrderUp, OrderDown } from "../components/TableModel";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import { RoutesPath } from "../values/RoutesPath";
import CommonDAO from "../DAO/CommonDAO";
import ProductDAO from "../DAO/ProductDAO";

const thead = [
    { property: "action", label: "Action", type: "string" },
    { property: "ressource", label: "Est accessible", type: "checkBox", onCheck: () => {} },
];
class AffectACLMethods extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            selectedMethods: [],
            ranges: [],
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Sauvegarder", onClick: () => this.add() }] });
        this.preload();
    }

    render() {
        const { is_loaded, data, selectedMethods } = this.state;
        let leftCheckBox = [];
        let rightCheckBox = [];
        if (is_loaded && data) {
            for (let i = 0; i < parseInt(data.length / 2); i++) {
                leftCheckBox.push(
                    <div key={"product_line_key_" + data[i].id} className="form_row">
                        <label htmlFor={"product_line_id_" + data[i].id} style={{}}>
                            {data[i].nom}
                        </label>
                        <label htmlFor={"product_line_id_" + data[i].id} className="checkbox" style={{ display: "block" }}>
                            <input type="checkbox" value={data[i].id} name={"product_line_id_" + data[i].id} id={"product_line_id_" + data[i].id} autoComplete="off" />
                            <span className="checked" />
                        </label>{" "}
                    </div>
                );
            }
            for (let i = parseInt(data.length / 2); i < data.length; i++) {
                rightCheckBox.push(
                    <div key={"product_line_key_" + data[i].id} className="form_row">
                        <label htmlFor={"product_line_id_" + data[i].id} style={{}}>
                            {data[i].nom}
                        </label>
                        <label htmlFor={"product_line_id_" + data[i].id} className="checkbox" style={{ display: "block" }}>
                            <input type="checkbox" value={data[i].id} name={"product_line_id_" + data[i].id} id={"product_line_id_" + data[i].id} autoComplete="off" />
                            <span className="checked" />
                        </label>{" "}
                    </div>
                );
            }
        }
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Paramètres de produits > Gammes de produits actives > Modifier</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page vous permet de désactiver/activer les gammes de produits.</Block_content_title>
                    </Block_content>
                </First_block>

                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Modifier gammes de produits actives</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <div className="bloc_content">
                        <div className="container4_in">
                            <div className="gri4">
                                <div className="bloc_h3">
                                    <h3>Gammes de produit</h3>
                                    <span />
                                </div>
                                <div className="grid2">
                                    <div className="form_elem_wrraper">{is_loaded && data && leftCheckBox.map((object) => object)}</div>
                                </div>
                                <div className="grid2">
                                    <div className="form_elem_wrraper">{is_loaded && data && rightCheckBox.map((object) => object)}</div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </Bloc>
            </>
        );
    }

    async add() {
        try {
            const checkedInputs = document.querySelectorAll("input[type=checkbox]:checked");
            let ranges = [];

            for (let i = 0; i < checkedInputs.length; i++) {
                ranges.push(parseInt(checkedInputs[i].value));
            }

            const params = {};
            const data = {
                gammes: ranges,
            };
            const response = await ProductDAO.updateActiveRanges(params, data);
            if (!response.data.msg && response.data.status === "ok") {
                notify({ msg: MessagesText.product.range.add, type: "success" });
                this.props.history.push(RoutesPath.setting.product.range.list);
            } else {
                throw { message: response.data.msg };
            }
        } catch (error) {
            this.setState({ s_loaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }

    preload = () => {
        this.getAll();
    };
    async getActiveRanges(pageNumber = 1) {
        const params = {};
        const data = {};
        data["orderby"] = {
            col: "id",
            dir: "DESC",
        };
        const response = await ProductDAO.getActiveRanges(pageNumber, params, data);
        this.setState({ ranges: [...this.state.ranges, ...response.data.ressources] }, () => {
            if (response.data.total / 20 > pageNumber) {
                this.getActiveRanges(pageNumber + 1);
            } else {
                this.state.ranges.map((object) => {
                    const shouldCheckedInput = document.getElementById(`product_line_id_${object.id}`);
                    shouldCheckedInput.checked = true;
                });
            }
        });
    }
    async getAll(pageNumber = 1) {
        try {
            const params = {};
            const response = await CommonDAO.getAllProductRanges(pageNumber);
            this.setState({ data: [...this.state.data, ...response.data.ressources] }, () => {
                if (response.data.total / 20 > pageNumber) {
                    this.getAll(pageNumber + 1);
                } else {
                    this.setState({ is_loaded: true });
                    this.getActiveRanges();
                }
            });
        } catch (error) {
            this.setState({ s_loaded: true });
            notify({ msg: error.message, type: "danger" });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(AffectACLMethods);

const Th = styled.th`
    cursor: pointer;
`;
const Td = styled.td`
    border-top: 1px solid #8d8d8d;
    wordwrap: "break-word";
    font-weight: 400;
    padding: 6px 5px;
`;
const Tr = styled.tr`
    cursor: pointer;
`;
const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    background: #fff;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
