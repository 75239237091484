import React, { Component } from "react";
import styled from "styled-components";
import UserDAO from "../DAO/UserDAO";
import TableModel from "../components/TableModel";
import { RoutesPath } from "../values/RoutesPath";
import { withRouter } from "react-router";
import { acl } from "../services/acl";
import { connect } from "react-redux";
import CustomInput from "../components/CustomInput";
import { DatePicker } from "../components/CustomDatePicker";
import SearchSelect from "../helpers/SearchSelect";
import CustomSelect from "../components/CustomSelect";

const thead = [
    { property: "nom", label: "Nom", type: "string" },
    { property: "prenom", label: "Prénom", type: "string" },
    { property: "is_admin", label: "Est Admin", type: "boolean" },
    { property: "is_superviseur", label: "Est Superviseur", type: "boolean" },
];

class UsersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            searchType: "simple",
            searchToggle: false,
            searchFields: {
                name: { value: "", operator: "" },
                firstName: { value: "", operator: "" },
                isAdmin: { value: "", operator: "" },
                isSupervisor: { value: "", operator: "" },
            },
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [] });
        this.getAll();
    }
    async getAll() {
        try {
            const { pageNumber, searchFields, searchType, searchToggle } = this.state;
            const params = {};
            let data = UserDAO.getSearchData({ searchFields, searchType, searchToggle });
            data["disabled"] = {
                value: 0,
                op: "is",
            };
            data["orderby"] = {
                col: "id",
                dir: "DESC",
            };
            const response = await UserDAO.getALLUsers(pageNumber, params, data);

            this.setState({ is_loaded: true, data: response.data.utilisateurs, totalItems: response.data.total }, () => this.state.data);
        } catch (error) {
            this.setState({ s_loaded: true });
        }
    }

    setSearchFields(field, property, value) {
        this.setState((prevState) => ({
            ...prevState,
            searchFields: {
                ...prevState.searchFields,
                [field]: {
                    ...prevState.searchFields[field],
                    [property]: value,
                },
            },
        }));
    }
    searchToggle(searchTypeParam) {
        const { searchToggle, searchType } = this.state;
        this.setState({ searchType: searchTypeParam, searchToggle: searchToggle && searchType === searchTypeParam ? false : true });
    }
    onReload = () => {
        this.setState(
            (prevState) => ({
                ...prevState,
                pageNumber: 1,
                is_loaded: false,
                searchFields: {
                    name: { value: "", operator: "" },
                    firstName: { value: "", operator: "" },
                    isAdmin: { value: "", operator: "" },
                    isSupervisor: { value: "", operator: "" },
                },
            }),
            () => this.getAll()
        );
    };
    render() {
        const { is_loaded, data, pageNumber, totalItems, searchToggle, searchType } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Utilisateurs</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page contient la liste de tous les utilisateurs.</Block_content_title>
                    </Block_content>
                </First_block>

                <TableModel
                    title="Utilisateurs"
                    onReload={this.onReload}
                    BasicSearchComponent={this.basicSearchComp()}
                    thead={thead}
                    tbody={{
                        dataIsLoaded: is_loaded,
                        data: data,
                        onClickRow: (id) => {},
                        actions: [
                            acl.setting.user.affect_role() && {
                                title: "modifier",
                                onClick: (id) => {
                                    this.props.history.push(`${RoutesPath.setting.user.affect_role}/${id}`);
                                },
                            },
                            // { title: "archiver",onClick:(id)=>{} }
                        ],
                    }}
                    searchToggle={searchToggle}
                    searchType={searchType}
                    searchToggleFunc={this.searchToggle.bind(this)}
                    tfoot={{
                        colSpan: 8,
                        pageNumber: pageNumber,
                        totalItems: totalItems,
                        nextPage: () => {
                            this.setState({ pageNumber: pageNumber + 1, is_loaded: false }, () => {
                                this.getAll();
                            });
                        },

                        PrevPage: () => {
                            this.setState({ pageNumber: pageNumber - 1, is_loaded: false }, () => {
                                this.getAll();
                            });
                        },
                    }}
                    {...this.props}
                />
            </>
        );
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll());
        }
    }
    basicSearchComp() {
        const { searchFields, searchType } = this.state;
        return (
            <tr className="table_th_orderable">
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput searchIcon={true} onKeyDown={(e) => this.handleKeyDown(e)} onChange={(value) => this.setSearchFields("name", "value", value.target.value)} value={searchFields.name.value} defaultValue={searchFields.name.value} label="Nom"></CustomInput>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomInput searchIcon={true} onKeyDown={(e) => this.handleKeyDown(e)} onChange={(value) => this.setSearchFields("firstName", "value", value.target.value)} value={searchFields.firstName.value} defaultValue={searchFields.firstName.value} label="Prénom"></CustomInput>
                    </Search_input_container>
                </Th>
                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect
                            onKeyDown={(e) => this.handleKeyDown(e)}
                            error={false}
                            onChange={(value) => {
                                this.setSearchFields("isAdmin", "value", value.target.value);
                            }}
                            selectedValue={searchFields.isAdmin.value}
                            label="Est Admin"
                        >
                            <option value="" defaultValue></option>
                            <option value="1">Oui</option>
                            <option value="0">Non</option>
                        </CustomSelect>
                    </Search_input_container>
                </Th>

                <Th backgroundColor="#fff">
                    <Search_input_container>
                        <CustomSelect
                            onKeyDown={(e) => this.handleKeyDown(e)}
                            error={false}
                            onChange={(value) => {
                                this.setSearchFields("isSupervisor", "value", value.target.value);
                            }}
                            selectedValue={searchFields.isSupervisor.value}
                            label="Est Superviseur"
                        >
                            <option value="" defaultValue></option>
                            <option value="1">Oui</option>
                            <option value="0">Non</option>
                        </CustomSelect>
                    </Search_input_container>
                </Th>
                {searchType == "advanced" ? (
                    <Th backgroundColor="#fff"></Th>
                ) : (
                    <Th backgroundColor="#fff">
                        <Search_btn onClick={() => this.setState({ pageNumber: 1, is_loaded: false }, () => this.getAll())}>
                            <Search_btn_icon aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    fill="#fff"
                                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                                ></path>
                            </Search_btn_icon>
                        </Search_btn>
                    </Th>
                )}
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state,
    };
};
export default connect(mapStateToProps)(withRouter(UsersPage));

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;
const Th = styled.th`
    cursor: pointer;
    background: ${(props) => props.backgroundColor} !important;
`;

const Search_input_container = styled.div`
    height: 38px;
    position: relative;
`;
const Search_icon = styled.div`
    position: absolute;
    height: 10px;
    width: 10px;
    top: 10px;
    right: 10px;
`;
const Svg = styled.svg`
    width: 15px;
`;
const SearchTr = styled.tr`
    background: "#fff";
`;
const Search_btn = styled.button`
    width: 28px;
    height: 27px;
    outline: none;
    border: 0;
    background: #00beb0;
    position: relative;
    cursor: pointer;
    padding-top: 5px;
    border-radius: 3px;
`;
const Search_btn_icon = styled.svg`
    width: 15px;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;
