import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import InvoicesTable from "../components/InvoicesTable";
import styled from "styled-components";

class ListInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Main_container className="Main_container">
                <TopOptions />
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Ventes</h2>
                        <div className="bloc_header_btns">
                            <a href="#" className="sprite-bloc-search" />
                        </div>
                    </div>
                    <InvoicesTable {...this.props} />
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 10px;
`;

export default ListInvoices;
