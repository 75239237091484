export default {
    200: "Page traité avec succès",
    301: "La ressource demandée n’est plus disponible à cette adresse",
    302: "Déplacement temporaire de cette adresse",
    307: "Redirection temporaire ; un usage méconnu mais utile !",
    407: "Accès non autorisé",
    403: "Interdit… non non",
    404: "L'URL est introuvable",
    500: "Erreur interne du serveur",
    502: "Mauvaise passerelle",
    503: "Service indisponible",
    504: "Temps d’attente expiré",
};
