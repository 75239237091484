import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";

import moment from "moment";
import FlashMessages from "../helpers/FlashMessages";
import { withRouter } from "react-router";
import { notify } from "../helpers/FlashMessage";
import ResourceDAO from "../DAO/ResourceDAO";

class DetailsHealthFacilitiesSuggPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resource: null,
            successMessage: "",
        };
    }
    componentDidMount() {
        document.title = "Produits - Sobrus Labs";

        this.get();
    }
    async get() {
        try {
            const id = this.props.match.params.id;
            const response = await ResourceDAO.getResourceSuggestionById("etablissement", id, {});
            this.setState({ resource: response.data.etablissement }, () => this.state.resource);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    translate(param) {
        if (param === true) {
            return "Oui";
        } else if (param === false) {
            return "Non";
        } else {
            return "Aucun";
        }
    }
    render() {
        const { resource, successMessage } = this.state;
        const title = resource && resource.nom;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={successMessage} errorMessage="" />
                <TopOptions title={"Établissement de santé : " + title}></TopOptions>
                <Content className="Content">
                    <Left_block_container>
                        <Block>
                            <div className="bloc_header">
                                <h2>Détails</h2>
                                <div className="bloc_header_btns"></div>
                            </div>
                            <Block_content>
                                <Title_container>
                                    <Title>Informations générales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Type</Field_label>
                                            {/* <Field_value_green>{contact && contact.gestionnaire}</Field_value_green> */}
                                            <Field_value>{resource && resource.type}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Spécialité</Field_label>
                                            <Field_value>{resource && resource.specialite}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Nom</Field_label>
                                            <Field_value>{resource && resource.nom}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Téléphone</Field_label>
                                            <Field_value>{resource && resource.telephone && resource.telephone}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Portable</Field_label>
                                            <Field_value>{resource && resource.portable && resource.portable}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Fax</Field_label>
                                            <Field_value>{resource && resource.fax && resource.fax}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Site internet</Field_label>
                                            <Field_value>{resource && resource.site_internet}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Informations légales</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>RC</Field_label>
                                            <Field_value>{resource && resource.rc}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>INPE</Field_label>
                                            <Field_value>{resource && resource.inpe}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>
                                <Title_container>
                                    <Title>Adresse</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Adresse</Field_label>
                                            <Field_value>{resource && resource.adresse}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Secteur</Field_label>
                                            <Field_value>{resource && resource.secteur}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Ville</Field_label>
                                            <Field_value>{resource && resource.ville}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Région</Field_label>
                                            <Field_value>{resource && resource.region}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Code postal</Field_label>
                                            <Field_value>{resource && resource.code_postal}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Pays</Field_label>
                                            <Field_value>{resource && resource.pays}</Field_value>
                                        </Col_2>
                                    </Row>
                                </Fields_container>


                                <Title_container>
                                    <Title>Informations sur statut</Title>
                                    <Bar></Bar>
                                </Title_container>
                                <Fields_container>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Statut</Field_label>
                                            <Field_value>{resource && resource.statut}</Field_value>
                                        </Col_1>
                                        <Col_2>
                                            <Field_label>Raison du rejet</Field_label>
                                            <Field_value>{resource && resource.raison_rejet}</Field_value>
                                        </Col_2>
                                    </Row>
                                    <Row>
                                        <Col_1>
                                            <Field_label>Commentaire du rejet</Field_label>
                                            <Field_value>{resource && resource.commentaire_rejet}</Field_value>
                                        </Col_1>
                                    </Row>
                                </Fields_container>

                            </Block_content>
                        </Block>
                    </Left_block_container>
                    <Right_block_container>
                        <Traceability_block>
                            <Traceability_block_title>Informations de traçabilité</Traceability_block_title>
                            <ul>
                                <Li>
                                    <Col_1_li>Créée par</Col_1_li>
                                    <Col_1_li>{resource && resource.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Créée le</Col_1_li>
                                    <Col_1_li>{resource && resource.cree_le ? moment(resource.cree_le).format("YYYY-MM-DD HH:mm") : ""}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour par</Col_1_li>
                                    <Col_1_li>{resource && resource.mis_a_jour_par ? resource && resource.mis_a_jour_par : resource && resource.cree_par}</Col_1_li>
                                </Li>
                                <Li>
                                    <Col_1_li>Mis à jour le</Col_1_li>
                                    <Col_1_li>{resource && resource.mis_a_jour_le ? resource && moment(resource.mis_a_jour_le).format("YYYY-MM-DD HH:mm") : resource && resource.cree_le && moment(resource.cree_le).format("YYYY-MM-DD HH:mm")}</Col_1_li>
                                </Li>
                            </ul>
                        </Traceability_block>
                    </Right_block_container>
                </Content>
            </Main_container>
        );
    }
}

export default withRouter(DetailsHealthFacilitiesSuggPage);

const Main_container = styled.div`
    height: 100%;
`;
const Left_block_container = styled.div`
    width: 74%;
    padding-bottom: 10px;
    display: inline-block;
    vertical-align: top;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 50px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;
const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    margin-top: 29px;
    margin-bottom: 30px;
`;
const Content = styled.div`
    margin-top: 10px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
`;
const Row = styled.div`
    display: block;
    margin-bottom: 5px;
    width: 100%;
`;
const Field_label = styled.p`
    display: block;
    color: grey;
    font-weight: 600;
`;
const Field_value = styled.p`
    display: block;
    font-size: 14px;
    font-weight: 700;
`;
const Col_1 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
`;
const Col_2 = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    background: #f6f6f6;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    width: 48%;
    margin-left: 39px;
`;
const Right_block_container = styled.div`
    width: 24%;
    display: inline-block;
    vertical-align: top;
    margin-left: 27px;
`;
const Traceability_block = styled.div`
    width: 100%;
    background: #fff;
    margin-bottom: 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 3px 0 #bcbcbc;
    -webkit-box-shadow: 0 1px 3px 0 #bcbcbc;
    box-shadow: 0 1px 3px 0 #bcbcbc;
`;
const Traceability_block_title = styled.p`
    padding: 10px;
    background: #f0f0f0;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    display: block;
    width: 100%;
`;
const Li = styled.li`
    border-top: 1px solid #8d8d8d;
    padding: 6px 5px;
    cursor: pointer;
    &:hover {
        background: #00beb0;
        > p {
            color: #fff;
        }
    }
`;
const Col_1_li = styled.p`
    font-weight: 600;
    font: 12px "Open Sans", sans-serif;
    color: #494949;
    height: 100%;
    width: 50%;
    display: inline-block;
    vertical-align: top;
`;
const Title_container = styled.div`
    position: relative;
`;
const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
