import React, { Component } from 'react';
import styled from "styled-components";

class PostsLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <Container>
                    <P>Loading...</P>
            </Container>
        );
    }
}
const Container = styled.div`
   width: 100%;
    display: block;
    position: relative;
    margin-left: .5%;
    margin-right: .5%;
    margin-top: 3%;
    background: #fff;
    box-shadow: 0 1px 3px 0 #bcbcbc;
    height: 48px;
    background: #fff;
`;
const P = styled.p`
font-family: 'Open Sans', sans-serif;    font-size: 14px;
    color: #1ebeb0;
    text-align: center;
    padding-top: 13px;
    cursor:pointer;
    
`;
export default PostsLoader;