import { BASE_URL } from "../env";

class Auth {
    constructor() {
        this.authenticated = false;
    }
    login = (cb) => {
        localStorage.setItem("isLogged", true);
        this.authenticated = true;

        cb();
    };
    logout = () => {
        localStorage.clear();
        this.authenticated = false;
        window.location.href = BASE_URL + "/auth/logout";
    };
    refresh = (cb) => {
        window.location.href = `${BASE_URL}/auth/refresh?redirect_uri=${window.location.pathname}`;
    };

    isAuthenticated = () => {
        const isLogged = localStorage.getItem("isLogged");
        return isLogged;
    };
}

export default new Auth();
