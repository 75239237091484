import React, { Component } from "react";
import styled from "styled-components";
import AclDAO from "../DAO/AclDAO";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import CustomSelect from "../components/CustomSelect";
import UserDAO from "../DAO/UserDAO";
import CustomInput from "../components/CustomInput";

import FromLoading from "../helpers/FromLoading";
import { FormContainer, Left, Right, InputContainer } from "../helpers/formStyledComp";
import { RoutesPath } from "../values/RoutesPath";
import { notify } from "../helpers/FlashMessage";
import { MessagesText } from "../values/MessagesText";
import SearchSelect from "../helpers/SearchSelect";

class RoleToUserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            totalItems: 0,
            data: [],
            isLoaded: false,
            fields: {
                role: "",
                id_manager: "",
                type_access: "self",
                userInfo: null,
                is_superviseur: false,
            },
            fieldsError: {
                role: false,
            },
            roles: [],
            loading: false,
            users: [],
            selected_user: null,
        };
    }
    componentDidMount() {
        this.props.dispatch({ type: "SETTINGS_TOP_OPTIONS_BTN", value: [{ label: "Sauvegarder", onClick: () => this.add() }] });
        this.getUsers();
        this.getRoles();
        this.get();
        // this.getConnectedUserInfos();
    }
    async get() {
        try {
            const user_id = this.props.match.params.user_id;
            const params = {};
            const { fields } = this.state;
            const response = await UserDAO.getUserById(user_id, params);
            this.setFields("userInfo", response && response.data && response.data.utilisateur ? response.data.utilisateur : {});
            this.setFields("id_manager", response.data.utilisateur.superviseur_id ? response.data.utilisateur.superviseur_id : "");

            response.data.utilisateur.superviseur_id && response.data.utilisateur.superviseur && this.setState({ selected_user: { value: response.data.utilisateur.superviseur_id, label: response.data.utilisateur.superviseur } });
            this.setFields("role", response && response.data && response.data.utilisateur ? response.data.utilisateur.role_id : "");
            this.setFields("type_access", response && response.data && response.data.utilisateur && response.data.utilisateur.type_access ? response.data.utilisateur.type_access : "self");
            this.setFields("is_superviseur", response && response.data && response.data.utilisateur.is_superviseur);
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async add() {
        try {
            const { fields } = this.state;
            const user_id = this.props.match.params.user_id;

            if (fields.role.length !== 0) {
                const params = {};
                let data = {
                    role: fields.role,
                    is_superviseur: fields.is_superviseur === true ? 1 : 0,
                };
                if (fields.id_manager) {
                    data["superviseur"] = fields.id_manager;
                }
                if (fields.type_access) {
                    data["type_access"] = fields.type_access;
                }
                this.setState({ loading: true });
                const response = await AclDAO.affectRole(user_id, params, data);
                this.setState({ loading: false });
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.roles.affectToUser, type: "success" });
                    this.props.history.push(RoutesPath.setting.user.list);
                } else {
                    throw { message: response.data.msg };
                }
            } else {
                if (fields.role.length === 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        fieldsError: {
                            ...prevState.fieldsError,
                            role: true,
                        },
                    }));
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
            this.setState({ loading: false });
        }
    }
    setFields(field, value) {
        this.setState(
            (prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [field]: value,
                },
                fieldsError: {
                    ...prevState.fieldsError,
                    [field]: false,
                },
            }),
            () => this.state.fields
        );
    }
    getRoles = async () => {
        try {
            let data = {};

            const response = await AclDAO.getAllRoles({});
            this.setState({ roles: response.data });
        } catch (error) {
            this.setState({ is_loaded: false });
            notify({ msg: error.message, type: "danger" });
        }
    };
    onSearchChange = (selectedOption) => {
        if (selectedOption) {
            this.setFields("role", selectedOption);
        }
    };
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.add();
        }
    }
    async getUsers() {
        try {
            const data = {
                pagination: {
                    value: "OFF",
                },
            };
            const response = await UserDAO.getALLUsers(1, {}, data);
            this.setState({ users: response.data.utilisateurs });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    async getConnectedUserInfos() {
        try {
            const params = {};
            const response = await UserDAO.getConnectedUserInfos(params);
            this.setState({ id_manager: response.data.id });
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    }
    render() {
        const { fields, loading, roles, fieldsError, users } = this.state;
        return (
            <>
                <First_block>
                    <div className="bloc_header">
                        <h2>Affecter un rôle</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Block_content_title>Cette page vous permet d'affecter un rôle à un utilisateur.</Block_content_title>
                    </Block_content>
                </First_block>
                <Block>
                    {loading && <FromLoading />}
                    <div className="bloc_header">
                        <h2>Affecter un rôle</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Block_content>
                        <Title_container>
                            <Title>Informations générales</Title>
                            <Bar></Bar>
                        </Title_container>
                        <Fields_container>
                            <FormContainer>
                                <Left>
                                    <InputContainer>
                                        <CustomInput editable={false} error={false} onChange={(value) => {}} defaultValue={fields && fields.userInfo && fields.userInfo.fullname} value={fields && fields.userInfo && fields.userInfo.fullname} id="full_name" label="Nom et Prénom"></CustomInput>
                                    </InputContainer>

                                    <InputContainer>
                                        <CustomSelect
                                            onKeyDown={this.handleKeyDown.bind(this)}
                                            error={fieldsError.role}
                                            onChange={(value) => {
                                                this.setFields("role", value.target.value);
                                            }}
                                            selectedValue={fields.role}
                                            label="Rôle"
                                        >
                                            <option defaultValue></option>
                                            {roles &&
                                                roles.map((object, index) => {
                                                    return (
                                                        <option key={`${index}_key`} value={object.id}>
                                                            {object.nom}
                                                        </option>
                                                    );
                                                })}
                                        </CustomSelect>
                                    </InputContainer>
                                    <InputContainer>
                                        <CustomSelect
                                            error={false}
                                            onChange={(value) => {
                                                this.setFields("type_access", value.target.value);
                                            }}
                                            selectedValue={fields.type_access}
                                            label="Type d'accès"
                                        >
                                            <option value="self">Accède seulement à ses données</option>
                                            <option value="full">Accède à toutes les données</option>
                                            <option value="team">Accède aux données de son équipe</option>
                                        </CustomSelect>
                                    </InputContainer>
                                </Left>
                                <Right>
                                    {/* <InputContainer>
                                        <CustomInput editable={false} error={false} onChange={(value) => {}} defaultValue={fields && fields.userInfo && fields.userInfo.email} value={fields && fields.userInfo && fields.userInfo.email} id="email" label="E-mail"></CustomInput>
                                    </InputContainer> */}
                                    <InputContainer>
                                        {/* <CustomSelect
                                            error={false}
                                            onChange={(value) => {
                                                this.setFields("id_manager", value.target.value);
                                            }}
                                            selectedValue={fields.id_manager}
                                            label="Superviseur"
                                        >
                                            <option defaultValue></option>
                                            {users.map((user, index) => {
                                                return (
                                                    <option key={"key_" + index} value={user.id}>
                                                        {user.fullname}
                                                    </option>
                                                );
                                            })}
                                        </CustomSelect> */}
                                        <SearchSelect
                                            filterData={{
                                                is_superviseur: { value: "1", op: "is" },
                                            }}
                                            fetchedUrl="/api/utilisateurs"
                                            HTTPMethod="post"
                                            receivedObject="utilisateurs"
                                            optionLabelProperty="fullname"
                                            optionValueProperty="id"
                                            value={this.state.selected_user}
                                            placeholder="Superviseur"
                                            onChange={(e) => {
                                                this.setState({ selected_user: e });
                                                this.setFields("id_manager", e && e.value);
                                            }}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <div className="form_row">
                                            <CheckBoxLabel htmlFor="is_superviseur" className style={{}}>
                                                Est superviseur
                                            </CheckBoxLabel>
                                            <label htmlFor="is_superviseur" className="checkbox" style={{ display: "block" }}>
                                                {/* <input
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.setField("produit_marche", "1");
                                                } else {
                                                    this.setField("produit_marche", "0");
                                                }
                                            }}
                                            type="hidden"
                                            name="produit_marche"
                                        /> */}
                                                <input
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            this.setFields("is_superviseur", true);
                                                        } else {
                                                            this.setFields("is_superviseur", false);
                                                        }
                                                    }}
                                                    checked={fields.is_superviseur}
                                                    type="checkbox"
                                                    name="is_superviseur"
                                                    id="is_superviseur"
                                                    defaultValue={fields.is_superviseur}
                                                    autoComplete="off"
                                                />
                                                <span className="checked" />
                                            </label>
                                        </div>
                                    </InputContainer>
                                </Right>
                            </FormContainer>
                        </Fields_container>
                    </Block_content>
                </Block>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        settingsTopOptionsBtn: state.Settings.settingsTopOptionsBtn,
    };
};
export default compose(withRouter, connect(mapStateToProps))(RoleToUserPage);

const CheckBoxLabel = styled.label`
    display: inline-block;
    margin-bottom: 3px;
`;
const Block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 10px;
    position: relative;
`;

const First_block = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 100%;
    position: relative;
    background: #fff;
    padding-bottom: 30px;
    margin-bottom: 10px;
`;

const Block_content = styled.div`
    width: 100%;
    padding: 10px;
`;

const Block_content_title = styled.p`
    padding: 10px 0;
    background: #f6f6f6;
    border: 2px solid #b2b2b2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
`;

const Title = styled.p`
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #494949;
    position: absolute;
    top: -10px;
    left: 0;
    padding-right: 21px;
`;
const Fields_container = styled.div`
    width: 98%;
    margin: auto;
    ${"" /* margin-top: 29px;
    margin-bottom: 30px; */}
`;

const Row = styled.div`
    display: block;
    margin-bottom: 20px;
    width: 100%;
`;

const Col_1 = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;

const Title_container = styled.div`
    position: relative;
`;

const Bar = styled.div`
    width: 98%;
    height: 1px;
    background: #8d8d8d;
    margin-top: 17px;
`;
