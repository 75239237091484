import React, { Component } from "react";
import TopOptions from "../components/TopOptions";
import styled from "styled-components";
import CustomInput from "../components/CustomInput";
import FlashMessages from "../helpers/FlashMessages";
import { errorMessage } from "../tmp/Messages";
import { connect } from "react-redux";

import CalenderDAO from "../DAO/CalenderDAO";
import { MessagesText } from "../values/MessagesText";
import { DatePicker } from "../components/CustomDatePicker";
import { RoutesPath } from "../values/RoutesPath";
import moment from "moment";
import { notify } from "../helpers/FlashMessage";
/**
 *
 * this component can used for persist and update  account
 * if id_account not 0 means update
 * else mean persist
 *
 */

class ReportEventPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                report_date: { value: new Date(), error: false },
                comment: { value: "", error: false },
            },
        };
    }

    componentDidMount() {
        document.title = "Comptes - Sobrus Labs";
    }

    verifyRequired() {
        const { fields } = this.state;
        if (fields.report_date && fields.report_date.value !== null) {
            const data = {
                date_report: moment(fields.report_date.value).format("YYYY-MM-DD HH:mm"),
                commentaire: fields.comment.value,
            };
            return data;
        } else {
            this.setFields("report_date", "error", true);
        }
    }

    report = async () => {
        try {
            const data = this.verifyRequired();
            const report_id = this.props.match.params.id;
            if (data) {
                const response = await CalenderDAO.reportEvent(report_id, {}, data);
                if (!response.data.msg && response.data.status === "ok") {
                    notify({ msg: MessagesText.event.report, type: "success" });
                    this.props.history.push(`${RoutesPath.calender.details}/${report_id}`);
                } else {
                    notify({ msg: response.data.msg, type: "danger" });
                }
            }
        } catch (error) {
            notify({ msg: error.message, type: "danger" });
        }
    };

    setFields(field, property, value) {
        this.setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [field]: {
                    ...prevState.fields[field],
                    [property]: value,
                },
            },
        }));
    }

    render() {
        const { fields, errorMessage } = this.state;
        return (
            <Main_container className="Main_container">
                <FlashMessages successMessage={""} errorMessage={errorMessage} />
                <PopUp></PopUp>
                <TopOptions title="Reporter événement">
                    <button className="btn_bleu" data-shortcut_alias="create" onClick={this.report}>
                        Sauvegarder
                    </button>
                </TopOptions>
                <Bloc className="Bloc">
                    <div className="bloc_header">
                        <h2>Reporter événement</h2>
                        <div className="bloc_header_btns"></div>
                    </div>
                    <Form_container>
                        <Row>
                            <Left_Input_container>
                                <DatePicker
                                    error={fields.report_date.error}
                                    selected={fields.report_date.value}
                                    onChange={(date) => {
                                        this.setFields("report_date", "value", date);
                                        this.setFields("report_date", "error", false);
                                    }}
                                    label="Date de report*"
                                    id="report_date_id"
                                />
                            </Left_Input_container>
                            <Right_input_container>
                                <CustomInput error={fields.comment.error} onChange={(value) => this.setFields("comment", "value", value.target.value)} defaultValue={fields.comment.value} id="comment" label="Commentaire"></CustomInput>
                            </Right_input_container>
                        </Row>
                    </Form_container>
                </Bloc>
            </Main_container>
        );
    }
}

const Main_container = styled.div`
    height: 100%;
`;
const PopUp = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: ;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Bloc = styled.div`
    box-shadow: 0 1px 3px 0 #bcbcbc;
    width: 96.3%;
    position: relative;
    margin: auto;
    padding-bottom: 38px;
    margin-top: 10px;
    background: #fff;
`;
const Left_Input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
`;
const Right_input_container = styled.div`
    width: 48%;
    height: 41px;
    display: inline-block;
    vertical-align: top;
    margin-left: 51px;
`;
const Form_container = styled.div`
    margin-top: 38px;
`;
const Row = styled.div`
    width: 94%;
    margin: auto;
    margin-top: 18px;
`;

const mapStateToProps = (state) => {
    return {
        selected: state.selcted,
    };
};
export default connect(mapStateToProps)(ReportEventPage);
